import { Mention as TipTapMention } from 'tiptap-extensions';

// Class to allow the use of hashtags and mentions within tiptap
// Extends then overrides Mention class
// See https://github.com/scrumpy/tiptap/issues/11
export default class Hashtag extends TipTapMention {
    get name() {
        return 'hashtag';
    }

    get defaultOptions() {
        return {
            matcher: {
                char: '#',
                allowSpaces: false,
                startOfLine: false,
            },
            mentionClass: 'hashtag',
            suggestionClass: 'hashtag-suggestion',
        };
    }

    get schema() {
        return {
            ...super.schema,
            // Implement our own dom structure
            toDOM: (node) => [
                // Tag
                'a',
                // Attributes
                {
                    class: this.options.mentionClass,
                    href: `/search/?text=%23${node.attrs.id}`,
                    rel: 'noopener noreferrer nofollow',
                    'data-tag-id': node.attrs.id,
                    target: '_blank',
                },
                // Content
                `${this.options.matcher.char}${node.attrs.label}`,
            ],
            parseDOM: [
                {
                    tag: 'a[data-tag-id]',
                    getAttrs: (dom) => {
                        const id = dom.getAttribute('data-tag-id');
                        const label = dom.innerText.split(this.options.matcher.char).join('');
                        return { id, label };
                    },
                },
            ],
        };
    }
}
