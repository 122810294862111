import AuthService from './auth-service.js';
import AuthModule from './auth-module.js';
import AuthProtectedContent from './AuthProtectedContent.vue';

const Auth = {
    install(Vue, {
        store = null,
        allowedMessageOrigins = null,
        ssoUrl = null,
        moduleName = 'auth',
    } = {}) {
        if (!store) {
            throw new Error('Auth plugin must be passed a vuex store.');
        }
        if (!allowedMessageOrigins) {
            throw new Error('Auth plugin must be passed an array of allowedMessageOrigins.');
        }
        if (!ssoUrl) {
            throw new Error('Auth plugin must be passed an ssoUrl.');
        }

        // Register the auth module for the service
        store.registerModule(moduleName, AuthModule);

        const service = new AuthService({
            store, allowedMessageOrigins, ssoUrl, moduleName,
        });
        // Register the auth service globally
        Vue.$auth = service;

        // Register the auth service on the vue instance
        Vue.prototype.$auth = service;

        // Register the AuthProtectedContent component
        Vue.component('AuthProtectedContent', AuthProtectedContent);
    },
};

export default Auth;
