export class ApiControllerOneTimeJWT {
    constructor({ axiosInstance, route } = {}) {
        // Configured base axios instance
        this.axiosInstance = axiosInstance;

        // Route appended to the axios instance baseURL
        this.route = route;
    }

    /**
     * Generates a one-time token by exchanging the user's JWT.
     * @param embeddedData - Object containing data that should be embedded into the one time jwt
     * @param storedData - Object containing private data that should be provided when one time jwt is used
     * @returns a petro result
     */
    generateOneTimeJWT({ embeddedData = null, storedData = null } = {}) {
        return this.axiosInstance.post(`${this.route}/jwts`, {
            embeddedData,
            storedData,
        });
    }
}
