import Vue from 'vue';
import { Logger } from '../logging/logger.js';
import petroJSSettings from '../../../../petrojs-settings.json';

export class GlobalSettingsService {
    constructor() {
        this.log = new Logger({ name: 'GlobalSettingsService', level: Logger.levels.warn });
        Vue.$logging.registerLogger(this.log);

        const workspaceName = '$root/ApplicationSettings';
        const resourceName = 'ApplicationSettings';
        this.__appSettingsForgeResource = `${workspaceName}#${resourceName}`;

        Vue.$auth.afterSignIn('loadAppSettings', user => {
            this.__loadAppSettings();
        });

        Vue.$auth.beforeSignOut('disposeAppSettings', () => {
            this.__dispose();
        });
    }

    __loadAppSettings() {
        this.__forgeDisposableReference = Vue.$compute.forgeSubscribeElement('GlobalSettingsService', this.__appSettingsForgeResource);
    }

    __dispose() {
        if (this.__forgeDisposableReference) {
            this.__forgeDisposableReference.dispose();
        }
    }

    get displayTimeZoneId() {
        if (this.settings && this.settings.displayTimeZoneId) {
            if (typeof this.settings.displayTimeZoneId === 'object') {
                return this.settings.displayTimeZoneId.value;
            }
            return this.settings.displayTimeZoneId;
        }
        return 'UTC';
    }

    get settings() {
        const settingsElement = Vue.$compute.getElement(this.__appSettingsForgeResource);
        if (settingsElement && settingsElement.state === 'ready' && settingsElement.data && settingsElement.data.length) {
            return settingsElement.data[0];
        }
        return {};
    }

    // Expose the compile time petroJS settings to the plugins
    get petroJSSettings() {
        return petroJSSettings;
    }

    updateSettings(value) {
        const id = this.settings.id;
        if (!id) {
            return;
        }
        const body = {
            ...value,
            id,
        };
        return Vue.$api.data.update({
            type: 'AppSetting',
            body,
            query: {
                id,
            },
        })
        .then(response => {
            // Catch all errors in order not disrupt downstream caller
            try {
                // Update the units service to ensure units are still accurate
                Vue.$units.__loadReportingUnitsDefinition();
            }
            catch (error) {
                this.log.warn('Unable to call $units.__loadReportingUnitsDefinition:', error);
            }
            return response;
        });
    }
}
