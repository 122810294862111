import Vue from 'vue';
import { Logger } from '../logging/logger.js';
import FilterModule from './filter-module.js';

class FilterService {
    constructor({ store, router }) {
        this.log = new Logger({ name: 'FilterService', level: Logger.levels.warn });
        Vue.$logging.registerLogger(this.log);

        if (!store) {
            this.log.error('Filter service must be passed a vuex store.');
            return;
        }
        if (!router) {
            this.log.error('Filter service must be passed a vue router.');
            return;
        }

        this.store = store;
        this.router = router;
        this.moduleName = 'FilterService';

        store.registerModule(this.moduleName, FilterModule);

        // Add getters and setters for module state so they are exposed through the service
        const accessorProperties = {};
        Object.keys(FilterModule.state).forEach(stateKey => {
            const accessorProperty = {
                enumerable: true,
                get() {
                    return this.store.state[this.moduleName][stateKey];
                },
                set(value) {
                    this.store.commit(`${this.moduleName}/${stateKey}`, value);
                },
            };
            accessorProperties[stateKey] = accessorProperty;
        });
        // Add getters for module getters so they are exposed through the service
        Object.keys(FilterModule.getters).forEach(getterKey => {
            const accessorProperty = {
                enumerable: true,
                get() {
                    return this.store.getters[`${this.moduleName}/${getterKey}`];
                },
            };
            accessorProperties[getterKey] = accessorProperty;
        });
        // Add all the vuex props to the service
        Object.defineProperties(this, accessorProperties);
    }

    get indexDefinitions() {
        return this.routeIndexDefinitions;
    }

    set indexDefinitions(indexDefinitions) {
        this.store.dispatch(`${this.moduleName}/updateIndexDefinitions`, { indexDefinitions });
    }

    resetAllFilters() {
        this.store.dispatch(`${this.moduleName}/resetAllFilters`);
    }
}

export default FilterService;
