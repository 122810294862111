<template>
    <FullPageCard>
        <DataTable
            v-bind="props"
            v-on="events"
        />
    </FullPageCard>
</template>

<script>
import FullPageCard from '../layouts/FullPageCard.vue';
import mixins from '../../mixins';
import DataTable from '../shared-components/tables/data-table/DataTable.vue';

export default {
    name: 'OverviewTableTemplate',
    components: {
        DataTable,
        FullPageCard,
    },
    mixins: [mixins.compute, mixins.lifecycleHooks({ addProps: false })],
    props: {
        props: {
            type: Object,
            required: true,
        },
        events: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style scoped>

</style>
