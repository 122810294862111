<template>
    <GeneralButton v-bind="settingsBtnProps">
        <q-menu
            anchor="bottom right"
            self="top right"
            auto-close
            :dark="dark"
        >
            <q-item
                v-if="currentSettingsDoc"
                dense
            >
                <q-item-section class="text-bold">
                    {{ currentSettingsDoc.name }}
                </q-item-section>
            </q-item>
            <q-item
                clickable
                dense
                :disable="!userCanSaveSetting"
                @click="saveSettingsDoc"
            >
                <q-item-section>Save current {{ displayName }}</q-item-section>
            </q-item>
            <q-item
                clickable
                dense
                @click="showSaveNewSettingsDialog = true"
            >
                <q-item-section>Save as new {{ displayName }}</q-item-section>
            </q-item>
            <q-item
                clickable
                dense
                @click="showLoadSettings()"
            >
                <q-item-section>Load {{ displayName }}</q-item-section>
            </q-item>
            <q-item
                clickable
                dense
                @click="showManageSettings()"
            >
                <q-item-section>Manage {{ displayName }}</q-item-section>
            </q-item>
            <slot name="menu-bottom"/>
        </q-menu>

        <SaveNewSettingsDialog
            v-model="showSaveNewSettingsDialog"
            :display-name="displayName"
            :setting="setting"
            :reference-id="referenceId"
            :parent-id="parentId"
            :version="version"
            @save="saveNewSetting"
        />

        <LoadSettingsDialog
            v-model="showLoadSettingsDialog"
            :display-name="displayName"
            :settings-docs="settingsDocs"
            @apply="applySettingDoc"
        />

        <ManageSettingsDialog
            v-model="showManageSettingsDialog"
            :display-name="displayName"
            :settings-docs="settingsDocs"
            @delete="deleteSetting"
        />
    </GeneralButton>
</template>

<script>
import LoadSettingsDialog from './LoadSettingsDialog.vue';
import SaveNewSettingsDialog from './SaveNewSettingsDialog.vue';
import ManageSettingsDialog from './ManageSettingsDialog.vue';
import GeneralButton from '../inline-elements/GeneralButton.vue';

export default {
    name: 'Settings',
    components: {
        SaveNewSettingsDialog,
        LoadSettingsDialog,
        ManageSettingsDialog,
        GeneralButton,
    },
    props: {
        value: {
            type: [Object, Array],
            required: true,
        },
        // Settings button icon
        icon: {
            type: String,
            required: false,
            default: 'settings',
        },
        // Settings button label
        label: {
            type: String,
            required: false,
            default: null,
        },
        color: {
            type: String,
            required: false,
            default: 'grey-7',
        },
        // Settings button tooltip
        tooltip: {
            type: String,
            required: false,
            default: 'Settings menu',
        },
        // Type of setting used in labels and tooltips
        displayName: {
            type: String,
            required: true,
        },
        // ReferenceId for settings document, typically the component where settings is being used
        referenceId: {
            type: String,
            required: true,
        },
        // ParentId for settings document, what id these settings are related to, e.g. petronId
        parentId: {
            type: String,
            required: true,
        },
        // Version for settings document
        version: {
            type: String,
            required: true,
        },
        useLocalStorage: {
            type: Boolean,
            required: false,
            default: false,
        },
        // Function to be called before saving settings to DB
        beforeSave: {
            required: false,
            validator: fn => typeof fn === 'function' || fn === null,
            default: null,
        },
        beforeLoad: {
            required: false,
            validator: fn => typeof fn === 'function' || fn === null,
            default: null,
        },
        dark: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            showSaveNewSettingsDialog: false,
            showLoadSettingsDialog: false,
            showManageSettingsDialog: false,
            settingsDocs: [],
            currentSettingsDoc: null,
        };
    },
    computed: {
        setting: {
            get() {
                return this.value;
            },
            set(newSetting) {
                this.$emit('input', newSetting);
            },
        },
        settingsBtnProps() {
            return {
                icon: this.icon ? this.icon : null,
                label: this.label ? this.label : null,
                tooltip: this.tooltip,
                color: this.color ? this.color : null,
                flat: true,
                dark: this.dark,
            };
        },
        userCanSaveSetting() {
            // Admin can save every setting
            if (this.currentSettingsDoc && this.$auth.userIsAdmin) {
                return true;
            }

            // Petron Owner can save Petron
            if (this.currentSettingsDoc && this.currentSettingsDoc.scope === 'petron' && this.petronUserIsOwner) {
                return true;
            }

            // Users can save only their own settings
            const userId = this.$auth.user.userId;
            if (this.currentSettingsDoc && this.currentSettingsDoc.scope === 'user' && this.currentSettingsDoc.parentId === userId) {
                return true;
            }
            return false;
        },
    },
    watch: {
        value() {
            this.saveToLocalStorage();
        },
        showLoadSettingsDialog(newVal) {
            this.$emit('showLoadSettings', newVal);
        },
        referenceId() {
            this.loadFromLocalStorage();
        },
    },
    async created() {
        this.loadFromLocalStorage();
        await this.loadSettingsDocs();
    },
    methods: {
        loadFromLocalStorage() {
            if (this.useLocalStorage) {
                if (localStorage[this.referenceId]) {
                    const data = JSON.parse(localStorage[this.referenceId]).data;
                    this.setting = data;
                }
                else {
                    this.$emit('no-local-settings-found');
                }
            }
        },
        saveToLocalStorage(settingsDoc) {
            if (this.useLocalStorage) {
                let doc = settingsDoc;
                if (!doc) {
                    doc = {
                        id: this.referenceId,
                        data: this.setting,
                    };
                }
                else {
                    doc.data = this.setting;
                }
                localStorage[this.referenceId] = JSON.stringify(doc);
            }
        },
        loadSettingsDocs() {
            const query = {
                referenceId: this.referenceId,
                // Find Org, Petron and user parentIds
                parentId: [this.$auth.user.orgId, this.parentId, this.$auth.user.userId],
                version: this.version,
            };
            return this.$api.data.query({
                type: 'Setting',
                query,
            })
            .then(response => {
                if (response && response.success && response.data) {
                    const settings = response.data;
                    this.settingsDocs = settings;
                }
                else {
                    this.$notify.error('Unable to load settings: response contained no data');
                }
            })
            .catch(error => {
                this.$notify.error(`Unable to load settings: ${error.message}`);
            });
        },
        applySettingDoc(settingDoc) {
            this.currentSettingsDoc = settingDoc;
            // When changing settings doc load the data into settings to apply
            if (settingDoc) {
                let setting = JSON.parse(settingDoc.data);
                // Apply any modifications to the settings before load
                if (this.beforeLoad) {
                    setting = this.beforeLoad(setting);
                }
                this.setting = setting;
            }
            else {
                this.setting = null;
            }
        },
        saveSettingsDoc() {
            if (this.userCanSaveSetting) {
                this.$api.data.update({
                    type: 'Setting',
                    query: { id: this.currentSettingsDoc.id },
                    body: {
                        data: JSON.stringify(this.setting),
                    },
                })
                .then(response => {
                    if (response && response.success) {
                        this.$notify.success(`${this.currentSettingsDoc.name} saved.`);
                        this.saveToLocalStorage(this.currentSettingsDoc);
                    }
                    else {
                        this.$notify.error(`There was an error in saving ${this.currentSettingsDoc.name}.`);
                        this.saveToLocalStorage(this.currentSettingsDoc);
                    }
                    this.settingsDocUpdated();
                })
                .catch(error => {
                    this.$notify.error(`There was an error in saving ${this.currentSettingsDoc.name}: ${error.message}.`);
                });
            }
        },
        saveNewSetting(settingDoc) {
            this.$api.data.insert({
                type: 'Setting',
                data: [settingDoc],
            })
            .then(response => {
                if (response && response.success && response.insertedIds.length) {
                    this.$notify.success('Settings saved.');
                    this.newSettingsDocCreated(response.insertedIds[0]);
                }
                else {
                    this.$notify.error('There was an error saving your settings.');
                }
            })
            .catch(error => {
                this.$notify.error('There was an error saving your settings.');
            });
        },
        deleteSetting(settingsDoc) {
            const deleteConfirmed = () => {
                this.$api.data.delete({
                    type: 'Setting',
                    query: { id: settingsDoc.id },
                })
                .then(response => {
                    if (response && response.success) {
                        this.$notify.success(`${settingsDoc.name} ${this.displayName} successfully deleted.`);
                        // remove the local copy of the setting
                        this.removeSetting(settingsDoc.id);
                    }
                    else {
                        this.$notify.error('There was an error deleting these settings.');
                    }
                })
                .catch(error => {
                    this.$notify.error('There was an error deleting these settings.');
                });
            };

            const notifySettings = {
                actions: [
                    {
                        label: 'Yes',
                        handler: deleteConfirmed,
                        color: 'white',
                    },
                    { label: 'Cancel', color: 'white' },
                ],
                timeout: 0,
            };

            this.$notify.warning(`Are you sure you want to delete the ${settingsDoc.name} ${this.displayName}?`, notifySettings);
        },
        async newSettingsDocCreated(settingsDocId) {
            await this.loadSettingsDocs();
            const newSettingsDoc = this.settingsDocs.find(settingsDoc => settingsDoc.id === settingsDocId);
            this.applySettingDoc(newSettingsDoc);
        },
        async settingsDocUpdated(settingsDocId) {
            await this.loadSettingsDocs();
        },
        removeSetting(settingsDocId) {
            this.settingsDocs = this.settingsDocs.filter(settingDoc => settingDoc.id !== settingsDocId);
        },
        async showLoadSettings() {
            await this.loadSettingsDocs();
            this.showLoadSettingsDialog = true;
        },
        async showManageSettings() {
            await this.loadSettingsDocs();
            this.showManageSettingsDialog = true;
        },
    },
};
</script>

<style scoped>

</style>
