<template>
    <q-layout view="hHh LpR fFf">
        <AppHeader/>

        <q-drawer
            v-model="showAppMenu"
            show-if-above
            mini-to-overlay
            :mini-width="38"
            persistent
            :width="452"
            :breakpoint="500"
            :mini="miniState"
        >
            <AppMainMenu
                v-model="miniState"
            />
        </q-drawer>

        <q-drawer
            v-model="showForgeStatusPanel"
            side="right"
            :width="385"
            elevated
        >
            <ForgeStatusPanel/>
        </q-drawer>

        <q-drawer
            v-model="showBackgroundJobsPanel"
            side="right"
            :width="385"
            overlay
            elevated
        >
            <JobsPanel/>
        </q-drawer>

        <q-drawer
            v-model="showSocialMenu"
            side="right"
            :width="385"
            overlay
            elevated
        >
            <AppSocialMenu/>
        </q-drawer>

        <q-page-container
            @click.native="clearMenus"
        >
            <router-view
                :key="$route.path"
                name="appMain"
            />
        </q-page-container>

        <PBlockingNotification/>

        <FDialog
            v-for="(pluginDialog, index) in pluginDialogs"
            :key="index"
            v-model="pluginDialog.model"
            v-bind="pluginDialog.props"
            v-on="pluginDialog.events"
        />
    </q-layout>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import AppHeader from '../navigation/AppHeader.vue';
import AppSocialMenu from '../navigation/AppSocialMenu.vue';
import AppMainMenu from '../navigation/AppMainMenu.vue';
import JobsPanel from '../navigation/JobsPanel.vue';
import FDialog from '../plugin-framework/fragments/FDialog.vue';
import ForgeStatusPanel from '../plugin-framework/services/compute/components/ForgeStatusPanel.vue';

export default {
    name: 'ApplicationLayout',
    components: {
        ForgeStatusPanel,
        JobsPanel,
        FDialog,
        AppMainMenu,
        AppSocialMenu,
        AppHeader,
    },
    data() {
        return {
            showAppMenu: false,
        };
    },
    computed: {
        ...mapState('app', {
            backgroundJobPanelOpen: 'backgroundJobPanelOpen',
            forgePanelOpen: 'forgePanelOpen',
            userPanelOpen: 'userPanelOpen',
            mainMenuOpen: 'mainMenuOpen',
        }),
        pluginDialogs() {
            return this.$pai.getFragments('dialog');
        },
        miniState: {
            get() {
                return !this.mainMenuOpen;
            },
            set(value) {
                if (value === this.mainMenuOpen) {
                    this.toggleMainMenu();
                }
            },
        },
        showBackgroundJobsPanel: {
            get() {
                return this.backgroundJobPanelOpen;
            },
            set(value) {
                if (value !== this.backgroundJobPanelOpen) {
                    this.toggleBackgroundJobPanel();
                }
            },
        },
        showForgeStatusPanel: {
            get() {
                return this.forgePanelOpen;
            },
            set(value) {
                if (value !== this.forgePanelOpen) {
                    this.toggleForgePanel();
                }
            },
        },
        showSocialMenu: {
            get() {
                return this.userPanelOpen;
            },
            set(value) {
                if (value !== this.userPanelOpen) {
                    this.toggleUserPanel();
                }
            },
        },
    },
    beforeDestroy() {
        clearInterval(this.$options.interval);
    },
    methods: {
        ...mapMutations('app', {
            toggleBackgroundJobPanel: 'toggleBackgroundJobPanel',
            toggleForgePanel: 'toggleForgePanel',
            toggleUserPanel: 'toggleUserPanel',
            toggleMainMenu: 'toggleMainMenu',
        }),
        clearMenus() {
            this.miniState = true;
            this.showSocialMenu = false;
            this.showBackgroundJobsPanel = false;
        },
    },
};
</script>

<style scoped>
</style>
