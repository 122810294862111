<template>
    <div class="row items-center no-wrap">
        <PRouterLink
            :router-to="overviewRoute"
            class="q-px-xl text-bold ellipsis"
            @navigate="$emit('navigate')"
        >
            <span v-cypress="'AppHeaderPetronInfo_PetronName_Span'">{{ petron.name || 'Loading...' }}</span>
        </PRouterLink>
        <PetronAppDropdown
            :petron="petron"
            @navigate="$emit('navigate')"
        />
    </div>
</template>

<script>
import PRouterLink from '../plugin-framework/services/pai-components/PRouterLink.vue';
import PetronAppDropdown from './PetronAppDropdown.vue';

export default {
    name: 'AppHeaderPetronInfo',
    components: {
        PetronAppDropdown,
        PRouterLink,
    },
    props: {
        petron: {
            type: Object,
            required: true,
        },
    },
    computed: {
        overviewRoute() {
            if (this.$route.name !== 'petron-overview') {
                return { name: 'petron-overview', params: { petronId: this.petron.id } };
            }
            return {};
        },
    },
};
</script>

<style scoped>
</style>
