<template>
    <q-th
        :class="headerClass"
    >
        <strong v-if="!context.col.sortable">{{ context.col.label }}&nbsp;</strong>
        <span
            v-else
            class="pointer"
            @click="$emit('sort', context.col.name)"
        >
            <q-icon
                v-if="context.col.name === sortByFieldName && sortAscending === true"
                name="arrow_upward"
            />
            <q-icon
                v-if="context.col.name === sortByFieldName && sortAscending === false"
                name="arrow_downward"
            />
            &nbsp;<strong>{{ context.col.label }}</strong>
            <q-tooltip :delay="750">Click to sort</q-tooltip>
        </span>
    </q-th>
</template>

<script>
export default {
    name: 'HeaderCell',
    props: {
        context: {
            type: Object,
            required: true,
        },
        sortByFieldName: {
            validator: data => typeof data === 'string' || data === null || data === undefined,
            required: true,
        },
        sortAscending: {
            validator: data => typeof data === 'boolean' || data === undefined,
            required: true,
        },
    },
    computed: {
        headerClass() {
            return {
                'text-center': this.context.col.align === 'center',
                'text-left': this.context.col.align === 'left',
                'text-right': !this.context.col.align || this.context.col.align === 'right',
            };
        },
    },
};
</script>

<style scoped>

</style>
