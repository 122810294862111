import { UtilsService } from './utils-service.js';

const Auth = {
    install(Vue, {
        store = null,
    } = {}) {
        if (!store) {
            throw new Error('Utils plugin must be passed a vuex store.');
        }

        const service = new UtilsService({ store });

        // Register the utils service globally
        Vue.$utils = service;

        // Register the utils service on the vue instance
        Vue.prototype.$utils = service;
    },
};

export default Auth;
