<template>
    <q-page
        class="q-py-md q-px-md full-page"
    >
        <q-card class="full-height">
            <slot/>
        </q-card>
    </q-page>
</template>

<script>
export default {
    name: 'FullPageCard',
};
</script>

<style scoped lang="stylus">
@import '../../css/petro.styl';
.full-page {
    /* full height minus the application bar */
    height: "calc(100vh - %s)" % $app-header-height;
}
</style>
