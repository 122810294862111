<template>
    <div class="row q-gutter-md">
        <q-input
            v-model="compValue"
            v-cypress="testId"
            :rules="['anyColor']"
            filled
            :clearable="clearable"
            :dark="dark"
            :debounce="debounce"
            :dense="dense"
            :disable="disable"
            :error="!!validationErrors.length"
            :hint="hint"
            :label="label"
            class="col"
            :readonly="readonly"
        >
            <template
                v-if="!readonly"
                v-slot:append
            >
                <q-icon
                    name="colorize"
                    class="cursor-pointer"
                >
                    <q-popup-proxy
                        ref="colorPopup"
                        transition-show="scale"
                        transition-hide="scale"
                    >
                        <q-color
                            v-model="compValue"
                            no-footer
                            no-header
                            default-view="palette"
                            :palette="palette"
                            :readonly="readonly"
                            @change="dismissPopup"
                        />
                    </q-popup-proxy>
                </q-icon>
            </template>
            <template v-slot:error>
                <AutoFormValidationErrors
                    :errors="validationErrors"
                />
            </template>
        </q-input>
        <q-badge
            class="col-auto badge-color-sample"
            :style="{ backgroundColor: sampleColor }"
        />
    </div>
</template>
<script>

import AutoFormValidationErrors from './AutoFormValidationErrors.vue';

export default {
    name: 'AutoFormColorPicker',
    components: {
        AutoFormValidationErrors,
    },
    props: {
        value: {
            type: String,
            required: true,
        },
        testId: {
            type: String,
            required: false,
            default: null,
        },
        clearable: {
            type: Boolean,
            required: false,
            default: true,
        },
        dark: {
            type: Boolean,
            required: false,
            default: false,
        },
        debounce: {
            type: Number,
            required: false,
            default: 0,
        },
        dense: {
            type: Boolean,
            required: false,
            default: true,
        },
        disable: {
            type: Boolean,
            required: false,
            default: false,
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false,
        },
        errors: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },
        hint: {
            type: String,
            required: false,
            default: '',
        },
        filled: {
            type: Boolean,
            required: false,
            default: false,
        },
        label: {
            type: String,
            required: true,
        },
        palette: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },
    },
    data() {
        return {};
    },
    computed: {
        compValue: {
            set(value) {
                this.$emit('input', value);
            },
            get() {
                return this.value;
            },
        },
        colorErrors() {
            const colorErrors = [];
            if (this.compValue && this.compValue.trim().length) {
                const testValue = this.compValue.trim();
                if (!/^#[0-9A-F]{6}$/i.test(testValue)) {
                    colorErrors.push('Enter a valid hex color, or choose one from the palette.');
                }
                if (this.palette && this.palette.length) {
                    if (!this.palette.includes(testValue)) {
                        colorErrors.push('Invalid choice. Choose a color from the palette.');
                    }
                }
            }
            return colorErrors;
        },
        validationErrors() {
            return [...this.colorErrors, ...this.errors];
        },
        sampleColor() {
            if (!this.compValue || !this.compValue.length || this.colorErrors.length) {
                return 'transparent';
            }
            return this.compValue;
        },
    },
    methods: {
        dismissPopup() {
            if (this.$refs.colorPopup) {
                this.$refs.colorPopup.hide();
            }
        },
    },
};
</script>

<style scoped>
.badge-color-sample {
    height: 3rem;
    width: 1.5rem !important;
}
</style>
