<template>
    <PDialog
        v-if="display"
        v-model="display"
        title="Edit Petron"
        @cancel="buildSettings"
        @show="buildSettings"
    >
        <AutoForm
            full-width
            full-height
            dense
            :schema="formSchema"
            :initial-value="formData"
            :value="formData"
            class="q-gutter-y-lg"
            @input="updateData"
            @validate="formValidate"
        />
        <template v-slot:footer>
            <GeneralButton
                v-cypress="'PetronEditDialog_Delete_Button'"
                label="Delete Petron"
                tooltip="Delete this Petron"
                color="error"
                :flat="false"
                @click="deletePetron"
            />
            <GeneralButton
                v-close-popup
                label="Save"
                :tooltip="validState ? 'Save' : 'Not all required fields are populated'"
                color="primary"
                :flat="false"
                :outline="false"
                :disabled="!validState"
                @click="save"
            />
        </template>
    </PDialog>
</template>

<script>
import AutoForm from '../../../plugin-framework/shared-components/auto-form/AutoForm';
import GeneralButton from '../../../plugin-framework/shared-components/inline-elements/GeneralButton.vue';

export default {
    name: 'PetronEditDialog',
    components: {
        AutoForm,
        GeneralButton,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        petron: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            validState: false,
            formData: {},
            allUnits: [],
        };
    },
    computed: {
        display: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
        users() {
            const sortedUsers = this.$users.sortUsersByName(this.$users.activeUsers || []);
            return sortedUsers.map(user => {
                return {
                    ...user,
                    label: user.fullName,
                    value: user.userId,
                };
            });
        },
        formSchema() {
            return {
                inputs: [
                    {
                        inputType: 'text',
                        key: 'name',
                        testId: 'PetronEditDialog_PetronName',
                        label: 'Petron Name',
                        validationRules: [
                            (value) => !!value || 'Name must be populated',
                        ],
                    },
                    {
                        inputType: 'select',
                        key: 'owner',
                        testId: 'PetronEditDialog_SelectOwner_Select',
                        label: 'Assign owner',
                        options: this.users,
                        clearable: false,
                        validationRules: [
                            (value) => !!value || 'Petrons must have an owner',
                        ],
                    },
                    {
                        inputType: 'select',
                        key: 'units',
                        testId: 'PetronEditDialog_SelectUnits',
                        label: 'Select Units',
                        options: this.allUnits,
                        clearable: false,
                        validationRules: [
                            (value) => !!value || 'Petrons must have a units definition',
                        ],
                    },
                    {
                        inputType: 'text',
                        key: 'description',
                        testId: 'PetronEditDialog_Description_Input',
                        label: 'Type a brief description',
                    },
                    {
                        inputType: 'boolean',
                        key: 'isPublic',
                        testId: 'PetronEditDialog_IsPublic_Checkbox',
                        label: 'Make this Petron public, allowing anyone to view its contents',
                    },
                ],
            };
        },
    },
    created() {
        this.buildSettings();
    },
    methods: {
        save() {
            const updates = {};
            if (this.formData.name !== this.petron.name) {
                updates.name = this.formData.name;
            }
            updates.ownerId = this.formData.owner;
            if (this.formData.description !== this.petron.description) {
                updates.description = this.formData.description;
            }
            updates.isPublic = this.formData.isPublic;
            updates.unitsId = this.formData.units;
            this.$api.data.update({
                type: 'Petron',
                query: { id: this.petron.id },
                body: updates,
            }).then(() => {
                this.$emit('updated');
                this.buildSettings();
            }).catch(() => {
                this.buildSettings();
            });
        },
        buildSettings() {
            this.$api.data.query({
                type: 'UnitsDefinition',
                query: {},
            }).then(response => {
                this.allUnits = response.data.map(unit => {
                    return {
                        value: unit.id,
                        label: unit.name,
                    };
                });
            });

            const settings = {
                isPublic: false,
            };
            settings.name = this.petron.name;
            settings.units = this.petron.unitsId;
            if (this.petron.description) {
                settings.description = this.petron.description;
            }
            if (this.petron.isPublic) {
                settings.isPublic = true;
            }
            const owner = this.users.find(user => this.petron.ownerId === user.userId);
            if (owner) {
                settings.owner = owner.userId;
            }
            this.formData = { ...settings };
        },
        updateData(value) {
            this.formData = { ...value };
        },
        formValidate(value) {
            this.validState = value;
        },
        deletePetron() {
            this.$emit('delete');
            this.buildSettings();
        },
    },
};
</script>

<style scoped>

</style>
