<template>
    <q-chip
        :dense="dense"
    >
        <div
            v-if="user"
        >
            <UserProfileImage
                :user="user"
            />
            <UserFullName
                :user="user"
                hide-link
                :font-size="`${dense ? '0.7em' : '1em'}`"
            />
        </div>
        <div
            v-else
            class="q-pa-sm"
        >
            {{ noUserMessage }}
        </div>
    </q-chip>
</template>

<script>
import UserFullName from './UserFullName.vue';

export default {
    name: 'UserChip',
    components: {
        UserFullName,
    },
    props: {
        userId: {
            validator(value) {
                return typeof value === 'string' || value !== 'undefined';
            },
            required: true,
        },
        noUserMessage: {
            type: String,
            required: false,
            default: 'unknown user',
        },
        dense: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        user() {
            return this.$users.getUser(this.userId);
        },
    },
};
</script>

<style scoped>

</style>
