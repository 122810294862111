<template>
    <div
        class="row items-center"
    >
        <q-circular-progress
            :color="isActive ? 'primary' : 'grey-8'"
            :indeterminate="isActive"
            :value="100"
            size="md"
        />
        <q-tooltip
            v-if="status"
        >
            {{ status }}
        </q-tooltip>
    </div>
</template>

<script>
export default {
    name: 'CircularProgressIndicator',
    props: {
        isActive: {
            type: Boolean,
            required: false,
            default: false,
        },
        status: {
            type: String,
            required: false,
            default: '',
        },
    },
};
</script>

<style scoped>

</style>
