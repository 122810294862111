<template>
    <p
        v-if="!hide"
        :class="textClass"
    >
        {{ value }}
    </p>
</template>

<script>
export default {
    name: 'TextSection',
    props: {
        value: {
            type: String,
            required: true,
        },
        hide: {
            type: Boolean,
            required: false,
            default: false,
        },
        textClass: {
            type: String,
            required: false,
            default: null,
        },
    },
};
</script>

<style scoped>

</style>
