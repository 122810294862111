<template>
    <q-markup-table
        dense
        flat
        :separator="separator"
        :wrap-cells="false"
        class="q-pt-none q-px-md"
    >
        <thead v-if="!transpose && !hideHeaders">
            <tr>
                <th
                    v-for="(field, index) in fields"
                    :key="`header-${index}`"
                    :class="field.align ? `text-${field.align}` : 'text-center'"
                >
                    <strong>{{ field.label }}</strong>
                </th>
            </tr>
        </thead>
        <tbody v-if="!transpose">
            <tr
                v-for="(doc, dataIndex) in data"
                :key="`row-${dataIndex}`"
            >
                <td
                    v-for="(field, fieldIndex) in fields"
                    :key="`data-${dataIndex}-${fieldIndex}`"
                    :class="field.align ? `text-${field.align}` : 'text-center'"
                >
                    {{ typeof field.field === 'string' ? doc[field.field] : field.field(doc) }}
                </td>
            </tr>
        </tbody>
        <tbody v-if="transpose">
            <tr
                v-for="(field, fieldIndex) in fields"
                :key="`row-${fieldIndex}`"
            >
                <td
                    v-if="!hideHeaders"
                    :class="field.align ? `text-${field.align}` : 'text-left'"
                >
                    <strong>{{ field.label }}</strong>
                </td>
                <td
                    v-for="(doc, dataIndex) in data"
                    :key="`data-${dataIndex}-${fieldIndex}`"
                    :class="field.align ? `text-${field.align}` : 'text-center'"
                >
                    {{ typeof field.field === 'string' ? doc[field.field] : field.field(doc) }}
                </td>
            </tr>
        </tbody>
    </q-markup-table>
</template>

<script>
export default {
    name: 'StaticTableVisualization',
    props: {
        // Array of objects with field, label and align keys
        // NOTE: field can be string or function to resolve value
        fields: {
            type: Array,
            required: true,
        },
        data: {
            type: Array,
            required: true,
        },
        hideHeaders: {
            type: Boolean,
            required: false,
            default: false,
        },
        transpose: {
            type: Boolean,
            required: false,
            default: false,
        },
        separator: {
            type: String,
            required: false,
            default: 'none',
        },
    },
};
</script>

<style scoped>

</style>
