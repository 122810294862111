<template>
    <button
        class="editor-menu-btn"
        type="button"
        :class="{ 'is-active': active }"
        @click="$emit('click')"
    >
        <q-icon :name="icon"/>
    </button>
</template>

<script>
export default {
    name: 'EditorMenuBtn',
    props: {
        active: {
            type: Boolean,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped lang="scss">
.editor-menu-btn {
    display: inline-flex;
    background: transparent;
    border: 0;
    color: white;
    padding: 0.2rem 0.5rem;
    margin-right: 0.2rem;
    border-radius: 0px;
    cursor: pointer;

    &:last-child {
        margin-right: 0;
    }

    &:hover {
        background-color: rgba(white, 0.25);
    }

    &.is-active {
        background-color: rgba(white, 0.5);
    }
}
</style>
