<template>
    <div class="calculated-full-height">
        <div class="row full-height relative-position">
            <iframe
                v-if="analysisUrl"
                :id="iframeId"
                :ref="iframeId"
                :src="analysisUrl"
                class="spotfire-web-player__iframe col"
                @load="onLoad"
                @error="onError"
            />

            <q-inner-loading :showing="iframeHasError">
                <div class="row text-error text-h3">
                    <q-icon name="clear"/>
                </div>
                <div class="row">
                    Unable to load
                </div>
            </q-inner-loading>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SpotfireWebPlayer',
    props: {
        spotfireURL: {
            validator(value) {
                return typeof value === 'string' || value === null;
            },
            required: true,
        },
        spotfireAnalysisPath: {
            validator(value) {
                return typeof value === 'string' || value === null;
            },
            required: true,
        },
        showUndoRedo: {
            type: Boolean,
            required: false,
            default: false,
        },
        showAnalysisInformation: {
            type: Boolean,
            required: false,
            default: false,
        },
        showDownloadDXPFile: {
            type: Boolean,
            required: false,
            default: false,
        },
        showHelp: {
            type: Boolean,
            required: false,
            default: false,
        },
        showAbout: {
            type: Boolean,
            required: false,
            default: false,
        },
        showClose: {
            type: Boolean,
            required: false,
            default: false,
        },
        showLogOut: {
            type: Boolean,
            required: false,
            default: false,
        },
        showHeader: {
            type: Boolean,
            required: false,
            default: false,
        },
        showStatusBar: {
            type: Boolean,
            required: false,
            default: false,
        },
        showToolbar: {
            type: Boolean,
            required: false,
            default: false,
        },
        showPageNavigation: {
            type: Boolean,
            required: false,
            default: false,
        },
        showFiltersPanel: {
            type: Boolean,
            required: false,
            default: false,
        },
        showDetailsOnDemand: {
            type: Boolean,
            required: false,
            default: false,
        },
        showEditButton: {
            type: Boolean,
            required: false,
            default: false,
        },
        showCollaboration: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            iframeId: 'spotfire-web-player-iframe',
            iframeLoading: true,
            iframeHasError: false,
        };
    },
    computed: {
        analysisUrl() {
            if (this.spotfireURL && this.spotfireAnalysisPath) {
                // Options query param sets the visible spotfire UI in iframe
                return `${this.spotfireURL}/spotfire/wp/OpenAnalysis?file=${this.spotfireAnalysisPath}&options=${this.viewOptions}`;
            }
            return null;
        },
        viewOptions() {
            const showUndoRedo = this.showUndoRedo ? '1' : '0';
            const showAnalysisInformation = this.showAnalysisInformation ? '1' : '0';
            const showDownloadDXPFile = this.showDownloadDXPFile ? '1' : '0';
            const showHelp = this.showHelp ? '1' : '0';
            const showAbout = this.showAbout ? '1' : '0';
            const showClose = this.showClose ? '1' : '0';
            const showLogOut = this.showLogOut ? '1' : '0';
            const showHeader = this.showHeader ? '1' : '0';
            const showStatusBar = this.showStatusBar ? '1' : '0';
            const showToolbar = this.showToolbar ? '1' : '0';
            const showPageNavigation = this.showPageNavigation ? '1' : '0';
            const showFiltersPanel = this.showFiltersPanel ? '1' : '0';
            const showDetailsOnDemand = this.showDetailsOnDemand ? '1' : '0';
            const showEditButton = this.showEditButton ? '1' : '0';
            const showCollaboration = this.showCollaboration ? '1' : '0';

            return `7-${showUndoRedo},9-${showAnalysisInformation},10-${showDownloadDXPFile},11-${showHelp},12-${showAbout},13-${showClose},14-${showLogOut},1-${showHeader},2-${showStatusBar},3-${showToolbar},4-${showPageNavigation},5-${showFiltersPanel},6-${showDetailsOnDemand},15-${showEditButton},17-${showCollaboration}`;
        },
    },
    methods: {
        onLoad(event) {
            this.iframeLoading = false;
        },
        onError(event) {
            this.iframeHasError = true;
            this.$notify.error('There was an error loading the spotfire web player.');
        },
    },
};
</script>

<style scoped>
.calculated-full-height {
    /* height of chart toolbar */
    height: calc(100% - 3rem);
}

.spotfire-web-player__iframe {
    border: 0;
}

.dots-container {
  width: 142px;
  height: 32px;
  padding-top: 8px;
  background: white;
  filter: contrast(20);
}

.dots-container .dot {
    position: absolute;
    width: 16px;
    height: 16px;
    left: 16px;
    filter: blur(4px);
    background: #000;
    border-radius: 50%;
    animation: dot 2.8s infinite;
}

.dots-container .dots {
    position: absolute;
    margin-left: 31px;
    animation: dots 2.8s infinite;
}

.dots-container .dots span {
    display: block;
    float: left;
    width: 16px;
    height: 16px;
    margin-left: 16px;
    filter: blur(4px);
    background: #000;
    border-radius: 50%;
}

@keyframes dot {
    50% {
        transform: translateX(96px);
    }
}

@keyframes dots {
    50% {
        transform: translateX(-31px);
    }
}
</style>
