import ApplicationLayout from '../../layouts/ApplicationLayout.vue';
import DefaultPageLayout from '../../layouts/DefaultPageLayout.vue';
import TheServicesPage from './components/TheServicesPage.vue';
import { PetroPlusGlobalAppCard } from './fragments/petro-plus-global-app-card.js';
import { PetroPlusRoute } from './fragments/petro-plus-route.js';
import { ROUTES } from './constants.js';
import { PetroPlusMainMenuItem } from './fragments/petro-plus-main-menu-item.js';

export const PetroPlus = {
    name: 'PetroPlus',
    views: {
        ApplicationLayout,
        DefaultPageLayout,
        TheServicesPage,
    },
    definition({ app, plugin }) {
        return {
            modules: [
                PetroPlusGlobalAppCard({ app, plugin }),
                PetroPlusRoute({ app, plugin }),
                PetroPlusMainMenuItem({ app, plugin }),
            ],
            model: {
                getters: {
                    petroPlusRouteActive: (state, getters, rootState) => rootState.route.name === ROUTES.PetroPlus,
                },
            },
        };
    },
};
