<template>
    <q-list v-if="appSettingsFragments.length">
        <AppSettingsItem
            v-for="(appSettingsFragment, index) in appSettingsFragments"
            :key="`app-${index}`"
            v-bind="appSettingsFragment.props"
            v-on="appSettingsFragment.events"
        />
    </q-list>
</template>

<script>
import AppSettingsItem from './AppSettingsItem.vue';

export default {
    name: 'AppSettingsList',
    components: {
        AppSettingsItem,
    },
    props: {
        appSettingsFragments: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style scoped>

</style>
