import Vue from 'vue';

Vue.filter('capitalize', value => {
    if (value !== '') {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }
});
Vue.filter('spaces', value => {
    if (value !== null) {
        return value.replace(/([a-z])([A-Z])/g, '$1 $2');
    }
});
