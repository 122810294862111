<template>
    <q-card class="bg-grey-2 fit">
        <PRouterLink
            :router-to="routeObject"
        >
            <q-card-section
                horizontal
            >
                <q-img
                    v-if="dashboard.thumbnailUrl"
                    class="col-4"
                    height="90.5px"
                    :src="dashboard.thumbnailUrl"
                />
                <q-card-section class="column">
                    <div class="text-primary text-bold">
                        {{ dashboard.name }}
                    </div>
                    <div class="text-grey-8 ellipsis-2-lines">
                        {{ dashboard.description }}
                    </div>
                </q-card-section>
            </q-card-section>
        </PRouterLink>
    </q-card>
</template>

<script>
import PRouterLink from '../../plugin-framework/services/pai-components/PRouterLink.vue';

export default {
    name: 'DashboardCard',
    components: {
        PRouterLink,
    },
    props: {
        dashboard: {
            type: Object,
            required: true,
        },
    },
    computed: {
        routeObject() {
            if (this.dashboard) {
                return {
                    name: 'dsu-design-project-viewer',
                    params: {
                        petronId: this.dashboard.pid,
                        dashboardId: this.dashboard.id,
                    },
                };
            }

            return {};
        },
    },
};
</script>

<style scoped>

</style>
