import moment from 'moment-timezone';

export function SettingsModel({ app, plugin }) {
    return {
        fields: {
            allUnitDefinitions: {
                source: 'forge',
                options: {
                    workspace: 'unitWorkspace',
                    resource: 'AllUnitDefinitions',
                },
            },
            disableEdits: {
                source: 'self',
                options: {
                    default: true,
                },
            },
            globalSettingsKeys: {
                source: 'self',
                options: {
                    default: [
                        'orgName',
                        'databaseUnitsDefinitionId',
                        'reportingUnitsDefinitionId',
                        'displayTimeZoneId',
                    ],
                },
            },
            globalSettingsForm: {
                source: 'self',
                options: {
                    default: {},
                },
            },
        },
        getters: {
            unitWorkspace: () => `$root/UnitDefinitions/${app.$auth.user.userId}`,
            forgeResources: (state, getters) => {
                const platformResources = [
                    getters.allUnitDefinitionsResource,
                ];
                const appResources = [];
                getters.pluginAppSettings.forEach(appSettingsDefinition => {
                    if (appSettingsDefinition.forgeResources) {
                        appResources.push(...appSettingsDefinition.forgeResources);
                    }
                });
                return [...platformResources, ...appResources];
            },
            pluginAppSettings: (state, getters) => {
                const frags = [...app.$plugins.getFragments('app-settings')].filter(fragment => fragment.props.title);
                frags.sort((a, b) => a.props.title.localeCompare(b.props.title));
                return frags;
            },
            canEditSettings: () => app.$auth.userIsAdmin,
            timeZoneOptions: () => {
                const timeZoneNames = moment.tz.names();
                const timeZoneOptions = timeZoneNames.map(zoneName => {
                    const offset = moment.tz(zoneName).format('Z');
                    const abbreviatedZoneName = moment.tz(zoneName).format('z');
                    return {
                        label: `${zoneName} - ${abbreviatedZoneName} (UTC${offset})`,
                        value: zoneName,
                    };
                });
                return timeZoneOptions;
            },
            unitsDefinitions: (state, getters) => {
                if (getters.allUnitDefinitions) {
                    return getters.allUnitDefinitions.map(unitsDefinition => {
                        return { label: unitsDefinition.name, value: unitsDefinition.id };
                    });
                }
                return [];
            },
        },
        actions: {
            editUpdate({ state, getters, dispatch }) {
                if (state.disableEdits) {
                    state.disableEdits = false;
                    return;
                }
                const newSettings = {};
                state.globalSettingsKeys.forEach(key => {
                    newSettings[key] = state.globalSettingsForm[key];
                });
                app.$globalSettings.updateSettings(newSettings).then(() => {
                    state.disableEdits = true;
                });
            },
            cancelEdit({ state, getters }) {
                const currentGlobalSettings = app.$globalSettings.settings;
                state.globalSettingsKeys.forEach(key => {
                    state.globalSettingsForm[key] = currentGlobalSettings[key];
                });
                state.disableEdits = true;
            },
        },
    };
}
