<template>
    <q-field
        v-cypress="testId"
        :label="label"
        :error="!!errors.length"
        :hint="hint"
        stack-label
        borderless
        bottom-slots
        :dark="dark"
    >
        <template v-slot:control>
            <CodeEditor
                ref="editor"
                v-model="code"
                :options="editorOptions"
                :lang="lang"
                theme="github"
                :class="{ 'q-mt-sm': label }"
                @init="editorInit"
            />
        </template>
        <template v-slot:error>
            <AutoFormValidationErrors :errors="combinedErrors"/>
        </template>
    </q-field>
</template>

<script>
import CodeEditor from 'vue2-ace-editor';
import AutoFormValidationErrors from './AutoFormValidationErrors.vue';
// Load editor modes
import 'brace/mode/csharp.js';
import 'brace/mode/css.js';
import 'brace/mode/dockerfile.js';
import 'brace/mode/gitignore.js';
import 'brace/mode/handlebars.js';
import 'brace/mode/html.js';
import 'brace/mode/java.js';
import 'brace/mode/javascript.js';
import 'brace/mode/json.js';
import 'brace/mode/jsx.js';
import 'brace/mode/latex.js';
import 'brace/mode/less.js';
import 'brace/mode/markdown.js';
import 'brace/mode/matlab.js';
import 'brace/mode/mysql.js';
import 'brace/mode/php.js';
import 'brace/mode/powershell.js';
import 'brace/mode/python.js';
import 'brace/mode/r.js';
import 'brace/mode/ruby.js';
import 'brace/mode/sass.js';
import 'brace/mode/scss.js';
import 'brace/mode/sql.js';
import 'brace/mode/sqlserver.js';
import 'brace/mode/stylus.js';
import 'brace/mode/svg.js';
import 'brace/mode/text.js';
import 'brace/mode/typescript.js';
import 'brace/mode/vbscript.js';
import 'brace/mode/xml.js';
import 'brace/mode/yaml.js';
// Load editor themes
import 'brace/theme/tomorrow_night_bright.js';
import 'brace/theme/github.js';

export const supportedLanguages = [
    'csharp',
    'css',
    'dockerfile',
    'gitignore',
    'handlebars',
    'html',
    'java',
    'javascript',
    'json',
    'jsx',
    'latex',
    'less',
    'markdown',
    'matlab',
    'mysql',
    'php',
    'powershell',
    'python',
    'r',
    'ruby',
    'sass',
    'scss',
    'sql',
    'sqlserver',
    'stylus',
    'svg',
    'text',
    'typescript',
    'vbscript',
    'xml',
    'yaml',
];

export default {
    name: 'AutoFormCodeInput',
    components: {
        AutoFormValidationErrors,
        CodeEditor,
    },
    props: {
        value: {
            type: String,
            required: false,
            default: null,
        },
        testId: {
            type: String,
            required: false,
            default: null,
        },
        label: {
            type: String,
            required: false,
            default: null,
        },
        hint: {
            type: String,
            required: false,
            default: null,
        },
        minLines: {
            type: Number,
            required: false,
            default: 5,
        },
        maxLines: {
            type: Number,
            required: false,
            default: 10,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false,
        },
        lang: {
            validator: (value) => supportedLanguages.includes(value),
            required: false,
            default: 'text',
        },
        errors: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },
        dark: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        combinedErrors: {
            get() {
                return this.errors;
            },
            set(value) {
                this.$emit('update:errors', value);
            },
        },
        editorOptions() {
            return {
                readOnly: this.disabled || this.readonly,
                minLines: this.minLines,
                maxLines: this.maxLines,
            };
        },
        code: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
    methods: {
        editorInit(editor) {},
    },
};
</script>

<style scoped>

</style>
