<template>
    <PDialog
        v-if="display"
        v-model="display"
        :title="`Add Collaborators to ${petron.name}`"
        @cancel="reset"
    >
        <AutoForm
            full-width
            full-height
            dense
            :schema="formSchema"
            :initial-value="formData"
            :value="formData"
            class="q-gutter-y-lg"
            @input="updateData"
        />
        <template v-slot:footer>
            <GeneralButton
                v-close-popup
                label="Add"
                :tooltip="`Invite ${numberCollaboratorsSelected} ${numberCollaboratorsSelected === 1 ? 'collaborator' : 'collaborators'}`"
                :disabled="numberCollaboratorsSelected < 1"
                :flat="false"
                :outline="false"
                color="accent"
                text-color="primary"
                class="border-primary"
                @click="sendNotifications"
            />
        </template>
    </PDialog>
</template>

<script>
import AutoForm from '../../../plugin-framework/shared-components/auto-form/AutoForm';
import GeneralButton from '../../../plugin-framework/shared-components/inline-elements/GeneralButton.vue';

export default {
    name: 'AddCollaboratorsDialog',
    components: {
        AutoForm,
        GeneralButton,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        petron: {
            type: Object,
            required: true,
        },
        collaborators: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    data() {
        return {
            formData: {},
        };
    },
    computed: {
        display: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
        users() {
            const allUsers = this.$users.activeUsers;
            if (!this.petron || !allUsers) {
                return [];
            }
            const users = allUsers.filter(user => {
                if (user.userId === this.petron.ownerId) {
                    return false;
                }
                return !this.collaborators.includes(user.userId);
            });
            const sortedUsers = this.$users.sortUsersByName(users);
            return sortedUsers.map(user => {
                return {
                    label: user.fullName,
                    value: user.userId,
                };
            });
        },
        formSchema() {
            return {
                inputs: [
                    {
                        inputType: 'select',
                        key: 'addedCollaborators',
                        testId: 'AddCollaboratorsDialog_SelectCollaborators_Select',
                        label: 'Collaborators',
                        hint: 'Add one or more collaborators. Start typing a name in the box above.',
                        options: this.users,
                        multiple: true,
                    },
                    {
                        inputType: 'textarea',
                        key: 'message',
                        testId: 'AddCollaboratorsDialog_Comment_CodeInput',
                        label: 'Comment',
                        hint: 'Type comment to include with notifications (optional.)',
                    },
                ],
            };
        },
        numberCollaboratorsSelected() {
            if (this.formData && this.formData.addedCollaborators) {
                return this.formData.addedCollaborators.length;
            }
            return 0;
        },
    },
    methods: {
        updateData(value) {
            this.formData = { ...value };
        },
        reset() {
            this.formData = {};
        },
        sendNotifications() {
            this.$api.tasks.runTask({
                runInBackground: false,
                taskType: 'AddCollaborator',
                options: {
                    petronId: this.petron.id,
                    collaborators: [...this.formData.addedCollaborators],
                    message: this.formData.message || '',
                },
            })
            .catch((issue) => {
                this.$logging.loggers.PluginFramework.error(`Could not add collaborators to Petron ${this.petron.id}: ${issue}`);
                this.$notify.error('Unable to add collaborators.');
            });
            this.reset();
        },
    },
};
</script>

<style scoped lang="stylus">
@import '../../../css/quasar.variables.styl';
.border-primary {
    border: solid 1px $primary;
    border-radius: 2px;
}
</style>
