import { render, staticRenderFns } from "./PCard.vue?vue&type=template&id=ddde758e&scoped=true&"
import script from "./PCard.vue?vue&type=script&lang=js&"
export * from "./PCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ddde758e",
  null
  
)

export default component.exports