<template>
    <div class="col">
        <div
            class="row items-center"
        >
            <div
                v-if="isNumber"
                class="col"
            >
                <AutoFormInput
                    input-type="range"
                    :value="[rangeLow, rangeHigh]"
                    :label="$utils.formatting.startCase(indexName)"
                    :range-limits="rangeMinimumMaximum"
                    :is-integer="isInteger"
                    :debounce="750"
                    clearable
                    :dark="dark"
                    @input="updateRangeValues"
                />
            </div>
            <div
                v-else-if="isDateTime"
                class="col"
            >
                <AutoFormInput
                    input-type="date-time-range"
                    :value="[rangeLow, rangeHigh]"
                    :label="$utils.formatting.startCase(indexName)"
                    :dark="dark"
                    @input="updateRangeValues"
                />
            </div>
            <div
                v-else
                class="col"
            >
                <AutoFormInput
                    :value="chosenValues"
                    :disabled="!selectOptions || !selectOptions.length"
                    input-type="select"
                    :options="selectOptions"
                    :hint="`${selectOptions.length ? '' : 'No values available for filter'}`"
                    :label="$utils.formatting.startCase(indexName)"
                    option-label="label"
                    dense
                    options-dense
                    multiple
                    clearable
                    :dark="dark"
                    test-id="ForgeFilterWidget_Input_Select"
                    @input="updateSelectValues"
                />
            </div>
        </div>
    </div>
</template>

<script>
import AutoFormInput from '../shared-components/auto-form/AutoFormInput.vue';

export default {
    name: 'ForgeFilterWidget',
    components: {
        AutoFormInput,
    },
    props: {
        workspaceName: {
            type: String,
            required: true,
        },
        collectionName: {
            type: String,
            required: true,
        },
        filterName: {
            type: String,
            required: true,
        },
        indexData: {
            type: Object,
            required: true,
        },
        events: {
            type: Object,
            required: false,
            default() {
                return {};
            },
        },
        dark: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            uniqueValues: [],
        };
    },
    computed: {
        indexDefinition() {
            return this.indexData.index;
        },
        setFilterParameters() {
            if (!this.indexData.filter) {
                return {};
            }
            let action = 'SetFilter';
            const filterQuery = {
                schema: this.indexName,
            };
            if (!this.isNumber && !this.isDateTime && this.indexData.filter.values.length === 0) {
                // This is a special case. Setting to an empty array would filter everything out.
                // We have to actually clear it.
                action = 'ClearFilter';
            }
            else {
                filterQuery.includeInvalid = this.indexData.filter ? this.indexData.filter.includeInvalid : false;
                if (this.indexData.filter.values) {
                    filterQuery.values = [...this.indexData.filter.values];
                }
                else {
                    filterQuery.range = [this.indexData.filter.low, this.indexData.filter.high];
                }
            }
            return {
                action,
                options: {
                    workspace: this.workspaceName,
                    name: this.filterName,
                    collection: this.collectionName,
                    filterQuery: JSON.stringify([filterQuery]),
                },
            };
        },
        indexName() {
            return this.indexData.name;
        },
        valueType() {
            if (this.indexData.index) {
                return this.indexData.index.valueType;
            }
            return 'double';
        },
        isNumber() {
            return this.isDouble || this.isInteger;
        },
        isDouble() {
            return this.valueType === 'double';
        },
        isInteger() {
            return this.valueType === 'int';
        },
        isDateTime() {
            return this.valueType === 'datetime';
        },
        isUser() {
            if (this.indexData.index) {
                const metadata = this.indexData.index.metadata;
                return metadata && metadata.includes('type:user');
            }
            return false;
        },
        rangeLow: {
            get() {
                return this.indexData.filter && this.indexData.filterSet ? this.indexData.filter.low : this.rangeMinimum;
            },
            set(value) {
                if (!this.indexData.filter) {
                    this.createDefaultFilter();
                }
                let newValue = value;
                if (this.isNumber) {
                    newValue = Math.max(value, this.rangeMinimum);
                    if (this.isInteger) {
                        newValue = Math.round(newValue);
                    }
                }
                this.indexData.filter.low = newValue;
            },
        },
        rangeHigh: {
            get() {
                return this.indexData.filter && this.indexData.filterSet ? this.indexData.filter.high : this.rangeMaximum;
            },
            set(value) {
                if (!this.indexData.filter) {
                    this.createDefaultFilter();
                }
                let newValue = value;
                if (this.isNumber) {
                    newValue = Math.min(value, this.rangeMaximum);
                    if (this.isInteger) {
                        newValue = Math.round(newValue);
                    }
                }
                this.indexData.filter.high = newValue;
            },
        },
        rangeMinimum() {
            if (this.isDouble) {
                return this.roundNumberDown(this.indexDefinition.low);
            }
            return this.indexDefinition.low;
        },
        rangeMaximum() {
            if (this.isDouble) {
                return this.roundNumberUp(this.indexDefinition.high);
            }
            return this.indexDefinition.high;
        },
        rangeMinimumMaximum() {
            return [this.rangeMinimum, this.rangeMaximum];
        },
        chosenValues: {
            get() {
                return this.indexData.filterSet && this.indexData.filter ? this.indexData.filter.values : [];
            },
            set(newValues) {
                if (!this.indexData.filter) {
                    this.createDefaultFilter();
                }
                const originalChoices = [...this.chosenValues];
                this.indexData.filter.values = newValues;
                this.saveFilter(() => {
                    this.indexData.filter.values = originalChoices;
                });
            },
        },
        selectOptions() {
            let options = this.uniqueValues;
            if (this.isUser) {
                options = this.uniqueValues.map(value => {
                    return {
                        value: value.value,
                        label: this.getUserName(value.value),
                    };
                });
            }
            options.sort((a, b) => a.label.localeCompare(b.label));
            return options;
        },
    },
    watch: {
        indexData(newVal, oldVal) {
            if (newVal && oldVal) {
                if (oldVal.name !== newVal.name) {
                    // This widget has been reassigned to a new index.
                    this.loadValues();
                }
                else if (oldVal.index && newVal.index && oldVal.index.rowCount !== newVal.index.rowCount) {
                    // The number of rows in the index has changed; refresh unique values.
                    this.loadValues();
                }
            }
        },
    },
    mounted() {
        this.loadValues();
    },
    methods: {
        roundNumberDown(rawValue) {
            return this.$utils.formatting.roundNumber(rawValue, { roundUp: false });
        },
        roundNumberUp(rawValue) {
            return this.$utils.formatting.roundNumber(rawValue, { roundUp: true });
        },
        createDefaultFilter() {
            this.indexData.filter = {
                name: this.indexName,
                includeInvalid: false,
            };
        },
        updateRangeValues(newValue) {
            if (newValue && (newValue.length > 1)) {
                const originalLow = this.rangeLow;
                const originalHigh = this.rangeHigh;
                this.rangeLow = this.isDouble ? this.roundNumberDown(newValue[0]) : newValue[0];
                this.rangeHigh = this.isDouble ? this.roundNumberUp(newValue[1]) : newValue[1];
                this.saveFilter(() => {
                    this.rangeLow = originalLow;
                    this.rangeHigh = originalHigh;
                });
            }
        },
        updateSelectValues(newValue) {
            this.chosenValues = newValue;
        },
        loadValues() {
            if (this.isNumber || this.isDateTime) {
                return;
            }
            this.$api.forge.runAction({
                action: 'GetUniqueIndexValues',
                options: {
                    workspace: this.workspaceName,
                    name: this.collectionName,
                    indexName: this.indexName,
                    limit: 50000,
                },
            })
            .then(response => {
                const info = response.data;
                this.uniqueValues = info.map(inf => {
                    const value = inf.value.toString();
                    return {
                        label: value,
                        value,
                    };
                });
            })
            .catch(issue => {
                console.error(issue);
                this.$notify.error(`There was a problem retrieving the ${this.indexName} filter parameters.`);
            });
        },
        saveFilter(onReset) {
            const params = this.setFilterParameters;
            const beforeEvent = this.events.beforeSetFilter;
            if (beforeEvent) {
                if (beforeEvent(params.options) === false) {
                    if (onReset) {
                        onReset();
                    }
                    return;
                }
            }
            this.$api.forge.runAction(params)
            .then(() => {
                if (this.events.afterSetFilter) {
                    this.events.afterSetFilter(params.options);
                }
            })
            .catch(issue => {
                console.error(issue);
                this.$notify.error(`There was a problem setting the ${this.filterName} filter parameters.`);
            });
        },
        getUserName(userId) {
            const name = this.$users.getUserFullName(userId);
            return name.length ? name : `unknown [${userId}]`;
        },
    },
};
</script>

<style scoped>

</style>
