<template>
    <q-intersection
        v-if="showVisualization"
        once
        class="full-height full-width intersection-observer"
    >
        <q-toolbar
            v-if="showToolbar"
            class="q-px-md q-pt-sm text-primary"
        >
            <div
                v-if="title"
                class="col-grow ellipsis"
            >
                {{ title }}
                <q-tooltip :delay="750">
                    {{ title }}
                </q-tooltip>
            </div>
            <q-tabs
                v-if="visualization.type === 'tabbed' && visualization.props"
                :value="visualization.currentTab"
                shrink
                stretch
                dense
                narrow-indicator
                no-caps
                class="q-pl-none"
                @input="visualization.events.tabUpdated"
            >
                <q-tab
                    v-for="(tab, index) in visualization.props.tabs"
                    :key="index"
                    :name="tab.tabValue"
                    :label="tab.tabLabel"
                    class="q-pa-none"
                    style="height: 1.5rem; min-height: 1.5rem"
                />
            </q-tabs>
            <div class="col col-shrink">
                <q-btn
                    v-if="visualization.actions && visualization.actions.length"
                    flat
                    dense
                    icon="more_vert"
                >
                    <q-menu
                        anchor="bottom right"
                        self="top right"
                    >
                        <q-item
                            v-for="(action, index) in visualization.actions"
                            :key="index"
                            v-close-popup
                            clickable
                            dense
                            v-on="action.events"
                        >
                            <q-item-section>{{ action.props.label }}</q-item-section>
                        </q-item>
                    </q-menu>
                </q-btn>
            </div>
        </q-toolbar>

        <div
            v-if="visualization.type === 'tabbed' && visualization.props"
            class="full-width calculated-full-height"
        >
            <q-tab-panels
                :value="visualization.currentTab"
                class="full-width full-height"
                keep-alive
                @input="visualization.events.tabUpdated"
            >
                <q-tab-panel
                    v-for="(tab, index) in visualization.props.tabs"
                    :key="index"
                    :name="tab.tabValue"
                    class="q-pa-none"
                >
                    <Visualization
                        class="full-width full-height overflow-auto"
                        :visualization-definition="tab.visualization"
                    />
                </q-tab-panel>
            </q-tab-panels>
        </div>

        <Visualization
            v-if="visualization.type !== 'tabbed' && visualization.props"
            id="VisualizationWrapper"
            class="full-width"
            :class="{ 'full-height': !showToolbar, 'calculated-full-height': showToolbar }"
            :visualization-definition="visualization"
        />
    </q-intersection>
</template>

<script>
import Visualization from './Visualization.vue';

export default {
    name: 'VisualizationContainer',
    components: {
        Visualization,
    },
    props: {
        title: {
            type: String,
            required: false,
            default: null,
        },
        visualization: {
            type: Object,
            required: true,
        },
    },
    computed: {
        showToolbar() {
            return this.title || this.visualization.type === 'tabbed' || (this.visualization.actions && this.visualization.actions.length);
        },
        showVisualization() {
            if (this.visualization && this.visualization.props) {
                return !this.visualization.props.hide;
            }
            return true;
        },
        visualizationStyle() {
            // Allow visualization to grow full height if no toolbar
            if (this.showToolbar) {
                // Toolbar is 3rem high
                return { height: 'calc(100% - 3rem)' };
            }
            return { height: '100%' };
        },
    },
    methods: {
        testEvent(val) {

        },
    },
};
</script>

<style scoped>
.calculated-full-height {
    /* height of toolbar */
    height: calc(100% - 3rem);
}
</style>

<style>
.intersection-observer > div {
    height: 100%;
    width: 100%;
}
</style>
