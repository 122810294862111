<template>
    <div>
        <q-card
            class="no-shadow"
            :class="cardClasses"
        >
            <q-card-section class="q-pa-none">
                <q-item
                    :v-ripple="!disabled"
                    :clickable="!disabled"
                    @click="itemClicked"
                >
                    <q-item-section avatar>
                        <LetterAvatar
                            v-if="isCreateAction"
                            :label="title"
                            font-size="2rem"
                            :class="{'bg-secondary': !disabled, 'bg-grey-4': disabled}"
                        />
                        <q-icon
                            v-else
                            :name="icon"
                        />
                    </q-item-section>

                    <q-item-section>
                        <q-item-label lines="1">
                            {{ title }}
                        </q-item-label>
                    </q-item-section>
                </q-item>

                <q-tooltip
                    v-if="tooltip"
                    :delay="750"
                >
                    {{ tooltip }}
                </q-tooltip>
            </q-card-section>
        </q-card>
    </div>
</template>

<script>
import LetterAvatar from '../../../plugin-framework/shared-components/LetterAvatar.vue';

export default {
    name: 'ActionCard',
    components: {
        LetterAvatar,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: false,
            default: null,
        },
        tags: {
            type: Array,
            required: false,
            default: () => [],
        },
        icon: {
            type: String,
            required: false,
            default: null,
        },
        selected: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        tooltip: {
            type: String,
            required: false,
            default: null,
        },
        isCreateAction: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        cardClasses() {
            return {
                'text-grey-8': !this.disabled && !this.isCreateAction,
                'text-secondary': !this.disabled && this.isCreateAction,
                'text-grey-4': this.disabled,
                'bg-grey-4': !this.disabled && this.selected,
                'border-grey-8': !this.disabled && !this.isCreateAction,
                'border-secondary': !this.disabled && this.isCreateAction,
                'border-grey-4': this.disabled,
                'bg-grey-2': !this.disabled,
            };
        },
    },
    methods: {
        itemClicked() {
            if (!this.disabled) {
                this.$emit('click');
            }
        },
    },
};
</script>

<style scoped lang="stylus">
@import '../../../css/quasar.variables.styl';
.border-grey-8 {
    border: solid 1px $grey-8;
}
.border-grey-4 {
    border: solid 1px $grey-4;
}
.border-secondary {
    border: solid 1px $secondary;
}
.q-item__section--avatar {
    min-width: 0px;
    padding-right: 10px;
}
</style>
