import ComputeStore from './compute-store.js';
import { ComputeService } from './compute-service';
import ForgeLineChart from './components/ForgeLineChart.vue';
import ForgeCanvas from './components/ForgeCanvas.vue';
import ForgePerformanceMonitor from './components/ForgePerformanceMonitor.vue';

const ComputePlugin = {
    install(Vue, {
        store = null,
        moduleName = 'compute',
        url = '/',
        reconnectWaitTimesSec = [0, 1, 1, 2, 3, 5],
        reconnectBlockUITimeoutSec = 4,
    } = {}) {
        if (!store) {
            throw new Error('Compute plugin must be passed a vuex store.');
        }

        if (!Vue.$auth) {
            throw new Error('Compute plugin requires the Auth service.');
        }

        if (!Vue.$notify) {
            throw new Error('Compute plugin requires the Notify service.');
        }

        if (!Vue.$api) {
            throw new Error('Compute plugin requires the API service.');
        }

        store.registerModule(moduleName, ComputeStore);
        const computeService = new ComputeService({
            store,
            moduleName,
            url,
            reconnectWaitTimesSec,
            reconnectBlockUITimeoutSec,
        });

        // register the compute service on the vue instance
        Vue.$compute = computeService;
        Vue.prototype.$compute = computeService;

        // Register components
        Vue.component('ForgeLineChart', ForgeLineChart);
        Vue.component('ForgeCanvas', ForgeCanvas);
        Vue.component('ForgePerformanceMonitor', ForgePerformanceMonitor);
    },
};

export default ComputePlugin;
