export class ApiControllerData {
    constructor({ axiosInstance, route } = {}) {
        // Configured base axios instance
        this.axiosInstance = axiosInstance;

        // Route appended to the axios instance baseURL
        this.route = route;
    }

    /**
     * Performs a query API call.
     * @param type - Petro Datatype
     * @param query - Query object (find will return all matching docs)
     * @param options - Find options (limit, skip, etc.)
     * @returns {*}
     */
    query({ type, query = null, options = null } = {}) {
        return this.axiosInstance.post(`${this.route}/query`, {
            type,
            query,
            options,
        });
    }

    /**
     * Performs an insert API call
     * @param type - Petro Datatype
     * @param data - Array of documents to insert
     * @returns {*}
     */
    insert({ type, data }) {
        return this.axiosInstance.post(this.route, {
            type,
            data,
        });
    }

    /**
     * Performs an update API call
     * @param type - Petro Datatype
     * @param body - Object containing document fields to be updated and their values.
     * @param query - Query object (update will apply to all matching docs)
     */
    update({ type, body, query = null } = {}) {
        return this.axiosInstance.patch(this.route, {
            type,
            query,
            body,
        });
    }

    /**
     * Performs an upsert API call.
     * @param type - Petro Datatype
     * @param data - Array containing documents to be upserted.
     */
    upsert({ type, data } = {}) {
        return this.axiosInstance.put(this.route, {
            type,
            data,
        });
    }

    /**
     * Performs a delete API call.
     * @param type - Petro Datatype
     * @param query - Query object (delete will apply to all matching docs)
     * @returns {*}
     */
    delete({ type, query = null } = {}) {
        return this.axiosInstance.delete(this.route, {
            data: {
                type,
                query,
            },
        });
    }
}
