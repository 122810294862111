<template>
    <div
        v-if="!hide"
        class="column q-py-sm"
        :style="styleHeight"
    >
        <div
            class="col-auto row items-center no-wrap"
        >
            <span class="q-pr-sm">Total {{ itemName }}: {{ items.length }}</span>
            <InlineElement
                v-for="(element, idx) in headerInlineElements"
                :key="`inline-${idx}`"
                :element="element"
            />
        </div>
        <q-scroll-area
            class="col"
        >
            <q-list
                v-if="mappedItems.length > 0"
                bordered
                dense
            >
                <q-item
                    v-for="(item, idx) in mappedItems"
                    :key="`item-${idx}`"
                    v-ripple
                    clickable
                    @click="$emit('rowClicked', item.item)"
                >
                    <q-item-section>
                        <q-item-label>{{ item.label }}</q-item-label>
                        <q-item-label
                            v-if="item.value"
                            caption
                        >
                            {{ item.value }}
                        </q-item-label>
                    </q-item-section>
                    <q-item-section side>
                        <q-icon
                            clickable
                            :name="icon"
                            @click.stop="$emit('iconClicked', item.item)"
                        />
                    </q-item-section>
                </q-item>
            </q-list>
            <div v-else>
                <em>{{ emptyMessage }}</em>
            </div>
        </q-scroll-area>
    </div>
</template>

<script>
import InlineElement from '../inline-elements/InlineElement.vue';

export default {
    name: 'ItemListSection',
    components: {
        InlineElement,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        itemName: {
            type: String,
            required: false,
            default: 'Items',
        },
        fieldLabel: {
            type: String,
            required: false,
            default: 'label',
        },
        fieldValue: {
            type: String,
            required: false,
            default: null,
        },
        height: {
            type: String,
            required: false,
            default: '400px',
        },
        icon: {
            type: String,
            required: false,
            default: 'launch',
        },
        headerInlineElements: {
            type: Array,
            required: false,
            default: () => [],
        },
        emptyMessage: {
            type: String,
            required: false,
            default: '',
        },
        sortItems: {
            type: Boolean,
            required: false,
            default: true,
        },
        hide: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        styleHeight() {
            return `height: ${this.height}`;
        },
        mappedItems() {
            const sortedItems = [...this.items];
            if (this.sortItems) {
                sortedItems.sort((a, b) => {
                    if (this.fieldValue) {
                        return a[this.fieldValue] - b[this.fieldValue];
                    }
                    return a[this.fieldLabel].toLowerCase().localeCompare(b[this.fieldLabel].toLowerCase());
                });
            }
            return sortedItems.map(baseItem => {
                const value = this.fieldValue && baseItem[this.fieldValue] ? Number(baseItem[this.fieldValue]).toLocaleString() : undefined;
                return {
                    label: baseItem[this.fieldLabel],
                    value,
                    item: baseItem,
                };
            });
        },
    },
};
</script>

<style scoped>

</style>
