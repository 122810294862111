import {
    Quasar,
    QInfiniteScroll,
    QIntersection,
    QAvatar,
    QExpansionItem,
    QBar,
    QCard,
    QCardActions,
    QCardSection,
    QLayout,
    QFab,
    QFabAction,
    QTab,
    QTabs,
    QTabPanel,
    QTabPanels,
    QDate,
    QPopupProxy,
    QRouteTab,
    QHeader,
    QFooter,
    QDrawer,
    QPageContainer,
    QPage,
    QToolbar,
    QToolbarTitle,
    QTooltip,
    QTimeline,
    QTimelineEntry,
    QBtn,
    QIcon,
    QList,
    QItem,
    QItemSection,
    QItemLabel,
    QRadio,
    QSelect,
    QSpace,
    QBadge,
    QRange,
    QInput,
    QOptionGroup,
    QTable,
    QMarkupTable,
    QTd,
    QTr,
    QTh,
    QSeparator,
    QBtnDropdown,
    QScrollArea,
    QCheckbox,
    QDialog,
    QMenu,
    QField,
    QForm,
    ClosePopup,
    Ripple,
    Notify,
    QEditor,
    QToggle,
    QSpinner,
    QSpinnerAudio,
    QSpinnerBall,
    QSpinnerBars,
    QSpinnerGears,
    QSpinnerHourglass,
    QSpinnerIos,
    QSpinnerRings,
    QInnerLoading,
    QStep,
    QStepper,
    QStepperNavigation,
    QPopupEdit,
    QBtnGroup,
    QUploader,
    QTime,
    QBtnToggle,
    QChip,
    QImg,
    QPageSticky,
    QCircularProgress,
    QLinearProgress,
    QSplitter,
    QSlider,
    QVideo,
    QTree,
    QColor,
    QCarousel,
    QCarouselControl,
    QCarouselSlide,
} from 'quasar';
import '../css/quasar.styl';
import 'quasar/dist/quasar.ie.polyfills';
import '@quasar/extras/roboto-font/roboto-font.css';
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css';
import '@quasar/extras/ionicons-v4/ionicons-v4.css';
import '@quasar/extras/mdi-v3/mdi-v3.css';
import '@quasar/extras/eva-icons/eva-icons.css';

export function registerQuasar(vueInstance) {
    vueInstance.use(Quasar, {
        components: {
            QAvatar,
            QInfiniteScroll,
            QIntersection,
            QExpansionItem,
            QBar,
            QTime,
            QCard,
            QCardActions,
            QCardSection,
            QLayout,
            QFab,
            QFabAction,
            QTab,
            QTabs,
            QTabPanel,
            QTabPanels,
            QDate,
            QRouteTab,
            QPopupProxy,
            QBadge,
            QHeader,
            QFooter,
            QDrawer,
            QPageContainer,
            QPage,
            QToolbar,
            QToolbarTitle,
            QTooltip,
            QTimeline,
            QTimelineEntry,
            QBtn,
            QIcon,
            QList,
            QItem,
            QItemSection,
            QItemLabel,
            QRadio,
            QSelect,
            QSpace,
            QRange,
            QInput,
            QOptionGroup,
            QTable,
            QMarkupTable,
            QTd,
            QTr,
            QTh,
            QSeparator,
            QBtnDropdown,
            QCheckbox,
            QDialog,
            QMenu,
            QField,
            QForm,
            QScrollArea,
            QEditor,
            QToggle,
            QSpinner,
            QSpinnerAudio,
            QSpinnerBall,
            QSpinnerBars,
            QSpinnerGears,
            QSpinnerHourglass,
            QSpinnerIos,
            QSpinnerRings,
            QInnerLoading,
            QStep,
            QStepper,
            QStepperNavigation,
            QPopupEdit,
            QBtnGroup,
            QUploader,
            QBtnToggle,
            QChip,
            QImg,
            QPageSticky,
            QCircularProgress,
            QLinearProgress,
            QSplitter,
            QSlider,
            QVideo,
            QTree,
            QColor,
            QCarouselControl,
            QCarouselSlide,
            QCarousel,
        },
        directives: {
            ClosePopup,
            Ripple,
        },
        plugins: {
            Notify,
        },
    });
}
