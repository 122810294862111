import Vue from 'vue';
import { saveAs } from 'file-saver';
import { Logger } from '../logging/logger.js';
import PlatformActionsModule from './actions-module.js';

function tableToCsv(source) {
    const columns = Object.keys(source);
    if (!columns.length) {
        return '';
    }
    const nrows = source[columns[0]].length;
    const lines = [columns.join(',')];
    for (let i = 0; i < nrows; i += 1) {
        const row = [];
        for (let j = 0; j < columns.length; j += 1) {
            const column = columns[j];
            row.push(source[column][i]);
        }
        lines.push(row.join(','));
    }
    return lines.join('\n').concat('\n');
}

class ActionsService {
    constructor({ store }) {
        this.log = new Logger({ name: 'ActionsService', level: Logger.levels.warn });
        Vue.$logging.registerLogger(this.log);

        if (!store) {
            this.log.error('Platform actions service must be passed a vuex store.');
            return;
        }

        this.store = store;
        this.moduleName = 'PlatformActionsService';

        store.registerModule(this.moduleName, PlatformActionsModule);

        // Add getters and setters for module state so they are exposed through the service
        const accessorProperties = {};
        Object.keys(PlatformActionsModule.state).forEach(stateKey => {
            const accessorProperty = {
                enumerable: true,
                get() {
                    return this.store.state[this.moduleName][stateKey];
                },
                set(value) {
                    this.store.commit(`${this.moduleName}/${stateKey}`, value);
                },
            };
            accessorProperties[stateKey] = accessorProperty;
        });
        // Add getters and setters for module getters so they are exposed through the service
        Object.keys(PlatformActionsModule.getters).forEach(getterKey => {
            const accessorProperty = {
                enumerable: true,
                get() {
                    return this.store.getters[`${this.moduleName}/${getterKey}`];
                },
            };
            accessorProperties[getterKey] = accessorProperty;
        });
        // Add all the vuex props to the service
        Object.defineProperties(this, accessorProperties);
    }

    downloadData({ data, name, csv }) {
        return new Promise((resolve, reject) => {
            let csvData = csv;
            if (data) {
                csvData = tableToCsv(data);
            }
            const filename = `${name}.csv`;
            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, filename);
            resolve(blob);
        });
    }

    promptForm({
        title, schema, value, size, message, referenceId,
    }) {
        return new Promise((resolve, reject) => {
            this.onConfirm = (isConfirmed, data) => {
                resolve({ success: isConfirmed, data });
            };
            this.formValue = {};
            this.formOptions = {
                title,
                schema,
                value,
                size,
                message,
                referenceId,
            };

            this.showFormDialog = true;
        });
    }

    promptName({ title, value }) {
        return this.promptForm({
            title,
            message: 'Please provide a name',
            schema: {
                dense: true,
                inputs: [
                    {
                        key: 'name',
                        inputType: 'text',
                        label: 'Name',
                    },
                ],
            },
            value: {
                name: value,
            },
            size: 'md',
            referenceId: 'prompt-name',
        });
    }

    showActions({ items, type, ids }) {
        let actionItems = [];
        if (items && items.length) {
            actionItems = [...items];
        }
        else {
            actionItems = ids.map(id => {
                return {
                    type,
                    id,
                };
            });
        }
        this.actionItems = actionItems;
        this.showActionDialog = true;
    }

    showInfo({ title, sections, size }) {
        return new Promise((resolve, reject) => {
            this.onConfirm = (isConfirmed, data) => {
                resolve({ success: isConfirmed, data });
            };
            this.infoOptions = {
                title,
                sections,
                size: size || 'md',
            };
            this.showInfoDialog = true;
        });
    }

    updateInputs({
        currentInputItems, newInputItems, parentType, parentId,
    }) {
        const currentIds = currentInputItems.map(item => item.id);
        const newIds = newInputItems.map(item => item.id);
        const idsToRemove = currentIds.filter(id => !newIds.includes(id));
        const idsToAdd = newIds.filter(id => !currentIds.includes(id));
        if (idsToRemove.length) {
            const linkIds = currentInputItems.filter(item => idsToRemove.includes(item.id)).map(filteredItem => filteredItem.linkId);
            Vue.$api.data.delete({
                type: 'Link',
                query: {
                    id: linkIds,
                },
            });
        }
        if (idsToAdd.length) {
            const newLinks = newInputItems.filter(item => idsToAdd.includes(item.id));
            const data = newLinks.map(item => {
                return {
                    childId: item.id,
                    childType: item.type,
                    parentId,
                    parentType,
                    linkType: 'input',
                };
            });
            Vue.$api.data.insert({
                type: 'Link',
                data,
            });
        }
    }

    defaultActionForType({ type }) {
        return this.defaultActionsMap[type];
    }
}

export default ActionsService;
