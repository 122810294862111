<template>
    <div class="editor q-pa-sm">
        <editor-content
            :ref="editorRef"
            :editor="editor"
            class="editor__content"
        />

        <EditorSuggestion
            :editor="editor"
            :editable="editable"
            :extension="mentionExtension"
            :suggestions="getUsers"
            popup-mount-selector=".editor"
            no-suggestions-text="No users found"
        />

        <EditorSuggestion
            :editor="editor"
            :editable="editable"
            :extension="hashtagExtension"
            :suggestions="getHashtags"
            :resolve-unmatched-suggestion="resolveUnmatchedHashtagSuggestion"
            popup-mount-selector=".editor"
            no-suggestions-text="No tags found"
        />

        <EditorMenu
            v-show="editable"
            :editor="editor"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Editor, EditorContent } from 'tiptap';
import {
    Image, HardBreak, Bold, Italic, BulletList, OrderedList, Code, CodeBlockHighlight, Strike, Underline, Placeholder,
} from 'tiptap-extensions';
import javascript from 'highlight.js/lib/languages/javascript';
import Mention from './Mention.js';
import Link from './Link.js';
import ListItem from './ListItem.js';
import Hashtag from './Hashtag.js';
import RootEnter from './RootEnter.js';
import EditorMenu from './EditorMenu.vue';
import EditorSuggestion from './EditorSuggestion.vue';

export default {
    name: 'CommentEditor',
    components: {
        EditorSuggestion,
        EditorMenu,
        EditorContent,
    },
    props: {
        editable: {
            type: Boolean,
            required: false,
            default: false,
        },
        placeholder: {
            type: String,
            required: false,
            default: '',
        },
        content: {
            type: String,
            required: false,
            default: '',
        },
        clearAfterSubmit: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            editorRef: `editor-${Math.random().toString(36).substr(2, 9)}`,
            mentionExtension: Mention,
            hashtagExtension: Hashtag,
            editor: new Editor({
                autoFocus: true,
                editable: this.editable,
                content: this.content,
                // On each input save the htmlContent
                onUpdate: ({ getHTML }) => {
                    const htmlContent = getHTML();
                    this.htmlContent = htmlContent;
                },
                extensions: [
                    new RootEnter({
                        handler: this.submit,
                    }),
                    new Image(),
                    new HardBreak(),
                    new Bold(),
                    new Italic(),
                    new BulletList(),
                    new OrderedList(),
                    new ListItem(),
                    new Code(),
                    new CodeBlockHighlight({
                        languages: {
                            javascript,
                        },
                    }),
                    new Link(),
                    new Strike(),
                    new Underline(),
                    new Placeholder({
                        emptyEditorClass: 'is-editor-empty',
                        emptyNodeClass: 'is-empty',
                        emptyNodeText: this.placeholder,
                        showOnlyWhenEditable: true,
                        showOnlyCurrent: true,
                    }),
                ],
            }),
            htmlContent: this.content,
        };
    },
    computed: {
        ...mapGetters('compute', {
            getElement: 'getElement',
        }),
        allTags() {
            const allTagsForgeElement = this.getElement(this.$tags.tagInfoForgeResource);
            if (allTagsForgeElement && allTagsForgeElement.state === 'ready' && allTagsForgeElement.data) {
                return allTagsForgeElement.data;
            }
            return [];
        },
    },
    watch: {
        content: {
            immediate: true,
            handler(newContent) {
                this.editor.setContent(newContent);
            },
        },
        editable: {
            immediate: true,
            handler(editable) {
                this.editor.setOptions({
                    editable,
                });
                // If editor changes to be editable then focus it
                if (editable) {
                    this.focus();
                }
            },
        },
    },
    beforeDestroy() {
        this.editor.destroy();
    },
    methods: {
        focus() {
            // Hack but must attempt focus after tiptap has updated element--there are no events from tiptap to trigger this
            setTimeout(() => {
                const editorRef = this.$refs[this.editorRef];
                if (editorRef) {
                    const editorInputDOMNode = editorRef.$el.querySelector('[contenteditable=true]');
                    if (editorInputDOMNode) {
                        editorInputDOMNode.focus();
                    }
                }
            }, 10);
        },
        submit() {
            const userIds = this.getUserIdsFromContent(this.htmlContent);
            this.$emit('submit', { htmlContent: this.htmlContent, mentionIds: userIds });
            // Clear the content of the editor after the user has submitted their comment
            if (this.clearAfterSubmit) {
                this.editor.setContent('');
            }
        },
        getUsers() {
            return this.$users.allUsers.map(user => {
                return {
                    id: user.userId,
                    label: `${user.firstName} ${user.lastName}`,
                };
            })
            .sort((a, b) => {
                if (a.label < b.label) {
                    return -1;
                }
                if (a.label > b.label) {
                    return 1;
                }
                return 0;
            });
        },
        getHashtags() {
            if (this.allTags) {
                return this.allTags.map(tagInfo => {
                    return {
                        id: tagInfo.content,
                        label: tagInfo.content,
                    };
                })
                .sort((a, b) => {
                    if (a.label < b.label) {
                        return -1;
                    }
                    if (a.label > b.label) {
                        return 1;
                    }
                    return 0;
                });
            }
            return [];
        },
        resolveUnmatchedHashtagSuggestion(query) {
            // Only return non-null suggestions
            if (query) {
                return {
                    id: query,
                    label: query,
                };
            }
            return null;
        },
        getUserIdsFromContent(htmlContent) {
            // Extract all user mentions from the html using regex capture group
            const matches = [...htmlContent.matchAll(/data-user-id="([A-Za-z0-9]{17})"/g)];
            const userIds = matches.map(match => match[1]);
            return userIds;
        },
    },
};
</script>

<style lang="stylus">
@import '../../../../css/quasar.variables.styl';
$color-black = #000000;
$color-white = #ffffff;
$color-grey = #dddddd;

/* Remove the default focus from prose mirror */
.ProseMirror:focus {
    outline: none;
}

.editor {
    position: relative;
}

.editor__content {
    border: $separator-color;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;

    * {
        caret-color: currentColor;
    }

    p {
        margin-bottom: 0;
    }

    pre {
        padding: 0.7rem 1rem;
        margin-top 0;
        margin-bottom: 0;
        border-radius: 5px;
        background: $color-black;
        color: $color-white;
        font-size: 0.8rem;
        overflow-x: auto;

        &::before {
            content: attr(data-language);
            text-transform: uppercase;
            display: block;
            text-align: right;
            font-weight: bold;
            font-size: 0.6rem;
        }

        code {
            display: block;
            .hljs-comment,
            .hljs-quote {
                color: #999999;
            }
            .hljs-variable,
            .hljs-template-variable,
            .hljs-attribute,
            .hljs-tag,
            .hljs-name,
            .hljs-regexp,
            .hljs-link,
            .hljs-name,
            .hljs-selector-id,
            .hljs-selector-class {
                color: #f2777a;
            }
            .hljs-number,
            .hljs-meta,
            .hljs-built_in,
            .hljs-builtin-name,
            .hljs-literal,
            .hljs-type,
            .hljs-params {
                color: #f99157;
            }
            .hljs-string,
            .hljs-symbol,
            .hljs-bullet {
                color: #99cc99;
            }
            .hljs-title,
            .hljs-section {
                color: #ffcc66;
            }
            .hljs-keyword,
            .hljs-selector-tag {
                color: #6699cc;
            }
            .hljs-emphasis {
                font-style: italic;
            }
            .hljs-strong {
                font-weight: 700;
            }
        }
    }


    p code {
        display: inline-block;
        padding: 0 0.4rem;
        border-radius: 5px;
        font-size: 0.8rem;
        font-weight: bold;
        background: rgba($color-black, 0.1);
        color: rgba($color-black, 0.8);
    }

    ul,
    ol {
        padding-left: 1rem;
        margin-top 0;
        margin-bottom 0;
    }

    li > p,
    li > ol,
    li > ul {
        margin: 0;
    }

    a {
        color: $primary;
        cursor: pointer;
        font-weight: bold;
        white-space: nowrap;
        text-decoration: none;
    }

    blockquote {
        border-left: 3px solid rgba($color-black, 0.1);
        color: rgba($color-black, 0.8);
        padding-left: 0.8rem;
        font-style: italic;

        p {
            margin: 0;
        }
    }

    img {
        max-width: 100%;
        border-radius: 3px;
    }

    table {
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
        margin: 0;
        overflow: hidden;

        td, th {
            min-width: 1em;
            border: 2px solid $color-grey;
            padding: 3px 5px;
            vertical-align: top;
            box-sizing: border-box;
            position: relative;
            > * {
                margin-bottom: 0;
            }
        }

        th {
            font-weight: bold;
            text-align: left;
        }

        .selectedCell:after {
            z-index: 2;
            position: absolute;
            content: "";
            left: 0; right: 0; top: 0; bottom: 0;
            background: rgba(200, 200, 255, 0.4);
            pointer-events: none;
        }

        .column-resize-handle {
            position: absolute;
            right: -2px; top: 0; bottom: 0;
            width: 4px;
            z-index: 20;
            background-color: #adf;
            pointer-events: none;
        }
    }

    .tableWrapper {
        margin: 1em 0;
        overflow-x: auto;
    }

    .resize-cursor {
        cursor: ew-resize;
        cursor: col-resize;
    }
}

.editor p.is-editor-empty:first-child::before {
    content: attr(data-empty-text);
    float: left;
    color: #aaa;
    pointer-events: none;
    height: 0;
    font-style: italic;
}

.mention {
    font-weight: bold;
    white-space: nowrap;
    text-decoration: none;
}
.mention-suggestion {
    color: $primary;
}

.hashtag {
    font-weight: bold;
    white-space: nowrap;
    text-decoration: none;
}
.hashtag-suggestion {
    color: $primary;
}

// Needed for suggestions
.tippy-box[data-theme~=dark] {
    background-color: $color-black;
    padding: 0;
    font-size: 1rem;
    text-align: inherit;
    color: $color-white;
    border-radius: 5px;
}
</style>
