<template>
    <q-item
        class="items-center"
    >
        <q-item-section
            avatar
            top
        >
            <q-circular-progress
                v-cypress="'BackgroundItem_CircularProgress'"
                :value="jobResult.progress/100.0"
                :color="statuses[jobResult.jobStatus].color"
                :thickness="0.4"
                :min="0"
                :max="1"
                size="40px"
                track-color="grey-4"
                center-color="transparent"
            />
        </q-item-section>

        <q-item-section>
            <!-- Title -->
            <q-item-label>
                <div class="text-h6">
                    {{ jobResult.taskName }}
                </div>
            </q-item-label>

            <!-- Caption -->
            <q-item-label
                v-if="jobResult.message"
                caption
            >
                {{ jobResult.message }}
            </q-item-label>
            <!-- Lower Line -->
            <q-item-label
                v-if="jobResult.jobStatus === 'scheduled'"
                class="q-pt-sm"
            >
                Scheduled {{ fromDate(jobResult.scheduledAt) }}
            </q-item-label>

            <q-item-label
                v-else-if="jobResult.jobStatus === 'running'"
                class="q-pt-sm"
            >
                <span>Started {{ fromDate(jobResult.startedAt) }}</span>
            </q-item-label>

            <q-item-label
                v-else-if="jobResult.jobStatus === 'completed'"
                class="q-pt-sm"
            >
                Completed {{ fromDate(jobResult.completedAt) }}
            </q-item-label>

            <q-item-label
                v-else-if="jobResult.jobStatus === 'cancelling'"
                class="q-pt-sm"
            >
                Cancelling
            </q-item-label>

            <q-item-label
                v-else-if="jobResult.jobStatus === 'error'"
                class="q-pt-sm"
            >
                Task Failed
            </q-item-label>

            <q-item-label
                v-else-if="jobResult.jobStatus === 'cancelled'"
                class="q-pt-sm"
            >
                Cancelled
            </q-item-label>
        </q-item-section>
    </q-item>
</template>

<script>
import { formatting } from '../plugin-framework/services/utils/formatting-utils.js';

export default {
    name: 'BackgroundJobItem',
    props: {
        jobResult: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            statuses: {
                scheduled: {
                    name: 'Scheduled',
                    color: 'info',
                    icon: 'access_time',
                },
                running: {
                    name: 'Running',
                    color: 'secondary',
                },
                completed: {
                    name: 'Completed',
                    color: 'positive',
                    icon: 'done',
                },
                error: {
                    name: 'Error',
                    color: 'negative',
                    icon: 'error_outline',
                },
                cancelled: {
                    name: 'Cancelled',
                    color: 'warning',
                    icon: 'clear',
                },
                cancelling: {
                    name: 'Cancelling',
                    color: 'warning',
                    icon: 'clear',
                },
            },
        };
    },
    methods: {
        fromDate(date) {
            return formatting.formatDate(date);
        },
    },
};
</script>

<style scoped>

</style>
