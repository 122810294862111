<template>
    <q-expansion-item
        v-model="panelShown"
        :dense="dense"
        :dense-toggle="dense"
        expand-separator
        :label="title"
    >
        <q-card>
            <q-card-section>
                <AutoFormInput
                    v-for="(input, index) in visibleInputs"
                    :key="`${input.key}-${index}`"
                    v-bind="input"
                    :value="getInputValue(input.key)"
                    :dense="dense"
                    @input="$emit('input-changed', $event, input.key)"
                    @validate="$emit('validate', $event, input.key)"
                />
            </q-card-section>
        </q-card>
    </q-expansion-item>
</template>

<script>
import dot from 'dot-object';
import AutoFormInput from './AutoFormInput.vue';

export default {
    name: 'AutoFormSection',
    components: {
        AutoFormInput,
    },
    props: {
        value: {
            type: Boolean,
            required: false,
            default: null,
        },
        title: {
            type: String,
            required: true,
        },
        startOpen: {
            type: Boolean,
            required: false,
            default: true,
        },
        inputs: {
            type: Array,
            required: true,
        },
        form: {
            type: Object,
            required: true,
        },
        dense: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data() {
        return {
            // If the user has passed expanded then use it rather than the start open prop
            panelExpanded: this.$listeners.input ? this.value : this.startOpen,
        };
    },
    computed: {
        panelShown: {
            get() {
                // If user has passed an event listener for the panel expansion then use it
                if (this.$listeners.input) {
                    return this.value;
                }
                // Otherwise handle the state locally
                return this.panelExpanded;
            },
            set(value) {
                // If user has passed an event listener for the panel expansion then use it
                if (this.$listeners.input) {
                    this.$emit('input', value);
                }
                // Otherwise handle the state locally
                else {
                    this.panelExpanded = value;
                }
            },
        },
        visibleInputs() {
            if (this.inputs) {
                return this.inputs.filter(input => !input.hide);
            }
            return [];
        },
    },
    methods: {
        getInputValue(inputKey) {
            const inputValue = dot.pick(inputKey, this.form);
            return inputValue;
        },
    },
};
</script>

<style scoped>

</style>
