<template>
    <div
        class="row q-col-gutter-md items-stretch q-pa-xs"
    >
        <div
            v-for="service in services"
            :key="service.id"
            class="col-auto"
        >
            <q-card
                class="service-card-fixed pointer"
                @click="$emit('view-service', service)"
            >
                <div
                    class="full-height column"
                    :style="`${coverImage(service.coverImage)}`"
                >
                    <div class="col"/>
                    <div class="column text-grey-1 primary-overlay">
                        <q-space/>
                        <div class="col-auto q-pa-md">
                            <div class="text-bold">
                                {{ service.name }}
                            </div>
                            <div class="description-fixed ellipsis-3-lines">
                                <small>{{ service.description }}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </q-card>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ServiceCardGrid',
    props: {
        services: {
            type: Array,
            required: true,
        },
    },
    methods: {
        coverImage(imageUrl) {
            if (imageUrl) {
                return `background-image: url(${imageUrl}); background-size: 100% auto; background-repeat: no-repeat`;
            }
            return '';
        },
    },
};
</script>

<style lang="stylus">
@import '../../../css/quasar.variables.styl';
.service-card-fixed {
    width: 400px;
    height: 267px;
}
.primary-overlay {
    background: transparent linear-gradient(0deg, $primary 0%, #5C3D568D 72%, #7B7B7B00 100%) 0% 0% no-repeat padding-box;
    height: 200px;
    width: 100%;
}
.description-fixed {
    height: 4em;
}
</style>
