const PAIComponents = {
    install(Vue) {
        // Dynamically require all of the components using a webpack require.context
        // https://webpack.js.org/guides/dependency-management#require-with-expression
        const imports = require.context('./', true, /\.vue$/);

        imports.keys().forEach(filePath => {
            // Get component from import
            const module = imports(filePath);
            const component = module.default || module;

            // Register the component using its name
            Vue.component(component.name, module.default || module);
        });
    },
};
export default PAIComponents;
