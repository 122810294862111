<template>
    <q-page
        v-if="forgeResourcesLoaded"
    >
        <q-input
            v-model="searchText"
            borderless
            dense
            filled
            clearable
            debounce="300"
            placeholder="Search apps"
            class="q-mx-md q-mb-md"
        >
            <template v-slot:prepend>
                <q-icon name="search"/>
            </template>
        </q-input>
        <div
            v-if="dockedApps.length"
        >
            <div
                class="row q-gutter-md full-width items-center"
            >
                <q-tabs
                    v-model="activeDock"
                    active-color="primary"
                    align="center"
                    class="q-px-xs"
                >
                    <q-tab
                        name="all"
                        :label="`Docked (${filteredDockedApps.length})`"
                    />
                </q-tabs>
                <q-space/>
            </div>
            <div class="q-pa-md row items-start q-gutter-md">
                <FAppCard
                    v-for="(app, index) in filteredDockedApps"
                    :key="index"
                    v-bind="app.props"
                    :is-docked="isAppDocked(app)"
                    class="tool-card"
                    @toggleDock="toggleDocked(app)"
                />
            </div>
        </div>
        <div class="row q-gutter-md full-width items-center">
            <q-tabs
                v-model="activeApps"
                active-color="primary"
                align="center"
                class="q-px-xs"
            >
                <q-tab
                    name="all"
                    :label="`All (${filteredApps.length})`"
                />
                <q-tab
                    name="tool"
                    :label="`Tools (${filteredTools.length})`"
                />
            </q-tabs>
        </div>
        <div class="q-pa-md row items-start q-gutter-md">
            <FAppCard
                v-for="(app, index) in displayedApps"
                :key="index"
                v-bind="app.props"
                :is-docked="isAppDocked(app)"
                class="tool-card"
                @toggleDock="toggleDocked(app)"
            />
        </div>
    </q-page>
    <q-page v-else>
        <ForgeLoadState
            :resources="forgeResourceElements"
        />
    </q-page>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import mixins from '../../../mixins';
import ForgeLoadState from '../../../plugin-framework/shared-components/ForgeLoadState.vue';

export default {
    name: 'ThePAppsPage',
    components: {
        ForgeLoadState,
    },
    mixins: [mixins.compute],
    data() {
        return {
            activeApps: 'all',
            activeDock: 'all',
            searchText: '',
        };
    },
    computed: {
        ...mapState('Petron', {
            petronId: 'petronId',
        }),
        ...mapGetters('Petron', {
            petronResource: 'petronResource',
            petron: 'petron',
        }),
        forgeResources() {
            return [
                this.petronResource,
            ];
        },
        displayedApps() {
            if (this.activeApps === 'tool') {
                return this.filteredTools;
            }
            return this.filteredApps;
        },
        enabledApps() {
            const appCards = this.$pai.getFragments('app-card');
            if (!appCards) {
                return [];
            }
            return appCards.filter(appCard => !appCard.props.hide);
        },
        dockedApps() {
            const dockedApps = [];
            if (this.petron && this.petron.dockedApps) {
                this.petron.dockedApps.forEach(docked => {
                    const matchingApp = this.enabledApps.find(app => docked.route === app.props.launchRoute.name);
                    if (matchingApp) {
                        dockedApps.push(matchingApp);
                    }
                });
            }
            return dockedApps;
        },
        filteredApps() {
            if (!this.searchText || !this.searchText.length) {
                return this.enabledApps;
            }
            return this.enabledApps.filter(app => {
                let match = false;
                if (app.props.title) {
                    match = app.props.title.toLowerCase()
                    .includes(this.searchText.toLowerCase());
                }
                if (!match && app.props.description) {
                    match = app.props.description.toLowerCase()
                    .includes(this.searchText.toLowerCase());
                }
                return match;
            });
        },
        filteredTools() {
            return this.filteredApps.filter(app => app.props.type === 'tool');
        },
        filteredDockedApps() {
            if (!this.searchText || !this.searchText.length) {
                return this.dockedApps;
            }
            return this.dockedApps.filter(app => {
                let match = false;
                if (app.props.title) {
                    match = app.props.title.toLowerCase()
                    .includes(this.searchText.toLowerCase());
                }
                if (!match && app.props.description) {
                    match = app.props.description.toLowerCase()
                    .includes(this.searchText.toLowerCase());
                }
                return match;
            });
        },
    },
    methods: {
        isAppDocked(app) {
            return this.dockedApps.includes(app);
        },
        toggleDocked(toggledApp) {
            // It's necessary to update the entire array on the server.
            const dockedArray = [...this.dockedApps];
            const removeIdx = dockedArray.indexOf(toggledApp);
            let addingToDock = true;
            if (removeIdx === -1) {
                // Add to dock.
                dockedArray.push(toggledApp);
            }
            else {
                // Remove from dock.
                addingToDock = false;
                dockedArray.splice(removeIdx, 1);
            }
            const newDockedApps = dockedArray.map(app => {
                return {
                    title: app.props.title,
                    route: app.props.launchRoute.name,
                };
            });
            this.$api.data.update({
                type: 'Petron',
                query: {
                    id: this.petronId,
                },
                body: {
                    dockedApps: newDockedApps,
                },
            }).then(() => {
                if (addingToDock) {
                    this.$notify.success(`'${toggledApp.props.title}' docked.`);
                }
                else {
                    this.$notify.success(`'${toggledApp.props.title}' removed from the dock.`);
                }
            }).catch((issue) => {
                if (addingToDock) {
                    this.$notify.error(`'${toggledApp.props.title}' not docked: ${issue}`);
                }
                else {
                    this.$notify.error(`'${toggledApp.props.title}' not undocked: ${issue}.`);
                }
            });
        },
    },
};
</script>

<style scoped>
.tool-card {
    width: 295px;
}
</style>
