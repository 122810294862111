import Vue from 'vue';
import Vuex from 'vuex';
import app from './app.js';
import notifications from '../plugin-framework/services/notifications/notifications-module.js';

Vue.use(Vuex);

const store = new Vuex.Store({
    // Turned of strict to suppress crazy errors from this issue:
    // https://github.com/vuejs/vue-test-utils/issues/1002
    strict: false,
    modules: {
        app,
        notifications,
    },
});

export default store;
