<template>
    <div class="column text-primary fit">
        <div class="row col-auto">
            <div class="q-pa-md">
                Connected to live data: <strong>{{ forgeConnectionLive ? 'Yes' : 'No' }}</strong>
            </div>
        </div>
        <q-scroll-area class="col">
            <q-list
                bordered
            >
                <q-expansion-item
                    dense
                    dense-toggle
                    expand-separator
                    header-class="text-success"
                    icon="done"
                    :label="`Healthy (${healthyElementsInfo.length})`"
                >
                    <q-list>
                        <q-item
                            v-for="(healthyElement, index) in healthyElementsInfo"
                            :key="index"
                            v-ripple
                            clickable
                            @click="resourceClicked(healthyElement)"
                        >
                            <q-item-section>
                                <q-item-label>{{ $compute.forgeParseResource(healthyElement.uri).el }}</q-item-label>
                                <q-item-label
                                    caption
                                    class="text-grey-6 wrap"
                                >
                                    URI: {{ healthyElement.uri }}
                                </q-item-label>
                                <q-item-label
                                    caption
                                    class="text-grey-6"
                                >
                                    Last Updated: {{ $utils.formatting.timeAgo(healthyElement.updatedAt, displayTimeZoneId) }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-expansion-item>

                <q-expansion-item
                    dense
                    dense-toggle
                    expand-separator
                    :content-inset-level="0.5"
                    header-class="text-warning"
                    icon="priority_high"
                    :label="`Stale (${staleElementsInfo.length})`"
                >
                    <q-list>
                        <q-item
                            v-for="(staleElement, index) in staleElementsInfo"
                            :key="index"
                            v-ripple
                            clickable
                            @click="resourceClicked(staleElement)"
                        >
                            <q-item-section>
                                <q-item-label>{{ $compute.forgeParseResource(staleElement.uri).el }}</q-item-label>
                                <q-item-label
                                    caption
                                    class="text-grey-6"
                                >
                                    URI: {{ staleElement.uri }}
                                </q-item-label>
                                <q-item-label
                                    caption
                                    class="text-grey-6"
                                >
                                    Update Requested: {{ $utils.formatting.timeAgo(staleElement.markedDirtyAt, displayTimeZoneId) }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-expansion-item>

                <q-expansion-item
                    dense
                    dense-toggle
                    expand-separator
                    :content-inset-level="0.5"
                    header-class="text-error"
                    icon="clear"
                    :label="`Unhealthy (${errorElementsInfo.length})`"
                >
                    <q-list>
                        <q-item
                            v-for="(errorElement, index) in errorElementsInfo"
                            :key="index"
                            v-ripple
                            clickable
                            @click="resourceClicked(errorElement)"
                        >
                            <q-item-section>
                                <q-item-label>{{ $compute.forgeParseResource(errorElement.uri).el }}</q-item-label>
                                <q-item-label
                                    caption
                                    class="text-grey-6"
                                >
                                    URI: {{ errorElement.uri }}
                                </q-item-label>
                                <q-item-label
                                    caption
                                    class="text-grey-6"
                                >
                                    Errors: {{ errorElement.errors }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-expansion-item>
            </q-list>
        </q-scroll-area>

        <PDialog
            v-model="showDialog"
            :title="selectedResourceName"
            hide-cancel-btn
            ok-btn-text="Close"
            @ok="closeDialog"
            @input="closeDialog"
        >
            <AutoFormJSONInput
                :value="selectedResource"
                :max-lines="30"
                disabled
            />
        </PDialog>
    </div>
</template>

<script>

import Vue from 'vue';
import AutoFormJSONInput from '../../../shared-components/auto-form/AutoFormJSONInput.vue';
import PDialog from '../../pai-components/PDialog.vue';

export default {
    name: 'ForgeStatusPanel',
    components: {
        PDialog,
        AutoFormJSONInput,
    },
    data() {
        return {
            showDialog: false,
            selectedResource: null,
        };
    },
    computed: {
        displayTimeZoneId() {
            return this.$globalSettings.displayTimeZoneId;
        },
        forgeConnectionLive() {
            return this.$compute.connectionLive;
        },
        initialElementsInfo() {
            return this.$compute.initialElementsInfo;
        },
        readyElementsInfo() {
            return this.$compute.readyElementsInfo;
        },
        staleElementsInfo() {
            return this.$compute.staleElementsInfo;
        },
        errorElementsInfo() {
            return this.$compute.errorElementsInfo;
        },
        healthyElementsInfo() {
            return this.$compute.healthyElementsInfo;
        },
        selectedResourceName() {
            if (this.selectedResource) {
                return this.$compute.forgeParseResource(this.selectedResource.uri).el;
            }
            return 'Resource';
        },
    },
    methods: {
        resourceClicked(resourceInfo) {
            this.selectedResource = Vue.$compute.getElement(resourceInfo.uri);
            this.showDialog = true;
        },
        closeDialog() {
            this.selectedResource = null;
        },
    },
};
</script>

<style scoped>

</style>
