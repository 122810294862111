<template>
    <q-dialog
        v-if="display"
        v-model="display"
        persistent
        :maximized="false"
        @before-show="resetState"
    >
        <q-card
            class="md-size column"
        >
            <img
                class="logo-img"
                :src="require('@/assets/images/input-modal-background.svg')"
                alt="Petro.ai"
                title="Background"
            >
            <q-card-section class="col-auto row items-center header-section">
                <q-space/>
                <GeneralButton
                    v-close-popup
                    dense
                    flat
                    icon="fas fa-times"
                    tooltip="Close dialog"
                    @click="$emit('cancel')"
                />
            </q-card-section>
            <q-card-section class="col row">
                <q-card-section class="col column main-section">
                    <div
                        class="text-primary text-h5 col-auto"
                    >
                        Add Inputs
                    </div>
                    <div class="col-auto q-pb-lg text-grey-9">
                        {{ addDetailMessage }}
                    </div>
                    <div
                        class="col column"
                    >
                        <q-input
                            v-model="searchInput"
                            v-cypress="'FindInputsDialog_Search_Input'"
                            borderless
                            filled
                            dense
                            clearable
                            placeholder="Search Petro.ai"
                            class="col-auto"
                            debounce="500"
                            @input="performSearch"
                        >
                            <template v-slot:prepend>
                                <q-icon name="search"/>
                            </template>
                        </q-input>
                        <div
                            v-if="searchRunning"
                            class="search-box"
                        >
                            <q-spinner
                                size="50px"
                                color="grey-8"
                                class="full-width"
                            />
                        </div>
                        <div
                            v-else-if="matchingInputs"
                            class="col column"
                        >
                            <div class="col-auto text-grey-8 q-pa-sm search-box">
                                {{ resultsMessage }}
                            </div>
                            <q-scroll-area
                                class="col full-width"
                            >
                                <q-list
                                    v-cypress="'FindInputsDialog_Results_List'"
                                    separator
                                    dense
                                    class="search-box shadow-1"
                                >
                                    <q-item
                                        v-for="(item, itemIndex) in matchingInputs"
                                        :key="`item-${itemIndex}`"
                                        clickable
                                        class="col"
                                        @click="selectInput(item)"
                                    >
                                        <q-item-section class="col">
                                            <q-item-label
                                                class="text-bold text-primary ellipsis"
                                            >
                                                {{ itemLabel(item) }}
                                            </q-item-label>
                                            <q-item-label
                                                class="text-grey-9 ellipsis"
                                            >
                                                {{ itemDetails(item) }}
                                            </q-item-label>
                                        </q-item-section>
                                    </q-item>
                                </q-list>
                            </q-scroll-area>
                        </div>
                    </div>
                    <div class="col-auto text-grey-8 q-pt-md">
                        <q-checkbox
                            v-model="currentPetronOnly"
                            v-cypress="'FindInputsDialog_PetronSearch_Checkbox'"
                            :label="petronMessage"
                        />
                    </div>
                </q-card-section>
                <q-separator vertical/>
                <q-card-section
                    v-cypress="'FindInputsDialog_Selected_Section'"
                    class="col column main-section"
                >
                    <div class="col column">
                        <div
                            class="col-auto text-primary text-h5"
                        >
                            Selected Inputs
                        </div>
                        <div class="col-auto q-pb-md text-grey-9">
                            {{ selectedDetailMessage }}
                        </div>
                        <div class="col column">
                            <div
                                v-for="(type, index) in mappedSearchTypes"
                                :key="`type-${index}`"
                                :class="`column q-pb-md ${type.typeData.singleSelection ? 'single-selection-section' : 'col'}`"
                            >
                                <div class="col-auto q-pb-sm">
                                    <div class="text-primary">
                                        {{ type.label }}
                                    </div>
                                    <div class="row text-grey-9 q-pb-sm items-center">
                                        <span>
                                            {{ type.detail }}
                                        </span>
                                        <q-space/>
                                        <GeneralButton
                                            :hide="type.hideActionButton"
                                            icon="add_circle_outline"
                                            text-color="text-grey-9"
                                            :tooltip="type.tooltip"
                                            dense
                                            @click="$emit('launchAction', type.typeData)"
                                        />
                                    </div>
                                    <q-separator/>
                                </div>
                                <q-scroll-area
                                    class="col full-width"
                                >
                                    <q-list
                                        separator
                                        dense
                                    >
                                        <q-item
                                            v-for="(item, itemIndex) in selectedForType(type.typeData)"
                                            :key="`item-${itemIndex}`"
                                        >
                                            <q-item-section class="col">
                                                <q-item-label
                                                    class="text-bold text-primary ellipsis"
                                                >
                                                    {{ nameForItem(item) }}
                                                </q-item-label>
                                                <q-item-label
                                                    class="text-grey-9 ellipsis"
                                                >
                                                    {{ itemDetails(item) }}
                                                </q-item-label>
                                            </q-item-section>
                                            <q-item-section side>
                                                <q-icon
                                                    clickable
                                                    name="cancel"
                                                    size="xs"
                                                    @click.stop="removeInput(item)"
                                                />
                                            </q-item-section>
                                        </q-item>
                                    </q-list>
                                </q-scroll-area>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto text-right q-pt-md">
                        <GeneralButton
                            v-cypress="'FindInputsDialog_Save_Button'"
                            v-close-popup
                            color="accent"
                            text-color="primary"
                            :flat="false"
                            :outline="false"
                            label="Save and Close"
                            tooltip="Save these inputs"
                            @click="$emit('inputsChosen', selectedInputs)"
                        />
                    </div>
                </q-card-section>
            </q-card-section>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { formatting } from '../../services/utils/formatting-utils.js';
import GeneralButton from '../inline-elements/GeneralButton.vue';

export default {
    name: 'FindInputsDialog',
    components: {
        GeneralButton,
    },
    props: {
        // v-model prop to show or hide the modal
        value: {
            type: Boolean,
            required: true,
        },
        searchTypes: {
            type: Array,
            required: true,
        },
        addDetailMessage: {
            type: String,
            required: false,
            default: null,
        },
        selectedDetailMessage: {
            type: String,
            required: false,
            default: null,
        },
        initialSelection: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    data() {
        return {
            searchInput: null,
            selectedInputs: [],
            matchingInputs: null,
            searchRunning: false,
            currentPetronOnly: true,
        };
    },
    computed: {
        ...mapState('Petron', {
            petronId: 'petronId',
        }),
        ...mapGetters('Petron', {
            petron: 'petron',
        }),
        ...mapGetters('Library', {
            allPetrons: 'allPetrons',
        }),
        displayTimeZoneId() {
            return this.$globalSettings.displayTimeZoneId;
        },
        display: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            },
        },
        petronMessage() {
            if (!this.petron) {
                return '';
            }
            return `Limit results to ${this.petron.name} for faster results`;
        },
        resultsMessage() {
            const searchText = this.searchInput ? this.searchInput.trim() : '';
            if (searchText.length === 0 || !this.matchingInputs) {
                return '';
            }
            let resultsText = `${this.matchingInputs.length} results`;
            if (this.matchingInputs.length === 0) {
                resultsText = 'No results';
            }
            else if (this.matchingInputs.length === 1) {
                resultsText = '1 result';
            }
            return `${resultsText} for '${searchText}'`;
        },
        mappedSearchTypes() {
            return this.searchTypes.map(inputType => {
                const baseLabel = formatting.startCase(inputType.label || inputType.type);
                const label = `${formatting.createAcronym(inputType.type)} | ${baseLabel}`;
                const detail = inputType.detailMessage || 'Add a \'detailMessage\' property for this search type.';
                const tooltip = inputType.actionTooltip || `Take action on ${baseLabel}`;
                const hideActionButton = inputType.hideLaunchAction || false;
                return {
                    label,
                    detail,
                    tooltip,
                    hideActionButton,
                    typeData: inputType,
                };
            });
        },
    },
    watch: {
        currentPetronOnly() {
            this.performSearch();
        },
        searchInput() {
            this.matchingInputs = null;
        },
    },
    methods: {
        resetState() {
            // Reset state on launch.
            this.searchInput = null;
            this.matchingInputs = null;
            this.currentPetronOnly = true;
            this.selectedInputs = [...this.initialSelection];
        },
        nameFieldForType(type) {
            let nameField = 'name';
            if (type) {
                const typeDef = this.searchTypes.find(searchType => searchType.type === type);
                if (typeDef && typeDef.nameField) {
                    nameField = typeDef.nameField;
                }
            }
            return nameField;
        },
        petronFieldForType(type) {
            let petronField = 'pid';
            if (type) {
                const typeDef = this.searchTypes.find(searchType => searchType.type === type);
                if (typeDef) {
                    if (typeDef.petronIndependent) {
                        return null;
                    }
                    if (typeDef.petronField) {
                        petronField = typeDef.petronField;
                    }
                }
            }
            return petronField;
        },
        nameForItem(item) {
            const type = item.type;
            // If there is a displayNameFunction, use that.
            if (type) {
                const typeDef = this.searchTypes.find(searchType => searchType.type === type);
                if (typeDef && typeDef.displayNameFunction) {
                    return typeDef.displayNameFunction(item);
                }
            }
            // Otherwise, use name.
            const nameField = this.nameFieldForType(item.type);
            return item[nameField];
        },
        searchFieldsForType(type) {
            // If search fields are explicitly defined, use those.
            if (type) {
                const typeDef = this.searchTypes.find(searchType => searchType.type === type);
                if (typeDef && typeDef.searchFields) {
                    return typeDef.searchFields;
                }
            }

            // Otherwise, just use name.
            return [this.nameFieldForType(type)];
        },
        selectedForType(type) {
            return this.selectedInputs.filter(input => input.type === type.type);
        },
        itemLabel(item) {
            const type = item.type || '';
            return `${formatting.createAcronym(type)} | ${this.nameForItem(item)}`;
        },
        itemPetron(item) {
            const petronField = this.petronFieldForType(item.type);
            if (!petronField) {
                return '';
            }
            const matchingPetron = this.allPetrons.find(petron => petron.id === item[petronField]);
            return matchingPetron ? matchingPetron.name : '';
        },
        itemDetails(item) {
            const petron = this.itemPetron(item);
            return `${petron} (Last updated: ${formatting.formatDate(item.updatedAt, this.displayTimeZoneId, 'MM-DD-YYYY')})`;
        },
        removeInput(item) {
            const removeIdx = this.selectedInputs.findIndex(input => (input.id === item.id) && (input.type === item.type));
            if (removeIdx > -1) {
                this.selectedInputs.splice(removeIdx, 1);
                this.performSearch();
            }
        },
        performSearch() {
            if (!this.searchInput || !this.searchInput.trim().length) {
                this.matchingInputs = null;
                return;
            }
            this.searchRunning = true;
            const searchText = this.searchInput.trim();
            const allMatches = [];
            const searchPromises = [];
            this.searchTypes.forEach(searchType => {
                const searchFields = this.searchFieldsForType(searchType.type);
                searchFields.forEach(field => {
                    const query = {
                        // Only include visible objects.
                        isVisible: true,
                    };
                    if (this.currentPetronOnly) {
                        const petronField = this.petronFieldForType(searchType.type);
                        if (petronField) {
                            query[petronField] = this.petronId;
                        }
                    }
                    query[field] = {
                        regex: `/.*${searchText}.*/i`,
                    };
                    const queryPromise = this.$api.data.query({
                        type: searchType.type,
                        query,
                    })
                    .then(response => {
                        return {
                            type: searchType,
                            results: response.data,
                        };
                    })
                    .catch(issue => {
                        this.$logging.loggers.PluginFramework.error('FindInputsDialog error searching for inputs.', searchType, issue);
                        return {
                            type: searchType,
                            results: [],
                        };
                    });
                    searchPromises.push(queryPromise);
                });
            });
            Promise.all(searchPromises)
            .then((allResults) => {
                allResults.forEach(typeResults => {
                    const type = typeResults.type;
                    const results = typeResults.results;
                    results.forEach(match => {
                        const result = { ...match };
                        result.type = type.type;
                        const existingSelection = this.selectedInputs.find(input => input.id === result.id && input.type === type.type);
                        if (!existingSelection) {
                            allMatches.push(result);
                        }
                    });
                });
                this.matchingInputs = [...allMatches];
            })
            .catch((issue) => {
                this.$logging.loggers.PluginFramework.error('FindInputsDialog error processing search results.', issue);
                this.matchingInputs = [];
            })
            .finally(() => {
                this.searchRunning = false;
            });
        },
        selectInput(selectedInput) {
            const type = this.searchTypes.find(searchType => searchType.type === selectedInput.type);
            if (type && type.singleSelection) {
                // Replace any existing input of that type.
                const replaceIdx = this.selectedInputs.findIndex(existingInput => existingInput.type === selectedInput.type);
                if (replaceIdx > -1) {
                    // Replace with new input.
                    this.selectedInputs[replaceIdx] = { ...selectedInput };
                    // Rerun search, in case the removed input is a match.
                    this.performSearch();
                    return;
                }
            }
            // Otherwise, just add to the inputs and carry on.
            this.selectedInputs.push(selectedInput);
            if (!this.matchingInputs) {
                return;
            }
            const removeIdx = this.matchingInputs.findIndex(input => (input.id === selectedInput.id) && (input.type === selectedInput.type));
            if (removeIdx > -1) {
                this.matchingInputs.splice(removeIdx, 1);
            }
        },
    },
};
</script>

<style scoped>
.md-size {
    height: 80%;
    width: 80%;
    min-width: 80vw;
    min-height: 40vw;
}
.logo-img {
    height: auto;
    position: absolute;
    left: 0;
    bottom: 8rem;
    width: 35%;
}
.search-box {
    background-color: white;
}
.header-section {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
}
.main-section {
    padding-top: 0;
    padding-bottom: 0;
}
.single-selection-section {
    height: 120px;
}
</style>
