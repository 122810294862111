import { ROUTES } from '../constants.js';

export const DataStorageMainMenuItem = function DataStorageMainMenuItem({ app, plugin }) {
    return {
        type: 'fragment',
        target: 'main-menu-item',
        priority: 1,
        options() {
            return {
                props: {
                    tooltip: 'See Data Storage Info',
                    icon: this.indicatorIcon,
                    isSelected: this.dataStorageRouteActive,
                    bottom: true,
                    color: this.indicatorColor,
                    hide: !(app.$auth.userIsAdmin || app.$auth.userIsSupportEngineer),
                },
                events: {
                    click: () => {
                        if (app.$router.currentRoute.name !== ROUTES.DataStorage) {
                            app.$router.push({ name: ROUTES.DataStorage });
                        }
                    },
                },
            };
        },
    };
};
