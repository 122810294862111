<template>
    <q-td
        :class="{'text-center': context.col.align === 'center', 'text-left': context.col.align === 'left', 'text-right': !context.col.align || context.col.align === 'right',}"
    >
        <q-badge
            v-bind="badgePropsForColumn"
        />
    </q-td>
</template>

<script>
export default {
    name: 'BadgeColumn',
    props: {
        context: {
            type: Object,
            required: true,
        },
    },
    computed: {
        badgePropsForColumn() {
            if (this.context.col.typeOptions && this.context.col.typeOptions.format) {
                return this.context.col.typeOptions.format(this.context.value, this.context.row);
            }
            return {
                label: this.context.value,
            };
        },
    },
};
</script>

<style scoped>

</style>
