<template>
    <GeneralButton
        v-if="!hide"
        v-cypress="testId"
        v-bind="$props"
        v-on="$listeners"
    >
        <q-menu
            :anchor="`bottom ${menuAlign}`"
            :self="`top ${menuAlign}`"
            auto-close
        >
            <q-list dense>
                <q-item
                    v-for="(item, index) in items"
                    :key="index"
                    clickable
                    :class="`text-${menuAlign}`"
                    :disable="item.disable"
                    v-on="item.events"
                >
                    <q-item-section>{{ item.props.label }}</q-item-section>
                </q-item>
            </q-list>
        </q-menu>
    </GeneralButton>
</template>

<script>
import GeneralButton from './GeneralButton.vue';
import { commonButtonProps } from './common-button-props.js';

export default {
    name: 'MenuButton',
    components: { GeneralButton },
    props: {
        ...commonButtonProps,
        items: {
            type: Array,
            required: false,
            default: () => [],
        },
        menuAlign: {
            required: false,
            default: 'right',
            validator: (value) => ['left', 'right'].includes(value),
        },
    },
};
</script>

<style scoped>

</style>
