/* eslint import/order: 0 */
import Vue from 'vue';
import { sync } from 'vuex-router-sync';

// Services/Plugins
import petroJSSettings from '../../petrojs-settings.json';
import Auth from '../plugin-framework/services/auth/index.js';
import Utils from '../plugin-framework/services/utils/index.js';
import Users from '../plugin-framework/services/users/index.js';
import PAIComponents from '../plugin-framework/services/pai-components/index.js';
import FrameworkComponents from '../plugin-framework/services/framework-components/index.js';
import Forge from '../plugin-framework/services/forge/index.js';
import Compute from '../plugin-framework/services/compute/index.js';
import Notifications from '../plugin-framework/services/notifications/index.js';
import { EnvironmentPlugin } from '../plugin-framework/services/environment/index.js';
import { TelemetryPlugin } from '../plugin-framework/services/telemetry/index.js';
import { Logging } from '../plugin-framework/services/logging/index.js';
import { DisableReactivityPlugin } from '../plugin-framework/services/disable-reactivity/index.js';
import { Tags } from '../plugin-framework/services/tags/index.js';
import { Units } from '../plugin-framework/services/units/index.js';
import { Library } from '../plugins/library/index.js';
import { JobsPlugin } from '../plugins/jobs/index.js';
import { PetronPlugin } from '../plugins/petron/index.js';
import { ComputeEditorPlugin } from '../plugins/computeEditor/index.js';
import { PluginFramework } from '../plugin-framework/index.js';
import { PetronOverview } from '../plugins/petron-overview/index.js';
import { NotificationsPlugin } from '../plugins/notifications/index.js';
import { UnitDefinitionsPlugin } from '../plugins/units/index.js';
import { SettingsPlugin } from '../plugins/settings/index.js';
import { DataStoragePlugin } from '../plugins/data-storage/index.js';
import { FeatureFlags } from '../plugins/feature-flags/index.js';
import CMS from '../plugin-framework/services/cms/index.js';
import { VideoExplorer } from '../plugins/video-explorer/index.js';
import PlatformActions from '../plugin-framework/services/actions/index.js';
import { ActionsPlugin } from '../plugins/actions/actions.js';
import { initializeRouter, routes } from '../router/router.js';
import { Filter } from '../plugin-framework/services/filter/index.js';
import { PetroPlus } from '../plugins/petro-plus/index.js';
import { GlobalSettings } from '../plugin-framework/services/global-settings/index.js';

// Global Filters
import '../filters/filters.js';

// Global Directives
import { registerDirectives } from '../directives/index.js';

// PetroSuite Imports
import store from '../stores/index.js';
import { API } from '../plugin-framework/services/api';

// Import 3rd party libs
import VueCytoscape from 'vue-cytoscape';
import VueShortkey from 'vue-shortkey';
import VueTippy, { TippyComponent } from 'vue-tippy';
import { registerQuasar } from './register-quasar';

// Initializes Vue for PetroSuite returning Vue Config options
export function getAppConfig(vueInstance) {
    window.pai = {};

    registerDirectives(vueInstance);
    registerQuasar(vueInstance);

    // Register the VueTippy component
    vueInstance.use(VueTippy);
    vueInstance.component('tippy', TippyComponent);

    // Configure vue-shortkey to globally suppress hotkey actions taken
    // when user focus is in input and textarea fields
    vueInstance.use(VueShortkey, {
        prevent: ['input', 'textarea'],
    });

    vueInstance.use(Logging);
    vueInstance.use(DisableReactivityPlugin);
    vueInstance.use(Notifications, { store });
    vueInstance.use(PAIComponents);
    vueInstance.use(FrameworkComponents);
    vueInstance.use(API);
    vueInstance.use(Auth, {
        store,
        allowedMessageOrigins: petroJSSettings.auth.allowedMessageOrigins,
        ssoUrl: petroJSSettings.auth.ssoUrl,
    });

    vueInstance.use(Utils, { store });
    vueInstance.use(Users, { store });
    vueInstance.use(Forge, { store });
    const computeConfig = {
        store,
        url: petroJSSettings.serverUrl || 'https://localhost:5001',
    };
    if (petroJSSettings && petroJSSettings.computeService && petroJSSettings.computeService.reconnectWaitTimesSec) {
        computeConfig.reconnectWaitTimesSec = petroJSSettings.computeService.reconnectWaitTimesSec;
    }
    if (petroJSSettings && petroJSSettings.computeService && petroJSSettings.computeService.reconnectBlockUITimeoutSec) {
        computeConfig.reconnectBlockUITimeoutSec = petroJSSettings.computeService.reconnectBlockUITimeoutSec;
    }
    vueInstance.use(Compute, computeConfig);
    vueInstance.use(Tags, { store });
    vueInstance.use(Units, { store });
    vueInstance.use(CMS, {
        settings: petroJSSettings.cms,
    });
    vueInstance.use(PlatformActions, { store });

    vueInstance.use(VueCytoscape);

    vueInstance.use(GlobalSettings);

    // Register PetroPlugins with provided app context
    const appContext = {
        $compute: Vue.$compute,
        $environment: {},
        $notify: Vue.$notify,
        $store: store,
        $api: Vue.$api,
        $router: {},
        $auth: Vue.$auth,
        $users: Vue.$users,
        $utils: Vue.$utils,
        $globals: {},
        $plugins: {},
        $units: Vue.$units,
        $cms: Vue.$cms,
        $actions: Vue.$actions,
        $filter: {},
        $globalSettings: Vue.$globalSettings,
    };
    const Framework = new PluginFramework({ routes, store, appContext });
    vueInstance.use(Framework);
    // Register Base Application plugins
    Framework.__registerPlugin(Library);
    Framework.__registerPlugin(JobsPlugin);
    Framework.__registerPlugin(PetronPlugin);
    Framework.__registerPlugin(ComputeEditorPlugin);
    Framework.__registerPlugin(PetronOverview);
    Framework.__registerPlugin(NotificationsPlugin);
    Framework.__registerPlugin(UnitDefinitionsPlugin);
    Framework.__registerPlugin(SettingsPlugin);
    Framework.__registerPlugin(DataStoragePlugin);
    Framework.__registerPlugin(VideoExplorer);
    Framework.__registerPlugin(ActionsPlugin);
    Framework.__registerPlugin(PetroPlus);
    Framework.__registerPlugin(FeatureFlags);

    // Setup the Vue Router
    const router = initializeRouter([...Vue.$pai.getFragments('root-route'), ...Framework.routes]);
    vueInstance.use(router);

    // Update the app context with the initialized router
    appContext.$router = router;

    vueInstance.use(TelemetryPlugin, { config: petroJSSettings.telemetry, router });
    vueInstance.use(EnvironmentPlugin, { store, router });
    appContext.$environment = Vue.$environment;
    vueInstance.use(Filter, { store, router });
    appContext.$filter = Vue.$filter;

    // Sync all router params to the store under a namespaced module called route
    sync(store, router);

    // Check if user is signed in which will run all afterSignIn hooks
    vueInstance.$auth.checkForSignedInUser();

    // Vue config options are returned
    return {
        router,
        store,
    };
}
