<template>
    <q-card
        :clickable="!!defaultAction"
        class="pointer"
        @click="launchDefaultAction"
    >
        <q-card-section class="row items-center q-gutter-x-md">
            <div class="col col-shrink">
                <LetterAvatar
                    v-if="$q.screen.gt.md"
                    :label="displayType"
                    class="work-avatar"
                    background-color="inherit"
                />
            </div>

            <div class="col">
                <div class="text-primary text-bold ellipsis">
                    {{ item.name }}
                    <q-tooltip :delay="750">
                        {{ item.name }}
                    </q-tooltip>
                </div>
                <div class="ellipsis">
                    {{ displayType }}
                    <q-tooltip :delay="750">
                        {{ displayType }}
                    </q-tooltip>
                </div>
                <small class="text-grey-8">
                    <PTimeAgo :date-time="item.updatedAt"/>
                    <q-tooltip :delay="750">
                        {{ updatedMessage }}
                    </q-tooltip>
                    <UserFullName
                        :user-id="item.updatedBy"
                        hide-link
                    />
                </small>
            </div>
            <GeneralButton
                icon="launch"
                :tooltip="`See actions for this ${displayType}`"
                color="grey-8"
                @click.stop="showActions"
            />
        </q-card-section>
    </q-card>
</template>

<script>
import { mapState } from 'vuex';
import LetterAvatar from '../LetterAvatar.vue';
import GeneralButton from '../inline-elements/GeneralButton.vue';

export default {
    name: 'WorkItem',
    components: {
        LetterAvatar,
        GeneralButton,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapState('Petron', {
            petronId: 'petronId',
        }),
        displayTimeZoneId() {
            return this.$globalSettings.displayTimeZoneId;
        },
        displayType() {
            if (this.item && this.item.projectType) {
                return this.$utils.formatting.startCase(this.item.projectType);
            }
            return '';
        },
        updatedMessage() {
            if (this.item && this.item.updatedAt) {
                return `Updated: ${this.$utils.formatting.formatShortDateTime(this.item.updatedAt, this.displayTimeZoneId)}`;
            }
            return null;
        },
        defaultAction() {
            if (this.item) {
                return this.$actions.defaultActionForType({ type: this.item.projectType });
            }
            return null;
        },
    },
    methods: {
        launchDefaultAction() {
            if (this.defaultAction) {
                const items = [
                    {
                        type: this.item.projectType,
                        id: this.item.projectId,
                    },
                ];
                this.defaultAction.invoke({ items, petronId: this.petronId });
            }
        },
        showActions() {
            this.$actions.showActions({
                ids: [
                    this.item.projectId,
                ],
                type: this.item.projectType,
            });
        },
    },
};
</script>

<style scoped lang="stylus">
@import '../../../css/quasar.variables.styl';
.work-avatar {
    background-color: $secondary !important;
}
</style>
