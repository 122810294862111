// Layouts
import FullPageLayout from '../layouts/FullPageLayout.vue';
import ApplicationLayout from '../layouts/ApplicationLayout.vue';
import DefaultPageLayout from '../layouts/DefaultPageLayout';

// Page Components
import TheHomePage from '../pages/home/TheHomePage.vue';
import TheKernelsPage from '../pages/data/kernels/TheKernelsPage.vue';
import TheSearchResultsPage from '../pages/search/TheSearchResultsPage.vue';
import TheInfoPage from '../pages/settings/info/TheInfoPage.vue';
import ThePOverviewPage from '../pages/petron/overview/ThePOverviewPage.vue';
import TheUserProfilePage from '../pages/data/user/TheUserProfilePage.vue';
import SignIn from '../pages/sign-in/SignIn.vue';
import Forbidden from '../pages/403/Forbidden.vue';
import NotFound from '../pages/404/NotFound.vue';
import ThePAppsPage from '../pages/petron/apps/ThePAppsPage.vue';

export const routes = [
    {
        path: '/',
        redirect: { name: 'home' },
    },
    {
        path: '/sign-in',
        component: FullPageLayout,
        children: [
            {
                path: '',
                name: 'sign-in',
                component: SignIn,
                props: true,
            },
        ],
    },
    {
        path: '/home',
        meta: {
            auth: true,
        },
        component: ApplicationLayout,
        children: [
            {
                path: '',
                components: {
                    appMain: DefaultPageLayout,
                },
                children: [
                    {
                        path: '',
                        name: 'home',
                        components: {
                            pageContent: TheHomePage,
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '/apps',
        meta: {
            auth: true,
        },
        component: ApplicationLayout,
        children: [
            {
                path: '',
                components: {
                    appMain: DefaultPageLayout,
                },
                children: [],
            },
        ],
    },
    {
        path: '/data',
        meta: {
            auth: true,
        },
        component: ApplicationLayout,
        children: [
            {
                path: '',
                redirect: { name: 'kernels' },
                components: {
                    appMain: DefaultPageLayout,
                },
                children: [
                    {
                        path: 'kernels',
                        name: 'kernels',
                        components: {
                            pageContent: TheKernelsPage,
                        },

                    },
                    {
                        path: 'user/:userId?',
                        name: 'user',
                        components: {
                            pageContent: TheUserProfilePage,
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '/search',
        meta: {
            auth: true,
        },
        component: ApplicationLayout,
        children: [
            {
                path: '',
                components: {
                    appMain: DefaultPageLayout,
                },
                children: [
                    {
                        path: '',
                        name: 'search',
                        components: {
                            pageContent: TheSearchResultsPage,
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '/settings',
        meta: {
            auth: true,
        },
        component: ApplicationLayout,
        children: [
            {
                path: '',
                components: {
                    appMain: DefaultPageLayout,
                },
                children: [
                    {
                        path: '',
                        name: 'settings',
                        redirect: { name: 'application-settings' },
                    },
                    {
                        path: 'info',
                        name: 'info',
                        components: {
                            pageContent: TheInfoPage,
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '/petron/:petronId',
        meta: {
            auth: true,
        },
        component: ApplicationLayout,
        children: [
            {
                path: '',
                components: {
                    appMain: DefaultPageLayout,
                },
                children: [
                    {
                        path: '',
                        name: 'petron',
                        redirect: { name: 'petron-overview' },
                    },
                    {
                        path: 'overview',
                        name: 'petron-overview',
                        components: {
                            pageContent: ThePOverviewPage,
                        },
                    },
                    {
                        path: 'apps',
                        name: 'petron-apps',
                        components: {
                            pageContent: ThePAppsPage,
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '/invalid-token',
        component: FullPageLayout,
        children: [
            {
                path: '',
                name: 'forbidden',
                component: Forbidden,
            },
        ],
    },
    {
        path: '*',
        component: FullPageLayout,
        children: [
            {
                path: '',
                name: 'not-found',
                component: NotFound,
            },
        ],
    },
];
