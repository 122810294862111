import ApplicationLayout from '../../../layouts/ApplicationLayout.vue';
import DefaultPageLayout from '../../../layouts/DefaultPageLayout.vue';
import FullPageTemplate from '../../../plugin-framework/templates/FullPageTemplate';
import NotificationsManagementForm from '../components/NotificationsManagementForm';
import HashtagSubscriptionManager from '../components/HashtagSubscriptionManager';

export function NotificationsRoute({ app, plugin }) {
    return {
        type: 'fragment',
        target: 'root-route',
        options() {
            return {
                path: '/settings/user-notifications',
                meta: {
                    auth: true,
                    pluginId: plugin.$definition.name,
                },
                component: ApplicationLayout,
                children: [
                    {
                        path: '',
                        components: {
                            appMain: DefaultPageLayout,
                        },
                        children: [
                            {
                                path: '',
                                name: 'notifications',
                                components: {
                                    pageContent: FullPageTemplate,
                                },
                                props: {
                                    pageContent: (route) => {
                                        return {
                                            props: {
                                                forgeResources: this.forgeResources,
                                                sections: [
                                                    {
                                                        type: 'title',
                                                        props: {
                                                            value: 'Notifications',
                                                        },
                                                    },
                                                    {
                                                        type: 'text',
                                                        props: {
                                                            value: 'Select the updates you would like to receive about the activities in your Petrons.',
                                                        },
                                                    },
                                                    {
                                                        type: 'text',
                                                        props: {
                                                            value: 'Note: For email notifications, you can receive individual emails or a comprehensive daily summary.',
                                                            textClass: 'text-italic',
                                                        },
                                                    },
                                                    {
                                                        type: 'custom',
                                                        componentType: NotificationsManagementForm,
                                                        props: {
                                                            columns: [
                                                                {
                                                                    label: 'Notification Sidebar',
                                                                },
                                                                {
                                                                    label: 'Individual Emails',
                                                                },
                                                                {
                                                                    label: 'Daily Summary Emails',
                                                                },
                                                            ],
                                                            configurableNotifications: [
                                                                {
                                                                    label: 'App alerts',
                                                                    values: [
                                                                        {
                                                                            consumerName: 'PlatformNotificationConsumer',
                                                                            options: [
                                                                                {
                                                                                    key: 'IncludeReportedNotifications',
                                                                                },
                                                                            ],
                                                                            testId: 'Notifications_PlatformAppAlert_Checkbox',
                                                                        },
                                                                    ],
                                                                },
                                                                {
                                                                    label: 'Job successes',
                                                                    values: [
                                                                        {
                                                                            consumerName: 'PlatformNotificationConsumer',
                                                                            options: [
                                                                                {
                                                                                    key: 'IncludeJobCompletedNotifications',
                                                                                },
                                                                            ],
                                                                            testId: 'Notifications_PlatformJobSuccess_Checkbox',
                                                                        },
                                                                    ],
                                                                },
                                                                {
                                                                    label: 'Job cancellations',
                                                                    values: [
                                                                        {
                                                                            consumerName: 'PlatformNotificationConsumer',
                                                                            options: [
                                                                                {
                                                                                    key: 'IncludeJobCancelledNotifications',
                                                                                },
                                                                            ],
                                                                            testId: 'Notifications_PlatformJobCancelled_Checkbox',
                                                                        },
                                                                    ],
                                                                },
                                                                {
                                                                    label: 'Job failures',
                                                                    values: [
                                                                        {
                                                                            consumerName: 'PlatformNotificationConsumer',
                                                                            options: [
                                                                                {
                                                                                    key: 'IncludeJobErrorNotifications',
                                                                                },
                                                                            ],
                                                                            testId: 'Notifications_PlatformJobFailed_Checkbox',
                                                                        },
                                                                        {
                                                                            consumerName: 'EmailNotificationConsumer',
                                                                            options: [
                                                                                {
                                                                                    key: 'JobErrorNotificationEmailFormat',
                                                                                    trueValue: 'INDIVIDUAL',
                                                                                    falseValue: 'NONE',
                                                                                },
                                                                            ],
                                                                            testId: 'Notifications_EmailIndividualJobFailed_Checkbox',
                                                                        },
                                                                        {
                                                                            consumerName: 'EmailNotificationConsumer',
                                                                            options: [
                                                                                {
                                                                                    key: 'JobErrorNotificationEmailFormat',
                                                                                    trueValue: 'DIGEST',
                                                                                    falseValue: 'NONE',
                                                                                },
                                                                            ],
                                                                            testId: 'Notifications_EmailDigestJobFailed_Checkbox',
                                                                        },
                                                                    ],
                                                                },
                                                                {
                                                                    label: 'Comments',
                                                                    values: [
                                                                        {
                                                                            consumerName: 'PlatformNotificationConsumer',
                                                                            options: [
                                                                                {
                                                                                    key: 'IncludeMentionComments',
                                                                                },
                                                                                {
                                                                                    key: 'IncludeReplyComments',
                                                                                },
                                                                            ],
                                                                            testId: 'Notifications_PlatformComment_Checkbox',
                                                                        },
                                                                        {
                                                                            consumerName: 'EmailNotificationConsumer',
                                                                            options: [
                                                                                {
                                                                                    key: 'MentionCommentEmailFormat',
                                                                                    trueValue: 'INDIVIDUAL',
                                                                                    falseValue: 'NONE',
                                                                                },
                                                                                {
                                                                                    key: 'ReplyCommentEmailFormat',
                                                                                    trueValue: 'INDIVIDUAL',
                                                                                    falseValue: 'NONE',
                                                                                },
                                                                            ],
                                                                            testId: 'Notifications_EmailIndividualComment_Checkbox',
                                                                        },
                                                                        {
                                                                            consumerName: 'EmailNotificationConsumer',
                                                                            options: [
                                                                                {
                                                                                    key: 'MentionCommentEmailFormat',
                                                                                    trueValue: 'DIGEST',
                                                                                    falseValue: 'NONE',
                                                                                },
                                                                                {
                                                                                    key: 'ReplyCommentEmailFormat',
                                                                                    trueValue: 'DIGEST',
                                                                                    falseValue: 'NONE',
                                                                                },
                                                                            ],
                                                                            testId: 'Notifications_EmailDigestComment_Checkbox',
                                                                        },
                                                                    ],
                                                                },
                                                                {
                                                                    label: 'Followed Tags',
                                                                    values: [
                                                                        {
                                                                            consumerName: 'PlatformNotificationConsumer',
                                                                            options: [
                                                                                {
                                                                                    key: 'IncludeHashtagUsageNotifications',
                                                                                },
                                                                            ],
                                                                            testId: 'Notifications_PlatformHashtag_Checkbox',
                                                                        },
                                                                        {
                                                                            consumerName: 'EmailNotificationConsumer',
                                                                            options: [
                                                                                {
                                                                                    key: 'HashtagUsageNotificationEmailFormat',
                                                                                    trueValue: 'INDIVIDUAL',
                                                                                    falseValue: 'NONE',
                                                                                },
                                                                            ],
                                                                            testId: 'Notifications_EmailIndividualHashtag_Checkbox',
                                                                        },
                                                                        {
                                                                            consumerName: 'EmailNotificationConsumer',
                                                                            options: [
                                                                                {
                                                                                    key: 'HashtagUsageNotificationEmailFormat',
                                                                                    trueValue: 'DIGEST',
                                                                                    falseValue: 'NONE',
                                                                                },
                                                                            ],
                                                                            testId: 'Notifications_EmailDigestHashtag_Checkbox',
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                            disabled: !this.currentUserObject || this.notificationUpdateInProgress,
                                                            notificationPreferences: this.userNotificationPreferences,
                                                        },
                                                        events: {
                                                            preferences: (value) => {
                                                                this.updateNotificationPreferences(value);
                                                            },
                                                        },
                                                    },
                                                    {
                                                        type: 'custom',
                                                        componentType: HashtagSubscriptionManager,
                                                        props: {
                                                            allHashtags: this.allHashtags,
                                                            followedHashtags: this.followedHashtags,
                                                        },
                                                        events: {
                                                            remove: (value) => {
                                                                this.unfollowHashtag(value);
                                                            },
                                                        },
                                                    },
                                                ],
                                            },
                                            events: {},
                                        };
                                    },
                                },
                            },
                        ],
                    },
                ],
            };
        },
    };
}
