import { ROUTES, APP_TITLE } from '../constants.js';

export const PetroPlusMainMenuItem = function PetroPlusMainMenuItem({ app, plugin }) {
    return {
        type: 'fragment',
        target: 'main-menu-item',
        priority: 1,
        options() {
            return {
                props: {
                    tooltip: APP_TITLE,
                    icon: 'add',
                    isSelected: this.petroPlusRouteActive,
                    bottom: true,
                    hide: !(app.$auth.userIsAdmin || app.$auth.userIsSupportEngineer),
                },
                events: {
                    click: () => {
                        if (app.$router.currentRoute.name !== ROUTES.PetroPlus) {
                            app.$router.push({ name: ROUTES.PetroPlus });
                        }
                    },
                },
            };
        },
    };
};
