export function DeleteJobsDialog({ app, plugin }) {
    return {
        type: 'fragment',
        target: 'dialog',
        priority: 1,
        options() {
            return {
                model: this.showDeleteJobsDialog,
                props: {
                    title: 'Delete Jobs',
                    sections: [
                        {
                            type: 'text',
                            props: {
                                value: this.deleteSelectedJobsConfirmMessage,
                            },
                        },
                    ],
                    actionButtons: [
                        {
                            props: {
                                label: 'Cancel',
                                tooltip: 'Do not delete any jobs',
                                flat: true,
                                outline: false,
                                color: 'primary',
                            },
                            events: {
                                click: () => {
                                    this.showDeleteJobsDialog = false;
                                },
                            },
                        },
                        {
                            props: {
                                label: 'Delete',
                                tooltip: 'Delete the job(s)',
                                flat: false,
                                outline: false,
                                color: 'primary',
                                testId: 'DeleteJobsDialog_Delete_Button',
                            },
                            events: {
                                click: () => {
                                    this.deleteSelectedJobs();
                                    this.showDeleteJobsDialog = false;
                                },
                            },
                        },
                    ],
                },
                events: {
                    input: (value) => {
                        this.showDeleteJobsDialog = value;
                    },
                },
            };
        },
    };
}
