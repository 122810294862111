<template>
    <q-avatar
        :style="{ height: dimension, width: dimension, borderRadius: borderRadius }"
    >
        <img
            v-if="url"
            :src="url"
            :alt="`userProfileImg:${userObject ? userObject.userId : ''}`"
            :class="{ 'profile-border': showImageBorder }"
        >
        <LetterAvatar
            v-else-if="userName"
            :label="userName"
            :font-size="fontSize"
            class="full-width full-height"
        />
        <slot
            v-if="showBadge"
            name="badge"
        />
        <q-tooltip
            v-if="!hideTooltip && userObject"
        >
            <UserFullName
                :user="userObject"
                hide-link
            />
        </q-tooltip>
    </q-avatar>
</template>

<script>
import LetterAvatar from '../../shared-components/LetterAvatar.vue';

export default {
    name: 'UserProfileImage',
    components: { LetterAvatar },
    props: {
        dimension: {
            type: String,
            required: false,
            default: '1em',
        },
        showBadge: {
            type: Boolean,
            required: false,
            default: false,
        },
        user: {
            type: [Object, String],
            required: false,
            default: null,
        },
        hideTooltip: {
            type: Boolean,
            required: false,
            default: false,
        },
        fontSize: {
            type: String,
            required: false,
            default: null,
        },
        borderRadius: {
            type: String,
            required: false,
            default: '50%',
        },
        showImageBorder: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        userObject() {
            if (!this.user) {
                return null;
            }
            if (typeof this.user === 'object') {
                return this.user;
            }
            return this.$users.getUser(this.user);
        },
        url() {
            if (!this.userObject) {
                return null;
            }
            return this.userObject.profileThumbnailUrl;
        },
        userName() {
            return this.userObject ? `${this.userObject.firstName} ${this.userObject.lastName}` : null;
        },
    },
};
</script>

<style scoped lang="stylus">
@import '../../../css/quasar.variables.styl';
.profile-border {
    border-color: $secondary;
    border-width: 2px
    border-style: solid;
}
</style>
