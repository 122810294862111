<template>
    <div>
        <div class="q-px-md">
            <q-btn
                v-cypress="'WorkspaceStatus_Update'"
                label="Update"
                @click="getResources"
            />
        </div>
        <q-tabs
            v-model="tab"
            dense
            class="text-grey"
            active-color="primary"
            indicator-color="primary"
            align="justify"
            narrow-indicator
        >
            <q-tab
                name="flows"
                label="Flows"
            />
            <q-tab
                name="resources"
                label="Resources"
            />
            <q-tab
                name="workspaces"
                label="Workspaces"
            />
            <q-tab
                name="info"
                label="Info"
            />
        </q-tabs>

        <q-separator/>
        <q-tab-panels
            v-model="tab"
            animated
        >
            <q-tab-panel name="flows">
                <component
                    :is="'DataTable'"
                    v-bind="flowsTableBind"
                />
            </q-tab-panel>

            <q-tab-panel name="resources">
                <component
                    :is="'DataTable'"
                    v-bind="resourceTableBind"
                    v-on="{ selected: showResources }"
                />
            </q-tab-panel>

            <q-tab-panel name="workspaces">
                <component
                    :is="'DataTable'"
                    v-bind="workspaceTableBind"
                />
            </q-tab-panel>
            <q-tab-panel name="info">
                <div>
                    <div>
                        Forge Resource: {{ resource }}
                        {{ forgeResources }}
                        Workspace: {{ workspace }}
                    </div>
                </div>
            </q-tab-panel>
        </q-tab-panels>
        <div>
            {{ resources }}
            <ResourceView
                v-for="res in resources"
                :key="res"
                :resource="res"
            />
        </div>
    </div>
</template>

<script>
import ResourceView from './ResourceView';

function makeCols(fields) {
    return fields.map(field => {
        return {
            name: field,
            align: 'left',
            label: field,
            field,
            sortable: true,
        };
    });
}

export default {
    name: 'WorkspaceStatus',
    components: {
        ResourceView,
    },
    props: {
        resource: {
            type: String,
            required: true,
        },
        parseFunc: {
            type: Function,
            required: true,
        },
        subscribeFunc: {
            type: Function,
            required: true,
        },
        getWorkspaceFunc: {
            type: Function,
            required: true,
        },
        tableParentId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            tab: 'flows',
            workspaceInfo: {
                workspaces: [],
                flows: [],
                resources: [],
            },
            updateAction: {
                props: {
                    label: 'Update',
                    tooltip: 'Update values',
                },
                events: {
                    click: this.getResources,
                },
            },
            resources: [],
        };
    },
    computed: {
        workspaceTableBind() {
            return {
                forgeResources: [],
                crudActions: [],
                actionButtons: [this.updateAction],
                data: this.workspaceInfo.workspaces,
                columns: makeCols(['name', 'fullName', 'workspace']),
                tableType: 'ForgeWorkspace',
                parentId: this.tableParentId,
            };
        },
        resourceTableBind() {
            return {
                forgeResources: [],
                crudActions: [],
                actionButtons: [this.updateAction],
                data: this.workspaceInfo.resources,
                columns: makeCols(['name', 'workspace', 'type', 'rowCount']),
                tableType: 'ForgeResources',
                parentId: this.tableParentId,
            };
        },
        flowsTableBind() {
            const flowData = this.workspaceInfo.flows.map(flow => {
                return {
                    ...flow,
                    ...flow.measures,
                };
            });

            return {
                forgeResources: [],
                crudActions: [],
                actionButtons: [this.updateAction],
                data: flowData,
                columns: makeCols(['name', 'workspace', 'needsUpdate', 'isUpdating', 'isRunning', 'hasError', 'errors', 'runCount', 'lastElapsedSeconds', 'uptime']),
                tableType: 'ForgeFlows',
                parentId: this.tableParentId,
            };
        },
        forgeResources() {
            if (this.resource === null || this.resource === '') return [];
            return [this.resource];
        },
        workspace() {
            const parts = this.$compute.forgeParseResource(this.resource);
            return parts.workspace;
        },
    },
    watch: {
        forgeResources() {
            this.connect();
        },
    },
    async beforeMount() {
        this.connect();
    },
    beforeDestroy() {
        if (this.forgeDisposableReference) {
            this.forgeDisposableReference.dispose();
        }
    },
    methods: {
        showResources(values) {
            this.resources = values.map(value => `${value.workspace}#${value.name}`);
        },
        forgeElement(name) {
            return this.$store.getters['compute/getElement'](name);
        },
        connect() {
            if (this.forgeDisposableReference) {
                this.forgeDisposableReference.dispose();
            }
            // Subscribe to the forge resources using name of component with it's uid as subscriber
            this.forgeDisposableReference = this.$compute.forgeSubscribeElements(`${this.$options.name}-${this._uid}`, this.forgeResources);
            this.getResources();
        },
        getResources() {
            this.$compute.forgeGetWorkspace(this.workspace)
            .then(response => {
                // got the graph
                this.workspaceInfo = response.data[0];
            }).catch(error => {
                console.error('Could not get workspace ', error);
            });
        },
    },
};
</script>

<style scoped>

</style>
