import { testingUtils } from '../../tests/e2e/support/app/testing-utils.js';

export function cypressDirective(vueInstance) {
    vueInstance.directive('cypress', {
        bind(el, binding) {
            let dataAttribute = testingUtils.cypressDataAttribute;
            if (binding.arg != null) {
                dataAttribute = binding.arg;
            }
            if (binding.value) {
                el.setAttribute(dataAttribute, binding.value);
            }
        },
    });
}
