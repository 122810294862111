import { ForgeDisposableReference } from './ForgeDisposableReference.js';
import { ForgeElementHandle } from './ForgeElementHandle.js';

export class ForgeWorkspaceHandle {
    constructor({
        log,
        workspace,
        onElementCreated,
        onElementRemoved,
        onCleanup,
    }) {
        this.log = log;
        this.workspace = workspace;
        this.connected = false;
        this.hasError = false;

        this.__elementHandles = {};
        this.__elementCreated = onElementCreated;
        this.__elementRemoved = onElementRemoved;
        this.__cleanup = onCleanup;
    }

    get elementHandles() {
        return Object.values(this.__elementHandles);
    }

    get(elementName) {
        return this.__elementHandles[elementName];
    }

    subscribe(subscriber, elInfo) {
        // Parse the element uri in name!format syntax
        const parts = elInfo.split('!');
        const elementName = parts[0];
        const format = parts.length > 1 ? parts[1] : '';

        // Get the existing element if it exists or create a new one
        let elementHandle = null;
        if (this.__elementHandles[elementName]) {
            elementHandle = this.__elementHandles[elementName];
        }
        else {
            elementHandle = new ForgeElementHandle({
                log: this.log,
                workspace: this.workspace,
                elementName,
                format,
                uri: `${this.workspace}#${elementName}`,
                onCleanup: (emptyElementHandle) => {
                    this.log.debug('Remove handle for', elementName);
                    this.removeElementHandle(elementName);
                },
            });

            // Add local reference to the element handle
            this.__elementHandles[elementName] = elementHandle;

            // Inform caller that element was created
            if (this.__elementCreated) {
                this.__elementCreated(elementHandle);
            }
        }

        elementHandle.subscribe(subscriber);

        const disposableReference = new ForgeDisposableReference({
            onDispose: () => {
                this.log.debug('Removing resource', elementName);
                elementHandle.unsubscribe(subscriber);
            },
        });
        return disposableReference;
    }

    removeElementHandle(elementName) {
        if (this.__elementHandles[elementName]) {
            const elementHandle = this.__elementHandles[elementName];

            // Remove the local reference to the element handle
            delete this.__elementHandles[elementName];
            this.log.debug('Element handle removed:', elementHandle);

            // Inform caller of element removal
            if (this.__elementRemoved) {
                this.__elementRemoved(elementHandle);
            }

            // Trigger cleanup of workspace if no more elements are being tracked.
            if (Object.keys(this.__elementHandles).length === 0) {
                this.__cleanup(this);
            }
        }
    }
}
