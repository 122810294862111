import Vue from 'vue';

const filterUpdated = (state, tableId, suppressBroadcast = false) => {
    const table = state.dataTables[tableId];
    table.propagateFilters();
    table.filteredAt = new Date();
    if (!suppressBroadcast) {
        broadcastFilter(state, table);
    }
};

const broadcastFilter = (state, table) => {
    const filter = getFilterState(state, table.parentId);
    Vue.$compute.broadcast({
        pid: table.parentId,
        marking: null,
        filtering: filter,
        stateId: table.id,
    });
};

const broadcastMarking = (state, table) => {
    const items = table.marked();

    Vue.$compute.broadcast({
        pid: table.parentId,
        marking: items,
        filtering: null,
        stateId: table.id,
    });
};

const getFilterState = (state, parentId) => {
    const tables = Object.values(state.dataTables);
    return tables.filter(tbl => tbl.parentId === parentId).map(table => {
        const filters = Object.values(table.filters).map(col => {
            return {
                name: col.name,
                value: col.getFilter(),
            };
        });
        return {
            name: table.name,
            filters,
        };
    });
};

const forge = {
    namespaced: true,
    state: {
        // All DataTable instances
        dataTables: {},
    },
    mutations: {
        setFilter(state, { dimension, range, values }) {
            if (range) {
                dimension.setRange(range);
            }
            else if (values) {
                dimension.setValues(values);
            }
            // propagate filter
            filterUpdated(state, dimension.tableId);
        },
        clearFilters(state, tableId) {
            const table = state.dataTables[tableId];
            table.removeAllDimensions();
            filterUpdated(state, tableId);
        },
        applyFilter(state, { parentId, filter, suppressBroadcast = false }) {
            // Get the tables associated with the parentId
            const tables = Object.values(state.dataTables);
            filter.forEach(tblFilter => {
                const table = tables.find(x => x.parentId === parentId && x.name === tblFilter.name);

                if (table) {
                    table.applyFilter(tblFilter.filters);
                    filterUpdated(state, table.id, suppressBroadcast);
                }
            });
        },
    },
    actions: {
        markIndices({ state, commit }, {
            tableId, ids, op, suppressBroadcast = false,
        }) {
            const table = state.dataTables[tableId];
            if (!table) {
                return;
            }
            table.markIndices(ids, op);
            if (!suppressBroadcast) {
                broadcastMarking(state, table);
            }
        },
    },
};

export default forge;
