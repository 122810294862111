<template>
    <div>
        <q-btn
            round
            :color="color"
            style="height: 1rem; width: 1rem"
            @click="$emit('click')"
        >
            <q-tooltip :delay="750">
                Live data status
            </q-tooltip>
        </q-btn>
    </div>
</template>

<script>

export default {
    name: 'ForgeStatusIndicator',
    components: {
    },
    data() {
        return {
            showDialog: false,
            tooltip: 'Live data status',
        };
    },
    computed: {
        color() {
            if (this.errorElementsInfo.length || !this.forgeConnectionLive) {
                return 'negative';
            }
            if (this.staleElementsInfo.length) {
                return 'warning';
            }
            return 'positive';
        },
        forgeConnectionLive() {
            return this.$compute.connectionLive;
        },
        staleElementsInfo() {
            return this.$compute.staleElementsInfo;
        },
        errorElementsInfo() {
            return this.$compute.errorElementsInfo;
        },
    },
    methods: {
        clicked() {
            this.$telemetry.trackUserEvent({
                name: this.$telemetry.eventNames.BUTTON_CLICK,
                properties: {
                    buttonLabel: this.tooltip,
                    buttonTooltip: this.tooltip,
                },
            });
            this.$emit('click');
        },
    },
};
</script>

<style scoped>

</style>
