<template>
    <div class="editor q-pa-sm">
        <editor-content
            :ref="editorRef"
            :editor="editor"
            class="editor__content"
        />
        <EditorSuggestion
            :editor="editor"
            editable
            :extension="hashtagExtension"
            :suggestions="getHashtags"
            :resolve-unmatched-suggestion="resolveUnmatchedHashtagSuggestion"
            popup-mount-selector=".editor"
            no-suggestions-text="No tags found"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Editor, EditorContent } from 'tiptap';
import { Placeholder } from 'tiptap-extensions';
import Hashtag from './Hashtag.js';
import RootEnter from './RootEnter.js';
import EditorSuggestion from './EditorSuggestion.vue';

export default {
    name: 'HashtagEditor',
    components: {
        EditorSuggestion,
        EditorContent,
    },
    props: {
        placeholder: {
            type: String,
            required: false,
            default: '',
        },
        content: {
            type: String,
            required: false,
            default: '',
        },
        clearAfterSubmit: {
            type: Boolean,
            required: false,
            default: false,
        },
        filteredTags: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    data() {
        return {
            editorRef: `editor-${Math.random().toString(36).substr(2, 9)}`,
            hashtagExtension: Hashtag,
            editor: new Editor({
                autoFocus: true,
                editable: true,
                content: this.content,
                // On each input save the htmlContent
                onUpdate: ({ getHTML }) => {
                    const htmlContent = getHTML();
                    this.htmlContent = htmlContent;
                },
                extensions: [
                    new RootEnter({
                        handler: this.submit,
                    }),
                    new Placeholder({
                        emptyEditorClass: 'is-editor-empty',
                        emptyNodeClass: 'is-empty',
                        emptyNodeText: this.placeholder,
                        showOnlyWhenEditable: true,
                        showOnlyCurrent: true,
                    }),
                ],
            }),
            htmlContent: this.content,
        };
    },
    computed: {
        ...mapGetters('compute', {
            getElement: 'getElement',
        }),
        allTags() {
            const allTagsForgeElement = this.getElement(this.$tags.tagInfoForgeResource);
            if (allTagsForgeElement && allTagsForgeElement.state === 'ready' && allTagsForgeElement.data) {
                return allTagsForgeElement.data;
            }
            return [];
        },
    },
    watch: {
        content: {
            immediate: true,
            handler(newContent) {
                this.editor.setContent(newContent);
            },
        },
    },
    beforeDestroy() {
        this.editor.destroy();
    },
    methods: {
        focus() {
            // Hack but must attempt focus after tiptap has updated element--there are no events from tiptap to trigger this
            setTimeout(() => {
                const editorRef = this.$refs[this.editorRef];
                if (editorRef) {
                    const editorInputDOMNode = editorRef.$el.querySelector('[contenteditable=true]');
                    if (editorInputDOMNode) {
                        editorInputDOMNode.focus();
                    }
                }
            }, 10);
        },
        submit() {
            const tags = this.getTagsFromContent(this.htmlContent);
            this.$emit('submit', { tags });
            // Clear the content of the editor after the user has submitted their comment
            if (this.clearAfterSubmit) {
                this.editor.setContent('');
            }
        },
        getHashtags() {
            if (this.allTags) {
                const tagsToShow = this.allTags.filter(tag => !this.filteredTags.find(filterTag => filterTag.id === tag.id));
                return tagsToShow.map(tagInfo => {
                    return {
                        id: tagInfo.content,
                        label: tagInfo.content,
                    };
                })
                .sort((a, b) => {
                    if (a.label < b.label) {
                        return -1;
                    }
                    if (a.label > b.label) {
                        return 1;
                    }
                    return 0;
                });
            }
            return [];
        },
        resolveUnmatchedHashtagSuggestion(query) {
            return {
                id: query,
                label: query,
            };
        },
        getTagsFromContent(htmlContent) {
            // Extract all hashtags from the html using regex capture group.
            const matches = [...htmlContent.matchAll(/data-tag-id="([A-Za-z0-9]+)"/g)];
            return matches.map(match => match[1]);
        },
    },
};
</script>

<style lang="stylus">
@import '../../../../css/quasar.variables.styl';
$color-black = #000000;
$color-white = #ffffff;

/* Remove the default focus from prose mirror */
.ProseMirror:focus {
    outline: none;
}

.editor {
    position: relative;
}

.editor__content {
    border: $separator-color;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;

    * {
        caret-color: currentColor;
    }
}

.hashtag {
    font-weight: bold;
    white-space: nowrap;
    text-decoration: none;
}
.hashtag-suggestion {
    color: $primary;
}

// Needed for suggestions
.tippy-box[data-theme~=dark] {
    background-color: $color-black;
    padding: 0;
    font-size: 1rem;
    text-align: inherit;
    color: $color-white;
    border-radius: 5px;
}
</style>
