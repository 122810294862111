<template>
    <div class="q-py-lg">
        <div class="text-h6">
            Follow hashtags
        </div>
        <div class="q-py-md">
            Quickly identify, search, and notify collaborators by adding hashtags to comments.
        </div>
        <HashtagEditor
            v-cypress="'HashtagSubscriptionManager_HashtagFollow_Div'"
            editable
            clear-after-submit
            placeholder="Type a hashtag to follow"
            class="hashtag-input q-px-sm"
            :filtered-tags="followedTagIds"
            @submit="tagSubmitted"
        />
        <div class="q-py-md">
            See and share your work more easily inside Petro.ai by using and following hashtags.
        </div>
        <div
            v-cypress="'HashtagSubscriptionManager_FollowedTags_Div'"
        >
            <div>{{ followedHashtagsMessage }}</div>
            <q-chip
                v-for="(hashtag, idx) in displayedFollowedHashtags"
                :key="`tag-${idx}`"
                dense
            >
                <span class="text-grey-9 q-pr-xs">{{ `#${hashtag.content}` }}</span>
                <q-icon
                    name="clear"
                    color="grey-2"
                    class="remove-subscription"
                    @click="unfollowHashtag(hashtag)"
                >
                    <q-tooltip :delay="750">
                        Stop following
                    </q-tooltip>
                </q-icon>
            </q-chip>
        </div>
    </div>
</template>

<script>
import HashtagEditor from '../../../plugin-framework/shared-components/comments/editor/HashtagEditor';

export default {
    name: 'HashtagSubscriptions',
    components: {
        HashtagEditor,
    },
    props: {
        allHashtags: {
            type: Array,
            required: true,
        },
        followedHashtags: {
            type: Array,
            required: true,
        },
    },
    computed: {
        displayedFollowedHashtags() {
            const detailedHashtags = this.followedHashtags.map(followedTag => {
                const detailsMatch = this.allHashtags.find(tag => tag.id === followedTag.followDataId);
                const details = {
                    followEntry: { ...followedTag },
                };
                if (detailsMatch) {
                    details.content = detailsMatch.content;
                }
                return details;
            }).filter(detailedTag => detailedTag.content);
            detailedHashtags.sort((a, b) => a.content.localeCompare(b.content));
            return detailedHashtags;
        },
        currentUserId() {
            return this.$auth.user.userId;
        },
        followedHashtagsMessage() {
            return this.followedHashtags.length === 1 ? '1 followed hashtag' : `${this.followedHashtags.length} hashtags followed`;
        },
        followedTagIds() {
            return this.followedHashtags.map(followTag => {
                return {
                    id: followTag.followDataId,
                };
            });
        },
    },
    methods: {
        createHashtag(content) {
            if (!content) {
                return;
            }
            // Does the tag already exist?
            const tagExists = this.allHashtags.find(tag => tag.content === content);
            if (tagExists) {
                // Subscribe to existing one.
                this.followHashtag(tagExists, `Awesome! Now following #${tagExists.content}.`);
                return;
            }
            // It does not exist. Create it before adding the follow.
            const data = [{
                approvalStatus: 'User',
                content,
            }];
            this.$api.data.insert({
                type: 'AnnotationTag',
                data,
            }).then((newTagData) => {
                if (newTagData && newTagData.insertedIds && newTagData.insertedIds.length) {
                    this.followHashtag(
                        {
                            id: newTagData.insertedIds[0],
                            content,
                        },
                        `Excellent! Tag comments with #${content}.`,
                    );
                }
            }).catch((issue) => {
                this.$notify.error(`Something went wrong creating #${content}.`);
            });
        },
        followHashtag(hashtag, successMessage) {
            const followExists = this.followedHashtags.find(tag => tag.followDataId === hashtag.id);
            if (followExists) {
                this.$notify.success(`You're already following #${hashtag.content}.`);
                return;
            }
            const data = [{
                followDataId: hashtag.id,
                followDataType: 'AnnotationTag',
                userId: this.currentUserId,
            }];
            this.$api.data.insert({
                type: 'FollowEntry',
                data,
            }).then(() => {
                if (successMessage) {
                    this.$notify.success(successMessage);
                }
            }).catch(() => {
                this.$notify.error(`Something went wrong following #${hashtag.content}`);
            });
        },
        unfollowHashtag(hashtag) {
            this.$api.data.delete({
                type: 'FollowEntry',
                query: {
                    followDataId: hashtag.followEntry.followDataId,
                    userId: this.currentUserId,
                },
            }).then(() => {
                this.$notify.success(`#${hashtag.content} unfollowed.`);
            }).catch(() => {
                this.$notify.error(`Something went wrong unfollowing #${hashtag.content}.`);
            });
        },
        tagSubmitted(submittedContent) {
            if (submittedContent.tags && submittedContent.tags.length) {
                const sanitizedTags = new Set();
                submittedContent.tags.forEach(tag => {
                    // Strip any hash symbols from the beginning.
                    const text = tag.replace(new RegExp('^["#"]+'), '').trim();
                    if (text.length) {
                        sanitizedTags.add(text);
                    }
                });
                sanitizedTags.forEach(tag => {
                    this.createHashtag(tag);
                });
            }
        },
    },
};
</script>

<style scoped lang="stylus">
@import '../../../css/quasar.variables.styl';
.remove-subscription {
    background-color: $grey-8;
    border-radius: 50%;
    cursor: pointer;
}
.hashtag-input {
    border: solid 1px $separator-color;
    width: 50%;
}
</style>
