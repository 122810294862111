<template>
    <div
        v-shortkey="['f2']"
        class="q-px-md q-pb-md"
        @shortkey="editScript"
    >
        <div
            v-if="hasError"
        >
            <p>
                {{ errorMessage }}
            </p>
        </div>
        <div
            v-if="showPlot"
            :id="chartId"
        />
        <q-inner-loading :showing="!showPlot && !hasError">
            <div class="row">
                <q-spinner
                    size="30px"
                    color="primary"
                />
            </div>
            <div class="row">
                <p>Rendering chart...</p>
            </div>
        </q-inner-loading>
    </div>
</template>

<script>
import { embed, set_log_level as setLogLevel } from '@bokeh/bokehjs';
import { debounce, uid } from 'quasar';
import { mapState } from 'vuex';

// Set logging level for BokehJS
// See https://github.com/bokeh/bokeh/blob/2.0.2/bokehjs/src/lib/core/logging.ts
if (process.env.NODE_ENV === 'development') {
    setLogLevel('warn');
}
else {
    setLogLevel('error');
}

export default {
    name: 'BokehChart',
    props: {
        // Incoming forge compiled JSON object representing the bokeh plot
        plot: {
            // type: Object,
            validator: val => val === null || val === undefined || typeof val === 'object',
            required: true,
        },
        // The underlying forge python code that is used to compile the plot
        script: {
            type: String,
            required: false,
            default: null,
        },
        // Object containing the data sources that are passed to the plot
        sources: {
            required: false,
            validator: val => val === null || val === undefined || typeof val === 'object',
            default: () => {},
        },
        // Debounce used for updating the plot data sources
        debounce: {
            type: Number,
            required: false,
            default: 250,
        },
    },
    data() {
        return {
            chartId: `plot-${uid()}`,
            bokehViews: [],
            bokehDocument: null,
        };
    },
    computed: {
        // Pull in status of the panels so bokeh chart can resize itself when needed
        ...mapState('app', {
            filterPanelOpen: state => state.filterPanelOpen,
            commentPanelOpen: state => state.commentPanelOpen,
        }),
        plotHasElements() {
            return this.plot && Object.keys(this.plot).length > 0;
        },
        // Compiled JSON from forge has an error or plot data contains an error
        hasError() {
            return this.plot && (this.plot.error || (this.plot.plotData && this.plot.plotData.error));
        },
        errorMessage() {
            if (this.plot.error) {
                return this.plot.error;
            }
            return this.plot.plotData.error;
        },
        showPlot() {
            return !this.hasError && this.plotHasElements;
        },
    },
    watch: {
        plot: {
            handler(newValue) {
                if (newValue) {
                    this.embedPlot();
                }
            },
        },
        sources: {
            deep: true,
            handler(newValue) {
                if (newValue) {
                    this.updateSources();
                }
            },
        },
        // Each time panel is toggled resize all bokeh views
        filterPanelOpen: {
            immediate: true,
            handler() {
                if (this.bokehViews.length) {
                    this.bokehViews.forEach(view => {
                        // Bug #6079. If the resize is called too soon after the panels are toggled,
                        // it has no effect; use setTimeout to cause a delay.
                        setTimeout(() => {
                            view.resize_layout();
                        }, 500);
                    });
                }
            },
        },
        // Each time panel is toggled resize all bokeh views
        commentPanelOpen: {
            immediate: true,
            handler() {
                if (this.bokehViews.length) {
                    this.bokehViews.forEach(view => {
                        // Bug #6079. If the resize is called too soon after the panels are toggled,
                        // it has no effect; use setTimeout to cause a delay.
                        setTimeout(() => {
                            view.resize_layout();
                        }, 500);
                    });
                }
            },
        },
    },
    created() {
        // Avoids debounce method and debounce time from being shared between instances
        // See https://v1.quasar.dev/quasar-utils/other-utils#debounce-function
        this.embedPlot = debounce(this.embedPlot, this.debounce);
        this.updateSources = debounce(this.updateSources, this.debounce);
    },
    mounted() {
        this.embedPlot();
    },
    beforeDestroy() {
        if (this.bokehDocument) {
            this.bokehDocument.clear();
        }
    },
    methods: {
        embedPlot() {
            if (this.plotHasElements) {
                const divId = `#${this.chartId}`;
                // We need to clean out the plot and put in the new one
                const div = document.querySelector(divId);

                // Check that chart exists and that empty plot object is not passed to Bokeh embed
                if (div !== null && Object.keys(this.plot.plotData).length) {
                    // Convert NodeList into an array then remove all the children
                    [].slice.call(div.children).forEach(child => div.removeChild(child));

                    embed.embed_item(this.plot.plotData, this.chartId)
                    .then(views => {
                        // Save the initialized bokeh views to call later if needed
                        this.bokehViews = views;

                        this.bokehViews.forEach(bokehView => {
                            if (bokehView.model) {
                                // Attach to the root element
                                bokehView.model.document.handleEvent = this.handleEvent;
                                // Save the bokeh document
                                this.bokehDocument = bokehView.model.document;
                            }
                        });
                    });

                    this.updateSources();
                }
            }
        },
        updateSources() {
            if (this.sources && this.bokehDocument) {
                Object.entries(this.sources).forEach(([sourceName, sourceData]) => {
                    // Find the bokeh model that represents the column data source
                    const model = Object.values(this.bokehDocument._all_models).find(bokehModel => bokehModel.name === sourceName && bokehModel.type === 'ColumnDataSource');
                    if (model) {
                        // Ensure that the source exists and is not an empty object
                        if (sourceData && Object.keys(sourceData).length) {
                            try {
                                model.data = sourceData;
                                // Force the model to signal that it has changed
                                model.change.emit();
                            }
                            catch (error) {
                                this.$logging.loggers.PluginFramework.error(`BokehChart unable to set source for '${sourceName}':`, error);
                            }
                        }
                    }
                });
            }
        },
        async editScript() {
            if (process.env.NODE_ENV === 'development' && this.script) {
                const result = await this.$actions.promptForm({
                    title: 'Edit Script',
                    size: 'lg',
                    message: '',
                    value: {
                        script: this.script,
                    },
                    schema: {
                        dense: true,
                        inputs: [
                            {
                                key: 'script',
                                inputType: 'code',
                                label: 'Code',
                                lang: 'python',
                                hint: 'Code for the dashboard',
                            },
                        ],
                    },
                });

                if (result.success) {
                    this.$emit('scriptUpdate', result.data.script);
                }
            }
        },
        handleEvent(name, data) {
            this.$emit(name, data);
        },
    },
};
</script>

<style>
/* Taken from the petro-bokeh CSS overrides for bokeh server apps */
.bk-btn-primary {
    background-color: #6e1e55 !important;
    border-color: #6e1e55 !important;
    color: #ffffff !important;
}

.bk-btn-success {
    background-color: #74b540 !important;
    border-color: #74b540 !important;
    color: #ffffff !important;
}

.bk-btn-warning {
    background-color: #ff9800 !important;
    border-color: #ff9800 !important;
    color: #ffffff !important;
}

.bk-btn-danger {
    background-color: #fa5a5a !important;
    border-color: #fa5a5a !important;
    color: #ffffff !important;
}

.bk-tab {
    color: #6e1e55 !important;
}

.bk-tab.bk-active {
    font-weight: bold !important;
    background-color: initial !important;
    border: initial !important;
    border-bottom: 3px solid #FCB62C !important;
}

.bk-btn-outline button.bk.bk-btn{
    border-color: #6e1e55 !important;
    color: #000000 !important;
}
</style>
