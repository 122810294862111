import Vue from 'vue';
import { Logger } from '../logging/logger.js';

class UsersService {
    constructor(options) {
        this.log = new Logger({ name: 'UsersService', level: Logger.levels.warn });
        Vue.$logging.registerLogger(this.log);

        this.store = options.store;
        this.moduleName = options.moduleName;

        // Handle the case where the user is already signed in
        if (Vue.$auth.signedIn) {
            this.store.dispatch(`${this.moduleName}/getOrgUsers`);
        }

        Vue.$auth.afterSignIn('initializeUserService', user => {
            this.store.dispatch(`${this.moduleName}/getOrgUsers`);
        });
    }

    // Returns a user object
    getUser(userId) {
        const user = this.store.getters[`${this.moduleName}/getUser`](userId);
        if (user) {
            return user;
        }

        return null;
    }

    // Return the users profile image URL if it exists
    getUserProfileImage(userId) {
        const user = this.getUser(userId);
        if (user && user.profileThumbnailUrl) {
            return user.profileThumbnailUrl;
        }
        return null;
    }

    getUserFullName(userId) {
        const user = this.store.getters[`${this.moduleName}/getUser`](userId);
        if (user) {
            return `${user.firstName} ${user.lastName}`;
        }
        return '';
    }

    sortUsersByName(users) {
        const sortedUsers = [...users];
        sortedUsers.sort((a, b) => {
            if (!a.lastName) {
                return 1;
            }
            if (!b.lastName) {
                return -1;
            }
            const lastNameSort = a.lastName.localeCompare(b.lastName);
            if ((lastNameSort === 0) && a.firstName && b.firstName) {
                return a.firstName.localeCompare(b.firstName);
            }
            return lastNameSort;
        });
        return sortedUsers;
    }

    get allUsers() {
        return this.store.state[this.moduleName].users;
    }

    get activeUsers() {
        const allUsers = this.allUsers;
        if (allUsers && allUsers.length > 0) return allUsers.filter(user => user.deleted === false);
        return null;
    }
}

export default UsersService;
