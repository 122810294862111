import { Logger } from './logger.js';

export class LoggingService {
    constructor() {
        this.loggers = {};
        this.localStorageKey = 'loggingLevels';
        window.pai.$logging = this;
    }

    registerLogger(logger) {
        if (!(logger instanceof Logger)) {
            throw new Error('registerLogger must be passed an instance of Logger');
        }

        this.loggers[logger.name] = logger;

        // Override the level if logger has been saved before
        const savedLoggingLevels = this.__loadLoggingLevels();
        if (savedLoggingLevels) {
            const savedLevel = savedLoggingLevels[logger.name];
            if (typeof savedLevel === 'number' && LoggingService.verifyLevel(savedLevel, 'registerLogger')) {
                this.setLoggerLevel(logger.name, savedLevel);
            }
        }

        this.__saveLoggingLevel(logger.name, logger.level);
    }

    setLoggerLevel(name, level) {
        const logger = this.loggers[name];
        if (!logger) {
            throw new Error(`Cannot find logger with name ${name}`);
        }

        LoggingService.verifyLevel(level, 'setLoggerLevel');

        logger.level = level;
        this.__saveLoggingLevel(name, level);
    }

    setGlobalLogLevel(level) {
        LoggingService.verifyLevel(level, 'setGlobalLogLevel');

        // Set all loggers to the provided level
        Object.entries(this.loggers).forEach(([name, logger]) => {
            logger.level = level;
            this.__saveLoggingLevel(name, level);
        });
    }

    get loggerNames() {
        const loggerNames = Object.keys(this.loggers);
        return loggerNames;
    }

    __saveLoggingLevel(loggerName, level) {
        const loggingLevels = this.__loadLoggingLevels();
        loggingLevels[loggerName] = level;
        const loggingLevelsData = JSON.stringify(loggingLevels);
        localStorage.setItem(this.localStorageKey, loggingLevelsData);
    }

    __loadLoggingLevels() {
        const loggingLevelsData = localStorage.getItem(this.localStorageKey);
        const loggingLevels = JSON.parse(loggingLevelsData);
        if (loggingLevels) {
            return loggingLevels;
        }
        return {};
    }

    static verifyLevel(level, methodName) {
        if (level > Logger.levels.none || level < Logger.levels.all) {
            throw new Error(`${methodName} must be passed an integer from ${Logger.levels.all} to ${Logger.levels.none}`);
        }
        return true;
    }
}
