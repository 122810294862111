export const FeatureFlagsTemplate = function FeatureFlagsTemplate({ app, plugin }) {
    return {
        type: 'template',
        target: 'full-page',
        route: {
            type: 'root-app',
            path: 'feature-flags',
            name: 'feature-flags',
        },
        options() {
            return {
                props: {
                    visualizations: [
                        {
                            type: 'custom',
                            componentType: 'FeatureFlagsPage',
                            gridArea: '1/1/13/13',
                            props: {
                                currentFeatureFlags: app.$globalSettings.settings.featureFlags,
                            },
                            events: {
                                click: () => {
                                    this.showFeatureFlagDialog = true;
                                },
                            },
                        },
                    ],
                },
                events: {},
            };
        },
    };
};
