<template>
    <q-page
        v-if="forgeResourcesLoaded"
        class="column"
        :class="pageClasses"
    >
        <div
            v-if="props.sections && props.sections.length"
            class="row col justify-around q-px-lg"
        >
            <q-card class="col-8 row">
                <component
                    :is="props.component"
                    v-if="props.component"
                    v-bind="props"
                    v-on="events"
                />
                <div
                    v-else-if="props.sections"
                    class="col q-px-lg q-pt-md column"
                >
                    <q-scroll-area class="col-grow q-pa-md">
                        <Section
                            v-for="(section, index) in props.sections"
                            :key="index"
                            :section="section"
                        />
                    </q-scroll-area>
                    <div
                        v-if="showFooter"
                        class="row q-pa-md"
                    >
                        <q-space/>
                        <OmniButton
                            v-for="(button, index) in props.actionButtons"
                            :key="`button-${index}`"
                            :button="button"
                            class="q-pl-sm"
                        />
                    </div>
                </div>
            </q-card>
        </div>

        <div
            v-else-if="props.visualizations"
            ref="gridContainer"
            class="col col-grow"
        >
            <div
                class="chart-grid"
                :style="`height: ${gridHeight}`"
            >
                <VisualizationContainer
                    v-for="(visualization, index) in props.visualizations"
                    :key="index"
                    :style="`grid-area: ${visualization.gridArea};`"
                    :visualization="visualization"
                    :title="visualization.title"
                />
            </div>
        </div>
    </q-page>
    <q-page
        v-else
    >
        <ForgeLoadState
            :resources="forgeResourceElements"
        />
    </q-page>
</template>

<script>
import mixins from '../../mixins/index.js';
import FullPageCard from '../layouts/FullPageCard.vue';
import ForgeLoadState from '../shared-components/ForgeLoadState.vue';
import Section from '../shared-components/sections/Section.vue';
import OmniButton from '../shared-components/inline-elements/OmniButton.vue';
import VisualizationContainer from '../shared-components/visualizations/VisualizationContainer.vue';

export default {
    name: 'FullPageTemplate',
    components: {
        VisualizationContainer,
        FullPageCard,
        ForgeLoadState,
        Section,
        OmniButton,
    },
    mixins: [mixins.compute, mixins.lifecycleHooks({ addProps: false })],
    props: {
        props: {
            type: Object,
            required: true,
        },
        events: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            gridHeight: '100%',
        };
    },
    computed: {
        forgeResources() {
            return this.props.forgeResources || [];
        },
        showFooter() {
            return this.props.actionButtons && this.props.actionButtons.length > 0;
        },
        pageClasses() {
            return {
                'q-py-md': !this.props.noPadding,
                'q-px-md': !this.props.noPadding,
            };
        },
    },
    mounted() {
        // Dynamically set the grid height from the container after it is rendered
        this.setGridHeight();
    },
    updated() {
        // Dynamically set the grid height from the container on each render change
        this.setGridHeight();
    },
    methods: {
        setGridHeight() {
            if (this.$refs.gridContainer) {
                this.gridHeight = `${this.$refs.gridContainer.clientHeight}px`;
            }
        },
    },
};
</script>

<style scoped>
.chart-grid {
    display: grid;
    grid: repeat(12, 1fr) / repeat(12, 1fr);
    grid-gap: 10px;
}
</style>
