export const FormDialog = function ({ app, plugin }) {
    return {
        type: 'fragment',
        target: 'dialog',
        priority: 1,
        options() {
            const dialog = {
                model: app.$actions.showFormDialog,
                props: {
                    title: app.$actions.formOptions.title,
                    size: app.$actions.formOptions.size,
                    sections: [
                        {
                            type: 'auto-form',
                            props: {
                                value: app.$actions.formValue,
                                initialValue: app.$actions.formOptions.value,
                                schema: app.$actions.formOptions.schema,
                            },
                            events: {
                                input: (value) => {
                                    app.$actions.formValue = value;
                                },
                                validate: (value) => {
                                    app.$actions.formValid = value;
                                },
                            },
                        },
                    ],
                    actionButtons: [
                        {
                            props: {
                                label: 'Confirm',
                                tooltip: 'Confirm the action',
                                color: 'accent',
                                textColor: 'primary',
                                outline: false,
                                flat: false,
                                disabled: !app.$actions.formValid,
                            },
                            events: {
                                click: () => {
                                    app.$actions.showFormDialog = false;
                                    if (app.$actions.onConfirm) app.$actions.onConfirm(true, app.$actions.formValue);
                                },
                            },
                        },
                    ],
                },
                events: {
                    input: (value) => {
                        app.$actions.showFormDialog = value;
                        if (!value && app.$actions.onConfirm) app.$actions.onConfirm(false, null);
                    },
                },
            };

            // Add the message if it is provided
            if (app.$actions.formOptions.message) {
                const messageSection = {
                    type: 'text',
                    props: {
                        value: app.$actions.formOptions.message,
                    },
                };
                dialog.props.sections.unshift(messageSection);
            }

            return dialog;
        },
    };
};
