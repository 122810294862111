import { UnitsService } from './units-service.js';
import UnitsModule from './units-module.js';

export const Units = {
    install(Vue, {
        store = null,
    } = {}) {
        if (!store) {
            throw new Error('Units plugin must be passed a vuex store.');
        }

        if (!Vue.$auth) {
            throw new Error('Units plugin requires the Auth plugin.');
        }

        if (!Vue.$api) {
            throw new Error('Units plugin requires the api plugin.');
        }

        store.registerModule('unitsService', UnitsModule);

        const service = new UnitsService({ store });

        // Register the units service globally.
        Vue.$units = service;

        // Register the units service on the Vue instance.
        Vue.prototype.$units = service;
    },
};
