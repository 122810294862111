export const tableColumnsUnits = function tableColumnsUnits(app) {
    return {
        source: 'self',
        options: {
            default: [
                {
                    name: 'name',
                    required: true,
                },
                {
                    name: 'Angle',
                    displayedByDefault: true,
                },
                {
                    name: 'Area',
                    displayedByDefault: true,
                },
                {
                    name: 'AreaDensity',
                    displayedByDefault: true,
                },
                {
                    name: 'Density',
                    displayedByDefault: true,
                },
                {
                    name: 'Duration',
                    displayedByDefault: true,
                },
                {
                    name: 'Energy',
                    displayedByDefault: true,
                },
                {
                    name: 'Volume',
                    displayedByDefault: true,
                },
                {
                    name: 'VolumeFlow',
                    displayedByDefault: true,
                },
                {
                    name: 'Mass',
                    displayedByDefault: true,
                },
                {
                    name: 'Length',
                    displayedByDefault: true,
                },
                {
                    name: 'Pressure',
                    displayedByDefault: true,
                },
                {
                    name: 'Force',
                    displayedByDefault: true,
                },
                {
                    name: 'Temperature',
                    displayedByDefault: true,
                },
                {
                    name: 'Torque',
                    displayedByDefault: true,
                },
                {
                    name: 'RotationalSpeed',
                    displayedByDefault: true,
                },
                {
                    name: 'Speed',
                    displayedByDefault: true,
                },
                {
                    name: 'Ratio',
                    displayedByDefault: true,
                },
                {
                    name: 'GasRate',
                    displayedByDefault: true,
                },
                {
                    name: 'GasVolume',
                    displayedByDefault: true,
                },
                {
                    name: 'LiquidRate',
                    displayedByDefault: true,
                },
                {
                    name: 'LiquidVolume',
                    displayedByDefault: true,
                },
                {
                    name: 'BoeRate',
                    displayedByDefault: true,
                },
                {
                    name: 'BoeVolume',
                    displayedByDefault: true,
                },
            ],
        },
    };
};
