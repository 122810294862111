export class Logger {
    constructor({ name, level = Logger.levels.None } = {}) {
        if (!name) {
            throw new Error('Logger name is required');
        }

        this.name = name;
        this.logPrefix = `[${this.name}]`;
        this.level = level;
    }

    debug(...args) {
        this.log(Logger.levels.debug, ...args);
    }

    info(...args) {
        this.log(Logger.levels.info, ...args);
    }

    warn(...args) {
        this.log(Logger.levels.warn, ...args);
    }

    error(...args) {
        this.log(Logger.levels.error, ...args);
    }

    log(level, ...args) {
        // If the log is below the set level show message
        if (level >= this.level) {
            switch (level) {
                case 1:
                    console.debug(this.logPrefix, ...args);
                    break;
                case 2:
                    console.info(this.logPrefix, ...args);
                    break;
                case 3:
                    console.warn(this.logPrefix, ...args);
                    break;
                case 4:
                    console.error(this.logPrefix, ...args);
                    break;
                default:
                    break;
            }
        }
    }

    // Establish numerical logging levels
    static get levels() {
        return {
            all: 0,
            debug: 1,
            info: 2,
            warn: 3,
            error: 4,
            none: 5,
        };
    }
}
