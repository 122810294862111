<template>
    <GeneralButton
        v-cypress="'AppCommentsButton_Comments_Button'"
        icon="chat_bubble_outline"
        dense
        :tooltip="commentPanelOpen ? 'Hide comments' : 'Comments'"
        :outline="false"
        :text-color="commentPanelOpen ? 'accent' : ''"
        :disabled="!commentsAvailable"
        size="lg"
        @click="toggleCommentPanel"
    >
        <q-badge
            v-if="forgeResourcesLoaded && commentsCount > 0"
            color="accent"
            text-color="secondary"
            class="badge-notification-rounded"
            floating
        >
            {{ commentsCount }}
        </q-badge>
    </GeneralButton>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import GeneralButton from '../plugin-framework/shared-components/inline-elements/GeneralButton.vue';
import mixins from '../mixins';

export default {
    name: 'AppCommentsButton',
    components: {
        GeneralButton,
    },
    mixins: [mixins.compute],
    computed: {
        ...mapState('app', {
            commentPanelOpen: state => state.commentPanelOpen,
        }),
        forgeResources() {
            const commentsForgeResources = this.commentsViewFragments.map(commentsViewFragment => commentsViewFragment.props.forgeResources);
            return commentsForgeResources.flat();
        },
        commentsViewFragments() {
            const commentViewFragments = this.$pai.getFragments('comment-view');
            const pageCommentViewFragments = commentViewFragments.filter(pageCommentView => pageCommentView.props.routes.includes(this.$route.name));
            return pageCommentViewFragments;
        },
        commentsAvailable() {
            return this.commentsViewFragments.length > 0;
        },
        commentsCount() {
            const totalComments = this.commentsViewFragments.reduce((total, commentViewFragment) => {
                if (commentViewFragment.props.comments) {
                    return total + commentViewFragment.props.comments.length;
                }
                return total;
            }, 0);
            return totalComments;
        },
    },
    methods: {
        ...mapMutations('app', [
            'toggleCommentPanel',
        ]),
    },
};
</script>

<style scoped>
.badge-notification-rounded {
    border-radius: 20%;
    height: 0.9rem;
    margin-top: 1rem;
}
</style>
