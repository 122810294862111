<template>
    <q-btn
        v-if="!hide"
        :class="mergedClasses"
        :label="label"
        :color="color"
        :text-color="textColor"
        :icon="icon"
        :icon-right="iconRight"
        :no-caps="noCaps"
        :type="type"
        :flat="flat"
        :outline="outline"
        :to="to"
        :replace="replace"
        :dense="dense"
        :fab="fab"
        :size="size"
        :align="align"
        :round="round"
        unelevated
        v-on="listenerOverrides"
    >
        <slot/>
        <slot name="loading"/>
        <q-tooltip :delay="750">
            {{ tooltip }}
        </q-tooltip>
    </q-btn>
</template>

<script>
export default {
    name: 'GeneralButton',
    // All props below are taken from https://quasar.dev/vue-components/button#QBtn-API
    props: {
        hide: {
            type: Boolean,
            required: false,
            default: false,
        },
        tooltip: {
            type: String,
            required: true,
        },
        label: {
            required: false,
            default: '',
            validator(value) {
                return typeof value === 'string' || value === 'number';
            },
        },
        color: {
            type: String,
            required: false,
            default: undefined,
        },
        textColor: {
            type: String,
            required: false,
            default: undefined,
        },
        icon: {
            type: String,
            required: false,
            default: undefined,
        },
        iconRight: {
            type: String,
            required: false,
            default: undefined,
        },
        noCaps: {
            type: Boolean,
            required: false,
            default: true,
        },
        type: {
            required: false,
            default: 'button',
            validator(value) {
                return ['a', 'submit', 'button', 'reset'].includes(value);
            },
        },
        flat: {
            type: Boolean,
            required: false,
            default: true,
        },
        outline: {
            type: Boolean,
            required: false,
            default: true,
        },
        to: {
            type: Object,
            required: false,
            default: null,
        },
        replace: {
            type: Boolean,
            required: false,
            default: false,
        },
        // Overrides the QBtn disable functionality so that the click event is not passed and the opacity is changed
        // This allows for the tooltip to persist even in disabled state
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        dense: {
            type: Boolean,
            required: false,
            default: false,
        },
        size: {
            type: String,
            required: false,
            default: 'md',
        },
        // Turn the button into a floating action button
        fab: {
            type: Boolean,
            required: false,
            default: false,
        },
        align: {
            type: String,
            required: false,
            default: 'center',
        },
        round: {
            type: Boolean,
            required: false,
            default: false,
        },
        classes: {
            type: Object,
            required: false,
            default: null,
        },
    },
    computed: {
        mergedClasses() {
            let mergedClasses = {
                // Apply the disabled class when disabled prop is set
                'p-btn-disabled': this.disabled,
                'custom-disabled': this.disabled,
            };

            // Spread in user provided classes if they exist
            if (this.classes) {
                mergedClasses = {
                    ...this.classes,
                    ...mergedClasses,
                };
            }

            return mergedClasses;
        },
        listenerOverrides() {
            const vm = this;
            return {
                // Pass all the default listeners
                ...this.$listeners,
                // Override the click listener to check for disabled state
                click(event) {
                    vm.$telemetry.trackUserEvent({
                        name: vm.$telemetry.eventNames.BUTTON_CLICK,
                        properties: {
                            buttonLabel: vm.label,
                            buttonTooltip: vm.tooltip,
                        },
                    });
                    // Prevent click on disabled button
                    if (!vm.disabled) {
                        vm.$emit('click', event);
                    }
                },
                // Override the click-stop listener to check for disabled state.
                '!click'(event) {
                    // Prevent click on disabled button.
                    if (!vm.disabled) {
                        vm.$emit('!click', event);
                    }
                    else {
                        // Even if it is disabled, stop the propagation.
                        event.stopPropagation();
                    }
                },
            };
        },
    },
};
</script>

<style scoped>
.custom-disabled {
    opacity: 0.6;
    cursor: not-allowed;
}
</style>
