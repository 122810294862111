<template>
    <q-page
        class="row"
        padding
    >
        <div class="col-xs-12 col-sm-6 col-md-3 q-pt-sm q-px-xl">
            <div>
                <div class="center-row">
                    <q-card class="profile-visualization">
                        <slot name="profileVisualizationComponent"/>
                    </q-card>
                </div>
                <slot name="summaryTitles">
                    <div class="text-h5 q-pt-lg text-bold ellipsis block-title">
                        {{ summaryTitles.title }}
                        <q-tooltip>
                            {{ summaryTitles.title }}
                        </q-tooltip>
                    </div>
                    <div class="text-h6 center-row q-pb-md">
                        {{ summaryTitles.subtitle }}
                    </div>
                    <div class="center-row">
                        {{ summaryTitles.description }}
                    </div>
                </slot>
                <br>
                <slot name="actionsBar"/>
            </div>
        </div>

        <div
            v-if="$slots.mainDataCard"
            class="col-xs-12 col-sm-6 col-md-6 q-pt-sm"
        >
            <q-card class="q-pa-md full-height">
                <slot name="mainDataCard"/>
            </q-card>
        </div>
    </q-page>
</template>

<script>
export default {
    name: 'ItemPage',
    props: {
        summaryTitles: {
            type: Object,
            required: false,
            default() {
                return {
                    title: 'Title',
                    subtitle: 'Sub-title',
                    description: 'Item Document',
                };
            },
        },
    },
};
</script>

<style scoped>
.center-row {
    display: flex;
    justify-content: center;
}
.profile-visualization {
    height: 25vh;
    width: 25vh;
}
.block-title {
    display: block;
    text-align: center;
}
</style>
