<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
export default {
    created() {
        // Extend the icon mapping to support petronicons
        this.$q.iconMapFn = (iconName) => {
            // iconName is the value of the name prop of QIcon
            if (iconName.startsWith('picon') === true) {
                return {
                    // Return the classes needed to show the petronicon
                    cls: `picon ${iconName}`,
                };
            }
        };
    },
};
</script>
