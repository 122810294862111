<template>
    <div>
        <span v-if="showAll">
            <UserProfileImage
                v-for="(user, idx) in providedUsers"
                :key="idx"
                :user="user"
                :dimension="avatarSize"
                font-size="xx-large"
                class="q-mr-xs"
            />
        </span>
        <span v-else>
            <UserProfileImage
                v-for="(user, idx) in providedUsers.slice(0, displayMax - 1)"
                :key="idx"
                :user="user"
                :dimension="avatarSize"
                class="q-mr-xs"
            />
            <q-avatar
                color="grey"
                text-color="white"
                :size="avatarSize"
                class="q-mr-xs"
            >
                {{ remaining }}+
                <q-tooltip>
                    <!-- Only want to show a limited number in the tool tip -->
                    <div
                        v-for="(u2, bono) in providedUsers.slice(displayMax - 1, maxUserRange)"
                        :key="bono"
                    >
                        <UserFullName
                            :user="u2"
                            hide-link
                        />
                    </div>
                    <div v-if="providedUsers.length > maxUserRange">
                        Plus {{ providedUsers.length - (maxUserRange) }} others
                    </div>
                </q-tooltip>
            </q-avatar>
        </span>
    </div>
</template>

<script>
export default {
    name: 'FacePile',
    props: {
        userIds: {
            type: Array,
            required: true,
        },
        avatarSize: {
            type: String,
            required: false,
            default: '32px',
        },
        displayMax: {
            type: Number,
            required: false,
            default: 4,
        },
    },
    computed: {
        currentUserId() {
            return this.$auth.user.userId;
        },
        providedUsers() {
            // Hide the current user, so they don't see themselves in their own facepile.
            // Also hide any IDs that can't be resolved to an actual User object.
            return this.userIds.filter(id => id !== this.currentUserId).map(uid => this.$users.getUser(uid)).filter(user => !!user)
            // sort users with a profile url to show up before those without
            .sort((user0, user1) => {
                // user 0 comes first if they have a profile url
                if (user0 && user0.profileThumbnailUrl) return -1;
                // otherwise user1 will come before user0
                if (user1 && user1.profileThumbnailUrl) return 1;
                // they're both null or they have a url
                return 0;
            });
        },
        showAll() {
            return this.userIds.length <= this.displayMax;
        },
        remaining() {
            const theScraps = this.providedUsers.length - (this.displayMax - 1);
            if (theScraps > 99) {
                return 99;
            }
            return theScraps;
        },
        maxUserRange() {
            return this.displayMax + 9;
        },
    },
};
</script>

<style scoped>

</style>
