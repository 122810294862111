<template>
    <q-dialog
        v-if="display && service && Object.keys(service).length"
        v-model="display"
    >
        <q-card
            class="card-size"
        >
            <q-card-section
                class="column text-grey-7"
            >
                <div class="q-pa-lg">
                    <div class="row items-center">
                        <div class="text-bold col">
                            {{ service.name }}
                        </div>
                        <div class="text-right col-auto">
                            <GeneralButton
                                v-close-popup
                                color="grey-8"
                                flat
                                icon="clear"
                                tooltip="Close dialog"
                                @click="$emit('cancel')"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <q-carousel
                                v-if="galleryImages.length"
                                v-model="slide"
                                navigation
                                swipeable
                                arrows
                                control-color="secondary"
                                class="carousel-size"
                            >
                                <q-carousel-slide
                                    v-for="(image, index) in galleryImages"
                                    :key="`image-${index}`"
                                    :name="image.value"
                                >
                                    <img
                                        :src="image.url"
                                        class="gallery-size"
                                    >
                                </q-carousel-slide>
                            </q-carousel>
                            <img
                                v-else-if="service.coverImage"
                                :src="service.coverImage"
                                class="gallery-size"
                            >
                            <div class="q-pl-lg q-pt-md carousel-width">
                                {{ service.description }}
                            </div>
                        </div>
                        <div class="col">
                            <div
                                v-if="keyDeliverables.length"
                                class="text-bold q-py-md"
                            >
                                Key Deliverables
                            </div>
                            <div
                                v-for="(deliverable, index) in keyDeliverables"
                                :key="`deliverable-${index}`"
                                class="q-px-md row items-center q-pb-md"
                            >
                                <q-icon
                                    name="add_circle_outline"
                                    color="secondary"
                                    class="col-auto"
                                />
                                <span class="col q-px-md">
                                    {{ deliverable }}
                                </span>
                            </div>
                            <div
                                v-if="dataRequirements.length"
                                class="text-bold q-py-md"
                            >
                                Data Requirements
                            </div>
                            <div
                                v-for="(requirement, index) in dataRequirements"
                                :key="`requirement-${index}`"
                                class="q-px-lg row items-center"
                            >
                                <q-icon
                                    name="mdi-circle-small"
                                    color="secondary"
                                    size="1.8rem"
                                    class="col-auto"
                                />
                                <span class="col">
                                    {{ requirement }}
                                </span>
                            </div>
                            <div
                                v-if="service.timeline"
                                class="text-bold q-py-md"
                            >
                                Estimated Timeline
                            </div>
                            <div>{{ service.timeline }}</div>
                        </div>
                    </div>
                    <div class="text-right">
                        <GeneralButton
                            v-close-popup
                            color="accent"
                            text-color="primary"
                            :flat="false"
                            :outline="false"
                            label="Submit Request"
                            tooltip="Email Petro.ai to request this service"
                            class="primary-box"
                            @click="$emit('email', service)"
                        />
                    </div>
                </div>
            </q-card-section>
        </q-card>
    </q-dialog>
</template>

<script>
import GeneralButton from '../../../plugin-framework/shared-components/inline-elements/GeneralButton.vue';

export default {
    name: 'ServiceDetailsDialog',
    components: {
        GeneralButton,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        service: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            secondsWatched: 0,
            percentWatched: 0,
            videoLength: 0,
            slide: 0,
        };
    },
    computed: {
        display: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        keyDeliverables() {
            let keyDeliverables = [];
            if (this.service && this.service.keyDeliverables) {
                keyDeliverables = this.service.keyDeliverables.map(line => {
                    const children = line.children.map(child => child.text);
                    return children.join('\n');
                });
            }
            return keyDeliverables;
        },
        dataRequirements() {
            let dataRequirements = [];
            if (this.service && this.service.dataRequirements) {
                dataRequirements = this.service.dataRequirements.map(line => {
                    const children = line.children.map(child => child.text);
                    return children.join('\n');
                });
            }
            return dataRequirements;
        },
        galleryImages() {
            const galleryImages = [];
            if (this.service && this.service.galleryImages) {
                this.service.galleryImages.forEach((url, index) => {
                    galleryImages.push({
                        label: index,
                        value: index,
                        url,
                    });
                });
            }
            return galleryImages;
        },
    },
};
</script>

<style scoped>
.card-size {
    width: 850px;
    max-width: 80vw;
}
.carousel-size {
    width: 320px;
    height: 225px;
}
.carousel-width {
    max-width: 320px;
}
.gallery-size {
    width: 300px;
    height: 200px;
}
</style>
