<template>
    <q-scroll-area
        class="fit"
    >
        <CommentView
            v-for="(commentView, index) in pageCommentViewFragments"
            :key="index"
            v-bind="commentView.props"
            v-on="commentView.events"
        />
    </q-scroll-area>
</template>

<script>
import CommentView from '../plugin-framework/shared-components/comments/CommentView.vue';

export default {
    name: 'CommentsMenu',
    components: {
        CommentView,
    },
    computed: {
        pageCommentViewFragments() {
            const commentViewFragments = this.$pai.getFragments('comment-view');
            const pageCommentViewFragments = commentViewFragments.filter(pageCommentView => pageCommentView.props.routes.includes(this.$route.name));
            return pageCommentViewFragments;
        },
    },
};
</script>

<style scoped>

</style>
