<template>
    <q-page class="bg-grey-3 default-layout-height">
        <div class="home-grid fit q-pa-md">
            <!-- LOGO -->
            <div
                class="q-py-lg text-center"
                style="grid-area: 1/1/4/4"
            >
                <div class="gt-sm image-wrapper">
                    <img
                        v-if="organizationImageUrl"
                        :src="organizationImageUrl"
                        class="organization-image"
                    >
                    <img
                        v-else
                        :src="require('@/assets/images/petro-mandala.png')"
                    >
                </div>
                <div
                    class="text-primary text-h5"
                >
                    {{ dayOfTheWeek }}
                </div>
                <div
                    class="text-secondary text-h6"
                >
                    {{ currentDate }}
                </div>
            </div>

            <!-- PETRON LIST -->
            <q-card
                style="grid-area: 4/1/13/4"
                class="column fit q-px-md q-py-md"
            >
                <div class="items-center row">
                    <div class="text-primary text-bold text-h6">
                        My Petrons
                    </div>
                    <q-space/>
                    <GeneralButton
                        icon="add_circle"
                        color="grey-8"
                        tooltip="Create a new petron"
                        size="lg"
                        @click="showCreateDialog = true"
                    />
                </div>

                <q-input
                    v-model="petronSearchText"
                    v-cypress="'TheHomePage_SearchPetrons_Input'"
                    borderless
                    dense
                    filled
                    clearable
                    debounce="300"
                    placeholder="Search My Petrons"
                    class="q-mb-sm"
                >
                    <template v-slot:prepend>
                        <q-icon name="search"/>
                    </template>
                </q-input>

                <q-scroll-area class="col col-grow">
                    <PetronFeed
                        :search-text="petronSearchText"
                    />
                </q-scroll-area>
            </q-card>

            <!-- DASHBOARDS -->
            <q-card
                v-if="dashboards.length"
                :style="`grid-area: 1/4/${bottomOfDashboardPanel}/13`"
                class="column fit q-px-md q-py-md"
            >
                <div class="text-primary text-bold text-h6">
                    Dashboards
                </div>

                <q-scroll-area class="col col-grow">
                    <div class="row q-col-gutter-md q-pa-xs">
                        <div
                            v-for="dashboard in dashboards"
                            :key="dashboard.id"
                            class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-3 col-auto"
                        >
                            <DashboardCard
                                :dashboard="dashboard"
                            />
                        </div>
                    </div>
                </q-scroll-area>
            </q-card>

            <!-- GLOBAL APPS -->
            <q-card
                :style="`grid-area: ${bottomOfDashboardPanel}/4/13/7`"
                class="column fit q-px-md q-py-md"
            >
                <div class="text-primary text-bold text-h6">
                    Global Apps
                </div>

                <q-input
                    v-model="appSearchText"
                    v-cypress="'TheHomePage_SearchApps_Input'"
                    borderless
                    dense
                    filled
                    clearable
                    debounce="300"
                    placeholder="Search Global Apps"
                    class="q-mt-sm"
                >
                    <template v-slot:prepend>
                        <q-icon name="search"/>
                    </template>
                </q-input>

                <q-scroll-area class="col col-grow">
                    <GlobalAppCard
                        v-for="(globalApp, appIdx) in globalApps"
                        :key="`globalApp-${appIdx}`"
                        v-bind="globalApp"
                        class="q-my-md q-mx-xs"
                    />
                </q-scroll-area>
            </q-card>

            <!-- ACTIVITY FEED -->
            <q-card
                :style="`grid-area: ${bottomOfDashboardPanel}/7/13/13`"
                class="column fit q-px-md q-py-md"
            >
                <div class="row items-center">
                    <div class="text-primary text-bold text-h6">
                        Recent Activity
                    </div>
                    <q-space/>
                    <GeneralButton
                        icon="refresh"
                        tooltip="Get latest activity"
                        size="lg"
                        @click="loadFeedItems"
                    />
                </div>

                <div class="col col-grow">
                    <q-scroll-area
                        v-if="feedItems"
                        ref="activityFeedScrollArea"
                        class="fit"
                    >
                        <ActivityFeed
                            :items="feedItems"
                            :total-items="totalFeedItems"
                            no-activity-message="Welcome to Petro.ai! Quickly find and share what you post by using #hashtags with your team."
                            @load-more="loadMoreItems"
                            @item-added="addFeedItem"
                            @item-updated="updateFeedItem"
                            @item-deleted="removeFeedItem"
                            @add-reaction="addReaction"
                            @remove-reaction="removeReaction"
                        />
                    </q-scroll-area>
                    <div
                        v-else
                        class="text-center"
                    >
                        <q-spinner
                            size="30px"
                            color="primary"
                        />
                        <p>Loading activity...</p>
                    </div>
                </div>

                <GeneralButton
                    v-if="$refs.activityFeedScrollArea && $refs.activityFeedScrollArea.getScrollPosition()"
                    fab
                    :flat="false"
                    :outline="false"
                    icon="fa fa-chevron-up"
                    color="primary"
                    tooltip="Go to top"
                    class="scroll-button-sticky"
                    @click="$refs.activityFeedScrollArea.setScrollPosition(0)"
                />
            </q-card>
        </div>

        <NewPetronDialog
            v-model="showCreateDialog"
            @createPetron="createPetron"
        />
    </q-page>
</template>

<script>
import { mapActions } from 'vuex';
import ActivityFeed from '../../plugin-framework/shared-components/activity-feed/ActivityFeed.vue';
import PetronFeed from '../../plugins/library/components/PetronFeed.vue';
import NewPetronDialog from '../../plugins/library/components/NewPetronDialog.vue';
import GeneralButton from '../../plugin-framework/shared-components/inline-elements/GeneralButton.vue';
import GlobalAppCard from './GlobalAppCard.vue';
import DashboardCard from './DashboardCard.vue';

export default {
    name: 'TheHomePage',
    components: {
        GlobalAppCard,
        ActivityFeed,
        PetronFeed,
        NewPetronDialog,
        GeneralButton,
        DashboardCard,
    },
    data() {
        return {
            feedItems: null,
            totalFeedItems: 0,
            feedFromDate: new Date(),
            feedItemsSkip: 0,
            feedItemsLimit: 5,
            showCreateDialog: false,
            petronSearchText: '',
            appSearchText: '',
            dashboards: [],
        };
    },
    computed: {
        bottomOfDashboardPanel() {
            if (this.dashboards.length) {
                return '6';
            }
            return '1';
        },
        displayTimeZoneId() {
            return this.$globalSettings.displayTimeZoneId;
        },
        dayOfTheWeek() {
            return this.$utils.formatting.formatDate(Date.now(), this.displayTimeZoneId, 'dddd');
        },
        currentDate() {
            return this.$utils.formatting.formatDate(Date.now(), this.displayTimeZoneId, 'MMMM D, YYYY');
        },
        globalApps() {
            const globalApps = this.$pai.getFragments('global-app-card').map(app => app.props).filter(app => app.title);
            globalApps.sort((a, b) => a.title.localeCompare(b.title));
            if (this.appSearchText && this.appSearchText.trim().length) {
                const searchTerm = this.appSearchText.trim().toLowerCase();
                return globalApps.filter(app => app.title.toLowerCase().includes(searchTerm)
                    || (app.description && app.description.toLowerCase().includes(searchTerm)));
            }
            return globalApps;
        },
        organizationImageUrl() {
            if (this.$environment.info && this.$environment.info.organizationImageUrl && this.$environment.info.organizationImageUrl.length) {
                return this.$environment.info.organizationImageUrl;
            }
            return null;
        },
    },
    created() {
        this.loadFeedItems();
        this.loadDashboards();
    },
    methods: {
        ...mapActions('Library', {
            createPetron: 'createPetron',
        }),
        // TODO: Generalize this duplicated code into a common Vue 3 paradigm
        getFeedItems({ fromDate = this.feedFromDate, skip = this.feedItemsSkip, limit = this.feedItemsLimit } = {}) {
            return this.$api.tasks.runTask({
                runInBackground: false,
                taskType: 'RenderActivityFeed',
                options: {
                    fromDate,
                    userId: this.$auth.user.userId,
                    pluginId: null,
                    pid: null,
                    skip,
                    limit,
                },
            })
            .then(response => {
                if (response.data) {
                    return response;
                }

                throw new Error('response contained no data');
            })
            .catch(error => {
                this.$notify.error(`Unable to load activity feed items: ${error.message}`);
            });
        },
        loadFeedItems() {
            // Reset to initial state
            this.feedFromDate = new Date();
            this.feedItemsSkip = 0;

            this.getFeedItems()
            .then(response => {
                this.feedItems = response.data;
                this.totalFeedItems = response.total;
            });
        },
        loadDashboards() {
            this.$api.data.query({
                type: 'DSUDesignProject',
                query: {},
            })
            .then(response => {
                if (response && response.data) {
                    this.dashboards = response.data.sort((a, b) => a.name.localeCompare(b.name));
                }
            });
        },
        // quasar infinite scroll load method signature, done is a callback to end infinite loading spinner
        loadMoreItems(index, done) {
            if (this.feedItems.length < this.totalFeedItems) {
                this.feedItemsSkip += this.feedItemsLimit;
                this.getFeedItems()
                .then(response => {
                    this.feedItems.push(...response.data);
                    this.totalFeedItems = response.total;
                    done();
                });
            }
            else {
                done();
            }
        },
        addFeedItem({ item, parentItem }) {
            if (parentItem) {
                if (!parentItem.document.replies) {
                    parentItem.document.replies = [];
                }
                parentItem.document.replies.unshift(item);
                return;
            }
            this.feedItems.unshift(item);
            this.totalFeedItems += 1;
        },
        removeFeedItem({ id, parentId }) {
            if (parentId) {
                const parent = this.feedItems.find(item => item.document.comment.id === parentId);
                if (parent) {
                    const replyIdx = parent.document.replies.findIndex(reply => reply.comment.id === id);
                    if (replyIdx > -1) {
                        parent.document.replies.splice(replyIdx, 1);
                    }
                }
                return;
            }
            const commentIdx = this.feedItems.findIndex(item => item.document.comment.id === id);
            if (commentIdx > -1) {
                this.feedItems.splice(commentIdx, 1);
                this.totalFeedItems -= 1;
            }
        },
        updateFeedItem({ comment, parentId }) {
            let originalComment;
            if (parentId) {
                const parent = this.feedItems.find(item => item.document.comment.id === parentId);
                if (parent) {
                    originalComment = parent.document.replies.find(item => item.comment.id === comment.id);
                }
            }
            else {
                const originalItem = this.feedItems.find(item => item.document.comment.id === comment.id);
                if (originalItem) {
                    originalComment = originalItem.document.comment;
                }
            }
            if (originalComment) {
                originalComment.richContent = comment.richContent;
                originalComment.mentionIds = comment.mentionIds;
                originalComment.updatedAt = new Date().toISOString();
            }
        },
        addReaction({ reaction, parentId }) {
            let matchingItem;
            if (parentId) {
                const parent = this.feedItems.find(item => item.document.comment.id === parentId);
                if (parent) {
                    matchingItem = parent.document.replies.find(item => item.comment.id === reaction.parentCommentId);
                }
            }
            else {
                const originalItem = this.feedItems.find(item => item.document.comment.id === reaction.parentCommentId);
                if (originalItem) {
                    matchingItem = originalItem.document;
                }
            }
            if (matchingItem) {
                if (!matchingItem.reactions) {
                    matchingItem.reactions = [];
                }
                matchingItem.reactions.push(reaction);
            }
        },
        removeReaction({ reaction, parentId }) {
            let matchingItem;
            if (parentId) {
                const parent = this.feedItems.find(item => item.document.comment.id === parentId);
                if (parent) {
                    matchingItem = parent.document.replies.find(item => item.comment.id === reaction.parentCommentId);
                }
            }
            else {
                const originalItem = this.feedItems.find(item => item.document.comment.id === reaction.parentCommentId);
                if (originalItem) {
                    matchingItem = originalItem.document;
                }
            }
            if (matchingItem) {
                const matchingIndex = matchingItem.reactions.findIndex(reactionItem => reactionItem.id === reaction.id);
                if (matchingIndex > -1) {
                    matchingItem.reactions.splice(matchingIndex, 1);
                }
            }
        },
    },
};
</script>

<style scoped>
.home-grid {
    display: grid;
    grid: repeat(12, 1fr) / repeat(12, 1fr);
    grid-gap: 10px;
}
</style>

<style scoped lang="stylus">
@import '../../css/petro.styl';
.default-layout-height {
    /* full height minus the application bar */
    height: "calc(100vh - %s)" % $app-header-height;
}

.scroll-button-sticky {
    position: absolute;
    bottom: 40px;
    right: 18px;
    opacity: 30%;
    &:hover {
        opacity: inherit;
    }
}

.image-wrapper {
    height: 210px;
}

.organization-image {
    height: auto;
    width: auto;
    max-height: 100%;
    max-width 100%;
}
</style>
