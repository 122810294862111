<template>
    <q-item-section>
        <div class="row">
            <div class="col col-shrink">
                <UserProfileImage
                    :user="reply.userId"
                    dimension="0.5em"
                    font-size="inherit"
                />
            </div>

            <div class="col q-ml-sm q-px-sm q-pb-sm">
                <div class="row items-center">
                    <div class="col ellipsis column">
                        <UserFullName
                            :user-id="reply.userId"
                            class="text-primary text-weight-bold"
                            hide-link
                        />
                        <small class="text-grey-6">
                            <PTimeAgo :date-time="reply.createdAt"/>
                        </small>
                    </div>
                    <div
                        v-if="$auth.user.userId === reply.userId"
                        class="col col-shrink"
                    >
                        <CommentMenu
                            v-if="!hideCommentMenu"
                            @edit="commentIsEditable = !commentIsEditable"
                            @delete="deleteComment"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <!-- Existing Reply -->
                        <CommentInput
                            v-cypress="'CommentView_Reply_Div'"
                            :class="{'show-editing': commentIsEditable}"
                            :content="reply.richContent"
                            :editable="commentIsEditable"
                            @submit="updateComment"
                        />
                    </div>
                </div>
                <CommentInfo
                    v-if="!hideCommentInfo"
                    :reactions="reactions"
                    hide-replies
                    :can-react="!disableReactions"
                    :has-liked="userHasLiked"
                    @react="reactToComment"
                />
            </div>
        </div>
    </q-item-section>
</template>

<script>
import PTimeAgo from '../../services/pai-components/PTimeAgo.vue';
import CommentMenu from './CommentMenu.vue';
import CommentInput from './editor/CommentEditor.vue';
import CommentInfo from './CommentInfo.vue';

// TODO: Share common functionality between RootComment and Reply
export default {
    name: 'Reply',
    components: {
        CommentInfo,
        CommentInput,
        CommentMenu,
        PTimeAgo,
    },
    props: {
        reply: {
            type: Object,
            required: true,
        },
        reactions: {
            type: Array,
            required: false,
            default: () => [],
        },
        disableReactions: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideCommentMenu: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideCommentInfo: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            commentIsEditable: false,
        };
    },
    computed: {
        userReactions() {
            const userReactions = this.reactions.filter(reaction => reaction.userId === this.$auth.user.userId);
            return userReactions;
        },
        userHasLiked() {
            return !!this.userReactions.find(reaction => reaction.commentType === 'like');
        },
    },
    methods: {
        updateComment({ htmlContent }) {
            const reply = {
                id: this.reply.id,
                richContent: htmlContent,
            };
            this.commentIsEditable = false;
            this.$emit('update', reply);
        },
        deleteComment() {
            this.$emit('delete', { id: this.reply.id });
        },
        reactToComment(type) {
            const userReaction = this.userReactions.find(reaction => reaction.commentType === type);
            // Remove reaction if user has already reacted
            if (userReaction) {
                this.$emit('remove-reaction', userReaction);
            }
            // Create a new reaction
            else {
                const reaction = {
                    commentType: type,
                    parentCommentId: this.reply.id,
                    userId: this.$auth.user.userId,
                };
                this.$emit('add-reaction', reaction);
            }
        },
    },
};
</script>

<style scoped lang="stylus">
.show-editing {
    border: solid 1px black;
}
</style>
