import { workspaceTestTemplate } from './templates/test-workspace';
import WorkspaceStatus from './components/WorkspaceStatus';

export const ComputeEditorPlugin = {
    name: 'ComputeEditor',
    views: {
        WorkspaceStatus,
    },
    definition({ app, plugin }) {
        return {
            modules: [
                {
                    type: 'fragment',
                    target: 'app-card',
                    priority: 10,
                    options() {
                        return {
                            props: {
                                title: 'PDL Workspace',
                                description: 'Create and edit Petron Data Modeling workspaces',
                                type: 'tool',
                                launchRoute: { name: 'workspace-test' },
                                hide: !(app.$auth.userIsAdmin || app.$auth.userIsSupportEngineer),
                            },
                        };
                    },
                },
                workspaceTestTemplate(app),
            ],
            model: {
                fields: {
                    petronId: {
                        source: 'route',
                    },
                    testWorkspaceForm: {
                        source: 'self',
                        options: {
                            default: {
                                route: '',
                            },
                        },
                    },
                    testFormIsValid: {
                        source: 'self',
                        options: {
                            default: true,
                        },
                    },
                    testResource: {
                        source: 'self',
                        options: {
                            default: '',
                        },
                    },
                },
                getters: {
                    workspace: state => '$root/WorkspaceDefinitions',
                },
                actions: {
                    parseResource(vuex, resourceUri) {
                        return app.$compute.forgeParseResource(resourceUri);
                    },
                    subscribeElements(vuex, source, els) {
                        return app.$compute.forgeSubscribeElements(source, els);
                    },
                    getWorkspace(vuex, workspace) {
                        return app.$compute.forgeGetWorkspace(workspace);
                    },
                },
            },
        };
    },
};
