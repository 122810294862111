<template>
    <div
        v-if="!hide"
        class="row items-center"
    >
        <q-select
            v-if="options.length"
            v-model="selectedValue"
            :options="options"
            :option-label="optionLabel"
            :option-value="optionValue"
            class="col q-pa-none"
            filled
            dense
            item-aligned
            emit-value
            map-options
            @input="$emit('select', $event)"
        />
        <div
            v-else
            class="col text-center"
        >
            <q-spinner
                size="40px"
                color="primary"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'DropdownNavSection',
    components: {
    },
    props: {
        selectedValue: {
            type: [Array, String],
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        optionValue: {
            type: String,
            required: false,
            default: 'value',
        },
        optionLabel: {
            type: String,
            required: false,
            default: 'label',
        },
        hide: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        model: {
            get() {
                return this.selectedValue;
            },
            set(value) {
                this.$emit('select', value);
            },
        },
    },
};
</script>

<style scoped>

</style>
