<template>
    <div>
        <div class="q-pa-lg text-h6 text-bold text-secondary">
            Admin Panel
        </div>
        <div
            v-for="(menuItem, index) in adminMenuItems"
            :key="`AdminCommand-${index}`"
            class="q-pa-md"
        >
            <GeneralButton
                :flat="false"
                :outline="false"
                class="full-width text-bold purple-box"
                :label="menuItem.label"
                color="grey-2"
                text-color="secondary"
                :tooltip="menuItem.tooltip"
                :to="menuItem.to"
                @click="$emit('collapse')"
            />
        </div>
    </div>
</template>

<script>
import GeneralButton from '../plugin-framework/shared-components/inline-elements/GeneralButton.vue';

export default {
    name: 'AppMenuAdmin',
    components: {
        GeneralButton,
    },
    props: {
    },
    data() {
        return {
            adminMenuItems: [
                {
                    label: 'Jobs Manager',
                    tooltip: 'Go to the Jobs Manager',
                    to: { name: 'jobs' },
                },
                {
                    label: 'View Kernels',
                    tooltip: 'Go to View Kernels',
                    to: { name: 'kernels' },
                },
                {
                    label: 'System Information',
                    tooltip: 'Go to System Information',
                    to: { name: 'info' },
                },
                {
                    label: 'Settings',
                    tooltip: 'Go to Application Settings',
                    to: { name: 'application-settings' },
                },
                {
                    label: 'Define Units',
                    tooltip: 'Go to Define Units',
                    to: { name: 'units' },
                },
            ],
        };
    },
};
</script>

<style scoped lang="stylus">
@import '../css/quasar.variables.styl';
.purple-box {
    border: solid 2px $secondary;
    border-radius: 2px;
}
</style>
