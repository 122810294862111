<template>
    <div class="full-height">
        <q-table
            v-if="tableProperties"
            ref="__dataTable"
            v-bind="tableProperties"
            :class="{'sticky-first-column': selection !== 'none'}"
            :pagination.sync="pagination"
            :selected.sync="innerSelectedValues"
            :selection="selection"
            :wrap-cells="wrapCells"
            :hide-header="hideColumnHeaders"
            :loading="innerLoading"
            :no-data-label="noDataLabel"
            row-key="__rowId"
            class="full-height sticky-header q-pa-lg"
            :grid="$q.screen.lt.md"
            :table-style="tableStyle"
            @request="handleRequest"
            @selection="handleSelect"
        >
            <!-- TOP-LEFT -->
            <div
                v-if="header && !hideTopMenu"
                slot="top-left"
                class="q-px-sm q-pb-sm text-h6"
            >
                {{ header }}
            </div>


            <!-- TOP-LEFT -->
            <div
                v-if="(isSortable || showSelectAll) && !hideTopMenu"
                slot="top-left"
                class="row q-px-md items-center q-pb-md"
            >
                <div
                    v-if="isSortable"
                    class="q-px-md"
                >
                    <span
                        v-if="pagination.sortBy && pagination.descending !== null && tableType !== 'DeclineCurveAnalysisModel'"
                    >
                        <span>Sorted by:</span>&nbsp;
                        <span>{{ `${pagination.sortBy === hiddenColumn.name ? 'Selected' : pagination.sortBy} (${pagination.descending ? 'descending' : 'ascending'} order)` }}</span>
                    </span>

                    <!-- Slot for plugins framework component next to left buttons -->
                    <slot
                        name="dt-top-left"
                    />
                </div>

                <p-btn
                    v-if="showSelectAll && selected.length >= paginationOptions.rowsPerPage && selected.length !== tableData.length"
                    :label="`Select all ${tableData.length} rows in the table`"
                    tooltip="Select all rows in table"
                    @click="selectAll"
                />

                <p-btn
                    v-if="showSelectAll && tableData.length && selected.length === tableData.length"
                    :label="`Clear Selection`"
                    tooltip="Clear selection"
                    @click="clearAllSelected"
                />

                <div
                    v-if="topLeftButtons && selected.length"
                    class="row items-center"
                >
                    <div
                        v-for="(button, index) in topLeftButtons"
                        :key="index"
                    >
                        <q-btn-dropdown
                            v-if="button.type === 'drop-down'"
                            v-cypress="button.props.testId"
                            v-bind="button.props"
                            v-on="button.events"
                        >
                            <template
                                v-if="button.props.listComponent"
                            >
                                <component
                                    :is="button.props.listComponent.componentType"
                                    v-bind="button.props.listComponent.props"
                                    v-on="button.props.listComponent.events"
                                />
                            </template>
                        </q-btn-dropdown>
                        <GeneralButton
                            v-else
                            v-cypress="button.props.testId"
                            v-bind="button.props"
                            v-on="button.events"
                        />
                    </div>
                </div>
            </div>

            <!-- TOP-RIGHT -->
            <!-- This could be confusing to the developer if they decide to use the top-right slot -->
            <div
                v-if="allowFullScreen && !hideTopMenu"
                slot="top-right"
                slot-scope="props"
            >
                <!-- Slot for custom html next to the full screen button -->
                <slot
                    name="dt-top-right"
                    v-bind="props"
                />&nbsp;<q-btn
                    flat
                    round
                    dense
                    :icon="props.inFullscreen ? 'fullscreen_exit' : 'fullscreen'"
                    class="pull-right"
                    @click="props.toggleFullscreen"
                />
            </div>

            <!-- TOP-RIGHT -->
            <div
                v-if="!hideTopMenu"
                slot="top-right"
                slot-scope="props"
                class="q-pb-lg"
            >
                <slot
                    v-if="isSortable || showColumnSelect"
                    name="dt-top-right"
                    v-bind="props"
                >
                    <div
                        class="row items-center"
                    >
                        <div
                            v-if="topRightButtons"
                            class="row items-center"
                        >
                            <div
                                v-for="(button, index) in topRightButtons"
                                :key="index"
                            >
                                <q-btn-dropdown
                                    v-if="button.type === 'drop-down'"
                                    v-cypress="button.props.testId"
                                    v-bind="button.props"
                                    v-on="button.events"
                                >
                                    <q-list>
                                        <q-item
                                            v-for="(item, itemIndex) in topRightButtonsDropDown"
                                            :key="itemIndex"
                                            v-close-popup
                                            clickable
                                            v-bind="item.props"
                                            v-on="item.events"
                                        >
                                            <q-item-section avatar>
                                                <q-avatar
                                                    :icon="item.icon"
                                                />
                                            </q-item-section>
                                            <q-item-section>
                                                <q-item-label>
                                                    {{ item.title }}
                                                </q-item-label>
                                                <q-item-label caption>
                                                    {{ item.caption }}
                                                </q-item-label>
                                            </q-item-section>
                                        </q-item>
                                    </q-list>
                                </q-btn-dropdown>
                                <GeneralButton
                                    v-else
                                    v-cypress="button.props.testId"
                                    v-bind="button.props"
                                    v-on="button.events"
                                />
                            </div>
                        </div>
                        <div
                            v-if="showColumnSelect"
                        >
                            <Settings
                                v-model="mutableVisibleColumnNames"
                                display-name="Column Preferences"
                                :reference-id="tableId"
                                :parent-id="columnSelectSettingsParentId"
                                use-local-storage
                                version="1"
                            />
                        </div>
                        <GeneralButton
                            v-if="showColumnSelect"
                            tooltip="Options for this table"
                            label="Column Settings"
                            dense
                            @click="expandTableOptions"
                        />
                        <q-menu
                            v-if="tableOptionsExpanded"
                            anchor="bottom right"
                            self="top right"
                            dense
                            auto-close
                            @before-hide="expandTableOptions"
                        >
                            <q-list
                                dense
                            >
                                <q-item
                                    clickable
                                    @click="openColumnSelector"
                                >
                                    <q-item-section>
                                        {{ 'Select Table Columns' }}
                                    </q-item-section>
                                </q-item>
                                <q-item
                                    clickable
                                    @click="sortSelected"
                                >
                                    <q-item-section>
                                        {{ 'Sort Selected Rows' }}
                                    </q-item-section>
                                </q-item>
                            </q-list>
                        </q-menu>
                    </div>
                </slot>
            </div>

            <!-- HEADER-CELL -->
            <!-- Fix up header slots so they're more friendly for us -->
            <template
                slot="header-cell"
                slot-scope="data"
            >
                <q-th
                    class="ptable-header-cell"
                    :class="{'text-center': data.col.align === 'center', 'text-left': data.col.align === 'left', 'text-right': !data.col.align || data.col.align === 'right',}"
                >
                    <slot
                        v-if="data.col.name !== hiddenColumn.name"
                        :name="`header-${data.col.name}`"
                        v-bind="data.col"
                    >
                        <strong v-if="!data.col.sortable">{{ data.col.label }}&nbsp;</strong>
                        <span
                            v-else
                            class="pointer"
                            @click="runSort(data.col.name)"
                        >
                            <q-icon
                                v-if="data.col.name === pagination.sortBy && pagination.descending === false"
                                name="arrow_upward"
                                class="pointer table-sort-arrow"
                            />
                            <q-icon
                                v-if="data.col.name === pagination.sortBy && pagination.descending === true"
                                name="arrow_downward"
                                class="pointer table-sort-arrow"
                            />
                            &nbsp;<strong>{{ data.col.label }}</strong>
                            <q-tooltip :delay="750">Click to sort</q-tooltip>
                        </span>
                    </slot>
                </q-th>
            </template>

            <!-- Hide our row counts -->
            <q-td
                slot="body-cell-__add__"
            />

            <!-- ALL SLOTS -->
            <!-- Inject the slots so they're available to everyone -->
            <template
                v-for="(_, slot) of $scopedSlots"
                v-slot:[slot]="scope"
            >
                <slot
                    :name="slot"
                    v-bind="scope"
                />
            </template>
        </q-table>

        <ColumnSelector
            v-if="showColumnSelector"
            v-model="showColumnSelector"
            :displayed-column-names.sync="mutableVisibleColumnNames"
            :all-columns="originalColumns"
            @apply-selection="applySelectedColumns"
        />
    </div>
</template>

<script>
import Vue from 'vue';
import PDialog from '../../../services/pai-components/PDialog.vue';
import GeneralButton from '../../inline-elements/GeneralButton.vue';
import Settings from '../../settings/Settings.vue';
import ColumnSelector from '../ColumnSelector.vue';

export default {
    name: 'PDataTable',
    components: {
        ColumnSelector,
        Settings,
        GeneralButton,
        PDialog,
    },
    props: {
        // v-model used to trigger a refresh of data in table
        value: {
            type: Boolean,
            required: false,
            default: false,
        },
        // Column values for the data table
        // see: https://quasar.dev/vue-components/table#QTable-API
        columns: {
            type: Array,
            required: true,
        },
        visibleColumns: {
            type: Array,
            required: false,
            default: () => [],
            validator: (v) => v.every(x => typeof x === 'string'),
        },
        // Header value for the data table
        header: {
            type: String,
            required: false,
            default: null,
        },
        // Name of the endpoint to hit using the petro API
        dataType: {
            type: String,
            required: false,
            default: null,
        },
        // Flag for signaling that the table can be selected from
        selection: {
            type: String,
            required: false,
            default: () => 'none',
            validator: (v) => ['none', 'single', 'multiple'].includes(v),
        },
        // Sync-ed selected values with the table
        selected: {
            type: Array,
            required: false,
            default: () => [],
        },
        selectionKey: {
            type: String,
            required: false,
            default: 'id',
        },
        // List of data to use for building up the table
        tableData: {
            type: Array,
            required: false,
            default: () => [],
        },
        // Id for retrieving saved table settings
        tableId: {
            type: String,
            required: false,
            default: 'Table Column Options',
        },
        // Query to use against the API
        query: {
            type: Object,
            required: false,
            default: null,
        },
        // Flag for showing the column select PSettings component
        showColumnSelect: {
            type: Boolean,
            required: false,
            default: false,
        },
        // Parent ID for PSettings component. MUST BE USED IF showColumnSelect is true
        columnSelectSettingsParentId: {
            type: String,
            required: false,
            default: '',
        },
        // Flag for the underlying table that we want to display in scrunchy mode
        dense: {
            type: Boolean,
            required: false,
            default: false,
        },
        // Flag to display table as flat with no border
        flat: {
            type: Boolean,
            required: false,
            default: false,
        },
        // Explicitly hide select all when selection is enabled
        hideSelectAll: {
            type: Boolean,
            required: false,
            default: false,
        },
        // Flag to allow the table to be "fullscreen"-able
        allowFullScreen: {
            type: Boolean,
            required: false,
            default: false,
        },
        // Optional method to sort by
        sortMethod: {
            type: Function,
            required: false,
            default: null,
        },
        wrapCells: {
            type: Boolean,
            required: false,
            default: false,
        },
        // Filter string used when tableData is provided
        filter: {
            type: String,
            required: false,
            default: '',
        },
        // Force the top menu to be hidden
        hideTopMenu: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideColumnHeaders: {
            type: Boolean,
            required: false,
            default: false,
        },
        // Hide the bottom bar including the pagination controls
        hideBottom: {
            type: Boolean,
            required: false,
            default: false,
        },
        // Quasar pagination object
        // See https://quasar.dev/vue-components/table#Pagination
        paginationOptions: {
            type: Object,
            required: true,
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        noDataLabel: {
            type: String,
            required: false,
            default: 'No data available',
        },
        tableStyle: {
            type: [String, Array, Object],
            required: false,
            default: null,
        },
        // If a dev wants to add any buttons to the top left slot for a datatable, need to pass in those buttons as props
        topLeftButtons: {
            type: Array,
            required: false,
            default: () => [],
        },
        // If a dev wants to add any buttons to the top right slot for a datatable, need to pass in those buttons as props
        topRightButtons: {
            type: Array,
            required: false,
            default: () => [],
        },
        topRightButtonsDropDown: {
            type: Array,
            required: false,
            default: () => [],
        },
        // These filters will let the developer filter the contents of a table from the top left slot
        topLeftDataFilterButtons: {
            type: Array,
            required: false,
            default: () => [],
        },
        // This defines the content of the filter if its a dropdown type button
        topLeftFilterButtonDropdown: {
            type: Array,
            required: false,
            default: () => [],
        },
        tableType: {
            type: String,
            required: false,
            default: '',
        },
    },
    data() {
        return {
            // inner* values are used to hold property info that we need to modify
            innerTableData: [],
            innerVisible: [],
            viewingColumns: [],
            columnSelectorFocused: false,
            hiddenColumn: {
                name: '__add__',
                field: '__rowId',
                align: 'right',
                sortable: true,
            },
            showColumnSelector: false,
            originalColumns: [],
            settings: null,
            mutableVisibleColumnNames: [],
            showSelectAllPrompt: false,
            tableOptionsExpanded: false,
        };
    },
    computed: {
        innerLoading: {
            get() {
                return this.loading;
            },
            set(value) {
                this.$emit('update:loading', value);
            },
        },
        innerSelectedValues: {
            get() {
                return this.selected;
            },
            set(value) {
                // Get the remove items if any are there
                const changes = {
                    // The new value
                    value,
                    // Get the rows that were removed by the selection if any were present
                    removed: this.selected.filter(current => value.findIndex(diff => diff.__rowId === current.__rowId) === -1),
                    // Get the rows that were added by the selection if any were present
                    added: value.filter(diff => this.selected.findIndex(current => current.__rowId === diff.__rowId) === -1),
                };
                if (
                    changes.removed.length === 0
                    && changes.added.length === 0
                    // Check if value and the selected are the same.
                    // If they are we don't want to trigger an $emit as this
                    // can affect some of the forge marking
                    && value
                    .map(v => v.__rowId)
                    .every(v => this.selected.findIndex(s => s.__rowId === v) > -1)
                ) {
                    return;
                }
                this.$emit('update:selected', value);
                // Kept in here for backwards compatibility
                this.$emit('selected', changes);
            },
        },
        refreshNeeded: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            },
        },
        pagination: {
            get() {
                return this.paginationOptions;
            },
            set(newPaginationOptions) {
                this.$emit('update:pagination-options', newPaginationOptions);
            },
        },
        isSortable() {
            return this.innerColumns.every(x => x.sortable);
        },
        // Method for building dynamic props
        tableProperties() {
            const props = {
                columns: this.innerColumns,
                data: this.innerTableData,
                title: this.header,
                loading: this.innerLoading,
                dense: this.dense,
                visibleColumns: this.innerVisible,
                flat: this.flat,
                filter: this.filter,
                hideBottom: this.hideBottom,
            };

            if (this.selection) {
                props.selection = this.selection;
            }

            if (this.sortMethod) {
                props['sort-method'] = this.sortMethod;
            }

            return props;
        },
        availableColumns: {
            get() {
                return this.innerColumns
                .filter(x => !x.required && x.name !== this.hiddenColumn.name)
                .map(x => x.name);
            },
        },
        // Inner columns ensures that the hidden column in always present
        innerColumns() {
            // Time to reorder some columns this might be expensive
            const items = [];

            // Add all columns from mutableVisibleColumnNames
            this.mutableVisibleColumnNames.forEach(mutableVisibleColumnName => {
                const column = this.columns.find(col => col.name === mutableVisibleColumnName);
                if (column) {
                    // Left align columns by default
                    if (!column.align) {
                        column.align = 'left';
                    }
                    items.push(column);
                }
            });

            // Add all columns that are not in mutableVisibleColumnNames
            this.columns.forEach(column => {
                if (this.mutableVisibleColumnNames.indexOf(column.name) === -1) {
                    // Left align columns by default
                    if (!column.align) {
                        column.align = 'left';
                    }
                    items.push(column);
                }
            });

            const innerColumns = [...items, this.hiddenColumn];
            return innerColumns;
        },
        showSelectAll() {
            return !this.dataType && !this.hideSelectAll && this.selection === 'multiple';
        },
    },
    watch: {
        refreshNeeded(newValue, oldValue) {
            if (newValue) {
                this.handleRequest({ pagination: this.pagination });
                this.refreshNeeded = false;
            }
        },
        tableData() {
            this.refresh();
        },
        columns: {
            deep: true,
            handler() {
                this.refresh();
                if (this.showColumnSelect === false || this.showColumnSelect === null) {
                    if (this.visibleColumns && this.visibleColumns.length > 0) {
                        this.mutableVisibleColumnNames = this.visibleColumns;
                    }
                    else {
                        const temp = this.columns.map(x => x.name).slice(0, 80);
                        this.mutableVisibleColumnNames = temp;
                    }
                }
            },
        },
        mutableVisibleColumnNames() {
            this.refresh();
        },
    },
    async mounted() {
        if (this.mutableVisibleColumnNames.length === 0) {
            const temp = this.columns.map(x => x.name).slice(0, 80);
            this.mutableVisibleColumnNames = temp;
        }
        if (this.showColumnSelect === false || this.showColumnSelect === null) {
            if (this.visibleColumns && this.visibleColumns.length > 0) {
                this.mutableVisibleColumnNames = this.visibleColumns;
            }
            else {
                const temp = this.columns.map(x => x.name).slice(0, 80);
                this.mutableVisibleColumnNames = temp;
            }
        }
        await this.refresh();

        // Only sort by selected rows if selection is enabled
        if (this.selection !== 'none') {
            this.sortSelected();
        }
    },
    methods: {
        async refresh() {
            this.innerLoading = true;
            this.originalColumns = [...this.columns].sort((a, b) => a.name.localeCompare(b.name));
            // If no visible columns were provided, make our own
            if (this.mutableVisibleColumnNames) {
                this.innerVisible = [...this.mutableVisibleColumnNames];
            }
            this.innerVisible.push(this.hiddenColumn.name);

            if (this.dataType) {
                await this.handleRequest({ pagination: this.pagination });
            }
            else if (this.tableData) {
                this.innerTableData = this.addRowIds([...this.tableData]);
            }
            this.innerLoading = false;
        },
        applySelectedColumns(selectedColumnNames) {
            this.mutableVisibleColumnNames = selectedColumnNames;
        },
        calculateSkip(pageNumber, perPage) {
            return (pageNumber - 1) * perPage;
        },
        addRowIds(tableData) {
            if (!tableData) {
                return;
            }

            let offset = 0;
            const bag = [];

            this.selected.forEach((target, idx) => {
                let itemIndex = -1;
                if (this.selectionKey) {
                    itemIndex = tableData.findIndex(record => record[this.selectionKey] === target[this.selectionKey]);
                }
                else {
                    itemIndex = tableData.findIndex(record => record.__index === target.__index);
                }

                if (itemIndex > -1) {
                    const item = tableData[itemIndex];
                    item.__rowId = idx;
                    item.__index = idx;
                    // item.__selected = true;
                    offset += 1;
                    bag.push(item);
                    tableData.splice(itemIndex, 1);
                }
            });

            tableData.forEach((item, idx) => {
                item.__rowId = offset + (this.pagination.rowsPerPage * (this.pagination.page - 1));
                item.__index = offset + (this.pagination.rowsPerPage * (this.pagination.page - 1));
                // item.__selected = false;
                offset += 1;
            });

            // Put the items in the bag back in
            tableData.push(...bag);
            return tableData;
        },
        async handleRequest({ pagination, filter }) {
            // Only handle q-table requests if using the API
            if (this.dataType) {
                // Create the query from the API call
                let query = {
                    skip: this.calculateSkip(pagination.page, pagination.rowsPerPage),
                    limit: pagination.rowsPerPage,
                };

                // Apply any sorting options
                if (pagination.sortBy) {
                    query.sortBy = pagination.sortBy;
                    query.sortOrder = pagination.descending ? 'descending' : 'ascending';
                }

                // Merge in user supplied query, NOTE user query will override any existing query options
                if (this.query) {
                    query = { ...query, ...this.query };
                }

                // Handle pagination for our boys.
                const params = {
                    type: this.dataType,
                    query: {},
                    options: query,
                };
                await this.$api.data.query(params)
                .then(response => {
                    // Tell Quasar that we're doing server side rendering, also informs the pagination rendering
                    Vue.set(this.pagination, 'rowsNumber', response.total);
                    // Update pagination with the emitted values from q-table
                    this.pagination.page = pagination.page;
                    this.pagination.rowsPerPage = pagination.rowsPerPage;
                    this.pagination.sortBy = pagination.sortBy;
                    this.pagination.descending = pagination.descending;

                    // Set the Data from the API
                    this.innerTableData = response.data;
                    this.addRowIds(this.innerTableData);
                })
                .catch(error => {
                    this.$notify.error(`Unable to get ${this.dataType}: ${error.message}`);
                });
            }
        },
        handleSelect(event) {
            // there is no difference in the evnet when you select all verse select one individually
            if (event.added === false && event.rows.length > 1) {
                this.unselectAll();
            }
        },
        resetSelected() {
            const selected = [...this.innerSelectedValues];
            this.innerSelectedValues = [];
            this.innerSelectedValues = selected;
        },
        resetPaginationSort() {
            const oldPagination = { ...this.pagination };
            oldPagination.sortBy = null;
            this.pagination = oldPagination;
        },
        sortSelected() {
            const standInPaginationObject = {
                page: 1,
                descending: false,
                sortBy: this.hiddenColumn.name,
                rowsPerPage: this.pagination.rowsPerPage,
            };
            // Refresh the table so we get the __rowIds updated
            this.refresh();

            // Make sure that the pagination update gets sent to the parent, can't just assign prop values
            this.pagination = { ...standInPaginationObject };

            // In order to trigger a rerender, we have to reassign the selection.
            this.innerSelectedValues = [...this.innerSelectedValues];
        },
        runSort(columnName) {
            // Reset the page to the first one
            this.pagination.page = 1;
            // Currently descending move to ascending
            if (this.pagination.sortBy === columnName && this.pagination.descending === true) {
                this.pagination.descending = false;
            }
            // Currently ascending move to default
            else if (this.pagination.sortBy === columnName && this.pagination.descending === false) {
                this.pagination.sortBy = null;
                this.pagination.descending = null;
            }
            // Currently default move to ascending
            else {
                this.pagination.sortBy = columnName;
                this.pagination.descending = true;
            }

            this.handleRequest({ pagination: this.pagination });
        },
        selectAll() {
            this.$emit('update:selected', this.innerTableData);
            this.$emit('selected', { value: this.innerTableData });
        },
        clearAllSelected() {
            this.$emit('update:selected', []);
            this.$emit('selected', []);
        },
        selectAllVisible() {
            const skip = this.calculateSkip(this.pagination.page, this.pagination.rowsPerPage);
            let lastRowInSelection = skip + this.pagination.rowsPerPage;
            lastRowInSelection = (lastRowInSelection > 0 ? lastRowInSelection : this.tableData.length);

            // Loop over the table data
            const newSelection = [];
            this.tableData.forEach(row => {
                if (row.__rowId >= skip && row.__rowId < lastRowInSelection) newSelection.push(row);
            });
            this.$emit('update:selected', newSelection);

            this.$emit('selected');
        },
        unselectAll() {
            // this.resetPaginationSort();
            this.$emit('update:selected', []);
            this.$emit('selected', { value: [] });
        },
        openColumnSelector() {
            this.showColumnSelector = true;
        },
        expandTableOptions() {
            this.tableOptionsExpanded = !this.tableOptionsExpanded;
        },
    },
};
</script>

<style lang="stylus" scoped>
.ptable-header-cell > .row
    width fit-content
    flex-wrap nowrap
    margin 0 auto

// Remove the padding from the top row
.q-table__top
    padding: 0

// Set a fixed height for the table middle
// NOTE: 43px is height of table bottom, 54px is height of table top
.q-table__middle
    height calc(100% - 43px - 54px)

// This hides the select all box on the q-table
.hidden-select-all
    thead tr:first-child th:first-child
        visibility hidden

.sticky-header
    .q-table__top,
    .q-table__bottom,
    thead tr:first-child td
        background-color #fff

    thead tr:first-child td
        position sticky
        top 0
        opacity 1
        z-index 1

    thead tr:first-child th /* bg color is important for th; just specify one */
        background-color #fff

    thead tr:first-child th
        position sticky
        top 0
        opacity 1
        z-index 1

.q-table__top
    padding: 0 !important

.sticky-first-column
    /* bg color is important for th; just specify one */
    /*thead tr:first-child th:first-child*/
    /*    background-color green*/
    /*    opacity 1*/

    td:first-child
        background-color #fff

    thead tr:first-child th:first-child,
    td:first-child
        position sticky
        left 0
        z-index 1
</style>
