<template>
    <div class="overflow-auto">
        <div
            v-if="!data.length && tableViewSyncedWithOptions && !showLoading"
            class="text-center items-center absolute full-width full-height col flex text-grey-6"
        >
            <div
                v-if="searchText"
                class="col"
            >
                <img :src="require('@/assets/images/table-filtered.svg')">
                <div>
                    Clear search to see rows.
                </div>
            </div>
            <div
                v-else-if="activeFiltersCount"
                class="col"
            >
                <img :src="require('@/assets/images/table-filtered.svg')">
                <div>
                    Clear filters to see rows.
                </div>
            </div>
            <div
                v-else-if="displayedColumns.length === 0"
                class="col"
            >
                <img :src="require('@/assets/images/table-filtered.svg')">
                <div>
                    Adjust column settings to see rows.
                </div>
            </div>
            <div
                v-else
                class="col"
            >
                <img :src="require('@/assets/images/table-empty.svg')">
                <div>
                    This table has no data.
                </div>
            </div>
        </div>

        <q-table
            v-cypress="'ForgeDataTable_Table'"
            :columns="localColumnDefinitions"
            :data="data"
            :pagination.sync="paginationOptions"
            :rows-per-page-options="[5, 10, 25, 50, 100]"
            :row-key="markingIdFieldName"
            :selected="visibleSelectedRows"
            :selection="selectionType"
            :selected-rows-label="getEmptySelectedRowsLabel"
            flat
            class="forge-table"
            :class="{ transparent: !data.length, 'table-height-full': !showAsGrid }"
            :grid="showAsGrid"
            :visible-columns="visibleColumns"
            @request="handleRequest"
            @selection="handleSelection"
        >
            <!-- Top Left Slot -->
            <template v-slot:top-left>
                <div
                    v-cypress="'ForgeDataTable_TopLeft_Slot'"
                    class="row items-center q-pb-sm"
                >
                    <q-input
                        v-model="searchInput"
                        v-cypress="'ForgeDataTable_Search_Input'"
                        borderless
                        filled
                        dense
                        clearable
                        class="col"
                        placeholder="Search"
                        style="width: 15vw"
                        @keypress.enter="performSearch"
                        @clear="performSearch"
                    >
                        <template v-slot:prepend>
                            <q-icon name="search"/>
                        </template>
                    </q-input>

                    <span
                        v-if="selectionType !== 'none'"
                        class="q-px-md"
                    >
                        {{ selectedRowsDisplayText }}
                    </span>


                    <GeneralButton
                        v-if="selectionType === 'multiple'"
                        :disabled="selectAllBtnProps.disabled"
                        class="q-mr-sm"
                        label="Select All"
                        :tooltip="selectAllBtnProps.tooltip"
                        @click="selectAll"
                    />

                    <div v-if="selectedIds.length">
                        <GeneralButton
                            v-if="selectionType !== 'none'"
                            v-cypress="'ForgeDataTable_ClearSelection_Button'"
                            class="q-mr-sm"
                            label="Clear Selection"
                            tooltip="Unselect all records"
                            :disabled="!selectedIds.length"
                            @click="unselectAll"
                        />

                        <GeneralButton
                            v-for="(button, idx) in wrappedTopRowButtons"
                            :key="`topRowBtn-${idx}`"
                            v-cypress="button.props.testId"
                            class="q-mr-sm"
                            v-bind="button.props"
                            v-on="button.events"
                        />
                    </div>
                </div>
            </template>

            <!-- Top Right Slot -->
            <template v-slot:top-right>
                <div
                    class="row col-auto items-center q-pb-sm"
                >
                    <Settings
                        v-model="displayedColumns"
                        v-cypress="'ForgeDataTable_ColumnSettings_Button'"
                        display-name="Column Preferences"
                        :reference-id="settingsReferenceId"
                        :parent-id="parentId"
                        label="Column Settings"
                        :icon="null"
                        :color="null"
                        use-local-storage
                        version="1"
                        @no-local-settings-found="setInitialDisplayedColumns"
                    >
                        <template v-slot:menu-bottom>
                            <q-item
                                v-cypress="'ForgeDataTable_ColumnSettings_OptionsMenuItem'"
                                clickable
                                dense
                                @click="showColumnSelector = true"
                            >
                                <q-item-section>Column Options</q-item-section>
                            </q-item>
                        </template>
                    </Settings>
                </div>
            </template>

            <!-- Header -->
            <template v-slot:header-cell="context">
                <HeaderCell
                    :context="context"
                    :sort-by-field-name="sortByFieldName"
                    :sort-ascending="sortAscending"
                    @sort="applySort"
                />
            </template>

            <!-- Badge Columns -->
            <template
                v-for="(badgeColumn, index) in badgeColumnNames"
                v-slot:[`body-cell-${badgeColumn}`]="context"
            >
                <BadgeColumn
                    :key="`${badgeColumn}-${index}`"
                    :context="context"
                />
            </template>

            <!-- Progress Columns -->
            <template
                v-for="(progressColumn, index) in progressColumnNames"
                v-slot:[`body-cell-${progressColumn}`]="context"
            >
                <ProgressColumn
                    :key="`${progressColumn}-${index}`"
                    :context="context"
                />
            </template>

            <!-- User Columns -->
            <template
                v-for="(userColumn, index) in userColumnNames"
                v-slot:[`body-cell-${userColumn}`]="context"
            >
                <UserColumn
                    :key="`${userColumn}-${index}`"
                    :context="context"
                />
            </template>

            <!-- Default Columns -->
            <template
                v-for="(defaultColumn, index) in defaultColumnNames"
                v-slot:[`body-cell-${defaultColumn}`]="context"
            >
                <DefaultColumn
                    :key="`${defaultColumn}-${index}`"
                    :context="context"
                    :time-zone-id="displayTimeZoneId"
                />
            </template>
        </q-table>

        <q-inner-loading :showing="showLoading">
            <div class="row">
                <q-spinner
                    size="50px"
                    color="primary"
                />
            </div>
            <div class="row">
                <p>Loading...</p>
            </div>
        </q-inner-loading>

        <ColumnSelector
            v-if="showColumnSelector"
            v-model="showColumnSelector"
            :displayed-column-names="displayedColumns"
            :all-columns="allSortedColumnDefinitions"
            ok-btn-test-id="ForgeDataTable_ColumnSettingsOK_Button"
            @apply-selection="applySelectedColumns"
        />
    </div>
</template>

<script>
import _ from 'lodash';
import Settings from '../../settings/Settings.vue';
import GeneralButton from '../../inline-elements/GeneralButton.vue';
import UserColumn from './UserColumn.vue';
import ProgressColumn from './ProgressColumn.vue';
import DefaultColumn from './DefaultColumn.vue';
import BadgeColumn from './BadgeColumn.vue';
import HeaderCell from './HeaderCell.vue';
import ColumnSelector from '../ColumnSelector.vue';

export default {
    name: 'ForgeDataTable',
    components: {
        ColumnSelector,
        HeaderCell,
        BadgeColumn,
        DefaultColumn,
        ProgressColumn,
        UserColumn,
        Settings,
        GeneralButton,
    },
    props: {
        parentId: {
            type: String,
            required: true,
        },
        tableView: {
            type: Object,
            required: true,
        },
        columnOptions: {
            type: Array,
            required: false,
            default: () => [],
        },
        markingWorkspace: {
            type: String,
            required: true,
        },
        tableType: {
            type: String,
            required: false,
            default: '',
        },
        selectionType: {
            type: String,
            required: false,
            default: () => 'multiple',
            validator: (v) => ['none', 'single', 'multiple'].includes(v),
        },
        tableOptions: {
            type: Object,
            required: true,
        },
        initialSortFieldName: {
            type: String,
            required: false,
            default: null,
        },
        initialSortAscending: {
            type: Boolean,
            required: false,
            default: null,
        },
        initialRowsPerPage: {
            type: Number,
            required: false,
            default: 10,
        },
        timeZoneId: {
            type: String,
            required: false,
            default: null,
        },
        topRowButtons: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    data() {
        return {
            // Flag to indicate that initial load of data is complete
            // TODO: Fix this hack as tableView records should be indicative of this
            initialLoadComplete: true,

            showColumnSelector: false,
            searchInput: null,

            // Local copy of table view, the local copy is used for the UI and receives updates from forge
            localTableView: null,
            // local copy of table options, the local copy is used for the UI and receives updates from forge
            localTableOptions: null,
            // tracker containing information about tableView's live status
            tableViewTracker: {
                live: true,
                lastUpdateSent: Date.now(),
                // Time to wait for selection to match before reconnecting live data
                updateWaitTime: 3000,
            },
            // tracker containing information about tableOptions's live status
            tableOptionsTracker: {
                live: true,
                lastUpdateSent: Date.now(),
                lastUpdate: {},
                updateWaitTime: 3000,
                timeoutHandle: null,
            },
            // Flag to show the markingIdFieldName
            showMarkingIdFieldNameColumn: false,
        };
    },
    computed: {
        // If timezoneId is not passed then use the global timezoneId.
        displayTimeZoneId() {
            return this.timeZoneId || this.$globalSettings.displayTimeZoneId;
        },
        // LOCAL TABLE VIEW PROPERTIES
        // Primary property for table data
        data() {
            // If there are no columns to show or no records then don't show any data.
            if (this.displayedColumns.length === 0 || !this.localTableView.viewRecords) {
                return [];
            }
            return this.localTableView.viewRecords;
        },
        // Primary row key name for table
        markingIdFieldName() {
            return this.localTableView.markingIdFieldName || 'id';
        },
        maxSelectableItems() {
            return this.localTableView.maxSelectableItems;
        },
        totalNumFilteredRecords() {
            return this.localTableView.totalNumFilteredRecords || 0;
        },
        selectedIds: {
            get() {
                return this.localTableView.selectedIds || [];
            },
            set(value) {
                this.localTableView.selectedIds = value;
            },
        },

        // LOCAL TABLE OPTIONS PROPERTIES
        skip() {
            return this.localTableOptions.skip || 0;
        },
        limit() {
            return this.localTableOptions.limit || 10;
        },
        searchText() {
            return this.localTableOptions.searchText;
        },
        sortAscending() {
            return this.localTableOptions.sortAscending;
        },
        sortByFieldName() {
            return this.localTableOptions.sortByFieldName;
        },
        // Calculated page that is currently shown
        page() {
            const skip = this.skip;
            const limit = this.limit;

            if ((skip === undefined || limit === undefined) || skip === 0) {
                return 1;
            }
            return (skip / limit) + 1;
        },

        // COLUMN PROPERTIES
        sourceCollectionSchema() {
            return this.localTableView.sourceCollectionSchema || {};
        },
        fieldsToInclude() {
            return this.localTableView.fieldsToInclude || [];
        },
        badgeColumnNames() {
            return Object.values(this.normalizedColumnOptions).filter(column => column.type === 'badge').map(column => column.name);
        },
        progressColumnNames() {
            return Object.values(this.normalizedColumnOptions).filter(column => column.type === 'progress').map(column => column.name);
        },
        userColumnNames() {
            return Object.values(this.normalizedColumnOptions).filter(column => column.type === 'user').map(column => column.name);
        },
        defaultColumnNames() {
            return this.localColumnDefinitions.map(column => column.name).filter(name => !this.badgeColumnNames.includes(name)
                && !this.progressColumnNames.includes(name)
                && !this.userColumnNames.includes(name));
        },
        initiallyDisplayedColumnNames() {
            // Check for required columns.
            const pluginRequiredColumnNames = this.requiredColumnNames;

            // Check the plugin preferences for columns displayed by default.
            const pluginInitiallyDisplayedColumnNames = Object.values(this.normalizedColumnOptions).filter(columnDefinition => columnDefinition.displayedByDefault).map(colDefinition => colDefinition.name);

            const initialColumnNames = _.union(pluginRequiredColumnNames, pluginInitiallyDisplayedColumnNames);
            if (initialColumnNames.length) {
                // As long as one of those two is non-empty, no need to keep looking for defaults.
                return initialColumnNames;
            }

            // Attempt to use some defaults by validating them against the schema.
            // Ensured to always have the row key by default, as source collection schema may still be loading from forge.
            const defaultInitiallyDisplayedColumnNames = [this.markingIdFieldName];

            // Check for a field called name
            if (this.sourceCollectionSchema.name) {
                defaultInitiallyDisplayedColumnNames.push('name');
            }
            // Check for a field called createdAt
            if (this.sourceCollectionSchema.createdAt) {
                defaultInitiallyDisplayedColumnNames.push('createdAt');
            }
            // Check for a field called createdBy
            if (this.sourceCollectionSchema.createdBy) {
                defaultInitiallyDisplayedColumnNames.push('createdBy');
            }
            // Hopefully something above stuck ;)
            return defaultInitiallyDisplayedColumnNames;
        },
        // takes the column options provided by plugin author and ensures defaults are set
        normalizedColumnOptions() {
            const normalizedColumnOptions = {};
            this.columnOptions.forEach((option, index) => {
                if (option.name === undefined) {
                    this.$logging.loggers.PluginFramework.error(`Column definition at position ${index} is missing required 'name' field. Column definition will be skipped and default will be used.`);
                }
                else {
                    const columnName = option.name;
                    if (normalizedColumnOptions[columnName]) {
                        return;
                    }

                    normalizedColumnOptions[columnName] = this.setDefaultColumnOptions(option);
                }
            });
            return normalizedColumnOptions;
        },
        columnDefinitions() {
            const columnDefinitions = this.normalizedColumnOptions;

            // Use the schema to determine column types, and to define any columns that are not explicitly defined.
            Object.entries(this.sourceCollectionSchema).forEach(([columnName, columnSchema]) => {
                let columnDefinition = columnDefinitions[columnName];
                if (!columnDefinition) {
                    // This column was not explicitly defined, so give it a default definition.
                    columnDefinition = {
                        name: columnName,
                        align: 'left',
                        label: this.$utils.formatting.startCase(columnName),
                        field: columnName,
                        sortable: true,
                        displayedByDefault: false,
                    };
                    columnDefinitions[columnName] = columnDefinition;
                }

                // Set data type formatters for all columns in the schema.
                switch (columnSchema.type) {
                    case 'System.DateTime':
                        columnDefinition.format = (value, row) => this.$utils.formatting.formatLongDateTime(value, this.displayTimeZoneId);
                        break;
                    case 'System.Double':
                        columnDefinition.format = (value, row) => this.$utils.formatting.formatNumber(value);
                        break;
                    // All other types (string, int32, boolean) should just pass through the value
                    default:
                        columnDefinition.format = (value, row) => {
                            if (value === null || value === undefined) {
                                return '-';
                            }
                            return value;
                        };
                        break;
                }
            });

            return columnDefinitions;
        },
        requiredColumnNames() {
            const requiredColumnDefinitions = Object.values(this.normalizedColumnOptions).filter(columnOption => columnOption.required);
            return requiredColumnDefinitions.map(columnDefinition => columnDefinition.name);
        },
        allSortedColumnDefinitions() {
            const localDefinitions = {};
            this.localColumnDefinitions.forEach(columnDefinition => {
                localDefinitions[columnDefinition.name] = columnDefinition;
            });
            const allDefinitions = { ...localDefinitions, ...this.columnDefinitions };
            return Object.values(allDefinitions).sort((a, b) => a.name.localeCompare(b.name));
        },
        // In q-table the order of definitions determines the order of columns so we need to generate the ordered array of definitions from the column definitions object
        // Order and presence is determined by fieldsToInclude from Forge and required columns from column definition
        localColumnDefinitions() {
            // If there are no fields specified, then show the initially-displayed ones.
            if (this.fieldsToInclude.length === 0) {
                return Object.values(this.normalizedColumnOptions).filter(columnOption => this.initiallyDisplayedColumnNames.includes(columnOption.name));
            }

            const localColumnDefinitions = [];
            // Make sure any required names are included.
            const localColumnNames = _.union(this.fieldsToInclude, this.requiredColumnNames);
            localColumnNames.forEach(columnName => {
                const definition = this.columnDefinitions[columnName];
                if (definition) {
                    localColumnDefinitions.push(definition);
                }
                else {
                    // There is no explicit definition and nothing in the schema.
                    // This can happen when there are no (representative) rows from which to build the schema.
                    this.$logging.loggers.PluginFramework.debug(`No definition found for column '${columnName}; using default options.`);
                    localColumnDefinitions.push(this.setDefaultColumnOptions({ name: columnName }));
                }
            });

            return localColumnDefinitions;
        },
        displayedColumns: {
            get() {
                // if we are not showing the marking Id column then remove it from the displayed columns
                if (!this.showMarkingIdFieldNameColumn) {
                    return this.localColumnDefinitions.map(colDefinition => colDefinition.name).filter(columnName => columnName !== this.markingIdFieldName);
                }
                return this.localColumnDefinitions.map(colDefinition => colDefinition.name);
            },
            set(values) {
                this.$logging.loggers.PluginFramework.debug('Setting displayedColumns:', values);
                let fieldsToInclude = values;

                // If we don't have any columns then use the initiallyDisplayedColumnNames
                if (fieldsToInclude.length === 0) {
                    this.$logging.loggers.PluginFramework.debug('No columns set so using initiallyDisplayedColumnNames:', this.initiallyDisplayedColumnNames);
                    fieldsToInclude = this.initiallyDisplayedColumnNames;
                }

                // Toggle the showing/hiding of the markingId column
                // This is done so that table can hide the markingId column when needed but still has access to it in the data
                this.showMarkingIdFieldNameColumn = !!fieldsToInclude.includes(this.markingIdFieldName);

                // Send the update to the forge
                this.updateTableOptions({
                    fieldsToInclude,
                });
            },
        },
        visibleColumns() {
            if (this.showAsGrid) {
                // In grid mode, cap the number of columns, so the cards aren't too tall.
                const visibleColumns = [...this.displayedColumns];
                visibleColumns.length = Math.min(visibleColumns.length, 5);
                return visibleColumns;
            }
            return this.displayedColumns;
        },
        // GENERAL COMPUTED PROPERTIES
        wrappedTopRowButtons() {
            const wrappedTopRowButtons = this.topRowButtons.map(button => {
                const wrappedEvents = {};
                Object.entries(button.events).forEach(([eventName, eventFunc]) => {
                    wrappedEvents[eventName] = (event) => {
                        eventFunc({ event, selectedIds: this.selectedIds });
                    };
                });
                return {
                    ...button,
                    events: {
                        ...wrappedEvents,
                    },
                };
            });
            return wrappedTopRowButtons;
        },
        showLoading() {
            return !this.tableOptionsTracker.live || !this.tableViewSyncedWithOptions || !this.initialLoadComplete;
        },
        // q-table selected rows that are currently in view
        visibleSelectedRows() {
            if (!this.markingIdFieldName) {
                return [];
            }
            const visibleSelectedRows = this.selectedIds.map(id => {
                const selectedRow = {};
                selectedRow[this.markingIdFieldName] = id;
                return selectedRow;
            });
            return visibleSelectedRows;
        },
        selectedRowsDisplayText() {
            if (this.selectedIds.length === 0) {
                return 'None selected';
            }
            if (this.selectedIds.length === 1) {
                return '1 row selected';
            }
            return `${this.selectedIds.length} rows selected`;
        },
        // Flag to verify that table options are accurately reflected in the tableView data, i.e. data reflects pagination/search
        tableViewSyncedWithOptions() {
            const skipEqual = this.skip === this.tableView.skip;
            const limitEqual = this.limit === this.tableView.limit;
            const searchEqual = this.searchText === this.tableView.searchText;
            return skipEqual && limitEqual && searchEqual;
        },
        settingsReferenceId() {
            return `forgeDataDisplayType-${this.tableType}`;
        },
        // Modify the select all button disabled state and tooltip based on total number of records
        selectAllBtnProps() {
            const allSelected = this.selectedIds.length === this.totalNumFilteredRecords;
            if (allSelected) {
                return {
                    disabled: true,
                    tooltip: 'All records currently selected',
                };
            }

            const tooManyToSelect = this.totalNumFilteredRecords > this.maxSelectableItems;
            if (tooManyToSelect) {
                return {
                    disabled: true,
                    tooltip: `Unable to select more than ${this.maxSelectableItems} records`,
                };
            }

            return {
                disabled: false,
                tooltip: `Select ${this.totalNumFilteredRecords} records`,
            };
        },
        paginationOptions: {
            get() {
                return {
                    sortBy: null, // Sent to make q-table happy--not used.
                    descending: null, // Sent to make q-table happy--not used.
                    page: this.page,
                    rowsPerPage: this.limit,
                    rowsNumber: this.totalNumFilteredRecords,
                };
            },
            set(value) {
                this.updateTableOptions({
                    limit: value.rowsPerPage,
                    skip: (value.page - 1) * value.rowsPerPage,
                });
            },
        },
        activeFiltersCount() {
            let activeCount = 0;
            const displayFrags = this.$pai.getFragments('page-filter').filter(fragment => {
                if (!fragment.props || !fragment.props.routes) {
                    return false;
                }
                return !fragment.props.hide && fragment.props.routes.includes(this.$route.name);
            });
            displayFrags.forEach(fragment => {
                if (fragment.props && fragment.props.indexData) {
                    fragment.props.indexData.forEach(indexDef => {
                        activeCount += indexDef.filters.filter(filterDef => filterDef.filterSet).length;
                    });
                }
            });
            return activeCount;
        },
        showAsGrid() {
            return this.$q.screen.lt.md;
        },
    },
    watch: {
        tableView: {
            immediate: true,
            handler(newTableView) {
                this.$logging.loggers.PluginFramework.debug('Table view received from forge:', newTableView);
                // If already live then pass through
                if (this.tableViewTracker.live) {
                    this.localTableView = newTableView;
                    return;
                }

                // If last update wait time has elapsed then set to live
                // This is done to ensure live data if the selection is modified outside of table, e.g. deleting rows
                if (Date.now() - this.tableViewTracker.lastUpdateSent >= this.tableViewTracker.updateWaitTime) {
                    this.$logging.loggers.PluginFramework.debug('Requested table view is stale and table has received new options.');
                    this.tableViewTracker.live = true;
                    this.localTableView = newTableView;
                }

                // Check that the incoming selection data has every id in the local selectedIds, could be in different order
                // short circuit out if lengths are not equal
                const lengthsEqual = this.selectedIds.length === newTableView.selectedIds.length;
                if (lengthsEqual) {
                    const intersectionLength = _.intersection(this.selectedIds, newTableView.selectedIds).length;
                    const selectedIdsMatch = intersectionLength === this.selectedIds.length && intersectionLength === newTableView.selectedIds.length;
                    if (selectedIdsMatch) {
                        this.$logging.loggers.PluginFramework.debug('Requested table view matches forge. Switching to live mode.');
                        this.tableViewTracker.live = true;
                        this.localTableView = newTableView;
                    }
                }
            },
        },
        tableOptions: {
            immediate: true,
            handler(newTableOptions) {
                this.$logging.loggers.PluginFramework.debug('Table options received from forge:', newTableOptions);

                // remove the transaction id - it shouldn't be part of
                // the table options
                Reflect.deleteProperty(newTableOptions, 'transactionId');

                // If already live then pass through
                if (this.tableOptionsTracker.live) {
                    this.localTableOptions = newTableOptions;
                    return;
                }

                // If last update wait time has elapsed then set to live
                // This is done to ensure live data if the options are modified outside of table
                if (Date.now() - this.tableOptionsTracker.lastUpdateSent >= this.tableOptionsTracker.updateWaitTime) {
                    this.$logging.loggers.PluginFramework.debug('Requested options are stale and table has received new options.');
                    clearTimeout(this.tableOptionsTracker.timeoutHandle);
                    this.tableOptionsTracker.live = true;
                    this.localTableOptions = newTableOptions;
                    return;
                }

                // Check to see if options have been updated in forge and sync to live data if they have.
                // Pair down the newTable options into an object with only the shared keys from the last update
                const sharedNewTableOptions = {};
                Object.keys(this.tableOptionsTracker.lastUpdate).forEach(key => {
                    // skip the transactionId ... it's not a real value we should look at
                    if (newTableOptions[key] !== undefined) {
                        sharedNewTableOptions[key] = newTableOptions[key];
                    }
                });
                // Now we can test that only the keys in the update are present in the newTableOptions via sharedNewTableOptions
                const syncLiveData = _.isEqual(sharedNewTableOptions, this.tableOptionsTracker.lastUpdate);
                // If the forge options match the last updated options then mark as live
                if (syncLiveData) {
                    this.$logging.loggers.PluginFramework.debug('Requested table options match forge. Switching to live mode.');
                    clearTimeout(this.tableOptionsTracker.timeoutHandle);
                    this.tableOptionsTracker.live = true;
                    this.localTableOptions = newTableOptions;
                }
            },
        },
    },
    created() {
        // Set initial sorting options and send to forge
        const initialTableOptions = { ...this.tableOptions };
        if (this.initialSortFieldName !== null || this.initialSortAscending !== null) {
            initialTableOptions.sortByFieldName = this.initialSortFieldName;
            this.updateTableOptions({
                sortByFieldName: this.initialSortFieldName,
            });
        }
        if (this.initialSortAscending !== null) {
            initialTableOptions.sortAscending = this.initialSortAscending;
            this.updateTableOptions({
                sortAscending: this.initialSortAscending,
            });
        }

        // Initialize the localTableView and localTableOptions
        this.localTableView = this.tableView;
        this.localTableOptions = initialTableOptions;

        this.$logging.loggers.PluginFramework.debug('Table initialized with localTableView:', this.localTableView);
        this.$logging.loggers.PluginFramework.debug('Table initialized with localTableOptions:', this.localTableView);

        // Set the initial load to the provided initialRowsPerPage
        this.paginationOptions = {
            ...this.paginationOptions,
            limit: this.initialRowsPerPage,
        };

        // Show the loading spinner on first load to get around the flash of no data from forge
        // TODO: Fix this hack as tableView records should be indicative of this
        setTimeout(() => {
            this.initialLoadComplete = true;
        }, 2000);
    },
    beforeDestroy() {
        // Clean up any lingering timeouts
        clearTimeout(this.tableOptionsTracker.timeoutHandle);
    },
    methods: {
        debug(...args) {
            this.$logging.loggers.PluginFramework.debug(...args);
        },
        updateTableOptions(newOptions) {
            if (newOptions.fieldsToInclude) {
                // Always include the marking key in requests to forge
                if (!newOptions.fieldsToInclude.includes(this.markingIdFieldName)) {
                    this.$logging.loggers.PluginFramework.debug('Adding the row key to fields to include:', this.markingIdFieldName);
                    const fieldsToInclude = [...newOptions.fieldsToInclude, this.markingIdFieldName];
                    newOptions.fieldsToInclude = fieldsToInclude;
                }
            }

            this.$logging.loggers.PluginFramework.debug('New table options set from table:', newOptions);
            // Disconnect live updates from forge
            this.tableOptionsTracker.live = false;

            // Clear any previous timeouts
            clearTimeout(this.tableOptionsTracker.timeoutHandle);

            // Timestamp the last options change
            this.tableOptionsTracker.lastUpdateSent = Date.now();

            // Place a transaction Id on each request for debugging
            const transactionId = Math.floor(Math.random() * 10000).toString();
            const options = { ...this.localTableOptions, ...newOptions, transactionId };

            // Update the local copy of options
            this.localTableOptions = options;

            // Set the options that should be confirmed before enabling live data
            this.tableOptionsTracker.lastUpdate = newOptions;

            // Notify the user if the forge has not responded with updated table options
            this.tableOptionsTracker.timeoutHandle = setTimeout(() => {
                if (!this.tableOptionsTracker.live) {
                    this.$logging.loggers.PluginFramework.error(`Forge table has not responded with updated table options in ${this.tableOptionsTracker.updateWaitTime}ms. Expected, Received:`, options, this.tableOptions);
                    this.$notify.info('Still waiting on data for this table.');
                }
            }, this.tableOptionsTracker.updateWaitTime);

            this.$logging.loggers.PluginFramework.debug('Table options emitted from table:', options);

            // Inform the plugin of change to options
            this.$emit('tableOptions', options);
        },
        setDefaultColumnOptions(options) {
            const columnName = options.name;

            // Auto format the label if not provided
            if (options.label === undefined) {
                options.label = this.$utils.formatting.startCase(columnName);
            }
            // Set the field if not provided
            if (options.field === undefined) {
                options.field = columnName;
            }

            // Set the alignment if not provided
            if (options.align === undefined) {
                options.align = 'left';
            }

            // Set sortable if not provided
            if (options.sortable === undefined) {
                options.sortable = true;
            }

            return options;
        },
        // If the settings component can't find any previous displayedColumns then set them to the initiallyDisplayed columns so we at least have something
        setInitialDisplayedColumns() {
            this.$logging.loggers.PluginFramework.debug('No user saved column settings. Using initiallyDisplayedColumnNames:', this.initiallyDisplayedColumnNames);
            this.displayedColumns = this.initiallyDisplayedColumnNames;
        },
        // Used to disable the selected rows on bottom of q-table due to lack of ability to hide it
        getEmptySelectedRowsLabel() {
            return '';
        },
        applySelectedColumns(selectedColumnNames) {
            this.displayedColumns = selectedColumnNames;
        },
        performSearch() {
            this.updateTableOptions({
                searchText: this.searchInput,
            });
        },
        selectAll() {
            this.updateSelection({
                operation: 'add',
                indexValues: this.localTableView.filteredRecordIds,
            });
        },
        unselectAll() {
            this.updateSelection({
                operation: 'clear',
            });
        },
        // Handles selection made from the q-table checkboxes
        handleSelection(event) {
            const indexValues = event.keys.filter(key => !!key);
            // If table is single select mode then we can only select one thing at a time
            if (this.selectionType === 'single') {
                this.updateSelection({
                    operation: event.added ? 'replace' : 'subtract',
                    indexValues,
                });
            }
            // Else if table is in multi select mode then we need to add/subtract selection
            else {
                this.updateSelection({
                    operation: event.added ? 'add' : 'subtract',
                    indexValues,
                });
            }
        },
        // Handles a request from q-table for new data
        handleRequest(event) {
            if (event.pagination) {
                this.paginationOptions = event.pagination;
            }
        },
        updateSelection(selectionOptions) {
            // Confirm that user is not trying to select more than possible and exit if they are
            const addingToSelection = selectionOptions.operation === 'add';
            if (addingToSelection && this.selectedIds.length + selectionOptions.indexValues.length > this.maxSelectableItems) {
                const mergedSelection = [...this.selectedIds, ...selectionOptions.indexValues];
                const uniqueMergedSelection = [...new Set(mergedSelection)];
                if (uniqueMergedSelection.length > this.maxSelectableItems) {
                    this.$notify.info('Maximum number of records selected');
                    return;
                }
            }

            // Disconnect live updates from forge
            this.tableViewTracker.live = false;

            // Timestamp the last selection change
            this.tableViewTracker.lastUpdateSent = Date.now();

            // Store the initial selection before modification
            const initialSelection = [...this.selectedIds];

            // Update the local selectionIds
            switch (selectionOptions.operation) {
                case 'add': {
                    // Set selection to de-duped merge of both selections
                    const mergedSelection = [...this.selectedIds, ...selectionOptions.indexValues];
                    this.selectedIds = [...new Set(mergedSelection)];
                    break;
                }
                case 'clear': {
                    this.selectedIds = [];
                    break;
                }
                case 'subtract': {
                    this.selectedIds = this.selectedIds.filter(id => !selectionOptions.indexValues.includes(id));
                    break;
                }
                case 'replace': {
                    this.selectedIds = selectionOptions.indexValues;
                    break;
                }
                default:
                    break;
            }

            // Send selection updates to the forge
            const forgeSetMarkingOptions = {
                op: selectionOptions.operation,
                indexName: this.markingIdFieldName,
                name: this.localTableView.markingResource,
            };
            if (selectionOptions.indexValues) {
                forgeSetMarkingOptions.indexValues = selectionOptions.indexValues;
            }
            const markingCollectionResource = `${this.markingWorkspace}#${this.localTableView.sourceCollection}`;

            this.$compute.forgeSetMarking(markingCollectionResource, forgeSetMarkingOptions)
            .catch(error => {
                this.$notify.error(`Unable to update forge with selection: ${error.message}`);
                // reset the selection to what it was before
                this.selectedIds = initialSelection;
            });

            // Inform plugin that selection has changed
            this.$emit('selection', this.selectedIds);
        },
        applySort(sortColumn) {
            let sortAscending = this.sortAscending;
            let sortByFieldName = this.sortByFieldName;
            if (sortByFieldName === sortColumn) {
                if (sortAscending) {
                    // It's currently sorted as ascending in that column; change to descending.
                    sortAscending = false;
                }
                else {
                    // Return to "default"--i.e., no sorting.
                    sortByFieldName = '';
                }
            }
            else {
                // It's a new sort column, so just set it to ascending, by default.
                sortByFieldName = sortColumn;
                sortAscending = true;
            }
            this.updateTableOptions({
                sortAscending,
                sortByFieldName,
            });
        },
    },
};
</script>

<style lang="stylus">
// Scope all the quasar changes using class
.forge-table
    // Height should be full minus the table header and footer
    height calc(100% - 46.5px - 49px)
    // Remove the padding from the top of the table
    .q-table__top
        padding 0 !important

    // Override the quasar row height and spacing
    .q-table th,
    .q-table td
        padding 0 1rem 0 0 !important
        height auto !important

    // Implement quasar's sticky header
    .q-table__top,
    .q-table__bottom,
    thead tr:first-child td
        background-color #fff

    thead tr:first-child td
        position sticky
        top 0
        opacity 1
        z-index 1

    thead tr:first-child th /* bg color is important for th; just specify one */
        background-color #fff

    thead tr:first-child th
        position sticky
        top 0
        opacity 1
        z-index 1
.table-height-full
    // Table contents should take full height, when not in grid mode, so the controls are fixed to the bottom.
    .q-table__middle
        height 100%
</style>
