<template>
    <div>
        <GeneralButton
            label="layout"
            tooltip="run layout"
            @click="updateLayout"
        />
        <div>
            <cytoscape
                ref="cy"
                :config="networkConfig"
                :after-created="afterCreated"
            >
                <cy-element
                    v-for="def in workspaces"
                    :key="`${def.data.id}`"
                    :definition="def"
                />
                <cy-element
                    v-for="def in flows"
                    :key="`${def.data.id}`"
                    :definition="def"
                />
                <cy-element
                    v-for="def in resources"
                    :key="`${def.data.id}`"
                    :definition="def"
                />
                <cy-element
                    v-for="def in edges"
                    :key="`${def.data.id}`"
                    :definition="def"
                />
            </cytoscape>
        </div>
    </div>
</template>

<script>
import GeneralButton from '../../../shared-components/inline-elements/GeneralButton.vue';

function _clean(name) {
    if (name) {
        return name.replace('$', '').replace('#', '!');
    }
    return '';
}

export default {
    name: 'ForgeCanvas',
    components: {
        GeneralButton,
    },
    props: {
        workspace: {
            type: String,
            required: false,
            default: '',
        },
        data: {
            type: Object,
            required: false,
            default() {
                return {};
            },
        },
    },
    data() {
        return {

        };
    },
    computed: {
        /*
        ...mapState({
            tick: (state) => state.mason.forge.tick,
            latestError: (state) => state.mason.forge.latestError,
        }),
         */
        workspaces() {
            const nodes = [];
            if (this.data != null) {
                if (this.data.workspaces !== undefined) {
                    const workspaces = this.data.workspaces.map(w => {
                        return { data: { id: _clean(w.fullName), name: w.Name } };
                    });
                    nodes.push(...workspaces);
                }
            }
            console.debug('Workspaces: ', nodes);
            return nodes;
        },
        resources() {
            const nodes = [];
            if (this.data != null && this.data.resources !== undefined) {
                const workspaces = this.data.resources.map(w => {
                    return {
                        data: {
                            id: _clean(w.fullName),
                            name: w.name,
                            parent: _clean(w.workspace),
                            label: w.name,
                        },
                        classes: ['resource'],
                    };
                });
                nodes.push(...workspaces);
            }
            return nodes;
        },
        flows() {
            const nodes = [];
            if (this.data != null && this.data.flows !== undefined) {
                const workspaces = this.data.flows.map(w => {
                    return {
                        data: {
                            id: _clean(w.fullName),
                            name: w.name,
                            parent: _clean(w.workspace),
                            label: w.name,
                        },
                        classes: ['flow'],
                    };
                });
                nodes.push(...workspaces);
            }
            return nodes;
        },
        edges() {
            const items = [];
            if (this.data != null && this.data.edges !== undefined) {
                const workspaces = this.data.edges.map(w => {
                    return {
                        data: {
                            id: `${_clean(w.source)}-${_clean(w.target)}`,
                            source: _clean(w.source),
                            target: _clean(w.target),
                            label: '',
                        },
                    };
                });
                items.push(...workspaces);
                items.push(...this.data.workspaces.filter(w => w.workspace !== null).map(w => {
                    return {
                        data: {
                            id: `${_clean(w.fullName)}-${_clean(w.workspace)}`,
                            source: _clean(w.fullName),
                            target: _clean(w.workspace),
                            label: '',
                        },
                    };
                }));
            }
            return items;
        },
        networkConfig() {
            const config = {
                style: [
                    {
                        selector: 'node',
                        css: {
                            content: 'data(name)',
                            'text-valign': 'center',
                            'text-halign': 'center',
                        },
                    },
                    {
                        selector: 'node.flow',
                        css: {
                            // 'background-color': 'red',
                            shape: 'rectangle',
                        },
                    },
                    {
                        selector: ':parent',
                        css: {
                            'text-valign': 'top',
                            'text-halign': 'center',
                        },
                    },
                    {
                        selector: 'edge',
                        css: {
                            'curve-style': 'bezier',
                            'target-arrow-shape': 'triangle',
                        },
                    },
                ],
                layout: {
                    name: 'breadthfirst',
                },
            };
            return config;
        },
    },
    watch: {
        data() {
            this.updateLayout();
        },
    },
    methods: {
        afterCreated(cy) {
            // ...
            this.cy = cy;
            this.updateLayout();
        },
        updateLayout() {
            if (this.cy !== undefined) {
                this.cy.layout({ name: 'cose' }).run();
                // layout.run();
            }
        },
    },
};
</script>

<style scoped>
    #cytoscape {
        height: 500px;
        width: 100%;
    }
</style>
