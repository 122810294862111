import UsersService from './users-service.js';
import UsersModule from './users-module.js';
import UserProfileImage from './UserProfileImage.vue';
import UserChip from './UserChip.vue';
import UserFullName from './UserFullName.vue';
import UserItem from './UserItem.vue';

const Users = {
    install(Vue, {
        store = null,
        moduleName = 'usersService',
    } = {}) {
        if (!store) {
            throw new Error('Users plugin must be passed a vuex store.');
        }

        if (!Vue.$auth) {
            throw new Error('Users plugin requires the Auth plugin.');
        }

        if (!Vue.$api) {
            throw new Error('Users plugin requires the API plugin.');
        }

        store.registerModule(moduleName, UsersModule);

        const service = new UsersService({ store, moduleName });

        // Register the users service globally
        Vue.$users = service;

        // register the users service on the vue instance
        Vue.prototype.$users = service;

        // Register the UserProfileImage component
        Vue.component('UserProfileImage', UserProfileImage);
        Vue.component('UserChip', UserChip);
        Vue.component('UserFullName', UserFullName);
        Vue.component('UserItem', UserItem);
    },
};

export default Users;
