<template>
    <span
        v-if="element.props.type === 'text' || element.type === 'text'"
        v-bind="element.props"
        class="text-h6"
        v-on="element.events"
    >
        {{ element.props.value }}
    </span>
    <OmniButton
        v-else-if="element.props.type === 'button' || element.props.type === 'drop-down' || element.props.type === 'menu' || element.type === 'button' || element.type === 'drop-down' || element.type === 'menu'"
        :button="{ type: element.props.type ? element.props.type : element.type, props: element.props, events: element.events }"
    />
    <component
        :is="element.componentType"
        v-else-if="element.type || element.componentType"
        v-bind="element.props"
        class="inline-block"
        v-on="element.events"
    />
    <div v-else/>
</template>

<script>
import OmniButton from './OmniButton.vue';

export default {
    name: 'InlineElement',
    components: {
        OmniButton,
    },
    props: {
        // type supported as a root field on element for backwards compatibility--needs to be removed
        element: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style scoped>

</style>
