<template>
    <div>
        <div class="row items-center">
            <GeneralButton
                v-cypress="testId"
                :tooltip="tooltip"
                :label="label"
                :disabled="disable"
                color="primary"
                :flat="false"
                class="col-auto"
                @click="showDialog = true"
            />
            <div class="q-px-sm row col">
                <AutoFormValidationErrors
                    v-if="errors.length"
                    :errors="errors"
                />
                <span
                    v-else
                    class="display-selected ellipsis"
                >
                    {{ selectedDisplayList }}
                    <q-tooltip>
                        {{ selectedDisplayList }}
                    </q-tooltip>
                </span>
            </div>
        </div>
        <AutoFormObjectPickerDialog
            v-model="showDialog"
            :target="target"
            @selectionChanged="trackSelection"
            @ok="commitSelection"
        >
            <template
                v-if="objectDisplayComponent"
                v-slot:selectedObject="objectProps"
            >
                <component
                    :is="objectDisplayComponent"
                    v-bind="objectProps.object"
                />
            </template>
            <template
                v-if="objectDisplayComponent"
                v-slot:matchingObject="objectProps"
            >
                <component
                    :is="objectDisplayComponent"
                    v-bind="objectProps.object"
                />
            </template>
        </AutoFormObjectPickerDialog>
    </div>
</template>

<script>

import AutoFormValidationErrors from './AutoFormValidationErrors.vue';
import AutoFormObjectPickerDialog from './AutoFormObjectPickerDialog.vue';
import GeneralButton from '../inline-elements/GeneralButton.vue';

export default {
    name: 'AutoFormObjectPicker',
    components: {
        AutoFormObjectPickerDialog,
        AutoFormValidationErrors,
        GeneralButton,
    },
    props: {
        value: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },
        testId: {
            type: String,
            required: false,
            default: null,
        },
        label: {
            type: String,
            required: false,
            default: 'Select',
        },
        target: {
            type: Object,
            required: true,
        },
        errors: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },
        disable: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            showDialog: false,
            pendingSelection: [],
        };
    },
    computed: {
        selectedItems: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
        selectedDisplayList() {
            if (this.selectedItems && this.selectedItems.length) {
                if (this.returnDisplayNames) {
                    return this.selectedItems.map(ob => ob.displayName).toString();
                }
                return this.selectedItems.toString();
            }
            return '';
        },
        objectDisplayComponent() {
            return this.target ? this.target.objectDisplayComponent : null;
        },
        tooltip() {
            return this.target ? this.target.tooltip : '';
        },
        returnField() {
            return this.target ? this.target.returnField : null;
        },
    },
    methods: {
        commitSelection() {
            this.selectedItems = [...this.pendingSelection];
        },
        trackSelection(selection) {
            this.pendingSelection = selection.map(ob => {
                if (this.returnField) {
                    const resultObject = {
                        id: ob.id,
                    };
                    const returnKey = this.returnField;
                    resultObject[returnKey] = ob[returnKey];
                    return resultObject;
                }
                return ob.id;
            });
        },
    },
};
</script>

<style scoped>
.display-selected {
    font-size: small;
    font-style: italic;
}
</style>
