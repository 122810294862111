<template>
    <q-btn-dropdown
        flat
        :label="`${prefix} ${options.find(option => option.value === selectedOption).label}`"
        menu-anchor="bottom left"
        menu-self="top left"
        no-caps
        :disable="disable"
    >
        <q-list
            :dark="dark"
            :bordered="borderedOptions"
            :class="{'bg-grey-10': dark, 'text-white': dark}"
        >
            <q-item
                v-for="option in options"
                :key="option.label"
                v-ripple
                tag="label"
                dense
            >
                <q-item-section>
                    <q-item-label
                        v-close-popup
                        @click="selectedOption = option.value"
                    >
                        {{ option.label }}
                    </q-item-label>
                </q-item-section>
            </q-item>
        </q-list>

        <slot/>
    </q-btn-dropdown>
</template>

<script>
export default {
    name: 'PDropdownSelect',
    props: {
        value: {
            required: true,
            validator: (v) => v != null,
        },
        // Array of options in form of { label, value }
        options: {
            type: Array,
            required: true,
        },
        dark: {
            type: Boolean,
            required: false,
            default: false,
        },
        borderedOptions: {
            type: Boolean,
            required: false,
            default: false,
        },
        disable: {
            type: Boolean,
            required: false,
            default: false,
        },
        prefix: {
            type: String,
            required: false,
            default: '',
        },
    },
    computed: {
        selectedOption: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
};
</script>
