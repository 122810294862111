<template>
    <PDialog
        v-model="display"
        :title="title"
        :ok-btn-text="okBtnText"
        :ok-btn-tooltip="okBtnTooltip"
        @ok="$emit('save', settingsDocument)"
    >
        <q-input
            v-model="settingName"
            label="Name"
            filled
            dense
        />
        <div class="q-pa-md">
            Scope
        </div>
        <q-list
            link
            dense
        >
            <q-item
                v-ripple
                tag="label"
            >
                <q-item-section
                    avatar
                    top
                >
                    <q-radio
                        v-model="settingScope"
                        val="user"
                    />
                </q-item-section>
                <q-item-section>
                    <q-item-label>User</q-item-label>
                    <q-item-label caption>
                        Visible only to you.
                    </q-item-label>
                </q-item-section>
            </q-item>
            <q-item
                v-if="insidePetron"
                v-ripple
                tag="label"
            >
                <q-item-section
                    avatar
                    top
                >
                    <q-radio
                        v-model="settingScope"
                        val="petron"
                    />
                </q-item-section>
                <q-item-section>
                    <q-item-label>Petron</q-item-label>
                    <q-item-label caption>
                        Visible to any users of this Petron.
                    </q-item-label>
                </q-item-section>
            </q-item>
            <q-item
                :v-ripple="$auth.userIsAdmin"
                tag="label"
            >
                <q-item-section
                    avatar
                    top
                >
                    <q-radio
                        v-model="settingScope"
                        val="org"
                        :disable="!$auth.userIsAdmin"
                    />
                </q-item-section>
                <q-item-section>
                    <q-item-label>Organization</q-item-label>
                    <q-item-label caption>
                        Visible to all Petro.ai users, across all Petrons.
                    </q-item-label>
                </q-item-section>
                <q-tooltip
                    v-if="!$auth.userIsAdmin"
                    :delay="250"
                >
                    Must be admin
                </q-tooltip>
            </q-item>
        </q-list>
    </PDialog>
</template>

<script>
export default {
    name: 'SaveNewSettingsDialog',
    props: {
        // v-model prop to show or hide the modal
        value: {
            type: Boolean,
            required: true,
        },
        // Type of setting used in labels and tooltips
        displayName: {
            type: String,
            required: true,
        },
        // Settings object to be saved
        setting: {
            type: [Array, Object],
            required: true,
        },
        // ReferenceId to save with settings document
        referenceId: {
            type: String,
            required: true,
        },
        // ParentId to save with settings document
        parentId: {
            type: String,
            required: true,
        },
        // Settings version to save with settings document
        version: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            settingName: null,
            settingScope: 'user',
        };
    },
    computed: {
        display: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            },
        },
        insidePetron() {
            return !!this.$route.params.petronId;
        },
        parentIdByScope() {
            switch (this.settingScope) {
                case 'user':
                    return this.$auth.user.userId;
                case 'petron':
                    return this.parentId;
                case 'org':
                    return this.$auth.user.orgId;
                default:
                    return this.parentId;
            }
        },
        title() {
            return `Save New ${this.displayName}`;
        },
        okBtnText() {
            return `Save ${this.displayName}`;
        },
        okBtnTooltip() {
            return `Save ${this.displayName}`;
        },
        settingsDocument() {
            const data = JSON.stringify(this.setting, null);
            return {
                name: this.settingName,
                parentId: this.parentIdByScope,
                // Set the reference name to the name of the component calling it
                referenceId: this.referenceId,
                scope: this.settingScope,
                version: this.version,
                data,
            };
        },
    },
};
</script>

<style scoped>

</style>
