const lifecycleHookNames = ['created', 'beforeMount', 'mounted', 'beforeUpdate', 'updated', 'beforeDestroy', 'destroyed'];

function createLifecycleHookProps() {
    // Create props for the lifecycle hooks if there is no events prop to handle them
    const mixinProps = {};
    lifecycleHookNames.forEach(lifecycleHookName => {
        mixinProps[lifecycleHookName] = {
            type: Function,
            required: false,
            default: undefined,
        };
    });
    return { props: mixinProps };
}

function createLifecycleHooks() {
    const mixinLifecycleHooks = {};
    lifecycleHookNames.forEach(lifecycleHookName => {
        mixinLifecycleHooks[lifecycleHookName] = function buildMixin() {
            // Check for the existence of a lifecycle hook in the events prop
            if (this.$props.events && this.$props.events[lifecycleHookName] && typeof this.$props.events[lifecycleHookName] === 'function') {
                this.$props.events[lifecycleHookName]();
            }
            // Check for the existence of a lifecycle hook in the props
            if (this.$props && this.$props[lifecycleHookName] && typeof this.$props[lifecycleHookName] === 'function') {
                this.$props[lifecycleHookName]();
            }
        };
    });
    return mixinLifecycleHooks;
}

export default function ({ addProps }) {
    // If the component requesting the mixin needs props defined then add them
    if (addProps) {
        return {
            ...createLifecycleHookProps(),
            ...createLifecycleHooks(),
        };
    }
    // Otherwise the component will use the events prop to handle lifecycle hooks
    return {
        ...createLifecycleHooks(),
    };
}
