<template>
    <q-item class="items-center">
        <UserProfileImage
            v-if="user"
            :user="user"
            show-image-border
        />
        <div
            v-if="user"
            class="column q-px-md"
        >
            <UserFullName
                :user="user"
                hide-link
                class="text-secondary"
            />
            <small class="text-grey-8">
                {{ user.position }}
            </small>
            <small
                v-if="label"
                class="text-grey-8 text-italic"
            >
                {{ label }}
            </small>
        </div>
        <div
            v-else
            class="q-pa-sm"
        >
            {{ noUserMessage }}
        </div>
        <q-space/>
        <GeneralButton
            v-if="canRemove"
            label="X"
            tooltip="Remove user from the list of collaborators"
            :flat="false"
            :outline="false"
            round
            dense
            size="xs"
            color="grey-4"
            text-color="white"
            @click="removeClicked"
        />
    </q-item>
</template>

<script>
import UserFullName from './UserFullName.vue';
import GeneralButton from '../../shared-components/inline-elements/GeneralButton.vue';

export default {
    name: 'UserChip',
    components: {
        UserFullName,
        GeneralButton,
    },
    props: {
        userId: {
            validator(value) {
                return typeof value === 'string' || value !== 'undefined';
            },
            required: true,
        },
        label: {
            type: String,
            required: false,
            default: null,
        },
        noUserMessage: {
            type: String,
            required: false,
            default: 'Unknown user',
        },
        canRemove: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        user() {
            return this.$users.getUser(this.userId);
        },
    },
    methods: {
        removeClicked() {
            const user = this.user || { userId: this.userId };
            this.$emit('remove', user);
        },
    },
};
</script>

<style scoped>

</style>
