<template>
    <q-page
        padding
    >
        <div class="row q-col-gutter-lg">
            <div class="col-12 col-grow">
                <PCard title="Forge">
                    <ForgePerformanceMonitor/>
                </PCard>
            </div>
        </div>
    </q-page>
</template>

<script>
export default {
    name: 'TheKernelsPage',
};
</script>

<style scoped>

</style>
