import ApplicationLayout from '../../../layouts/ApplicationLayout.vue';
import DefaultPageLayout from '../../../layouts/DefaultPageLayout.vue';
import FullPageTemplate from '../../../plugin-framework/templates/FullPageTemplate.vue';
import DataStorageIndicator from '../components/DataStorageIndicator.vue';
import { ROUTES } from '../constants.js';

export function DataStorageRoute({ app, plugin }) {
    return {
        type: 'fragment',
        target: 'root-route',
        options() {
            return {
                path: '/data-storage',
                meta: {
                    auth: true,
                    pluginId: plugin.$definition.name,
                },
                component: ApplicationLayout,
                children: [
                    {
                        path: '',
                        components: {
                            appMain: DefaultPageLayout,
                        },
                        children: [
                            {
                                path: '',
                                name: ROUTES.DataStorage,
                                components: {
                                    pageContent: FullPageTemplate,
                                },
                                props: {
                                    pageContent: (route) => {
                                        return {
                                            props: {
                                                forgeResources: [],
                                                sections: [
                                                    {
                                                        type: 'title',
                                                        props: {
                                                            value: 'Data Storage',
                                                        },
                                                    },
                                                    {
                                                        type: 'text',
                                                        props: {
                                                            value: this.storageMessage,
                                                            textClass: this.storageTextClasses,
                                                        },
                                                    },
                                                    {
                                                        type: 'custom',
                                                        componentType: DataStorageIndicator,
                                                        props: {
                                                            usedStorage: app.$environment.usedStorage,
                                                            maxStorage: app.$environment.maxStorage,
                                                            approachingLimit: app.$environment.approachingLimit,
                                                            overLimit: app.$environment.overLimit,
                                                            fractionUsed: app.$environment.fractionUsed,
                                                            unlimited: app.$environment.unlimitedStorage,
                                                        },
                                                        events: {},
                                                    },
                                                    {
                                                        type: 'text',
                                                        props: {
                                                            value: 'Usage is refreshed upon login.',
                                                            textClass: 'text-grey-6',
                                                        },
                                                    },
                                                    {
                                                        type: 'inline-elements',
                                                        props: {
                                                            inlineElements: [
                                                                {
                                                                    props: {
                                                                        type: 'button',
                                                                        label: 'Request to Load Data',
                                                                        outline: true,
                                                                        flat: false,
                                                                        tooltip: 'Email Petro.ai support to load more data',
                                                                    },
                                                                    events: {
                                                                        click: () => {
                                                                            window.location.href = 'mailto:support@petro.ai?subject=Request to Load Data';
                                                                        },
                                                                    },
                                                                },
                                                                {
                                                                    props: {
                                                                        type: 'button',
                                                                        label: 'Upgrade Storage Plan',
                                                                        color: 'accent',
                                                                        textColor: 'primary',
                                                                        outline: false,
                                                                        flat: false,
                                                                        tooltip: 'Email Petro.ai support to upgrade storage plan',
                                                                    },
                                                                    events: {
                                                                        click: () => {
                                                                            window.location.href = 'mailto:support@petro.ai?subject=Upgrade Storage Plan';
                                                                        },
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                            events: {},
                                        };
                                    },
                                },
                            },
                        ],
                    },
                ],
            };
        },
    };
}
