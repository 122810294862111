<template>
    <div
        v-cypress="'NotificationDisplayItem_Main_Div'"
        class="row"
    >
        <q-avatar
            :class="avatarClass"
        >
            <UserProfileImage
                v-if="!isReportedNotification && notificationAuthor"
                :user="notificationAuthor"
                dimension="40px"
            />
            <q-icon
                v-else
                :name="isReportedNotification ? 'notifications_active' : 'settings'"
                color="white"
            />
            <q-badge
                v-if="isUnseenNotification"
                floating
                color="accent"
                label="New"
                text-color="primary"
                style="border-radius: 8px"
            />
        </q-avatar>
        <div
            class="col q-px-md"
        >
            <div
                class="ellipsis-3-lines"
            >
                <span v-html="notificationMessage()"/>
            </div>
            <small class="text-grey-5">{{ timeDisplay(notification.createdAt) }}</small>
        </div>
    </div>
</template>

<script>
import { formatting } from '../plugin-framework/services/utils/formatting-utils.js';

export default {
    name: 'NotificationDisplayItem',
    props: {
        notification: {
            type: Object,
            required: true,
        },
    },
    computed: {
        isJobCompletedNotification() {
            return (this.notification && this.notification.notificationObject.notificationType === 'JobCompleted');
        },
        isJobErrorNotification() {
            return (this.notification && this.notification.notificationObject.notificationType === 'JobError');
        },
        isReportedNotification() {
            return (this.notification && this.notification.notificationObject.notificationType === 'Reported');
        },
        isUnseenNotification() {
            return (this.notification && this.notification.status === 'Unseen');
        },
        notificationAuthor() {
            if (this.isJobCompletedNotification || this.isJobErrorNotification) {
                return null;
            }
            return this.notification.notificationObject.createdBy;
        },
        avatarClass() {
            if (this.isJobCompletedNotification || this.isReportedNotification) {
                return 'avatar-size avatar-success';
            }
            if (this.isJobErrorNotification) {
                return 'avatar-size avatar-error';
            }
            if (this.notificationAuthor) {
                return 'avatar-size';
            }
            return 'avatar-size avatar-general';
        },
    },
    methods: {
        timeDisplay(dateString) {
            return formatting.timeAgo(dateString);
        },
        notificationMessage() {
            const notification = this.notification;
            if (!notification) {
                return '';
            }
            let extraContent = '';
            if (notification.notificationObject.document && notification.notificationObject.document.content) {
                extraContent = `"${notification.notificationObject.document.content}"`;
            }
            return `${notification.notificationObject.message} ${extraContent}`;
        },
    },
};
</script>

<style lang="stylus">
@import '../css/quasar.styl';
.avatar-size {
    height: 40px !important;
    width: 40px !important;
}
.avatar-success {
    background-color: $success;
}
.avatar-error {
    background-color: $error;
}
.avatar-general {
    background-color: $accent;
}
</style>
