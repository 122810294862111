export const InfoDialog = function ({ app, plugin }) {
    return {
        type: 'fragment',
        target: 'dialog',
        priority: 1,
        options() {
            return {
                model: app.$actions.showInfoDialog,
                props: {
                    title: app.$actions.infoOptions.title,
                    size: app.$actions.infoOptions.size,
                    sections: app.$actions.infoOptions.sections,
                    actionButtons: [],
                },
                events: {
                    input: (value) => {
                        if (value === false && app.$actions.onConfirm) {
                            app.$actions.onConfirm(true);
                        }
                        app.$actions.showInfoDialog = value;
                    },
                },
            };
        },
    };
};
