import FilterService from './filter-service.js';

export const Filter = {
    install(Vue, {
        store = null,
        router = null,
    } = {}) {
        const service = new FilterService({ store, router });

        // Register the filter service globally.
        Vue.$filter = service;

        // Register the filter service on the vue instance.
        Vue.prototype.$filter = service;
    },
};
