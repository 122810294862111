<template>
    <div
        :style="{ width: chartWidth, height: chartHeight }"
    >
        <v-chart
            ref="theChart"
            :options="chartOptions"
            :style="{ width: chartWidth, height: chartHeight }"
            autoresize
        />
    </div>
</template>

<script>
import ECharts from 'vue-echarts/components/ECharts.vue';
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/scatter';
import 'echarts/lib/component/dataZoom';
import 'echarts/lib/component/markArea';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/brush';
import 'echarts/lib/component/toolbox';
import 'echarts/lib/component/legend';
import moment from 'moment';

export default {
    name: 'ForgeLineChart',
    components: {
        'v-chart': ECharts,
    },
    props: {
        data: {
            type: Array,
            required: true,
        },
        chartWidth: {
            type: String,
            required: false,
            default: '99%',
        },
        chartHeight: {
            type: String,
            required: false,
            default: '99%',
        },
        xAxis: {
            type: String,
            required: false,
            default: 'x',
        },
        yLabel: {
            type: String,
            required: false,
            default: '',
        },
        yAxis: {
            type: String,
            required: false,
            default: 'y',
        },
        yMin: {
            type: [Number, String],
            required: false,
            default: null,
        },
        yMax: {
            type: [Number, String],
            required: false,
            default: null,
        },
        timeFormat: {
            type: String,
            required: false,
            default: 'H:mm:ss',
        },
        xType: {
            type: String,
            required: false,
            default: 'time',
        },
        filled: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data() {
        return {
            dataSeries: [],
        };
    },
    computed: {
        chartOptions() {
            const baseOpt = {
                xAxis: {
                    type: 'time',
                    // position: 'top',
                    axisLabel: {
                        formatter: (value) => moment(value).format(this.timeFormat),
                    },
                    // silent: false,
                    // triggerEvent: true,
                },
                yAxis: {
                    type: 'value',
                    min: this.yMin,
                    max: this.yMax,
                    name: this.yLabel,
                    // silent: false,
                    // triggerEvent: true,
                },
                series: [
                    {
                        type: 'line',
                        encode: {
                            x: this.xAxis,
                            y: this.yAxis,
                            // tooltip: [0, 1, 2, 3, 4],
                        },
                        areaStyle: {
                            color: '#c21e77',
                        },
                        symbol: 'circle',
                        lineStyle: {
                            color: '#911d4d',
                            width: 1.0,
                        },
                        sampling: 'average',
                        showSymbol: false,
                        hoverAnimation: false,
                    },
                ],
                tooltip: {
                    trigger: 'axis',
                },
                dataset: {
                    source: this.data,
                },
            };
            if (this.xType !== 'time') {
                baseOpt.xAxis = {
                    type: 'value',
                };
            }
            if (!this.filled) {
                baseOpt.series[0].areaStyle = null;
            }
            return baseOpt;
        },
    },
};
</script>

<style scoped></style>
