<template>
    <q-dialog
        v-if="display && video && Object.keys(video).length"
        v-model="display"
    >
        <q-card
            class="col-3"
        >
            <q-card-section
                class="column"
            >
                <div class="text-right col-auto">
                    <GeneralButton
                        v-close-popup
                        color="grey-8"
                        flat
                        icon="clear"
                        tooltip="Close dialog"
                        @click="$emit('cancel')"
                    />
                </div>
                <div
                    v-cypress="'VideoCard_Title_Div'"
                    class="text-secondary text-h6 text-bold col-auto"
                >
                    {{ video.title }}
                    <q-tooltip :delay="750">
                        {{ video.title }}
                    </q-tooltip>
                </div>
                <div
                    class="text-grey-8 q-pb-lg col-auto video-description"
                >
                    {{ video.description }}
                    <q-tooltip :delay="750">
                        {{ video.description }}
                    </q-tooltip>
                </div>
                <vueVimeoPlayer
                    v-if="isVimeo"
                    ref="videoPlayer"
                    :video-id="videoId"
                    :player-width="$q.screen.gt.sm ? 960 : 480"
                    :player-height="$q.screen.gt.sm ? 540 : 270"
                    @ready="playVideo"
                    @timeupdate="updateViewProgress"
                />
                <q-video
                    v-else
                    :src="video.videoUrl"
                    class="video-size"
                />
            </q-card-section>
        </q-card>
    </q-dialog>
</template>

<script>
import { vueVimeoPlayer } from 'vue-vimeo-player';
import GeneralButton from '../../../plugin-framework/shared-components/inline-elements/GeneralButton.vue';

export default {
    name: 'VideoPlayerDialog',
    components: {
        vueVimeoPlayer,
        GeneralButton,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        video: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            secondsWatched: 0,
            percentWatched: 0,
            videoLength: 0,
        };
    },
    computed: {
        display: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        videoId() {
            if (this.isVimeo) {
                const index = this.video.videoUrl.lastIndexOf('/');
                if (index > -1) {
                    return this.video.videoUrl.substring(index + 1);
                }
                return this.video.videoUrl;
            }
            return '';
        },
        isVimeo() {
            return this.video && this.video.videoUrl && (this.video.videoUrl.indexOf('vimeo.com') > -1);
        },
    },
    watch: {
        value(newValue, oldValue) {
            // Dialog is displayed--reset telemetry
            if (newValue === true) {
                this.resetMetrics();
            }
            // Dialog is closed--send telemetry
            if (newValue === false) {
                this.$telemetry.trackUserEvent({
                    name: 'Video Watched',
                    properties: {
                        videoSecondsWatched: this.secondsWatched,
                        videoPercentWatched: this.percentWatched,
                        videoLength: this.videoLength,
                        videoApp: this.video.app,
                        videoTitle: this.video.title,
                        videoUrl: this.video.videoUrl,
                        videoIsNew: this.video.isNew,
                        videoCategory: this.video.category,
                    },
                });
            }
        },
    },
    methods: {
        playVideo() {
            if (this.$refs.videoPlayer) {
                this.$refs.videoPlayer.play();
            }
        },
        resetMetrics() {
            this.secondsWatched = 0;
            this.percentWatched = 0;
        },
        updateViewProgress({ seconds, percent, duration }) {
            this.secondsWatched = seconds;
            this.percentWatched = percent;
            this.videoLength = duration;
        },
    },
};
</script>

<style scoped>
.video-size {
    width: 60vw;
    height: 60vh;
}
.video-description {
    max-width: 960px;
}
</style>
