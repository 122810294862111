<template>
    <div class="q-py-sm">
        <div
            v-if="inlineElements.length > 0"
            class="row items-center no-wrap q-gutter-md"
        >
            <InlineElement
                v-for="(element, idx) in inlineElements"
                :key="`inline-${idx}`"
                :element="element"
            />
        </div>
    </div>
</template>

<script>
import InlineElement from '../inline-elements/InlineElement.vue';

export default {
    name: 'InlineElementsSection',
    components: {
        InlineElement,
    },
    props: {
        inlineElements: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
};
</script>

<style scoped>
</style>
