import Vue from 'vue';
import { getAppConfig } from './boot/app-config.js';
import App from './App.vue';

const appConfig = getAppConfig(Vue);

Vue.config.productionTip = false;

new Vue({
    ...appConfig,
    render: h => h(App),
}).$mount('#app');
