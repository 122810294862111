<template>
    <q-select
        ref="myInput"
        v-model="compValue"
        :filled="filled"
        :options="visibleOptions"
        :label="label"
        :option-value="optionValue"
        :option-label="optionLabel"
        options-dense
        :dense="dense"
        :hint="hint"
        :outlined="outlined"
        use-input
        :input-debounce="debounce"
        :disable="disable"
        :multiple="multiselect"
        :use-chips="multiselect"
        :clearable="clearable"
        :emit-value="emitValue"
        :map-options="mapOptions"
        bottom-slots
        :error="error"
        :error-message="errorMessage"
        @filter="filterFn"
        @input="clearInput"
    >
        <!-- Inject the slots so they're available to everyone -->
        <template
            v-for="(_, slot) of $scopedSlots"
            v-slot:[slot]="scope"
        >
            <slot
                :name="slot"
                v-bind="scope"
            />
        </template>
    </q-select>
</template>
<script>
export default {
    name: 'PSelect',
    props: {
        value: {
            required: true,
            validator: v => Array.isArray(v) || typeof v === 'object' || v === null || typeof v === 'string',
        },
        options: {
            type: Array,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        optionValue: {
            type: String,
            required: false,
            default: 'value',
        },
        optionLabel: {
            type: String,
            required: false,
            default: 'label',
        },
        debounce: {
            type: Number,
            required: false,
            default: 0,
        },
        clearable: {
            type: Boolean,
            required: false,
            default: true,
        },
        dense: {
            type: Boolean,
            required: false,
            default: true,
        },
        multiselect: {
            type: Boolean,
            required: false,
            default: false,
        },
        hint: {
            type: String,
            required: false,
            default: '',
        },
        bottomSlot: {
            type: Boolean,
            required: false,
            default: false,
        },
        error: {
            type: Boolean,
            required: false,
            default: false,
        },
        outlined: {
            type: Boolean,
            required: false,
            default: false,
        },
        filled: {
            type: Boolean,
            required: false,
            default: false,
        },
        disable: {
            type: Boolean,
            required: false,
            default: false,
        },
        emitValue: {
            type: Boolean,
            required: false,
            default: false,
        },
        mapOptions: {
            type: Boolean,
            required: false,
            default: false,
        },
        errorMessage: {
            type: String,
            required: false,
            default: '',
        },
    },
    data() {
        return {
            visibleOptions: [],
        };
    },
    computed: {
        compValue: {
            set(value) {
                this.setValue(value);
            },
            get() {
                return this.value;
            },
        },
    },
    watch: {
        options() {
            this.visibleOptions = this.options;
        },

    },
    methods: {
        filterFn(val, update, abort) {
            update(() => {
                const needle = val.toLowerCase();
                this.visibleOptions = this.options.filter(v => v[this.optionLabel].toLowerCase().indexOf(needle) > -1) || this.visibleOptions;
            });
        },
        setValue(value) {
            if (value === null && this.multiselect) {
                this.$emit('input', []);
            }
            else {
                this.$emit('input', value);
            }
        },
        clearInput() {
            this.$refs.myInput.updateInputValue('');
        },
    },

};
</script>

<style scoped>

</style>
