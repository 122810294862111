<template>
    <div>
        <TitleSection
            v-if="section.type === 'title'"
            v-bind="section.props"
            v-on="section.events"
        />
        <TextSection
            v-if="section.type === 'text'"
            v-bind="section.props"
            v-on="section.events"
        />
        <AutoForm
            v-if="section.type === 'auto-form'"
            v-bind="section.props"
            v-on="section.events"
        />
        <DropdownNavSection
            v-if="section.type === 'dropdown-nav'"
            v-bind="section.props"
            v-on="section.events"
        />
        <CommentView
            v-if="section.type ==='comment-view'"
            v-bind="section.props"
            v-on="section.events"
        />
        <DataChart
            v-if="section.type === 'data-chart'"
            v-bind="section.props"
            :style="section.style"
            v-on="section.events"
        />
        <OmniButton
            v-if="section.type === 'button'"
            :button="section"
        />
        <InputListSection
            v-if="section.type === 'input-list'"
            v-bind="section.props"
            v-on="section.events"
        />
        <InlineElementsSection
            v-if="section.type === 'inline-elements'"
            v-bind="section.props"
            v-on="section.events"
        />
        <ItemListSection
            v-if="section.type === 'item-list'"
            v-bind="section.props"
            v-on="section.events"
        />
        <component
            :is="section.componentType"
            v-if="(section.type === 'custom' || section.componentType) && !section.hide"
            v-bind="section.props"
            v-on="section.events"
        />
    </div>
</template>

<script>
import TitleSection from './TitleSection.vue';
import TextSection from './TextSection.vue';
import InputListSection from './InputListSection.vue';
import AutoForm from '../auto-form/AutoForm.vue';
import DropdownNavSection from './DropdownNavSection.vue';
import DataChart from '../visualizations/DataChart.vue';
import CommentView from '../comments/CommentView.vue';
import OmniButton from '../inline-elements/OmniButton.vue';
import InlineElementsSection from './InlineElementsSection.vue';
import ItemListSection from './ItemListSection.vue';

export default {
    name: 'Section',
    components: {
        OmniButton,
        CommentView,
        DropdownNavSection,
        TextSection,
        TitleSection,
        AutoForm,
        DataChart,
        InputListSection,
        InlineElementsSection,
        ItemListSection,
    },
    props: {
        section: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style scoped>

</style>
