import Vue from 'vue';

const Buffer = require('buffer/').Buffer;

export const conversion = {
    // Takes serial64 data (base64 encoded binary string with specified width, e.g. {payload: ..., width: <num>, format:'base64'})
    // and converts to an array of numeric records
    decodeSerial64Data(data) {
        if (!data.payload) {
            Vue.$logging.loggers.PluginFramework.warn(`decodeSerial64Data was passed serial64 data without payload: ${data}. An empty array will be returned.`);
            return [];
        }

        if (!data.payload) {
            Vue.$logging.loggers.PluginFramework.warn(`decodeSerial64Data was passed serial64 data without width: ${data}. An empty array will be returned.`);
            return [];
        }

        const rawBuffer = new ArrayBuffer(8);
        const byteBuffer = new Uint8Array(rawBuffer);
        const doubleBuffer = new Float64Array(rawBuffer);
        const extractedValues = [];

        // convert binary string back to bytes
        const binaryString = Buffer.from(data.payload, 'base64').toString('binary');
        for (let binaryIndex = 0; binaryIndex < binaryString.length; binaryIndex += 1) {
            // reverse the order to make big endian
            const byteIndex = binaryIndex % 8;
            byteBuffer[7 - byteIndex] = binaryString.charCodeAt(binaryIndex);

            // if we've just filled up the byte buffer, then grab out the value
            if (binaryIndex % 8 === 7) {
                extractedValues.push(doubleBuffer[0]);
            }
        }

        // slice up the values into chunks of width length
        const records = [];
        while (extractedValues.length > 0) {
            records.push(extractedValues.splice(0, data.width));
        }
        return records;
    },
};
