<template>
    <q-page>
        <div class="sign-in-gradient-radial"/>
        <div class="sign-in-background">
            <div class="sign-in-gradient-left"/>
            <div class="sign-in-gradient-right"/>
            <img
                class="sign-in-logo"
                :src="require('@/assets/images/petro-logo.svg')"
            >
            <div class="column window-height text-white">
                <div
                    class="col row justify-center items-center"
                >
                    <div class="text-center">
                        <div
                            class="text-bold text-h5"
                        >
                            {{ hostLabelText }}
                        </div>
                        <div
                            class="q-pt-lg"
                        >
                            <GeneralButton
                                v-cypress="'SignIn_Login_Button'"
                                size="md"
                                :label="buttonText"
                                tooltip="Sign in with your Petro.ai account"
                                class="q-px-xl q-py-sm text-bold"
                                text-color="primary"
                                color="accent"
                                :flat="false"
                                :outline="false"
                                @click="signIn"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-auto row justify-center q-py-md">
                    This software works best on the latest Chrome and Edge browsers.
                </div>
            </div>
            <div class="sign-in-links column justify-end text-right q-pa-lg text-h6">
                <a
                    :href="portalUrl"
                    class="q-px-md q-py-sm text-bold text-white"
                >
                    Go to Portal
                </a>
                <a
                    href="https://petro.ai/platform/"
                    class="q-px-md text-grey-4"
                >
                    Terms of Use
                </a>
                <a
                    href="https://petro.ai/"
                    class="q-px-md q-py-sm text-grey-4"
                >
                    Privacy & Cookies
                </a>
            </div>
        </div>
    </q-page>
</template>

<script>
import petroJSSettings from '../../../petrojs-settings.json';
import GeneralButton from '../../plugin-framework/shared-components/inline-elements/GeneralButton.vue';

export default {
    name: 'SignIn',
    components: {
        GeneralButton,
    },
    props: {
        redirectTo: {
            type: String,
            required: false,
            default: null,
        },
        buttonText: {
            type: String,
            required: false,
            default: 'Log In',
        },
        hostLabelText: {
            type: String,
            required: false,
            default: `${window.location.host}`,
        },
        redirectEnabled: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data() {
        return {
            portalUrl: petroJSSettings.portalUrl,
            email: null,
            password: null,
            emailValid: true,
            passwordValid: true,
            serverErrorMsg: null,
        };
    },
    created() {
        this.$auth.afterSignIn('routeFromSignInPage', user => {
            this.redirect();
        });
        this.$auth.afterInvalidToken('routeToForbiddenPage', () => {
            this.$router.replace({ name: 'forbidden' });
        });
    },
    methods: {
        signIn() {
            this.$auth.getSSOUser().then(user => {
                this.redirect();
            });
        },
        redirect() {
            if (this.redirectTo && this.redirectEnabled) {
                this.$router.push({ path: this.redirectTo }).catch(error => {
                    // swallow the navigation duplicated errors
                    if (error.name !== 'NavigationDuplicated') {
                        throw error;
                    }
                });
            }
            // Send user to home
            else if (this.redirectEnabled) {
                this.$router.push({ name: 'home' });
            }
        },
    },
};
</script>

<style scoped>
.sign-in-gradient-radial {
    background: radial-gradient(27.14% 47.31% at 51.11% 45.94%,#420a37 0,rgba(66,10,55,0) 100%),radial-gradient(54.4% 87.97% at -4.4% 100%,#420a37 0,rgba(66,10,55,0) 100%),radial-gradient(22.57% 33.31% at 95.62% 2.16%,#420a37 0,rgba(66,10,55,0) 100%),radial-gradient(23.68% 43.29% at 95.46% 103.68%,rgba(19,156,221,.15) 0,rgba(19,156,221,0) 100%),radial-gradient(49% 86.1% at -12.67% 57.92%,rgba(19,156,221,.15) 0,rgba(19,156,221,0) 100%),radial-gradient(34.85% 53.04% at 105.58% 56.92%,rgba(116,181,64,.15) 0,rgba(116,181,64,0) 100%),radial-gradient(45.5% 67.74% at -2.48% 0,rgba(116,181,64,.15) 0,rgba(116,181,64,0) 100%),radial-gradient(107.62% 144.95% at 3.75% 100%,#86116f 0,#420A37 77.6%);
    position: absolute;
    transform: matrix(-1,0,0,1,0,0);
    width: 100%;
    height: 100%;
}

.sign-in-background {
    background-image: url(~@/assets/images/petro-mountain.svg);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center bottom;
    position: absolute;
    width: 100%;
}

.sign-in-logo {
    position: absolute;
    left: 10vh;
    top: 10vh;
    width: 285px;
}

.sign-in-links {
    position: absolute;
    height: 100%;
    bottom: 0px;
    right: 0px;
}

@media (min-width: 1921px) {
    .sign-in-background {
        background-size: 1920px 100%;
    }

    .sign-in-gradient-left {
        background: linear-gradient(91.34deg, #420A37 45%, rgba(66,11,55,0) 75%);
        width: 50vw;
        height: 100%;
        position: absolute;
        left: 0;
    }

    .sign-in-gradient-right {
        background: linear-gradient(91.34deg, #420A37 45%, rgba(66,11,55,0) 75%);
        width: 50vw;
        height: 100%;
        position: absolute;
        right: 0;
        transform: rotate(-180deg);
    }
}

@media (max-width: 768px) {
    .sign-in-gradient-radial {
        background: radial-gradient(41.09% 74.18% at 100% 101.89%,rgba(0,158,255,.2) 0,rgba(0,158,255,0) 100%),radial-gradient(25.79% 41.07% at 104.22% -4.7%,rgba(116,181,64,.25) 0,rgba(116,181,64,0) 100%),radial-gradient(107.62% 144.95% at 3.75% 100%,#86116f 0,#420A37 77.6%);
        transform: none;
    }

    .sign-in-background {
        background-size: auto;
    }

    .sign-in-logo {
        left: 2vh;
        top: 2vh;
        width: 166px;
    }

    .sign-in-links {
        bottom: 10%;
    }
}

a {
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}

</style>
