<template>
    <div :class="editorClass">
        <div
            v-if="showMaximized"
            class="row items-center"
        >
            <div class="text-h6">
                {{ label }}
            </div>
            <q-space/>
            <GeneralButton
                v-if="allowVariablesInsert"
                flat
                dense
                size="large"
                icon="mdi-variable"
                tooltip="Insert variable"
                @click="showInsertVariableDialog = true"
            />
            <GeneralButton
                v-if="canMaximize"
                flat
                dense
                size="large"
                icon="mdi-close-box-multiple-outline"
                tooltip="Restore editor"
                @click="showMaximized = false"
            />
        </div>
        <q-scroll-area
            :class="{ 'fit': showMaximized, 'col-grow': !showMaximized }"
            :style="`${scrollStyle}`"
            :dark="dark"
        >
            <q-field
                v-cypress="testId"
                :label="`${showMaximized ? '' : label}`"
                :error="!!errors.length"
                :hint="hint"
                :stack-label="!showMaximized"
                borderless
                bottom-slots
                :dark="dark"
            >
                <template v-slot:control>
                    <CodeEditor
                        ref="jsonEditor"
                        v-model="jsonPayload"
                        :options="editorOptions"
                        lang="json"
                        theme="github"
                        :class="{ 'q-mt-sm': label }"
                        @init="editorInit"
                    />
                </template>
                <template v-slot:append>
                    <GeneralButton
                        v-if="allowVariablesInsert && !showMaximized"
                        flat
                        dense
                        size="large"
                        icon="mdi-variable"
                        tooltip="Insert variable"
                        @click="showInsertVariableDialog = true"
                    />
                    <GeneralButton
                        v-if="canMaximize && !showMaximized"
                        flat
                        dense
                        size="large"
                        icon="launch"
                        tooltip="Maximize editor"
                        @click="showMaximized = true"
                    />
                </template>
                <template v-slot:error>
                    <AutoFormValidationErrors :errors="errors"/>
                </template>
            </q-field>
        </q-scroll-area>
        <InsertVariableDialog
            v-if="allowVariablesInsert"
            v-model="showInsertVariableDialog"
            @insert="insertVariable"
        />
    </div>
</template>

<script>
import CodeEditor from 'vue2-ace-editor';
import AutoFormValidationErrors from './AutoFormValidationErrors.vue';
import 'brace/mode/json';
import 'brace/theme/tomorrow_night_bright.js';
import 'brace/theme/github.js';
import GeneralButton from '../inline-elements/GeneralButton.vue';
import InsertVariableDialog from './InsertVariableDialog.vue';

export default {
    name: 'AutoFormJSONInput',
    components: {
        InsertVariableDialog,
        GeneralButton,
        AutoFormValidationErrors,
        CodeEditor,
    },
    props: {
        value: {
            type: Object,
            required: false,
            default: null,
        },
        testId: {
            type: String,
            required: false,
            default: null,
        },
        label: {
            type: String,
            required: false,
            default: null,
        },
        hint: {
            type: String,
            required: false,
            default: null,
        },
        minLines: {
            type: Number,
            required: false,
            default: 5,
        },
        maxLines: {
            type: Number,
            required: false,
            default: 10,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false,
        },
        errors: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },
        inputErrors: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },
        canMaximize: {
            type: Boolean,
            required: false,
            default: false,
        },
        allowVariablesInsert: {
            type: Boolean,
            required: false,
            default: false,
        },
        dark: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            showMaximized: false,
            showInsertVariableDialog: false,
        };
    },
    computed: {
        jsonPayload: {
            get() {
                return this.value ? JSON.stringify(this.value, null, '\t') : null;
            },
            set(value) {
                try {
                    const valObject = JSON.parse(value);
                    this.$emit('input', valObject);
                }
                catch (error) {
                    // Take no action. The callback will display any errors.
                }
            },
        },
        syntaxErrors: {
            get() {
                return this.inputErrors;
            },
            set(value) {
                this.$emit('update:inputErrors', value);
            },
        },
        editorOptions() {
            if (this.showMaximized) {
                return {
                    readOnly: this.disabled || this.readonly,
                    minLines: this.minLines,
                    maxLines: 100,
                };
            }
            return {
                readOnly: this.disabled || this.readonly,
                minLines: this.minLines,
                maxLines: this.maxLines,
            };
        },
        editorClass() {
            if (this.showMaximized) {
                return 'col fullscreen fixed block bg-white q-pa-lg';
            }
            return 'col';
        },
        scrollStyle() {
            if (this.showMaximized) {
                return '';
            }
            // Fixed height elements of 44 for header/footer and 31.5 for additional spacing
            return `height: ${this.maxLines * 14 + 44 + 31.5}px`;
        },
    },
    methods: {
        editorInit(editor) {
            // Register a callback for reporting syntax errors
            editor.getSession().on('changeAnnotation', (event) => {
                const annotations = editor.getSession().getAnnotations();
                const syntaxErrors = annotations.filter(annotation => annotation.type === 'error');
                if (syntaxErrors.length > 0) {
                    this.syntaxErrors = ['Syntax error'];
                }
                else {
                    this.syntaxErrors = [];
                }
            });
        },
        insertVariable(variable) {
            // Wrap the variable in double curly braces and double quotes for json string
            const wrappedVariable = `"{{${variable}}}"`;
            this.$refs.jsonEditor.editor.insert(wrappedVariable);
        },
    },
};
</script>

<style scoped>

</style>
