import { TagsService } from './tags-service.js';

export const Tags = {
    install(Vue, {
        store = null,
    } = {}) {
        if (!store) {
            throw new Error('Tags plugin must be passed a vuex store.');
        }

        if (!Vue.$auth) {
            throw new Error('Tags plugin requires the Auth plugin.');
        }

        if (!Vue.$compute) {
            throw new Error('Tags plugin requires the Compute plugin.');
        }

        const service = new TagsService({ store });

        // Register the tags service globally
        Vue.$tags = service;

        // register the tags service on the vue instance
        Vue.prototype.$tags = service;
    },
};
