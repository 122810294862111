<template>
    <div
        v-if="forgeResourcesLoaded"
        class="fit q-px-md q-pt-md column"
        :class="{'dark-mode': darkMode}"
    >
        <q-toolbar
            class="col-auto q-pb-md"
        >
            <q-toolbar-title>
                {{ title }}
            </q-toolbar-title>

            <GeneralButton
                v-cypress="'FilterMenu_Reset_Button'"
                icon="refresh"
                tooltip="Reset all filters"
                @click="resetAllFilters"
            />

            <Settings
                v-model="indexDefinitions"
                display-name="Filter Preferences"
                :reference-id="$route.name"
                :parent-id="parentId"
                version="1"
                use-local-storage
                :dark="darkMode"
            />
        </q-toolbar>

        <q-input
            v-model="filtersFilter"
            v-cypress="'FilterMenu_Filter_Input'"
            clearable
            dense
            filled
            debounce="300"
            placeholder="Find filters"
            :dark="darkMode"
            class="full-width q-pb-md"
        >
            <template v-slot:prepend>
                <q-icon name="search"/>
            </template>
        </q-input>

        <q-scroll-area class="col">
            <q-expansion-item
                v-for="fragment in pageFilterFragments"
                :key="fragment.id"
                value
                :label="fragment.props.title"
                dense
                expand-separator
                :dark="darkMode"
            >
                <ForgeFilterPanel
                    v-for="(indexDef, idx) in fragment.props.indexData"
                    :key="`${fragment.id}-def${idx}`"
                    :index-data="indexDef"
                    :include-indexes="fragment.props.includeIndexes"
                    :exclude-indexes="fragment.props.excludeIndexes"
                    :show-title="fragment.props.indexData.length > 1"
                    :events="fragment.events"
                    :display-filter="filtersFilter"
                    :dark-mode="fragment.props.dark"
                />
            </q-expansion-item>
        </q-scroll-area>
    </div>
    <ForgeLoadState
        v-else
        :resources="forgeResourceElements"
        :class="{'dark-mode': darkMode}"
    />
</template>

<script>
import mixins from '../mixins';
import Settings from '../plugin-framework/shared-components/settings/Settings.vue';
import ForgeLoadState from '../plugin-framework/shared-components/ForgeLoadState.vue';
import ForgeFilterPanel from '../plugin-framework/fragments/ForgeFilterPanel.vue';
import GeneralButton from '../plugin-framework/shared-components/inline-elements/GeneralButton.vue';

export default {
    name: 'FilterMenu',
    components: {
        Settings,
        ForgeFilterPanel,
        ForgeLoadState,
        GeneralButton,
    },
    mixins: [mixins.compute],
    props: {
        title: {
            type: String,
            required: false,
            default: 'Filters',
        },
    },
    data() {
        return {
            filtersFilter: '',
        };
    },
    computed: {
        parentId() {
            return this.$route.params.petronId || this.$route.name;
        },
        pageFilterFragments() {
            return this.$filter.pageFilterFragments;
        },
        darkMode() {
            return this.pageFilterFragments.some(fragment => fragment.props && fragment.props.dark);
        },
        forgeResources() {
            let resources = [];
            this.pageFilterFragments.forEach(fragment => {
                resources = resources.concat(fragment.props.forgeResources);
            });
            return resources;
        },
        indexDefinitions: {
            get() {
                return this.$filter.indexDefinitions;
            },
            set(indexDataFragments) {
                if (!this.pageFilterFragments.length) {
                    return;
                }
                this.$filter.indexDefinitions = indexDataFragments;
            },
        },
    },
    methods: {
        resetAllFilters() {
            this.$filter.resetAllFilters();
        },
    },
};
</script>

<style scoped>
.dark-mode {
    background-color: black;
    color: white;
}
</style>
