<template>
    <q-page
        padding
        class="text-grey-7"
    >
        <div
            class="text-secondary text-h6 text-bold"
        >
            {{ title }}
        </div>
        <div>
            Access services that augment and accelerate your work in Petro.ai.
        </div>
        <ServiceCardGrid
            v-if="loadedServices.length"
            :services="loadedServices"
            class="q-py-lg"
            @view-service="showService"
        />
        <div
            v-else
            class="q-py-lg"
        >
            No services are currently available.
        </div>
        <ServiceDetailsDialog
            v-model="showServiceDialog"
            :service="viewingService"
            @email="sendServiceRequest"
        />
        <q-inner-loading :showing="showLoading">
            <q-spinner
                size="30px"
                color="primary"
            />
            <p>Retrieving services...</p>
        </q-inner-loading>
    </q-page>
</template>

<script>
import { APP_TITLE } from '../constants.js';
import ServiceCardGrid from './ServiceCardGrid.vue';
import ServiceDetailsDialog from './ServiceDetailsDialog.vue';

export default {
    name: 'TheServicesPage',
    components: {
        ServiceDetailsDialog,
        ServiceCardGrid,
    },
    data() {
        return {
            loadedServices: [],
            servicesLoaded: false,
            showServiceDialog: false,
            viewingService: {},
        };
    },
    computed: {
        showLoading() {
            return !this.servicesLoaded;
        },
        title() {
            return APP_TITLE;
        },
    },
    mounted() {
        this.loadServices();
    },
    methods: {
        showService(service) {
            if (service) {
                this.viewingService = service;
                this.showServiceDialog = true;
            }
        },
        loadServices() {
            this.servicesLoaded = false;
            this.$cms.getPlusServices()
            .then((response) => {
                const services = response.map(service => {
                    return {
                        ...service,
                    };
                });
                services.sort((a, b) => {
                    if (!a.appOrder) {
                        return 100;
                    }
                    if (!b.appOrder) {
                        return -100;
                    }
                    return a.appOrder - b.appOrder;
                });
                this.loadedServices = services;
                this.servicesLoaded = true;
            })
            .catch((issue) => {
                this.servicesLoaded = true;
                this.$logging.loggers.PluginFramework.error(`Error loading Plus services: ${issue}`);
            });
        },
        sendServiceRequest(service) {
            const subject = `Services Request for '${service.name}'`;
            window.location.href = `mailto:sales@petro.ai?subject=${subject}`;
        },
    },
};
</script>

<style scoped>

</style>
