export const commonButtonProps = {
    label: {
        required: false,
        default: '',
        validator(value) {
            return typeof value === 'string' || value === 'number';
        },
    },
    tooltip: {
        type: String,
        required: true,
    },
    hide: {
        type: Boolean,
        required: false,
        default: false,
    },
    // Overrides the QBtn disable functionality so that the click event is not passed and the opacity is changed
    // This allows for the tooltip to persist even in disabled state
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
    testId: {
        type: String,
        required: false,
        default: null,
    },
    dense: {
        type: Boolean,
        required: false,
        default: false,
    },
    size: {
        type: String,
        required: false,
        default: 'md',
    },
    color: {
        type: String,
        required: false,
        default: undefined,
    },
    textColor: {
        type: String,
        required: false,
        default: undefined,
    },
    icon: {
        type: String,
        required: false,
        default: undefined,
    },
    iconRight: {
        type: String,
        required: false,
        default: undefined,
    },
    flat: {
        type: Boolean,
        required: false,
        default: true, // TODO: Revisit this
    },
    outline: {
        type: Boolean,
        required: false,
        default: true,
    },
    align: {
        type: String,
        required: false,
        default: 'center',
    },
    noCaps: {
        type: Boolean,
        required: false,
        default: true,
    },
    type: {
        required: false,
        default: 'button',
        validator(value) {
            return ['a', 'submit', 'button', 'reset'].includes(value);
        },
    },
    to: {
        type: Object,
        required: false,
        default: null,
    },
    replace: {
        type: Boolean,
        required: false,
        default: false,
    },
};
