<template>
    <div class="column items-center">
        <GeneralButton
            label="Set Feature Flags"
            tooltip="Open Feature Flags Dialog"
            :outline="false"
            :flat="false"
            color="accent"
            text-color="primary"
            :disabled="!currentFeatureFlags"
            @click="$emit('click', $event)"
        />
        <p class="text-bold q-mt-md">
            Current Feature Flags:
        </p>
        <div
            v-if="currentFeatureFlags && currentFeatureFlags.length"
            class="column items-center"
        >
            <div
                v-for="(featureFlag, index) in currentFeatureFlags"
                :key="index"
            >
                {{ featureFlag }}<br>
            </div>
        </div>
        <p v-else-if="currentFeatureFlags === undefined">
            Loading...
        </p>
        <p v-else>
            None
        </p>
    </div>
</template>

<script>
import GeneralButton from '../../plugin-framework/shared-components/inline-elements/GeneralButton.vue';

export default {
    name: 'FeatureFlagsPage',
    components: {
        GeneralButton,
    },
    props: {
        currentFeatureFlags: {
            validator: (value) => Array.isArray(value) || value === undefined,
            required: true,
        },
    },
};
</script>

<style>

</style>
