import { SettingsModel } from './model/settings-model.js';
import { SettingsRootRoute } from './fragments/settings-route.js';
import AppSettingsList from './components/AppSettingsList';

export const SettingsPlugin = {
    name: 'Settings',
    views: {
        AppSettingsList,
    },
    definition({ app, plugin }) {
        return {
            modules: [
                SettingsRootRoute({ app, plugin }),
            ],
            model: SettingsModel({ app, plugin }),
        };
    },
};
