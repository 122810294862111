<template>
    <div class="column full-height">
        <div class="col-shrink text-h5 q-pa-md row items-center no-wrap">
            <span class="col ellipsis">
                {{ title }}
                <q-tooltip :delay="750">
                    {{ title }}
                </q-tooltip>
            </span>
            <span class="col-auto">
                <OmniButton
                    v-for="(button, index) in leftHeaderButtons"
                    :key="`leftbutton-${index}`"
                    :button="button"
                />
                <q-space/>
                <OmniButton
                    v-for="(button, index) in rightHeaderButtons"
                    :key="`rightbutton-${index}`"
                    :button="button"
                />
            </span>
        </div>

        <q-scroll-area class="col-grow q-pa-md">
            <Section
                v-for="(section, index) in sections"
                :key="index"
                :section="section"
                class="q-pb-sm"
            />
        </q-scroll-area>

        <hr
            v-if="showFooter"
            class="custom-separator"
        >

        <div
            v-if="showFooter"
            class="row q-pa-md"
        >
            <div
                v-for="(indicator, index) in indicators"
                :key="`indicator-${index}`"
                class="col"
            >
                <CircularProgressIndicator
                    v-if="indicator.type === 'circular-progress'"
                    v-bind="indicator.props"
                />
            </div>
            <OmniButton
                v-for="(button, index) in leftActionButtons"
                :key="`button-left-${index}`"
                :button="button"
            />
            <q-space/>
            <OmniButton
                v-for="(button, index) in rightActionButtons"
                :key="`button-right-${index}`"
                :button="button"
            />
        </div>
    </div>
</template>

<script>
import CircularProgressIndicator from './CircularProgressIndicator.vue';
import Section from './sections/Section.vue';
import OmniButton from './inline-elements/OmniButton.vue';

export default {
    name: 'Sidebar',
    components: {
        OmniButton,
        Section,
        CircularProgressIndicator,
    },
    props: {
        title: {
            type: [String, Function],
            required: true,
        },
        leftHeaderButtons: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },
        rightHeaderButtons: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },
        sections: {
            type: Array,
            required: true,
        },
        indicators: {
            type: Array,
            required: true,
        },
        leftActionButtons: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },
        rightActionButtons: {
            type: Array,
            required: true,
        },
        debug: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        showFooter() {
            return this.leftActionButtons.length || this.rightActionButtons.length || this.indicators.length;
        },
    },
};
</script>

<style scoped>
.custom-separator {
    border: none;
    height: 20px;
    width: 100%;
    margin-top: 0;
    box-shadow: 0px -5px 5px -5px #ccc;
    margin-bottom: -20px;
}
</style>
