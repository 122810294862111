import { Link as TipTapLink } from 'tiptap-extensions';

// Custom Link class to override schema allowing for mentions and hashtags to be links as well
// Without this mentions and hashtags resolve to links and lose their data attributes
export default class Link extends TipTapLink {
    get schema() {
        return {
            ...super.schema,
            // Implement our own dom structure using links
            toDOM: (node) => [
                // Tag
                'a',
                // Attributes
                {
                    ...node.attrs,
                    // This is key! Need a unique way to identify links vs mentions and hashtags
                    'data-link-type': 'link',
                    rel: 'noopener noreferrer nofollow',
                },
                // Content
                0,
            ],
            parseDOM: [
                {
                    tag: 'a[data-link-type]',
                    getAttrs: (dom) => {
                        return {
                            href: dom.getAttribute('href'),
                        };
                    },
                },
            ],
        };
    }
}
