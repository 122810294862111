import { ActionsDialog } from './fragments/actions-dialog.js';
import { FormDialog } from './fragments/form-dialog.js';
import { InfoDialog } from './fragments/info-dialog.js';
import ActionsList from './components/ActionsList.vue';

export const ActionsPlugin = {
    name: 'Actions',
    views: {
        ActionsList,
    },
    definition({ app, plugin }) {
        return {
            modules: [
                ActionsDialog({ app, plugin }),
                FormDialog({ app, plugin }),
                InfoDialog({ app, plugin }),
            ],
            model: {},
        };
    },
};
