<template>
    <div
        v-if="forgeResourcesLoaded"
        class="q-pa-md fit"
    >
        <div
            v-if="title"
            class="text-h6 q-pb-md"
        >
            {{ title }}
        </div>
        <CommentInput
            v-cypress="'CommentView_RootComment_Input'"
            editable
            clear-after-submit
            placeholder="Comment, use @ to mention, or # to tag"
            class="comment-input q-mb-lg"
            @submit="rootCommentSubmitted"
        />
        <Comment
            v-for="(comment, index) in comments"
            :key="index"
            :comment="comment.comment"
            :replies="comment.replies"
            :reactions="comment.reactions"
            @create="createComment"
            @update="updateComment"
            @delete="deleteComment"
            @add-reaction="addReaction"
            @remove-reaction="removeReaction"
        />
    </div>
    <ForgeLoadState
        v-else
        :resources="forgeResourceElements"
    />
</template>

<script>
import mixins from '../../../mixins';
import Comment from './Comment.vue';
import CommentInput from './editor/CommentEditor.vue';
import ForgeLoadState from '../ForgeLoadState.vue';

export default {
    name: 'CommentView',
    components: {
        ForgeLoadState,
        CommentInput,
        Comment,
    },
    mixins: [mixins.compute],
    props: {
        title: {
            type: String,
            required: false,
            default: null,
        },
        forgeResources: {
            type: Array,
            required: false,
            default: () => [],
        },
        comments: {
            type: Array,
            required: false,
            default: () => [],
        },
        pluginId: {
            type: String,
            required: false,
            default: null,
        },
        viewId: {
            type: String,
            required: false,
            default: null,
        },
        petronId: {
            type: String,
            required: false,
            default: null,
        },
    },
    computed: {
        canMakeApiCalls() {
            return !!this.pluginId && !!this.viewId;
        },
    },
    methods: {
        rootCommentSubmitted({ htmlContent, mentionIds }) {
            const comment = {
                richContent: htmlContent,
                parentCommentId: null,
                userId: this.$auth.user.userId,
                mentionIds,
            };
            this.createComment(comment);
        },
        createComment(comment) {
            comment.pluginId = this.pluginId;
            comment.petronId = this.petronId;
            comment.viewId = this.viewId;

            if (this.$listeners.createComment) {
                this.$emit('createComment', comment);
            }
            else if (this.canMakeApiCalls) {
                this.$api.data.insert({
                    type: 'Comment',
                    data: [comment],
                })
                .then(response => {
                    this.$notify.success('Comment added');
                })
                .catch(error => {
                    this.$notify.error(`There was an error adding comment: ${error}`);
                });
            }
            else {
                this.$logging.loggers.PluginFramework.error('Comment View missing props to create comment. Provide a createComment event or pass pluginId and viewId');
            }
        },
        updateComment({ comment }) {
            comment.pluginId = this.pluginId;
            comment.petronId = this.petronId;
            comment.viewId = this.viewId;

            if (this.$listeners.updateComment) {
                this.$emit('updateComment', comment);
            }
            else if (this.canMakeApiCalls) {
                this.$api.data.update({
                    type: 'Comment',
                    query: { id: comment.id },
                    body: {
                        ...comment,
                    },
                })
                .then(response => {
                    this.$notify.success('Comment updated');
                })
                .catch(error => {
                    this.$notify.error(`There was an error updating comment: ${error}`);
                });
            }
            else {
                this.$logging.loggers.PluginFramework.error('Comment View missing props to update comment. Provide a updateComment event or pass pluginId and viewId');
            }
        },
        deleteComment({ id }) {
            if (this.$listeners.deleteComment) {
                this.$emit('deleteComment', { id });
            }
            else if (this.canMakeApiCalls) {
                this.$api.data.delete({
                    type: 'Comment',
                    query: { id },
                })
                .then(response => {
                    this.$notify.success('Comment deleted');
                })
                .catch(error => {
                    this.$notify.error(`There was an error deleting comment: ${error}`);
                });
            }
            else {
                this.$logging.loggers.PluginFramework.error('Comment View missing props to delete comment. Provide a deleteComment event or pass pluginId and viewId');
            }
        },
        addReaction({ reaction }) {
            reaction.pluginId = this.pluginId;
            reaction.petronId = this.petronId;
            reaction.viewId = this.viewId;

            if (this.$listeners.addReaction) {
                this.$emit('addReaction', reaction);
            }
            else if (this.canMakeApiCalls) {
                this.$api.data.insert({
                    type: 'Comment',
                    data: [reaction],
                })
                .then(response => {
                    this.$notify.success('Reaction added');
                })
                .catch(error => {
                    this.$notify.error(`There was an error adding reaction: ${error}`);
                });
            }
            else {
                this.$logging.loggers.PluginFramework.error('Comment View missing props to add reaction. Provide a addReaction event or pass pluginId and viewId');
            }
        },
        removeReaction({ reaction }) {
            const id = reaction.id;
            if (this.$listeners.removeReaction) {
                this.$emit('removeReaction', { id });
            }
            else if (this.canMakeApiCalls) {
                this.$api.data.delete({
                    type: 'Comment',
                    query: { id },
                })
                .then(response => {
                    this.$notify.success('Reaction removed');
                })
                .catch(error => {
                    this.$notify.error(`There was an error removing reaction: ${error}`);
                });
            }
            else {
                this.$logging.loggers.PluginFramework.error('Comment View missing props to remove reaction. Provide a removeReaction event or pass pluginId and viewId');
            }
        },
    },
};
</script>

<style scoped lang="stylus">
@import '../../../css/quasar.variables.styl'

.comment-input {
    border: solid 1px $separator-color;
}
</style>
