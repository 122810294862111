<template>
    <q-list>
        <UserItem
            :user-id="petron.ownerId"
            label="Owner"
        />
        <UserItem
            v-for="(collaborator, idx) in collaborators"
            :key="`collaborator-${idx}`"
            :user-id="collaborator"
            label="Collaborator"
            :can-remove="canEdit"
            @remove="(user) => $emit('remove', user)"
        />
    </q-list>
</template>

<script>
export default {
    name: 'CollaboratorList',
    props: {
        petron: {
            type: Object,
            required: true,
        },
        collaborators: {
            type: Array,
            required: false,
            default: () => [],
        },
        canEdit: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style scoped>

</style>
