<template>
    <PDialog
        v-model="display"
        title="Insert Variable"
        hide-cancel-btn
        ok-btn-text="Insert & Close"
        ok-btn-tooltip="Insert variable"
        :ok-btn-disabled="!insertButtonEnabled"
        @ok="$emit('insert', variableForm.selectedVariable)"
    >
        <AutoForm
            :value="variableForm"
            :initial-value="variableForm"
            :schema="variableFormSchema"
            @input="updateForm"
        />
    </PDialog>
</template>

<script>
import PDialog from '../../services/pai-components/PDialog.vue';

export default {
    name: 'InsertVariableDialog',
    components: {
        // https://vuejs.org/v2/guide/components-edge-cases.html#Circular-References-Between-Components
        AutoForm: () => import('./AutoForm.vue'),
        PDialog,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            variableForm: {
                selectedVariable: null,
            },
            variables: [],
            insertButtonEnabled: !!this.selectedVariable,
        };
    },
    computed: {
        display: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            },
        },
        variableFormSchema() {
            const variableFormSchema = {
                inputs: [
                    {
                        label: 'Variable',
                        key: 'selectedVariable',
                        hint: 'Select a variable to insert',
                        inputType: 'select',
                        default: null,
                        clearable: true,
                        options: this.variables,
                        optionLabel: 'key',
                        optionValue: 'key',
                        multiple: false,
                        useInput: true,
                        validationRules: [
                            (value) => !!value || 'Please select a variable to insert.',
                        ],
                    },
                ],
            };
            return variableFormSchema;
        },
    },
    async created() {
        this.variables = await this.getVariables();
    },
    methods: {
        async getVariables() {
            return this.$api.variables.getAll()
            .then(response => response.data)
            .catch(error => {
                this.$notify.error(`There was an error retrieving variables: ${error}`);
            });
        },
        updateForm(updatedForm) {
            this.variableForm = updatedForm;
            // Disable the insert button if needed
            this.insertButtonEnabled = !!this.variableForm.selectedVariable;
        },
    },
};
</script>

<style scoped>

</style>
