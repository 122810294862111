<template>
    <div
        v-if="!hide"
        class="text-h5 text-bold q-pb-md"
    >
        {{ value }}
    </div>
</template>

<script>
export default {
    name: 'TitleSection',
    props: {
        value: {
            type: String,
            required: true,
        },
        hide: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style scoped>

</style>
