<template>
    <PCard
        v-cypress="'PluginInfoCard_Plugins_PCard'"
        title="Plugins"
    >
        <q-markup-table
            dense
            flat
            :bordered="false"
            separator="none"
            class="q-py-md text-grey-8"
        >
            <tbody>
                <tr
                    v-for="plugin in plugins"
                    :key="plugin.id"
                >
                    <td>
                        {{ plugin.name }}
                    </td>
                    <td/>
                    <td>
                        <span style="vertical-align: text-top">{{ `v${plugin.version}` }}</span>
                        <GeneralButton
                            icon="info_outline"
                            tooltip="Show details"
                            dense
                            @click="displayPluginDetails(plugin)"
                        />
                    </td>
                </tr>
            </tbody>
        </q-markup-table>
        <PDialog
            v-if="detailsPlugin"
            v-model="displayDetailsDialog"
            hide-cancel-btn
            ok-btn-text="Close"
        >
            <template
                slot="header"
            >
                <span class="text-h6">
                    {{ detailsPlugin.name }}
                </span>
            </template>
            <template
                slot="footer"
            >
                <GeneralButton
                    v-close-popup
                    color="primary"
                    flat
                    outline
                    label="Close"
                    tooltip="Hide details"
                />
            </template>
            <div>
                v{{ detailsPlugin.version }}
            </div>
            <div class="q-pb-xl">
                {{ detailsPlugin.description }}
            </div>
            <div class="col">
                <q-expansion-item
                    dense
                    label="Containers"
                    :disable="!containersForDisplay.length"
                >
                    <q-list
                        class="q-px-md text-grey-8"
                        dense
                    >
                        <q-item
                            v-for="(containerName, index) in containersForDisplay"
                            :key="`container-${index}`"
                        >
                            <q-item-section>
                                {{ containerName }}
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-expansion-item>
                <q-expansion-item
                    dense
                    label="Triggers"
                    :disable="!triggersForDisplay.length"
                >
                    <q-list
                        class="q-px-md text-grey-8"
                        dense
                    >
                        <q-item
                            v-for="(trigger, index) in triggersForDisplay"
                            :key="`trigger-${index}`"
                        >
                            <q-item-section>
                                {{ trigger.name || 'N/A' }}
                            </q-item-section>
                            <q-item-section>
                                {{ trigger.eventName === '*' ? 'Any' : trigger.eventName }}
                            </q-item-section>
                            <q-item-section>
                                {{ trigger.eventSource === '*' ? 'Any' : trigger.eventSource }}
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-expansion-item>
                <q-expansion-item
                    dense
                    label="Events"
                    :disable="!eventsForDisplay.length"
                >
                    <q-list
                        class="q-px-md text-grey-8"
                        dense
                    >
                        <q-item
                            v-for="(eventName, index) in eventsForDisplay"
                            :key="`event-${index}`"
                        >
                            <q-item-section>
                                {{ eventName }}
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-expansion-item>
                <q-expansion-item
                    dense
                    label="Tasks"
                    :disable="!tasksForDisplay.length"
                >
                    <q-list
                        class="q-px-md text-grey-8"
                        dense
                    >
                        <q-item
                            v-for="(taskName, index) in tasksForDisplay"
                            :key="`task-${index}`"
                        >
                            <q-item-section>
                                {{ taskName }}
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-expansion-item>
            </div>
        </PDialog>
    </PCard>
</template>

<script>
import PDialog from '../../../plugin-framework/services/pai-components/PDialog';
import GeneralButton from '../../../plugin-framework/shared-components/inline-elements/GeneralButton.vue';

export default {
    name: 'PluginInfoCard',
    components: {
        GeneralButton,
        PDialog,
    },
    props: {
        plugins: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            displayDetailsDialog: false,
            detailsPlugin: null,
        };
    },
    computed: {
        containersForDisplay() {
            if (this.detailsPlugin && this.detailsPlugin.containers) {
                const containerNames = this.detailsPlugin.containers.map(container => container.containerName);
                containerNames.sort((a, b) => a.localeCompare(b));
                return containerNames;
            }
            return [];
        },
        eventsForDisplay() {
            if (this.detailsPlugin && this.detailsPlugin.events) {
                const eventNames = this.detailsPlugin.events.map(event => event.name);
                eventNames.sort((a, b) => a.localeCompare(b));
                return eventNames;
            }
            return [];
        },
        tasksForDisplay() {
            if (this.detailsPlugin && this.detailsPlugin.tasks) {
                const taskNames = this.detailsPlugin.tasks.map(task => task.name);
                taskNames.sort((a, b) => a.localeCompare(b));
                return taskNames;
            }
            return [];
        },
        triggersForDisplay() {
            if (this.detailsPlugin && this.detailsPlugin.triggers) {
                return this.detailsPlugin.triggers;
            }
            return [];
        },
    },
    methods: {
        displayPluginDetails(selectedPlugin) {
            this.detailsPlugin = selectedPlugin;
            this.displayDetailsDialog = true;
        },
    },
};
</script>

<style scoped>
    td:first-of-type {
        width: 50%;
    }
    td:nth-child(2) {
        width: 15%;
    }
</style>
