import moment from 'moment-timezone';
import Vue from 'vue';
import startCase from 'lodash/startCase';
import camelCase from 'lodash/camelCase';
import kebabCase from 'lodash/kebabCase';
import snakeCase from 'lodash/snakeCase';

function defaultDecimalPlaces(number, decimalPlacesParameter) {
    // If decimalPlaces is provided then use it, if not then use 2 for above 1 and 4 for between -1 and 1
    if (typeof decimalPlacesParameter === 'number' && decimalPlacesParameter >= 0) {
        return decimalPlacesParameter.toFixed(0);
    }
    return (number >= 1 || number <= -1) ? 2 : 4;
}

function numberValid(number) {
    return !(number === null || number === undefined || typeof number !== 'number');
}

export const formatting = {
    FORMAT_DEFAULT_LONG_DATE_TIME: 'h:mm A, MMMM D, YYYY z',
    FORMAT_DEFAULT_SHORT_DATE_TIME: 'h:mm A, MM/D/YY z',
    FORMAT_DEFAULT_LONG_DATE: 'MMMM D, YYYY z',
    FORMAT_DEFAULT_SHORT_DATE: 'M/D/YY z',
    FORMAT_DEFAULT_TIME: 'h:mm a z',
    formatNumber(number, {
        thousandsSeparator = true, decimalPlaces = null, defaultValue = '-', returnNumber = false,
    } = {}) {
        if (numberValid(number)) {
            const fractionDigits = defaultDecimalPlaces(number, decimalPlaces);
            let numberString = number.toLocaleString(undefined, {
                minimumFractionDigits: fractionDigits,
                maximumFractionDigits: fractionDigits,
            });

            if (returnNumber) {
                // remove thousands separator and convert to Number
                return Number(numberString.replace(/,/, ''));
            }

            if (!thousandsSeparator) {
                numberString = numberString.replace(/,/, '');
            }

            return numberString;
        }
        return defaultValue;
    },
    formatBytes(bytes, { binary = false, decimalPlaces = null } = {}) {
        let sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB'];
        let base = 1000;

        if (binary) {
            sizes = ['Bytes', 'kiB', 'MiB', 'GiB', 'TiB'];
            base = 1024;
        }

        if (bytes === undefined || bytes === null || Number.isNaN(bytes)) {
            return '- Bytes';
        }

        if (bytes === 0) {
            return '0 Bytes';
        }

        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(base)), 10);
        if (i === 0) {
            const formattedBytesValue = this.formatNumber(bytes / (base ** i), { decimalPlaces });
            return `${formattedBytesValue} ${sizes[i]}`;
        }

        const formattedBytesValue = this.formatNumber(bytes / (base ** i), { decimalPlaces });
        return `${formattedBytesValue} ${sizes[i]}`;
    },
    formatPercent(number, { thousandsSeparator = true, decimalPlaces = null, defaultValue = '-' } = {}) {
        if (numberValid(number)) {
            const percent = number * 100;
            const formattedPercent = this.formatNumber(percent, { thousandsSeparator, decimalPlaces, defaultValue });
            return `${formattedPercent}%`;
        }
        return defaultValue;
    },
    // Formats a JS Date object or date string into the correct display timezone
    formatDate(date, timeZoneId = 'UTC', formatString = this.FORMAT_DEFAULT_LONG_DATE_TIME) {
        if (!date || !timeZoneId) {
            return '-';
        }

        // Check if timeZoneId exists and is valid
        if (timeZoneId && !(typeof timeZoneId === 'object') && moment.tz.zone(timeZoneId)) {
            return moment(date).tz(timeZoneId).format(formatString);
        }

        Vue.$logging.loggers.PluginFramework.warn(`Invalid timeZoneId passed to formatDate: ${timeZoneId}. Using local timezone.`);
        return moment(date).tz(moment.tz.guess(true)).format(formatString);
    },
    formatLongDate(date, timeZoneId) {
        return this.formatDate(date, timeZoneId, this.FORMAT_DEFAULT_LONG_DATE);
    },
    formatShortDate(date, timeZoneId) {
        return this.formatDate(date, timeZoneId, this.FORMAT_DEFAULT_SHORT_DATE);
    },
    formatLongDateTime(date, timeZoneId) {
        return this.formatDate(date, timeZoneId, this.FORMAT_DEFAULT_LONG_DATE_TIME);
    },
    formatShortDateTime(date, timeZoneId) {
        return this.formatDate(date, timeZoneId, this.FORMAT_DEFAULT_SHORT_DATE_TIME);
    },
    isValidDate(dateText) {
        return moment(dateText).isValid();
    },
    timeAgo(date, timeZoneId = 'UTC', excludeSuffix = false) {
        if (!date) {
            return '';
        }

        // Special case for times in the future or the last couple of seconds.
        if (new Date(date) - Date.now() > -(2 * 1000)) {
            return 'just now';
        }

        // Check if timeZoneId exists and is valid
        if (timeZoneId && !(typeof timeZoneId === 'object') && moment.tz.zone(timeZoneId)) {
            return moment(date).tz(timeZoneId).fromNow(excludeSuffix);
        }

        Vue.$logging.loggers.PluginFramework.warn(`Invalid timeZoneId passed to timeAgo: ${timeZoneId}. Using local timezone.`);
        return moment(date).tz(moment.tz.guess(true)).fromNow(excludeSuffix);
    },
    // Convert a string to 32 bit integer hash then normalize from 0 to 1
    getNormHashFromString(string) {
        let hash = 0;
        let i;
        let chr;
        for (i = 0; i < string.length; i += 1) {
            chr = string.charCodeAt(i);
            // eslint-disable-next-line no-bitwise
            hash = ((hash << 5) - hash) + chr;
            // eslint-disable-next-line no-bitwise
            hash |= 0; // Convert to 32bit integer
        }
        return (hash + 2147483648) / (2147483647 + 2147483648);
    },
    roundNumber(number, { decimalPlaces = null, roundUp = true }) {
        if (!numberValid(number)) {
            return NaN;
        }
        const fractionDigits = defaultDecimalPlaces(number, decimalPlaces);
        const multiplier = 10 ** fractionDigits;
        return (roundUp ? Math.ceil(number * multiplier) : Math.floor(number * multiplier)) / multiplier;
    },
    // Converts a provided string to start case (i.e. every word capitalized with spaces between words)
    startCase(string) {
        return startCase(string);
    },
    camelCase(string) {
        return camelCase(string);
    },
    kebabCase(string) {
        return kebabCase(string);
    },
    snakeCase(string) {
        return snakeCase(string);
    },
    createAcronym(inputText, maximumLetters = null) {
        if (!inputText) {
            return '';
        }
        const capitalizedLetters = inputText.match(/[A-Z]+/g);
        if (!capitalizedLetters) {
            return '';
        }
        const acronym = capitalizedLetters.join('');
        if (!maximumLetters) {
            return acronym;
        }
        const lettersToKeep = Math.min(acronym.length, maximumLetters);
        return acronym.slice(0, lettersToKeep);
    },
};
