<template>
    <q-infinite-scroll @load="load">
        <CommentInput
            v-if="commentPetronId"
            v-cypress="'ActivityFeed_Comment_Input'"
            editable
            clear-after-submit
            placeholder="Create post, use @ to mention, or # to tag"
            class="comment-input q-mb-lg q-px-sm"
            @submit="commentSubmitted"
        />
        <div
            v-if="commentItems.length > 0"
        >
            <ActivityItem
                v-for="(item, index) in itemsWithDateSeparators"
                :key="index"
                :type="item.documentType"
                :item="item.thread"
                :petron-name="item.petronName"
                :petron-id="item.pid"
                :plugin-id="item.pluginId"
                :show-navigation="showNavigationForItem(item)"
                :show-petron="!isCurrentPetron(item)"
                class="q-px-xs"
                @create="replySubmitted($event, item)"
                @update="commentUpdated"
                @delete="commentDeleted"
                @add-reaction="reactionAdded"
                @remove-reaction="reactionRemoved"
            />
        </div>
        <div
            v-else
            class="text-center text-grey-9"
        >
            <p>{{ noActivityMessage }}</p>
        </div>
        <template v-slot:loading>
            <div class="text-center">
                <q-spinner
                    size="30px"
                    color="primary"
                />
                <p>Loading activity...</p>
            </div>
        </template>
    </q-infinite-scroll>
</template>

<script>
import moment from 'moment-timezone';
import ActivityItem from './ActivityItem.vue';
import CommentInput from '../comments/editor/CommentEditor.vue';

export default {
    name: 'ActivityFeed',
    components: {
        ActivityItem,
        CommentInput,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        totalItems: {
            type: Number,
            required: true,
        },
        commentPetronId: {
            type: String,
            required: false,
            default: null,
        },
        commentPetronName: {
            type: String,
            required: false,
            default: '',
        },
        noActivityMessage: {
            type: String,
            required: false,
            default: 'No recent activity.',
        },
    },
    data() {
        return {
            pluginId: 'PetronOverview',
            viewId: 'activity-feed',
        };
    },
    computed: {
        commentItems() {
            if (this.items && this.items.length) {
                return this.items;
            }
            return [];
        },
        displayTimeZoneId() {
            return this.$globalSettings.displayTimeZoneId;
        },
        itemsWithDateSeparators() {
            const todayDate = moment.utc();
            let lastDate = todayDate;
            let todayInserted = false;
            const itemsWithDateSeparators = [];
            this.commentItems.forEach(item => {
                // Insert a 'Today' marker if not inserted already and day is today
                if (!todayInserted && moment(item.generatedAt).isSame(todayDate, 'day')) {
                    itemsWithDateSeparators.push({
                        documentType: 'horizontal-rule',
                        thread: {
                            label: 'Today',
                        },
                    });
                    todayInserted = true;
                }
                // Insert a date marker when day changes
                if (moment(item.generatedAt).isBefore(lastDate, 'day')) {
                    itemsWithDateSeparators.push({
                        documentType: 'horizontal-rule',
                        thread: {
                            label: this.$utils.formatting.formatDate(item.generatedAt, this.displayTimeZoneId, 'MMM Do'),
                        },
                    });
                }
                lastDate = item.generatedAt;
                itemsWithDateSeparators.push(item);
            });
            return itemsWithDateSeparators;
        },
    },
    methods: {
        load(index, done) {
            if (this.items.length < this.totalItems) {
                // Pass the infinite scroll event up to the parent to get more items
                this.$emit('load-more', index, done);
            }
            else {
                done();
            }
        },
        commentSubmitted({ htmlContent, mentionIds }) {
            if (!this.commentPetronId) {
                return;
            }
            const created = new Date().toISOString();
            const comment = {
                richContent: htmlContent,
                parentCommentId: null,
                userId: this.$auth.user.userId,
                mentionIds,
                pluginId: this.pluginId,
                petronId: this.commentPetronId,
                viewId: this.viewId,
            };
            const item = {
                createdAt: created,
                documentType: 'CommentThread',
                thread: {
                    comment: {
                        ...comment,
                        annotationType: 'Comment',
                        commentType: 'comment',
                        createdAt: created,
                    },
                },
                generatedAt: created,
                petronName: this.commentPetronName,
                pid: this.commentPetronId,
                pluginId: this.pluginId,
            };
            this.$api.data.insert({
                type: 'Comment',
                data: [comment],
            })
            .then((response) => {
                if (response && response.insertedIds && response.insertedIds.length) {
                    item.thread.comment.id = response.insertedIds[0];
                }
                this.$emit('item-added', { item });
            })
            .catch(error => {
                this.$notify.error(`There was an error adding comment: ${error}`);
            });
        },
        replySubmitted(reply, parentItem) {
            const created = new Date().toISOString();
            const item = {
                comment: {
                    ...reply,
                    annotationType: 'Comment',
                    commentType: 'comment',
                    createdAt: created,
                },
                reactions: [],
            };
            this.$api.data.insert({
                type: 'Comment',
                data: [reply],
            })
            .then((response) => {
                if (response && response.insertedIds && response.insertedIds.length) {
                    item.comment.id = response.insertedIds[0];
                }
                this.$emit('item-added', { item, parentItem });
            })
            .catch(error => {
                this.$notify.error(`There was an error adding comment: ${error}`);
            });
        },
        commentUpdated({ comment, parentId }) {
            this.$api.data.update({
                type: 'Comment',
                query: { id: comment.id },
                body: {
                    ...comment,
                },
            })
            .then(() => {
                this.$emit('item-updated', { comment, parentId });
            })
            .catch(error => {
                this.$notify.error(`There was an error updating comment: ${error}`);
            });
        },
        commentDeleted({ id, parentId }) {
            this.$api.data.delete({
                type: 'Comment',
                query: { id },
            })
            .then(() => {
                this.$notify.success('Comment deleted.');
                this.$emit('item-deleted', { id, parentId });
            })
            .catch(error => {
                this.$notify.error(`There was an error deleting comment: ${error}`);
            });
        },
        reactionAdded({ reaction, parentId }) {
            this.$api.data.insert({
                type: 'Comment',
                data: [reaction],
            })
            .then((response) => {
                if (response && response.insertedIds && response.insertedIds.length) {
                    reaction.id = response.insertedIds[0];
                }
                this.$emit('add-reaction', { reaction, parentId });
            })
            .catch(error => {
                this.$notify.error(`There was an error adding reaction: ${error}`);
            });
        },
        reactionRemoved({ reaction, parentId }) {
            this.$api.data.delete({
                type: 'Comment',
                query: { id: reaction.id },
            })
            .then(() => {
                this.$emit('remove-reaction', { reaction, parentId });
            })
            .catch(error => {
                this.$notify.error(`There was an error removing reaction: ${error}`);
            });
        },
        isCurrentPetron(item) {
            return (this.commentPetronId && item.pid && this.commentPetronId === item.pid);
        },
        isLocalComment(item) {
            return (this.commentPetronId && (item.thread && item.thread.comment && item.thread.comment.viewId === this.viewId));
        },
        showNavigationForItem(item) {
            if (item.documentType === 'horizontal-rule') {
                return false;
            }
            return !this.isLocalComment(item);
        },
    },
};
</script>

<style scoped lang="stylus">
@import '../../../css/quasar.variables.styl'
.comment-input {
    border: solid 1px $separator-color;
}
</style>
