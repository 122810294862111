import { DataStorageRoute } from './fragments/data-storage-route.js';
import { DataStorageModel } from './model/data-storage-model.js';
import { DataStorageMainMenuItem } from './fragments/data-storage-main-menu-item.js';
import DataStorageIndicator from './components/DataStorageIndicator.vue';

export const DataStoragePlugin = {
    name: 'DataStorage',
    views: {
        DataStorageIndicator,
    },
    definition({ app, plugin }) {
        return {
            modules: [
                DataStorageRoute({ app, plugin }),
                DataStorageMainMenuItem({ app, plugin }),
            ],
            model: DataStorageModel({ app, plugin }),
        };
    },
};
