
export const workspaceTestTemplate = function template(app) {
    return {
        type: 'template',
        target: 'interactive-analysis',
        route: {
            type: 'petron',
            path: 'apps/workspaces/test',
            name: 'workspace-test',
        },
        options() {
            return {
                debug: true,
                title: 'Test Workspace',
                forgeResources: [],
                visualizationsDirty: false,
                sections: [
                    {
                        type: 'text',
                        props: {
                            value: 'Type in a forge resource to test. Make sure it looks like $root/Name#Resource.',
                        },
                    },
                    {
                        debug: true,
                        type: 'auto-form',
                        props: {
                            value: this.testWorkspaceForm,
                            initialValue: { route: 'Enter Route...' },
                            checkLoading: false,
                            schema: {
                                dense: true,
                                inputs: [
                                    {
                                        key: 'route',
                                        testId: 'TestWorkspace_Route',
                                        inputType: 'text',
                                        label: 'Workspace Route',
                                        validationRules: [
                                            (value) => (!!value && value.length > 0) || 'Please provide a workspace route',
                                        ],
                                    },
                                ],
                            },
                        },
                        events: {
                            input: (value) => {
                                this.testWorkspaceForm = value;
                            },
                            validate: (value) => {
                                this.testFormIsValid = value;
                            },
                        },
                    },
                ],
                indicators: [

                ],
                visualizations: [
                    {
                        title: 'Workspace',
                        gridArea: '1/1/13/13',
                        type: 'custom',
                        componentType: 'WorkspaceStatus',
                        props: {
                            resource: this.testResource,
                            parseFunc: this.parseResource,
                            subscribeFunc: this.subscribeElements,
                            getWorkspaceFunc: this.getWorkspace,
                            tableParentId: this.petronId,
                        },
                    },
                ],
                rightActionButtons: [
                    {
                        props: {
                            label: 'Close',
                            testId: 'TestWorkspace_Close',
                            tooltip: 'Return to Apps',
                            flat: true,
                            outline: false,
                            color: 'primary',
                            to: { name: 'petron-apps' },
                        },
                    },
                    {
                        props: {
                            label: 'Load',
                            testId: 'TestWorkspace_Load',
                            tooltip: 'Load workspace',
                            flat: false,
                            outline: false,
                            disabled: false,
                            color: 'primary',
                        },
                        events: {
                            click: () => {
                                this.testResource = this.testWorkspaceForm.route;
                            },
                        },
                    },
                ],
            };
        },
    };
};
