<template>
    <q-card
        v-if="!hide"
        v-cypress="'GlobalAppCard_Card_Body'"
        class="bg-grey-2"
    >
        <PRouterLink
            :router-to="getAppLaunchRoute()"
        >
            <q-card-section
                class="row items-center q-gutter-x-md"
            >
                <LetterAvatar
                    :acronym="displayInitials"
                    :label="title"
                    :acronym-length="displayInitials ? displayInitials.length : 2"
                />
                <div class="col">
                    <div
                        v-cypress="'GlobalAppCard_AppTitle_Div'"
                        class="q-py-sm text-primary text-bold"
                    >
                        {{ title }}
                    </div>
                    <div class="text-grey-8">
                        {{ description }}
                    </div>
                </div>
            </q-card-section>
        </PRouterLink>
    </q-card>
</template>

<script>
import LetterAvatar from '../../plugin-framework/shared-components/LetterAvatar.vue';
import PRouterLink from '../../plugin-framework/services/pai-components/PRouterLink.vue';

export default {
    name: 'GlobalAppCard',
    components: {
        PRouterLink,
        LetterAvatar,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        launchRoute: {
            type: [Object, String],
            required: true,
        },
        description: {
            type: String,
            required: false,
            default: '',
        },
        displayInitials: {
            type: String,
            required: false,
            default: null,
        },
        hide: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    methods: {
        getAppLaunchRoute() {
            if (this.launchRoute instanceof Object) {
                return this.launchRoute;
            }
            // Support a simple String, for backwards compatibility. TODO: Remove this later.
            return {
                name: this.launchRoute,
            };
        },
    },
};
</script>

<style scoped>

</style>
