import Vue from 'vue';

const usersModule = {
    namespaced: true,
    state: {
        users: [],
    },
    getters: {
        getUser: state => userId => {
            if (state.users !== null && state.users.length > 0) {
                return state.users.find(user => user.userId === userId);
            }
            return null;
        },
    },
    mutations: {
        setUsers(state, users) {
            state.users = users;
        },
    },
    actions: {
        getOrgUsers({ commit }) {
            return Vue.$api.data.query({ type: 'User', query: {} })
            .then(response => {
                if (!response || !response.data) {
                    Vue.$users.log.error('Cloud not load org users. Response from find was empty.');
                    return;
                }
                const users = response.data;
                Object.freeze(users);
                commit('setUsers', users);
            })
            .catch(error => {
                Vue.$notify.error(`Could not load Org users: ${error.message}`, { timeout: 0 });
            });
        },
    },
};

export default usersModule;
