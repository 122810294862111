<template>
    <div
        v-if="!unlimited"
        class="q-pb-md"
    >
        <div class="row">
            <div>
                {{ $utils.formatting.formatBytes(usedStorage) }} used
            </div>

            <q-space/>

            <div>
                {{ $utils.formatting.formatBytes(maxStorage) }} total
            </div>
        </div>
        <q-linear-progress
            :value="fractionUsed"
            size="2rem"
            :color="progressColor"
        />
    </div>
</template>

<script>
export default {
    name: 'DataStorageIndicator',
    props: {
        usedStorage: {
            validator: (value) => typeof value === 'number' || value === undefined,
            required: true,
        },
        maxStorage: {
            validator: (value) => typeof value === 'number' || value === undefined,
            required: true,
        },
        overLimit: {
            type: Boolean,
            required: true,
        },
        approachingLimit: {
            type: Boolean,
            required: true,
        },
        fractionUsed: {
            validator: (value) => typeof value === 'number' || value === undefined,
            required: true,
        },
        unlimited: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        progressColor() {
            if (this.overLimit) {
                return 'error';
            }
            if (this.approachingLimit) {
                return 'warning';
            }
            return 'secondary';
        },
    },
};
</script>

<style>

</style>
