<template>
    <div
        v-if="forgeResourcesLoaded"
        class="column full-height q-pl-lg"
    >
        <div class="col-auto text-grey-7">
            <div class="q-px-lg q-pt-lg">
                <UserFullName
                    v-cypress="'AppSocialMenu_UserFullName'"
                    :user="currentUser"
                    class="text-secondary text-bold text-h4"
                />
                <div class="q-pb-md">
                    {{ currentUserEmail }}
                </div>
                <q-separator color="grey-3"/>
            </div>
            <div class="row">
                <q-space/>
                <GeneralButton
                    v-cypress="'AppSocialMenu_MarkAllRead'"
                    flat
                    dense
                    tooltip="Mark all unread notifications as read"
                    class="q-px-md q-py-sm button-link"
                    :disabled="unseenNotifications.length === 0"
                    @click.native="markAllRead"
                >
                    <span class="underline">Mark all as read</span>
                </GeneralButton>
            </div>
        </div>
        <q-scroll-area
            class="col"
        >
            <q-list
                v-cypress="'AppSocialMenu_Notifications_List'"
            >
                <q-item
                    v-for="notification in forgeUserNotifications"
                    :key="notification.id"
                    class="q-my-sm"
                    clickable
                    @click="navigateToSource(notification)"
                >
                    <q-item-section>
                        <div>
                            <NotificationDisplayItem
                                :notification="notification"
                                class="q-pb-sm"
                            />
                            <q-separator color="grey-3"/>
                        </div>
                    </q-item-section>
                </q-item>
            </q-list>
        </q-scroll-area>
        <div
            class="col-auto row q-pt-md"
        >
            <q-space/>
            <GeneralButton
                v-cypress="'AppSocialMenu_NotificationSettings_Btn'"
                flat
                dense
                label="Notification Settings"
                tooltip="Modify notification settings"
                class="q-px-md text-grey-7 button-link"
                :to="{ name: 'notifications' }"
            />
        </div>
        <div
            class="col-auto row q-pb-md"
        >
            <q-space/>
            <GeneralButton
                v-cypress="'AppSocialMenu_SignOut_Btn'"
                v-ripple
                flat
                dense
                tooltip="Sign out"
                class="q-px-md button-link"
                @click.native="logout"
            >
                &nbsp;Sign Out
            </GeneralButton>
        </div>
    </div>
    <ForgeLoadState
        v-else
        :resources="forgeResourceElements"
        class="text-white fit dark-panel"
    />
</template>

<script>
import mixins from '../mixins';
import NotificationDisplayItem from './NotificationDisplayItem';
import ForgeLoadState from '../plugin-framework/shared-components/ForgeLoadState.vue';
import GeneralButton from '../plugin-framework/shared-components/inline-elements/GeneralButton.vue';

export default {
    name: 'AppSocialMenu',
    components: {
        ForgeLoadState,
        NotificationDisplayItem,
        GeneralButton,
    },
    mixins: [mixins.compute],
    data() {
        return {
        };
    },
    computed: {
        workspace() {
            return `$root/Notifications/User/${this.$auth.user.userId}`;
        },
        notificationsResource() {
            return `${this.workspace}#NewestUserNotifications`;
        },
        forgeResources() {
            return [this.notificationsResource];
        },
        forgeUserNotifications() {
            const forgeNotifications = this.forgeElement(this.notificationsResource);
            if (forgeNotifications && forgeNotifications.data && forgeNotifications.data.length) {
                return forgeNotifications.data[0].viewRecords;
            }
            return [];
        },
        unseenNotifications() {
            return this.forgeUserNotifications.filter(notification => this.isUnseenNotification(notification));
        },
        currentUser() {
            return this.$auth.user;
        },
        currentUserEmail() {
            return this.currentUser.sub || '';
        },
    },
    methods: {
        logout() {
            this.$auth.signOut();
            this.$router.push({ name: 'sign-in' });
        },
        isAddCollaboratorNotification(notification) {
            return (notification.notificationObject.sourceEntity === 'AddCollaboratorTask');
        },
        isCommentNotification(notification) {
            return (notification.notificationObject.sourceDataType === 'Comment');
        },
        isJobResultNotification(notification) {
            return (notification.notificationObject.sourceDataType === 'JobResult');
        },
        isUnseenNotification(notification) {
            return (notification.status === 'Unseen');
        },
        markRead(notification) {
            this.$api.data.update({
                type: 'PlatformNotification',
                body: {
                    status: 'Read',
                },
                query: {
                    id: notification.id,
                },
            }).catch(issue => {
                this.$notify.error(`Notification could not be updated: ${issue}`);
            });
        },
        markAllRead() {
            if (this.unseenNotifications.length) {
                const promises = this.unseenNotifications.map(notification => this.$api.data.update({
                    type: 'PlatformNotification',
                    body: {
                        status: 'Read',
                    },
                    query: {
                        id: notification.id,
                    },
                }));
                Promise.all(promises).then(response => {
                    this.$notify.success('Notifications updated');
                }).catch(issue => {
                    this.$notify.error(`Notifications could not be updated: ${issue}`);
                });
            }
        },
        navigateToSource(notification) {
            if (this.isUnseenNotification(notification)) {
                this.markRead(notification);
            }
            if (this.isCommentNotification(notification)) {
                const notificationSource = notification.notificationObject.document;
                this.$pai.triggerPluginAction('resolveComment', [notificationSource.pluginId], notificationSource);
            }
            else if (this.isAddCollaboratorNotification(notification)) {
                const petronId = notification.notificationObject.document.parentId;
                this.$router.push({
                    name: 'petron-overview',
                    params: {
                        petronId,
                    },
                });
            }
            else if (this.isJobResultNotification(notification)) {
                const notificationSource = notification.notificationObject.document;
                this.$pai.triggerPluginAction('resolveJobResult', null, notificationSource);
            }
        },
    },
};
</script>

<style scoped>
.button-link:hover {
    text-decoration: underline;
}
</style>
