<template>
    <div
        v-cypress="testId"
    >
        <div
            class="col"
        >
            <span
                :class="{'text-grey-7' : !dark}"
            >
                {{ label }}
            </span>
            <div
                v-if="showSlider"
                class="q-px-lg"
            >
                <q-range
                    :value="sliderRange"
                    :min="rangeMin"
                    :max="rangeMax"
                    :step="rangeStep"
                    :disable="disable || !(rangeMax - rangeMin > 0)"
                    :readonly="readonly"
                    label
                    :dense="dense"
                    :color="dark ? 'grey-6' : undefined"
                    @change="(value) => sliderRange = value"
                />
            </div>
            <div class="row">
                <div class="col q-mr-md">
                    <q-input
                        :value="rangeLow"
                        filled
                        :dense="dense"
                        :debounce="debounce"
                        label="Low"
                        :disable="disable"
                        :dark="dark"
                        :readonly="readonly"
                        @input="(value) => rangeLowFocusValue = value"
                        @blur="rangeLowBlurValidate"
                        @keyup.enter="rangeLowBlurValidate"
                    >
                        <template v-slot:append>
                            {{ append }}
                        </template>
                    </q-input>
                    <q-tooltip
                        :delay="750"
                    >
                        {{ lowTooltip }}
                    </q-tooltip>
                </div>
                <div class="col">
                    <q-input
                        :value="rangeHigh"
                        label="High"
                        filled
                        :debounce="debounce"
                        :disable="disable"
                        :dense="dense"
                        :dark="dark"
                        :readonly="readonly"
                        @input="(value) => rangeHighFocusValue = value"
                        @blur="rangeHighBlurValidate"
                        @keyup.enter="rangeHighBlurValidate"
                    >
                        <template v-slot:append>
                            {{ append }}
                        </template>
                    </q-input>
                    <q-tooltip
                        :delay="750"
                    >
                        {{ highTooltip }}
                    </q-tooltip>
                </div>
            </div>
        </div>
        <div class="row q-px-md q-pt-sm hint-error-row">
            <div
                v-if="!validationErrors.length"
                class="hint-text"
            >
                {{ hint }}
            </div>
            <AutoFormValidationErrors
                v-else
                class="text-error"
                :errors="validationErrors"
            />
        </div>
    </div>
</template>

<script>
import { formatting } from '../../services/utils/formatting-utils.js';
import AutoFormValidationErrors from './AutoFormValidationErrors.vue';

export default {
    name: 'AutoFormNumberRange',
    components: {
        AutoFormValidationErrors,
    },
    props: {
        value: {
            validator(value) {
                return true;
            },
            required: false,
            default() {
                return null;
            },
        },
        testId: {
            type: String,
            required: false,
            default: null,
        },
        rangeLimits: {
            type: Array,
            required: true,
        },
        debounce: {
            type: Number,
            required: false,
            default: 500,
        },
        label: {
            type: String,
            required: false,
            default: null,
        },
        hint: {
            type: String,
            required: false,
            default: null,
        },
        dense: {
            type: Boolean,
            required: false,
            default: true,
        },
        disable: {
            type: Boolean,
            required: false,
            default: false,
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false,
        },
        append: {
            type: String,
            required: false,
            default: null,
        },
        isInteger: {
            type: Boolean,
            required: false,
            default: false,
        },
        errors: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },
        dark: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideSlider: {
            type: Boolean,
            required: false,
            default: false,
        },
        lowInputTooltip: {
            type: String,
            required: false,
            default: null,
        },
        highInputTooltip: {
            type: String,
            required: false,
            default: null,
        },
        step: {
            type: Number,
            required: false,
            default: undefined,
        },
    },
    data() {
        return {
            rangeLowFocusValue: null,
            rangeHighFocusValue: null,
        };
    },
    computed: {
        showSlider() {
            return !this.hideSlider && this.rangeLimits && this.rangeLimits.length > 1;
        },
        rangeErrors() {
            if (!this.rangeLimits || !this.rangeLimits.length > 1) {
                // Range errors only apply when there's a range.
                return [];
            }
            if (this.rangeLimits[0] > this.rangeLimits[1]) {
                return ['"rangeLimits" property low value must be less than or equal to high value.'];
            }
            const rangeErrors = [];
            if (this.rangeLow < this.rangeLimits[0]) {
                rangeErrors.push(`Low value is lower than lower range limit of ${this.rangeLimits[0]}.`);
            }
            if (this.rangeLow > this.rangeLimits[1]) {
                rangeErrors.push(`Low value is higher than upper range limit of ${this.rangeLimits[1]}.`);
            }
            if (this.rangeHigh < this.rangeLimits[0]) {
                rangeErrors.push(`High value is lower than lower range limit of ${this.rangeLimits[0]}.`);
            }
            if (this.rangeHigh > this.rangeLimits[1]) {
                rangeErrors.push(`High value is higher than upper range limit ${this.rangeLimits[1]}.`);
            }
            if (this.rangeLow > this.rangeHigh) {
                rangeErrors.push('Low value is greater than high value.');
            }
            return rangeErrors;
        },
        validationErrors() {
            return [...this.rangeErrors, ...this.errors];
        },
        rangeLow: {
            get() {
                const rangeLow = (this.value === null) ? this.rangeLimits[0] : this.value[0];
                return rangeLow;
            },
            set(value) {
                this.setRangeLowValue(value);
            },
        },
        rangeHigh: {
            get() {
                const rangeHigh = (this.value === null) ? this.rangeLimits[1] : this.value[1];
                return rangeHigh;
            },
            set(value) {
                this.setRangeHighValue(value);
            },
        },
        sliderRange: {
            get() {
                let min = Math.max(this.rangeLow, this.rangeMin);
                min = Math.min(min, this.rangeMax);
                let max = Math.min(this.rangeHigh, this.rangeMax);
                max = Math.max(max, this.rangeMin);
                return {
                    min,
                    max,
                };
            },
            set(value) {
                if (value.min !== this.rangeLow) {
                    this.rangeLow = value.min;
                }
                else if (value.max !== this.rangeHigh) {
                    this.rangeHigh = value.max;
                }
            },
        },
        rangeMin() {
            return (this.rangeLimits && this.rangeLimits.length) ? this.rangeLimits[0] : 0;
        },
        rangeMax() {
            return (this.rangeLimits && this.rangeLimits.length > 1) ? this.rangeLimits[1] : 1;
        },
        rangeStep() {
            if (this.step) {
                return step;
            }

            if (Number.isNaN(this.rangeMin) || Number.isNaN(this.rangeMax)) {
                return 0;
            }

            const spread = this.rangeMax - this.rangeMin;
            if (spread === 0) {
                return 1;
            }

            let step = spread / 10;
            if (this.isInteger) {
                // The step increment needs to also be an integer.
                step = Math.max(1, Math.floor(step));
            }
            else {
                step = formatting.roundNumber(step, { roundUp: false });
            }
            return step;
        },
        lowTooltip() {
            return this.lowInputTooltip ? this.lowInputTooltip : `${this.label} (Low)`;
        },
        highTooltip() {
            return this.highInputTooltip ? this.highInputTooltip : `${this.label} (High)`;
        },
    },
    methods: {
        rangeLowBlurValidate() {
            if (this.rangeLowFocusValue !== null) {
                const numberValue = Number(this.rangeLowFocusValue);
                if (!Number.isNaN(numberValue)) {
                    this.rangeLow = this.isInteger ? Math.floor(numberValue) : numberValue;
                }
                this.rangeLowFocusValue = null;
            }
        },
        rangeHighBlurValidate() {
            if (this.rangeHighFocusValue !== null) {
                const numberValue = Number(this.rangeHighFocusValue);
                if (!Number.isNaN(numberValue)) {
                    this.rangeHigh = this.isInteger ? Math.floor(numberValue) : numberValue;
                }
                this.rangeHighFocusValue = null;
            }
        },
        setRangeLowValue(newValue) {
            // Low value changing. Make sure it's a number.
            const lowValue = Number.isNaN(newValue) ? null : newValue;

            // It's necessary to send both the low and high values, even if just one changed.
            const highValue = (this.value === null || this.value === undefined) ? this.rangeMin : this.value[1];

            this.$emit('input', [lowValue, highValue]);
        },
        setRangeHighValue(newValue) {
            // It's necessary to send both the low and high values, even if just one changed.
            const lowValue = (this.value === null || this.value === undefined) ? this.rangeMax : this.value[0];

            // High value changing. Make sure it's a number.
            const highValue = Number.isNaN(newValue) ? null : newValue;

            this.$emit('input', [lowValue, highValue]);
        },
    },
};
</script>

<style scoped>
.hint-error-row {
    min-height: 11px;
}

.hint-text {
    font-size: 11px;
    color: rgba(0,0,0,0.54);
}
</style>
