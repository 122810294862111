import ApplicationLayout from '../../../layouts/ApplicationLayout.vue';
import DefaultPageLayout from '../../../layouts/DefaultPageLayout.vue';
import TheVideoExplorerPage from '../components/TheVideoExplorerPage.vue';

export function VideoExplorerRoute({ app, plugin }) {
    return {
        // test
        type: 'fragment',
        target: 'root-route',
        options() {
            return {
                path: '/video-explorer',
                meta: {
                    auth: true,
                    pluginId: plugin.$definition.name,
                },
                component: ApplicationLayout,
                children: [
                    {
                        path: '/video-explorer',
                        components: {
                            appMain: DefaultPageLayout,
                        },
                        children: [
                            {
                                path: '',
                                name: 'video-explorer',
                                meta: {
                                    pluginId: 'VideoExplorer',
                                },
                                components: {
                                    pageContent: TheVideoExplorerPage,
                                },
                                props: {
                                    pageContent: (route) => {
                                        return {};
                                    },
                                },
                            },
                        ],
                    },
                ],
            };
        },
    };
}
