<template>
    <div class="q-mb-md">
        <q-input
            v-model="displayDate"
            filled
            :clearable="clearable"
            :dense="dense"
            :label="label"
            :hint="hint"
            :disable="disable"
            :readonly="readonly"
        >
            <template v-slot:prepend>
                <q-icon
                    name="event"
                    class="cursor-pointer"
                    :disable="disable"
                >
                    <q-popup-proxy
                        ref="qDateProxy"
                        transition-show="scale"
                        transition-hide="scale"
                    >
                        <q-date
                            v-model="displayDate"
                            :disable="disable"
                            :mask="displayDateFormat"
                            @input="closeDialog"
                        />
                    </q-popup-proxy>
                </q-icon>
            </template>
        </q-input>
    </div>
</template>

<script>

export default {
    name: 'PDatePicker',
    props: {
        value: {
            type: String || null,
            default: null,
            required: false,
        },
        label: {
            type: String,
            required: false,
            default: '',
        },
        hint: {
            type: String,
            required: false,
            default: '',
        },
        clearable: {
            type: Boolean,
            required: false,
            default: true,
        },
        dense: {
            type: Boolean,
            required: false,
            default: true,
        },
        displayDateFormat: {
            type: String,
            required: false,
            default: 'MM-DD-YYYY',
        },
        disable: {
            type: Boolean,
            required: false,
            default: false,
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false,
        },
        displayTimeZone: {
            required: true,
            validator(value) {
                return typeof value === 'string' || value === null;
            },
        },
    },
    computed: {
        compValue: {
            set(value) {
                if (!this.disable) {
                    this.setValue(value);
                }
            },
            get() {
                return this.$utils.formatting.isValidDate(this.value) ? this.value : null;
            },
        },
        displayDate: {
            get() {
                if (this.value === '' || this.compValue === null) {
                    return null;
                }
                return this.$utils.formatting.formatDate(this.compValue, this.displayTimeZone, this.displayDateFormat);
            },
            set(value) {
                this.compValue = value;
            },
        },
    },
    methods: {
        setValue(value) {
            if (value === null) {
                this.$emit('input', '');
            }
            if (this.$utils.formatting.isValidDate(value)) {
                this.$emit('input', value);
            }
        },
        closeDialog() {
            this.$refs.qDateProxy.hide();
        },
    },
};
</script>

<style scoped>

</style>
