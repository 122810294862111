import { Mention as TipTapMention } from 'tiptap-extensions';

// Class to allow the use of custom mentions within tiptap
// Extends then overrides Mention class
// See https://github.com/scrumpy/tiptap/issues/11
export default class Mention extends TipTapMention {
    get name() {
        return 'mention';
    }

    get defaultOptions() {
        return {
            matcher: {
                char: '@',
                allowSpaces: false,
                startOfLine: false,
            },
            mentionClass: 'mention',
            suggestionClass: 'mention-suggestion',
        };
    }

    get schema() {
        return {
            ...super.schema,
            // Implement our own dom structure using links
            toDOM: (node) => [
                // Tag
                'a',
                // Attributes
                {
                    class: this.options.mentionClass,
                    href: `/data/user/${node.attrs.id}`,
                    rel: 'noopener noreferrer nofollow',
                    'data-user-id': node.attrs.id,
                    target: '_blank',
                },
                // Content
                `${this.options.matcher.char}${node.attrs.label}`,
            ],
            parseDOM: [
                {
                    tag: 'a[data-user-id]',
                    getAttrs: (dom) => {
                        const id = dom.getAttribute('data-user-id');
                        const label = dom.innerText.split(this.options.matcher.char).join('');
                        return { id, label };
                    },
                },
            ],
        };
    }
}
