import ApplicationLayout from '../../../layouts/ApplicationLayout.vue';
import DefaultPageLayout from '../../../layouts/DefaultPageLayout.vue';
import ForgeTableTemplate from '../../../plugin-framework/templates/ForgeTableTemplate.vue';
import JobFrequencyForm from '../components/JobFrequencyForm.vue';
import FullPageTemplate from '../../../plugin-framework/templates/FullPageTemplate.vue';

export function JobsRoute({ app, plugin }) {
    return {
        type: 'fragment',
        target: 'root-route',
        options() {
            return {
                path: '/data/jobs',
                meta: {
                    auth: true,
                    pluginId: plugin.$definition.name,
                },
                component: ApplicationLayout,
                children: [
                    {
                        path: '',
                        components: {
                            appMain: DefaultPageLayout,
                        },
                        children: [
                            {
                                path: '',
                                name: 'jobs',
                                components: {
                                    pageContent: ForgeTableTemplate,
                                },
                                props: {
                                    pageContent: (route) => {
                                        return {
                                            props: {
                                                columnOptions: this.jobsColumnOptions,
                                                forgeResources: this.jobsTableForgeResources,
                                                headerLeftInlineElements: [
                                                    {
                                                        props: {
                                                            type: 'text',
                                                            value: 'Jobs',
                                                        },
                                                    },
                                                ],
                                                headerRightInlineElements: [
                                                    {
                                                        props: {
                                                            type: 'button',
                                                            label: 'Create Job',
                                                            tooltip: 'Create a new job',
                                                            color: 'primary',
                                                            flat: false,
                                                            outline: false,
                                                            testId: 'JobsTable_CreateJob_Button',
                                                        },
                                                        events: {
                                                            click: () => {
                                                                this.editNewJob();
                                                            },
                                                        },
                                                    },
                                                ],
                                                parentId: 'Jobs',
                                                tableOptions: this.jobsTableOptions,
                                                tableType: this.jobsTableType,
                                                tableView: this.jobsTableView,
                                                markingWorkspace: this.jobWorkspace,
                                                topRowButtons: [
                                                    {
                                                        props: {
                                                            label: 'Run Now',
                                                            tooltip: 'Start the job now',
                                                            disabled: !this.canRunJob,
                                                            testId: 'JobsTable_RunNow_Button',
                                                        },
                                                        events: {
                                                            click: ({ selectedIds }) => {
                                                                this.runSelectedJob();
                                                            },
                                                        },
                                                    },
                                                    {
                                                        props: {
                                                            label: 'Edit',
                                                            tooltip: 'Edit the job',
                                                            hide: this.selectedJobs.length !== 1,
                                                            testId: 'JobsTable_Edit_Button',
                                                        },
                                                        events: {
                                                            click: () => {
                                                                this.editSelectedJob();
                                                            },
                                                        },
                                                    },
                                                    {
                                                        props: {
                                                            label: 'View Results',
                                                            tooltip: 'View job results',
                                                            hide: this.selectedJobs.length !== 1,
                                                            testId: 'JobsTable_ViewResults_Button',
                                                        },
                                                        events: {
                                                            click: () => {
                                                                this.viewJobResults();
                                                            },
                                                        },
                                                    },
                                                    {
                                                        props: {
                                                            label: 'Duplicate',
                                                            tooltip: 'Create a duplicate of the job',
                                                            hide: this.selectedJobs.length !== 1,
                                                            testId: 'JobsTable_Duplicate_Button',
                                                        },
                                                        events: {
                                                            click: () => {
                                                                this.duplicateSelectedJob();
                                                            },
                                                        },
                                                    },
                                                    {
                                                        props: {
                                                            label: 'Delete',
                                                            tooltip: 'Delete selected jobs',
                                                            testId: 'JobsTable_Delete_Button',
                                                        },
                                                        events: {
                                                            click: () => {
                                                                this.showDeleteJobsDialog = true;
                                                            },
                                                        },
                                                    },
                                                ],
                                            },
                                            events: {
                                                tableOptions: (newOptions) => {
                                                    this.jobsTableOptions = newOptions;
                                                },
                                                selection: selectedIds => {
                                                    this.selectedJobs = selectedIds;
                                                },
                                            },
                                        };
                                    },
                                },
                            },
                            {
                                path: 'job-editor',
                                name: 'job-editor',
                                components: {
                                    pageContent: FullPageTemplate,
                                },
                                props: {
                                    pageContent: (route) => {
                                        // When the page loads get the available tasks
                                        if (this.allTasks.length === 0) {
                                            app.$api.tasks.getTasks()
                                            .then(response => {
                                                const allTasks = response.data;
                                                allTasks.sort((a, b) => a.display.localeCompare(b.display));
                                                this.allTasks = allTasks;
                                            })
                                            .catch(error => {
                                                app.$notify.error(`Could not retrieve the list of job types: ${error.message}`);
                                            });
                                        }

                                        return {
                                            props: {
                                                visualizationsDirty: false,
                                                leftHeaderButtons: [],
                                                sections: [
                                                    {
                                                        type: 'text',
                                                        props: {
                                                            value: 'Define Job',
                                                            class: 'text-h6 text-primary',
                                                        },
                                                    },
                                                    {
                                                        type: 'auto-form',
                                                        props: {
                                                            value: this.editJobNamePage,
                                                            initialValue: {},
                                                            testId: 'JobEditor_Name_Form',
                                                            schema: {
                                                                dense: true,
                                                                inputs: [
                                                                    {
                                                                        key: 'name',
                                                                        inputType: 'text',
                                                                        label: 'Enter job name',
                                                                        clearable: false,
                                                                        testId: 'JobEditor_JobName_Text',
                                                                        validationRules: [
                                                                            (value) => value.trim().length > 0 || 'The job must have a name',
                                                                        ],
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        events: {
                                                            input: (value) => {
                                                                this.editJobNamePage = value;
                                                            },
                                                            validate: (value) => {
                                                                this.editNamePageIsValid = value;
                                                            },
                                                        },
                                                    },
                                                    {
                                                        type: 'custom',
                                                        componentType: JobFrequencyForm,
                                                        props: {
                                                            schedule: this.editJobSettingsPage.schedule,
                                                        },
                                                        events: {
                                                            schedule: (value) => {
                                                                this.editJobSettingsPage.schedule = value;
                                                            },
                                                        },
                                                    },
                                                    {
                                                        type: 'auto-form',
                                                        props: {
                                                            value: this.editJobSettingsPage,
                                                            initialValue: {},
                                                            testId: 'JobEditor_Settings_Form',
                                                            schema: {
                                                                dense: true,
                                                                inputs: [
                                                                    {
                                                                        key: 'showSystemTasks',
                                                                        inputType: 'boolean',
                                                                        label: 'Show system-level jobs',
                                                                        testId: 'JobEditor_SystemJobs_Boolean',
                                                                    },
                                                                    {
                                                                        key: 'taskName',
                                                                        inputType: 'select',
                                                                        options: this.typeOptions,
                                                                        optionValue: 'taskName',
                                                                        optionLabel: 'display',
                                                                        label: 'Select job type',
                                                                        multiple: false,
                                                                        useChips: false,
                                                                        clearable: false,
                                                                        optionCategories: true,
                                                                        testId: 'JobEditor_JobType_Select',
                                                                        validationRules: [
                                                                            (value) => !!value || 'Please select a job type',
                                                                        ],
                                                                    },
                                                                    {
                                                                        key: 'taskOptions',
                                                                        inputType: 'json',
                                                                        label: 'Job Options',
                                                                        minLines: 10,
                                                                        maxLines: 10,
                                                                        testId: 'JobEditor_JobOptions_JSONEditor',
                                                                        canMaximize: true,
                                                                        allowVariablesInsert: true,
                                                                        validationRules: [
                                                                            async (value) => {
                                                                                const taskName = this.editJobSettingsPage.taskName;
                                                                                if (!taskName) {
                                                                                    return true;
                                                                                }
                                                                                const result = await this.getValidation({
                                                                                    type: taskName,
                                                                                    options: value,
                                                                                });
                                                                                return result.length ? result : true;
                                                                            },
                                                                        ],
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        events: {
                                                            input: (value) => {
                                                                if (value.taskName && value.taskName !== this.editJobSettingsPage.taskName) {
                                                                    // Load new template.
                                                                    value.taskOptions = this.taskOptions(value.taskName);
                                                                }
                                                                if (this.editJobSettingsPage.showSystemTasks !== value.showSystemTasks) {
                                                                    // Clear the template.
                                                                    value.taskName = '';
                                                                    value.taskOptions = {};
                                                                }
                                                                this.editJobSettingsPage = value;
                                                            },
                                                            validate: (value) => {
                                                                this.editSettingsPageIsValid = value;
                                                            },
                                                        },
                                                    },
                                                ],
                                                actionButtons: [
                                                    {
                                                        props: {
                                                            label: 'Cancel',
                                                            tooltip: 'Do not save any changes',
                                                            flat: true,
                                                            outline: false,
                                                            color: 'primary',
                                                            to: { name: 'jobs' },
                                                            testId: 'JobEditor_Cancel_Button',
                                                        },
                                                    },
                                                    {
                                                        props: {
                                                            label: 'Save & Close',
                                                            tooltip: (!this.editNamePageIsValid || !this.editSettingsPageIsValid) ? 'Please correct the errors' : 'Save Job',
                                                            flat: false,
                                                            outline: false,
                                                            color: (this.editNamePageIsValid && this.editSettingsPageIsValid) ? 'primary' : 'grey',
                                                            disabled: (!this.editNamePageIsValid || !this.editSettingsPageIsValid),
                                                            testId: 'JobEditor_SaveClose_Button',
                                                        },
                                                        events: {
                                                            click: () => {
                                                                this.saveJob();
                                                            },
                                                        },
                                                    },
                                                ],
                                            },
                                            events: {},
                                        };
                                    },
                                },
                            },
                            {
                                path: 'job-results/:jobId',
                                name: 'job-results',
                                components: {
                                    pageContent: ForgeTableTemplate,
                                },
                                props: {
                                    pageContent: (route) => {
                                        return {
                                            props: {
                                                columnOptions: this.resultsColumnOptions,
                                                forgeResources: this.resultsTableForgeResources,
                                                headerLeftInlineElements: [
                                                    {
                                                        props: {
                                                            type: 'text',
                                                            value: `Results for "${this.currentJob ? this.currentJob.name : ''}"`,
                                                        },
                                                    },
                                                ],
                                                parentId: 'Jobs',
                                                tableOptions: this.resultsTableOptions,
                                                tableType: this.resultsTableType,
                                                tableView: this.resultsTableView,
                                                markingWorkspace: this.resultsWorkspace,
                                                topRowButtons: [
                                                    {
                                                        props: {
                                                            label: 'View Log',
                                                            tooltip: 'Display the job results log',
                                                            disabled: this.selectedResults.length !== 1,
                                                            testId: 'JobResultsTable_ViewLog_Button',
                                                        },
                                                        events: {
                                                            click: () => {
                                                                this.viewLogs();
                                                            },
                                                        },
                                                    },
                                                    {
                                                        props: {
                                                            label: 'Delete',
                                                            tooltip: 'Delete selected job results',
                                                            disabled: this.selectedResults.length === 0,
                                                            testId: 'JobResultsTable_Delete_Button',
                                                        },
                                                        events: {
                                                            click: () => {
                                                                this.showDeleteJobResultsDialog = true;
                                                            },
                                                        },
                                                    },
                                                ],
                                            },
                                            events: {
                                                tableOptions: (newOptions) => {
                                                    this.resultsTableOptions = newOptions;
                                                },
                                                selection: selectedIds => {
                                                    this.selectedResults = selectedIds;
                                                },
                                            },
                                        };
                                    },
                                },
                            },
                        ],
                    },
                ],
            };
        },
    };
}
