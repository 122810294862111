<template>
    <div class="actions-wrapper column q-px-md">
        <div class="col-auto">
            <q-tabs
                v-model="tab"
                align="left"
                indicator-color="accent"
                class="petro-font-bold"
                dense
                narrow-indicator
            >
                <q-tab
                    v-for="type in types"
                    :key="type.name"
                    :name="type.name"
                    :label="type.label"
                    no-caps
                />
            </q-tabs>
        </div>
        <q-tab-panels
            v-model="tab"
            animated
            class="col q-pb-lg"
        >
            <q-tab-panel
                v-for="type in types"
                :key="type.name"
                :name="type.name"
            >
                <div class="row justify-center full-height">
                    <div class="col-10 column">
                        <div
                            v-if="recommendedActions.length"
                            class="col-auto"
                        >
                            <div
                                class="q-pb-sm text-grey-8"
                            >
                                Recommended
                            </div>
                            <div
                                class="row q-col-gutter-md"
                            >
                                <ActionCard
                                    v-for="action in recommendedActions"
                                    :key="`recommend-${action.label}`"
                                    :title="action.label"
                                    :tags="action.tags"
                                    :icon="action.icon"
                                    :description="action.description"
                                    :disabled="!isValid(action)"
                                    :tooltip="getActionTooltip(action, items)"
                                    :selected="selectedAction && selectedAction.label === action.label"
                                    :is-create-action="action.category === 'Create'"
                                    class="col-3"
                                    @click="actionClicked(action, tab, currentTabItems)"
                                />
                            </div>
                            <div
                                class="row q-py-lg"
                            >
                                <q-separator
                                    color="grey-5"
                                />
                            </div>
                        </div>
                        <q-scroll-area
                            class="col"
                            visible
                        >
                            <div
                                v-for="category in orderedCategories"
                                :key="`category-${category}`"
                            >
                                <div
                                    v-if="categorizedActions[category] && categorizedActions[category].length"
                                    class="q-pb-sm text-grey-8"
                                >
                                    {{ category }}
                                </div>
                                <div
                                    v-if="categorizedActions[category]"
                                    class="row q-col-gutter-md q-pb-md"
                                >
                                    <ActionCard
                                        v-for="action in categorizedActions[category]"
                                        :key="action.label"
                                        :title="action.label"
                                        :tags="action.tags"
                                        :icon="action.icon"
                                        :description="action.description"
                                        :disabled="!isValid(action)"
                                        :tooltip="getActionTooltip(action, items)"
                                        :selected="selectedAction && selectedAction.label === action.label"
                                        :is-create-action="category === 'Create'"
                                        class="col-3"
                                        @click="actionClicked(action, tab, currentTabItems)"
                                    />
                                </div>
                            </div>
                        </q-scroll-area>
                    </div>
                </div>
            </q-tab-panel>
        </q-tab-panels>
        <div class="col-auto">
            <div
                v-if="selectedAction && !selectedAction.global"
                class="text-h6 petro-font-bold text-primary row"
            >
                Select a Petron
            </div>
            <div
                v-if="selectedAction && !selectedAction.global"
                class="row"
            >
                <q-list
                    dense
                    class="col-4"
                >
                    <q-item>
                        <q-item-section avatar>
                            <q-radio
                                v-model="petronContext"
                                val="existing"
                            />
                        </q-item-section>

                        <q-item-section>
                            <q-select
                                v-model="selectedPetronId"
                                label="Select existing Petron"
                                :options="petronOptions"
                                option-label="name"
                                option-value="id"
                                emit-value
                                map-options
                                dense
                                clearable
                                @focus="petronContext = 'existing'"
                            />
                        </q-item-section>
                    </q-item>

                    <q-item>
                        <q-item-section avatar>
                            <q-radio
                                v-model="petronContext"
                                val="new"
                            />
                        </q-item-section>

                        <q-item-section>
                            <q-input
                                v-model="newPetronName"
                                label="Create a new Petron (type name)"
                                dense
                                clearable
                                @focus="petronContext = 'new'"
                            />
                        </q-item-section>
                    </q-item>
                </q-list>
            </div>
            <div
                v-if="selectedAction && !selectedAction.global"
                class="row justify-end"
            >
                <GeneralButton
                    color="accent"
                    text-color="primary"
                    :flat="false"
                    :outline="false"
                    :disabled="!canLaunchAction"
                    label="Launch Action"
                    :tooltip="canLaunchAction ? 'Launch action in selected Petron' : 'Select an action and Petron'"
                    class="text-bold border-primary"
                    @click="launchAction(selectedAction, tab, currentTabItems)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import mixins from '../../../mixins';
import ActionCard from './ActionCard';
import GeneralButton from '../../../plugin-framework/shared-components/inline-elements/GeneralButton.vue';

export default {
    name: 'ActionsList',
    components: {
        ActionCard,
        GeneralButton,
    },
    mixins: [mixins.compute],
    props: {
        actions: {
            type: Array,
            required: true,
        },
        actionTypes: {
            type: Array,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            tab: (this.actionTypes.length > 0) ? this.actionTypes[0] : null,
            selectedAction: null,
            selectedPetronId: null,
            petronContext: 'existing',
            newPetronName: null,
            orderedCategories: [
                'Create',
                'View',
                'Update',
                'Other',
            ],
        };
    },
    computed: {
        ...mapGetters('Library', {
            ownerCollaboratorPetrons: 'ownerCollaboratorPetrons',
            favoritePetrons: 'favoritePetrons',
            forgeResources: 'forgeResources',
        }),
        petronOptions() {
            const petronOptions = [...this.ownerCollaboratorPetrons];
            // Add any favorites that aren't already included.
            this.favoritePetrons.forEach(favorite => {
                if (!petronOptions.find(petron => petron.id === favorite.id)) {
                    petronOptions.push(favorite);
                }
            });
            petronOptions.sort((a, b) => a.name.localeCompare(b.name));
            return petronOptions;
        },
        currentTabItems() {
            return this.items.filter(x => x.type === this.tab);
        },
        types() {
            const items = this.actionTypes.map(actionType => {
                if (actionType) {
                    const label = actionType.replace(/([A-Z])/g, ' $1');
                    // Count the items
                    const count = this.items.filter(it => it.type === actionType).length;
                    return {
                        label: `${label} (${count})`,
                        name: actionType,
                    };
                }
                return null;
            })
            .filter(item => item !== null);

            return items;
        },
        filteredActions() {
            const filterActions = this.actions.filter(action => {
                let valid = true;
                if (action.types.length > 0) {
                    // If the types list is not empty, then the action only applies to particular types.
                    valid = valid && action.types.includes(this.tab);
                }
                return valid;
            });
            return filterActions;
        },
        categorizedActions() {
            const categorizedActions = { Other: [] };
            this.filteredActions.forEach(action => {
                const category = action.category || 'Other';
                if (!categorizedActions[category]) {
                    categorizedActions[category] = [];
                }
                categorizedActions[category].push(action);
            });
            Object.keys(categorizedActions).forEach(category => {
                categorizedActions[category].sort((a, b) => a.label.localeCompare(b.label));
            });
            return categorizedActions;
        },
        recommendedActions() {
            const recommendedActions = this.filteredActions.filter(action => action.recommendPriority);
            recommendedActions.sort((a, b) => a.recommendPriority - b.recommendPriority);
            return recommendedActions;
        },
        canLaunchAction() {
            if (this.selectedAction && this.petronContext === 'new' && this.newPetronName) {
                return true;
            }
            if (this.selectedAction && this.petronContext === 'existing' && this.selectedPetronId) {
                return true;
            }
            return false;
        },
    },
    watch: {
        types(value) {
            if (value.length > 0) {
                this.tab = value[0].name;
            }
        },
    },
    created() {
        // Set the current petron, if we are on a page with one.
        if (this.$route.params) {
            this.selectedPetronId = this.$route.params.petronId;
        }
    },
    methods: {
        actionClicked(action, type, items) {
            if (action.global) {
                this.$emit('invokeAction', {
                    action, type, items, petronId: null,
                });
                return;
            }
            this.selectedAction = action;
        },
        launchAction(action, type, items) {
            // Create a new petron is needed then call action
            if (this.petronContext === 'new') {
                this.$api.tasks.runTask({
                    runInBackground: false,
                    pid: 'null',
                    taskType: 'CreatePetron',
                    options: {
                        name: this.newPetronName,
                    },
                })
                .then(response => {
                    if (response.insertedIds.length > 0) {
                        const petronId = response.insertedIds[0];
                        this.$emit('invokeAction', {
                            action, type, items, petronId,
                        });
                    }
                    else {
                        // TODO: Handle no insertedId
                    }
                })
                .catch(error => {
                    this.$notify.error(`There was an error while creating petron: ${error}`);
                });
            }
            // Use the selected petron to launch the action
            else {
                this.$emit('invokeAction', {
                    action, type, items, petronId: this.selectedPetronId,
                });
            }
        },
        getActionTooltip(action, items) {
            if (action && action.validate) {
                const validation = action.validate({ items });
                return (typeof validation === 'string') ? validation : action.label;
            }
            return action.label;
        },
        isValid(action) {
            if (!action.validate) {
                return true;
            }
            const items = this.currentTabItems;
            if (action.validate({ items }) === true) {
                return true;
            }
            return false;
        },
    },
};
</script>

<style scoped lang="stylus">
@import '../../../css/quasar.variables.styl';
.actions-wrapper {
    height: 65vh;
}
.border-primary {
    border: solid 2px $primary;
    border-radius: 2px;
}
</style>
