import ApplicationLayout from '../../../layouts/ApplicationLayout.vue';
import DefaultPageLayout from '../../../layouts/DefaultPageLayout.vue';
import FullPageTemplate from '../../../plugin-framework/templates/FullPageTemplate.vue';

export function SettingsRootRoute({ app, plugin }) {
    return {
        type: 'fragment',
        target: 'root-route',
        options() {
            return {
                path: '/settings/application-settings',
                meta: {
                    auth: true,
                    pluginId: plugin.$definition.name,
                },
                component: ApplicationLayout,
                children: [
                    {
                        path: '',
                        components: {
                            appMain: DefaultPageLayout,
                        },
                        children: [
                            {
                                path: '',
                                name: 'application-settings',
                                components: {
                                    pageContent: FullPageTemplate,
                                },
                                props: {
                                    pageContent: (route) => {
                                        return {
                                            props: {
                                                forgeResources: this.forgeResources,
                                                sections: [
                                                    {
                                                        type: 'title',
                                                        props: {
                                                            value: 'Global Settings',
                                                        },
                                                    },
                                                    {
                                                        type: 'inline-elements',
                                                        props: {
                                                            inlineElements: [
                                                                {
                                                                    props: {
                                                                        type: 'button',
                                                                        hide: !this.canEditSettings,
                                                                        testId: 'AppSettings_EditUpdate_Button',
                                                                        flat: false,
                                                                        outline: false,
                                                                        color: 'grey-4',
                                                                        textColor: 'primary',
                                                                        label: this.disableEdits ? 'Edit' : 'Update',
                                                                        tooltip: this.disableEdits ? 'Edit settings' : 'Commit changes',
                                                                    },
                                                                    events: {
                                                                        click: () => {
                                                                            this.editUpdate();
                                                                        },
                                                                    },
                                                                },
                                                                {
                                                                    props: {
                                                                        type: 'button',
                                                                        hide: this.disableEdits,
                                                                        testId: 'AppSettings_Cancel_Button',
                                                                        flat: true,
                                                                        textColor: 'grey-7',
                                                                        label: 'Cancel',
                                                                        tooltip: 'Undo any changes',
                                                                    },
                                                                    events: {
                                                                        click: () => {
                                                                            this.cancelEdit();
                                                                        },
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                    {
                                                        type: 'auto-form',
                                                        props: {
                                                            initialValue: app.$globalSettings.settings,
                                                            value: this.globalSettingsForm,
                                                            schema: {
                                                                inputs: [
                                                                    {
                                                                        inputType: 'text',
                                                                        key: 'orgName',
                                                                        testId: 'AppSettings_OrgName_Input',
                                                                        label: 'Organization Name',
                                                                        hint: '',
                                                                        clearable: false,
                                                                        disabled: this.disableEdits,
                                                                    },
                                                                    {
                                                                        inputType: 'select',
                                                                        key: 'databaseUnitsDefinitionId',
                                                                        testId: 'AppSettings_DbUnits_Select',
                                                                        label: 'Database Units Definition',
                                                                        hint: 'Units for storing values in Petro.ai.',
                                                                        options: this.unitsDefinitions,
                                                                        clearable: false,
                                                                        disabled: this.disableEdits || !this.unitsDefinitions.length,
                                                                    },
                                                                    {
                                                                        inputType: 'select',
                                                                        key: 'reportingUnitsDefinitionId',
                                                                        testId: 'AppSettings_ReportUnits_Select',
                                                                        label: 'Reporting Units Definition',
                                                                        hint: 'Units for displaying values in Petro.ai.',
                                                                        options: this.unitsDefinitions,
                                                                        clearable: false,
                                                                        disabled: this.disableEdits || !this.unitsDefinitions.length,
                                                                    },
                                                                    {
                                                                        inputType: 'select',
                                                                        key: 'displayTimeZoneId',
                                                                        testId: 'AppSettings_TimeZone_Select',
                                                                        label: 'Time Zone',
                                                                        hint: 'IANA time zone id for displaying times in Petro.ai.',
                                                                        options: this.timeZoneOptions,
                                                                        clearable: false,
                                                                        disabled: this.disableEdits,
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        events: {
                                                            input: (value) => {
                                                                this.globalSettingsForm = { ...value };
                                                            },
                                                        },
                                                    },
                                                    {
                                                        type: 'title',
                                                        props: {
                                                            value: 'App Settings',
                                                            hide: !this.pluginAppSettings.length,
                                                        },
                                                    },
                                                    {
                                                        type: 'custom',
                                                        componentType: 'AppSettingsList',
                                                        props: {
                                                            appSettingsFragments: this.pluginAppSettings,
                                                        },
                                                    },
                                                ],
                                            },
                                            events: {},
                                        };
                                    },
                                },
                            },
                        ],
                    },
                ],
            };
        },
    };
}
