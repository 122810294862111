<template>
    <q-page class="bg-grey">
        <div class="row justify-center items-center window-height">
            <div class="col-md-3">
                <q-card>
                    <q-card-section class="text-center">
                        <div
                            v-cypress="'NotFound_404_Header'"
                            class="text-h4"
                        >
                            404
                        </div>
                        <div class="text-subtitle2">
                            Oops!
                        </div>
                        <div class="text-subtitle2">
                            The page you are looking for was not found.
                        </div>
                    </q-card-section>
                </q-card>
            </div>
        </div>
    </q-page>
</template>

<script>
export default {
    name: 'NotFound',
};
</script>

<style scoped>

</style>
