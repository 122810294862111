<template>
    <div
        v-if="!hide"
        class="q-py-lg text-center pointer menu-item"
        :class="{ 'menu-item-active': isSelected }"
        @click="clicked"
    >
        <q-icon
            :name="icon"
            size="sm"
            :color="color"
        />
        <q-tooltip :delay="750">
            {{ tooltip }}
        </q-tooltip>
    </div>
</template>

<script>
export default {
    name: 'FMainMenuItem',
    props: {
        tooltip: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            required: false,
            default: undefined,
        },
        isSelected: {
            type: Boolean,
            required: false,
            default: false,
        },
        hide: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    methods: {
        clicked() {
            this.$telemetry.trackUserEvent({
                name: this.$telemetry.eventNames.BUTTON_CLICK,
                properties: {
                    buttonLabel: this.icon,
                    buttonTooltip: this.tooltip,
                },
            });
            this.$emit('click');
        },
    },
};
</script>

<style scoped lang="stylus">
@import '../../css/quasar.variables.styl';

.menu-item:hover {
    background-color: $primary;
}
.menu-item-active {
    background-color: $primary;
}
</style>
