export const ActionsDialog = function ActionDialog({ app, plugin }) {
    return {
        type: 'fragment',
        target: 'dialog',
        priority: 1,
        options() {
            return {
                model: app.$actions.showActionDialog,
                props: {
                    title: 'Choose an action',
                    size: 'lg',
                    sections: [
                        {
                            type: 'custom',
                            componentType: 'ActionsList',
                            props: {
                                actions: app.$actions.actionFragments,
                                actionTypes: app.$actions.actionTypes,
                                items: app.$actions.actionItems,
                            },
                            events: {
                                invokeAction: ({
                                    action, type, items, petronId,
                                }) => {
                                    app.$actions.showActionDialog = false;

                                    action.invoke({ items, petronId })
                                    .then(response => {
                                        if (app.$actions.onActionSuccess && typeof app.$actions.onActionSuccess === 'function') {
                                            app.$actions.onActionSuccess(action);
                                        }
                                    })
                                    .catch(error => {
                                        if (app.$actions.onActionError && typeof app.$actions.onActionError === 'function') {
                                            app.$actions.onActionError(error);
                                        }
                                    });
                                },
                                copied: ({ message }) => {
                                    app.$notify.success(message);
                                    app.$actions.showActionDialog = false;
                                    if (app.$actions.onActionSuccess) {
                                        app.$actions.onActionSuccess(null);
                                    }
                                },
                                warning: ({ message }) => {
                                    app.$notify.error(message);
                                },
                            },
                        },
                    ],
                    actionButtons: [],
                },
                events: {
                    input: (value) => {
                        if (value === false && app.$actions.onActionError && typeof app.$actions.onActionError === 'function') {
                            app.$actions.onActionError('Actions modal closed');
                        }
                        app.$actions.showActionDialog = value;
                    },
                },
            };
        },
    };
};
