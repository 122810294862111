import { tableColumnsUnits } from './table-columns-units.js';
import { formatting } from '../../../plugin-framework/services/utils/formatting-utils.js';

export function UnitsModel({ app, plugin }) {
    return {
        fields: {
            tableType: {
                source: 'self',
                options: {
                    default: 'UnitDefinitions',
                },
            },
            allUnitDefinitions: {
                source: 'forge',
                options: {
                    workspace: 'unitWorkspace',
                    resource: 'AllUnitDefinitions',
                },
            },
            tableView: {
                source: 'forge',
                options: {
                    workspace: 'unitWorkspace',
                    resource: 'TableViews',
                    single: true,
                },
            },
            tableOptions: {
                source: 'forge',
                options: {
                    workspace: 'unitWorkspace',
                    resource: 'TableOptions',
                    single: true,
                },
            },
            selectedDefinition: {
                source: 'self',
                options: {
                    default: null,
                },
            },
            unitDefinitionId: {
                source: 'route',
            },
            columnOptions: tableColumnsUnits(app),
            showEditDialog: {
                source: 'self',
                options: {
                    default: false,
                },
            },
            editDefinitionPage: {
                source: 'self',
                options: {
                    default: {
                        id: null,
                        name: null,
                        Angle: null,
                        Area: null,
                        AreaDensity: null,
                        Density: null,
                        Duration: null,
                        Energy: null,
                        Volume: null,
                        VolumeFlow: null,
                        Mass: null,
                        Length: null,
                        Pressure: null,
                        Force: null,
                        Temperature: null,
                        Torque: null,
                        RotationalSpeed: null,
                        Speed: null,
                        Ratio: null,
                        GasRate: null,
                        GasVolume: null,
                        LiquidRate: null,
                        LiquidVolume: null,
                        BoeRate: null,
                        BoeVolume: null,
                    },
                },
            },
            editDefinitionPageIsValid: {
                source: 'self',
                options: {
                    default: false,
                },
            },
        },
        getters: {
            unitWorkspace: () => `$root/UnitDefinitions/${app.$auth.user.userId}`,
            tableForgeResources: (state, getters) => [
                getters.allUnitDefinitionsResource,
                getters.tableViewResource,
                getters.tableOptionsResource,
            ],
            unitInputSchema: (state, getters) => {
                const inputs = [
                    {
                        key: 'name',
                        inputType: 'text',
                        label: 'Name',
                        clearable: false,
                        testId: 'EditUnitDefinitionDialog_Name_Text',
                    },
                ];
                Object.keys(state.editDefinitionPage).forEach(key => {
                    if (key === 'name' || key === 'id') {
                        // These fields are special cases. Name is a text control and ID is read-only.
                        return;
                    }
                    const label = formatting.startCase(key);
                    const options = app.$units.unitOptions[label];
                    inputs.push({
                        key,
                        inputType: 'select',
                        label,
                        options,
                        clearable: false,
                        dense: true,
                        multiple: false,
                        useChips: false,
                    });
                });
                return inputs;
            },
        },
        actions: {
            addNewDefinition({ state }) {
                const newDefinition = {
                    name: 'New Units Definition',
                };
                const params = {
                    type: 'UnitsDefinition',
                    data: [
                        newDefinition,
                    ],
                };
                // Insert a blank units definition.
                app.$api.data.insert(params)
                .then((response) => {
                    if (response && response.insertedIds && response.insertedIds.length) {
                        const insertedId = response.insertedIds[0];
                        newDefinition.id = insertedId;
                        Object.keys(state.editDefinitionPage).forEach(key => {
                            state.editDefinitionPage[key] = newDefinition[key] || null;
                        });
                        state.showEditDialog = true;
                    }
                })
                .catch((issue) => {
                    app.$notify.error('Could not create new units definition:', issue);
                });
            },
            editSelectedDefinition({ state, getters }, selectedIds) {
                const definitionId = (selectedIds && selectedIds.length === 1) ? selectedIds[0] : null;
                if (!definitionId) {
                    return;
                }
                const existingDefinition = getters.allUnitDefinitions.find(definition => definitionId === definition.id);
                if (!existingDefinition) {
                    return;
                }
                Object.keys(state.editDefinitionPage).forEach(key => {
                    state.editDefinitionPage[key] = existingDefinition[key] || null;
                });
                state.showEditDialog = true;
            },
            deleteSelectedDefinition({ state, getters }, selectedIds) {
                const definitionId = (selectedIds && selectedIds.length === 1) ? selectedIds[0] : null;
                if (!definitionId) {
                    return;
                }
                const definitionToDelete = getters.allUnitDefinitions.find(definition => definitionId === definition.id);
                if (!definitionToDelete) {
                    return;
                }
                app.$notify.confirm(`Are you sure you would like to delete the definition '${definitionToDelete.name}'?`, {
                    title: 'Confirm Delete',
                    okButtonText: 'Delete',
                    okButtonTooltip: 'Confirm delete',
                    cancelButtonTooltip: 'Cancel delete',
                    onOk: (closeCallback) => {
                        closeCallback(true);
                        app.$api.data.delete({
                            type: 'UnitsDefinition',
                            query: {
                                id: definitionId,
                            },
                        })
                        .then(() => {
                            app.$notify.success('Units Definition deleted.');
                        })
                        .catch(issue => {
                            app.$notify.error('Could not delete definition.');
                        });
                    },
                });
            },
            saveUnitDefinition({ state, getters }) {
                const unitsDefinition = { ...state.editDefinitionPage };
                const params = {
                    type: 'UnitsDefinition',
                    query: {
                        id: unitsDefinition.id,
                    },
                    body: unitsDefinition,
                };
                app.$api.data.update(params)
                .then(() => {
                    app.$notify.success('Units definition updated.');
                })
                .catch((issue) => {
                    app.$notify.error('Error saving units definition:', issue);
                });
            },
        },
    };
}
