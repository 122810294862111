<template>
    <GeneralButton
        tooltip="Comment options"
        dense
        icon="more_horiz"
    >
        <q-menu
            anchor="bottom right"
            self="top right"
            dense
            auto-close
        >
            <q-list dense>
                <q-item
                    v-cypress="'CommentMenu_Edit_Btn'"
                    clickable
                    @click="$emit('edit')"
                >
                    <q-item-section>
                        Edit
                    </q-item-section>
                </q-item>
                <q-item
                    v-cypress="'CommentMenu_Delete_Btn'"
                    clickable
                    @click="$emit('delete')"
                >
                    <q-item-section>
                        Delete
                    </q-item-section>
                </q-item>
            </q-list>
        </q-menu>
    </GeneralButton>
</template>

<script>
import GeneralButton from '../inline-elements/GeneralButton.vue';

export default {
    name: 'CommentMenu',
    components: {
        GeneralButton,
    },
};
</script>

<style scoped>

</style>
