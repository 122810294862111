<template>
    <div class="calculated-full-height">
        <div class="row full-height relative-position">
            <iframe
                :id="iframeId"
                :ref="iframeId"
                v-cypress="'Visualizations_BokehAppliance_IFrame'"
                class="bokeh-appliance__iframe col"
                @load="onLoad"
                @error="onError"
            />

            <q-inner-loading :showing="pageLoadingMessages.length > 0">
                <div
                    v-if="showPageLoadingSpinner"
                    class="row"
                >
                    <q-spinner
                        size="50px"
                        color="primary"
                    />
                </div>
                <div
                    v-for="message in pageLoadingMessages"
                    :key="message.id"
                    class="row text-primary"
                >
                    <p>
                        {{ message.message }}
                    </p>
                </div>
            </q-inner-loading>

            <q-inner-loading :showing="iframeLoading && !iframeHasError">
                <div class="row">
                    <q-spinner
                        size="50px"
                        color="primary"
                    />
                </div>
                <div class="row">
                    <p>Loading...</p>
                </div>
            </q-inner-loading>

            <q-inner-loading :showing="iframeHasError">
                <div class="row text-error text-h3">
                    <q-icon name="clear"/>
                </div>
                <div class="row">
                    Unable to load
                    <span v-if="iframeError">
                        :&nbsp;{{ iframeError }}
                    </span>
                </div>
            </q-inner-loading>

            <q-inner-loading
                :showing="!bokehHasContent"
                class="bokeh-splash column"
            >
                <q-space/>
                <div class="text-center">
                    <div class="dots-container">
                        <span class="dot"/>
                        <div class="dots">
                            <span/>
                            <span/>
                            <span/>
                        </div>
                    </div>
                    <div class="text-h5 text-grey-9 q-pt-md q-gutter-xl">
                        <div>
                            Loading...
                        </div>
                    </div>
                </div>
                <q-space/>
            </q-inner-loading>
        </div>
    </div>
</template>

<script>
import qs from 'qs';

export default {
    name: 'BokehAppliance',
    props: {
        appName: {
            type: String,
            required: true,
        },
        params: {
            type: Object,
            required: false,
            default() {
                return {};
            },
        },
    },
    data() {
        return {
            iframeId: 'bokeh-appliance-iframe',
            iframeLoading: true,
            iframeHasError: false,
            iframeError: null,
            bokehApplianceRootURL: `${window.location.origin}/appliances/bokeh`,
            pageLoadingMessages: [],
            notificationDismissCallbacks: {},
            bokehLoadTimerName: `Bokeh ${this.appName} Loading Time`,
            bokehContentPollingIntervalHandle: null,
            bokehHasContent: false,
        };
    },
    computed: {
        showPageLoadingSpinner() {
            return this.pageLoadingMessages && this.pageLoadingMessages.some(message => message.showSpinner);
        },
    },
    created() {
        console.time(this.bokehLoadTimerName);
        this.generateOneTimeJWT()
        .then(token => {
            if (token) {
                const params = {
                    ...this.params,
                    ...this.$route.query,
                };
                const bokehAppURL = this.generateBokehAppURL({ token, params, appName: this.appName });
                this.$refs[this.iframeId].src = bokehAppURL;
            }
        });
        window.addEventListener('message', this.receiveMessage);
    },
    mounted() {
        // Start polling for a bokeh content
        this.bokehContentPollingIntervalHandle = setInterval(this.pollForBokehContent, 250);
    },
    destroyed() {
        // Clear the polling interval if present
        this.clearBokehContentPolling();

        window.removeEventListener('message', this.receiveMessage);
        // Clear all the persistent toasts that bokeh has sent
        this.clearAllNotifications();
    },
    methods: {
        pollForBokehContent() {
            const iframe = document.getElementById(this.iframeId);
            // Check for nulls all the way down to avoid errors
            if (iframe) {
                const iframeDocument = iframe.contentWindow.document;
                const iframeBodies = iframeDocument.getElementsByTagName('body');
                if (iframeBodies && iframeBodies.length) {
                    const iframeBody = iframeBodies[0];
                    if (iframeBody) {
                        const bokehHasContent = iframeBody.hasChildNodes();
                        if (bokehHasContent) {
                            this.bokehHasContent = bokehHasContent;
                            this.clearBokehContentPolling();
                        }
                    }
                }
            }
        },
        clearBokehContentPolling() {
            console.timeEnd(this.bokehLoadTimerName);
            clearInterval(this.bokehContentPollingIntervalHandle);
        },
        removeNotification(notificationId) {
            const dismissCallback = this.notificationDismissCallbacks[notificationId];
            // Remove the callback from tracking
            if (dismissCallback) {
                delete this.notificationDismissCallbacks[notificationId];
            }
            // Return the notification dismiss callback
            return dismissCallback;
        },
        clearAllNotifications() {
            Object.keys(this.notificationDismissCallbacks)
            .forEach(notificationId => {
                const dismissCallback = this.removeNotification(notificationId);
                if (dismissCallback) {
                    dismissCallback();
                }
            });
        },
        receiveMessage(messageEvent) {
            if (!(messageEvent.data && messageEvent.data.event)) {
                return;
            }

            switch (messageEvent.data.event) {
                case 'model-updated': {
                    this.modelUpdated(messageEvent);
                    break;
                }

                case 'show-notification': {
                    this.showNotification(messageEvent);
                    break;
                }

                case 'hide-notification': {
                    this.hideNotification(messageEvent);
                    break;
                }

                case 'show-actions': {
                    this.showActions(messageEvent);
                    break;
                }

                case 'show-page-loader': {
                    this.showPageLoader(messageEvent);
                    break;
                }

                default: {
                    // Pass the event on to the plugin.
                    this.$emit(messageEvent.data.event, messageEvent.data.payload);
                    break;
                }
            }
        },
        modelUpdated(messageEvent) {
            const modelChange = messageEvent.data.payload;
            const paramValue = modelChange.value;

            // Update the URL to reflect the model change
            this.$router.replace({
                query: {
                    ...this.$route.query,
                    [`${modelChange.name}-${modelChange.property}`]: paramValue,
                },
            })
            .catch(error => {
            // Hide navigation duplicated error
                if (error.name !== 'NavigationDuplicated') {
                    console.error(error);
                }
            });
        },
        showNotification(messageEvent) {
            // Expecting a payload like:
            // {
            //   id: '65eda165-b05c-44f8-9248-0b66e7ff895b',
            //   level: 'success',
            //   message: 'This thing just succeeded.',
            //   options: {
            //     timeout: 0,
            //   },
            // }
            if (messageEvent.data.payload) {
                const data = messageEvent.data.payload;
                const level = data.level;
                const options = data.options || {};
                const notificationId = data.id;
                // Add a callback to the dismiss to remove the local notification handle
                options.onDismiss = () => {
                    this.removeNotification(notificationId);
                };
                let dismissCallback = null;
                if (level === 'success') {
                    dismissCallback = this.$notify.success(data.message, options);
                }
                else if (level === 'error') {
                    dismissCallback = this.$notify.error(data.message, options);
                }
                else if (level === 'info') {
                    dismissCallback = this.$notify.info(data.message, options);
                }
                else if (level === 'warning') {
                    dismissCallback = this.$notify.warning(data.message, options);
                }
                if (dismissCallback) {
                    this.notificationDismissCallbacks[notificationId] = dismissCallback;
                }
            }
        },
        hideNotification(messageEvent) {
            // Expecting a payload like:
            // {
            //   id: '65eda165-b05c-44f8-9248-0b66e7ff895b',
            // }
            if (messageEvent.data.payload) {
                const data = messageEvent.data.payload;
                const notificationId = data.id;
                const dismissCallback = this.removeNotification(notificationId);
                if (dismissCallback) {
                    dismissCallback();
                }
            }
        },
        showActions(messageEvent) {
            const data = messageEvent.data.payload;
            if (data && (data.ids || data.items)) {
                this.$actions.showActions(messageEvent.data.payload);
            }
        },
        showPageLoader(messageEvent) {
            const data = messageEvent.data.payload;
            if (data.messages) {
                this.pageLoadingMessages = data.messages;
            }
            else {
                console.error('show-page-loader payload missing messages');
            }
        },
        generateOneTimeJWT() {
            return this.$api.oneTimeJWT.generateOneTimeJWT({ storedData: { userPetroaiToken: this.$auth.token } })
            .then(response => {
                if (response.success && response.data[0]) {
                    return response.data[0];
                }
                throw new Error('response contained was unsuccessful or contained no data');
            })
            .catch(error => {
                this.$logging.loggers.PluginFramework.error('There was an error generating the one time jwt for bokeh appliance:', error);
                this.iframeHasError = true;
                this.iframeError = `There was an error generating credentials for the bokeh appliance: ${error}`;
            });
        },
        generateBokehAppURL({ token, params, appName }) {
            const serializedQueryParams = qs.stringify({ token, ...params });
            return `${this.bokehApplianceRootURL}/${appName}?${serializedQueryParams}`;
        },
        onLoad(event) {
            this.iframeLoading = false;
        },
        onError(event) {
            this.iframeHasError = true;
            this.$notify.error('There was an error loading the bokeh appliance.');
        },
    },
};
</script>

<style scoped>
.calculated-full-height {
    /* height of chart toolbar */
    height: calc(100% - 3rem);
}

.bokeh-appliance__iframe {
    border: 0;
}

.bokeh-splash {
    background-color: white;
}

.dots-container {
  width: 142px;
  height: 32px;
  padding-top: 8px;
  background: white;
  filter: contrast(20);
}

.dots-container .dot {
    position: absolute;
    width: 16px;
    height: 16px;
    left: 16px;
    filter: blur(4px);
    background: #000;
    border-radius: 50%;
    animation: dot 2.8s infinite;
}

.dots-container .dots {
    position: absolute;
    margin-left: 31px;
    animation: dots 2.8s infinite;
}

.dots-container .dots span {
    display: block;
    float: left;
    width: 16px;
    height: 16px;
    margin-left: 16px;
    filter: blur(4px);
    background: #000;
    border-radius: 50%;
}

@keyframes dot {
    50% {
        transform: translateX(96px);
    }
}

@keyframes dots {
    50% {
        transform: translateX(-31px);
    }
}
</style>
