import ActionsService from './actions-service.js';

const PlatformActions = {
    install(Vue, {
        store = null,
    } = {}) {
        const service = new ActionsService({ store });

        // Register the actions service globally.
        Vue.$actions = service;

        // Register the actions service on the vue instance.
        Vue.prototype.$actions = service;
    },
};

export default PlatformActions;
