<template>
    <div
        v-cypress="'AppCurrentUser_Main_Div'"
        class="pointer"
        @click="clicked"
    >
        <UserProfileImage
            :user="$auth.user"
            show-badge
            hide-tooltip
            dimension="2.4rem"
        >
            <template v-slot:badge>
                <q-badge
                    v-if="forgeResourcesLoaded && unseenNotificationsCount > 0"
                    v-cypress="'AppCurrentUser_Unseen_Badge'"
                    color="accent"
                    text-color="secondary"
                    floating
                    class="badge-notification-rounded"
                >
                    {{ unseenNotificationsCount }}
                </q-badge>
            </template>
        </UserProfileImage>
        <q-tooltip :delay="750">
            {{ tooltip }}
        </q-tooltip>
    </div>
</template>

<script>
import mixins from '../mixins';

export default {
    name: 'AppCurrentUser',
    mixins: [mixins.compute],
    props: {
        tooltip: {
            type: String,
            required: true,
        },
    },
    computed: {
        workspace() {
            return `$root/Notifications/User/${this.$auth.user.userId}`;
        },
        notificationsResource() {
            return `${this.workspace}#NewestUserNotifications`;
        },
        forgeResources() {
            return [this.notificationsResource];
        },
        unseenNotificationsCount() {
            const forgeNotifications = this.forgeElement(this.notificationsResource);
            if (forgeNotifications && forgeNotifications.data && forgeNotifications.data.length) {
                return forgeNotifications.data[0].viewRecords.filter(notification => notification.status === 'Unseen').length;
            }
            return 0;
        },
    },
    methods: {
        clicked() {
            this.$telemetry.trackUserEvent({
                name: this.$telemetry.eventNames.BUTTON_CLICK,
                properties: {
                    buttonLabel: this.tooltip,
                    buttonTooltip: this.tooltip,
                },
            });
        },
    },
};
</script>

<style scoped>
.badge-notification-rounded {
    border-radius: 20%;
    height: 1rem;
    margin-top: 0.6rem;
}
</style>
