import Vue from 'vue';
import { Logger } from '../logging/logger.js';

class CMSService {
    constructor(options) {
        this.log = new Logger({ name: 'CMSService', level: Logger.levels.warn });
        Vue.$logging.registerLogger(this.log);
        if (!options.settings) {
            Vue.$logging.loggers.CMSService.error('CMS plugin requires settings.');
            return;
        }
        if (!options.settings.projectId) {
            Vue.$logging.loggers.CMSService.error('CMS plugin settings required projectId.');
            return;
        }
        this.projectId = options.settings.projectId;
        this.useCdn = options.settings.useCdn || true;
        this.dataset = process.env.NODE_ENV === 'development' ? 'development' : 'production';
        if (options.settings.dataset) {
            // Override default with setting.
            this.dataset = options.settings.dataset;
        }
    }

    async _getSanityClient() {
        if (!this._sanityClient) {
            const sanityClient = (await import('@sanity/client')).default;

            this._sanityClient = sanityClient({
                projectId: this.projectId,
                dataset: this.dataset,
                useCdn: this.useCdn,
            });
        }

        return this._sanityClient;
    }

    async _runGetQuery(query) {
        const client = await this._getSanityClient();
        const results = await client.fetch(query);
        return results || [];
    }

    async getExplorerCategories() {
        const query = /* groq */ `*[ _type == "appCategory"] {
                title,
                priority
        }`;
        return this._runGetQuery(query);
    }

    async getExplorerVideos() {
        const query = /* groq */ `*[ _type == "video" && showInExplorer == true] {
                title,
                publishedAt,
                description,
                "app": app->.title,
                "category": category->.title,
                length,
                showInExplorer,
                videoUrl,
                appOrder,
                tags
        }`;
        return this._runGetQuery(query);
    }

    async getPlusServices() {
        const query = /* groq */ `*[ _type == "service"] {
                name,
                description,
                keyDeliverables,
                dataRequirements,
                timeline,
                "coverImage": cover.asset->url,
                appOrder,
                "galleryImages": galleryImages[].asset[]->url
        }`;
        return this._runGetQuery(query);
    }
}

export default CMSService;
