<template>
    <div class="input-container">
        <div
            class="q-pa-sm"
        >
            <GeneralButton
                v-cypress="'InputListSection_AddInputs_Button'"
                label="Add Inputs"
                tooltip="Search for more inputs to add"
                text-color="primary"
                :flat="false"
                outline
                class="full-width"
                :disabled="loading || disabled"
                @click="showFindDialog = true"
            />
        </div>
        <div
            v-cypress="'InputListSection_Header_Div'"
            class="q-px-sm row items-center no-wrap"
        >
            <span
                v-if="selectedItems.length === 0"
            >
                No inputs selected.
            </span>
            <span
                v-else-if="selectedItems.length === 1"
            >
                1 input selected.
            </span>
            <span
                v-else
            >
                {{ selectedItems.length }} inputs selected.
            </span>
            <div
                v-if="headerInlineElements.length > 0"
                class="row items-center no-wrap"
            >
                <InlineElement
                    v-for="(element, idx) in headerInlineElements"
                    :key="`inline-${idx}`"
                    :element="element"
                    style="padding-right: 15px;"
                />
            </div>
        </div>
        <q-scroll-area :style="styleHeight">
            <q-list
                v-cypress="'InputListSection_Selected_List'"
                dense
            >
                <q-item
                    v-for="item in sortedItems"
                    :key="item.id"
                    clickable
                    class="item-extradense"
                    @click="$emit('rowClicked', item)"
                >
                    <q-item-section>
                        <q-item-label
                            class="text-secondary ellipsis"
                        >
                            {{ itemLabel(item) }}
                        </q-item-label>
                    </q-item-section>
                    <q-item-section side>
                        <q-icon
                            name="cancel"
                            size="xs"
                            :disabled="loading || disabled"
                            @click.stop="removeInput(item)"
                        />
                    </q-item-section>
                </q-item>
            </q-list>
        </q-scroll-area>
        <div
            v-if="loading"
            class="spinner-overlay column"
        >
            <q-space/>
            <q-spinner
                size="50px"
                class="full-width"
            />
            <q-space/>
        </div>
        <FindInputsDialog
            v-model="showFindDialog"
            :search-types="inputTypes"
            :initial-selection="items"
            :add-detail-message="addDetailMessage"
            :selected-detail-message="selectedDetailMessage"
            @inputsChosen="updateInputs"
            @launchAction="(type) => $emit('launchAction', type)"
        />
    </div>
</template>

<script>
import { formatting } from '../../services/utils/formatting-utils.js';
import GeneralButton from '../inline-elements/GeneralButton.vue';
import InlineElement from '../inline-elements/InlineElement.vue';
import FindInputsDialog from '../inputs/FindInputsDialog.vue';

export default {
    name: 'ItemList',
    components: {
        FindInputsDialog,
        GeneralButton,
        InlineElement,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        listHeight: {
            type: String,
            required: false,
            default: '112px',
        },
        headerInlineElements: {
            type: Array,
            required: false,
            default: () => [],
        },
        inputTypes: {
            type: Array,
            required: true,
        },
        addDetailMessage: {
            type: String,
            required: true,
            default: 'Property \'addDetailMessage\' will appear here.',
        },
        selectedDetailMessage: {
            type: String,
            required: true,
            default: 'Property \'selectedDetailMessage\' will appear here.',
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            showFindDialog: false,
            loading: false,
        };
    },
    computed: {
        selectedItems: {
            get() {
                return this.items;
            },
            set(value) {
                this.$emit('selectedItems', value);
            },
        },
        styleHeight() {
            return `height: ${this.listHeight}; border: 1px solid #D2D2D2`;
        },
        sortedItems() {
            const sorted = this.selectedItems.slice().sort((a, b) => this.nameForItem(a).localeCompare(this.nameForItem(b)));
            return sorted;
        },
    },
    methods: {
        nameForItem(item) {
            const type = item.type;
            if (type) {
                // If there is a displayNameFunction, use that.
                const typeDef = this.inputTypes.find(searchType => searchType.type === type);
                if (typeDef && typeDef.displayNameFunction) {
                    return typeDef.displayNameFunction(item);
                }
                // Otherwise, use name.
                let nameField = 'name';
                if (typeDef && typeDef.nameField) {
                    nameField = typeDef.nameField;
                }
                return item[nameField] || '';
            }
            return '';
        },
        itemLabel(item) {
            const type = item.type || '';
            return `${formatting.createAcronym(type)} | ${this.nameForItem(item)}`;
        },
        updateInputs(selectedInputs) {
            this.selectedItems = [...selectedInputs];
        },
        removeInput(input) {
            this.loading = true;
            setTimeout(() => {
                this.loading = false;
            }, 1000);
            const inputItems = [...this.selectedItems];
            const removeIdx = inputItems.findIndex(item => item.id === input.id && item.type === input.type);
            if (removeIdx > -1) {
                inputItems.splice(removeIdx, 1);
                this.selectedItems = inputItems;
            }
        },
    },
};
</script>

<style scoped>
.item-extradense {
    min-height: 0 !important;
}
.input-container {
    position: relative;
}
.spinner-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
</style>
