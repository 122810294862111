export const DisableReactivityPlugin = {
    install(Vue) {
        // Create a copy of the observer constructor
        const Observer = (new Vue()).$data.__ob__.constructor;

        function disableReactivity(value) {
            // Create a dummy observer to trick Vue into not adding reactivity
            value.__ob__ = new Observer({});
            return value;
        }

        Vue.$disableReactivity = disableReactivity;
        Vue.prototype.$disableReactivity = disableReactivity;
    },
};
