<template>
    <q-btn-dropdown
        v-if="currentPetronApp"
        v-cypress="'PetronAppDropdown_Apps_Button'"
        :label="currentPetronApp"
        no-caps
        menu-anchor="bottom left"
        menu-self="top left"
        :class="dropdownClass"
        @show="menuShowing = true"
        @hide="menuShowing = false"
    >
        <q-list>
            <ExpandableActionList
                title="Petron Apps"
                :items="mappedLockedApps"
                class="q-py-md text-secondary"
                @item-clicked="$emit('navigate')"
            >
                <template v-slot:actionControls="app">
                    <GeneralButton
                        icon="remove"
                        dense
                        tooltip="Remove from Petron Apps"
                        color="secondary"
                        @click="togglePetronLocked(app)"
                    />
                </template>
            </ExpandableActionList>
            <ExpandableActionList
                title="Available Apps"
                :items="mappedAvailablePetronApps"
                class="q-py-md text-secondary"
                @item-clicked="$emit('navigate')"
            >
                <template v-slot:actionControls="app">
                    <GeneralButton
                        icon="add"
                        dense
                        tooltip="Add to Petron Apps"
                        color="secondary"
                        @click="togglePetronLocked(app)"
                    />
                </template>
            </ExpandableActionList>
            <ExpandableActionList
                title="Global Apps"
                :items="mappedAvailableGlobalApps"
                class="q-py-md text-secondary"
                @item-clicked="$emit('navigate')"
            >
                <template v-slot:actionControls="app">
                    <GeneralButton
                        icon="add"
                        dense
                        tooltip="Add to Petron Apps"
                        color="secondary"
                        @click="togglePetronLocked(app)"
                    />
                </template>
            </ExpandableActionList>
        </q-list>
    </q-btn-dropdown>
</template>

<script>
import ExpandableActionList from '../plugin-framework/services/pai-components/ExpandableActionList.vue';
import GeneralButton from '../plugin-framework/shared-components/inline-elements/GeneralButton.vue';

export default {
    name: 'PetronAppDropdown',
    components: {
        ExpandableActionList,
        GeneralButton,
    },
    props: {
        petron: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            menuShowing: false,
        };
    },
    computed: {
        dropdownClass() {
            return this.menuShowing ? 'text-bold text-accent' : 'text-grey-4';
        },
        currentPetronApp() {
            // See if the route has a name defined and matches a fragment
            const matchedFragment = this.allAppCardFragments.find(fragment => fragment.props.launchRoute.name === this.$route.name);
            if (matchedFragment && matchedFragment.props.title) {
                return matchedFragment.props.title;
            }
            // If we can't find matching route then use the pluginId as a fallback
            const meta = this.$route.meta;
            if (meta && meta.pluginId) {
                return this.$utils.formatting.startCase(meta.pluginId);
            }
            // If nothing was found, just default to "Apps".
            return 'Apps';
        },
        allAppCardFragments() {
            return [...this.enabledPetronApps, ...this.enabledGlobalApps];
        },
        enabledPetronApps() {
            const appCards = this.$pai.getFragments('app-card');
            if (!appCards) {
                return [];
            }
            return appCards.filter(appCard => !appCard.props.hide);
        },
        enabledGlobalApps() {
            const appCards = this.$pai.getFragments('global-app-card');
            if (!appCards) {
                return [];
            }
            return appCards.filter(appCard => !appCard.props.hide);
        },
        lockedApps() {
            const petronLocked = [];
            if (this.petron.dockedApps) {
                this.petron.dockedApps.forEach(docked => {
                    let matchingApp = this.enabledPetronApps.find(app => docked.route === app.props.launchRoute.name);
                    if (matchingApp) {
                        petronLocked.push(matchingApp);
                        return;
                    }
                    matchingApp = this.enabledGlobalApps.find(app => docked.route === app.props.launchRoute.name);
                    if (matchingApp) {
                        petronLocked.push(matchingApp);
                    }
                });
            }
            return petronLocked;
        },
        mappedLockedApps() {
            return this.lockedApps.map(app => {
                return {
                    title: app.props.title,
                    to: {
                        name: app.props.launchRoute.name,
                    },
                    tooltip: app.props.description,
                    app,
                };
            });
        },
        mappedAvailablePetronApps() {
            const availableAppsSorted = this.enabledPetronApps.filter(app => !this.lockedApps.includes(app));
            availableAppsSorted.sort((a, b) => a.props.title.localeCompare(b.props.title));
            return availableAppsSorted.map(app => {
                return {
                    title: app.props.title,
                    to: {
                        name: app.props.launchRoute.name,
                    },
                    tooltip: app.props.description,
                    app,
                };
            });
        },
        mappedAvailableGlobalApps() {
            const availableAppsSorted = this.enabledGlobalApps.filter(app => !this.lockedApps.includes(app));
            availableAppsSorted.sort((a, b) => a.props.title.localeCompare(b.props.title));
            return availableAppsSorted.map(app => {
                return {
                    title: app.props.title,
                    to: {
                        name: app.props.launchRoute.name,
                    },
                    tooltip: app.props.description,
                    app,
                };
            });
        },
    },
    methods: {
        togglePetronLocked(toggledItem) {
            if (!toggledItem || !toggledItem.app) {
                return;
            }
            const toggledApp = toggledItem.app;
            // It's necessary to update the entire array on the server.
            const lockedArray = [...this.lockedApps];
            const removeIdx = lockedArray.indexOf(toggledApp);
            let addToLocks = true;
            if (removeIdx === -1) {
                // Add to locks.
                lockedArray.push(toggledApp);
            }
            else {
                // Remove from locks.
                addToLocks = false;
                lockedArray.splice(removeIdx, 1);
            }
            const newLockedApps = lockedArray.map(app => {
                return {
                    title: app.props.title,
                    route: app.props.launchRoute.name,
                };
            });
            this.$api.data.update({
                type: 'Petron',
                query: {
                    id: this.petron.id,
                },
                body: {
                    dockedApps: newLockedApps,
                },
            }).catch((issue) => {
                this.$logging.loggers.PluginFramework.error(`Could not toggle petron lock for '${toggledApp.props.title}': ${issue}`);
            });
        },
    },
};
</script>

<style scoped>
</style>
