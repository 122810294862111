<template>
    <PDialog
        v-if="forgeResourcesLoaded && display"
        v-model="display"
        :size="size"
        hide-cancel-btn
        hide-ok-btn
    >
        <template v-slot:header>
            <div
                class="petro-font-bold text-primary text-uppercase q-pr-xs"
            >
                {{ title }}
            </div>
            <div
                v-if="subtitle"
                class="text-grey-6"
            >
                | {{ subtitle }}
            </div>
        </template>
        <Section
            v-for="(section, index) in sections"
            :key="index"
            :section="section"
        />
        <template v-slot:footer>
            <OmniButton
                v-for="(button, index) in actionButtons"
                :key="index"
                :button="button"
            />
        </template>
    </PDialog>
    <ForgeLoadState
        v-else-if="display"
        :resources="forgeResourceElements"
    />
</template>

<script>
import mixins from '../../mixins';
import PDialog from '../services/pai-components/PDialog.vue';
import Section from '../shared-components/sections/Section.vue';
import OmniButton from '../shared-components/inline-elements/OmniButton.vue';
import ForgeLoadState from '../shared-components/ForgeLoadState.vue';

export default {
    name: 'FDialog',
    components: {
        OmniButton,
        Section,
        PDialog,
        ForgeLoadState,
    },
    mixins: [mixins.compute],
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
            required: false,
            default: null,
        },
        // Horizontal width sizing for the modal
        // sm: 300px wide, md: 700px wide, lg: 1100px wide
        size: {
            type: String,
            required: false,
            default: 'md',
            validator: (value) => ['sm', 'md', 'lg', 'auto'].includes(value),
        },
        sections: {
            type: Array,
            required: true,
        },
        actionButtons: {
            type: Array,
            required: true,
        },
        forgeResources: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    computed: {
        display: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            },
        },
    },
};
</script>

<style scoped>

</style>
