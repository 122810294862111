<template>
    <div class="col">
        <div class="row q-py-sm bottom-separator">
            <div class="col-2 text-bold">
                {{ title }}
            </div>
            <div
                v-for="(column, columnIndex) in columns"
                :key="`columnHeader-${columnIndex}`"
                class="col-2 text-bold text-center"
            >
                {{ column.label }}
            </div>
        </div>
        <div
            v-for="(notificationParameter, rowIndex) in configurableNotifications"
            :key="`row-${rowIndex}`"
            class="row q-py-sm bottom-separator"
        >
            <div class="col-2">
                {{ notificationParameter.label }}
            </div>
            <div
                v-for="(valueColumn, columnIndex) in notificationParameter.values"
                :key="`columnValue-${rowIndex}-${columnIndex}`"
                class="col-2 text-center"
            >
                <q-checkbox
                    v-cypress="valueColumn.testId"
                    :value="isOptionSet(valueColumn)"
                    dense
                    :disable="disabled"
                    @input="(value) => setOption(valueColumn, value)"
                />
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'NotificationsManagementForm',
    props: {
        columns: {
            type: Array,
            required: true,
        },
        configurableNotifications: {
            type: Array,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        notificationPreferences: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            required: false,
            default: null,
        },
    },
    methods: {
        getPreferencesForConsumer(consumerName) {
            if (this.notificationPreferences && this.notificationPreferences.length) {
                const consumerPreferences = this.notificationPreferences.find(consumer => consumer.consumerName === consumerName);
                if (consumerPreferences && consumerPreferences.userOptions) {
                    const preferenceObject = JSON.parse(consumerPreferences.userOptions);
                    const keyedPreferencesObject = {};
                    Object.keys(preferenceObject).forEach((key) => {
                        keyedPreferencesObject[key.toLowerCase()] = preferenceObject[key];
                    });
                    return keyedPreferencesObject;
                }
            }
            return {};
        },
        isOptionSet(valueColumn) {
            if (!valueColumn) {
                return false;
            }
            const consumerPreferences = this.getPreferencesForConsumer(valueColumn.consumerName);
            return valueColumn.options.every(option => {
                const targetValue = option.trueValue || true;
                return consumerPreferences[option.key.toLowerCase()] === targetValue;
            });
        },
        setOption(valueColumn, isSet) {
            if (!valueColumn) {
                return;
            }
            const consumerPreferences = this.getPreferencesForConsumer(valueColumn.consumerName);
            const newPreferences = Object.assign({}, consumerPreferences);
            valueColumn.options.forEach(option => {
                const key = option.key;
                if (isSet) {
                    newPreferences[key] = option.trueValue || true;
                }
                else {
                    newPreferences[key] = option.falseValue || false;
                }
            });
            this.$emit('preferences', {
                consumerName: valueColumn.consumerName,
                userOptions: JSON.stringify(newPreferences),
            });
        },
    },
};
</script>

<style scoped>
.bottom-separator {
    border-bottom: 1px solid #E6E6E6;
}
</style>
