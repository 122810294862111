<template>
    <q-card>
        <slot name="media"/>
        <q-card-section
            v-if="!hideTitle"
            class="q-pb-none"
        >
            <slot name="title">
                <div
                    v-if="title"
                    class="text-h6"
                >
                    {{ title }}
                </div>
            </slot>
            <slot name="subtitle">
                <div v-if="subtitle">
                    {{ subtitle }}
                </div>
            </slot>
        </q-card-section>

        <q-card-section
            v-if="hasDefaultSlot"
            :style="{ height: bodyHeight, padding: bodyPadding }"
        >
            <!-- Default slot is passed to body of card -->
            <slot/>
        </q-card-section>

        <!-- Footer slot is optionally used -->
        <q-separator v-if="!hideFooterSeparator && hasFooterSlot"/>
        <q-card-actions v-if="hasFooterSlot">
            <slot name="footer"/>
        </q-card-actions>
    </q-card>
</template>

<script>
export default {
    name: 'PCard',
    props: {
        title: {
            type: String,
            required: false,
            default: undefined,
        },
        subtitle: {
            type: String,
            required: false,
            default: undefined,
        },
        bodyHeight: {
            type: String,
            required: false,
            default: '',
        },
        bodyPadding: {
            type: String,
            required: false,
            default: '16px;',
        },
        hideFooterSeparator: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideTitle: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        hasDefaultSlot() {
            const hasDefaultSlot = (this.$slots && !!this.$slots.default) || (this.$slots && this.$slots.$scopedSlots && !!this.$slots.$scopedSlots.default);
            return hasDefaultSlot;
        },
        hasFooterSlot() {
            return (this.$slots && !!this.$slots.footer) || (this.$slots && this.$slots.$scopedSlots && !!this.$slots.$scopedSlots.footer);
        },
    },
};
</script>

<style scoped>

</style>
