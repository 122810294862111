import Vue from 'vue';
import VueRouter from 'vue-router';
import qs from 'qs';
import AuthService from '../plugin-framework/services/auth/auth-service.js';
import { formatting } from '../plugin-framework/services/utils/formatting-utils.js';

export * from './routes.js';

// Initializes the VueRouter with provided options and adds the global guards
export const initializeRouter = function initializeRouter(routes) {
    Vue.use(VueRouter);

    const routerOptions = {
        mode: 'history',
        // Inject route fragments from plugins into routes
        routes,
        // Handle more complex query string types, e.g. arrays, via the qs package
        parseQuery(queryString) {
            // Array limit is set extremely high to ensure that arrays are not converted to objects
            // See: https://github.com/ljharb/qs#parsing-arrays
            const objectifiedString = qs.parse(queryString, { arrayLimit: 10000 });
            return objectifiedString;
        },
        stringifyQuery(object) {
            const stringifiedObject = qs.stringify(object);
            return stringifiedObject ? `?${stringifiedObject}` : '';
        },
        // Allow for scrolling to anchors
        scrollBehavior(to, from, savedPosition) {
            // If there is an anchor tag scroll to it
            if (to.hash) {
                return { selector: to.hash, behavior: 'smooth' };
            }
            // If there is a previous position then use it
            if (savedPosition) {
                return savedPosition;
            }
            // Don't scroll
            return { x: 0, y: 0 };
        },
    };

    const router = new VueRouter(routerOptions);

    // Ensure user is logged in before each route that is marked with auth meta
    // E.g. meta: { auth: <true|'admin'> }
    // TODO: Move to guards.js file with other guards in the future
    router.beforeEach((to, from, next) => {
        // Route contains the auth meta
        const routeWithAuth = to.matched.find(route => route.meta && route.meta.auth);
        const authTag = routeWithAuth ? routeWithAuth.meta.auth : null;
        const userSignedIn = Vue.$auth.signedIn;
        const jwt = Vue.$auth.token;
        const jwtExpired = jwt && AuthService.isTokenExpired(jwt);
        const userRoles = userSignedIn ? Vue.$auth.user.roles : [];
        const routeRequiresRole = authTag && authTag !== true;
        const routeRequiresOnlySignin = authTag === true;
        const userHasRole = userRoles.includes(authTag);

        if (authTag && userSignedIn && jwtExpired) {
            // JWT is expired; force sign-in.
            next({
                name: 'sign-in',
                params: { redirectTo: to.fullPath },
            });
        }
        // Route is protected and user is not yet verified as signed in.
        else if (authTag && !userSignedIn) {
            if (Vue.$auth.hasJwt) {
                // User has JWT that has not been fully validated.
                Vue.$auth.afterSignIn('routerJWTValidationSuccess', (user) => {
                    if (user) {
                        // JWT has been verified. Let them through to the route.
                        next();
                    }
                    else {
                        // User is just not signed in; force them to do so.
                        next({
                            name: 'sign-in',
                            params: { redirectTo: to.fullPath },
                        });
                    }
                });
                Vue.$auth.afterInvalidToken('routerJWTInvalid', (user) => {
                    // User is just not signed in; force them to do so.
                    next({
                        name: 'sign-in',
                        params: { redirectTo: to.fullPath },
                    });
                });
            }
            else {
                // User is just not signed in; force them to do so.
                next({
                    name: 'sign-in',
                    params: { redirectTo: to.fullPath },
                });
            }
        }
        // Route is protected and user meets conditions
        else if ((routeRequiresOnlySignin && userSignedIn) || (routeRequiresRole && userHasRole)) {
            next();
        }

        // User accessing route with a named auth tag outside their role
        // Prevent navigation and send back to where they came
        // TODO: notify that they tried to access a restricted route
        else if (routeRequiresRole && !userHasRole) {
            next({ path: from.path });
        }
        // Route is unprotected
        else {
            next();
        }
    });

    router.afterEach((to, from, next) => {
        // Notify the user on each page if they are over their data limit
        Vue.$environment.__notifyIfOverStorageLimit();

        if (to.name && to.name.length) {
            document.title = `${formatting.startCase(to.name)} | Petro.ai`;
        }
    });

    return router;
};
