import { tableColumnsJobs } from './table-columns-jobs.js';
import { tableColumnsJobResults } from './table-columns-job-results.js';

export function JobsModel({ app, plugin }) {
    return {
        fields: {
            jobsTableType: {
                source: 'self',
                options: {
                    default: 'Jobs',
                },
            },
            allJobs: {
                source: 'forge',
                options: {
                    workspace: 'jobWorkspace',
                    resource: 'LatestJobResults',
                },
            },
            jobsTableView: {
                source: 'forge',
                options: {
                    workspace: 'jobWorkspace',
                    resource: 'TableViews',
                    single: true,
                },
            },
            jobsTableOptions: {
                source: 'forge',
                options: {
                    workspace: 'jobWorkspace',
                    resource: 'TableOptions',
                    single: true,
                },
            },
            selectedJobs: {
                source: 'self',
                options: {
                    default: [],
                },
            },
            jobId: {
                source: 'route',
            },
            currentJob: {
                source: 'forge',
                options: {
                    workspace: 'currentJobWorkspace',
                    resource: 'CurrentJob',
                    single: true,
                },
            },
            allResults: {
                source: 'forge',
                options: {
                    workspace: 'resultsWorkspace',
                    resource: 'CronJobResults',
                },
            },
            resultsTableType: {
                source: 'self',
                options: {
                    default: 'JobResults',
                },
            },
            resultsTableView: {
                source: 'forge',
                options: {
                    workspace: 'resultsWorkspace',
                    resource: 'TableViews',
                    single: true,
                },
            },
            resultsTableOptions: {
                source: 'forge',
                options: {
                    workspace: 'resultsWorkspace',
                    resource: 'TableOptions',
                    single: true,
                },
            },
            jobResultId: {
                source: 'self',
                options: {
                    default: null,
                },
            },
            selectedResults: {
                source: 'self',
                options: {
                    default: [],
                },
            },
            editJobNamePage: {
                source: 'self',
                options: {
                    default: {
                        name: '',
                    },
                },
            },
            editJobSettingsPage: {
                source: 'self',
                options: {
                    default: {
                        showSystemTasks: false,
                        taskName: '',
                        taskOptions: {},
                        schedule: {
                            singleExecutionTime: (new Date()).toISOString(),
                        },
                    },
                },
            },
            editNamePageIsValid: {
                source: 'self',
                options: {
                    default: true,
                },
            },
            editSettingsPageIsValid: {
                source: 'self',
                options: {
                    default: true,
                },
            },
            showDeleteJobsDialog: {
                source: 'self',
                options: {
                    default: false,
                },
            },
            showDeleteJobResultsDialog: {
                source: 'self',
                options: {
                    default: false,
                },
            },
            showLogDialog: {
                source: 'self',
                options: {
                    default: false,
                },
            },
            allTasks: {
                source: 'self',
                options: {
                    default: [],
                },
            },
            jobsColumnOptions: tableColumnsJobs(app),
            resultsColumnOptions: tableColumnsJobResults(app),
            userRunningJobs: {
                source: 'forge',
                options: {
                    workspace: 'runningJobsWorkspace',
                    resource: 'UserRunningJobs',
                },
            },
        },
        getters: {
            jobWorkspace: () => `$root/GlobalJobs/${app.$auth.user.userId}`,
            jobsTableForgeResources: (state, getters) => [
                getters.allJobsResource,
                getters.jobsTableViewResource,
                getters.jobsTableOptionsResource,
            ],
            currentJobWorkspace: (state) => `$root/JobResults/${state.jobId}`,
            resultsWorkspace: (state, getters) => `${getters.currentJobWorkspace}/${app.$auth.user.userId}`,
            resultsTableForgeResources: (state, getters) => [
                getters.allResultsResource,
                getters.resultsTableViewResource,
                getters.resultsTableOptionsResource,
                getters.currentJobResource,
            ],
            runningJobsWorkspace: () => `$root/RunningJobs/${app.$auth.user.userId}`,
            runningJobsForgeResources: (state, getters) => [
                getters.userRunningJobsResource,
            ],
            deleteSelectedJobsConfirmMessage: (state, getters) => {
                if (state.selectedJobs.length === 1) {
                    const selectedJob = getters.singleSelectedJob;
                    return selectedJob ? `Delete job '${selectedJob.name}'?` : 'Delete job?';
                }
                return `Delete ${state.selectedJobs.length} jobs?`;
            },
            deleteSelectedJobResultsConfirmMessage: (state, getters) => {
                if (state.selectedResults.length === 1) {
                    return 'Delete job result?';
                }
                return `Delete ${state.selectedResults.length} job results?`;
            },
            singleSelectedJob: (state, getters) => {
                if (state.selectedJobs.length === 1) {
                    const selectedId = state.selectedJobs[0];
                    return getters.allJobs.find(job => job.id === selectedId);
                }
                return null;
            },
            selectedJobInPast: (state, getters) => {
                const job = getters.singleSelectedJob;
                if (job && job.schedule && job.schedule.singleExecutionTime) {
                    const until = (new Date(job.schedule.singleExecutionTime)).getTime() - (new Date()).getTime();
                    return (until < 0);
                }
                return false;
            },
            singleSelectedJobResult: (state, getters) => {
                if (state.selectedResults.length === 1) {
                    const selectedResultId = state.selectedResults[0];
                    return getters.allResults.find(result => result.id === selectedResultId);
                }
                return null;
            },
            taskOptions: (state, getters) => (taskName) => {
                const selectedTask = getters.jobTemplate(taskName);
                return selectedTask ? selectedTask.options : {};
            },
            jobTemplate: (state, getters) => (taskName) => state.allTasks.find(task => task.taskName === taskName),
            typeOptions: (state, getters) => {
                let templates = [...state.allTasks];
                if (!state.editJobSettingsPage.showSystemTasks) {
                    templates = templates.filter(task => task.systemLevelJob === false);
                }
                templates.sort((a, b) => a.display.localeCompare(b.display));
                const groupedTemplates = {};
                const otherTemplates = [];
                templates.forEach(template => {
                    let jobsForType = otherTemplates;
                    const jobType = template.jobType;
                    if (jobType && jobType !== 'Other') {
                        jobsForType = groupedTemplates[jobType];
                        if (!jobsForType) {
                            jobsForType = [];
                            groupedTemplates[jobType] = jobsForType;
                        }
                    }
                    jobsForType.push(template);
                });
                const typeOptions = [];
                const orderedCategories = [
                    'Import',
                    'Export',
                    'Processing',
                    'Presentation',
                    'Diagnostics',
                ];
                // Grab the ordered ones first.
                orderedCategories.forEach(jobType => {
                    const optionsForType = groupedTemplates[jobType];
                    if (optionsForType && optionsForType.length) {
                        typeOptions.push({
                            display: jobType,
                            children: optionsForType,
                        });
                        delete groupedTemplates[jobType];
                    }
                });
                // Grab any groups not covered yet.
                Object.keys(groupedTemplates).forEach(jobType => {
                    typeOptions.push({
                        display: jobType,
                        children: groupedTemplates[jobType],
                    });
                });
                // Append the 'Other' category.
                if (otherTemplates.length > 0) {
                    typeOptions.push({
                        display: 'Other',
                        children: otherTemplates,
                    });
                }
                return typeOptions;
            },
            canRunJob: (state, getters) => {
                const selectedJob = getters.singleSelectedJob;
                if (selectedJob) {
                    return selectedJob.jobStatus !== 'running';
                }
                return false;
            },
            defaultJobSettingsPage: () => {
                return {
                    showSystemTasks: false,
                    taskName: '',
                    taskOptions: {},
                };
            },
        },
        actions: {
            deleteSelectedJobs({ state, getters }) {
                app.$api.data.delete({
                    type: 'CronJob',
                    query: {
                        id: state.selectedJobs,
                    },
                }).then(() => {
                    app.$notify.success('Job(s) deleted.');
                }).catch(issue => {
                    app.$notify.error('Could not delete job(s).');
                });
            },
            deleteSelectedJobResults({ state, getters }) {
                app.$api.data.delete({
                    type: 'JobResult',
                    query: {
                        id: state.selectedResults,
                    },
                }).then(() => {
                    app.$notify.success('Job result(s) deleted.');
                }).catch(issue => {
                    app.$notify.error('Could not delete job result(s).');
                });
            },
            duplicateSelectedJob({ state, getters }) {
                const job = getters.singleSelectedJob;
                if (!job) {
                    return;
                }
                const schedule = job.schedule || {};
                if (getters.selectedJobInPast) {
                    schedule.runNow = true;
                }
                const template = getters.jobTemplate(job.taskName);
                const basePage = Object.assign({}, getters.defaultJobSettingsPage);
                state.editJobNamePage.name = `Copy of ${job.name}`;
                state.editJobSettingsPage = Object.assign(basePage, {
                    showSystemTasks: (!!template && !!template.systemLevelJob),
                    taskName: job.taskName,
                    taskOptions: job.taskOptions ? JSON.parse(job.taskOptions) : {},
                    schedule,
                });
                app.$router.push({ name: 'job-editor' });
            },
            editNewJob({ state, getters }) {
                state.editJobNamePage.name = '';
                const basePage = Object.assign({}, getters.defaultJobSettingsPage);
                state.editJobSettingsPage = Object.assign(basePage, {
                    schedule: {
                        runNow: true,
                        singleExecutionTime: (new Date()).toISOString(),
                    },
                });
                app.$router.push({ name: 'job-editor' });
            },
            editSelectedJob({ state, getters }) {
                const job = getters.singleSelectedJob;
                if (!job) {
                    return;
                }
                const schedule = job.schedule || {};
                if (getters.selectedJobInPast) {
                    schedule.runNow = true;
                }
                const template = getters.jobTemplate(job.taskName);
                state.editJobNamePage.name = job.name;
                const basePage = Object.assign({}, getters.defaultJobSettingsPage);
                state.editJobSettingsPage = Object.assign(basePage, {
                    id: job.id,
                    showSystemTasks: (!!template && !!template.systemLevelJob),
                    taskName: job.taskName,
                    taskOptions: job.taskOptions ? JSON.parse(job.taskOptions) : {},
                    schedule,
                });
                app.$router.push({ name: 'job-editor' });
            },
            runSelectedJob({ state, getters }) {
                const selectedJob = getters.singleSelectedJob;
                if (selectedJob) {
                    app.$api.tasks.runTask({
                        runInBackground: true,
                        taskType: selectedJob.taskName,
                        cronJobId: selectedJob.id,
                        options: selectedJob.taskOptions ? JSON.parse(selectedJob.taskOptions) : {},
                    })
                    .then(() => {
                        app.$notify.success(`Job '${selectedJob.name}' queued for execution.`);
                    })
                    .catch(issue => {
                        app.$notify.error(`Could not queue job '${selectedJob.name}': ${issue}`);
                    });
                }
            },
            saveJob({ state, getters }) {
                // If the user is trying to save, we only allow it if:
                // there are no validation errors;
                // OR, there is no schedule.
                const isValid = state.editNamePageIsValid && state.editSettingsPageIsValid;
                const jobParameters = state.editJobSettingsPage;
                const schedule = jobParameters.schedule;
                const interval = schedule.interval;
                const validInterval = !!interval && !!interval.startDateTime && (!!interval.daysInterval || !!interval.hoursInterval || !!interval.minutesInterval || !!interval.secondsInterval);
                const scheduled = !!schedule.singleExecutionTime || validInterval;
                if (!isValid && scheduled) {
                    // Cannot be saved.
                    return;
                }
                if (schedule.runNow) {
                    // Make sure the time really is "now".
                    schedule.singleExecutionTime = (new Date()).toISOString();
                }
                else if (scheduled) {
                    // Make sure the time is not in the past.
                    const scheduledStartTime = schedule.singleExecutionTime || schedule.interval.startDateTime;
                    if ((new Date(scheduledStartTime)).getTime() - (new Date()).getTime() < -(1000 * 60)) {
                        app.$notify.error('A schedule cannot start in the past. Please update the start time or set to "Do not run."',
                            { timeout: 0 });
                        return;
                    }
                }
                const data = {
                    name: state.editJobNamePage.name,
                    taskName: jobParameters.taskName,
                    taskOptions: jobParameters.taskOptions ? JSON.stringify(jobParameters.taskOptions) : '',
                    schedule,
                };
                if (jobParameters.id) {
                    // Clear the lastExecutionTime, which will trigger the schedule to be recreated.
                    data.lastExecutionTime = null;
                    app.$api.data.update({
                        type: 'CronJob',
                        query: {
                            id: jobParameters.id,
                        },
                        body: data,
                    })
                    .then(() => {
                        app.$notify.success('Job updated.');
                        app.$router.push({ name: 'jobs' });
                    }).catch(issue => {
                        app.$notify.error(`Could not update job: ${issue}`);
                    });
                }
                else {
                    app.$api.data.insert({
                        type: 'CronJob',
                        data: [data],
                    })
                    .then(() => {
                        app.$notify.success('New job saved.');
                        app.$router.push({ name: 'jobs' });
                    }).catch(issue => {
                        app.$notify.error(`Could not save job: ${issue}`);
                    });
                }
            },
            getValidation({ state, getters }, { type, options }) {
                const jobParameters = state.editJobSettingsPage;
                const taskName = jobParameters.taskName;
                return new Promise((resolve, reject) => {
                    app.$api.tasks.validateTask({
                        taskType: taskName,
                        options,
                    }).then((response) => {
                        const errorMessages = [];
                        if (response && response.data) {
                            response.data.forEach(result => {
                                if (result.errors) {
                                    result.errors.forEach(error => {
                                        errorMessages.push(error.message);
                                    });
                                }
                            });
                        }
                        resolve(errorMessages);
                    }).catch(issue => {
                        app.$notify.error(`Could not validate job: ${issue}`);
                    });
                });
            },
            viewJobResults({ state, getters }) {
                const job = getters.singleSelectedJob;
                if (job) {
                    app.$router.push(
                        {
                            name: 'job-results',
                            params: {
                                jobId: job.id,
                            },
                        },
                    );
                }
            },
            viewLogs({ state, getters }) {
                const result = getters.singleSelectedJobResult;
                if (result) {
                    state.jobResultId = result.id;
                    state.showLogDialog = true;
                }
            },
        },
    };
}
