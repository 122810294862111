<template>
    <PDialog
        v-if="display"
        v-model="display"
        :title="title"
        :ok-btn-text="okButtonText"
        :ok-btn-tooltip="okButtonTooltip"
        ok-btn-test-id="ChoosePetronDialog_OK_Button"
        cancel-btn-tooltip="Cancel"
        @ok="petronSelected"
        @cancel="display = false"
    >
        <q-list>
            <q-item>
                <q-item-section avatar>
                    <q-radio
                        v-model="petronContext"
                        val="existing"
                        :disable="!petronOptions.length"
                    />
                </q-item-section>

                <q-item-section>
                    <q-select
                        v-model="selectedPetronId"
                        label="Select existing Petron"
                        :options="petronOptions"
                        :disable="!petronOptions.length"
                        option-label="name"
                        option-value="id"
                        emit-value
                        map-options
                        dense
                        clearable
                        @focus="petronContext = 'existing'"
                    />
                </q-item-section>
            </q-item>

            <q-item>
                <q-item-section avatar>
                    <q-radio
                        v-model="petronContext"
                        val="new"
                    />
                </q-item-section>

                <q-item-section>
                    <q-input
                        v-model="newPetronName"
                        label="Create a new Petron (type name)"
                        dense
                        clearable
                        @focus="petronContext = 'new'"
                    />
                </q-item-section>
            </q-item>
        </q-list>
    </PDialog>
</template>

<script>

import { mapGetters } from 'vuex';
import mixins from '../mixins';

export default {
    name: 'ChoosePetronDialog',
    mixins: [mixins.compute],
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        okButtonText: {
            type: String,
            required: false,
            default: 'Select',
        },
        okButtonTooltip: {
            type: String,
            required: false,
            default: 'Select current petron',
        },
        title: {
            type: String,
            required: false,
            default: 'Select a petron',
        },
    },
    data() {
        return {
            petronContext: 'existing',
            selectedPetronId: null,
            newPetronName: null,
        };
    },
    computed: {
        display: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        ...mapGetters('Library', {
            ownerCollaboratorPetrons: 'ownerCollaboratorPetrons',
            favoritePetrons: 'favoritePetrons',
            forgeResources: 'forgeResources',
        }),
        petronOptions() {
            const petronOptions = [...this.ownerCollaboratorPetrons];
            // Add any favorites that aren't already included.
            this.favoritePetrons.forEach(favorite => {
                if (!petronOptions.find(petron => petron.id === favorite.id)) {
                    petronOptions.push(favorite);
                }
            });
            petronOptions.sort((a, b) => a.name.localeCompare(b.name));
            return petronOptions;
        },
    },
    mounted() {
        if (!this.petronOptions.length) {
            this.petronContext = 'new';
        }
    },
    methods: {
        petronSelected() {
            if (this.petronContext === 'new') {
                this.$api.tasks.runTask({
                    runInBackground: false,
                    pid: 'null',
                    taskType: 'CreatePetron',
                    options: {
                        name: this.newPetronName,
                    },
                })
                .then(response => {
                    if (response.insertedIds.length > 0) {
                        const petronId = response.insertedIds[0];
                        this.$emit('petron-selected', petronId);
                    }
                })
                .catch(error => {
                    this.$notify.error(`There was an error while creating petron: ${error}`);
                });
                return;
            }
            this.$emit('petron-selected', this.selectedPetronId);
        },
    },
};
</script>

<style scoped>
</style>
