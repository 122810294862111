<template>
    <q-field
        v-cypress="testId"
        :label="label ? label : undefined"
        :error="!!errors.length"
        :hint="hint ? hint : undefined"
        stack-label
        borderless
        :bottom-slots="showBottomSlots"
        :hide-bottom-space="!showBottomSlots"
        :disable="disable"
        :dark="dark"
        :readonly="readonly"
        :dense="dense"
        class="q-pt-none"
    >
        <template v-slot:control>
            <q-list
                :dense="dense"
                :dark="dark"
            >
                <q-item
                    v-for="(option, index) in options"
                    :key="index"
                    v-ripple
                    tag="label"
                    :disable="option.disable || false"
                    :dark="dark"
                    :dense="dense"
                >
                    <q-item-section avatar>
                        <q-radio
                            v-model="selection"
                            v-cypress="option.testId"
                            :val="option.value"
                            :dark="dark"
                            :dense="dense"
                            :color="dark ? 'grey-6' : undefined"
                            :disable="option.disable || false"
                        />
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>{{ option.displayName }}</q-item-label>
                        <q-item-label
                            v-if="option.description"
                            caption
                        >
                            {{ option.description }}
                        </q-item-label>
                    </q-item-section>
                </q-item>
            </q-list>
        </template>
        <template v-slot:error>
            <AutoFormValidationErrors :errors="errors"/>
        </template>
    </q-field>
</template>

<script>

import AutoFormValidationErrors from './AutoFormValidationErrors.vue';

export default {
    name: 'AutoFormRadioSelect',
    components: {
        AutoFormValidationErrors,
    },
    props: {
        value: {
            required: true,
            validator(value) {
                return typeof value === 'string' || value === null;
            },
        },
        testId: {
            type: String,
            required: false,
            default: null,
        },
        label: {
            type: String,
            required: false,
            default: null,
        },
        hint: {
            type: String,
            required: false,
            default: null,
        },
        errors: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },
        disable: {
            type: Boolean,
            required: false,
            default: false,
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false,
        },
        options: {
            type: Array,
            required: false,
            default: () => [],
        },
        dark: {
            type: Boolean,
            required: false,
            default: false,
        },
        dense: {
            type: Boolean,
            required: false,
            default: true,
        },
        hasValidationRules: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    computed: {
        selection: {
            get() {
                return this.value;
            },
            set(value) {
                if (!this.readonly) {
                    this.$emit('input', value);
                }
            },
        },
        showBottomSlots() {
            return !!this.hint || this.hasValidationRules;
        },
    },
};
</script>

<style scoped>

</style>
