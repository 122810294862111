<template>
    <div>
        <q-card v-if="type === 'CommentThread'">
            <Comment
                :comment="item.comment"
                :replies="item.replies"
                :reactions="item.reactions"
                @create="createComment"
                @update="updateComment"
                @delete="$emit('delete', $event)"
                @add-reaction="addReaction"
                @remove-reaction="$emit('remove-reaction', $event)"
            >
                <template
                    v-if="showNavigation"
                    v-slot:info
                >
                    <span v-if="showPetron && petronId">
                        {{ pluginId | spaces | capitalize }} of <router-link :to="{ name: 'petron-overview', params: { petronId, } }">{{ petronName }}</router-link> petron.
                    </span>
                    <span v-else>
                        {{ pluginId | spaces | capitalize }}
                    </span>
                    <GeneralButton
                        v-if="canResolveActivity"
                        tooltip="Go to activity"
                        icon="fa fa-chevron-right"
                        dense
                        size="sm"
                        @click="resolveActivity"
                    />
                </template>
            </Comment>
        </q-card>
        <HorizontalRuleLabel
            v-if="type === 'horizontal-rule'"
            :label="item.label"
        />
    </div>
</template>

<script>
import Comment from '../comments/Comment.vue';
import HorizontalRuleLabel from './HorizontalRuleLabel.vue';
import GeneralButton from '../inline-elements/GeneralButton.vue';

export default {
    name: 'ActivityItem',
    components: {
        HorizontalRuleLabel,
        Comment,
        GeneralButton,
    },
    props: {
        type: {
            type: String,
            required: true,
        },
        item: {
            type: Object,
            required: true,
        },
        pluginId: {
            type: String,
            required: false,
            default: '',
        },
        petronName: {
            type: String,
            required: false,
            default: '',
        },
        petronId: {
            type: String,
            required: false,
            default: null,
        },
        showPetron: {
            type: Boolean,
            required: false,
            default: true,
        },
        showNavigation: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        canResolveActivity() {
            return this.item && this.pluginId;
        },
    },
    methods: {
        createComment(comment) {
            comment.pluginId = this.item.comment.pluginId;
            comment.petronId = this.item.comment.petronId;
            comment.viewId = this.item.comment.viewId;
            this.$emit('create', comment);
        },
        updateComment({ comment, parentId }) {
            comment.pluginId = this.item.comment.pluginId;
            comment.petronId = this.item.comment.petronId;
            comment.viewId = this.item.comment.viewId;
            this.$emit('update', { comment, parentId });
        },
        addReaction({ reaction, parentId }) {
            reaction.pluginId = this.item.comment.pluginId;
            reaction.petronId = this.item.comment.petronId;
            reaction.viewId = this.item.comment.viewId;
            this.$emit('add-reaction', { reaction, parentId });
        },
        resolveActivity() {
            // TODO: Need some way to lookup the resolve function name for different types of activity
            if (this.type === 'CommentThread') {
                this.$pai.triggerPluginAction('resolveComment', [this.pluginId], this.item.comment);
            }
        },
    },
};
</script>

<style scoped>

</style>
