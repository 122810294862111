<template>
    <!-- Show the table as a block for use in visualizations -->
    <div
        v-if="display === 'block'"
        v-cypress="testId"
        class="q-px-md full-height full-width"
    >
        <div class="row items-center q-pb-sm">
            <GeneralButton
                label="Download"
                tooltip="Download table as CSV"
                icon="get_app"
                @click="downloadCSV"
            />

            <q-space/>

            <span
                class="q-px-md text-grey-6"
            >
                {{ validationMessage }}
                <q-spinner
                    v-if="validatingPaste"
                />
            </span>
            <AutoFormValidationErrors
                v-if="!validatingPaste"
                class="text-error q-pr-md"
                :separator="' '"
                :errors="[errorMessage]"
            />

            <GeneralButton
                label="Update"
                tooltip="Update table"
                color="accent"
                :flat="false"
                :outline="false"
                text-color="black"
                :disabled="validatingPaste || errorInfo.hasErrors"
                @click="updateTable"
            />
        </div>

        <div class="hot-container">
            <hot-table
                ref="hotWrapper"
                :settings="hotSettings"
            />
        </div>
    </div>

    <!-- Show table in a modal accessed via a button -->
    <div
        v-else
        v-cypress="testId"
    >
        <q-field
            :error="!!errorMessage"
            :hint="hint"
            borderless
            class="field-wrapper q-pb-sm"
            :dark="dark"
        >
            <GeneralButton
                :label="label"
                :tooltip="hint"
                :disabled="disable"
                :outline="true"
                :flat="false"
                :color="dark ? 'grey-6' : undefined"
                @click="displayModal"
            />
            <template v-slot:error>
                <AutoFormValidationErrors
                    :separator="' '"
                    :errors="[errorMessage]"
                />
            </template>
        </q-field>
        <PDialog
            :value="showModal"
            :title="label"
            size="lg"
            body-height="70vh"
            hide-cancel-btn
            ok-btn-text="Close"
            ok-btn-tooltip="Close table editor"
            @input="showTableModalChanged"
        >
            <hot-table
                v-if="showTable"
                ref="hotWrapper"
                :settings="hotSettings"
            />
            <div
                v-else
                class="text-center"
            >
                <q-spinner
                    size="30px"
                    color="primary"
                />
                <p>Loading table...</p>
            </div>
            <template slot="footer">
                <span
                    class="q-px-md text-grey-6"
                >
                    {{ validationMessage }}
                    <q-spinner
                        v-if="validatingPaste"
                    />
                </span>
                <AutoFormValidationErrors
                    v-if="!validatingPaste"
                    class="text-error q-pr-md"
                    :separator="' '"
                    :errors="[errorMessage]"
                />
                <GeneralButton
                    label="Download"
                    tooltip="Download table as CSV"
                    icon="get_app"
                    :disabled="validatingPaste"
                    @click="downloadCSV"
                />
                <GeneralButton
                    v-close-popup
                    :label="saveBtnText"
                    :tooltip="saveBtnTooltip"
                    color="accent"
                    text-color="primary"
                    :flat="false"
                    :outline="false"
                    :disabled="validatingPaste || errorInfo.hasErrors"
                    @click="updateTable"
                />
            </template>
        </PDialog>
    </div>
</template>

<script>
import Vue from 'vue';
import { HotTable } from '@handsontable/vue';
import moment from 'moment-timezone';
import GeneralButton from '../inline-elements/GeneralButton.vue';
import PDialog from '../../services/pai-components/PDialog.vue';
import AutoFormValidationErrors from './AutoFormValidationErrors.vue';
import petroJSSettings from '../../../../petrojs-settings.json';
// Needed for date columns of HOT
import 'pikaday';
// Needed for time columns of HOT
import 'moment';

export default {
    name: 'AutoFormTable',
    components: {
        HotTable,
        GeneralButton,
        PDialog,
        AutoFormValidationErrors,
    },
    props: {
        value: {
            required: true,
            validator: v => Array.isArray(v),
        },
        testId: {
            type: String,
            required: false,
            default: null,
        },
        label: {
            type: String,
            required: true,
        },
        hint: {
            type: String,
            required: false,
            default: '',
        },
        disable: {
            type: Boolean,
            required: false,
            default: false,
        },
        display: {
            required: false,
            validator: value => typeof value === 'string' && ['modal', 'block'].includes(value),
            default: 'modal',
        },
        columnOptions: {
            type: Array,
            required: false,
            default: () => [],
        },
        sortable: {
            type: Boolean,
            required: false,
            default: true,
        },
        saveBtnText: {
            type: String,
            required: true,
        },
        saveBtnTooltip: {
            type: String,
            required: true,
        },
        dark: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            showModal: false,
            showTable: false,
            hotSettings: null,
            validationErrors: {},
            validatingPaste: false,
            isTableDirty: false,
        };
    },
    computed: {
        errorInfo() {
            let errorCount = 0;
            let firstErrorRow = Number.MAX_SAFE_INTEGER;
            let lastErrorRow = Number.MIN_SAFE_INTEGER;
            Object.entries(this.validationErrors).forEach(([columnName, columnErrors]) => {
                // errors are 0 indexed in the validation errors and 1 indexed in UI
                const rowNumbers = Object.keys(columnErrors).map(rowNumString => parseInt(rowNumString, 10) + 1);
                errorCount += rowNumbers.length;
                firstErrorRow = Math.min(firstErrorRow, ...rowNumbers);
                lastErrorRow = Math.max(lastErrorRow, ...rowNumbers);
            });

            return {
                hasErrors: !!errorCount,
                count: errorCount,
                firstErrorRow,
                lastErrorRow,
            };
        },
        errorMessage() {
            if (this.errorInfo.hasErrors) {
                const totalErrorMessage = this.errorInfo.count === 1 ? 'One validation error.' : `${this.errorInfo.count} validation errors.`;
                const rowMessage = this.errorInfo.firstErrorRow === this.errorInfo.lastErrorRow ? `See row ${this.errorInfo.firstErrorRow}.` : `See rows ${this.errorInfo.firstErrorRow} - ${this.errorInfo.lastErrorRow}.`;
                const errorMessage = `${totalErrorMessage} ${rowMessage}`;
                return errorMessage;
            }
            return null;
        },
        validationMessage() {
            // In order of priority, we show:
            // 1) A message about post-paste formatting.
            // 2) Any validation errors.
            // 3) An indicator of any unsaved changes.
            if (this.validatingPaste) {
                return 'Formatting pasted data';
            }
            if (!this.errorMessage && this.isTableDirty) {
                return 'The table has unsaved changes.';
            }
            return null;
        },
    },
    watch: {
        // Handsontable does not support reactive data so we have to watch for value to change and set data accordingly
        value: {
            deep: true,
            handler(newValue, oldValue) {
                const hotInstance = this.getHotInstance();
                if (hotInstance) {
                    // Hot does not copy data but rather modifies value so we need to copy to break object reference
                    hotInstance.loadData(JSON.parse(JSON.stringify(newValue)));
                }
            },
        },
        // Send errors to parent to invalidate form.
        errorMessage(newValue) {
            if (newValue) {
                this.$emit('validated', [newValue]);
            }
            else {
                this.$emit('validated', []);
            }
        },
        columnOptions() {
            if (this.hotSettings && this.hotSettings.columns) {
                // If the column definitions are dynamic and change after the table is created, we need to capture that.
                // E.g., the options for a dropdown column changes.
                this.hotSettings.columns = this.buildHotColumns();
            }
        },
    },
    created() {
        this.hotSettings = this.buildHotSettings();
        this.validationErrors = this.initializeValidationErrors();
    },
    methods: {
        initializeValidationErrors() {
            const validationErrors = {};
            this.columnOptions.forEach(columnOption => {
                if (columnOption.name) {
                    // seed the validation errors object with the column names
                    validationErrors[columnOption.name] = {};
                }
                else {
                    this.$logging.loggers.PluginFramework.error('AutoFormTable must be passed a name or label in column options.');
                }
            });
            return validationErrors;
        },
        buildHotSettings() {
            const hotSettings = {
                licenseKey: petroJSSettings.handsontableLicenseKey ? petroJSSettings.handsontableLicenseKey : 'non-commercial-and-evaluation',
                // Hot does not copy data but rather modifies value so we need to copy to break object reference
                data: JSON.parse(JSON.stringify(this.value)),
                rowHeaders: true,
                colHeaders: this.buildHotColumnHeaders(),
                columns: this.buildHotColumns(),
                columnSorting: this.sortable,
                contextMenu: ['row_above', 'row_below', 'remove_row', '---------', 'cut', 'copy'],
                stretchH: 'all',
                height: '100%',
                width: '100%',
                minRows: 1,
                afterInit: this.afterInit,
                afterLoadData: this.afterLoadData,
                afterCreateRow: this.afterCreateRow,
                afterRemoveRow: this.afterRemoveRow,
                afterPaste: this.afterPaste,
                afterChange: this.afterChange,
            };
            return hotSettings;
        },
        buildHotColumnHeaders() {
            const hotColumnHeaders = this.columnOptions.map(columnOption => {
                if (columnOption.label) {
                    return columnOption.label;
                }
                // If no label then format the name
                if (columnOption.name) {
                    return this.$utils.formatting.startCase(columnOption.name);
                }
                this.$logging.loggers.PluginFramework.error('AutoFormTable must be passed a name or label in column options.');
                return '';
            });
            return hotColumnHeaders;
        },
        buildHotColumns() {
            const hotColumns = this.columnOptions.map(columnOption => {
                const hotColumnOption = {
                    data: columnOption.name,
                    readOnly: columnOption.readOnly,
                    ...this.buildHotAlignOptions(columnOption),
                    ...this.buildHotTypeOptions(columnOption),
                    ...this.buildHotSortingOptions(columnOption),
                    ...this.buildHotValidationOptions(columnOption),
                };
                return hotColumnOption;
            });
            return hotColumns;
        },
        buildHotAlignOptions(columnOption) {
            switch (columnOption.align) {
                case 'right':
                    return {
                        // All columns should be middle aligned hence htMiddle
                        className: 'htRight htMiddle',
                    };
                case 'center':
                    return {
                        className: 'htCenter htMiddle',
                    };
                default:
                    return {
                        className: 'htLeft htMiddle',
                    };
            }
        },
        buildHotSortingOptions(columnOption) {
            // Block sorting only if explicitly set to false
            if (columnOption.sortable === false) {
                // If explicitly defined as not sortable then blocking sort at column level
                return {
                    columnSorting: {
                        indicator: false,
                        headerAction: false,
                        compareFunctionFactory() {
                            return () => 0;
                        },
                    },
                };
            }
            // return empty if we want to sort as all columns are sortable by default at table level
            return {};
        },
        buildHotTypeOptions(columnOption) {
            switch (columnOption.type) {
                case 'number': {
                    // See http://numbrojs.com/format.html for options/format schema
                    const defaultNumberFormat = '0,0';
                    const userProvidedFormat = (columnOption.typeOptions && columnOption.typeOptions.format) ? columnOption.typeOptions.format : null;
                    return {
                        type: 'numeric',
                        numericFormat: {
                            pattern: userProvidedFormat || defaultNumberFormat,
                            culture: 'en-US',
                        },
                    };
                }
                case 'date': {
                    const defaultDateFormat = 'MM/DD/YYYY';
                    const userProvidedFormat = (columnOption.typeOptions && columnOption.typeOptions.format) ? columnOption.typeOptions.format : null;
                    return {
                        type: 'date',
                        dateFormat: userProvidedFormat || defaultDateFormat,
                        correctFormat: true,
                    };
                }
                case 'time': {
                    const defaultTimeFormat = 'h:mm a';
                    const userProvidedFormat = (columnOption.typeOptions && columnOption.typeOptions.format) ? columnOption.typeOptions.format : null;
                    return {
                        type: 'time',
                        timeFormat: userProvidedFormat || defaultTimeFormat,
                        correctFormat: true,
                    };
                }
                case 'boolean':
                    return {
                        type: 'checkbox',
                    };
                case 'dropdown':
                    if (!columnOption.typeOptions || !columnOption.typeOptions.values) {
                        this.$logging.loggers.PluginFramework.error('AutoFormTable dropdown missing values.');
                    }
                    return {
                        type: 'dropdown',
                        source: columnOption.typeOptions.values,
                    };
                case 'string':
                    // string is implicit in Hot
                    return {};
                default:
                    this.$logging.loggers.PluginFramework.error(`AutoFormTable passed an unknown column type: ${columnOption.type}`);
                    return {};
            }
        },
        buildHotValidationOptions(columnOption) {
            if (columnOption.validationRules) {
                // Store the access to the vue instance for reference in setting validation errors
                const vm = this;
                return {
                    validator(value, callback) {
                        const rowNum = this.row;
                        const colNum = this.col;
                        const columnName = this.prop;
                        const columnTypeOptions = vm.buildHotTypeOptions(columnOption);
                        let validationResults = [];

                        // Validate date formatting and update to correct format if possible
                        if (columnOption.type === 'date') {
                            const dateValid = moment(value).isValid();
                            const dateFormattingValid = moment(value, columnTypeOptions.dateFormat, true).isValid();

                            // Date can't be parsed--it's invalid
                            if (!dateValid) {
                                validationResults.push('Date cannot be parsed');
                            }

                            // Date is valid and formatted correctly
                            if (dateValid && dateFormattingValid) {
                                validationResults.push(true);
                            }

                            // Date is valid but in wrong format--update it
                            if (dateValid && !dateFormattingValid) {
                                const formattedDate = moment(value).format(columnTypeOptions.dateFormat);
                                this.instance.setDataAtCell(this.visualRow, this.visualCol, formattedDate, 'paiDateValidator');
                                validationResults.push(true);
                            }
                        }

                        // Array validation rules must be collapsed into a single validator for hot
                        // Get an array of validation rules and then evaluate if they all pass (true) to be valid
                        validationResults = [...validationResults, ...columnOption.validationRules.map(validationRule => validationRule(value, columnTypeOptions))];

                        // Ensure that all validation rules pass
                        if (validationResults.every(result => result === true)) {
                            // If there were previous errors in this cell remove them
                            if (vm.validationErrors[columnName][rowNum]) {
                                Vue.delete(vm.validationErrors[columnName], rowNum);
                            }
                            callback(true);
                        }
                        else {
                            // Log the validation errors
                            Vue.set(vm.validationErrors[columnName], rowNum, validationResults);
                            callback(false);
                        }
                    },
                };
            }
            return {};
        },
        getHotInstance() {
            // Grab Hot instance when needed as $refs are unstable before render
            if (this.$refs.hotWrapper) {
                return this.$refs.hotWrapper.hotInstance;
            }
            return null;
        },
        afterInit() {
            // Workaround for HOT not loading data correctly immediately after init
            setTimeout(() => {
                // Hot does not copy data but rather modifies value so we need to copy to break object reference
                const copiedData = JSON.parse(JSON.stringify(this.value));
                this.getHotInstance().loadData(copiedData);
            }, 100);
        },
        afterCreateRow(index, amount, source) {
            // Only validate if user added row
            if (source === 'ContextMenu.rowAbove' || source === 'ContextMenu.rowBelow') {
                this.validate();
            }
        },
        afterRemoveRow(index, amount, physicalRows, source) {
            this.validate();
        },
        afterLoadData(sourceData, initialLoad) {
            this.validate();
        },
        afterPaste(data, coords) {
            this.validatingPaste = true;
            // After paste, there is a delay while HOT applies formatting to the pasted cells.
            // If Update/Save is clicked in the meantime, the table snapshot will be in an intermediate state.
            // Block save until the formatting has time to finish.
            // TODO: Find a better long-term solution.
            const delay = Math.min(10000, (data.length * 50));
            setTimeout(() => {
                this.validatingPaste = false;
            }, delay);
        },
        afterChange(changes) {
            // changes will be null when data is loaded, which we can ignore.
            if (changes) {
                this.isTableDirty = true;
            }
        },
        validate() {
            // Workaround for HOT bug where validation invalid classes are not applied if synchronous with data change operations
            setTimeout(() => {
                const hotInstance = this.getHotInstance();
                if (hotInstance) {
                    this.validationErrors = this.initializeValidationErrors();
                    hotInstance.validateCells();
                }
            }, 50);
        },
        displayModal() {
            this.showModal = true;
            // Hack to get table to show in modal
            // See https://forum.handsontable.com/t/table-not-fully-rendered-until-click/1482/2
            setTimeout(() => {
                this.showTable = true;
            }, 500);
        },
        showTableModalChanged(value) {
            // When the modal closes reset the showTable flag
            if (!value) {
                this.showTable = false;
                this.isTableDirty = false;
            }
            this.showModal = value;
        },
        downloadCSV() {
            const hotInstance = this.getHotInstance();
            if (hotInstance && hotInstance.getPlugin('exportFile')) {
                hotInstance.getPlugin('exportFile').downloadFile('csv', {
                    bom: true,
                    columnDelimiter: ',',
                    columnHeaders: true,
                    exportHiddenColumns: false,
                    exportHiddenRows: false,
                    fileExtension: 'csv',
                    filename: this.label,
                    mimeType: 'text/csv',
                    rowDelimiter: '\r\n',
                    rowHeaders: true,
                });
            }
        },
        updateTable() {
            const hotInstance = this.getHotInstance();
            if (hotInstance) {
                const data = hotInstance.getSourceData();
                this.$emit('input', data);
                this.isTableDirty = false;
            }
        },
    },
};
</script>

<style>
@import '~handsontable/dist/handsontable.min.css';
@import '~pikaday/css/pikaday.css';

.hot-container {
    height: calc(100% - 3rem);
    width: 100%
}

.field-wrapper .q-field__bottom {
    /* padding-top: 0 !important; */
    font-size: 11px;
}

.field-wrapper .q-field__control {
    min-height: 3rem !important;
    height: 3rem !important;
}
</style>
