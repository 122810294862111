<template>
    <div class="col q-pb-lg">
        <div class="text-primary q-py-md">
            Schedule the job to run:
        </div>
        <div class="row">
            <q-list
                dense
                separator
                class="col-8"
            >
                <q-item>
                    <div class="row col">
                        <q-radio
                            v-model="frequency"
                            v-cypress="'JobFrequencyForm_FrequencyNow_RadioButton'"
                            val="now"
                            label="Now"
                            class="col-3"
                            dense
                        />
                    </div>
                </q-item>
                <q-item>
                    <div class="row col">
                        <div class="col-3 q-pt-sm">
                            <q-radio
                                v-model="frequency"
                                v-cypress="'JobFrequencyForm_FrequencyLater_RadioButton'"
                                val="later"
                                label="Later"
                                dense
                            />
                        </div>
                        <AutoFormDateTimeInput
                            v-model="singleExecutionTime"
                            :clearable="false"
                            :debounce="300"
                            :disable="frequency !== 'later'"
                            :display-time-zone="displayTimeZoneId"
                            :date-options="dateOptions"
                            hint=""
                            label="At"
                        />
                    </div>
                </q-item>
                <q-item>
                    <div
                        :disable="frequency !== 'interval'"
                        class="row col"
                    >
                        <div class="col-3 q-pt-sm">
                            <q-radio
                                v-model="frequency"
                                v-cypress="'JobFrequencyForm_FrequencyInterval_RadioButton'"
                                val="interval"
                                label="On a schedule"
                                dense
                            />
                        </div>
                        <div>
                            <div class="row q-px-sm items-baseline">
                                <span class="col">
                                    Run every
                                </span>
                                <q-input
                                    v-model.number="intervalSize"
                                    dense
                                    type="number"
                                    :disable="frequency !== 'interval'"
                                    class="col-3 q-px-md"
                                    input-class="text-right"
                                />
                                <q-select
                                    v-model="intervalType"
                                    :options="allIntervalTypes"
                                    dense
                                    options-dense
                                    :disable="frequency !== 'interval'"
                                    class="col-auto"
                                />
                            </div>
                            <AutoFormDateTimeInput
                                v-model="startDateTime"
                                :clearable="false"
                                :debounce="300"
                                :disable="frequency !== 'interval'"
                                :display-time-zone="displayTimeZoneId"
                                :date-options="dateOptions"
                                hint=""
                                label="Starting on"
                            />
                        </div>
                    </div>
                </q-item>
                <q-item>
                    <q-radio
                        v-model="frequency"
                        v-cypress="'JobFrequencyForm_FrequencyNever_RadioButton'"
                        val="never"
                        label="Do not run"
                        dense
                    />
                </q-item>
            </q-list>
        </div>
    </div>
</template>

<script>
import AutoFormDateTimeInput from '../../../plugin-framework/shared-components/auto-form/AutoFormDateTimeInput';

export default {
    name: 'JobFrequencyForm',
    components: {
        AutoFormDateTimeInput,
    },
    props: {
        schedule: {
            type: Object,
            required: true,
        },
        startOpen: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data() {
        return {
            allIntervalTypes: [
                'days',
                'hours',
                'minutes',
                'seconds',
            ],
            optionsShown: this.startOpen,
        };
    },
    computed: {
        displayTimeZoneId() {
            return this.$globalSettings.displayTimeZoneId;
        },
        frequency: {
            get() {
                let frequency = 'never';
                if (this.schedule.singleExecutionTime) {
                    frequency = this.schedule.runNow ? 'now' : 'later';
                }
                else if (this.isIntervalValid(this.schedule.interval)) {
                    frequency = 'interval';
                }
                return frequency;
            },
            set(value) {
                const newSchedule = {};
                if (value === 'now' || value === 'later') {
                    newSchedule.singleExecutionTime = (new Date()).toISOString();
                    newSchedule.runNow = (value === 'now');
                    newSchedule.interval = null;
                }
                if (value === 'later') {
                    newSchedule.singleExecutionTime = this.forceFutureStart(this.schedule.singleExecutionTime);
                    newSchedule.interval = null;
                }
                else if (value === 'interval') {
                    newSchedule.singleExecutionTime = null;
                    newSchedule.interval = this.interval;
                    newSchedule.interval.startDateTime = (new Date()).toISOString();
                }
                this.$emit('schedule', newSchedule);
            },
        },
        singleExecutionTime: {
            get() {
                return this.schedule.singleExecutionTime || (new Date()).toISOString();
            },
            set(value) {
                const newSchedule = Object.assign({}, this.schedule);
                newSchedule.singleExecutionTime = value;
                this.$emit('schedule', newSchedule);
            },
        },
        startDateTime: {
            get() {
                return this.interval.startDateTime;
            },
            set(value) {
                const newSchedule = Object.assign({}, this.schedule);
                newSchedule.interval.startDateTime = value;
                this.$emit('schedule', newSchedule);
            },
        },
        intervalSize: {
            get() {
                const type = this.intervalType;
                if (type === 'days') {
                    return this.interval.daysInterval;
                }
                if (type === 'hours') {
                    return this.interval.hoursInterval;
                }
                if (type === 'minutes') {
                    return this.interval.minutesInterval;
                }
                return this.interval.secondsInterval;
            },
            set(value) {
                const intValue = Math.floor(value);
                this.updateInterval(this.intervalType, intValue);
            },
        },
        intervalType: {
            get() {
                if (this.interval.daysInterval) {
                    return 'days';
                }
                if (this.interval.hoursInterval) {
                    return 'hours';
                }
                if (this.interval.minutesInterval) {
                    return 'minutes';
                }
                return 'seconds';
            },
            set(value) {
                this.updateInterval(value, this.intervalSize);
            },
        },
        interval() {
            if (this.isIntervalValid(this.schedule.interval)) {
                return this.schedule.interval;
            }
            return {
                daysInterval: 1,
                hoursInterval: null,
                minutesInterval: null,
                secondsInterval: null,
                startDateTime: (new Date()).toISOString(),
            };
        },
    },
    methods: {
        isIntervalValid(interval) {
            // A schedule saved to the database with no defined interval will have a startDateTime from the year 0001.
            // Check for a valid interval, consisting of at least one of days/hours/minutes/seconds.
            return interval && !!interval.startDateTime && (!!interval.daysInterval || !!interval.hoursInterval || !!interval.minutesInterval || !!interval.secondsInterval);
        },
        updateInterval(type, size) {
            const newInterval = {
                startDateTime: this.startDateTime,
            };
            if (type === 'days') {
                newInterval.daysInterval = size;
            }
            else if (type === 'hours') {
                newInterval.hoursInterval = size;
            }
            else if (type === 'minutes') {
                newInterval.minutesInterval = size;
            }
            else {
                newInterval.secondsInterval = size;
            }
            const newSchedule = Object.assign({}, this.schedule);
            newSchedule.interval = newInterval;
            this.$emit('schedule', newSchedule);
        },
        forceFutureStart(proposedStartDateString) {
            const dateNow = new Date();
            dateNow.setMinutes(dateNow.getMinutes() + 1);
            if (!proposedStartDateString) {
                return dateNow.toISOString();
            }
            const proposedDate = new Date(proposedStartDateString);
            if (dateNow.getTime() > proposedDate.getTime()) {
                return dateNow.toISOString();
            }
            return proposedStartDateString;
        },
        dateOptions(testDateString) {
            const currentDate = new Date();
            const testDate = new Date(testDateString);
            const until = testDate.getTime() - currentDate.getTime();
            if (until > 0) {
                return true;
            }
            // Check for earlier the same day.
            return (currentDate.getFullYear() === testDate.getFullYear())
                && (currentDate.getMonth() === testDate.getMonth())
                && (currentDate.getDate() === testDate.getDate());
        },
    },
};
</script>

<style scoped>

</style>
