<template>
    <FullPageCard>
        <div
            v-if="forgeResourcesLoaded"
            class="row full-height"
        >
            <div class="col-xs-12 col-md-3 full-height">
                <div class="column full-height">
                    <Sidebar
                        :title="title"
                        :sections="sections"
                        :indicators="indicators"
                        :left-action-buttons="leftActionButtons"
                        :right-action-buttons="rightActionButtons"
                        :left-header-buttons="leftHeaderButtons"
                        :right-header-buttons="rightHeaderButtons"
                    />
                </div>
            </div>
            <div class="relative-position col-xs-12 col-md-9 full-height">
                <div class="chart-grid full-height">
                    <VisualizationContainer
                        v-for="(visualization, index) in visualizations"
                        :key="index"
                        :style="`grid-area: ${visualization.gridArea}; background-color: white` "
                        :visualization="visualization"
                        :title="visualization.title"
                    />
                </div>
                <q-inner-loading :showing="visualizationsDirty">
                    <div/>
                </q-inner-loading>
            </div>
        </div>
        <ForgeLoadState
            v-else
            :resources="forgeResourceElements"
            class="row full-height"
        />
    </FullPageCard>
</template>

<script>
import Sidebar from '../shared-components/Sidebar.vue';
import FullPageCard from '../layouts/FullPageCard.vue';
import mixins from '../../mixins';
import VisualizationContainer from '../shared-components/visualizations/VisualizationContainer.vue';
import ForgeLoadState from '../shared-components/ForgeLoadState.vue';

export default {
    name: 'InteractiveAnalysis',
    components: {
        ForgeLoadState,
        VisualizationContainer,
        FullPageCard,
        Sidebar,
    },
    mixins: [mixins.compute, mixins.lifecycleHooks({ addProps: true })],
    props: {
        title: {
            type: String,
            required: true,
        },
        forgeResources: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },
        visualizationsDirty: {
            type: Boolean,
            required: false,
            default: false,
        },
        sections: {
            type: Array,
            required: true,
        },
        indicators: {
            type: Array,
            required: true,
        },
        leftActionButtons: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },
        rightActionButtons: {
            type: Array,
            required: true,
        },
        visualizations: {
            type: Array,
            required: true,
        },
        leftHeaderButtons: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },
        rightHeaderButtons: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },
    },
};
</script>

<style scoped>
.chart-grid {
    padding-left: 1rem;
    background-color: #fafafa;
    display: grid;
    grid: repeat(12, 1fr) / repeat(12, 1fr);
    grid-gap: 10px;
}
</style>
