<template>
    <CodeEditor
        v-if="!!jobLogs"
        :value="editorContent"
        :options="editorOptions"
    />
    <div
        v-else
        class="text-center"
    >
        <q-spinner
            size="30px"
            color="primary"
        />
        <p>Loading logs...</p>
    </div>
</template>

<script>
import CodeEditor from 'vue2-ace-editor';

export default {
    name: 'JobResultLogsDisplay',
    components: {
        CodeEditor,
    },
    props: {
        jobResultsId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            jobLogs: null,
        };
    },
    computed: {
        editorContent() {
            if (!this.jobLogs || !this.jobLogs.length) {
                return 'No logs found.';
            }
            const content = this.jobLogs.map(log => `[${log.createdAt}] [${log.severity}] ${log.message}`);
            return content.join('\n');
        },
        editorOptions() {
            return {
                readOnly: true,
                minLines: 5,
                maxLines: 20,
            };
        },
    },
    created() {
        this.queryLogs();
    },
    methods: {
        queryLogs() {
            this.$api.data.query({
                type: 'JobLog',
                query: {
                    jobResultId: this.jobResultsId,
                },
            }).then((response) => {
                if (response && response.data) {
                    this.jobLogs = response.data;
                    return;
                }
                this.jobLogs = [];
            }).catch((error) => {
                this.$notify.error('Problem loading logs', error);
                this.jobLogs = [];
            });
        },
    },
};
</script>

<style scoped>

</style>
