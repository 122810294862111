import { GlobalSettingsService } from './global-settings-service.js';

export const GlobalSettings = {
    install(Vue = {}) {
        const service = new GlobalSettingsService();

        // Register the service globally.
        Vue.$globalSettings = service;

        // Register the service on the vue instance.
        Vue.prototype.$globalSettings = service;
    },
};
