import { render, staticRenderFns } from "./InsertVariableDialog.vue?vue&type=template&id=0a60f99b&scoped=true&"
import script from "./InsertVariableDialog.vue?vue&type=script&lang=js&"
export * from "./InsertVariableDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a60f99b",
  null
  
)

export default component.exports