import Vue from 'vue';
import axios from 'axios';
import qs from 'qs';
import { ApiControllerData } from './api-controller-data.js';
import { ApiControllerTasks } from './api-controller-tasks';
import { ApiControllerForge } from './api-controller-forge.js';
import { ApiControllerInfo } from './api-controller-info.js';
import { Logger } from '../logging/logger.js';
import petroJSSettings from '../../../../petrojs-settings.json';
import { ApiControllerVariables } from './api-controller-variables.js';
import { ApiControllerOneTimeJWT } from './api-controller-one-time-jwt.js';
import { ApiControllerAppliances } from './api-controller-appliances';

export class APIService {
    constructor({ baseAPIRoute }) {
        this.log = new Logger({ name: 'APIService', level: Logger.levels.warn });
        Vue.$logging.registerLogger(this.log);

        this.baseURL = baseAPIRoute;
        this.base64EncodeDataFlagKeyName = 'base64EncodeData';

        // Use provided flag or if no flog then set to true in production/false in dev
        const base64EncodeAPIBodies = petroJSSettings.base64EncodeAPIBodies;
        const base64EncodeData = (base64EncodeAPIBodies === true || base64EncodeAPIBodies === false) ? base64EncodeAPIBodies : process.env.NODE_ENV !== 'development';
        this.base64EncodeData = base64EncodeData;

        this.axiosInstance = axios.create({
            baseUrl: this.baseURL,
            // Ensure the arrays of query params are repeated for correct ASP.NET Core serialization
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
            transformRequest: [(data, headers) => {
                // Set default headers to json
                headers['Content-Type'] = 'application/json;charset=utf-8';
                const jsonString = JSON.stringify(data);

                // If data needs to be base64 encoded modify the header and data
                if (this.base64EncodeData) {
                    // Encode all data payloads in base64 with text/plain content-type if flag is set
                    // eslint-disable-next-line no-param-reassign
                    headers['Content-Type'] = 'text/plain';
                    const utf8Data = unescape(encodeURIComponent(jsonString));
                    const base64Data = btoa(utf8Data);
                    return base64Data;
                }
                return jsonString;
            }],
        });

        // Configure interceptors for all requests
        this.axiosInstance.interceptors.request.use(
            config => {
                // Attach the user JWT token to header of all requests
                if (Vue.$auth.token) {
                    config.headers.common.Authorization = `Bearer ${Vue.$auth.token}`;
                }

                return config;
            },
            // Pass all errors through
            error => Promise.reject(error),
        );

        // Configure interceptors for all responses
        this.axiosInstance.interceptors.response.use(
            // Handle responses with 2xx codes but with errors by returning a rejected promise
            response => {
                const serverResponse = response.data;
                if ((serverResponse && serverResponse.data && serverResponse.data.hasErrors) || (serverResponse && serverResponse.data && serverResponse.data.errors && serverResponse.data.errors.length > 0)) {
                    return Promise.reject(serverResponse.data.errors);
                }
                return serverResponse;
            },
            // Handle responses outside of 2xx code range
            error => {
                // Handle responses with 403 error codes by sending to invalid-token page
                if (error && error.response && error.response.status === 403) {
                    const forbidden = `${window.origin}/invalid-token`;
                    if (window.location.href !== forbidden) {
                        window.location = forbidden;
                    }
                }
                return Promise.reject(error);
            },
        );

        // Create the api controllers
        // Data controller contains all data type endpoints
        this.data = new ApiControllerData({
            axiosInstance: this.axiosInstance,
            route: `${this.baseURL}/data`,
        });

        this.tasks = new ApiControllerTasks({
            axiosInstance: this.axiosInstance,
            route: `${this.baseURL}/tasks`,
        });

        this.forge = new ApiControllerForge({
            axiosInstance: this.axiosInstance,
            route: `${this.baseURL}/forge`,
        });

        this.info = new ApiControllerInfo({
            axiosInstance: this.axiosInstance,
            route: `${this.baseURL}/info`,
        });

        this.variables = new ApiControllerVariables({
            axiosInstance: this.axiosInstance,
            route: `${this.baseURL}/variables`,
        });

        this.oneTimeJWT = new ApiControllerOneTimeJWT({
            axiosInstance: this.axiosInstance,
            route: `${this.baseURL}/oneTimeJWT`,
        });

        this.appliances = new ApiControllerAppliances({
            axiosInstance: this.axiosInstance,
            route: `${this.baseURL}/appliances`,
        });

        // Attach the $api service to the global window
        window.pai.$api = this;

        // One off legacy routes to be removed at later date
        // TODO: Remove once legacy routes have been removed/transferred
        this.legacy = {
            convertUnit: (unitType, value, uom, toUom) => this.axiosInstance.get(`${this.baseURL}/UnitsInformation/Convert`, {
                params: {
                    unitType,
                    uom,
                    value,
                    toUom,
                },
            }),
        };
    }

    // Toggles the transmission of message bodies in base64
    toggleBase64EncodeData() {
        this.base64EncodeData = !this.base64EncodeData;
        if (this.base64EncodeData) {
            this.log.warn('Message bodies will now be encoded in base base64.');
        }
        else {
            this.log.warn('Message bodies will now not be encoded.');
        }
    }
}
