import Vue from 'vue';

const compute = {
    namespaced: true,
    state: {
        connectionLive: false,
        elements: {},
    },
    getters: {
        allElementsInfo: (state) => {
            const allElementsInfo = Object.values(state.elements).map(element => {
                const elementInfo = {
                    ...element,
                };
                // Remove the data as it is too heavy and not needed
                delete elementInfo.data;
                return elementInfo;
            });
            return allElementsInfo;
        },
        initialElementsInfo: (state, getters) => {
            const initialElementsInfo = getters.allElementsInfo.filter(element => element.state === 'initial');
            return initialElementsInfo;
        },
        readyElementsInfo: (state, getters) => {
            const readyElementsInfo = getters.allElementsInfo.filter(element => element.state === 'ready');
            return readyElementsInfo;
        },
        staleElementsInfo: (state, getters) => {
            const staleElementsInfo = getters.allElementsInfo.filter(element => element.state === 'stale');
            return staleElementsInfo;
        },
        errorElementsInfo: (state, getters) => {
            const errorElementsInfo = getters.allElementsInfo.filter(element => element.state === 'error');
            return errorElementsInfo;
        },
        failedSetVariableInfo: (state, getters) => {
            // Sort them into a queue of first one requested at the beginning of array so they can be processed in order they were received
            const failedSetVariableInfo = getters.allElementsInfo.filter(element => element.setVariableFailed).sort((a, b) => a.setVariableRequestedAt - b.setVariableRequestedAt);
            return failedSetVariableInfo;
        },
        dirtyElementsInfo: (state, getters) => {
            const dirtyElementsInfo = getters.allElementsInfo.filter(element => element.dirty && !element.isLoading);
            return dirtyElementsInfo;
        },
        healthyElementsInfo: (state, getters) => [...getters.readyElementsInfo, ...getters.initialElementsInfo],
        getElement: state => token => {
            const info = Vue.$compute.forgeParseResource(token);
            const element = state.elements[info.uri];
            return element;
        },
    },
    mutations: {
        createElement(state, { uri }) {
            if (state.elements[uri] === undefined) {
                Vue.set(state.elements, uri, {
                    uri,
                    subscribers: [],
                    state: 'initial',
                    dirty: true,
                    data: [],
                    updatedAt: null,
                    markedDirtyAt: new Date(),
                    // Date of the last request
                    requestedAt: new Date(0),
                    requestAttempt: 0,
                    // Duration of last request (ms)
                    requestDurationMs: null,
                    // true if the elements is actively loading
                    isLoading: false,
                    errors: [],
                });
            }
        },
        addSubscriber(state, { uri, subscriber }) {
            if (state.elements[uri]) {
                state.elements[uri].subscribers = [...state.elements[uri].subscribers, subscriber];
            }
            else {
                Vue.$logging.loggers.ComputeService.debug('addSubscriber: Cannot find uri:', uri);
            }
        },
        removeElement(state, uri) {
            Vue.delete(state.elements, uri);
        },
        setElementErrors(state, { uri, errors }) {
            if (state.elements[uri]) {
                const element = {
                    ...state.elements[uri],
                    state: 'error',
                    updatedAt: new Date(),
                    errors,
                    data: [],
                    isLoading: false,
                };
                Vue.set(state.elements, uri, element);
            }
            else {
                Vue.$logging.loggers.ComputeService.debug('setElementErrors: Cannot find uri:', uri);
            }
        },
        markRequested(state, { uri, attempt }) {
            if (state.elements[uri]) {
                state.elements[uri].requestedAt = new Date();
                state.elements[uri].isLoading = true;
                state.elements[uri].requestAttempt = attempt;
            }
            else {
                Vue.$logging.loggers.ComputeService.debug('markRequested: Cannot find uri:', uri);
            }
        },
        markRequestedSuccess(state, { uri, data }) {
            if (state.elements[uri]) {
                const element = {
                    ...state.elements[uri],
                    state: 'ready',
                    data: Object.freeze(data),
                    updatedAt: new Date(),
                    isLoading: false,
                    requestFailed: false,
                    requestFailedAt: null,
                    requestSucceeded: true,
                    requestAttempt: 0,
                    requestDurationMs: new Date() - state.elements[uri].requestedAt,
                    errors: [],
                    dirty: false,
                };
                Vue.set(state.elements, uri, element);
            }
            else {
                Vue.$logging.loggers.ComputeService.debug('markRequestedSuccess: Cannot find uri:', uri);
            }
        },
        markRequestedError(state, { uri, errors }) {
            if (state.elements[uri]) {
                const element = {
                    ...state.elements[uri],
                    isLoading: false,
                    requestFailed: true,
                    requestFailedAt: new Date(),
                    requestSucceeded: false,
                    requestDurationMs: new Date() - state.elements[uri].requestedAt,
                    errors,
                };
                Vue.set(state.elements, uri, element);
            }
            else {
                Vue.$logging.loggers.ComputeService.debug('markRequestedError: Cannot find uri:', uri);
            }
        },
        markRequestedMaxRetriesReached(state, { uri }) {
            if (state.elements[uri]) {
                state.elements[uri].maxRequestRetriesReached = true;
                state.elements[uri].isLoading = false;
                state.elements[uri].state = 'error';
            }
            else {
                Vue.$logging.loggers.ComputeService.debug('markRequestedMaxRetriesReached: Cannot find uri:', uri);
            }
        },
        markDirty(state, uri) {
            // Only mark items that we are tracking as dirty
            if (state.elements[uri]) {
                state.elements[uri].dirty = true;
                state.elements[uri].markedDirtyAt = new Date();
            }
            else {
                Vue.$logging.loggers.ComputeService.debug('markDirty: Cannot find uri:', uri);
            }
        },
        markStale(state, uri) {
            // Only mark items that we are tracking as dirty[]
            if (state.elements[uri]) {
                state.elements[uri].state = 'stale';
                state.elements[uri].isLoading = false;
            }
            else {
                Vue.$logging.loggers.ComputeService.debug('markStale: Cannot find uri:', uri);
            }
        },
        setConnectionLive(state, connectionLive) {
            state.connectionLive = connectionLive;
        },
        markSetVariable(state, { uri, value, attempt }) {
            if (state.elements[uri]) {
                const element = {
                    ...state.elements[uri],
                    setVariableRequestedAt: new Date(),
                    setVariableValue: value,
                    setVariableAttempt: attempt,
                    setVariableFailed: false,
                    setVariableFailedAt: null,
                    setVariableSucceeded: false,
                    setVariableSucceededAt: null,
                    errors: [],
                };
                Vue.set(state.elements, uri, element);
            }
            else {
                Vue.$logging.loggers.ComputeService.debug('markSetVariable: Cannot find uri:', uri);
            }
        },
        markSetVariableSuccess(state, { uri }) {
            if (state.elements[uri]) {
                const element = {
                    ...state.elements[uri],
                    setVariableFailed: false,
                    setVariableFailedAt: null,
                    setVariableSucceeded: true,
                    setVariableSucceededAt: new Date(),
                    setVariableRequestDurationMs: new Date() - state.elements[uri].setVariableRequestedAt,
                    errors: [],
                };
                Vue.set(state.elements, uri, element);
            }
            else {
                Vue.$logging.loggers.ComputeService.debug('markSetVariableSuccess: Cannot find uri:', uri);
            }
        },
        markSetVariableError(state, { uri, errors }) {
            if (state.elements[uri]) {
                const element = {
                    ...state.elements[uri],
                    setVariableFailed: true,
                    setVariableFailedAt: new Date(),
                    setVariableSucceeded: false,
                    setVariableSucceededAt: null,
                    setVariableRequestDurationMs: new Date() - state.elements[uri].setVariableRequestedAt,
                    errors,
                };
                Vue.set(state.elements, uri, element);
            }
            else {
                Vue.$logging.loggers.ComputeService.debug('markSetVariableError: Cannot find uri:', uri);
            }
        },
        markSetVariableMaxRetriesReached(state, { uri }) {
            if (state.elements[uri]) {
                state.elements[uri].maxSetVariableRetriesReached = true;
                state.elements[uri].state = 'error';
            }
            else {
                Vue.$logging.loggers.ComputeService.debug('markSetVariableMaxRetriesReached: Cannot find uri:', uri);
            }
        },
        clearElements(state) {
            state.elements = {};
        },
    },
};

export default compute;
