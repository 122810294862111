<template>
    <div
        class="row center-row q-pa-sm"
    >
        <GeneralButton
            v-for="btn in actionButtons"
            :key="btn.id"
            :tooltip="btn.tooltip"
            color="secondary"
            dense
            @click="btn.click"
        >
            <div
                class="col-auto inner-button"
            >
                <q-icon
                    :name="btn.icon"
                    size="32px"
                />
                <div class="button-text">
                    {{ btn.text }}
                </div>
            </div>
        </GeneralButton>
    </div>
</template>

<script>
import GeneralButton from '../../../plugin-framework/shared-components/inline-elements/GeneralButton.vue';

export default {
    name: 'ItemActionsBar',
    components: {
        GeneralButton,
    },
    props: {
        actionButtons: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
        };
    },
};
</script>

<style scoped>
.center-row {
    display: flex;
    justify-content: center;
}
.button-text {
    font-size: 11px;
}
.inner-button {
    width: 75px;
    height: 60px;
}
</style>
