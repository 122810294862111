<template>
    <q-td :style="styleForColumn">
        {{ context.value }}
    </q-td>
</template>

<script>
export default {
    name: 'DefaultColumn',
    props: {
        context: {
            type: Object,
            required: true,
        },
        timeZoneId: {
            type: String,
            required: false,
            default: 'UTC',
        },
    },
    computed: {
        styleForColumn() {
            const styles = [];
            const columnDef = this.context.col;

            if (columnDef) {
                const textColor = columnDef.textColor;
                if (textColor) {
                    styles.push(`color: ${textColor}`);
                }

                const backgroundColor = columnDef.backgroundColor;
                if (backgroundColor) {
                    styles.push(`background-color: ${backgroundColor}`);
                }

                const align = columnDef.align;
                if (align) {
                    styles.push(`text-align: ${align}`);
                }
            }
            return styles.join(';');
        },
    },
};
</script>

<style scoped>

</style>
