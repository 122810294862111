<template>
    <q-avatar
        :style="{ backgroundColor: displayBackgroundColor, borderRadius: 0, fontSize: fontSize }"
        text-color="white"
    >
        {{ displayedAcronym }}
    </q-avatar>
</template>

<script>
export default {
    name: 'LetterAvatar',
    props: {
        label: {
            type: String,
            required: true,
        },
        acronym: {
            type: String,
            required: false,
            default: null,
        },
        acronymLength: {
            type: Number,
            required: false,
            default: 2,
        },
        fontSize: {
            type: String,
            required: false,
            default: null,
        },
        backgroundColor: {
            type: String,
            required: false,
            default: null,
        },
    },
    computed: {
        displayedAcronym() {
            if (this.acronym) {
                return this.acronym.slice(0, this.acronymLength);
            }
            const firstLetters = this.label.match(/\b(\w)/g);
            if (!firstLetters) {
                return '';
            }
            // Use the first 3 letters for acronym
            const capitalizedFirstLetters = firstLetters.map(letter => letter.toUpperCase());
            const displayedAcronym = capitalizedFirstLetters.join('').slice(0, this.acronymLength);
            return displayedAcronym;
        },
        displayBackgroundColor() {
            if (this.backgroundColor) {
                return this.backgroundColor;
            }
            const colorHash = this.label ? this.$utils.formatting.getNormHashFromString(this.label) : this.$utils.formatting.getNormHashFromString(this.acronym);
            const colors = ['#6e1e55', '#374649', '#f2c80f', '#707070', '#fe9667', '#bc4b9a',
                '#3699b8', '#dfbfbf', '#959C9E', '#F3DE81', '#8AD4EB', '#B189A4'];
            const increment = 1 / colors.length;
            const colorIndex = Math.trunc(colorHash / increment);
            const backgroundColor = colors[colorIndex];
            return backgroundColor;
        },
    },
};
</script>

<style scoped>

</style>
