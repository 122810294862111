import { FeatureFlagsDialog } from './feature-flags-dialog.js';
import { FeatureFlagsModel } from './feature-flags-model.js';
import { FeatureFlagsTemplate } from './feature-flags-template.js';
import FeatureFlagsPage from './FeatureFlagsPage.vue';

export const FeatureFlags = {
    name: 'FeatureFlags',
    views: {
        FeatureFlagsPage,
    },
    definition({ app, plugin }) {
        return {
            modules: [
                FeatureFlagsTemplate({ app, plugin }),
                FeatureFlagsDialog({ app, plugin }),
            ],
            model: FeatureFlagsModel({ app, plugin }),
        };
    },
};
