<template>
    <PDialog
        v-model="display"
        title="Create New Petron"
        @cancel="display = false"
    >
        <div
            class="text-center q-pa-lg"
        >
            <div class="text-grey-6">
                Petrons are collaborative workspaces.<br>All your data organized in one spot, ready to go.
            </div>
            <img
                :src="require('@/assets/images/petro-shield.png')"
                class="q-pa-lg"
            >
            <q-input
                v-model="name"
                v-cypress="'NewPetronDialog_Name_Input'"
                bottom-slots
                label="Type a name for your new Petron."
                hint="Petron Name"
                :dense="dense"
            />
        </div>
        <template v-slot:footer>
            <GeneralButton
                v-cypress="'NewPetronDialog_Create_Btn'"
                v-close-popup
                color="secondary"
                :flat="false"
                :outline="false"
                :disabled="!name || !name.trim().length"
                label="Create New Petron"
                tooltip="Create the new Petron"
                icon-right="arrow_right_alt"
                @click="create"
            />
        </template>
    </PDialog>
</template>

<script>
import GeneralButton from '../../../plugin-framework/shared-components/inline-elements/GeneralButton.vue';

export default {
    name: 'NewPetronForm',
    components: {
        GeneralButton,
    },
    props: {
        dense: {
            type: Boolean,
            required: false,
            default: true,
        },
        value: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            name: '',
        };
    },
    computed: {
        display: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    },
    methods: {
        create() {
            if (!this.name || this.name.trim() === '') {
                return;
            }
            this.$emit('createPetron', { name: this.name.trim() });
        },
    },
};
</script>

<style scoped>
</style>
