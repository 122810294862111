<template>
    <div
        v-if="forgeResourcesLoaded"
        class="fit custom-scroll-panel"
    >
        <div class="row items-center">
            <div class="col q-pa-md">
                My Running Jobs
            </div>
        </div>
        <q-scroll-area
            class="row results-list-scroll"
            :thumb-style="{ background: 'white' }"
        >
            <div class="col fit">
                <q-list
                    v-cypress="'JobsPanel_RunningJobs_List'"
                >
                    <div
                        v-for="jobResult in userRunningJobs"
                        :key="jobResult.id"
                    >
                        <BackgroundJobItem
                            :job-result="jobResult"
                        />
                        <q-separator
                            spaced
                            inset
                        />
                    </div>
                    <div v-if="userRunningJobs.length === 0">
                        <q-item
                            v-cypress="'JobsPanel_NoJobs_Item'"
                        >
                            <q-item-section class="text-center">
                                No running jobs were found.
                            </q-item-section>
                        </q-item>
                    </div>
                </q-list>
            </div>
        </q-scroll-area>
    </div>
    <ForgeLoadState
        v-else
        :resources="forgeResourceElements"
        class="fit custom-scroll-panel"
    />
</template>

<script>
import { mapGetters } from 'vuex';
import BackgroundJobItem from './BackgroundJobItem.vue';
import mixins from '../mixins';
import ForgeLoadState from '../plugin-framework/shared-components/ForgeLoadState.vue';

export default {
    name: 'JobsPanel',
    components: {
        ForgeLoadState,
        BackgroundJobItem,
    },
    mixins: [mixins.compute],
    computed: {
        ...mapGetters('Jobs', {
            forgeResources: 'runningJobsForgeResources',
            userRunningJobs: 'userRunningJobs',
        }),
    },
};
</script>

<style scoped>
    .results-list-scroll {
        height: calc(100% - 48px - 33px);
    }
    .custom-scroll-panel {
        overflow-y: hidden;
    }
</style>
