<template>
    <q-header
        bordered
        class="title-bar text-white"
    >
        <q-toolbar class="toolbar-app">
            <q-toolbar-title shrink>
                <router-link
                    :to="{ name: 'home' }"
                    @click.native="closeMainMenu"
                >
                    <img
                        class="logo-img"
                        src="@/assets/images/petro-logo.svg"
                        alt="Petro.ai"
                        title="Home"
                    >
                </router-link>
            </q-toolbar-title>

            <AppHeaderPetronInfo
                v-if="petron"
                :petron="petron"
                class="petron-size-cap"
                @navigate="closeMainMenu"
            />
            <GlobalAppDropdown
                v-else
                @navigate="closeMainMenu"
            />

            <q-space/>

            <FacePile
                v-if="forgeResourcesLoaded"
                v-cypress="'AppHeader_FacePile'"
                :user-ids="petronUsers"
                avatar-size="24px"
                class="q-px-md"
            />

            <q-separator
                dark
                vertical
                inset
            />
            <AppHeaderSearchInput/>
            <q-separator
                dark
                vertical
                inset
            />

            <div
                class="row items-center q-pl-md"
                style="flex-wrap: nowrap"
            >
                <ForgeStatusIndicator
                    v-if="userIsAdmin"
                    class="q-px-md"
                    @click="toggleForgePanel"
                />

                <AppJobsButton/>

                <AppFiltersButton/>

                <AppCommentsButton/>
            </div>
            <AppCurrentUser
                :tooltip="userPanelOpen ? 'Hide notifications' : 'Notifications'"
                class="q-pl-md"
                @click.native="toggleUserPanel"
            />
        </q-toolbar>
    </q-header>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import mixins from '../mixins';
import AppCommentsButton from './AppCommentsButton.vue';
import AppCurrentUser from './AppCurrentUser.vue';
import AppFiltersButton from './AppFiltersButton.vue';
import AppJobsButton from './AppJobsButton.vue';
import AppHeaderSearchInput from './AppHeaderSearchInput.vue';
import AppHeaderPetronInfo from './AppHeaderPetronInfo.vue';
import ForgeStatusIndicator from '../plugin-framework/services/compute/components/ForgeStatusIndicator.vue';
import FacePile from '../plugin-framework/shared-components/FacePile.vue';
import GlobalAppDropdown from './GlobalAppDropdown.vue';

export default {
    name: 'AppHeader',
    components: {
        GlobalAppDropdown,
        ForgeStatusIndicator,
        AppHeaderPetronInfo,
        AppHeaderSearchInput,
        AppCurrentUser,
        AppCommentsButton,
        AppFiltersButton,
        AppJobsButton,
        FacePile,
    },
    mixins: [mixins.compute],
    computed: {
        ...mapState('app', {
            mainMenuOpen: 'mainMenuOpen',
            userPanelOpen: 'userPanelOpen',
        }),
        ...mapGetters('Petron', {
            petron: 'petron',
            petronUsers: 'usersInPetron',
            forgeResources: 'forgeResources',
        }),
        userIsAdmin() {
            return this.$auth.userIsAdmin;
        },
    },
    methods: {
        ...mapMutations('app', [
            'toggleForgePanel',
            'toggleUserPanel',
            'toggleMainMenu',
        ]),
        closeMainMenu() {
            if (this.mainMenuOpen) {
                this.toggleMainMenu();
            }
        },
    },
};
</script>

<style scoped>
.toolbar-app {
    padding-left: 0.4rem;
}
.logo-img {
    height: 2.4rem;
    padding-top: 0.3rem;
    cursor: pointer;
}
.title-bar {
    height: 3rem;
}
.petron-size-cap {
    max-width: 50%;
}
</style>
