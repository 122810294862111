<template>
    <div class="row full-height main-menu-parent">
        <div
            class="column text-white icon-offset title-bar-color full-height"
        >
            <div
                class="col"
            >
                <FMainMenuItem
                    v-cypress="'AppMainMenu_Petrons_Icon'"
                    icon="apps"
                    tooltip="My Petrons"
                    :is-selected="showingMode('petrons')"
                    @click="toggleMode('petrons')"
                />

                <FMainMenuItem
                    v-cypress="'AppMainMenu_Shortcuts_Icon'"
                    icon="mdi-link"
                    tooltip="My Shortcuts"
                    :is-selected="showingMode('shortcuts')"
                    @click="toggleMode('shortcuts')"
                />

                <FMainMenuItem
                    v-for="(mainMenuItem, index) in topMainMenuItems"
                    :key="index"
                    v-bind="mainMenuItem.props"
                    v-on="mainMenuItem.events"
                />
            </div>

            <div class="col-auto">
                <FMainMenuItem
                    v-for="(mainMenuItem, index) in bottomMainMenuItems"
                    :key="index"
                    v-bind="mainMenuItem.props"
                    v-on="mainMenuItem.events"
                />

                <FMainMenuItem
                    v-if="isAdmin"
                    v-cypress="'AppMainMenu_Admin_Icon'"
                    icon="settings"
                    tooltip="Admin Panel"
                    :is-selected="showingMode('admin')"
                    @click="toggleMode('admin')"
                />

                <FMainMenuItem
                    icon="help_outline"
                    tooltip="Open Petro.ai Support"
                    :is-selected="showingMode('admin')"
                    @click="openSupportPage"
                />
            </div>
        </div>
        <div
            class="q-mini-drawer-hide expanded-drawer col"
        >
            <AppMenuPetrons
                v-if="mode === 'petrons'"
                class="full-height"
                @collapse="collapsed = true"
            />
            <AppMenuAdmin
                v-else-if="mode === 'admin'"
                class="full-height"
                @collapse="collapsed = true"
            />
            <AppMenuShortcuts
                v-else
                class="full-height"
                @collapse="collapsed = true"
            />
            <div class="close-menu-button">
                <GeneralButton
                    icon="close"
                    tooltip="Close menu"
                    dense
                    color="grey-6"
                    @click="collapsed = true"
                />
            </div>
        </div>
    </div>
</template>

<script>
import AppMenuAdmin from '@/navigation/AppMenuAdmin';
import AppMenuPetrons from './AppMenuPetrons';
import AppMenuShortcuts from './AppMenuShortcuts';
import FMainMenuItem from '../plugin-framework/fragments/FMainMenuItem.vue';

export default {
    name: 'AppMainMenu',
    components: {
        AppMenuAdmin,
        AppMenuPetrons,
        AppMenuShortcuts,
        FMainMenuItem,
    },
    props: {
        value: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            mode: 'petrons',
        };
    },
    computed: {
        isAdmin() {
            return this.$auth.userIsAdmin;
        },
        collapsed: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
        mainMenuItems() {
            return this.$pai.getFragments('main-menu-item');
        },
        topMainMenuItems() {
            return this.mainMenuItems.filter(mainMenuItem => !mainMenuItem.props.bottom);
        },
        bottomMainMenuItems() {
            return this.mainMenuItems.filter(mainMenuItem => mainMenuItem.props.bottom);
        },
    },
    methods: {
        toggleMode(modeClicked) {
            if (!this.collapsed && this.mode === modeClicked) {
                this.collapsed = true;
                return;
            }
            this.collapsed = false;
            this.mode = modeClicked;
        },
        showingMode(mode) {
            if (this.collapsed) {
                return false;
            }
            return this.mode === mode;
        },
        openSupportPage() {
            const supportUrl = 'https://support.petro.ai/';
            window.open(supportUrl, '_blank');
        },
    },
};
</script>

<style lang="stylus">
@import '../css/quasar.variables.styl';
.title-bar-color {
    background-color: $secondary;
}
.expanded-drawer {
    background-color: white;
    flex-grow: 100;
    border-right: 1px solid #00000029;
}
.icon-offset {
    width: 38px;
}
.main-menu-parent {
    overflow: hidden;
}
.menu-item:hover {
    background-color: $primary;
}
.menu-item-active {
    background-color: $primary;
}
.close-menu-button {
    position: absolute;
    top: 10px;
    right: 10px;
}
</style>
