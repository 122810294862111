<template>
    <PDialog
        v-model="display"
        hide-ok-btn
        cancel-btn-text="Close"
        cancel-btn-tooltip="Close"
        :title="title"
        @hide="filter = ''"
    >
        <PDataTable
            v-if="columns"
            v-model="refreshTable"
            :table-data="settingsDocs"
            :columns="columns"
            :pagination-options.sync="paginationOptions"
            :filter="filter"
            dense
            flat
        >
            <template v-slot:top-right>
                <q-input
                    v-model="filter"
                    borderless
                    dense
                    debounce="300"
                    placeholder="Search"
                >
                    <template v-slot:append>
                        <q-icon name="search"/>
                    </template>
                </q-input>
            </template>
            <q-td
                slot="body-cell-createdBy"
                slot-scope="data"
                class="text-left"
            >
                <UserChip
                    v-if="data.row.createdBy"
                    :user-id="$users.getUser(data.row.createdBy).userId"
                />
            </q-td>

            <q-td
                slot="body-cell-actions"
                slot-scope="data"
            >
                <GeneralButton
                    label="Delete"
                    :tooltip="`Delete ${displayName}`"
                    :disabled="!userCanDeleteSetting(data.row)"
                    color="primary"
                    flat
                    @click="$emit('delete', data.row)"
                />
            </q-td>
        </PDataTable>
    </PDialog>
</template>

<script>
import PDataTable from '../tables/data-table/PDataTable.vue';
import GeneralButton from '../inline-elements/GeneralButton.vue';

export default {
    name: 'ManageSettingsDialog',
    components: {
        PDataTable,
        GeneralButton,
    },
    props: {
        // v-model prop to show or hide the modal
        value: {
            type: Boolean,
            required: true,
        },
        // Type of setting used in labels and tooltips
        displayName: {
            type: String,
            required: true,
        },
        // Array of settings docs to browse
        settingsDocs: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            paginationOptions: {
                sortBy: null,
                descending: null,
                page: 1,
                rowsPerPage: 5,
            },
            columns: [
                {
                    name: 'name', field: 'name', label: 'Name', align: 'left',
                },
                {
                    name: 'scope', field: 'scope', label: 'Scope', align: 'left',
                },
                {
                    name: 'createdBy', field: 'createdBy', label: 'Created By', align: 'left',
                },
                {
                    name: 'actions', label: 'Actions',
                },
            ],
            refreshTable: false,
            filter: '',
        };
    },
    computed: {
        display: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            },
        },
        title() {
            return `Manage ${this.displayName} Settings`;
        },
    },
    methods: {
        userCanDeleteSetting(settingsDoc) {
            const userId = this.$auth.user.userId;

            // Admin can delete every setting
            if (this.$auth.userIsAdmin) {
                return true;
            }

            // Petron Owner can delete Petron
            const userIsPetronOwner = this.petron && this.petron.ownerId === userId;
            if (settingsDoc.scope === 'petron' && userIsPetronOwner) {
                return true;
            }

            // Users can delete only their own settings
            if (settingsDoc.scope === 'user' && settingsDoc.parentId === userId) {
                return true;
            }
            return false;
        },
    },
};
</script>

<style scoped>

</style>
