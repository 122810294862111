<template>
    <div>
        <div
            v-if="petrons.length > 0"
            class="row q-col-gutter-md items-stretch q-pa-xs"
        >
            <div
                v-for="petron in petrons"
                :key="petron.id"
                class="col-12 col-xs-10 col-sm-10 col-md-5 col-lg-3 col-auto"
                :style="petronCardStyle"
            >
                <PetronCard
                    :petron="petron"
                    :is-favorite="petron.isFavorite"
                    :editable="canEditPetron(petron)"
                    class="full-height"
                    @remove="$emit('remove', petron)"
                    @pin-to-favorites="$emit('add-to-favorites', petron.id)"
                    @unpin="$emit('remove-from-favorites', petron.id)"
                    @copy="$emit('copy-petron', petron)"
                />
            </div>
        </div>
        <div
            v-else
            class="row q-col-gutter-md"
        >
            <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-auto">
                <p class="q-pa-md">
                    No Petrons.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import PetronCard from './PetronCard.vue';

export default {
    name: 'PetronCardGrid',
    components: {
        PetronCard,
    },
    props: {
        petrons: {
            type: Array,
            required: true,
        },
        isFavorite: {
            type: Boolean,
            required: false,
            default: false,
        },
        petronCardStyle: {
            type: String,
            required: false,
            default: '',
        },
    },
    methods: {
        canEditPetron(petron) {
            return this.$auth.userIsAdmin || (this.$auth.user.userId === petron.ownerId);
        },
    },
};
</script>

<style scoped>

</style>
