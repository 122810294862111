<template>
    <PDialog
        v-model="showModal"
        size="lg"
        title="Select Columns for Display"
        body-height="75vh"
        :ok-btn-test-id="okBtnTestId"
        :cancel-btn-test-id="cancelBtnTestId"
        :ok-btn-disabled="displayColumnsExceedsLimit"
        :ok-btn-tooltip="`${displayColumnsExceedsLimit ? columnCountLimitMessage : 'Save displayed columns'}`"
        @ok="$emit('apply-selection', selectedColumnNames)"
    >
        <div class="row full-height">
            <div class="col">
                <div class="column full-height">
                    <div class="col col-shrink">
                        <p>
                            <strong>Available Columns</strong>
                        </p>

                        <q-input
                            v-model="columnsNotSelectedFilterString"
                            v-cypress="'PColumnSelector_FilterAvailable_Input'"
                            placeholder="Filter columns"
                            type="text"
                            class="q-mb-md"
                            filled
                            dense
                        >
                            <template v-slot:append>
                                <q-icon
                                    name="close"
                                    class="cursor-pointer"
                                    @click="columnsNotSelectedFilterString = ''"
                                />
                            </template>
                        </q-input>

                        <p
                            v-if="columnsNotSelected.length > 0"
                            class="text-grey"
                        >
                            Double-click a column to add it.
                            <span v-if="columnCountWarning > 0">{{ `For best performance, select up to ${columnCountWarning} columns.` }}</span>
                        </p>
                    </div>

                    <div class="col">
                        <q-scroll-area class="full-height">
                            <q-list
                                v-cypress="'PColumnSelector_ColumnsAvailable_List'"
                            >
                                <q-item
                                    v-for="(column, index) in columnsNotSelected"
                                    :key="index"
                                    v-ripple
                                    :active-class="'bg-blue-2 text-grey-8'"
                                    :active="isColumnActive(column.name, 'left')"
                                    clickable
                                    dense
                                    @click="selectColumn(column.name, 'left')"
                                    @dblclick="addColumnToSelection(column)"
                                >
                                    <q-item-section>
                                        {{ column.label }}
                                    </q-item-section>
                                </q-item>
                            </q-list>
                        </q-scroll-area>
                    </div>
                </div>
            </div>

            <div class="col column items-center self-center col-shrink q-mx-xl">
                <GeneralButton
                    v-cypress="'ColumnSelector_AddAll_Button'"
                    class="full-width q-mb-sm"
                    label="Add All"
                    tooltip="Add all filtered columns"
                    dense
                    :flat="false"
                    :outline="true"
                    color="black"
                    @click="addAll"
                />
                <GeneralButton
                    v-cypress="'ColumnSelector_RemoveAll_Button'"
                    class="full-width q-mb-lg"
                    label="Remove All"
                    tooltip="Remove all columns from selection"
                    dense
                    :flat="false"
                    :outline="true"
                    full-width
                    color="black"
                    @click="removeAll"
                />
                <GeneralButton
                    class="full-width q-mb-sm"
                    label="Move To Top"
                    tooltip="Moved selected column to the top of the list"
                    dense
                    full-width
                    :flat="false"
                    :outline="true"
                    color="black"
                    @click="moveToTop"
                />
                <GeneralButton
                    class="full-width q-mb-lg"
                    label="Move To Bottom"
                    tooltip="Move selected column to the bottom of the list"
                    dense
                    full-width
                    :flat="false"
                    :outline="true"
                    color="black"
                    @click="moveToBottom"
                />
                <GeneralButton
                    class="full-width q-mb-sm"
                    label="Move Up"
                    tooltip="Move selected column up"
                    dense
                    full-width
                    :flat="false"
                    :outline="true"
                    color="black"
                    @click="moveUp"
                />
                <GeneralButton
                    class="full-width q-mb-lg"
                    label="Move Down"
                    tooltip="Move selected column down"
                    dense
                    full-width
                    :flat="false"
                    :outline="true"
                    color="black"
                    @click="moveDown"
                />
            </div>

            <div class="col">
                <div class="column full-height">
                    <div class="col col-shrink">
                        <p>
                            <strong>Displayed Columns</strong>
                        </p>

                        <q-input
                            v-model="selectedColumnsFilterString"
                            placeholder="Filter columns"
                            type="string"
                            class="q-mb-md"
                            filled
                            dense
                        >
                            <template v-slot:append>
                                <q-icon
                                    name="close"
                                    class="cursor-pointer"
                                    @click="selectedColumnsFilterString = ''"
                                />
                            </template>
                        </q-input>

                        <p
                            v-if="selectedColumns.length > 0"
                            class="text-grey"
                        >
                            Double-click a column to remove it. Required columns cannot be removed. Drag columns to reorder them.
                        </p>
                    </div>

                    <div class="col">
                        <q-scroll-area class="full-height">
                            <q-list
                                v-cypress="'PColumnSelector_ColumnsSelected_List'"
                            >
                                <draggable
                                    v-model="dragValue"
                                    v-bind="dragOptions"
                                    @start="dragOptions.drag = true"
                                    @end="dragOptions.drag = false"
                                >
                                    <q-item
                                        v-for="(column, index) in filteredSelectedColumns"
                                        :key="index"
                                        v-ripple
                                        :active-class="'bg-blue-2 text-grey-8'"
                                        :active="isColumnActive(column.name, 'right')"
                                        clickable
                                        dense
                                        @click="selectColumn(column.name, 'right')"
                                        @dblclick="removeColumnFromSelection(column)"
                                    >
                                        <q-item-section>
                                            {{ column.label }}
                                        </q-item-section>
                                        <q-item-section avatar>
                                            <q-icon
                                                v-if="column.required"
                                                name="lock"
                                            />
                                            <q-icon
                                                v-else
                                                name="drag_handle"
                                            />
                                        </q-item-section>
                                        <q-tooltip
                                            v-if="column.required"
                                            :delay="750"
                                        >
                                            Required columns cannot be removed
                                        </q-tooltip>
                                    </q-item>
                                </draggable>
                            </q-list>
                        </q-scroll-area>
                    </div>
                </div>
            </div>
        </div>
        <template v-slot:footer-note>
            <div>
                <span
                    class="text-grey-8"
                >
                    {{ columnCountMessage }}
                </span>
                <span
                    v-if="displayColumnsExceedsLimit"
                    class="text-error"
                >
                    {{ columnCountLimitMessage }}
                </span>
                <span
                    v-else-if="columnCountWarning > 0 && selectedColumns.length > columnCountWarning"
                    class="text-error"
                >
                    {{ columnCountWarningMessage }}
                </span>
            </div>
            <q-space/>
        </template>
    </PDialog>
</template>

<script>
import Draggable from 'vuedraggable';
import GeneralButton from '../inline-elements/GeneralButton.vue';
import PDialog from '../../services/pai-components/PDialog.vue';

const deepCopy = function (obj) {
    const data = JSON.stringify(obj);
    return JSON.parse(data);
};

export default {
    name: 'ColumnSelector',
    components: {
        PDialog,
        GeneralButton,
        Draggable,
    },
    props: {
        // v-model value for showing modal
        value: {
            type: Boolean,
            required: true,
        },
        // List of all available column definitions
        allColumns: {
            type: Array,
            required: true,
        },
        // Displayed column names
        displayedColumnNames: {
            type: Array,
            required: true,
        },
        okBtnTestId: {
            type: String,
            required: false,
            default: null,
        },
        cancelBtnTestId: {
            type: String,
            required: false,
            default: null,
        },
        columnCountWarning: {
            type: Number,
            required: false,
            default: 20,
        },
        columnCountLimit: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        // Initialize selected columns with a copy of the currently displayed columns
        const selectedColumns = [];
        this.displayedColumnNames.forEach(columnName => {
            selectedColumns.push(this.allColumns.find(columnDefinition => columnDefinition.name === columnName));
        });

        return {
            selectedColumns,
            columnsNotSelectedFilterString: '',
            selectedColumnsFilterString: '',
            selectedColumnName: null,
            activeSide: null,
            dragOptions: {
                animation: 200,
                drag: true,
                ghostClass: 'ghost',
            },
        };
    },
    computed: {
        showModal: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
        selectedColumnNames() {
            return this.selectedColumns.map(column => column.name);
        },
        filteredSelectedColumns() {
            if (this.selectedColumnsFilterString === '') {
                return this.selectedColumns;
            }
            return this.selectedColumns.filter(column => column.label.toLowerCase().includes(this.selectedColumnsFilterString.toLowerCase()));
        },
        columnsNotSelected() {
            let columnsNotSelected = this.allColumns.filter(colDefinition => !this.selectedColumnNames.includes(colDefinition.name));
            if (this.columnsNotSelectedFilterString !== '') {
                columnsNotSelected = columnsNotSelected.filter(column => column.label.toLowerCase().includes(this.columnsNotSelectedFilterString.toLowerCase()));
            }
            return columnsNotSelected;
        },
        // Returns the currently selected column object
        selectedColumn() {
            if (this.activeSide && this.selectedColumnName) {
                return this.allColumns.find(column => column.name === this.selectedColumnName);
            }
            return null;
        },
        dragValue: {
            get() {
                return deepCopy(this.selectedColumns);
            },
            set(newValue) {
                this.selectedColumns = newValue;
            },
        },
        columnCountMessage() {
            if (this.selectedColumns.length === 1) {
                return '1 column selected.';
            }
            return `${this.selectedColumns.length} columns selected.`;
        },
        columnCountWarningMessage() {
            return `Improve performance by selecting a maximum of ${this.columnCountWarning} columns.`;
        },
        columnCountLimitMessage() {
            return `Select a maximum of ${this.columnCountLimit} columns.`;
        },
        displayColumnsExceedsLimit() {
            return (this.columnCountLimit > 0) && (this.selectedColumns.length > this.columnCountLimit);
        },
    },
    watch: {
        displayedColumnNames(newValue) {
            this.selectedColumns = newValue;
        },
    },
    methods: {
        selectColumn(columnName, side) {
            this.activeSide = side;
            this.selectedColumnName = columnName;
        },
        isColumnActive(columnName, side) {
            return columnName === this.selectedColumnName && side === this.activeSide;
        },
        addAll() {
            this.selectedColumns = [...this.selectedColumns, ...this.columnsNotSelected];
        },
        removeAll() {
            // remove all non-required columns
            this.selectedColumns = this.selectedColumns.filter(column => column.required);
        },
        moveToTop() {
            if (this.activeSide === 'right' && this.selectedColumnName !== null) {
                // Remove the column
                const newSelectedColumns = this.selectedColumns.filter(column => column.name !== this.selectedColumnName);
                // Add the column to the top
                newSelectedColumns.unshift(this.selectedColumn);
                this.selectedColumns = newSelectedColumns;
            }
        },
        moveToBottom() {
            if (this.activeSide === 'right' && this.selectedColumnName !== null) {
                // Remove the column
                const newSelectedColumns = this.selectedColumns.filter(column => column.name !== this.selectedColumnName);
                // Add the column at the bottom
                newSelectedColumns.push(this.selectedColumn);
                this.selectedColumns = newSelectedColumns;
            }
        },
        moveUp() {
            if (this.activeSide === 'right' && this.selectedColumnName !== null) {
                const selectedColumnIndex = this.selectedColumns.findIndex(column => column.name === this.selectedColumnName);

                // Exit if column is not found or is already at top
                if (selectedColumnIndex === -1 || selectedColumnIndex === 0) {
                    return;
                }
                // Remove the column
                const removedColumn = this.selectedColumns.splice(selectedColumnIndex, 1)[0];
                // Insert column in new spot
                this.selectedColumns.splice(selectedColumnIndex - 1, 0, removedColumn);
            }
        },
        moveDown() {
            if (this.activeSide === 'right' && this.selectedColumnName !== null) {
                const selectedColumnIndex = this.selectedColumns.findIndex(column => column.name === this.selectedColumnName);

                // Exit if column is not found or is already at bottom
                if (selectedColumnIndex === -1 || selectedColumnIndex >= this.selectedColumns.length) {
                    return;
                }
                // Remove the column
                const removedColumn = this.selectedColumns.splice(selectedColumnIndex, 1)[0];
                // Insert column in new spot
                this.selectedColumns.splice(selectedColumnIndex + 1, 0, removedColumn);
            }
        },
        addColumnToSelection(column) {
            this.selectedColumns.push(column);
        },
        removeColumnFromSelection(column) {
            if (column.required) {
                this.$notify.info('Required columns cannot be removed');
            }
            else {
                this.selectedColumns = this.selectedColumns.filter(selectedColumn => selectedColumn.name !== column.name);
            }
        },
    },
};
</script>

<style scoped>

</style>
