import { ROUTES, APP_TITLE } from '../constants.js';

export function PetroPlusGlobalAppCard({ app, plugin }) {
    return {
        type: 'fragment',
        target: 'global-app-card',
        priority: 1,
        options() {
            return {
                props: {
                    title: APP_TITLE,
                    description: 'Browse technical services available for purchase through Petro.ai.',
                    launchRoute: { name: ROUTES.PetroPlus },
                    hide: !(app.$auth.userIsAdmin || app.$auth.userIsSupportEngineer),
                },
            };
        },
    };
}
