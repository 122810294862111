import jwtDecode from 'jwt-decode';
import Vue from 'vue';
import { Logger } from '../logging/logger.js';

class AuthService {
    constructor(options) {
        this.log = new Logger({ name: 'AuthService', level: Logger.levels.warn });
        Vue.$logging.registerLogger(this.log);

        this.localStorageKeyName = 'petroai-token';
        this.allowedOrigins = options.allowedMessageOrigins;
        this.ssoUrl = options.ssoUrl;
        this.store = options.store;
        this.moduleName = options.moduleName;

        this.__afterSignInHooks = {};
        this.__beforeSignOutHooks = {};
        this.__afterInvalidTokenHooks = {};

        this.__installGlobalTokenListener();
        this.__installGlobalStorageListener();

        // Attach the $auth service to the global window
        window.pai.$auth = this;
    }

    get user() {
        return this.store.state.auth.user;
    }

    set user(user) {
        this.store.commit(`${this.moduleName}/setUser`, user);
    }

    get userIsAdmin() {
        return this.store.state.auth.user && this.store.state.auth.user.roles.includes('admin');
    }

    get userIsSupportEngineer() {
        return this.store.state.auth.user && this.store.state.auth.user.roles.includes('support-engineer');
    }

    get token() {
        return this.__getTokenFromLocalStorage();
    }

    get signedIn() {
        return !!this.store.state.auth.user;
    }

    get hasJwt() {
        return !!this.__getTokenFromLocalStorage();
    }

    // Public Methods
    checkForSignedInUser() {
        // Get the user from local storage if there is one
        const jwt = this.__getTokenFromLocalStorage();

        if (AuthService.isTokenExpired(jwt)) {
            this.__removeTokenFromLocalStorage();
        }
        else {
            this.__signIn(jwt);
        }
    }

    signOut() {
        this.__runBeforeSignOutHooks(this.user);
        this.user = null;
        this.__removeTokenFromLocalStorage();
        this.log.debug('User signed out');
    }

    // Shows the SSO window to retrieve user from Portal
    getSSOUser({
        popupWidth = 650,
        popupHeight = 670,
        popupTitle = 'PortalLogin',
    } = {}) {
        return new Promise((resolve, reject) => {
            const ssoUrl = this.ssoUrl;
            const width = window.innerWidth || document.documentElement.clientWidth;
            const height = window.innerHeight || document.documentElement.clientHeight;
            const left = (width - popupWidth) / 2;
            const top = (height - popupHeight) / 2;

            const ssoWindow = window.open(ssoUrl, popupTitle, `scrollbars=yes, width=${popupWidth}, height=${popupHeight}, top=${top}, left=${left}`);

            if (window && ssoWindow && window.focus) {
                ssoWindow.focus();
            }

            // start polling for login event
            const interval = setInterval(() => {
                if (this.signedIn) {
                    clearInterval(interval);
                    resolve(this.user);
                }
            }, 250);
        });
    }

    // Register a hook to be called after a user has signed in that is passed the user
    afterSignIn(name, callback) {
        this.__afterSignInHooks[name] = callback;
    }

    // Register a hook to be called before a user has signed out
    beforeSignOut(name, callback) {
        this.__beforeSignOutHooks[name] = callback;
    }

    // Register a hook to be called after a user has attempted a sign-in that resulted in an invalid token.
    afterInvalidToken(name, callback) {
        this.__afterInvalidTokenHooks[name] = callback;
    }

    // Private Methods
    // Watches for a token from the SSO endpoint and saves it to localstorage and the store
    __installGlobalTokenListener() {
        window.addEventListener('message', (messageEvent) => {
            const allowedOrigins = this.allowedOrigins;

            // Only allow messages from valid portal origins
            if (allowedOrigins.includes(messageEvent.origin)) {
                const jwt = messageEvent.data;
                this.__signIn(jwt);
            }
        });
    }

    // Handle the case where the user clears their browser cache and we need to update the store
    __installGlobalStorageListener() {
        window.addEventListener('storage', (storageEvent) => {
            const tokenRemoved = storageEvent.key === this.localStorageKeyName && storageEvent.newValue === null;
            const localStorageCleared = storageEvent.key === null;
            if (tokenRemoved || localStorageCleared) {
                this.user = null;
            }
        });
    }

    // Sign in the user by validating with server
    __signIn(jwt) {
        Vue.$api.axiosInstance.get('/api/Validate', {
            headers: {
                Authorization: `Bearer ${jwt}`,
            },
        })
        .then(response => {
            if (response === 'Validated') {
                this.__saveTokenToLocalStorage(jwt);
                this.user = AuthService.decodeToken(jwt);
                this.log.debug('User signed in');
                this.__runAfterSignInHooks(this.user);
            }
            else {
                this.log.warn('User attempted sign in with invalid JWT');
                this.__runAfterInvalidTokenHooks();
            }
        })
        .catch(error => {
            this.log.error('Unable to validate JWT with server:', error);
            this.__runAfterInvalidTokenHooks();
        });
    }

    __runAfterSignInHooks(user) {
        const hookNames = Object.keys(this.__afterSignInHooks);
        hookNames.forEach(hookName => {
            this.log.debug(`Running ${hookName} after sign in hook.`);
            this.__afterSignInHooks[hookName](user);
        });
    }

    __runBeforeSignOutHooks(user) {
        const hookNames = Object.keys(this.__beforeSignOutHooks);
        hookNames.forEach(hookName => {
            this.log.debug(`Running ${hookName} before sign out hook.`);
            this.__beforeSignOutHooks[hookName](user);
        });
    }

    __runAfterInvalidTokenHooks() {
        const hookNames = Object.keys(this.__afterInvalidTokenHooks);
        hookNames.forEach(hookName => {
            this.log.debug(`Running ${hookName} after-invalid-token hook.`);
            this.__afterInvalidTokenHooks[hookName]();
        });
    }

    __saveTokenToLocalStorage(token) {
        localStorage.setItem(this.localStorageKeyName, token);
    }

    __getTokenFromLocalStorage() {
        const token = localStorage.getItem(this.localStorageKeyName);
        return token;
    }

    __removeTokenFromLocalStorage() {
        localStorage.removeItem(this.localStorageKeyName);
    }

    // Static Methods
    static decodeToken(jwt) {
        try {
            const payload = jwtDecode(jwt);
            return payload;
        }
        catch (error) {
            return null;
        }
    }

    static isTokenExpired(token) {
        const payload = AuthService.decodeToken(token);
        const currentEpoch = Math.trunc(new Date().getTime() / 1000);
        if (payload && currentEpoch <= payload.exp) {
            return false;
        }
        return true;
    }
}

export default AuthService;
