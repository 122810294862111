export class ApiControllerTasks {
    constructor({ axiosInstance, route } = {}) {
        // Configured base axios instance
        this.axiosInstance = axiosInstance;

        // Route appended to the axios instance baseURL
        this.route = route;
    }


    getTasks({ name = null } = {}) {
        return this.axiosInstance.get(this.route, {
            name,
        });
    }

    /**
     * Performs a Task validation API call.
     * @param pid - Petron ID.
     * @param taskType - Task type.
     * @param options - Task options to validate.
     * @returns {*}
     */
    validateTask({ pid, taskType, options } = {}) {
        return this.axiosInstance.post(`${this.route}/validate`, {
            pid,
            taskType,
            options,
        });
    }

    // Runs a task with provided options
    runTask({
        pid = null,
        taskType,
        options = {},
        cronJobId = null,
        runInBackground = false,
        aggregateExceptions = true,
    } = {}) {
        // Ensure that task type is provided
        if (!taskType) {
            return Promise.reject(new Error('runTask must be provided a taskType'));
        }

        return this.axiosInstance.post(this.route, {
            pid,
            taskType,
            options,
            cronJobId,
            asJolt: !runInBackground,
            aggregateExceptions,
        });
    }

    /**
     * Cancels a task.
     * @param taskId - ID of the task to cancel.
     * @returns {*}
     */
    cancelTask({ taskId } = {}) {
        return this.axiosInstance.delete(this.route, {
            data: {
                taskId,
            },
        });
    }
}
