<template>
    <div>
        {{ resource }}
        {{ value }}
    </div>
</template>

<script>
export default {
    name: 'ResourceView',
    props: {
        resource: {
            type: String,
            required: true,
        },
    },
    computed: {
        forgeResources() {
            return [this.resource];
        },
        value() {
            return this.forgeElement(this.resource);
        },
    },
    async beforeMount() {
        this.connect();
    },
    beforeDestroy() {
        if (this.forgeDisposableReference) {
            this.forgeDisposableReference.dispose();
        }
    },
    methods: {
        forgeElement(name) {
            return this.$store.getters['compute/getElement'](name);
        },
        connect() {
            if (this.forgeDisposableReference) {
                this.forgeDisposableReference.dispose();
            }
            // Subscribe to the forge resources using name of component with it's uid as subscriber
            this.forgeDisposableReference = this.$compute.forgeSubscribeElements(`${this.$options.name}-${this._uid}`, this.forgeResources);
        },
    },
};
</script>

<style scoped>

</style>
