<template>
    <div
        v-show="showSuggestions"
        ref="suggestions"
        class="suggestion-list"
    >
        <template v-if="hasResults">
            <div
                v-for="(suggestion, index) in displayedSuggestions.slice(0, displayedSuggestionCount)"
                :key="suggestion.id"
                class="suggestion-list__item"
                :class="{ 'is-selected': currentSuggestionIndex === index }"
                @click="selectSuggestion(suggestion)"
            >
                {{ suggestion.label }}
            </div>
        </template>
        <div
            v-else
            class="suggestion-list__item is-empty"
        >
            {{ noSuggestionsText }}
        </div>
    </div>
</template>

<script>
import { Editor } from 'tiptap';
import tippy from 'tippy.js';

export default {
    name: 'EditorSuggestion',
    props: {
        editor: {
            type: Editor,
            required: true,
        },
        editable: {
            type: Boolean,
            required: true,
        },
        extension: {
            type: Function,
            required: true,
        },
        suggestions: {
            type: Function,
            required: true,
            default: () => [],
        },
        // Provides a selector to mount the popup to, use jquery type selector syntax
        popupMountSelector: {
            type: String,
            required: true,
        },
        // Number of suggestions to display in the popup
        displayedSuggestionCount: {
            type: Number,
            required: false,
            default: 5,
        },
        noSuggestionsText: {
            type: String,
            required: false,
            default: 'No suggestions',
        },
        // Used to resolve a query that has no suggestions, inserting the result on exiting
        // Takes the user typed query as only argument
        // Returns an object with id and label fields to insert as suggestion
        resolveUnmatchedSuggestion: {
            type: Function,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            query: null,
            suggestionRange: null,
            filteredSuggestions: [],
            currentSuggestionIndex: 0,
            insertSuggestion: () => {},
        };
    },
    computed: {
        displayedSuggestions: {
            get() {
                return this.filteredSuggestions;
            },
            set(suggestions) {
                if (this.resolveUnmatchedSuggestion) {
                    const newSuggestion = this.resolveUnmatchedSuggestion(this.query);

                    // Check to make sure the new suggestion doesn't already exist or if the new suggestion is null
                    const newSuggestionAlreadyExists = suggestions.some(suggestion => suggestion.id === newSuggestion.id);
                    if (newSuggestionAlreadyExists || !newSuggestion) {
                        this.filteredSuggestions = suggestions;
                    }
                    // If the new suggestion exists and is unique then show it
                    else {
                        this.filteredSuggestions = [newSuggestion, ...suggestions];
                    }
                }
                // Only known suggestions will populate list
                else {
                    this.filteredSuggestions = suggestions;
                }
            },
        },
        showSuggestions() {
            return this.editable && (this.query || this.hasResults);
        },
        hasResults() {
            return this.displayedSuggestions.length;
        },
    },
    created() {
        this.initializeExtension();
    },
    beforeDestroy() {
        this.destroyPopup();
    },
    methods: {
        initializeExtension() {
            // eslint-disable-next-line new-cap
            const extension = new this.extension({
                items: this.suggestions,
                // Called when the suggestion starts
                onEnter: this.onEnter,
                // Called when the suggestion has changed
                onChange: this.onChange,
                // Called when suggestion is cancelled
                onExit: this.onExit,
                // Called on every keydown while suggestion is active
                onKeyDown: this.onKeyDown,
            });

            // TODO: Find out way to dynamically add new extension without re-initializing the editor
            this.editor.init({
                ...this.editor.defaultOptions,
                ...this.editor.options,
                extensions: [
                    ...this.editor.options.extensions,
                    extension,
                ],
            });
        },
        onEnter({
            items, query, range, command, virtualNode,
        }) {
            this.query = query;
            this.displayedSuggestions = items;
            this.suggestionRange = range;
            this.renderPopup(virtualNode);
            // save off the command so we can call from view
            this.insertSuggestion = command;
        },
        onChange({
            items, query, range, virtualNode,
        }) {
            this.query = query;
            this.displayedSuggestions = items;
            this.suggestionRange = range;
            this.currentSuggestionIndex = 0;
            this.renderPopup(virtualNode);
        },
        onExit() {
            // Reset all data
            this.query = null;
            this.displayedSuggestions = [];
            this.suggestionRange = null;
            this.currentSuggestionIndex = 0;
            // Destroy the popup
            this.destroyPopup();
        },
        onKeyDown({ event }) {
            if (event.code === 'ArrowUp') {
                this.upHandler();
                return true;
            }
            if (event.code === 'ArrowDown') {
                this.downHandler();
                return true;
            }
            if (event.code === 'Enter') {
                this.confirmSuggestionHandler();
                return true;
            }
            if (event.code === 'Space') {
                this.confirmSuggestionHandler();
                return true;
            }
            return false;
        },
        // renders a popup with suggestions
        // tiptap provides a virtualNode object for using popper.js (or tippy.js) for popups
        renderPopup(node) {
            if (this.popup) {
                return;
            }
            // ref: https://atomiks.github.io/tippyjs/v6/all-props/
            this.popup = tippy(this.popupMountSelector, {
                getReferenceClientRect: node.getBoundingClientRect,
                appendTo: () => document.body,
                interactive: true,
                content: this.$refs.suggestions,
                trigger: 'mouseenter', // manual
                showOnCreate: true,
                theme: 'dark',
                placement: 'bottom-start',
                inertia: true,
                duration: [400, 200],
            });
        },
        destroyPopup() {
            if (this.popup) {
                this.popup = null;
            }
            if (this.popup && this.popup[0]) {
                this.popup[0].destroy();
            }
        },
        // navigate to the previous item, if it's the first item, navigate to the last one
        upHandler() {
            this.currentSuggestionIndex = ((this.currentSuggestionIndex + this.displayedSuggestionCount) - 1) % this.displayedSuggestionCount;
        },
        // navigate to the next item, if it's the last item, navigate to the first one
        downHandler() {
            this.currentSuggestionIndex = (this.currentSuggestionIndex + 1) % this.displayedSuggestionCount;
        },
        confirmSuggestionHandler() {
            const suggestion = this.displayedSuggestions[this.currentSuggestionIndex];
            if (suggestion) {
                this.selectSuggestion(suggestion);
            }
        },
        // Replace our text with the suggestion, important to pass the range of the selection
        selectSuggestion(suggestion) {
            this.insertSuggestion({
                range: this.suggestionRange,
                attrs: {
                    id: suggestion.id,
                    label: suggestion.label,
                },
            });
            this.editor.focus();
        },
    },
};
</script>

<style lang="stylus">
@import '../../../../css/quasar.variables.styl';

.suggestion-list {
    padding: 0.2rem;
    border: 2px solid rgba($grey-10, 0.1);
    font-size: 0.8rem;
    font-weight: bold;
}
.suggestion-list__no-results {
    padding: 0.2rem 0.5rem;
}

.suggestion-list__item {
    border-radius: 5px;
    padding: 0.2rem 0.5rem;
    margin-bottom: 0.2rem;
    cursor: pointer;

    &:last-child {
        margin-bottom: 0;
    }

    &.is-selected,
    &:hover {
        background-color: rgba($light, 0.2);
    }

    &.is-empty {
        opacity: 0.5;
    }
}
</style>
