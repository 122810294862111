<template>
    <q-page
        padding
    >
        <div
            v-if="forgeResourcesLoaded"
            class="col"
        >
            <q-input
                v-model="searchText"
                v-cypress="'TheLibraryPage_Search_Input'"
                borderless
                dense
                filled
                clearable
                debounce="300"
                placeholder="Search by Petron name"
                class="q-mr-md q-mb-md"
            >
                <template v-slot:prepend>
                    <q-icon name="search"/>
                </template>
            </q-input>

            <div
                v-if="filteredFavoritePetrons.length > 0"
                class="row q-mb-md q-pr-lg"
            >
                <div class="col">
                    <q-tabs
                        v-model="topTab"
                        align="left"
                        active-color="primary"
                    >
                        <q-tab
                            name="favorites"
                            :label="`Favorites (${filteredFavoritePetrons.length})`"
                        />
                    </q-tabs>
                </div>
            </div>

            <q-tab-panels
                v-if="filteredFavoritePetrons.length > 0"
                v-model="topTab"
                animated
                class="q-pr-lg"
            >
                <q-tab-panel
                    name="favorites"
                    class="q-px-none"
                >
                    <PetronCardGrid
                        :petrons="filteredFavoritePetrons"
                        @remove="displayDeletePetronDialog"
                        @add-to-favorites="addToFavoritesFunc"
                        @remove-from-favorites="removeFromFavoritesFunc"
                        @copy-petron="displayCopyPetronDialog"
                    />
                </q-tab-panel>
            </q-tab-panels>

            <div class="row q-mt-md q-pr-lg">
                <div class="col">
                    <q-tabs
                        v-model="bottomTab"
                        align="left"
                        active-color="primary"
                    >
                        <q-tab
                            v-cypress="'TheLibraryPage_OwnerTab'"
                            name="owner"
                            :label="`Owner (${filteredOwnedPetrons.length})`"
                        />
                        <q-tab
                            v-cypress="'TheLibraryPage_CollaboratorTab'"
                            name="collaboratorOn"
                            :label="`Collaborator (${filteredCollaboratorPetrons.length})`"
                        />
                        <q-tab
                            v-cypress="'TheLibraryPage_PublicTab'"
                            name="public"
                            :label="`Public (${filteredPublicPetrons.length})`"
                        />
                        <q-tab
                            v-if="$auth.userIsAdmin"
                            v-cypress="'TheLibraryPage_AllTab'"
                            name="all"
                            :label="`All (${filteredPetrons.length})`"
                        />
                    </q-tabs>
                </div>
            </div>

            <q-tab-panels
                v-model="bottomTab"
                animated
                class="q-pr-lg"
            >
                <q-tab-panel
                    name="owner"
                    class="q-px-none"
                >
                    <PetronCardGrid
                        :petrons="filteredOwnedPetrons"
                        @remove="displayDeletePetronDialog"
                        @add-to-favorites="addToFavoritesFunc"
                        @remove-from-favorites="removeFromFavoritesFunc"
                        @copy-petron="displayCopyPetronDialog"
                    />
                </q-tab-panel>

                <q-tab-panel
                    name="collaboratorOn"
                    class="q-px-none"
                >
                    <PetronCardGrid
                        :petrons="filteredCollaboratorPetrons"
                        @remove="displayDeletePetronDialog"
                        @add-to-favorites="addToFavoritesFunc"
                        @remove-from-favorites="removeFromFavoritesFunc"
                        @copy-petron="displayCopyPetronDialog"
                    />
                </q-tab-panel>

                <q-tab-panel
                    name="public"
                    class="q-px-none"
                >
                    <PetronCardGrid
                        :petrons="filteredPublicPetrons"
                        @remove="displayDeletePetronDialog"
                        @add-to-favorites="addToFavoritesFunc"
                        @remove-from-favorites="removeFromFavoritesFunc"
                        @copy-petron="displayCopyPetronDialog"
                    />
                </q-tab-panel>

                <q-tab-panel
                    v-if="$auth.userIsAdmin"
                    name="all"
                    class="q-px-none"
                >
                    <PetronCardGrid
                        :petrons="filteredPetrons"
                        @remove="displayDeletePetronDialog"
                        @copy-petron="displayCopyPetronDialog"
                        @add-to-favorites="addToFavoritesFunc"
                        @remove-from-favorites="removeFromFavoritesFunc"
                    />
                </q-tab-panel>
            </q-tab-panels>

            <q-page-sticky
                position="bottom-right"
                :offset="[40, 18]"
            >
                <GeneralButton
                    v-cypress="'TheLibraryPage_AddPetron_Button'"
                    :fab="true"
                    :flat="false"
                    :outline="false"
                    icon="ion-add"
                    color="primary"
                    tooltip="Add a new Petron"
                    @click="showCreateDialog = true"
                />
            </q-page-sticky>
        </div>

        <ForgeLoadState
            v-else
            class="col"
            :resources="forgeResourceElements"
        />

        <NewPetronDialog
            v-model="showCreateDialog"
            @createPetron="createPetronFunc"
        />

        <CopyPetronDialog
            v-model="showCopyDialog"
            :petron="petronToCopy"
            @copy="copyPetronFunc"
        />
    </q-page>
</template>

<script>
import NewPetronDialog from './NewPetronDialog.vue';
import mixins from '../../../mixins';
import PetronCardGrid from './PetronCardGrid.vue';
import GeneralButton from '../../../plugin-framework/shared-components/inline-elements/GeneralButton.vue';
import CopyPetronDialog from './CopyPetronDialog.vue';
import ForgeLoadState from '../../../plugin-framework/shared-components/ForgeLoadState';

export default {
    name: 'TheLibraryPage',
    components: {
        ForgeLoadState,
        CopyPetronDialog,
        GeneralButton,
        PetronCardGrid,
        NewPetronDialog,
    },
    mixins: [mixins.compute],
    props: {
        forgeResources: {
            type: Array,
            required: false,
            default: () => [],
        },
        allPetrons: {
            type: Array,
            required: true,
        },
        favoritePetrons: {
            type: Array,
            required: false,
            default: () => [],
        },
        collaboratorPetrons: {
            type: Array,
            required: false,
            default: () => [],
        },
        ownedPetrons: {
            type: Array,
            required: false,
            default: () => [],
        },
        createPetronFunc: {
            type: Function,
            required: true,
        },
        removePetronFunc: {
            type: Function,
            required: true,
        },
        addToFavoritesFunc: {
            type: Function,
            required: true,
        },
        removeFromFavoritesFunc: {
            type: Function,
            required: true,
        },
        copyPetronFunc: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            topTab: 'favorites',
            bottomTab: 'owner',
            newPetronName: '',
            showAddDialog: false,
            showCreateDialog: false,
            focusedPetron: null,
            petronToAdd: null,
            validatingSelection: false,
            canAdd: false,
            searchText: '',
            expandResources: true,
            petronToCopy: null,
            showCopyDialog: false,
        };
    },
    computed: {
        // Add a flag for favorite
        libraryPetrons() {
            const favoritePetronIds = this.favoritePetrons.map(x => x.id);
            const libraryPetrons = this.allPetrons.filter(petron => petron.name).map(petron => {
                return { ...petron, isFavorite: favoritePetronIds.includes(petron.id) };
            });
            libraryPetrons.sort((a, b) => a.name.localeCompare(b.name));
            return libraryPetrons;
        },
        // Add a flag for favorite.
        userFavoritePetrons() {
            const userFavoritePetrons = this.favoritePetrons.filter(petron => petron.name).map(petron => {
                return { ...petron, isFavorite: true };
            });
            userFavoritePetrons.sort((a, b) => a.name.localeCompare(b.name));
            return userFavoritePetrons;
        },
        // Add a flag for favorite.
        userCollaboratorPetrons() {
            const favoritePetronIds = this.favoritePetrons.map(x => x.id);
            const userCollaboratorPetrons = this.collaboratorPetrons.filter(petron => petron.name).map(petron => {
                return { ...petron, isFavorite: favoritePetronIds.includes(petron.id) };
            });
            userCollaboratorPetrons.sort((a, b) => a.name.localeCompare(b.name));
            return userCollaboratorPetrons;
        },
        // Add a flag for favorite.
        userOwnedPetrons() {
            const favoritePetronIds = this.favoritePetrons.map(x => x.id);
            const userOwnedPetrons = this.ownedPetrons.filter(petron => petron.name).map(petron => {
                return { ...petron, isFavorite: favoritePetronIds.includes(petron.id) };
            });
            userOwnedPetrons.sort((a, b) => a.name.localeCompare(b.name));
            return userOwnedPetrons;
        },
        filteredCollaboratorPetrons() {
            if (this.searchText) {
                return this.userCollaboratorPetrons.filter(petron => petron.name.toLowerCase().includes(this.searchText.toLowerCase()));
            }
            return this.userCollaboratorPetrons;
        },
        filteredFavoritePetrons() {
            if (this.searchText) {
                return this.userFavoritePetrons.filter(petron => petron.name.toLowerCase().includes(this.searchText.toLowerCase()));
            }
            return this.userFavoritePetrons;
        },
        filteredPetrons() {
            if (this.searchText) {
                return this.libraryPetrons.filter(petron => petron.name.toLowerCase().includes(this.searchText.toLowerCase()));
            }
            return this.libraryPetrons;
        },
        allPetronsPublic() {
            return this.libraryPetrons.filter(petronDoc => petronDoc.isPublic);
        },
        filteredPublicPetrons() {
            if (this.searchText) {
                return this.allPetronsPublic.filter(petron => petron.name.toLowerCase().includes(this.searchText.toLowerCase()));
            }
            return this.allPetronsPublic;
        },
        userId() {
            return this.$auth.user.userId;
        },
        filteredOwnedPetrons() {
            if (this.searchText) {
                return this.userOwnedPetrons.filter(petron => petron.name.toLowerCase().includes(this.searchText.toLowerCase()));
            }
            return this.userOwnedPetrons;
        },
    },
    methods: {
        displayCopyPetronDialog(petronToCopy) {
            this.petronToCopy = petronToCopy;
            this.showCopyDialog = true;
        },
        displayDeletePetronDialog(petronToRemove) {
            this.removePetronFunc({ petronToRemove });
        },
    },
};
</script>

<style scoped>
</style>
