import { ROUTES } from '../constants.js';

export function DataStorageModel({ app, plugin }) {
    return {
        fields: {},
        getters: {
            // Create a reactive getter to determine if the browser route is active
            dataStorageRouteActive: (state, getters, rootState) => rootState.route.name === ROUTES.DataStorage,
            indicatorColor: () => {
                if (app.$environment.overLimit) {
                    return 'error';
                }
                if (app.$environment.approachingLimit) {
                    return 'warning';
                }
                return '';
            },
            indicatorIcon: () => {
                const used = app.$environment.fractionUsed;
                switch (true) {
                    case (used >= 0 && used < 0.05):
                        return 'picon-pie-0';
                    case (used >= 0.05 && used < 0.1):
                        return 'picon-pie-5';
                    case (used >= 0.1 && used < 0.15):
                        return 'picon-pie-10';
                    case (used >= 0.15 && used < 0.2):
                        return 'picon-pie-15';
                    case (used >= 0.2 && used < 0.35):
                        return 'picon-pie-20';
                    case (used >= 0.25 && used < 0.3):
                        return 'picon-pie-25';
                    case (used >= 0.3 && used < 0.35):
                        return 'picon-pie-30';
                    case (used >= 0.35 && used < 0.4):
                        return 'picon-pie-35';
                    case (used >= 0.4 && used < 0.45):
                        return 'picon-pie-40';
                    case (used >= 0.45 && used < 0.5):
                        return 'picon-pie-45';
                    case (used >= 0.5 && used < 0.55):
                        return 'picon-pie-50';
                    case (used >= 0.55 && used < 0.6):
                        return 'picon-pie-55';
                    case (used >= 0.6 && used < 0.65):
                        return 'picon-pie-60';
                    case (used >= 0.65 && used < 0.7):
                        return 'picon-pie-65';
                    case (used >= 0.7 && used < 0.75):
                        return 'picon-pie-70';
                    case (used >= 0.75 && used < 0.8):
                        return 'picon-pie-75';
                    case (used >= 0.8 && used < 0.85):
                        return 'picon-pie-80';
                    case (used >= 0.85 && used < 0.9):
                        return 'picon-pie-85';
                    case (used >= 0.9 && used < 0.95):
                        return 'picon-pie-90';
                    case (used >= 0.95 && used < 1):
                        return 'picon-pie-95';
                    case (used >= 1):
                        return 'error';
                    default:
                        return 'picon-pie-0';
                }
            },
            storageMessage: () => {
                if (app.$environment.unlimitedStorage) {
                    return `Storage Plan: Unlimited (${app.$utils.formatting.formatBytes(app.$environment.usedStorage)} used)`;
                }
                if (app.$environment.overLimit) {
                    return `Storage Plan: ${app.$utils.formatting.formatBytes(app.$environment.maxStorage)} (${app.$utils.formatting.formatBytes(-app.$environment.availableStorage)} over)`;
                }
                return `Storage Plan: ${app.$utils.formatting.formatBytes(app.$environment.maxStorage)} (${app.$utils.formatting.formatBytes(app.$environment.availableStorage)} available)`;
            },
            storageTextClasses: () => {
                if (app.$environment.overLimit) {
                    return 'text-error';
                }
                if (app.$environment.approachingLimit) {
                    return 'text-warning';
                }
                return '';
            },
        },
        actions: {},
    };
}
