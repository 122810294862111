<template>
    <div
        v-if="!hide"
        v-cypress="testId"
        :class="{'full-height': fullHeight, 'full-width': fullWidth}"
    >
        <div
            v-if="title || settings || headerLeftInlineElements.length !== 0 || headerRightInlineElements.length !== 0"
            class="row items-center"
        >
            <div
                v-if="title || headerLeftInlineElements.length !== 0"
                class="col col-shrink"
            >
                <InlineElement
                    v-for="(element, idx) in headerLeftInlineElements"
                    :key="`inline-${idx}`"
                    :element="element"
                />
                <div
                    v-if="title"
                    class="col text-h6"
                >
                    {{ title }}
                </div>
            </div>
            <q-space/>
            <div
                v-if="settings || headerRightInlineElements.length !== 0"
                class="col col-shrink col-end"
            >
                <Settings
                    v-if="settings"
                    v-model="form"
                    v-bind="settings.props"
                    v-on="settings.events"
                />
                <InlineElement
                    v-for="(element, idx) in headerRightInlineElements"
                    :key="`inline-${idx}`"
                    :element="element"
                />
            </div>
        </div>

        <div
            v-if="visibleInputs"
            :class="{'full-height': fullHeight, 'full-width': fullWidth}"
        >
            <AutoFormInput
                v-for="(input, index) in visibleInputs"
                :key="`${input.key}-${index}`"
                v-bind="input"
                :value="getInputValue(input.key)"
                :dense="dense"
                :class="{'full-height': input.display === 'block', 'full-width': input.display === 'block'}"
                @input="setFormValue($event, input.key)"
                @validate="updateValidationStatus($event, input.key)"
            />
        </div>

        <div
            v-if="schema.sections"
            :class="{'full-height': fullHeight, 'full-width': fullWidth}"
        >
            <AutoFormSection
                v-for="(section, index) in visibleSections"
                :key="index"
                v-bind="section"
                :dense="dense"
                :form="form"
                v-on="section.events"
                @input-changed="setFormValue"
                @validate="updateValidationStatus"
            />
        </div>

        <q-inner-loading :showing="!initialDataLoaded">
            <div class="row">
                <q-spinner
                    size="50px"
                    color="primary"
                />
            </div>
            <div class="row">
                <p>Loading form...</p>
            </div>
        </q-inner-loading>
    </div>
</template>

<script>
import Vue from 'vue';
import dot from 'dot-object';
import AutoFormInput from './AutoFormInput.vue';
import AutoFormSection from './AutoFormSection.vue';
import Settings from '../settings/Settings.vue';
import InlineElement from '../inline-elements/InlineElement.vue';

export default {
    name: 'AutoForm',
    components: {
        Settings,
        AutoFormInput,
        AutoFormSection,
        InlineElement,
    },
    props: {
        title: {
            type: String,
            required: false,
            default: null,
        },
        value: {
            type: Object,
            required: true,
        },
        initialValue: {
            validator(value) {
                return true;
            },
            required: true,
        },
        fullHeight: {
            type: Boolean,
            required: false,
            default: false,
        },
        fullWidth: {
            type: Boolean,
            required: false,
            default: false,
        },
        // TODO: This might be able to be removed.
        checkLoading: {
            type: Boolean,
            required: false,
            default: true,
        },
        headerLeftInlineElements: {
            type: Array,
            required: false,
            default: () => [],
        },
        headerRightInlineElements: {
            type: Array,
            required: false,
            default: () => [],
        },
        schema: {
            type: Object,
            required: true,
        },
        hide: {
            type: Boolean,
            required: false,
            default: false,
        },
        // Options for saving/recalling settings
        settings: {
            type: Object,
            required: false,
            default: null,
        },
        dense: {
            type: Boolean,
            required: false,
            default: true,
        },
        testId: {
            type: String,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            validationStatuses: {},
            formValid: true,
            initialDataLoaded: !this.checkLoading,
        };
    },
    computed: {
        form: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('validate', this.formValid);
                this.$emit('input', value);
            },
        },
        // Create an array of all inputKeys used in the autoform
        inputKeys() {
            const inputKeys = [];
            if (this.schema.inputs) {
                this.schema.inputs.forEach(input => inputKeys.push(input.key));
            }
            if (this.schema.sections) {
                this.schema.sections.forEach(section => {
                    if (section.inputs) {
                        section.inputs.forEach(input => inputKeys.push(input.key));
                    }
                });
            }
            return inputKeys;
        },
        visibleInputs() {
            if (this.schema.inputs) {
                return this.schema.inputs.filter(input => !input.hide);
            }
            return [];
        },
        visibleSections() {
            if (this.schema.sections) {
                return this.schema.sections.filter(section => !section.hide);
            }
            return [];
        },
    },
    watch: {
        // TODO: Remove this watcher? Does it do anything as copyFromValue is run when created.
        // Watch for the initial data to be passed and set the form to it once updated
        initialValue(newValue) {
            if (newValue) {
                this.copyFromValue(newValue);
            }
        },
    },
    created() {
        this.generateValidationStatus();
        this.copyFromValue(this.initialValue);
    },
    methods: {
        copyFromValue(newValue) {
            if (this.initialDataLoaded) return;
            this.inputKeys.forEach(inputKey => {
                // Copy only in the values that the form has input keys for to the form object
                dot.copy(inputKey, inputKey, newValue, this.form);
            });
            this.initialDataLoaded = true;
        },
        getInputValue(inputKey) {
            const inputValue = dot.pick(inputKey, this.form);
            return inputValue;
        },
        setFormValue(newValue, inputKey) {
            const updatedForm = { ...this.form };
            dot.transfer('value', inputKey, { value: newValue }, updatedForm);
            // Emit the individual field change event
            this.$emit(inputKey, { fieldValue: newValue, form: updatedForm });
            this.form = updatedForm;
        },
        generateValidationStatus() {
            const allInputs = [];
            if (this.schema.inputs) {
                this.schema.inputs.forEach(input => allInputs.push(input));
            }
            if (this.schema.sections) {
                this.schema.sections.flatMap(section => section.inputs).forEach(input => allInputs.push(input));
            }

            allInputs.forEach(input => {
                this.validationStatuses[input.key] = {
                    isValid: true,
                    errors: [],
                };
            });
        },
        updateValidationStatus(validationErrors, inputKey) {
            Vue.set(this.validationStatuses, inputKey, {
                isValid: !validationErrors.length,
                errors: validationErrors,
            });

            this.formValid = this.isFormValid();
            this.$emit('validate', this.formValid);
        },
        // Reduce all input validation to a single form is Valid, i.e. if all are valid then form is valid
        isFormValid() {
            const statuses = Object.values(this.validationStatuses);
            return statuses.every(status => status.isValid);
        },
    },
};
</script>

<style scoped>

</style>
