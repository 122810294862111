<template>
    <q-expansion-item
        :label="`${title} (${items.length})`"
        :default-opened="defaultOpened"
        dense
        class="text-bold"
        expand-icon-class="text-secondary"
    >
        <q-separator
            :color="controlColor"
            inset
        />
        <div
            v-for="(item, itemIndex) in items"
            :key="`Item-${itemIndex}`"
            class="q-px-md"
        >
            <div
                class="row items-center expansion-row"
            >
                <div class="col q-pr-sm">
                    <GeneralButton
                        :label="item.title"
                        :to="item.to"
                        :tooltip="item.tooltip"
                        align="left"
                        dense
                        class="ellipsis full-width"
                        @click="$emit('item-clicked', item)"
                    />
                </div>
                <slot
                    name="actionControls"
                    class="col-auto"
                    v-bind="item"
                />
            </div>
            <q-separator color="grey-2"/>
        </div>
    </q-expansion-item>
</template>

<script>
import GeneralButton from '../../shared-components/inline-elements/GeneralButton.vue';

export default {
    name: 'ExpandableActionList',
    components: {
        GeneralButton,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            required: false,
            default: () => [],
        },
        defaultOpened: {
            type: Boolean,
            required: false,
            default: true,
        },
        controlColor: {
            type: String,
            required: false,
            default: 'secondary',
        },
    },
};
</script>

<style scoped>
</style>
