<template>
    <q-card
        v-cypress="'PetronCard_Card_Body'"
    >
        <PRouterLink
            :router-to="petronRoute"
        >
            <q-card-section
                class="row items-center"
                :class="{'padding-dense': dense}"
            >
                <div class="col col-shrink">
                    <LetterAvatar
                        :label="petron.name"
                        :font-size="`${dense ? '3rem' : null}`"
                    />
                </div>
                <div class="col q-pl-md">
                    <div class="row items-center">
                        <div
                            v-cypress="'PetronCard_PetronName_Div'"
                            class="col ellipsis"
                            :class="{ 'text-h6': !dense }"
                        >
                            {{ petron.name }}
                        </div>

                        <div class="col col-shrink">
                            <q-icon
                                v-if="!petron.isPublic"
                                name="fa fa-lock"
                                class="q-pa-md"
                                style="cursor:default"
                            >
                                <q-tooltip :delay="750">
                                    Private
                                </q-tooltip>
                            </q-icon>

                            <GeneralButton
                                v-if="isFavorite"
                                v-cypress="'PetronCard_Unfavorite_Button'"
                                icon="star"
                                tooltip="Remove from favorites"
                                flat
                                dense
                                @click.stop="(event) => actionClicked(event, 'unpin')"
                            />

                            <GeneralButton
                                v-else
                                v-cypress="'PetronCard_Favorite_Button'"
                                icon="star_border"
                                tooltip="Add to favorites"
                                flat
                                dense
                                @click.stop="(event) => actionClicked(event,'pin-to-favorites')"
                            />

                            <GeneralButton
                                v-cypress="'PetronCard_MoreOptions_Button'"
                                icon="more_horiz"
                                tooltip="More options"
                                flat
                                dense
                                @click.stop="toggleMenu"
                            >
                                <q-menu
                                    v-model="showMenu"
                                    auto-close
                                    anchor="bottom right"
                                    self="top right"
                                    no-parent-event
                                >
                                    <q-item
                                        clickable
                                        dense
                                        title="Copy Petron"
                                        @click.stop="(event) => actionClicked(event,'copy')"
                                    >
                                        <q-item-section>Copy Petron</q-item-section>
                                    </q-item>
                                    <q-item
                                        clickable
                                        dense
                                        :disable="!editable"
                                        title="Removes Petron"
                                        @click.stop="(event) => actionClicked(event,'remove')"
                                    >
                                        <q-item-section>
                                            Delete Petron
                                        </q-item-section>
                                    </q-item>
                                </q-menu>
                            </GeneralButton>
                        </div>
                    </div>
                    <UserChip
                        :user-id="petron.ownerId"
                        no-user-message="No owner assigned"
                    />
                </div>
            </q-card-section>
        </PRouterLink>
    </q-card>
</template>

<script>
import LetterAvatar from '../../../plugin-framework/shared-components/LetterAvatar.vue';
import PRouterLink from '@/plugin-framework/services/pai-components/PRouterLink';
import GeneralButton from '../../../plugin-framework/shared-components/inline-elements/GeneralButton.vue';

export default {
    name: 'PetronCard',
    components: {
        PRouterLink,
        LetterAvatar,
        GeneralButton,
    },
    props: {
        petron: {
            type: Object,
            required: true,
        },
        isFavorite: {
            type: Boolean,
            required: false,
            default: false,
        },
        editable: {
            type: Boolean,
            required: false,
            default: false,
        },
        dense: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            showMenu: false,
        };
    },
    computed: {
        petronId() {
            return this.petron.id;
        },
        petronRoute() {
            return { name: 'petron-overview', params: { petronId: this.petronId } };
        },
    },
    methods: {
        actionClicked(event, emitAction) {
            event.preventDefault();
            this.$emit(emitAction);
        },
        toggleMenu(event) {
            event.preventDefault();
            this.showMenu = !this.showMenu;
        },
    },
};
</script>

<style scoped>
.padding-dense {
    padding: 8px;
}
</style>
