<template>
    <q-infinite-scroll
        :offset="250"
        @load="loadMore"
    >
        <div
            v-if="showTitle"
            class="text-h6"
        >
            {{ filterCollection }}
        </div>
        <ForgeFilterWidget
            v-for="(filter, index) in displayFilters.slice(0, renderIndex)"
            :key="`filter-${index}`"
            :workspace-name="filterWorkspace"
            :collection-name="filterCollection"
            :filter-name="filterName"
            :index-data="filter"
            :events="events"
            :dark="darkMode"
            class="col"
        />
    </q-infinite-scroll>
</template>

<script>
import ForgeFilterWidget from './ForgeFilterWidget.vue';

export default {
    name: 'ForgeFilterPanel',
    components: {
        ForgeFilterWidget,
    },
    props: {
        indexData: {
            type: Object,
            required: true,
        },
        includeIndexes: {
            type: Array,
            required: false,
            default: null,
        },
        excludeIndexes: {
            type: Array,
            required: false,
            default: null,
        },
        showTitle: {
            type: Boolean,
            required: false,
            default: true,
        },
        events: {
            type: Object,
            required: false,
            default() {
                return {};
            },
        },
        displayFilter: {
            type: String,
            required: false,
            default: null,
        },
        darkMode: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            renderIndex: 1,
        };
    },
    computed: {
        filterWorkspace() {
            return this.indexData.workspace;
        },
        filterCollection() {
            return this.indexData.collection;
        },
        filterName() {
            return this.indexData.name;
        },
        displayFilters() {
            const allFilters = this.indexData.filters;
            let displayFilters = allFilters;
            if (this.displayFilter && this.displayFilter.length) {
                const filtersFilter = this.displayFilter.trim().toLowerCase();
                displayFilters = allFilters.filter(filter => filter.name.toLowerCase().includes(filtersFilter));
            }
            if (this.includeIndexes) {
                // Filter out everything not in the array.
                displayFilters = allFilters.filter(ft => this.includeIndexes.includes(ft.name));
            }
            if (this.excludeIndexes) {
                // Filter out everything in the array.
                displayFilters = displayFilters.filter(ft => !this.excludeIndexes.includes(ft.name));
            }
            displayFilters.sort((a, b) => a.name.localeCompare(b.name));
            return displayFilters;
        },
    },
    methods: {
        loadMore(index, done) {
            if (this.renderIndex < this.displayFilters.length) {
                this.renderIndex += 1;
                done();
            }
            else {
                done();
            }
        },
    },
};
</script>

<style scoped>

</style>
