export const tableColumnsJobs = function tableColumnsJobs(app) {
    return {
        source: 'self',
        options: {
            default: [
                {
                    name: 'name',
                    label: 'Job Name',
                    required: true,
                    displayedByDefault: true,
                },
                {
                    name: 'taskName',
                    required: true,
                    displayedByDefault: true,
                },
                {
                    name: 'createdAt',
                    required: true,
                    displayedByDefault: true,
                },
                {
                    name: 'createdBy',
                    type: 'user',
                    required: true,
                    displayedByDefault: true,
                },
                {
                    name: 'updatedAt',
                    required: true,
                    displayedByDefault: true,
                },
                {
                    name: 'updatedBy',
                    label: 'Modified By',
                    type: 'user',
                    displayedByDefault: true,
                },
                {
                    name: 'lastExecutionTime',
                    displayedByDefault: true,
                },
                {
                    name: 'jobStatus',
                    align: 'center',
                    label: 'Status',
                    displayedByDefault: true,
                    type: 'badge',
                    typeOptions: {
                        format: (value) => {
                            return {
                                color: value === 'completed' ? 'green' : 'black',
                                label: value,
                                textColor: 'white',
                            };
                        },
                    },
                },
                {
                    name: 'runCount',
                    displayedByDefault: true,
                },
                {
                    name: 'id',
                    label: 'Job ID',
                },
                {
                    name: 'invalidFlag',
                    label: 'Invalid?',
                },
            ],
        },
    };
};
