<template>
    <div class="inline-block">
        <q-btn-dropdown
            v-if="!hide"
            v-cypress="testId"
            v-bind="$props"
            v-on="listenerOverrides"
        >
            <q-list dense>
                <q-item
                    v-for="(item, itemIndex) in dropdown"
                    :key="itemIndex"
                    v-cypress="item.props ? item.props.testId : ''"
                    v-close-popup
                    clickable
                    v-bind="item.props"
                    v-on="item.events"
                >
                    <q-item-section v-if="item.props && item.props.counter">
                        <q-item-label>
                            {{ `${item.title} (${item.props.counter})` }}
                        </q-item-label>
                    </q-item-section>
                    <q-item-section v-else>
                        <q-item-label>
                            {{ item.title }}
                        </q-item-label>
                    </q-item-section>
                </q-item>
            </q-list>
        </q-btn-dropdown>
        <q-tooltip :delay="750">
            {{ tooltip }}
        </q-tooltip>
    </div>
</template>

<script>
import { commonButtonProps } from './common-button-props.js';

export default {
    name: 'DropdownButton',
    props: {
        ...commonButtonProps,
        split: {
            type: Boolean,
            required: false,
            default: false,
        },
        dropdown: {
            type: Array,
            required: false,
            default: () => [],
        },
        dropdownIcon: {
            type: String,
            required: false,
            default: null,
        },
    },
    computed: {
        classes() {
            return {
                // Apply the disabled class when disabled prop is set
                'p-btn-disabled': this.disabled,
            };
        },
        listenerOverrides() {
            const vm = this;
            return {
                // Pass all the default listeners
                ...this.$listeners,
                // Override the click listener to check for disabled state
                click(event) {
                    vm.$telemetry.trackUserEvent({
                        name: vm.$telemetry.eventNames.BUTTON_CLICK,
                        properties: {
                            buttonLabel: vm.label,
                            buttonTooltip: vm.tooltip,
                        },
                    });
                    // Prevent click on disabled button
                    if (!vm.disabled) {
                        vm.$emit('click', event);
                    }
                },
                // Override the click-stop listener to check for disabled state.
                '!click'(event) {
                    // Prevent click on disabled button.
                    if (!vm.disabled) {
                        vm.$emit('!click', event);
                    }
                    else {
                        // Even if it is disabled, stop the propagation.
                        event.stopPropagation();
                    }
                },
            };
        },
    },
};
</script>

<style scoped>
.custom-disabled {
    opacity: 0.6;
    cursor: not-allowed;
}
</style>
