<template>
    <div class="column">
        <div class="col-auto q-px-md q-py-lg text-h6 text-bold text-secondary">
            My Petrons
        </div>
        <div
            class="col-auto q-px-md"
        >
            Petro.ai delivers integrated tools to shareable workspaces, called Petrons. You now have the power to solve problems in new ways.
        </div>
        <q-scroll-area
            class="col q-pt-md"
        >
            <ExpandableActionList
                v-if="forgeResourcesLoaded"
                v-cypress="'AppMenuPetrons_Favorites_ExpandableActionList'"
                title="Favorites"
                :items="mappedPetronFavorites"
                class="text-secondary"
                @item-clicked="$emit('collapse')"
            >
                <template v-slot:actionControls="petronItem">
                    <q-icon
                        v-if="!petronItem.petron.isPublic"
                        name="fa fa-lock"
                        class="q-pa-md"
                        style="cursor:default"
                    >
                        <q-tooltip :delay="750">
                            Private
                        </q-tooltip>
                    </q-icon>
                    <GeneralButton
                        v-cypress="'AppMenuPetrons_Unfavorite_Button'"
                        icon="star"
                        tooltip="Remove from favorites"
                        flat
                        dense
                        @click.stop="removeFromFavorites(petronItem.petron.id)"
                    />
                    <GeneralButton
                        v-cypress="'AppMenuPetrons_MoreOptions_Button'"
                        icon="more_horiz"
                        tooltip="More options"
                        flat
                        dense
                        class="q-pr-lg"
                        @click.stop="togglePetronMenu(petronItem.petron)"
                    >
                        <q-menu
                            :value="petronForMenu === petronItem.petron"
                            auto-close
                            anchor="bottom right"
                            self="top right"
                            no-parent-event
                            @input="petronForMenu = null"
                        >
                            <q-list>
                                <q-item
                                    clickable
                                    dense
                                    :disable="!isOwner(petronItem.petron)"
                                    @click.stop="removePetron(petronItem.petron)"
                                >
                                    <q-item-section>
                                        Delete Petron
                                    </q-item-section>
                                </q-item>
                            </q-list>
                        </q-menu>
                    </GeneralButton>
                </template>
            </ExpandableActionList>
            <q-spinner
                v-else
                size="30px"
                color="secondary"
                class="full-width"
            />
        </q-scroll-area>
        <div class="col-auto q-pa-lg">
            <div class="q-py-md">
                <GeneralButton
                    v-cypress="'AppMenuPetrons_Library_Button'"
                    :flat="false"
                    :outline="false"
                    class="full-width text-bold purple-box"
                    label="Petron Library"
                    color="grey-2"
                    text-color="secondary"
                    tooltip="Go to the Petron library"
                    :to="{ name: 'petron-library' }"
                    @click="$emit('collapse')"
                />
            </div>
            <GeneralButton
                v-cypress="'AppMenuPetrons_CreatePetron_Button'"
                :flat="false"
                :outline="false"
                class="full-width text-bold purple-box"
                label="Create New Petron"
                color="accent"
                text-color="secondary"
                tooltip="Add a new Petron"
                @click="launchPetronCreateDialog"
            />
        </div>
        <NewPetronDialog
            v-model="showCreatePetronDialog"
            @createPetron="createPetron"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import mixins from '@/mixins';
import NewPetronDialog from '../plugins/library/components/NewPetronDialog';
import ExpandableActionList from '../plugin-framework/services/pai-components/ExpandableActionList';
import GeneralButton from '../plugin-framework/shared-components/inline-elements/GeneralButton.vue';

export default {
    name: 'AppMenuPetrons',
    components: {
        ExpandableActionList,
        NewPetronDialog,
        GeneralButton,
    },
    mixins: [mixins.compute],
    data() {
        return {
            showCreatePetronDialog: false,
            petronForMenu: null,
        };
    },
    computed: {
        ...mapGetters('Library', {
            favoritePetrons: 'favoritePetrons',
            favoritePetronsResource: 'favoritePetronsResource',
        }),
        forgeResources() {
            return [this.favoritePetronsResource];
        },
        mappedPetronFavorites() {
            const mappedPetrons = this.favoritePetrons.map(petron => {
                return {
                    title: petron.name,
                    tooltip: `Visit petron '${petron.name}`,
                    to: { name: 'petron-overview', params: { petronId: petron.id } },
                    petron,
                };
            });
            mappedPetrons.sort((a, b) => a.title.localeCompare(b.title));
            return mappedPetrons;
        },
    },
    methods: {
        ...mapActions('Library', {
            removeFromFavorites: 'removeFromFavorites',
            removePetron: 'removePetron',
        }),
        launchPetronCreateDialog() {
            this.$emit('collapse');
            this.showCreatePetronDialog = true;
        },
        createPetron({ name }) {
            this.$api.tasks.runTask({
                runInBackground: false,
                pid: 'null',
                taskType: 'CreatePetron',
                options: {
                    name,
                },
            }).then(response => {
                if (response.insertedIds.length > 0) {
                    const petronId = response.insertedIds[0];
                    this.$router.push({ name: 'petron-overview', params: { petronId } });
                }
            });
        },
        isOwner(petron) {
            return petron.ownerId === this.$auth.user.userId;
        },
        togglePetronMenu(petron) {
            if (this.petronForMenu) {
                this.petronForMenu = null;
                return;
            }
            this.petronForMenu = petron;
        },
    },
};
</script>

<style scoped lang="stylus">
@import '../css/quasar.variables.styl';
.purple-box {
    border: solid 2px $secondary;
    border-radius: 2px;
}
</style>
