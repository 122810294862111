<template>
    <q-inner-loading :showing="true">
        <q-list dense>
            <div class="text-h6 text-center">
                Loading Resources:
            </div>
            <q-item
                v-for="resource in resources"
                :key="resource.uri"
                clickable
            >
                <q-item-section avatar>
                    <q-icon
                        :class="getStatusIcon(resource).class"
                        :name="getStatusIcon(resource).name"
                        size="sm"
                    />
                </q-item-section>
                <q-item-section>
                    {{ getResourceName(resource) | spaces | capitalize }}
                    <q-tooltip :delay="750">
                        {{ resource.uri }}
                    </q-tooltip>
                </q-item-section>
            </q-item>
        </q-list>
    </q-inner-loading>
</template>

<script>
export default {
    name: 'ForgeLoadState',
    props: {
        resources: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    methods: {
        getStatusIcon(resource) {
            switch (resource.state) {
                case 'initial':
                    return {
                        name: 'eva-sync-outline',
                        class: 'text-info fa-spin',
                    };
                case 'ready':
                    return {
                        name: 'done',
                        class: 'text-success',
                    };
                case 'stale':
                    return {
                        name: 'priority_high',
                        class: 'text-warning',
                    };
                case 'error':
                    return {
                        name: 'clear',
                        class: 'text-error',
                    };
                default:
                    return {
                        name: 'clear',
                        class: 'text-error',
                    };
            }
        },
        getResourceName(resource) {
            // Grab the name of the resource for display in UI.
            // Look for everything afer the #.
            // Also strip out leading/trailing double underscores, for system variables.
            const displayName = resource.uri.match(/#__(.*)__/) || resource.uri.match(/#(.*)/);
            // Protect against resource name not matching.
            if (!displayName || !displayName.length) {
                return resource.uri;
            }
            return displayName[displayName.length - 1];
        },
    },
};
</script>

<style scoped>

</style>
