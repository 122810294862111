import { UnitsModel } from './model/units-model.js';
import { UnitsRoute } from './fragments/units-route';
import { UnitDefinitionEditDialog } from './fragments/unit-definition-edit-dialog';

export const UnitDefinitionsPlugin = {
    name: 'UnitDefinitions',
    views: {
    },
    definition({ app, plugin }) {
        return {
            modules: [
                UnitsRoute({ app, plugin }),
                UnitDefinitionEditDialog({ app, plugin }),
            ],
            model: UnitsModel({ app, plugin }),
        };
    },
};
