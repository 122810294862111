<template>
    <div>
        <DataChart
            v-if="visualizationDefinition.type === 'data-chart'"
            v-bind="visualizationDefinition.props"
            class="full-width full-height"
            v-on="visualizationDefinition.events"
        />
        <BokehChart
            v-else-if="visualizationDefinition.type === 'bokeh'"
            v-bind="visualizationDefinition.props"
            class="full-width full-height"
            v-on="visualizationDefinition.events"
        />
        <BokehAppliance
            v-else-if="visualizationDefinition.type === 'bokeh-appliance'"
            v-bind="visualizationDefinition.props"
            class="full-width full-height overflow-auto"
            v-on="visualizationDefinition.events"
        />
        <SpotfireWebPlayer
            v-else-if="visualizationDefinition.type === 'spotfire-web-player'"
            v-bind="visualizationDefinition.props"
            class="full-width full-height overflow-auto"
            v-on="visualizationDefinition.events"
        />
        <StaticTableVisualization
            v-else-if="visualizationDefinition.type === 'static-table'"
            v-bind="visualizationDefinition.props"
            class="full-height full-width"
            v-on="visualizationDefinition.events"
        />
        <SmartTable
            v-else-if="visualizationDefinition.type === 'smart-table'"
            v-bind="visualizationDefinition.props"
            class="full-height full-width"
            v-on="visualizationDefinition.events"
        />
        <Section
            v-else-if="visualizationDefinition.type === 'section'"
            :section="visualizationDefinition.props"
            class="full-height full-width"
        />
        <Markdown
            v-else-if="visualizationDefinition.type === 'markdown'"
            v-bind="visualizationDefinition.props"
            class="full-height full-width"
            v-on="visualizationDefinition.events"
        />
        <component
            :is="visualizationDefinition.componentType"
            v-else-if="visualizationDefinition.type === 'custom' || visualizationDefinition.componentType"
            v-bind="visualizationDefinition.props"
            class="full-height full-width"
            v-on="visualizationDefinition.events"
        />
    </div>
</template>

<script>
import SpotfireWebPlayer from './SpotfireWebPlayer.vue';
import DataChart from './DataChart.vue';
import StaticTableVisualization from './StaticTableVisualization.vue';
import SmartTable from './SmartTable.vue';
import BokehChart from './BokehChart.vue';
import BokehAppliance from './BokehAppliance.vue';
import Section from '../sections/Section.vue';
import Markdown from './Markdown.vue';

export default {
    name: 'Visualization',
    components: {
        SpotfireWebPlayer,
        BokehAppliance,
        BokehChart,
        SmartTable,
        DataChart,
        StaticTableVisualization,
        Section,
        Markdown,
    },
    props: {
        visualizationDefinition: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style scoped>

</style>
