import { PetronModel } from './model.js';

export const PetronPlugin = {
    name: 'Petron',
    views: {},
    definition({ app, plugin }) {
        return {
            modules: [],
            model: PetronModel(app),
        };
    },
};
