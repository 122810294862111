const UnitsModule = {
    namespaced: true,
    state: {
        unitOptions: {},
        reportingUnitsDefinition: {},
    },
    mutations: {
        setUnitOptions(state, options) {
            state.unitOptions = options;
        },
        setReportingUnitsDefinition(state, unitsDefinition) {
            state.reportingUnitsDefinition = unitsDefinition;
        },
    },
};

export default UnitsModule;
