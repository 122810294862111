<template>
    <q-page
        class="row search-page__row justify-around"
    >
        <q-scroll-area
            ref="searchResultsScrollArea"
            class="col-grow full-height"
        >
            <div class="row justify-around">
                <div class="col-sm-12 col-md-6">
                    <div class="text-h4 q-py-lg">
                        Search for "{{ $route.query.text }}" <span
                            v-if="searchResults"
                            v-cypress="'SearchResultsPage_TotalResults_Span'"
                            class="text-info"
                        >{{ searchResultsTotal }} Results</span>
                    </div>

                    <SearchResults
                        v-if="searchResults && searchResults.length"
                        :search-results="searchResults"
                        :total-search-results="searchResultsTotal"
                        @load-more="loadMoreSearchResults"
                    />
                    <div
                        v-else-if="searchResults && searchResults.length === 0"
                        class="text-center"
                    >
                        <p>No results</p>
                    </div>
                    <div
                        v-else
                        class="text-center"
                    >
                        <q-spinner
                            size="30px"
                            color="primary"
                        />
                        <p>Loading search results...</p>
                    </div>
                </div>
            </div>
        </q-scroll-area>
        <q-page-sticky
            position="bottom-right"
            :offset="[40,18]"
        >
            <GeneralButton
                fab
                :flat="false"
                :outline="false"
                icon="fa fa-chevron-up"
                color="primary"
                tooltip="Go to top"
                @click="$refs.searchResultsScrollArea.setScrollPosition(0)"
            />
        </q-page-sticky>
    </q-page>
</template>

<script>
import SearchResults from '../../plugin-framework/shared-components/search/SearchResults.vue';
import GeneralButton from '../../plugin-framework/shared-components/inline-elements/GeneralButton.vue';

export default {
    name: 'TheSearchResults',
    components: {
        SearchResults,
        GeneralButton,
    },
    data() {
        return {
            searchResults: null,
            searchResultsTotal: 0,
            searchFromDate: new Date(),
            searchResultsSkip: 0,
            searchResultsLimit: 15,
        };
    },
    computed: {

    },
    watch: {
        // Watch for changes to search and update results when changes are made
        '$route.query.text': {
            immediate: true,
            handler(newSearchText) {
                this.loadSearchResults();
            },
        },
    },
    methods: {
        getSearchResults({
            searchText = this.$route.query.text,
            skip = this.searchResultsSkip,
            limit = this.searchResultsLimit,
            endDateTime = this.searchFromDate,
        } = { }) {
            return this.$api.tasks.runTask({
                runInBackground: false,
                taskType: 'GlobalTextSearch',
                options: {
                    searchText,
                    endDateTime,
                    skip,
                    limit,
                },
            })
            .then(response => {
                if (response.data) {
                    return response;
                }

                throw new Error('response contained no data');
            })
            .catch(error => {
                this.$notify.error(`Unable to search results: ${error.message}`);
            });
        },
        loadSearchResults() {
            // Reset to initial state
            this.searchResults = null;
            this.searchFromDate = new Date();
            this.searchResultsSkip = 0;

            this.getSearchResults()
            .then(response => {
                this.searchResults = response.data;
                this.searchResultsTotal = response.total;
            });
        },
        // quasar infinite scroll load method signature, done is a callback to end infinite loading spinner
        loadMoreSearchResults(index, done) {
            if (this.searchResults.length < this.searchResultsTotal) {
                this.searchResultsSkip += this.searchResultsLimit;
                this.getSearchResults()
                .then(response => {
                    this.searchResults.push(...response.data);
                    this.totalFeedItems = response.total;
                    done();
                });
            }
            else {
                done();
            }
        },
    },
};
</script>

<style scoped lang="stylus">
@import '../../css/petro.styl';
.search-page__row {
    /* full height minus the application bar */
    height: "calc(100vh - %s)" % $app-header-height;
}
</style>
