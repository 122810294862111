<template>
    <router-link
        v-if="!hideLink"
        class="user-full-name__link"
        :to="{ name: 'user', params: { userId: resolvedUserId } }"
        :style="{ fontSize: fontSize }"
    >
        {{ fullName }}<q-tooltip :delay="750">
            {{ fullName }}
        </q-tooltip>
    </router-link>
    <span
        v-else
        :style="{ fontSize: fontSize }"
    >
        {{ fullName }}
        <q-tooltip :delay="750">
            {{ fullName }}
        </q-tooltip>
    </span>
</template>

<script>
export default {
    name: 'UserFullName',
    props: {
        user: {
            type: Object,
            required: false,
            default: null,
        },
        userId: {
            type: String,
            required: false,
            default: '',
        },
        hideLink: {
            type: Boolean,
            required: false,
            default: false,
        },
        fontSize: {
            type: String,
            required: false,
            default: null,
        },
    },
    computed: {
        resolvedUserId() {
            return this.userId || this.user.userId;
        },
        fullName() {
            if (this.userId) {
                const user = this.$users.getUser(this.userId);
                if (user && user.firstName && user.lastName) {
                    return `${user.firstName} ${user.lastName}`;
                }
            }
            if (this.user) {
                return `${this.user.firstName} ${this.user.lastName}`;
            }
            return null;
        },
    },
};
</script>

<style scoped>
.user-full-name__link {
    text-decoration: none;
}
</style>
