<template>
    <span v-if="errors.length">{{ errors.join(separator) }}</span>
</template>

<script>
export default {
    name: 'AutoFormValidationErrors',
    props: {
        errors: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },
        separator: {
            type: String,
            required: false,
            default: ', ',
        },
    },
};
</script>

<style scoped>

</style>
