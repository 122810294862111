import EnvironmentService from './environment-service';

export const EnvironmentPlugin = {
    install(Vue, {
        store = null,
        router = null,
    } = {}) {
        const service = new EnvironmentService({ store, router });

        // Register the env service globally
        Vue.$environment = service;

        // register the env service on the vue instance
        Vue.prototype.$environment = service;
    },
};
