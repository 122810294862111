<template>
    <router-link
        :to="routerTo"
        class="router-link-transparent"
        @click.native="$emit('navigate')"
    >
        <slot/>
    </router-link>
</template>

<script>

export default {
    name: 'PRouterLink',
    props: {
        routerTo: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style scoped>
    .router-link-transparent {
        color: inherit;
        text-decoration: inherit;
    }
</style>
