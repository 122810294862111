<template>
    <q-page class="q-py-md q-px-md column">
        <div
            v-if="tableIsReady && hasHeader"
            class="col-auto row"
            style="padding-bottom: 15px"
        >
            <div
                v-if="headerLeftInlineElements"
                class="row items-center"
            >
                <InlineElement
                    v-for="(element, idx) in headerLeftInlineElements"
                    :key="`topLeft-${idx}`"
                    :element="element"
                    class="q-mr-sm"
                />
            </div>
            <q-space/>
            <div
                v-if="headerRightInlineElements"
                class="row"
            >
                <InlineElement
                    v-for="(element, idx) in headerRightInlineElements"
                    :key="`topRight-${idx}`"
                    :element="element"
                    class="q-ml-sm"
                />
            </div>
        </div>

        <q-card
            v-if="tableIsReady"
            class="col full-width q-pa-md"
            style="height: 400px"
        >
            <ForgeDataTable
                class="full-height"
                v-bind="props"
                v-on="events"
            />
        </q-card>

        <div
            v-if="tableIsReady && hasFooter"
            class="col-auto row"
            style="padding-top: 15px"
        >
            <div
                v-if="footerLeftInlineElements"
                class="row"
            >
                <InlineElement
                    v-for="(element, idx) in footerLeftInlineElements"
                    :key="`bottomLeft-${idx}`"
                    :element="element"
                    class="q-pr-sm"
                />
            </div>
            <q-space/>
            <div
                v-if="footerRightInlineElements"
                class="row"
            >
                <InlineElement
                    v-for="(element, idx) in footerRightInlineElements"
                    :key="`bottomRight-${idx}`"
                    :element="element"
                    class="q-pl-sm"
                />
            </div>
        </div>

        <ForgeLoadState
            v-if="!tableIsReady"
            :resources="forgeResourceElements"
        />
    </q-page>
</template>

<script>
import mixins from '../../mixins/index.js';
import ForgeLoadState from '../shared-components/ForgeLoadState.vue';
import ForgeDataTable from '../shared-components/tables/forge-table/ForgeDataTable.vue';
import InlineElement from '../shared-components/inline-elements/InlineElement.vue';

export default {
    name: 'ForgeTableTemplate',
    components: {
        InlineElement,
        ForgeLoadState,
        ForgeDataTable,
    },
    mixins: [mixins.compute, mixins.lifecycleHooks({ addProps: false })],
    props: {
        props: {
            type: Object,
            required: true,
        },
        events: {
            type: Object,
            required: true,
        },
    },
    computed: {
        forgeResources() {
            return this.props.forgeResources;
        },
        hasFooter() {
            return (!!this.footerLeftInlineElements && this.footerLeftInlineElements.length) || (!!this.footerRightInlineElements && this.footerRightInlineElements.length);
        },
        hasHeader() {
            return (!!this.headerLeftInlineElements && this.headerLeftInlineElements.length) || (!!this.headerRightInlineElements && this.headerRightInlineElements.length);
        },
        headerLeftInlineElements() {
            if (this.props.headerLeftInlineElements) {
                return this.props.headerLeftInlineElements;
            }
            if (this.props.headerLeftButtons) {
                // TODO: Support temporarily for backwards compatibility.
                return this.props.headerLeftButtons.map(button => {
                    return {
                        type: 'button',
                        props: button.props,
                        events: button.events,
                    };
                });
            }
            return null;
        },
        headerRightInlineElements() {
            if (this.props.headerRightInlineElements) {
                return this.props.headerRightInlineElements;
            }
            if (this.props.headerRightButtons) {
                // TODO: Support temporarily for backwards compatibility.
                return this.props.headerRightButtons.map(button => {
                    return {
                        type: 'button',
                        props: button.props,
                        events: button.events,
                    };
                });
            }
            return null;
        },
        footerLeftInlineElements() {
            if (this.props.footerLeftInlineElements) {
                return this.props.footerLeftInlineElements;
            }
            if (this.props.footerLeftButtons) {
                // TODO: Support temporarily for backwards compatibility.
                return this.props.footerLeftButtons.map(button => {
                    return {
                        type: 'button',
                        props: button.props,
                        events: button.events,
                    };
                });
            }
            return null;
        },
        footerRightInlineElements() {
            if (this.props.footerRightInlineElements) {
                return this.props.footerRightInlineElements;
            }
            if (this.props.footerRightButtons) {
                // TODO: Support temporarily for backwards compatibility.
                return this.props.footerRightButtons.map(button => {
                    return {
                        type: 'button',
                        props: button.props,
                        events: button.events,
                    };
                });
            }
            return null;
        },
        headerFooterHeight() {
            let height = 24;
            if (this.hasHeader) {
                height += 50;
            }
            if (this.hasFooter) {
                height += 52;
            }
            return `${height}px`;
        },
        tableIsReady() {
            return this.forgeResourcesLoaded && !!this.props.tableView && !!this.props.tableOptions;
        },
    },
};
</script>

<style scoped lang="stylus">

</style>
