import Vue from 'vue';

const EnvironmentModule = {
    namespaced: true,
    state: {
        info: null,
    },
    getters: {
        maxStorage: (state) => state?.info?.databaseInfo?.databaseSizeLimit,
        usedStorage: (state) => state?.info?.databaseInfo?.storageSize,
        unlimitedStorage: (state, getters) => getters.maxStorage < 0,
        availableStorage: (state, getters) => {
            if (getters.unlimitedStorage) {
                return null;
            }
            return getters.maxStorage - getters.usedStorage;
        },
        fractionUsed: (state, getters) => {
            if (getters.unlimitedStorage) {
                return 0;
            }
            return getters.usedStorage / getters.maxStorage;
        },
        overLimit: (state, getters) => {
            if (getters.unlimitedStorage) {
                return false;
            }
            return getters.availableStorage <= 0;
        },
        approachingLimit: (state, getters) => getters.fractionUsed >= 0.9,
    },
    mutations: {
        setInfo(state, info) {
            state.info = info;
        },
    },
    actions: {
        getEnvironmentInfo({ commit }) {
            return Vue.$api.info.getWebServerInfo()
            .then(response => {
                if (!response) {
                    Vue.$environment.log.error('Could not load environment info. API response was empty.');
                }
                const info = response;
                Object.freeze(info);
                commit('setInfo', info);
            })
            .catch(error => {
                Vue.$environment.log.error('Could not load environment info. API response was empty.');
            });
        },
    },
};

export default EnvironmentModule;
