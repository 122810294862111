<template>
    <div class="q-pb-sm">
        <q-input
            v-if="inputType === 'text' || inputType === 'textarea'"
            v-model="inputValue"
            v-cypress="testId"
            filled
            :clearable="clearable"
            :dense="dense"
            :debounce="debounce"
            :label="label"
            :hint="hint"
            :error="!!validationErrors.length"
            :disable="disabled"
            :type="inputType"
            :dark="dark"
            :readonly="readonly"
        >
            <template v-slot:error>
                <AutoFormValidationErrors :errors="validationErrors"/>
            </template>
        </q-input>

        <q-field
            v-if="inputType === 'boolean'"
            v-cypress="testId"
            :error="!!validationErrors.length"
            :hint="hint"
            :dense="dense"
            borderless
            bottom-slots
            :dark="dark"
            :readonly="readonly"
        >
            <q-checkbox
                v-model="inputValue"
                :dense="dense"
                :label="label"
                :disable="disabled || readonly"
                :dark="dark"
            />
            <template v-slot:error>
                <AutoFormValidationErrors
                    :errors="validationErrors"
                    class="q-pl-md"
                />
            </template>
        </q-field>

        <q-input
            v-if="inputType === 'number'"
            v-model.number="inputValue"
            v-cypress="testId"
            filled
            type="number"
            :clearable="clearable"
            :dense="dense"
            :debounce="debounce"
            :label="label"
            :disable="disabled"
            :error="!!validationErrors.length"
            :dark="dark"
            :step="step"
            :readonly="readonly"
        >
            <template v-slot:error>
                <AutoFormValidationErrors :errors="validationErrors"/>
            </template>
            <template v-slot:append>
                {{ append }}
            </template>
            <template v-slot:hint>
                <div class="ellipsis">
                    {{ hint }}
                    <q-tooltip :delay="750">
                        {{ hint }}
                    </q-tooltip>
                </div>
            </template>
        </q-input>

        <AutoFormSelect
            v-if="inputType === 'select'"
            v-model="inputValue"
            :test-id="testId"
            :dense="dense"
            :options="options"
            :option-label="optionLabel"
            :option-value="optionValue"
            :option-disable="optionDisable"
            :debounce="debounce"
            :clearable="clearable"
            filled
            :label="label"
            :hint="hint"
            :disable="disabled"
            :multiselect="multiple"
            :use-input="useInput"
            :new-value="newValue"
            emit-value
            map-options
            :errors.sync="validationErrors"
            :option-categories="optionCategories"
            :dark="dark"
            :readonly="readonly"
        />

        <AutoFormRadioSelect
            v-if="inputType === 'radio-select'"
            v-model="inputValue"
            :test-id="testId"
            :dense="dense"
            :options="options"
            :label="label"
            :hint="hint"
            :disable="disabled"
            :errors.sync="validationErrors"
            :dark="dark"
            :readonly="readonly"
            :has-validation-rules="hasValidationRules"
        />

        <PDatePicker
            v-if="inputType === 'date'"
            v-model="inputValue"
            v-cypress="testId"
            :label="label"
            :clearable="clearable"
            :disable="disabled"
            :hint="hint"
            :display-time-zone="displayTimeZone"
            :readonly="readonly"
        />

        <AutoFormDateTimeInput
            v-if="inputType === 'date-time'"
            v-model="inputValue"
            :test-id="testId"
            :label="label"
            :display-time-zone="displayTimeZone"
            :hint="hint"
            :debounce="debounce"
            :clearable="clearable"
            :disable="disabled"
            :dark="dark"
            :readonly="readonly"
        />

        <div
            v-if="inputType === 'date-time-range'"
            v-cypress="testId"
        >
            <div>
                <AutoFormDateTimeInput
                    v-model="compDateRange0"
                    v-cypress="'AutoFormInput_From_AutoFormDateTimeInput'"
                    :label="`${label} (From)`"
                    :display-time-zone="displayTimeZone"
                    :debounce="debounce"
                    :clearable="clearable"
                    :disable="disabled"
                    hint=""
                    :dark="dark"
                    class="full-width"
                    :readonly="readonly"
                />
            </div>
            <div>
                <AutoFormDateTimeInput
                    v-model="compDateRange1"
                    v-cypress="'AutoFormInput_To_AutoFormDateTimeInput'"
                    :label="`${label} (To)`"
                    :display-time-zone="displayTimeZone"
                    :debounce="debounce"
                    :clearable="clearable"
                    :disable="disabled"
                    hint=""
                    :dark="dark"
                    class="full-width"
                    :readonly="readonly"
                />
            </div>
            <div class="row q-px-md hint-error-row">
                <div
                    v-if="!validationErrors.length"
                    class="hint-text"
                >
                    {{ hint }}
                </div>
                <AutoFormValidationErrors
                    v-else
                    :errors="validationErrors"
                />
            </div>
        </div>

        <AutoFormJSONInput
            v-if="inputType === 'json'"
            v-model="inputValue"
            :test-id="testId"
            :label="label"
            :hint="hint"
            :min-lines="minLines"
            :max-lines="maxLines"
            :input-errors.sync="inputErrors"
            :errors="validationErrors"
            :can-maximize="canMaximize"
            :allow-variables-insert="allowVariablesInsert"
            :disabled="disabled"
            :dark="dark"
            :readonly="readonly"
        />

        <AutoFormCodeInput
            v-if="inputType === 'code'"
            v-model="inputValue"
            :disabled="disabled"
            :test-id="testId"
            :label="label"
            :hint="hint"
            :min-lines="minLines"
            :max-lines="maxLines"
            :lang="lang"
            :errors.sync="inputErrors"
            :dark="dark"
            :readonly="readonly"
        />

        <AutoFormObjectPicker
            v-if="inputType === 'picker'"
            v-model="inputValue"
            :test-id="testId"
            :disable="disabled"
            :label="label"
            :hint="hint"
            :target="target"
            :errors="validationErrors"
        />

        <AutoFormNumberRange
            v-if="inputType === 'range'"
            v-model="inputValue"
            :test-id="testId"
            :append="append"
            :debounce="debounce"
            :disable="disabled"
            :errors="validationErrors"
            :hint="hint"
            :is-integer="isInteger"
            :label="label"
            :range-limits="rangeLimits"
            :dark="dark"
            :readonly="readonly"
            :hide-slider="hideSlider"
            :low-input-tooltip="lowInputTooltip"
            :high-input-tooltip="highInputTooltip"
            :step="step"
        />

        <AutoFormTable
            v-if="inputType === 'table'"
            v-model="inputValue"
            :test-id="testId"
            :label="label"
            :hint="hint"
            :disable="disabled"
            :display="display"
            :column-options="columnOptions"
            :sortable="sortable"
            :save-btn-text="saveBtnText"
            :save-btn-tooltip="saveBtnTooltip"
            :dark="dark"
            @validated="inputErrors = $event"
        />

        <AutoFormColorPicker
            v-if="inputType === 'color-picker'"
            v-model="inputValue"
            :test-id="testId"
            :dense="dense"
            :debounce="debounce"
            :clearable="clearable"
            filled
            :label="label"
            :hint="hint"
            :disable="disabled"
            :errors.sync="validationErrors"
            :dark="dark"
            :palette="palette"
            :readonly="readonly"
        />
    </div>
</template>

<script>
import PDatePicker from '../../services/pai-components/PDatePicker.vue';
import AutoFormValidationErrors from './AutoFormValidationErrors.vue';
import AutoFormJSONInput from './AutoFormJSONInput.vue';
import AutoFormObjectPicker from './AutoFormObjectPicker.vue';
import AutoFormRadioSelect from './AutoFormRadioSelect.vue';
import AutoFormDateTimeInput from './AutoFormDateTimeInput.vue';
import AutoFormSelect from './AutoFormSelect.vue';
import AutoFormCodeInput, { supportedLanguages } from './AutoFormCodeInput.vue';
import AutoFormNumberRange from './AutoFormNumberRange.vue';
import AutoFormTable from './AutoFormTable.vue';
import AutoFormColorPicker from './AutoColorPicker.vue';

const TRANS = {
    percent(value, back) {
        const scalar = 100;
        const result = back ? Number(value) * scalar : Number(value) / scalar;
        // TODO: figure out floating point errors with ability to return number not string
        return result;
    },
    percentDecimal2(value, back) {
        const scalar = 100;
        const result = back ? Number(value) * scalar : Number(value) / scalar;
        return result.toFixed(back ? 2 : 4);
    },
};

const applyTrans = function applyFunc(transform, value, out) {
    // If transform is a function then run it
    if (typeof transform === 'function') {
        return transform(value, out);
    }

    // If transform is a name then run the associated pre-built transform
    if (TRANS[transform] !== undefined) {
        return TRANS[transform](value, out);
    }

    // No or unknown transform
    return value;
};

export default {
    name: 'AutoFormInput',
    components: {
        AutoFormColorPicker,
        AutoFormSelect,
        AutoFormDateTimeInput,
        AutoFormRadioSelect,
        AutoFormJSONInput,
        AutoFormObjectPicker,
        AutoFormValidationErrors,
        AutoFormCodeInput,
        AutoFormNumberRange,
        AutoFormTable,
        PDatePicker,
    },
    props: {
        testId: {
            type: String,
            required: false,
            default: null,
        },
        value: {
            validator(value) {
                return true;
            },
            required: false,
            default() {
                return null;
            },
        },
        inputType: {
            type: String,
            required: false,
            default: 'text',
        },
        displayTimeZone: {
            type: String,
            required: false,
            default: 'UTC',
        },
        validationRules: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },
        validationTrigger: {
            // Allow any type of variable to be a validation trigger
            validator(value) {
                return true;
            },
            required: false,
            default: false,
        },
        default: {
            validator(value) {
                return true;
            },
            required: false,
            default: null,
        },
        clearable: {
            type: Boolean,
            required: false,
            default: true,
        },
        debounce: {
            type: Number,
            required: false,
            default: 500,
        },
        transform: {
            validator(value) {
                return typeof value === 'function' || typeof value === 'string';
            },
            required: false,
            default: 'none',
        },
        label: {
            type: String,
            required: false,
            default: null,
        },
        hint: {
            type: String,
            required: false,
            default: null,
        },
        rangeLimits: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },
        isInteger: {
            type: Boolean,
            required: false,
            default: false,
        },
        dense: {
            type: Boolean,
            required: false,
            default: true,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false,
        },
        minLines: {
            type: Number,
            required: false,
            default: 5,
        },
        maxLines: {
            type: Number,
            required: false,
            default: 10000,
        },
        lang: {
            validator: (value) => supportedLanguages.includes(value),
            required: false,
            default: 'text',
        },
        options: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },
        optionLabel: {
            required: false,
            default: 'label',
            validator(value) {
                return typeof value === 'string' || typeof value === 'function';
            },
        },
        optionValue: {
            required: false,
            default: 'value',
            validator(value) {
                return typeof value === 'string' || typeof value === 'function';
            },
        },
        optionCategories: {
            type: Boolean,
            required: false,
            default: false,
        },
        optionDisable: {
            required: false,
            default: 'disable',
            validator(value) {
                return typeof value === 'string' || typeof value === 'function';
            },
        },
        target: {
            type: Object,
            required: false,
            default() {
                return {};
            },
        },
        multiple: {
            type: Boolean,
            required: false,
            default: false,
        },
        useInput: {
            type: Boolean,
            required: false,
            default: true,
        },
        newValue: {
            type: Function,
            required: false,
            default: () => null,
        },
        dateOptions: {
            type: Function,
            required: false,
            default: () => null,
        },
        canMaximize: {
            type: Boolean,
            required: false,
            default: false,
        },
        allowVariablesInsert: {
            type: Boolean,
            required: false,
            default: false,
        },
        display: {
            required: false,
            validator: value => typeof value === 'string' && ['modal', 'block'].includes(value),
            default: 'modal',
        },
        columnOptions: {
            type: Array,
            required: false,
            default: () => [],
        },
        sortable: {
            type: Boolean,
            required: false,
            default: true,
        },
        saveBtnText: {
            type: String,
            required: false,
            default: 'Save & Close',
        },
        saveBtnTooltip: {
            type: String,
            required: false,
            default: 'Save table',
        },
        dark: {
            type: Boolean,
            required: false,
            default: false,
        },
        palette: {
            type: Array,
            required: false,
            default() {
                return [];
            },
            validator: v => (Array.isArray(v) && v.every(e => typeof e === 'string')) || v === null,
        },
        hideSlider: {
            type: Boolean,
            required: false,
            default: false,
        },
        lowInputTooltip: {
            type: String,
            required: false,
            default: null,
        },
        highInputTooltip: {
            type: String,
            required: false,
            default: null,
        },
        step: {
            type: Number,
            required: false,
            default: undefined,
        },
    },
    data() {
        return {
            inputErrors: [],
            validationErrors: [],
        };
    },
    computed: {
        inputValue: {
            get() {
                const v = (this.value === null) ? this.default : this.value;
                return applyTrans(this.transform, v, true);
            },
            set(value) {
                this.validateInput(value);
                this.setValue(value);
            },
        },
        append() {
            if (this.transform === 'percent' || this.transform === 'percentDecimal2') {
                return '%';
            }
            return null;
        },
        compDateRange0: {
            set(value) {
                const v0 = applyTrans(this.transform, value, false);
                const v1 = (this.value === null || this.value === undefined) ? this.default[1] : this.value[1];
                this.$emit('input', [v0 || null, v1 || null]);
            },
            get() {
                const v = (this.value === null) ? this.default[0] : this.value[0];
                return applyTrans(this.transform, v, true);
            },
        },
        compDateRange1: {
            set(value) {
                const v0 = (this.value === null) ? this.default[0] : this.value[0];
                const v1 = applyTrans(this.transform, value, false);
                this.$emit('input', [v0 || null, v1 || null]);
            },
            get() {
                const v = (this.value === null) ? this.default[1] : this.value[1];
                return applyTrans(this.transform, v, true);
            },
        },
        hasValidationRules() {
            return this.validationRules.length !== 0;
        },
    },
    watch: {
        // Validate input when changed externally
        value(newValue) {
            this.validateInput(newValue);
        },
        // When the user defined trigger changes then re-validate the input
        validationTrigger: {
            deep: true,
            handler(newValue) {
                this.validateInput(this.value);
            },
        },
        inputErrors() {
            this.validateInput(this.value);
        },
    },
    // Validate the inputs on first load
    created() {
        this.validateInput(this.value);
    },
    destroyed() {
        // Reset the validation status in parent if this input is destroyed
        this.$emit('validate', []);
    },
    methods: {
        setValue(value) {
            const transformedInputValue = applyTrans(this.transform, value, false);
            this.$emit('input', transformedInputValue);
        },
        validateInput(value) {
            if (!this.validationRules.length) {
                // Reset any existing errors.
                this.validationErrors = [...this.inputErrors];
                this.$emit('validate', this.validationErrors);
                return;
            }
            // Run input validation rules.
            const validationPromises = [];
            this.validationRules.forEach(validationRule => {
                const validationResponse = validationRule(value);
                validationPromises.push(validationResponse);
            });
            Promise.all(validationPromises).then(responses => {
                // Reset any existing errors.
                const validationErrors = [...this.inputErrors];
                responses.forEach(validationResponse => {
                    // Invalid responses return the error messages.
                    if (validationResponse !== true) {
                        validationErrors.push(validationResponse);
                    }
                });
                // Report validation state to parent.
                this.validationErrors = validationErrors;
                this.$emit('validate', this.validationErrors);
            });
        },

    },
};
</script>

<style scoped>
.hint-error-row {
    min-height: 11px;
}

.hint-text {
    font-size: 11px;
    color: rgba(0,0,0,0.54);
}
</style>
