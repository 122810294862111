import { APIService } from './api-service.js';

export const API = {
    install(Vue, {
        baseAPIRoute = '/api',
    } = {}) {
        const service = new APIService({
            baseAPIRoute,
        });

        // Register the service globally
        Vue.$api = service;

        // Register the service on the vue instance
        Vue.prototype.$api = service;
    },
};
