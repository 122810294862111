// Contains the application level state
const app = {
    namespaced: true,
    state: {
        forgePanelOpen: false,
        backgroundJobPanelOpen: false,
        filterPanelOpen: false,
        commentPanelOpen: false,
        userPanelOpen: false,
        mainMenuOpen: false,
    },
    mutations: {
        toggleBackgroundJobPanel(state) {
            if (!state.backgroundJobPanelOpen) {
                // If opening, close other overlapping right drawers.
                if (state.userPanelOpen) {
                    state.userPanelOpen = false;
                }
            }
            state.backgroundJobPanelOpen = !state.backgroundJobPanelOpen;
        },
        toggleForgePanel(state) {
            state.forgePanelOpen = !state.forgePanelOpen;
        },
        toggleFilterPanel(state) {
            if (!state.filterPanelOpen) {
                // If opening, close all the other right panels.
                if (state.backgroundJobPanelOpen) {
                    state.backgroundJobPanelOpen = false;
                }
                if (state.commentPanelOpen) {
                    state.commentPanelOpen = false;
                }
                if (state.userPanelOpen) {
                    state.userPanelOpen = false;
                }
            }
            state.filterPanelOpen = !state.filterPanelOpen;
        },
        toggleCommentPanel(state) {
            if (!state.commentPanelOpen) {
                // If opening, close all the other right panels.
                if (state.backgroundJobPanelOpen) {
                    state.backgroundJobPanelOpen = false;
                }
                if (state.filterPanelOpen) {
                    state.filterPanelOpen = false;
                }
                if (state.userPanelOpen) {
                    state.userPanelOpen = false;
                }
            }
            state.commentPanelOpen = !state.commentPanelOpen;
        },
        toggleUserPanel(state) {
            if (!state.userPanelOpen) {
                // If opening, close other overlapping right drawers.
                if (state.backgroundJobPanelOpen) {
                    state.backgroundJobPanelOpen = false;
                }
            }
            state.userPanelOpen = !state.userPanelOpen;
        },
        toggleMainMenu(state) {
            state.mainMenuOpen = !state.mainMenuOpen;
        },
    },
};

export default app;
