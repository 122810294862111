<template>
    <q-list
        bordered
        class="q-mb-lg"
    >
        <q-item>
            <q-item-section>
                <div class="row q-col-gutter-sm">
                    <div class="col col-shrink">
                        <UserProfileImage
                            :user="comment.userId"
                            dimension="0.5em"
                            font-size="inherit"
                        />
                    </div>

                    <div class="col">
                        <div class="row items-center">
                            <div class="col ellipsis column">
                                <UserFullName
                                    :user-id="comment.userId"
                                    class="text-primary text-weight-bold"
                                    hide-link
                                />
                                <div class="row text-grey-6 items-center q-gutter-x-sm">
                                    <span>
                                        <PTimeAgo :date-time="comment.createdAt"/>
                                    </span>
                                    <slot name="info"/>
                                </div>
                            </div>
                            <div
                                v-if="$auth.user.userId === comment.userId"
                                class="col col-shrink"
                            >
                                <CommentMenu
                                    v-if="!hideCommentMenu"
                                    v-cypress="'CommentView_RootComment_Menu'"
                                    @edit="commentIsEditable = !commentIsEditable"
                                    @delete="deleteComment"
                                />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col q-px-sm">
                                <!-- Existing Root Comment -->
                                <CommentEditor
                                    v-cypress="'CommentView_RootComment_Div'"
                                    :class="{'show-editing': commentIsEditable}"
                                    :content="comment.richContent"
                                    :editable="commentIsEditable"
                                    @submit="updateComment"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <CommentInfo
                    v-if="!hideCommentInfo"
                    :replies="replies"
                    :reactions="reactions"
                    :has-liked="userHasLiked"
                    :can-react="!hideCommentActions"
                    class="q-px-xl"
                    @react="reactToComment"
                />
            </q-item-section>
        </q-item>
        <div class="q-pl-xl">
            <q-item v-if="showReply">
                <q-item-section>
                    <div class="row q-col-gutter-sm items-center">
                        <div class="col">
                            <!-- New Reply -->
                            <CommentEditor
                                v-cypress="'CommentView_Reply_Input'"
                                editable
                                clear-after-submit
                                placeholder="Reply"
                                class="comment-input q-px-sm"
                                @submit="createReply"
                            />
                        </div>
                    </div>
                </q-item-section>
            </q-item>
            <GeneralButton
                v-if="hiddenReplies"
                :label="expandMessage"
                tooltip="See other replies"
                dense
                size="md"
                color="grey-8"
                class="q-px-md"
                @click="repliesExpanded = true"
            />
            <!-- Render the replies to the root comment -->
            <q-item
                v-for="(reply, index) in visibleReplies"
                :key="index"
            >
                <Reply
                    :reply="reply.comment"
                    :reactions="reply.reactions"
                    :disable-reactions="hideCommentActions"
                    :hide-comment-info="hideCommentInfo"
                    :hide-comment-menu="hideCommentMenu"
                    @update="updateReply"
                    @delete="deleteReply"
                    @add-reaction="addReactionToReply"
                    @remove-reaction="removeReactionFromReply"
                />
            </q-item>
        </div>
    </q-list>
</template>

<script>
import CommentMenu from './CommentMenu.vue';
import PTimeAgo from '../../services/pai-components/PTimeAgo.vue';
import CommentInfo from './CommentInfo.vue';
import CommentEditor from './editor/CommentEditor.vue';
import Reply from './Reply.vue';
import GeneralButton from '../inline-elements/GeneralButton.vue';

export default {
    name: 'Comment',
    components: {
        Reply,
        CommentEditor,
        CommentInfo,
        PTimeAgo,
        CommentMenu,
        GeneralButton,
    },
    props: {
        comment: {
            type: Object,
            required: true,
        },
        replies: {
            type: Array,
            required: false,
            default: () => [],
        },
        reactions: {
            type: Array,
            required: false,
            default: () => [],
        },
        hideCommentActions: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideCommentMenu: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideCommentInfo: {
            type: Boolean,
            required: false,
            default: false,
        },
        numberRepliesToShow: {
            type: Number,
            required: false,
            default: 3,
            validator(value) {
                return Number.isInteger(value);
            },
        },
    },
    data() {
        return {
            commentIsEditable: false,
            repliesExpanded: false,
        };
    },
    computed: {
        showReply() {
            return !this.hideCommentActions;
        },
        userReactions() {
            const userReactions = this.reactions.filter(reaction => reaction.userId === this.$auth.user.userId);
            return userReactions;
        },
        userHasLiked() {
            return !!this.userReactions.find(reaction => reaction.commentType === 'like');
        },
        hiddenReplies() {
            if (this.repliesExpanded) {
                // Replies have been expanded; show everything.
                return 0;
            }
            if (this.numberRepliesToShow < 0) {
                // Collapsing feature is disabled; show everything.
                return 0;
            }
            if (this.replies.length <= this.numberRepliesToShow) {
                // There aren't enough replies to hide any yet.
                return 0;
            }
            return this.replies.length - this.numberRepliesToShow;
        },
        expandMessage() {
            if (this.hiddenReplies === 1) {
                return 'View 1 more comment';
            }
            if (this.hiddenReplies > 1) {
                return `View ${this.hiddenReplies} more comments`;
            }
            return '';
        },
        visibleReplies() {
            if (this.hiddenReplies) {
                return this.replies.slice(0, this.numberRepliesToShow);
            }
            return this.replies;
        },
    },
    methods: {
        updateComment({ htmlContent, mentionIds }) {
            const comment = {
                id: this.comment.id,
                richContent: htmlContent,
                mentionIds,
            };
            this.commentIsEditable = false;
            this.$emit('update', { comment });
        },
        updateReply(comment) {
            this.$emit('update', { comment, parentId: this.comment.id });
        },
        createReply({ htmlContent, mentionIds }) {
            const comment = {
                richContent: htmlContent,
                parentCommentId: this.comment.id,
                userId: this.$auth.user.userId,
                mentionIds,
            };
            this.$emit('create', comment);
        },
        deleteComment() {
            this.$emit('delete', { id: this.comment.id });
        },
        deleteReply({ id }) {
            this.$emit('delete', { id, parentId: this.comment.id });
        },
        reactToComment(type) {
            const userReaction = this.userReactions.find(reaction => reaction.commentType === type);
            // Remove reaction if user has already reacted
            if (userReaction) {
                this.$emit('remove-reaction', { reaction: userReaction });
            }
            // Create a new reaction
            else {
                const reaction = {
                    commentType: type,
                    parentCommentId: this.comment.id,
                    userId: this.$auth.user.userId,
                };
                this.$emit('add-reaction', { reaction });
            }
        },
        addReactionToReply(reaction) {
            this.$emit('add-reaction', { reaction, parentId: this.comment.id });
        },
        removeReactionFromReply(reaction) {
            this.$emit('remove-reaction', { reaction, parentId: this.comment.id });
        },
    },
};
</script>

<style scoped lang="stylus">
@import '../../../css/quasar.variables.styl'

.show-editing {
    border: solid 1px black;
}

.bottom-border {
    border-bottom: $layout-border;
}

.comment-input {
    border: solid 1px $separator-color;
}
</style>
