import Vue from 'vue';
import { Notify } from 'quasar';
import PDialog from '../pai-components/PDialog.vue';

class NotificationsService {
    constructor({
        color = 'info',
        textColor = 'white',
        html = false,
        icon = 'info',
        avatar = '',
        position = 'bottom',
        classes = '',
        timeout = 3000,
        actions = [{ icon: 'close', color: 'white' }],
        onDismiss = () => undefined,
        closeBtn = '',
        multiLine = false,
        store = null,
    } = {}) {
        this.color = color;
        this.textColor = textColor;
        this.html = html;
        this.icon = icon;
        this.avatar = avatar;
        this.position = 'bottom';
        this.classes = classes;
        this.timeout = timeout;
        this.actions = actions;
        this.onDismiss = onDismiss;
        this.closeBtn = closeBtn;
        this.multiLine = multiLine;
        this.store = store;

        // Configure blocking types
        this.blockingNotificationConfig = {
            network: {
                priority: 1,
                title: 'Network Connection Lost',
                description: 'Connection to network has been lost. Please reconnect to the internet.',
            },
            websocket: {
                priority: 2,
                title: 'Lost Live Data Connection to Petro.ai',
                description: "We're having trouble reaching the Petro.ai Server. This can result from network issues, a change in the firewall policies, or browser extension conflicts. Currently attempting to reconnect...",
            },
            websocketReconnectFailed: {
                priority: 2,
                title: 'Reconnect Attempts to Petro.ai Failed',
                description: 'We cannot reach the Petro.ai Server. This can result from network issues, a change in the firewall policies, or browser extension conflicts. Try refreshing the page. Please contact support if this error persists.',
            },
        };

        this.unblockingNotificationConfig = {
            network: {
                title: 'Reconnected to Network',
                description: 'Connection to the network has been established',
            },
            websocket: {
                title: 'Reconnected to Petro.ai',
                description: 'Connection to Petro.ai has been re-established',
            },
        };

        // Configure event listeners for network status
        window.addEventListener('offline', event => {
            this.block('network', `Network connection lost at ${new Date().toISOString()}`);
        });
        window.addEventListener('online', event => {
            this.unblock('network');
        });
    }

    // Show a positive notification
    // PARAMS: a required message and optional:
    // timeout (use 0 for infinite)
    // array of actions to merge with close btn (see https://quasar.dev/quasar-plugins/notify#Notify-API)
    // onDismiss function to run before closing the notification
    // RETURNS: function that when called closes the notification
    success(message, {
        timeout = this.timeout,
        actions = [],
        onDismiss = this.onDismiss,
    } = {}) {
        const notifyOptions = {
            message,
            color: 'positive',
            textColor: this.textColor,
            html: this.html,
            icon: 'check_circle',
            position: this.position,
            classes: this.classes,
            timeout,
            actions: [...actions, ...this.actions],
            onDismiss,
            multiLine: this.multiLine,
        };
        this.store.dispatch('notifications/submitLog', { message, options: notifyOptions, type: 'positive' });
        return Notify.create(notifyOptions);
    }

    // Show a negative notification
    // PARAMS: a required message and optional:
    // timeout (use 0 for infinite)
    // array of actions to merge with close btn (see https://quasar.dev/quasar-plugins/notify#Notify-API)
    // onDismiss function to run before closing the notification
    // RETURNS: function that when called closes the notification
    error(message, {
        timeout = this.timeout,
        actions = [],
        onDismiss = this.onDismiss,
    } = {}) {
        const notifyOptions = {
            message,
            color: 'negative',
            textColor: this.textColor,
            html: this.html,
            icon: 'error',
            position: this.position,
            classes: this.classes,
            timeout,
            actions: [...actions, ...this.actions],
            onDismiss,
            multiLine: this.multiLine,
        };
        this.store.dispatch('notifications/submitLog', { message, options: notifyOptions, type: 'negative' });
        return Notify.create(notifyOptions);
    }

    // Show a info notification
    // PARAMS: a required message and optional:
    // timeout (use 0 for infinite)
    // array of actions to merge with close btn (see https://quasar.dev/quasar-plugins/notify#Notify-API)
    // onDismiss function to run before closing the notification
    // RETURNS: function that when called closes the notification
    info(message, {
        timeout = this.timeout,
        actions = [],
        onDismiss = this.onDismiss,
    } = {}) {
        const notifyOptions = {
            message,
            color: 'info',
            textColor: this.textColor,
            html: this.html,
            icon: 'info',
            position: this.position,
            classes: this.classes,
            timeout,
            actions: [...actions, ...this.actions],
            onDismiss,
            multiLine: this.multiLine,
        };
        this.store.dispatch('notifications/submitLog', { message, options: notifyOptions, type: 'info' });
        return Notify.create(notifyOptions);
    }

    // Show a warning notification
    // PARAMS: a required message and optional:
    // timeout (use 0 for infinite)
    // array of actions to merge with close btn (see https://quasar.dev/quasar-plugins/notify#Notify-API)
    // onDismiss function to run before closing the notification
    // RETURNS: function that when called closes the notification
    warning(message, {
        timeout = this.timeout,
        actions = [],
        onDismiss = this.onDismiss,
    } = {}) {
        const notifyOptions = {
            message,
            color: 'warning',
            textColor: this.textColor,
            html: this.html,
            icon: 'warning',
            position: this.position,
            classes: this.classes,
            timeout,
            actions: [...actions, ...this.actions],
            onDismiss,
            multiLine: this.multiLine,
        };
        this.store.dispatch('notifications/submitLog', { message, options: notifyOptions, type: 'warning' });
        return Notify.create(notifyOptions);
    }

    confirm(message, {
        title = 'Please confirm',
        okButtonText = 'OK',
        okButtonTooltip = 'Confirm the action',
        cancelButtonText = 'Cancel',
        cancelButtonTooltip = 'Cancel the action',
        onOk = null,
        onCancel = null,
    } = {}) {
        const ComponentClass = Vue.extend(PDialog);
        const instance = new ComponentClass({
            propsData: {
                title,
                okBtnText: okButtonText,
                okBtnTooltip: okButtonTooltip,
                cancelBtnText: cancelButtonText,
                cancelBtnTooltip: cancelButtonTooltip,
                value: true,
                okBtnTestId: 'NotifyConfirm_Ok_Btn',
            },
        });
        instance.$on(['ok'], e => {
            if (onOk) {
                onOk((closeDialog = true) => {
                    if (closeDialog) {
                        instance.value = false;
                    }
                });
            }
            else {
                instance.value = false;
            }
        });
        instance.$on(['cancel'], e => {
            if (onCancel) {
                onCancel((closeDialog = true) => {
                    if (closeDialog) {
                        instance.value = false;
                    }
                });
            }
            else {
                instance.value = false;
            }
        });
        instance.$slots.default = [message];
        instance.$mount();
        return instance;
    }

    block(type, details) {
        const typeConfig = this.blockingNotificationConfig[type];
        if (!typeConfig) {
            throw new Error(`${type} is not a recognized blocking notification type`);
        }

        this.store.commit('notifications/showBlockingNotification', {
            title: typeConfig.title,
            description: typeConfig.description,
            details,
        });
    }

    unblock(type) {
        const typeConfig = this.unblockingNotificationConfig[type];
        if (!typeConfig) {
            throw new Error(`${type} is not a recognized blocking notification type`);
        }
        this.store.commit('notifications/deactivateBlockingNotification', {
            title: typeConfig.title,
            description: typeConfig.description,
        });
    }
}

export default NotificationsService;
