<template>
    <ItemPage>
        <template slot="profileVisualizationComponent">
            <div
                v-if="profileUser"
                class="full-height"
            >
                <img
                    src="@/assets/images/petro-purple-bg.png"
                    class="full-width full-height"
                    style="position: absolute"
                >
                <UserProfileImage
                    :user="profileUser"
                    border-radius="0"
                    dimension="100%"
                    font-size="10rem"
                />
            </div>
        </template>

        <template slot="actionsBar">
            <UserActionsBar
                :profile-url="profileUrl"
                :viewing-self="viewingSelf"
            />
        </template>

        <template slot="summaryTitles">
            <div class="text-h5 q-pt-lg center-row text-bold ellipsis">
                <UserFullName
                    :user="profileUser"
                    hide-link
                />
            </div>
            <div class="center-row q-pt-md">
                User Profile
            </div>
        </template>
    </ItemPage>
</template>

<script>
import petroJSSettings from '../../../../petrojs-settings.json';
import ItemPage from '../ItemPage';
import UserActionsBar from './components/UserActionsBar';

export default {
    name: 'TheUserProfilePage',
    components: {
        ItemPage,
        UserActionsBar,
    },
    computed: {
        currentUserId() {
            return this.$auth.user.userId;
        },
        viewingSelf() {
            return this.profileId === this.currentUserId;
        },
        profileId() {
            let pId = this.$route.params.userId;
            if (!pId) {
                pId = this.currentUserId;
            }
            return pId;
        },
        profileUrl() {
            return `${petroJSSettings.portalUrl}/settings/profile`;
        },
        profileUser() {
            return this.$users.allUsers.find((us) => us.userId === this.profileId);
        },
    },
};
</script>

<style scoped>
    .center-row {
        display: flex;
        justify-content: center;
    }
</style>
