<template>
    <div id="DataChart">
        <q-toolbar>
            <q-space/>
            <GeneralButton
                label="Export"
                tooltip="See export options"
                icon="get_app"
                flat
            >
                <q-menu
                    anchor="bottom right"
                    self="top right"
                >
                    <q-list dense>
                        <q-item
                            clickable
                            @click="makePost"
                        >
                            <q-item-section>
                                Export to PNG
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-menu>
            </GeneralButton>

            <GeneralButton
                v-if="showChartOptions"
                label="Edit"
                tooltip="Edit Chart Options"
                icon="edit"
                flat
                @click="showOptions = true"
            />

            <GeneralButton
                label="Comment"
                tooltip="Toggle comment mode"
                icon="add_comment"
                :flat="!commentModeEnabled"
                :outline="false"
                :color="commentModeEnabled ? 'primary' : null"
                @click="toggleCommentMode"
            />
        </q-toolbar>

        <DataVisualization
            v-if="internalOptions.layers"
            ref="viz"
            v-bind="internalOptions"
            :data-sources="expandedDataSources"
            :can-comment="commentModeEnabled"
            class="calculated-full-height"
            @dataselected="dataSelected"
        />
        <div v-else>
            Options are not yet set.
        </div>

        <PDialog
            v-model="showOptions"
            title="Edit Chart Options"
            hide-ok-btn
            cancel-btn-text="Close"
            cancel-btn-tooltip="Close"
        >
            <AutoForm
                v-if="userOptions"
                :value="userOptions"
                :initial-value="userOptions"
                :schema="schema"
                @input="setOptions"
            />
        </PDialog>

        <q-dialog
            v-model="showPost"
            persistent
        >
            <q-card style="width: 700px; max-width: 80vw;">
                <img
                    v-if="currentPostImageUrl"
                    :src="currentPostImageUrl"
                >

                <!-- Notice v-close-popup -->
                <q-card-actions align="right">
                    <q-btn
                        v-close-popup
                        flat
                        label="Close"
                        color="primary"
                    />
                </q-card-actions>
            </q-card>
        </q-dialog>
    </div>
</template>

<script>
import DataVisualization from './DataVisualization.vue';
import AutoForm from '../auto-form/AutoForm.vue';
import GeneralButton from '../inline-elements/GeneralButton.vue';

export default {
    name: 'DataChart',
    components: {
        GeneralButton,
        DataVisualization,
        AutoForm,
    },
    props: {
        defaultOptions: {
            type: Function,
            required: true,
        },
        schema: {
            type: Object,
            required: false,
            default: () => {},
        },
        createOptions: {
            type: Function,
            required: true,
        },
        dataSources: {
            type: Object,
            required: false,
            default: () => {},
        },
        showChartOptions: {
            type: Boolean,
            default: true,
            required: false,
        },
    },
    data() {
        return {
            internalOptions: {},
            userOptions: this.defaultOptions(),
            showOptions: false,
            showPost: false,
            currentPostImageUrl: null,
            expandedDataSources: {},
            commentModeEnabled: false,
        };
    },
    computed: {
        // TODO: Fix after comments revamp
        chartComments() {
            if (!this.allComments || !this.internalOptions.chartCommentPartName) {
                return [];
            }
            const chartName = this.internalOptions.chartCommentPartName();
            return this.allComments.filter(cmt => cmt.partName === chartName);
        },
    },
    watch: {
        userOptions() {
            this.updateOptions();
        },
        dataSources() {
            this.updateDataSources();
        },
        chartComments() {
            this.updateDataSources();
        },
    },
    mounted() {
        this.updateOptions();
    },
    methods: {
        updateDataSources() {
            const commentsDataSources = {};
            this.chartComments.forEach(cmt => {
                const context = cmt.context;
                const dataSource = `comments-${context.seriesId}`;
                if (commentsDataSources[dataSource] === undefined) {
                    commentsDataSources[dataSource] = {
                        format: 'index',
                        columns: [],
                        data: {},
                    };
                }
                const commentColumns = commentsDataSources[dataSource].columns;
                const commentsData = commentsDataSources[dataSource].data;
                const content = cmt.content;
                if (commentsData.content === undefined) {
                    commentsData.content = [];
                    commentColumns.push({
                        name: 'content',
                        valueField: 'content',
                    });
                }
                commentsData.content.push(content);
                if (commentsData.id === undefined) {
                    commentsData.id = [];
                    commentColumns.push({
                        name: 'id',
                        valueField: 'id',
                    });
                }
                commentsData.id.push(cmt.id);
                Object.keys(context).forEach(key => {
                    const value = context[key];
                    if (commentsData[key] === undefined) {
                        commentsData[key] = [];
                        commentColumns.push({
                            name: key,
                            valueField: key,
                        });
                    }
                    commentsData[key].push(value);
                });
            });
            this.expandedDataSources = Object.assign(commentsDataSources, this.dataSources);
        },
        updateOptions() {
            this.internalOptions = Object.freeze(this.createOptions(this.userOptions));
        },
        setOptions(value) {
            // Is this bad?
            // this.options = value;
            this.userOptions = { ...value };
            this.$emit('optionsChanged', value);
        },
        makePost() {
            const imageUrl = this.$refs.viz.getDataURL({});
            this.currentPostImageUrl = imageUrl;
            this.showPost = true;
        },
        toggleCommentMode() {
            this.commentModeEnabled = !this.commentModeEnabled;
        },
        dataSelected(layerItems) {
            this.$emit('dataSelected', layerItems);
        },
    },
};
</script>

<style scoped>
.calculated-full-height {
    /* height of chart toolbar */
    height: calc(100% - 3rem);
}
</style>
