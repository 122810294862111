import { Extension } from 'tiptap';

export default class RootEnter extends Extension {
    keys() {
        return {
            // If enter is pressed from the root paragraph node (i.e. depth 1) then call handler
            Enter: (state, dispatch, view) => {
                const node = state.selection.$from.node(depth);
                const depth = state.selection.$head.depth;
                if (node.type.name !== 'paragraph' || depth > 1) {
                    // Indicate that enter is not handled by this extension--continue on
                    return false;
                }
                this.options.handler(state, dispatch, view);
                // Indicate that enter has been handled--do not process any more keys
                return true;
            },
        };
    }
}
