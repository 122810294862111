import { TelemetryService } from './telemetry-service.js';

export const TelemetryPlugin = {
    install(Vue, { config = {}, router = null } = {}) {
        const telemetryService = new TelemetryService({ config, router });

        Vue.$telemetry = telemetryService;
        Vue.prototype.$telemetry = telemetryService;
    },
};
