import ForgeModule from './forge-module.js';

const ForgePackage = {
    install(Vue, {
        store = null,
        moduleName = 'forge',
    } = {}) {
        if (!store) {
            throw new Error('Forge plugin must be passed a vuex store.');
        }

        // Dynamically register the Forge module on the provided store
        store.registerModule(moduleName, ForgeModule);
    },
};

export default ForgePackage;
