export function FeatureFlagsModel({ app, plugin }) {
    return {
        fields: {
            showFeatureFlagDialog: {
                source: 'self',
                options: {
                    default: false,
                },
            },
            featureFlagsForm: {
                source: 'self',
                options: {
                    default: {
                        featureFlags: '',
                    },
                },
            },
        },
        getters: {
            initialFeatureFlags: (state) => {
                const featureFlags = app.$globalSettings.settings.featureFlags;
                if (featureFlags) {
                    // convert feature flags list to text separated by new lines
                    const textFeatureFlags = featureFlags.join('\n');
                    return { featureFlags: textFeatureFlags };
                }
                return { featureFlags: '' };
            },
        },
        actions: {
            saveFeatureFlags({ state }, textFeatureFlags) {
                const featureFlags = textFeatureFlags.trim().split('\n');
                app.$globalSettings.updateSettings({ featureFlags });
            },
        },
    };
}
