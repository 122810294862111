import Vue from 'vue';

const notificationsModule = {
    namespaced: true,
    state: {
        logs: [],
        blockingNotification: {
            priority: 0,
            display: false,
            active: false,
            title: '',
            description: '',
            details: null,
        },
    },
    getters: {
        errors: (state) => state.logs.filter(x => x.type === 'negative'),
        warnings: (state) => state.logs.filter(x => x.type === 'warning'),
        updates: (state) => state.logs.filter(x => x.type === 'info'),
        successes: (state) => state.logs.filter(x => x.type === 'positive'),
    },
    mutations: {
        addLog(state, log) {
            state.logs.unshift({ ...log, index: state.logs.length, timestamp: Date.now() });
            // Remove the last entry after 150
            if (state.logs.length > 150) {
                state.logs.pop();
            }
        },
        emptyLogs(state) {
            state.logs = [];
        },
        setBlockingNotificationDisplay(state, value) {
            state.blockingNotification.display = value;
        },
        showBlockingNotification(state, { title, description, details }) {
            state.blockingNotification.title = title;
            state.blockingNotification.description = description;
            state.blockingNotification.details = details;
            state.blockingNotification.active = true;
            state.blockingNotification.display = true;
        },
        deactivateBlockingNotification(state, { title, description }) {
            state.blockingNotification.active = false;
            state.blockingNotification.display = false;
            state.blockingNotification.title = title;
            state.blockingNotification.description = description;
            state.blockingNotification.details = null;
        },
    },
    actions: {
        submitLog({ commit }, entry) {
            commit('addLog', {
                message: entry.message,
                options: { ...entry.options },
                type: entry.type,
            });
        },
    },
};

export default notificationsModule;
