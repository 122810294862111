import { formatting } from './formatting-utils.js';
import { conversion } from './conversion-utils.js';

export class UtilsService {
    constructor(options) {
        this.store = options.store;
        this.formatting = formatting;
        this.conversion = conversion;
    }
}
