<template>
    <PRouterLink
        :router-to="launchRoute"
    >
        <PCard
            :title="title"
            class="tool-card"
        >
            <div
                class="ellipsis-2-lines q-pb-none"
                style="height: 2.5rem;"
            >
                {{ description }}
                <q-tooltip :delay="750">
                    {{ description }}
                </q-tooltip>
            </div>
            <template v-slot:footer>
                <GeneralButton
                    :tooltip="`${isDocked? 'Remove this app from the header bar' : 'Dock this app to the header bar'}`"
                    color="primary"
                    :text-color="`${isDocked ? 'white' : 'primary'}`"
                    icon="fa fa-thumbtack"
                    :flat="false"
                    dense
                    :outline="!isDocked"
                    :disabled="!canToggleDock"
                    @click.capture.stop="toggleApp"
                />
            </template>
        </PCard>
    </PRouterLink>
</template>

<script>

import PRouterLink from '../services/pai-components/PRouterLink.vue';
import GeneralButton from '../shared-components/inline-elements/GeneralButton.vue';

export default {
    name: 'FAppCard',
    components: {
        PRouterLink,
        GeneralButton,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        launchRoute: {
            type: Object,
            required: true,
        },
        isDocked: {
            type: Boolean,
            required: false,
            default: false,
        },
        canToggleDock: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    methods: {
        toggleApp(event) {
            event.preventDefault();
            this.$emit('toggleDock');
        },
    },
};
</script>

<style scoped>

</style>
