<template>
    <q-td :class="cellClasses">
        <q-linear-progress
            size="20px"
            :color="context.col.typeOptions ? context.col.typeOptions.color : ''"
            :value="calculateProgressForColumn"
        >
            <div class="absolute-full flex flex-center">
                <q-badge
                    :label="label"
                    :text-color="textColor"
                    :color="color"
                    rounded
                />
            </div>
        </q-linear-progress>
    </q-td>
</template>

<script>
import { formatting } from '../../../services/utils/formatting-utils.js';

export default {
    name: 'ProgressColumn',
    props: {
        context: {
            type: Object,
            required: true,
        },
    },
    computed: {
        cellClasses() {
            return {
                'text-center': this.context.col.align === 'center',
                'text-left': this.context.col.align === 'left',
                'text-right': !this.context.col.align || this.context.col.align === 'right',
            };
        },
        calculateProgressForColumn() {
            let progress = 0.0;
            if (this.context.value && this.context.col.typeOptions) {
                progress = this.calculateProgress(this.context.col.typeOptions, this.context.value);
            }
            return progress;
        },
        label() {
            let label;
            if (this.context.value && this.context.col.typeOptions && this.context.col.typeOptions.label) {
                if (this.context.col.typeOptions.label === 'absolute') {
                    const decimalPlaces = this.context.col.typeOptions.decimalPlaces || 2;
                    label = `${formatting.formatNumber(this.context.value, { decimalPlaces })}`;
                }
                else if (this.context.col.typeOptions.label === 'percent') {
                    const decimalPlaces = this.context.col.typeOptions.decimalPlaces || 1;
                    const progress = formatting.formatPercent(this.calculateProgress(this.context.col.typeOptions, this.context.value), { decimalPlaces });
                    label = `${progress}`;
                }
            }
            return label;
        },
        textColor() {
            return this.context.col.typeOptions ? this.context.col.typeOptions.textColor : undefined;
        },
        color() {
            return this.context.col.typeOptions ? this.context.col.typeOptions.labelBackgroundColor : 'white';
        },
    },
    methods: {
        calculateProgress(options, value) {
            const min = options.min || 0;
            const max = options.max || 1.0;
            return (value - min) / (max - min);
        },

    },
};
</script>

<style scoped>

</style>
