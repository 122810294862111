export class ApiControllerAppliances {
    constructor({ axiosInstance, route } = {}) {
        // Configured base axios instance
        this.axiosInstance = axiosInstance;

        // Route appended to the axios instance baseURL
        this.route = route;
    }

    // Get web server information
    getStatuses() {
        return this.axiosInstance.get(this.route);
    }
}
