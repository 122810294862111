<template>
    <q-page
        v-if="forgeResourcesLoaded"
        class="column"
    >
        <div
            class="q-pa-md col-grow"
            style="height: 100%;"
        >
            <div class="row">
                <div class="col">
                    Padding A
                </div>
                <div class="col-lg-8 col-md-8 col-sm-10">
                    <div class="row">
                        <div class="col-4">
                            <q-item>
                                <q-item-section
                                    avatar
                                >
                                    <q-avatar
                                        color="primary"
                                        text-color="white"
                                        icon="bluetooth"
                                        square
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label>
                                        <strong>Well Name</strong>
                                    </q-item-label>
                                    <q-item-label>
                                        Information on the Well.
                                    </q-item-label>
                                    <q-item-label caption>
                                        Some more info, but smaller. Date and Operator.
                                    </q-item-label>
                                </q-item-section>
                            </q-item>
                        </div>
                        <div class="col-4">
                            <div class="row">
                                <div class="col">
                                    <strong>5,602 ft</strong><br>
                                    Lateral Length
                                </div>
                                <q-separator
                                    vertical
                                    inset
                                />
                                <div class="col">
                                    <strong>34.02 bbl/ft</strong>
                                    <br>
                                    EUR per Foot
                                </div>
                                <q-separator vertical/>
                                <div class="col">
                                    <strong>23.0</strong>
                                    <br>
                                    Prop per Ft
                                </div>
                            </div>
                            Some info on the stats.
                        </div>
                        <div class="col-4 justify-end">
                            <q-btn-group outline>
                                <q-btn
                                    icon="share"
                                />
                                <q-btn
                                    icon="favorite"
                                />
                                <q-btn
                                    icon="star"
                                />
                            </q-btn-group>
                        </div>
                        <div class="col-12">
                            <q-tabs
                                v-model="tab"
                                dense
                                class="text-grey"
                                active-color="primary"
                                indicator-color="primary"
                                align="justify"
                                narrow-indicator
                            >
                                <q-tab
                                    name="mails"
                                    label="Mails"
                                />
                                <q-tab
                                    name="alarms"
                                    label="Alarms"
                                />
                                <q-tab
                                    name="movies"
                                    label="Movies"
                                />
                            </q-tabs>
                            <q-separator/>
                        </div>
                        <div class="col-12">
                            <q-carousel
                                v-model="slide"
                                animated
                                navigation
                                infinite
                                :autoplay="autoplay"
                                arrows
                                transition-prev="slide-right"
                                transition-next="slide-left"
                                @mouseenter="autoplay = false"
                                @mouseleave="autoplay = true"
                            >
                                <q-carousel-slide
                                    :name="1"
                                    img-src="https://cdn.quasar.dev/img/mountains.jpg"
                                />
                                <q-carousel-slide
                                    :name="2"
                                    img-src="https://cdn.quasar.dev/img/parallax1.jpg"
                                />
                                <q-carousel-slide
                                    :name="3"
                                    img-src="https://cdn.quasar.dev/img/parallax2.jpg"
                                />
                                <q-carousel-slide
                                    :name="4"
                                    img-src="https://cdn.quasar.dev/img/quasar.jpg"
                                />
                            </q-carousel>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div
                                v-if="sections"
                                class="row"
                            >
                                <div
                                    v-for="(section, index) in sections"
                                    :key="index"
                                    class="col-12"
                                >
                                    <Section :section="section"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 col-md-3">
                            <!-- Action list -->
                            <q-list
                                bordered
                                separator
                            >
                                <q-item
                                    v-for="item in sidebarItems"
                                    :key="item.props.title"
                                    v-ripple
                                    clickable
                                >
                                    <q-item-section>{{ item.props.title }}</q-item-section>
                                </q-item>
                            </q-list>
                        </div>
                    </div>
                </div>
                <div class="col">
                    Padding B
                </div>
            </div>
        </div>
    </q-page>
    <q-page v-else>
        <ForgeLoadState :resources="forgeResourceElements"/>
    </q-page>
</template>

<script>
import mixins from '../../mixins/index.js';
import ForgeLoadState from '../shared-components/ForgeLoadState.vue';
import Section from '../shared-components/sections/Section.vue';

export default {
    name: 'ProfilePageTemplate',
    components: {
        ForgeLoadState,
        Section,
    },
    mixins: [mixins.compute, mixins.lifecycleHooks({ addProps: false })],
    props: {
        props: {
            type: Object,
            required: true,
        },
        events: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            autoplay: false,
            slide: 1,
            sectionFragments: [],
            tab: 'mails',
        };
    },
    computed: {
        forgeResources() {
            return this.props.forgeResources || [];
        },
        title() {
            return this.props.title || 'No Title';
        },
        sidebarItems() {
            return this.props.sidebar || [];
        },
        sections() {
            return this.props.sections || [];
        },
    },
};
</script>

<style scoped>

</style>
