<template>
    <q-btn-dropdown
        v-if="currentGlobalApp"
        v-cypress="'GlobalAppDropdown_Apps_Button'"
        :label="currentGlobalApp"
        no-caps
        menu-anchor="bottom left"
        menu-self="top left"
        :class="dropdownClass"
        @show="menuShowing = true"
        @hide="menuShowing = false"
    >
        <q-list>
            <ExpandableActionList
                title="Available Apps"
                :items="mappedAvailablePetronApps"
                class="q-py-md text-secondary"
                @item-clicked="choosePetron"
            />
            <ExpandableActionList
                title="Global Apps"
                :items="mappedAvailableGlobalApps"
                class="q-py-md text-secondary"
                @item-clicked="$emit('navigate')"
            />
        </q-list>
        <ChoosePetronDialog
            v-model="showPetronDialog"
            ok-button-text="Go to App"
            :ok-button-tooltip="petronNavigateMessage"
            @petron-selected="goToPetronApp"
            @cancel="selectedPetronApp = null"
        />
    </q-btn-dropdown>
</template>

<script>
import { formatting } from '../plugin-framework/services/utils/formatting-utils.js';
import ExpandableActionList from '../plugin-framework/services/pai-components/ExpandableActionList';
import ChoosePetronDialog from './ChoosePetronDialog';

export default {
    name: 'GlobalAppDropdown',
    components: {
        ChoosePetronDialog,
        ExpandableActionList,
    },
    data() {
        return {
            menuShowing: false,
            showPetronDialog: false,
            selectedPetronApp: null,
        };
    },
    computed: {
        dropdownClass() {
            return this.menuShowing ? 'text-bold text-accent' : 'text-grey-4';
        },
        currentGlobalApp() {
            // See if the route has a name defined and matches a fragment
            const matchedFragment = this.allAppCardFragments.find(fragment => fragment.props.launchRoute.name === this.$route.name);
            if (matchedFragment && matchedFragment.props.title) {
                return matchedFragment.props.title;
            }
            // If we can't find matching route then use the pluginId as a fallback
            const meta = this.$route.meta;
            if (meta && meta.pluginId) {
                return this.$utils.formatting.startCase(meta.pluginId);
            }
            // If nothing was found, just default to null.
            return null;
        },
        allAppCardFragments() {
            return [...this.enabledPetronApps, ...this.enabledGlobalApps];
        },
        enabledPetronApps() {
            const appCards = this.$pai.getFragments('app-card');
            if (!appCards) {
                return [];
            }
            return appCards.filter(appCard => !appCard.props.hide);
        },
        enabledGlobalApps() {
            const appCards = this.$pai.getFragments('global-app-card');
            if (!appCards) {
                return [];
            }
            return appCards.filter(appCard => !appCard.props.hide);
        },
        mappedAvailablePetronApps() {
            const availableAppsSorted = [...this.enabledPetronApps];
            availableAppsSorted.sort((a, b) => a.props.title.localeCompare(b.props.title));
            return availableAppsSorted.map(app => {
                return {
                    title: app.props.title,
                    route: app.props.launchRoute,
                    tooltip: app.props.description,
                    app,
                };
            });
        },
        mappedAvailableGlobalApps() {
            const availableAppsSorted = [...this.enabledGlobalApps];
            availableAppsSorted.sort((a, b) => a.props.title.localeCompare(b.props.title));
            return availableAppsSorted.map(app => {
                return {
                    title: app.props.title,
                    to: {
                        name: app.props.launchRoute.name,
                    },
                    tooltip: app.props.description,
                    app,
                };
            });
        },
        petronNavigateMessage() {
            if (this.selectedPetronApp) {
                return `Go to '${this.selectedPetronApp.title} in selected Petron`;
            }
            return '';
        },
    },
    methods: {
        choosePetron(appClicked) {
            this.selectedPetronApp = appClicked;
            this.showPetronDialog = true;
        },
        goToPetronApp(petronId) {
            this.$router.push({
                name: this.selectedPetronApp.route.name,
                params: {
                    petronId,
                },
            });
            this.$emit('navigate');
            this.selectedPetronApp = null;
        },
    },
};
</script>

<style scoped>
</style>
