import ApplicationLayout from '../../../layouts/ApplicationLayout.vue';
import DefaultPageLayout from '../../../layouts/DefaultPageLayout.vue';
import TheLibraryPage from '../components/TheLibraryPage.vue';

export function LibraryRoute({ app, plugin }) {
    return {
        type: 'fragment',
        target: 'root-route',
        options() {
            return {
                path: '/library',
                meta: {
                    auth: true,
                    pluginId: plugin.$definition.name,
                },
                component: ApplicationLayout,
                children: [
                    {
                        path: '/library',
                        components: {
                            appMain: DefaultPageLayout,
                        },
                        children: [
                            {
                                path: '',
                                name: 'petron-library',
                                components: {
                                    pageContent: TheLibraryPage,
                                },
                                props: {
                                    pageContent: (route) => {
                                        return {
                                            allPetrons: this.allPetrons,
                                            forgeResources: this.forgeResources,
                                            favoritePetrons: this.favoritePetrons,
                                            collaboratorPetrons: this.collaboratorPetrons,
                                            ownedPetrons: this.ownedPetrons,
                                            createPetronFunc: this.createPetron,
                                            removePetronFunc: this.removePetron,
                                            addToFavoritesFunc: this.addToFavorites,
                                            removeFromFavoritesFunc: this.removeFromFavorites,
                                            copyPetronFunc: this.copyPetron,
                                        };
                                    },
                                },
                            },
                        ],
                    },
                ],
            };
        },
    };
}
