export class ForgeElementHandle {
    constructor({
        log,
        elementName,
        workspace,
        format,
        onCleanup,
    }) {
        this.log = log;
        this.elementName = elementName;
        this.workspace = workspace;
        this.uri = `${this.workspace}#${this.elementName}`;
        this.format = format;
        // The url contains all the info on the element
        this.url = `${this.uri}!${this.format}`;
        this.isLoaded = false;

        // List of subscribers who are using forge element
        this.__subscribers = [];
        this.__cleanup = onCleanup;
    }

    subscribe(subscriber) {
        this.log.debug(`Subscribing ${subscriber} to ${this.elementName} resource`);
        this.__subscribers.push(subscriber);
    }

    unsubscribe(subscriber) {
        this.log.debug(`Unsubscribing ${subscriber} from ${this.elementName} resource`);
        this.__subscribers = this.__subscribers.filter(x => x !== subscriber);

        if (this.__subscribers.length === 0) {
            if (this.__cleanup) {
                this.__cleanup(this);
            }
        }
    }
}
