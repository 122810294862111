import Vue from 'vue';
import EventEmitter from 'events';
import { Logger } from '../logging/logger.js';
import { ForgeWorkspaceHandle } from './ForgeWorkspaceHandle.js';
import { ForgeDisposableReference } from './ForgeDisposableReference.js';

const signalR = require('@microsoft/signalr');

// This is a lock used to ensure that multiple senders don't try to send to the
// same variable at the same time
const sendingLock = () => {
    const locked = {};
    const ee = new EventEmitter();
    ee.setMaxListeners(0);

    return {
        acquire: key => new Promise(resolve => {
            let numTries = 0;
            if (!locked[key]) {
                locked[key] = true;
                return resolve(numTries);
            }
            const tryAcquire = () => {
                numTries += 1;
                if (!locked[key]) {
                    locked[key] = true;
                    ee.removeListener(key, tryAcquire);
                    return resolve(numTries);
                }
            };

            ee.on(key, tryAcquire);
        }),
        release: key => {
            Reflect.deleteProperty(locked, key);
            setImmediate(() => ee.emit(key));
        },
    };
};

export class ComputeService {
    constructor({
        moduleName, store, url, reconnectWaitTimesSec, reconnectBlockUITimeoutSec,
    }) {
        this.log = new Logger({ name: 'ComputeService', level: Logger.levels.warn });
        Vue.$logging.registerLogger(this.log);

        this.moduleName = moduleName;

        this.__setVariableLock = sendingLock();
        this.__store = store;
        this.__url = process.env.NODE_ENV === 'development' ? url : '';
        this.__connection = null;
        this.__forgeWorkspaceHandles = {};
        this.__staleTimeoutMs = 10000;
        this.__updateInterval = 100;
        this.__updateIntervalHandle = null;
        this.__isUpdating = false;
        this.__requestMaxAttempts = 50;
        this.__setVariableMaxAttempts = 50;
        // Turn this on to update automatically
        this.__runUpdateLoop = true;
        this.__resourceRequestThrottleMs = 500;
        // Set the backoff retry interval times in seconds converted to ms for signalr
        this.__reconnectWaitTimesMs = reconnectWaitTimesSec.map(seconds => seconds * 1000);
        this.__reconnectBlockUITimeoutMs = reconnectBlockUITimeoutSec * 1000;
        this.__blockUITimeout = null;

        Vue.$auth.afterSignIn('initializeComputeServiceWebsocket', user => {
            this.buildConnection();
            this.startConnection();
        });

        Vue.$auth.beforeSignOut('stopComputeServiceWebsocket', user => {
            // Wait for the UI to gracefully close all references to forge resources by calling ForgeExitClient
            // TODO: Figure out a better way to do this
            setTimeout(this.stopConnection.bind(this), 2000);
        });
    }

    getElement(uri) {
        return this.__store.getters[this.__getNamespacedProperty('getElement')](uri);
    }

    get initialElementsInfo() {
        return this.__store.getters[this.__getNamespacedProperty('initialElementsInfo')];
    }

    get readyElementsInfo() {
        return this.__store.getters[this.__getNamespacedProperty('readyElementsInfo')];
    }

    get staleElementsInfo() {
        return this.__store.getters[this.__getNamespacedProperty('staleElementsInfo')];
    }

    get errorElementsInfo() {
        return this.__store.getters[this.__getNamespacedProperty('errorElementsInfo')];
    }

    get failedSetVariableInfo() {
        return this.__store.getters[this.__getNamespacedProperty('failedSetVariableInfo')];
    }

    get dirtyElementsInfo() {
        return this.__store.getters[this.__getNamespacedProperty('dirtyElementsInfo')];
    }

    get healthyElementsInfo() {
        return this.__store.getters[this.__getNamespacedProperty('healthyElementsInfo')];
    }

    get connectionLive() {
        return this.__store.state[this.moduleName].connectionLive;
    }

    set connectionLive(value) {
        this.__store.commit(this.__getNamespacedProperty('setConnectionLive'), value);
    }

    __getNamespacedProperty(name) {
        return `${this.moduleName}/${name}`;
    }

    buildConnection() {
        this.log.debug('Connecting to signalR hub');
        this.connectionLive = false;

        // Set logging level based on environment
        const loggingLevel = process.env.NODE_ENV === 'development' ? signalR.LogLevel.Warning : signalR.LogLevel.Critical;

        // Establish a new connection
        this.__connection = new signalR.HubConnectionBuilder()
        .withUrl(`${this.__url}/hubs/kernels`, {
            accessTokenFactory: () => Vue.$auth.token,
        })
        // Attempt to automatically reconnect waiting the reconnect backoff times before reattempting
        .withAutomaticReconnect(this.__reconnectWaitTimesMs)
        .configureLogging(loggingLevel)
        .build();

        this.__connection.on('Connected', () => {
            this.log.debug('Connected to signalR hub');
        });

        this.__connection.on('ForgeEvents', (updates) => {
            this.log.debug('ForgeEvents updates:', updates);
            if (updates.events) {
                updates.events.forEach(forgeEvent => {
                    switch (forgeEvent.eventSource) {
                        case 'Resource':
                            this.handleResourceForgeEvent(forgeEvent);
                            break;
                        case 'Flow':
                            this.handleFlowForgeEvent(forgeEvent);
                            break;
                        default:
                            this.handleUnknownForgeEvent(forgeEvent);
                            break;
                    }
                });
            }
            else {
                this.log.error('ForgeEvents message did not contain events', updates);
            }
        });

        this.__connection.onclose((error) => {
            // If no error then connection closed intentionally
            if (!error) {
                this.log.error('Connection closed');
            }
            else {
                this.log.error('Connection Closed with error:', error);
            }

            this.connectionLive = false;
            this.clearBlockUITimeout();
            // Inform user that
            Vue.$notify.block('websocketReconnectFailed', 'Failed to reconnect to Petro.ai');
        });

        this.__connection.onreconnecting(error => {
            this.log.warn('Connection lost, attempting reconnection:', error);
            this.log.warn(`UI will block user if connection not reestablished in ${this.__reconnectBlockUITimeoutMs}ms`);
            this.connectionLive = false;
            // Wait to block the user from interacting to give time for reconnection
            this.__blockUITimeout = setTimeout(() => {
                Vue.$notify.block('websocket');
            }, this.__reconnectBlockUITimeoutMs);
        });

        this.__connection.onreconnected(() => {
            this.log.info('Reconnection attempt successful');
            this.connectionLive = true;
            this.clearBlockUITimeout();
            Vue.$notify.unblock('websocket');
        });
    }

    clearBlockUITimeout() {
        if (this.__blockUITimeout) {
            clearTimeout(this.__blockUITimeout);
            this.__blockUITimeout = null;
        }
    }

    startConnection() {
        return new Promise((resolve, reject) => {
            try {
                this.__connection.start()
                .then(() => {
                    // If the modal is open, make sure to close it on startup
                    Vue.$notify.unblock('websocket');

                    // Mark the connection as live
                    this.connectionLive = true;
                    this.log.debug('Websocket connection started');

                    // Start the main forge update interval
                    if (this.__runUpdateLoop) {
                        this.__updateIntervalHandle = setInterval(this.update.bind(this), this.__updateInterval);
                    }
                    // Connect to all the workspaces that are currently being watched
                    this.forgeReconnectWorkspaces();
                    resolve({ success: true });
                })
                .catch(error => {
                    this.log.error('There was an error while starting websocket connection', error);
                    this.stopConnection();
                    reject(error);
                });
            }
            catch (err) {
                this.log.error('There was an error while starting websocket connection', err);
                reject(err);
            }
        });
    }

    stopConnection() {
        this.__connection.stop()
        .then(() => {
            this.log.debug('Websocket connection stopped');
        })
        .catch(error => {
            this.log.error('There was an error while stopping websocket connection', error);
        })
        .finally(() => {
            this.connectionLive = false;
            // Stop the update interval
            clearInterval(this.__updateIntervalHandle);
            // Clear all workspace handles
            this.__forgeWorkspaceHandles = {};
            // Clear all elements from the store
            this.__store.commit(this.__getNamespacedProperty('clearElements'));
        });
    }

    // Primary method that is run on the update interval
    update() {
        if (this.__isUpdating) {
            return;
        }
        this.__isUpdating = true;
        try {
            this.__forgeRetryFailedSetVariableRequests();
            this.__forgeUpdateDirtyResources();
            this.markStaleElements();
        }
        catch (err) {
            this.log.error('Ran into error during update tick', err);
        }
        this.__isUpdating = false;
    }

    markStaleElements() {
        const elements = Object.values(this.__store.state[this.moduleName].elements);
        elements.forEach(element => {
            const elapsedMs = Date.now() - element.markedDirtyAt.getTime();

            // Only mark dirty old items that are not in error as stale
            if (element.dirty && elapsedMs > this.__staleTimeoutMs && element.state !== 'error') {
                this.log.debug('Marking element as stale:', element);
                this.__store.commit(this.__getNamespacedProperty('markStale'), element.uri);
            }
        });
    }

    handleResourceForgeEvent(event) {
        this.log.debug('Resource forge event:', event);
        if (event.hasErrors) {
            this.__store.commit(this.__getNamespacedProperty('setElementErrors'), { uri: event.uri, errors: event.errors });
        }
        this.__store.commit(this.__getNamespacedProperty('markDirty'), event.uri);
    }

    handleFlowForgeEvent(event) {
        this.log.debug('Flow forge event:', event);
    }

    handleUnknownForgeEvent(event) {
        this.log.warn('Unknown forge event passed:', event);
    }

    forgeEnterClient(workspaceUri) {
        const workspaceHandle = this.__forgeWorkspaceHandles[workspaceUri];

        // Don't attempt to enter workspace before connection is live
        if (this.connectionLive) {
            this.__connection.invoke('ForgeEnterClient', workspaceUri)
            .then(response => {
                if (response.responseType === 'error') {
                    throw new Error(`${response.data.errorType}: ${response.data.message}`);
                }
                else {
                    this.log.debug(`ForgeEnterClient complete for ${workspaceUri}`);
                    workspaceHandle.connected = true;
                    workspaceHandle.hasError = false;
                    workspaceHandle.elementHandles.forEach(elementHandle => {
                        elementHandle.isLoaded = false;
                        this.__markElementDirty(elementHandle.uri);
                    });
                }
            })
            .catch(error => {
                this.log.error(`ForgeEnterClient for ${workspaceUri} encountered and error:`, error);
                workspaceHandle.connected = false;
                workspaceHandle.hasError = true;
                workspaceHandle.error = true;
                workspaceHandle.elementHandles.forEach(elementHandle => {
                    this.__store.commit(this.__getNamespacedProperty('setElementErrors'), { uri: elementHandle.uri, errors: [error] });
                });
            });
        }
    }

    forgeExitClient(workspaceUri) {
        this.__connection.invoke('ForgeExitClient', workspaceUri)
        .then(response => {
            if (response.responseType === 'error') {
                throw new Error(`${response.data.errorType}: ${response.data.message}`);
            }
            else {
                this.log.debug(`ForgeExitClient complete for ${workspaceUri}`);
            }
        })
        .catch(error => {
            this.log.error(`ForgeExitClient for ${workspaceUri} encountered and error:`, error);
        });
    }

    __forgeCreateGetResourceOptions(workspace, resourceName, uri = null) {
        const getResourceOptions = {
            Name: resourceName,
            Workspace: workspace,
            Uri: uri,
        };
        if (resourceName.includes('!')) {
            // pull out the format
            const parts = resourceName.split('!');
            getResourceOptions.Name = parts[0];
            getResourceOptions.CollectionOptions = { Format: parts[1] };
        }
        return getResourceOptions;
    }

    // FORGE ACTIONS
    forgeAction(action, options) {
        return Vue.$api.forge.runAction({ action, options });
    }

    forgeGetResource(workspace, resource) {
        const options = this.__forgeCreateGetResourceOptions(workspace, resource);
        return this.forgeAction('GetResource', options);
    }

    forgeGetResourceList(resourceListOptions) {
        return this.forgeAction('GetResourceList', { resources: resourceListOptions });
    }

    forgeGetWorkspace(workspaceUri) {
        return this.forgeAction('GetWorkspace', { Name: workspaceUri });
    }

    forgeSetVariable(varUri, value, attempt = 1) {
        const info = this.forgeParseResource(varUri);

        return this.__setVariableLock.acquire(varUri)
        .then(numTries => {
            // if this is a retry, but another call has gone through before us,
            // then don't attempt again
            if (numTries > 1 && attempt > 1) {
                this.__setVariableLock.release(varUri);
                return;
            }
            this.__store.commit(this.__getNamespacedProperty('markSetVariable'), { uri: varUri, value, attempt });

            if (value.transactionId) {
                this.log.debug(`SetVariable called on ${varUri} with transactionId ${value.transactionId} and value:`, value);
            }
            else {
                this.log.debug(`SetVariable called on ${varUri} with value:`, value);
            }
            return this.forgeAction('SetVariable', {
                workspace: info.workspace,
                variable: info.el,
                value,
            })
            .then(response => {
                // Consider any error as a reason for marking errors and retrying the set variable
                if (response.hasErrors) {
                    if (value.transactionId) {
                        this.log.debug(`SetVariable response for ${varUri} with error/transactionId:`, response.errors, value.transactionId);
                    }
                    else {
                        this.log.debug(`SetVariable response for ${varUri} with error:`, response.errors);
                    }

                    this.__store.commit(this.__getNamespacedProperty('markSetVariableError'), { uri: varUri, errors: response.errors });
                }
                else {
                    if (value.transactionId) {
                        this.log.debug(`SetVariable response for ${varUri} successful for transactionId:`, value.transactionId);
                    }
                    else {
                        this.log.debug(`SetVariable response for ${varUri} successful`);
                    }

                    this.__store.commit(this.__getNamespacedProperty('markSetVariableSuccess'), { uri: varUri });
                }
                this.__setVariableLock.release(varUri);
                return response;
            })
            .catch(error => {
                this.log.error('SetVariable request failed:', error);
                this.__setVariableLock.release(varUri);
            });
        });
    }

    forgeSetMarking(workspaceUri, options) {
        const info = this.forgeParseResource(workspaceUri);
        return this.forgeAction('SetMarking', {
            workspace: info.workspace,
            Collection: info.el,
            ...options,
        });
    }

    forgeParseResource(resourceUri) {
        const values = resourceUri.split('#');
        const el = values[1];
        let parts = '';
        let format = '';
        let resource = '';
        if (el) {
            parts = el.split('!');
            resource = parts[0];
            if (parts.length > 1) {
                format = parts[1];
            }
        }

        return {
            url: resourceUri,
            workspace: values[0],
            el: resource,
            format,
            uri: `${values[0]}#${resource}`,
        };
    }

    __forgeDisposeWorkspaceHandle(workspaceHandle) {
        this.log.debug('Disposing of workspace handle:', workspaceHandle);
        // unsubscribe from the workspace
        this.forgeExitClient(workspaceHandle.workspace);
        // remove the handle reference
        delete this.__forgeWorkspaceHandles[workspaceHandle.workspace];
    }

    forgeSubscribeElements(subscriber, resources) {
        this.log.debug('Subscribing to resources', resources);
        this.log.debug('subscriber', subscriber);

        const handles = resources.map(resourceUrl => this.forgeSubscribeElement(subscriber, resourceUrl));
        const disposableReference = new ForgeDisposableReference({
            onDispose: () => {
                this.log.debug('Removing references', resources);
                handles.forEach(x => x.dispose());
            },
        });
        return disposableReference;
    }

    forgeSubscribeElement(subscriber, resource) {
        const info = this.forgeParseResource(resource);
        let workspaceHandle = null;
        if (this.__forgeWorkspaceHandles[info.workspace] === undefined) {
            workspaceHandle = new ForgeWorkspaceHandle({
                log: this.log,
                workspace: info.workspace,
                onElementCreated: (elementHandle) => {
                    this.log.debug('Making workspace handle', elementHandle);
                    this.__store.commit(this.__getNamespacedProperty('createElement'), { uri: elementHandle.uri });
                },
                onElementRemoved: (elementHandle) => {
                    this.__store.commit(this.__getNamespacedProperty('removeElement'), elementHandle.uri);
                },
                onCleanup: (emptyWorkspaceHandle) => {
                    this.__forgeDisposeWorkspaceHandle(emptyWorkspaceHandle);
                },
            });

            this.__forgeWorkspaceHandles[info.workspace] = workspaceHandle;
            this.forgeEnterClient(info.workspace);
        }
        else {
            workspaceHandle = this.__forgeWorkspaceHandles[info.workspace];
        }

        // TODO: Clean up this subscription paradigm
        // Returns a disposable obj for unsubscribing
        const elementReference = workspaceHandle.subscribe(subscriber, `${info.el}!${info.format}`);
        this.__store.commit(this.__getNamespacedProperty('addSubscriber'), { uri: resource, subscriber });

        return elementReference;
    }

    forgeReconnectWorkspaces() {
        this.log.debug('Reconnect clients for', Object.keys(this.__forgeWorkspaceHandles));
        Object.keys(this.__forgeWorkspaceHandles).forEach(workspace => {
            const workspaceHandle = this.__forgeWorkspaceHandles[workspace];
            workspaceHandle.connected = false;
            // TODO: set elements to false
            workspaceHandle.hasError = false;
        });
        this.forgeConnectWorkspaces();
    }

    forgeConnectWorkspaces() {
        this.log.debug('Connect clients for', Object.keys(this.__forgeWorkspaceHandles));
        Object.keys(this.__forgeWorkspaceHandles).forEach(workspaceUri => {
            const workspaceHandle = this.__forgeWorkspaceHandles[workspaceUri];
            if (!workspaceHandle.connected) {
                this.forgeEnterClient(workspaceUri);

                // TODO: check elements even if not connected
                workspaceHandle.elementHandles.forEach(elementHandle => {
                    elementHandle.isLoaded = false;
                    if (!elementHandle.isLoaded) {
                        this.__markElementDirty(elementHandle.uri);
                    }
                });
            }
        });
    }

    __markElementDirty(uri) {
        // Create the element if it DNE
        const element = this.__store.getters[this.__getNamespacedProperty('getElement')](uri);
        if (!element) {
            this.__store.commit(this.__getNamespacedProperty('createElement'), { uri });
        }

        this.__store.commit(this.__getNamespacedProperty('markDirty'), uri);
    }

    __forgeUpdateDirtyResources() {
        const dirtyResourceUris = this.dirtyElementsInfo.map(dirtyResource => dirtyResource.uri);

        // Array of API options for the GetResourceList call
        const resourceListOptions = [];

        // Build up the resourceListOptions
        dirtyResourceUris.forEach(dirtyResourceUri => {
            const UriInfo = this.forgeParseResource(dirtyResourceUri);
            const elementHandle = this.__forgeWorkspaceHandles[UriInfo.workspace].get(UriInfo.el);
            const element = this.getElement(dirtyResourceUri);
            const timeSinceLastRequest = new Date() - element.requestedAt;

            // Throttle the resource requests on a resource level by looking at the time since last request
            if (elementHandle && timeSinceLastRequest >= this.__resourceRequestThrottleMs) {
                // Attempt to get if we have not reached max
                if (element.requestAttempt <= this.__requestMaxAttempts) {
                    // If we have attempted before then increment otherwise start with 1st attempt
                    const attempt = element.requestAttempt ? element.requestAttempt + 1 : 1;

                    // Mark this resource as being requested
                    this.__store.commit(this.__getNamespacedProperty('markRequested'), {
                        uri: dirtyResourceUri,
                        attempt,
                    });

                    // Build the list of options for the api call to get resource
                    const resourceOptions = this.__forgeCreateGetResourceOptions(UriInfo.workspace, `${elementHandle.elementName}!${elementHandle.format}`, dirtyResourceUri);
                    resourceListOptions.push(resourceOptions);
                }

                // If we have requested too many times then mark resource as having error and stop trying
                else if (!element.maxRequestRetriesReached) {
                    // Log when we have exhausted attempts
                    this.log.error(`GetResource for ${element.uri} failed after ${this.__requestMaxAttempts} attempts.`);

                    // Let the user know they need to take action to recover
                    const info = this.forgeParseResource(element.uri);
                    Vue.$notify.error(`There was an error getting data for ${info.el}. Please refresh page.`);

                    // Mark the element as having reached the max retires so that it doesn't alert user again
                    this.__store.commit(this.__getNamespacedProperty('markRequestedMaxRetriesReached'), { uri: element.uri });
                }
            }
        });

        if (resourceListOptions.length) {
            this.forgeGetResourceList(resourceListOptions)
            .then(response => {
                if (response && response.data) {
                    const updatedResources = response.data;
                    // validate we got all the requests back
                    const updatedUris = updatedResources.map(updatedResource => updatedResource.uri);
                    updatedResources.forEach(updatedResource => {
                        // update handle
                        const UriInfo = this.forgeParseResource(updatedResource.uri);
                        // __forgeWorkspaceHandles is our dependency tracker and has meta info on the resource
                        const elementHandle = this.__forgeWorkspaceHandles[UriInfo.workspace].get(UriInfo.el);
                        elementHandle.isLoading = false;
                        // mark if resource has errors
                        if (updatedResource.hasError) {
                            this.__store.commit(this.__getNamespacedProperty('markRequestedError'), { uri: updatedResource.uri, errors: updatedResource.errors });
                        }
                        // save data if no errors
                        else {
                            this.__store.commit(this.__getNamespacedProperty('markRequestedSuccess'), { uri: updatedResource.uri, data: updatedResource.data });
                        }
                    });
                }
                else {
                    throw new Error('response contained no data');
                }
            })
            .catch(error => {
                this.log.error('GetResourceList request failed:', error);
            });
        }
    }

    __forgeRetryFailedSetVariableRequests() {
        const failedSetVariableElements = this.failedSetVariableInfo;
        if (failedSetVariableElements.length) {
            failedSetVariableElements.forEach(element => {
                // Make sure we don't cause a never ending flood of set variable requests by only attempting a set amount
                if (element.setVariableAttempt <= this.__setVariableMaxAttempts) {
                    // Run the failed set variable requests again incrementing the attempt number
                    this.log.warn(`Attempt ${element.setVariableAttempt} to call forgeSetVariable for ${element.uri}.`);
                    this.forgeSetVariable(element.uri, element.setVariableValue, element.setVariableAttempt + 1);
                }
                else if (!element.maxSetVariableRetriesReached) {
                    // Log when we have exhausted attempts
                    this.log.error(`SetVariable for ${element.uri} failed after ${this.__setVariableMaxAttempts} attempts.`);

                    // Let the user know they need to take action to recover
                    const info = this.forgeParseResource(element.uri);
                    Vue.$notify.error(`There was an error setting ${info.el}. Please refresh page.`);

                    // Mark the element as having reached the max retires so that it doesn't alert user again
                    this.__store.commit(this.__getNamespacedProperty('markSetVariableMaxRetriesReached'), { uri: element.uri });
                }
            });
        }
    }

    // TODO: Remove these functions in lieu of $tasks API.
    // NOTE: They are used throughout plugins to have to keep them around for now and proxy them to $api.tasks.
    // Submit a long running task to the Compute service.
    submitJob(taskParameters) {
        this.log.warn('$compute.submitJob is deprecated. Use $api.tasks.runTask.');
        // Override asJolt param
        const parameters = { ...taskParameters, runInBackground: true };

        this.log.debug('Running job with params:', parameters);
        return Vue.$api.tasks.runTask(parameters);
    }

    // Run a jolt immediately in the Compute service.
    runJolt(taskParameters) {
        this.log.warn('$compute.runJolt is deprecated. Use $api.tasks.runTask.');
        const parameters = { ...taskParameters, runInBackground: false };

        this.log.debug('Running jolt with params:', parameters);
        return Vue.$api.tasks.runTask(parameters);
    }
}
