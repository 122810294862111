export const PetronOverviewModel = function PetronOverviewModel({ app, plugin }) {
    return {
        fields: {
            petronId: {
                source: 'route',
            },
            pluginId: {
                source: 'self',
                options: {
                    default: plugin.$definition.name,
                },
            },
            viewId: {
                source: 'self',
                options: {
                    default: 'page',
                },
            },
            pageComments: {
                source: 'forge',
                options: {
                    workspace: 'commentsWorkspace',
                    resource: 'ThreadComments',
                },
            },
        },
        getters: {
            commentsWorkspace: state => `$root/Comments/plugin/${state.pluginId}/petron/${state.petronId}/view/${state.viewId}`,
            commentsResources: (state, getters) => [
                getters.pageCommentsResource,
            ],
        },
        actions: {},
    };
};
