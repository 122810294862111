<template>
    <GeneralButton
        v-cypress="'AppFiltersButton_Filter_Button'"
        icon="mdi-filter-outline"
        dense
        :tooltip="filterPanelOpen ? 'Hide filters' : 'Filters'"
        :outline="false"
        :text-color="filterPanelOpen ? 'accent' : ''"
        :disabled="!filtersAvailable"
        size="lg"
        @click="toggleFilterPanel"
    >
        <q-badge
            v-if="activeFiltersCount > 0"
            v-cypress="'AppFiltersButton_ActiveFilters_Badge'"
            color="accent"
            text-color="secondary"
            class="badge-notification-rounded"
            floating
        >
            {{ activeFiltersCount }}
        </q-badge>
    </GeneralButton>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import GeneralButton from '../plugin-framework/shared-components/inline-elements/GeneralButton.vue';
import mixins from '../mixins';

export default {
    name: 'AppFiltersButton',
    components: {
        GeneralButton,
    },
    mixins: [mixins.compute],
    computed: {
        ...mapState('app', {
            filterPanelOpen: state => state.filterPanelOpen,
        }),
        ...mapGetters('Petron', {
            forgeResources: 'forgeResources',
        }),
        filterFragments() {
            const pageFragments = this.$pai.getFragments('page-filter');
            if (!pageFragments) {
                return [];
            }
            return pageFragments.filter(fragment => {
                if (!fragment.props || !fragment.props.routes) {
                    return false;
                }
                return !fragment.props.hide && fragment.props.routes.includes(this.$route.name);
            });
        },
        filtersAvailable() {
            return this.filterFragments.length > 0;
        },
        activeFiltersCount() {
            let activeCount = 0;
            this.filterFragments.forEach(fragment => {
                if (fragment.props && fragment.props.indexData) {
                    fragment.props.indexData.forEach(indexDef => {
                        activeCount += indexDef.filters.filter(filterDef => filterDef.filterSet).length;
                    });
                }
            });
            return activeCount;
        },
    },
    methods: {
        ...mapMutations('app', [
            'toggleFilterPanel',
        ]),
    },
};
</script>

<style scoped>
.badge-notification-rounded {
    border-radius: 20%;
    height: 0.9rem;
    margin-top: 1rem;
}
</style>
