<template>
    <q-dialog
        v-model="display"
        persistent
    >
        <q-card>
            <q-card-section>
                <div class="text-h6">
                    {{ blockingNotification.title }}
                </div>
            </q-card-section>

            <q-card-section class="q-pb-none">
                <p v-if="blockingNotification && blockingNotification.details">
                    {{ blockingNotification.details }}
                </p>
                <p v-if="blockingNotification && blockingNotification.description">
                    {{ blockingNotification.description }}
                </p>
            </q-card-section>

            <q-card-actions align="right">
                <a
                    class="q-pa-md"
                    href="mailto:support@petro.ai"
                >Contact support</a>
                <GeneralButton
                    v-if="!blockingNotification.active"
                    :flat="false"
                    :outline="false"
                    tooltip="Dismiss"
                    label="Dismiss"
                    color="primary"
                    @click="display === false"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapState } from 'vuex';
import GeneralButton from '../../shared-components/inline-elements/GeneralButton.vue';

export default {
    name: 'PBlockingNotification',
    components: {
        GeneralButton,
    },
    computed: {
        ...mapState('notifications', {
            blockingNotification: 'blockingNotification',
        }),
        display: {
            get() {
                return this.$store.state.notifications.blockingNotification.display;
            },
            set(value) {
                this.$store.commit('notifications/setBlockingNotificationDisplay', value);
            },
        },
    },
};
</script>

<style scoped>

</style>
