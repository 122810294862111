<template>
    <div
        class="row q-col-gutter-md items-stretch q-pa-xs"
    >
        <div
            v-for="video in videos"
            :key="video.id"
            class="col-auto"
        >
            <q-card
                class="video-card-fixed pointer"
                @click="$emit('play-video', video)"
            >
                <q-badge
                    v-if="video.isNew"
                    floating
                    color="primary"
                    label="NEW"
                    class="badge-rounded"
                />
                <q-card-section
                    class="full-height column"
                >
                    <small class="text-grey-8 text-bold">
                        {{ video.category ? video.category.toUpperCase() : '' }}
                    </small>
                    <div
                        class="text-secondary text-h6 text-bold col-auto ellipsis-2-lines"
                    >
                        {{ video.title }}
                        <q-tooltip :delay="750">
                            {{ video.title }}
                        </q-tooltip>
                    </div>
                    <div class="text-grey-8 col q-pt-md overflow-hidden">
                        {{ video.description }}
                        <q-tooltip :delay="750">
                            {{ video.description }}
                        </q-tooltip>
                    </div>
                    <div class="col-auto row items-center q-pt-md">
                        <q-icon
                            name="schedule"
                            color="grey-6"
                            size="xs"
                        />
                        <span class="text-grey-6 q-px-sm">{{ video.length }}</span>
                        <q-space/>
                        <GeneralButton
                            tooltip="Play this video"
                            :flat="false"
                            :outline="false"
                            color="grey-5"
                            text-color="secondary"
                            size="md"
                            round
                            dense
                        >
                            <q-icon
                                name="play_arrow"
                                size="md"
                            />
                        </GeneralButton>
                    </div>
                </q-card-section>
            </q-card>
        </div>
    </div>
</template>

<script>
import GeneralButton from '../../../plugin-framework/shared-components/inline-elements/GeneralButton.vue';

export default {
    name: 'VideoCardGrid',
    components: {
        GeneralButton,
    },
    props: {
        videos: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style scoped>
.video-card-fixed {
    width: 250px;
    height: 300px;
}
.badge-rounded {
    border-radius: 20% !important;
}
</style>
