export function LibraryModel({ app, plugin }) {
    return {
        fields: {
            workspace: {
                source: 'self',
                options: {
                    default: '$root/Petrons',
                },
            },
            allPetrons: {
                source: 'forge',
                options: {
                    workspace: 'libraryWorkspace',
                    resource: 'AllPetrons',
                },
            },
            favoritePetrons: {
                source: 'forge',
                options: {
                    workspace: 'userWorkspace',
                    resource: 'FavoritePetrons',
                },
            },
            collaboratorPetrons: {
                source: 'forge',
                options: {
                    workspace: 'userWorkspace',
                    resource: 'CollaboratingPetrons',
                },
            },
            ownedPetrons: {
                source: 'forge',
                options: {
                    workspace: 'userWorkspace',
                    resource: 'OwnedPetrons',
                },
            },
            shortcuts: {
                source: 'forge',
                options: {
                    workspace: 'userWorkspace',
                    resource: 'UserShortcutsByPetron',
                },
            },
        },
        getters: {
            libraryWorkspace: state => '$root/Petrons',
            userWorkspace: (state, getters) => `${getters.libraryWorkspace}/${app.$auth.user.userId}`,
            forgeResources: (state, getters) => [
                getters.allPetronsResource,
                getters.favoritePetronsResource,
                getters.collaboratorPetronsResource,
                getters.shortcutsResource,
                getters.ownedPetronsResource,
            ],
            ownerCollaboratorPetrons: (state, getters) => {
                // Users should not be collaborators on petrons they own, but just in case...
                const collaboratorPetrons = getters.collaboratorPetrons.filter(collabPetron => !getters.ownedPetrons.find(owned => owned.id === collabPetron.id));
                return [
                    ...getters.ownedPetrons,
                    ...collaboratorPetrons,
                ];
            },
        },
        actions: {
            createPetron({ commit }, { name }) {
                // make the petron!
                app.$api.tasks.runTask({
                    runInBackground: false,
                    pid: 'null',
                    taskType: 'CreatePetron',
                    options: {
                        name,
                    },
                }).then(response => {
                    if (response.insertedIds.length > 0) {
                        const petronId = response.insertedIds[0];
                        app.$router.push({ name: 'petron-overview', params: { petronId } });
                    }
                });
            },
            removePetron(vuex, { petronToRemove, onSuccess }) {
                if (!petronToRemove) {
                    return;
                }
                app.$notify.confirm(`Are you sure you want to delete '${petronToRemove.name}'?`, {
                    title: 'Confirm Delete',
                    okButtonText: 'Delete',
                    okButtonTooltip: 'Confirm delete',
                    cancelButtonTooltip: 'Cancel delete',
                    onOk: (closeCallback) => {
                        closeCallback(true);
                        app.$api.data.delete({
                            type: 'Petron',
                            query: {
                                id: petronToRemove.id,
                            },
                            options: {},
                        }).then(response => {
                            if (response && response.success) {
                                app.$notify.success(`Petron '${petronToRemove.name}' successfully deleted.`);
                                if (onSuccess) {
                                    onSuccess();
                                }
                            }
                            else {
                                app.$notify.error(`Petron '${petronToRemove.name}' could not be deleted.`);
                            }
                        }).catch(error => {
                            app.$notify.error(`There was an error trying to delete Petron '${petronToRemove.name}'.`);
                        });
                    },
                });
            },
            addToFavorites(vuex, petronId) {
                app.$api.data.insert({
                    type: 'Link',
                    data: [{
                        parentId: app.$auth.user.userId,
                        parentType: 'User',
                        childId: petronId,
                        childType: 'Petron',
                        linkType: 'favorite',
                    }],
                }).then(response => {
                    app.$notify.success('Petron added to favorites');
                })
                .catch(error => {
                    app.$notify.error(`Unable to add petron to favorites: ${error.message}`);
                });
            },
            removeFromFavorites(vuex, petronId) {
                app.$api.data.delete({
                    type: 'Link',
                    query: {
                        linkType: 'favorite',
                        childType: 'Petron',
                        childId: petronId,
                        parentType: 'User',
                        parentId: app.$auth.user.userId,
                    },
                    options: {},
                })
                .then(response => {
                    app.$notify.success('Petron removed from favorites');
                })
                .catch(error => {
                    app.$notify.error(`Unable to remove petron from favorites: ${error.message}`);
                });
            },
            copyPetron({ commit }, options) {
                app.$api.tasks.runTask({
                    runInBackground: false,
                    taskType: 'CopyPetron',
                    options: {
                        destName: options.destinationPetronName,
                        sourcePetronId: options.source.id,
                    },
                }).then(response => {
                    if (response.success && response.insertedIds.length > 0) {
                        options.triggers.forEach(tr => {
                            tr(options.source.id, response.insertedIds[0]);
                        });
                    }
                });
            },
        },
    };
}
