export function VideoExplorerGlobalAppCard({ app, plugin }) {
    return {
        type: 'fragment',
        target: 'global-app-card',
        priority: 1,
        options() {
            return {
                props: {
                    title: 'Video Explorer',
                    description: 'Get quick tips, walkthroughs, and deep dives into the tools, applications, and workflows in Petro.ai.',
                    launchRoute: { name: 'video-explorer' },
                },
            };
        },
    };
}
