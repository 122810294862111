<template>
    <PDialog
        v-model="display"
        :title="`Copy '${petronName}'`"
        @cancel="clearForm"
    >
        <div
            class="text-center q-pa-lg"
        >
            <q-input
                v-model="destinationPetronName"
                v-cypress="'CopyPetronDialog_DestinationName_Input'"
                bottom-slots
                label="Type a name for your new Petron."
                hint="Petron Name"
                dense
            />
            <br>
            <div v-if="copyTaskFragments.length">
                <p>Associated Data to Copy</p>
                <q-list
                    dense
                >
                    <q-item
                        v-for="task in copyTaskFragments"
                        :key="task.props.title"
                    >
                        <q-item-section>
                            <q-checkbox
                                :value="task.selected"
                                :label="task.props.title"
                                :hint="task.props.description"
                                @input="task.toggleSelect()"
                            />
                        </q-item-section>
                    </q-item>
                </q-list>
            </div>
        </div>
        <template v-slot:footer>
            <GeneralButton
                v-cypress="'CopyPetronDialog_Copy_Btn'"
                v-close-popup
                color="secondary"
                :flat="false"
                :outline="false"
                :disabled="!destinationPetronName || !destinationPetronName.trim().length"
                label="Copy New Petron"
                tooltip="Create the new Petron"
                icon-right="arrow_right_alt"
                @click="copy"
            />
        </template>
    </PDialog>
</template>

<script>
import GeneralButton from '../../../plugin-framework/shared-components/inline-elements/GeneralButton.vue';

export default {
    name: 'CopyPetronDialog',
    components: {
        GeneralButton,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        /**
         * Source petron
         */
        petron: {
            required: true,
            validator: v => (typeof v === 'object' && !Array.isArray(v)) || v == null,
        },
    },
    data() {
        return {
            destinationPetronName: '',
        };
    },
    computed: {
        display: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        petronName() {
            if (this.petron) return this.petron.name;
            return 'Petron';
        },
        copyTaskFragments() {
            const tasks = this.$pai.getFragments('copy-petron-dialog');
            if (!tasks) return [];
            return tasks;
        },
    },
    created() {
        this.reset();
    },
    methods: {
        reset() {
            this.destinationPetronName = '';
            this.copyTaskFragments.forEach(t => {
                t.setSelected(false);
            });
        },
        copy() {
            if (!this.destinationPetronName || !this.destinationPetronName.trim().length) {
                return;
            }
            this.$emit('copy', {
                triggers: Object.values(this.copyTaskFragments).filter(x => x.selected).map(t => t.actions.trigger),
                destinationPetronName: this.destinationPetronName.trim(),
                source: this.petron,
            });
        },
        clearForm() {
            this.display = false;
            this.reset();
        },
    },
};
</script>

<style scoped>

</style>
