<template>
    <q-field
        v-cypress="testId"
        :error="!!errors.length"
        borderless
        class="select-wrapper q-pb-sm"
    >
        <q-select
            ref="selectComponent"
            v-model="compValue"
            :filled="filled"
            :options="visibleOptions"
            :label="label"
            :option-value="optionValue"
            :option-label="optionLabel"
            :option-disable="optionDisable"
            options-dense
            :dense="dense"
            :hint="!errors.length ? hint : null"
            :outlined="false"
            :use-input="useInput"
            :input-debounce="debounce"
            :hide-selected="!multiselect"
            :fill-input="!multiselect"
            :disable="disable"
            :multiple="multiselect"
            :use-chips="multiselect"
            :clearable="clearable"
            :emit-value="emitValue"
            :map-options="mapOptions"
            :dark="dark"
            :readonly="readonly"
            class="full-width q-pb-sm"
            @input="clearMatch"
            @filter="filterFn"
            @new-value="newValueFn"
        >
            <template
                v-if="optionCategories"
                v-slot:option="scope"
            >
                <q-expansion-item
                    expand-separator
                    :group="getItemLabel(scope.opt)"
                    default-opened
                    :label="getItemLabel(scope.opt)"
                    :dense="dense"
                >
                    <template v-for="child in scope.opt.children">
                        <q-item
                            :key="getItemLabel(child)"
                            v-ripple
                            v-close-popup
                            clickable
                            :dense="dense"
                            @click="setValue(getItemValue(child))"
                        >
                            <q-item-section>
                                <q-item-label
                                    :class="itemClass(child)"
                                    v-html="getItemLabel(child)"
                                />
                            </q-item-section>
                        </q-item>
                    </template>
                </q-expansion-item>
            </template>
        </q-select>
        <template v-slot:error>
            <AutoFormValidationErrors
                :errors="innerErrors"
                class="q-pl-md"
            />
        </template>
    </q-field>
</template>
<script>

import AutoFormValidationErrors from './AutoFormValidationErrors.vue';

export default {
    name: 'AutoFormSelect',
    components: {
        AutoFormValidationErrors,
    },
    props: {
        value: {
            required: true,
            validator: v => Array.isArray(v) || typeof v === 'object' || v === null || typeof v === 'string',
        },
        testId: {
            type: String,
            required: false,
            default: null,
        },
        options: {
            type: Array,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        optionValue: {
            required: false,
            default: 'value',
            validator(value) {
                return typeof value === 'string' || typeof value === 'function';
            },
        },
        optionLabel: {
            required: false,
            default: 'label',
            validator(value) {
                return typeof value === 'string' || typeof value === 'function';
            },
        },
        optionCategories: {
            type: Boolean,
            required: false,
            default: false,
        },
        optionDisable: {
            required: false,
            default: 'disable',
            validator(value) {
                return typeof value === 'string' || typeof value === 'function';
            },
        },
        debounce: {
            type: Number,
            required: false,
            default: 0,
        },
        clearable: {
            type: Boolean,
            required: false,
            default: true,
        },
        dense: {
            type: Boolean,
            required: false,
            default: true,
        },
        multiselect: {
            type: Boolean,
            required: false,
            default: false,
        },
        hint: {
            type: String,
            required: false,
            default: '',
        },
        filled: {
            type: Boolean,
            required: false,
            default: false,
        },
        disable: {
            type: Boolean,
            required: false,
            default: false,
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false,
        },
        emitValue: {
            type: Boolean,
            required: false,
            default: false,
        },
        mapOptions: {
            type: Boolean,
            required: false,
            default: false,
        },
        useInput: {
            type: Boolean,
            required: false,
            default: true,
        },
        newValue: {
            type: Function,
            required: false,
            default: () => null,
        },
        errors: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },
        dark: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            visibleOptions: [],
        };
    },
    computed: {
        compValue: {
            set(value) {
                this.setValue(value);
            },
            get() {
                return this.value;
            },
        },
        innerErrors: {
            get() {
                return this.errors;
            },
            set(value) {
                this.$emit('update:errors', value);
            },
        },
    },
    watch: {
        options() {
            this.visibleOptions = this.options;
        },
    },
    created() {
        this.visibleOptions = this.options;
    },
    methods: {
        filterFn(val, update, abort) {
            update(() => {
                const needle = val.toLowerCase();
                if (this.optionCategories) {
                    this.visibleOptions = this.options.map(option => {
                        const filteredOption = Object.assign({}, option);
                        if (option.children) {
                            if (typeof this.optionLabel === 'function') {
                                filteredOption.children = option.children.filter(v => this.optionLabel(v).toLowerCase().indexOf(needle) > -1) || this.visibleOptions;
                            }
                            else {
                                filteredOption.children = option.children.filter(v => v[this.optionLabel].toLowerCase().indexOf(needle) > -1) || this.visibleOptions;
                            }
                        }
                        return filteredOption;
                    });
                    return;
                }
                if (typeof this.optionLabel === 'function') {
                    this.visibleOptions = this.options.filter(v => this.optionLabel(v).toLowerCase().indexOf(needle) > -1) || this.visibleOptions;
                }
                else {
                    this.visibleOptions = this.options.filter(v => v[this.optionLabel].toLowerCase().indexOf(needle) > -1) || this.visibleOptions;
                }
            });
        },
        newValueFn(newValue, done) {
            if (!this.newValue) {
                done();
                return;
            }
            this.newValue({
                value: newValue,
                doneCallback: done,
            });
        },
        setValue(value) {
            if (value === null && this.multiselect) {
                this.$emit('input', []);
            }
            else {
                this.$emit('input', value);
            }
        },
        getItemLabel(childItem) {
            if (typeof this.optionLabel === 'function') {
                return this.optionLabel(childItem);
            }
            return childItem[this.optionLabel];
        },
        getItemValue(childItem) {
            if (typeof this.optionValue === 'function') {
                return this.optionValue(childItem);
            }
            return childItem[this.optionValue];
        },
        isChildSelected(childItem) {
            let isSelected = false;
            const itemValue = this.getItemValue(childItem);
            if (this.multiselect) {
                isSelected = this.compValue && this.compValue.includes[itemValue];
            }
            else {
                isSelected = this.compValue === itemValue;
            }
            return isSelected;
        },
        itemClass(item) {
            return `q-ml-md${this.isChildSelected(item) ? ' text-primary' : ''}`;
        },
        clearMatch(event) {
            if (!this.useInput || !this.multiselect) {
                return;
            }
            // This works around a bug (#4716) when multiple and use-input are both true.
            // After a value that matches the search text is chosen, the text lingers inside the input.
            // Clear the text after a successful match.
            // FWIW: the Quasar devs consider this a "feature": https://github.com/quasarframework/quasar/issues/4600
            if (this.$refs.selectComponent) {
                this.$refs.selectComponent.updateInputValue('');
            }
        },
    },
};
</script>

<style lang="stylus">
.select-wrapper
    .q-field__bottom
        padding-top: 0 !important
        font-size: 11px
</style>
