import NotificationsService from './notifications-service.js';
import PBlockingNotification from './PBlockingNotification.vue';

const Notifications = {
    install(Vue, {
        store = null,
    } = {}) {
        if (!store) {
            throw new Error('Notifications plugin must be passed a vuex store.');
        }

        // Register the service
        const notificationsService = new NotificationsService({ store });
        Vue.$notify = notificationsService;
        Vue.prototype.$notify = notificationsService;

        // Register plugin components
        Vue.component('PBlockingNotification', PBlockingNotification);
    },
};
export default Notifications;
