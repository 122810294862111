export const PetronModel = function PetronModel(app) {
    return {
        fields: {
            petronId: {
                source: 'route',
            },
            petron: {
                source: 'forge',
                options: {
                    workspace: 'petronWorkspace',
                    resource: 'Petron',
                    single: true,
                },
            },
            petronUnits: {
                source: 'forge',
                options: {
                    workspace: 'petronWorkspace',
                    resource: 'PetronUnits',
                    single: true,
                },
            },
            usersInPetron: {
                source: 'forge',
                options: {
                    workspace: 'petronWorkspace',
                    resource: '__currentUsers__',
                },
            },
            petronCollaborators: {
                source: 'forge',
                options: {
                    workspace: 'petronWorkspace',
                    resource: 'CollaboratorUserIds',
                    single: true,
                },
            },
        },
        getters: {
            petronWorkspace: state => `$root/Petron/${state.petronId}`,
            forgeResources: (state, getters) => {
                if (!state.petronId) {
                    return [];
                }
                return [
                    getters.petronResource,
                    getters.petronUnitsResource,
                    getters.usersInPetronResource,
                    getters.petronCollaboratorsResource,
                ];
            },
        },
    };
};
