import { JobsModel } from './model/jobs-model.js';
import { DeleteJobsDialog } from './fragments/delete-jobs-dialog.js';
import { DeleteJobResultsDialog } from './fragments/delete-job-results-dialog.js';
import { JobResultLogDialogFragment } from './fragments/job-result-log-dialog.js';
import { JobsRoute } from './fragments/jobs-route.js';

export const JobsPlugin = {
    name: 'Jobs',
    views: {
    },
    definition({ app, plugin }) {
        return {
            modules: [
                DeleteJobsDialog({ app, plugin }),
                DeleteJobResultsDialog({ app, plugin }),
                JobResultLogDialogFragment({ app, plugin }),
                JobsRoute({ app, plugin }),
            ],
            model: JobsModel({ app, plugin }),
        };
    },
};
