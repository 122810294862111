<template>
    <PDialog
        v-model="display"
        title="Create New Shortcut"
        ok-btn-text="Create"
        ok-btn-tooltip="Create the shortcut"
        ok-btn-test-id="NewShortcutDialog_OK_Button"
        cancel-btn-tooltip="Do not create the shortcut"
        @ok="create"
        @cancel="display = false"
    >
        <div
            class="form-wrap"
        >
            <q-input
                v-model="name"
                v-cypress="'NewShortcutDialog_Name_Input'"
                bottom-slots
                label="Name"
                :dense="dense"
            >
                <template v-slot:hint>
                    Name for the new Shortcut.
                </template>
            </q-input>
        </div>
    </PDialog>
</template>

<script>

export default {
    name: 'NewShortcutDialog',
    props: {
        defaultName: {
            type: String,
            required: false,
            default: undefined,
        },
        dense: {
            type: Boolean,
            required: false,
            default: true,
        },
        value: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            name: '',
        };
    },
    computed: {
        display: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    },
    watch: {
        value(newVal) {
            if (newVal && this.defaultName) {
                this.name = this.defaultName;
            }
        },
    },
    created() {
        if (this.defaultName) {
            this.name = this.defaultName;
        }
    },
    methods: {
        create() {
            if (this.name === '') {
                return;
            }
            this.$emit('createShortcut', { name: this.name });
        },
    },
};
</script>

<style scoped>
.form-wrap {
    min-height: 7.5rem;
}
</style>
