import ApplicationLayout from '../../layouts/ApplicationLayout.vue';
import DefaultPageLayout from '../../layouts/DefaultPageLayout.vue';
import TheVideoExplorerPage from './components/TheVideoExplorerPage.vue';
import { VideoExplorerGlobalAppCard } from './fragments/video-explorer-global-app-card.js';
import { VideoExplorerRoute } from './fragments/video-explorer-route.js';

export const VideoExplorer = {
    name: 'VideoExplorer',
    views: {
        ApplicationLayout,
        DefaultPageLayout,
        TheVideoExplorerPage,
    },
    definition({ app, plugin }) {
        return {
            modules: [
                VideoExplorerGlobalAppCard({ app, plugin }),
                VideoExplorerRoute({ app, plugin }),
            ],
            model: {
                fields: {},
                getters: {},
                actions: {},
            },
        };
    },
};
