<template>
    <div>
        Smart Table goes here!
        {{ data.length }}
        <div v-if="data.length > 0">
            {{ data[0] }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'SmartTable',
    props: {
        data: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style scoped>

</style>
