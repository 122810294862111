// Modify the library list item class to fix tab focus issue
// See https://github.com/scrumpy/tiptap/issues/457
import { Node } from 'tiptap';
import {
    splitListItem, liftListItem, sinkListItem, chainCommands, insertText,
} from 'tiptap-commands';

export default class ListItem extends Node {
    get name() {
        return 'list_item';
    }

    get schema() {
        return {
            content: 'paragraph block*',
            defining: true,
            draggable: false,
            parseDOM: [
                { tag: 'li' },
            ],
            toDOM: () => ['li', 0],
        };
    }

    keys({ type }) {
        return {
            Enter: splitListItem(type),
            Tab: chainCommands(sinkListItem(type), insertText('    ')),
            'Shift-Tab': liftListItem(type),
        };
    }
}
