<template>
    <q-page padding>
        <div class="row">
            <q-input
                v-model="searchText"
                v-cypress="'TheVideoExplorerPage_Search_Input'"
                borderless
                dense
                filled
                clearable
                debounce="300"
                placeholder="Search videos"
                class="q-mr-md col-3"
            >
                <template v-slot:prepend>
                    <q-icon name="search"/>
                </template>
            </q-input>
            <div class="q-gutter-x-md no-wrap">
                <q-checkbox
                    v-model="allCategoriesSelected"
                    label="All"
                    color="grey-8"
                />
                <q-checkbox
                    v-for="(categoryName, idx) in availableCategories"
                    :key="`categoryButton-${idx}`"
                    :value="categoryFilterOn(categoryName)"
                    :label="categoryName"
                    color="grey-8"
                    @input="toggleCategoryFilter(categoryName)"
                />
            </div>
        </div>
        <VideoCardGrid
            v-if="filteredVideos.length"
            :videos="filteredVideos"
            class="q-pa-lg"
            @play-video="playVideo"
        />
        <div
            v-else
            class="q-pa-lg"
        >
            No videos were found.
            <span v-if="searchTerm"> Try a different a search term.</span>
            <span v-if="!allCategoriesSelected"> Try selecting more categories.</span>
        </div>
        <VideoPlayerDialog
            v-model="showVideoPlayer"
            :video="playingVideo"
        />
        <q-inner-loading :showing="showLoading">
            <q-spinner
                size="30px"
                color="primary"
            />
            <p>Retrieving videos...</p>
        </q-inner-loading>
    </q-page>
</template>

<script>
import VideoCardGrid from './VideoCardGrid.vue';
import VideoPlayerDialog from './VideoPlayerDialog.vue';

const DAYS_TO_REMAIN_NEW = 60;

export default {
    name: 'TheVideoExplorerPage',
    components: {
        VideoPlayerDialog,
        VideoCardGrid,
    },
    data() {
        return {
            loadedCategories: [],
            loadedVideos: [],
            searchText: '',
            selectedCategories: [],
            showVideoPlayer: false,
            playingVideo: {},
            videosLoaded: false,
            categoriesLoaded: false,
        };
    },
    computed: {
        mappedVideos() {
            const daysAgoNew = (new Date()).setDate(((new Date()).getDate() - DAYS_TO_REMAIN_NEW));
            const allVideos = this.loadedVideos.map(video => {
                return {
                    ...video,
                    isNew: (video.publishedAt > daysAgoNew),
                    category: video.category || 'General',
                };
            });
            allVideos.sort((a, b) => {
                if (!a.appOrder) {
                    return 100;
                }
                if (!b.appOrder) {
                    return -100;
                }
                return a.appOrder - b.appOrder;
            });
            return [
                ...allVideos.filter(video => video.isNew),
                ...allVideos.filter(video => !video.isNew),
            ];
        },
        filteredVideos() {
            return this.mappedVideos.filter(video => this.videoMatchesSearch(video) && this.selectedCategories.includes(video.category));
        },
        availableCategories() {
            const topCategories = this.loadedCategories.map(category => {
                return {
                    ...category,
                    priority: category.priority || 0,
                };
            });
            topCategories.sort((a, b) => a.priority - b.priority);
            const allCategories = topCategories.map(category => category.title);
            if (!allCategories.find(category => category === 'General')) {
                allCategories.push('General');
            }
            // Hide empty categories.
            const availableCategories = allCategories.filter(categoryName => this.loadedVideos.some(video => video.category === categoryName));
            return availableCategories;
        },
        allCategoriesSelected: {
            get() {
                return this.availableCategories.every(category => this.selectedCategories.includes(category));
            },
            set(val) {
                this.selectedCategories = val ? [...this.availableCategories] : [];
            },
        },
        searchTerm() {
            if (this.searchText && this.searchText.trim().length) {
                return this.searchText.trim().toLowerCase();
            }
            return null;
        },
        showLoading() {
            return !this.videosLoaded || !this.categoriesLoaded;
        },
    },
    mounted() {
        this.loadCategories();
        this.loadVideos();
    },
    methods: {
        playVideo(video) {
            if (video) {
                this.playingVideo = video;
                this.showVideoPlayer = true;
            }
        },
        videoMatchesSearch(video) {
            if (!this.searchTerm) {
                // There is no search filter, so all videos match.
                return true;
            }
            if (video.title.toLowerCase().includes(this.searchTerm)) {
                return true;
            }
            if (video.description && video.description.toLowerCase().includes(this.searchTerm)) {
                return true;
            }
            return (video.tags && video.tags.find(tag => tag.toLowerCase().includes(this.searchTerm)));
        },
        categoryFilterOn(categoryName) {
            return !this.allCategoriesSelected && this.selectedCategories.includes(categoryName);
        },
        toggleCategoryFilter(categoryName) {
            if (this.allCategoriesSelected) {
                this.selectedCategories = [categoryName];
                return;
            }
            const idx = this.selectedCategories.findIndex(selectedCategory => selectedCategory === categoryName);
            if (idx > -1) {
                this.selectedCategories.splice(idx, 1);
                return;
            }
            this.selectedCategories.push(categoryName);
        },
        loadCategories() {
            this.categoriesLoaded = false;
            this.$cms.getExplorerCategories()
            .then((response) => {
                this.loadedCategories = response;
                this.allCategoriesSelected = true;
                this.categoriesLoaded = true;
            })
            .catch((issue) => {
                this.categoriesLoaded = true;
                this.$logging.loggers.PluginFramework.error(`Error loading Explorer video categories: ${issue}`);
            });
        },
        loadVideos() {
            this.videosLoaded = false;
            this.$cms.getExplorerVideos()
            .then((response) => {
                this.loadedVideos = response.map(video => {
                    return {
                        ...video,
                        publishedAt: new Date(video.publishedAt),
                    };
                });
                this.allCategoriesSelected = true;
                this.videosLoaded = true;
            })
            .catch((issue) => {
                this.videosLoaded = true;
                this.$logging.loggers.PluginFramework.error(`Error loading Explorer videos: ${issue}`);
            });
        },
    },
};
</script>

<style scoped>
</style>
