import ApplicationLayout from '../../../layouts/ApplicationLayout.vue';
import DefaultPageLayout from '../../../layouts/DefaultPageLayout.vue';
import TheServicesPage from '../components/TheServicesPage.vue';
import { ROUTES } from '../constants.js';

export function PetroPlusRoute({ app, plugin }) {
    return {
        type: 'fragment',
        target: 'root-route',
        options() {
            return {
                path: '/petroai-plus',
                meta: {
                    auth: true,
                    pluginId: plugin.$definition.name,
                },
                component: ApplicationLayout,
                children: [
                    {
                        path: '/petroai-plus',
                        components: {
                            appMain: DefaultPageLayout,
                        },
                        children: [
                            {
                                path: '',
                                name: ROUTES.PetroPlus,
                                meta: {
                                    pluginId: plugin.$definition.name,
                                },
                                components: {
                                    pageContent: TheServicesPage,
                                },
                                props: {
                                    pageContent: (route) => {
                                        return {};
                                    },
                                },
                            },
                        ],
                    },
                ],
            };
        },
    };
}
