import ApplicationLayout from '../../../layouts/ApplicationLayout.vue';
import DefaultPageLayout from '../../../layouts/DefaultPageLayout.vue';
import ForgeTableTemplate from '../../../plugin-framework/templates/ForgeTableTemplate';

export function UnitsRoute({ app, plugin }) {
    return {
        type: 'fragment',
        target: 'root-route',
        options() {
            return {
                path: '/settings/units',
                meta: {
                    auth: true,
                    pluginId: plugin.$definition.name,
                },
                component: ApplicationLayout,
                children: [
                    {
                        path: '',
                        components: {
                            appMain: DefaultPageLayout,
                        },
                        children: [
                            {
                                path: '',
                                name: 'units',
                                components: {
                                    pageContent: ForgeTableTemplate,
                                },
                                props: {
                                    pageContent: (route) => {
                                        return {
                                            props: {
                                                columnOptions: this.columnOptions,
                                                forgeResources: this.tableForgeResources,
                                                headerLeftInlineElements: [
                                                    {
                                                        props: {
                                                            type: 'text',
                                                            value: 'Units Definitions',
                                                        },
                                                    },
                                                ],
                                                headerRightInlineElements: [
                                                    {
                                                        props: {
                                                            type: 'button',
                                                            label: 'Create Definition',
                                                            tooltip: 'Add a new units definition',
                                                            color: 'primary',
                                                            flat: false,
                                                            outline: false,
                                                            testId: 'UnitsTable_CreateDefinition_Button',
                                                        },
                                                        events: {
                                                            click: () => {
                                                                this.addNewDefinition();
                                                            },
                                                        },
                                                    },
                                                ],
                                                parentId: 'Units',
                                                tableOptions: this.tableOptions,
                                                tableType: this.tableType,
                                                tableView: this.tableView,
                                                markingWorkspace: this.unitWorkspace,
                                                selectionType: 'single',
                                                topRowButtons: [
                                                    {
                                                        props: {
                                                            label: 'Edit Definition',
                                                            tooltip: 'Edit the selected definition',
                                                            testId: 'UnitsTable_EditDefinition_Button',
                                                            color: 'secondary',
                                                            flat: true,
                                                        },
                                                        events: {
                                                            click: ({ selectedIds }) => {
                                                                this.editSelectedDefinition(selectedIds);
                                                            },
                                                        },
                                                    },
                                                    {
                                                        props: {
                                                            label: 'Delete Definition',
                                                            tooltip: 'Delete the selected definition',
                                                            testId: 'UnitsTable_DeleteDefinition_Button',
                                                            color: 'secondary',
                                                            flat: true,
                                                        },
                                                        events: {
                                                            click: ({ selectedIds }) => {
                                                                this.deleteSelectedDefinition(selectedIds);
                                                            },
                                                        },
                                                    },
                                                ],
                                            },
                                            events: {
                                                tableOptions: (newOptions) => {
                                                    this.tableOptions = newOptions;
                                                },
                                                selection: selectedIds => {
                                                    this.selectedDefinition = selectedIds.length ? selectedIds[0] : null;
                                                },
                                            },
                                        };
                                    },
                                },
                            },
                        ],
                    },
                ],
            };
        },
    };
}
