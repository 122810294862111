<template>
    <v-chart
        ref="theChart"
        :options="chartOptions"
        class="full-width"
        autoresize
        @click="onChartClick"
        @contextmenu="onChartContextMenu"
        @brushselected="onBrushChanged"
    />
</template>

<script>
import debounce from 'lodash.debounce';
import ECharts from 'vue-echarts/components/ECharts.vue';
import moment from 'moment-timezone';
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/scatter';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/dataZoom';
import 'echarts/lib/component/markArea';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/brush';
import 'echarts/lib/component/toolbox';
import 'echarts/lib/component/legend';

export default {
    name: 'DataVisualization',
    components: {
        'v-chart': ECharts,
    },
    props: {
        axes: {
            type: Object,
            required: true,
        },
        chartCommentPartName: {
            type: Function,
            required: true,
        },
        chart: {
            type: Object,
            required: false,
            default: () => {},
        },
        tooltip: {
            type: Object,
            required: false,
            default: () => {},
        },
        dataZoom: {
            type: Array,
            required: false,
            default: () => [],
        },
        dataSources: {
            type: Object,
            required: false,
            default: () => {},
        },
        layers: {
            type: Array,
            required: false,
            default: () => [],
        },
        tracks: {
            type: Array,
            required: false,
            default: () => [],
        },
        chartClick: {
            type: Function,
            required: false,
            default: null,
        },
        chartContextMenu: {
            type: Function,
            required: false,
            default: null,
        },
        canComment: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data() {
        return {
            // Chart height and width set on mounted lifecycle hook
            chartWidth: null,
            chartHeight: null,
            // Track spacing is in pixel values to ensure consistent space despite resizing
            defaultTrackSpacing: 10,
            // Position of data zoom component from edge of chart
            defaultDataZoomPosition: 10,
            defaultOptions: {
                'line.width': 1.0,
                'line.color': 'purple',
                'line.showSymbols': false,
                'line.style': 'solid',
                'marker.color': 'purple',
                'marker.size': 5,
                'marker.opacity': 1.0,
                'axis.min': 'dataMin',
                'axis.max': 'dataMax',
                'pie.radius': '30%',
            },
            xAxisDefaults: {
                type: 'value',
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#CCC',
                    },
                },
                axisLabel: {
                    color: '#444',
                },
                axisTick: {
                    alignWithLabel: true,
                },
                axisPointer: {
                    show: true,
                },
            },
            yAxisDefaults: {
                type: 'value',
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#CCC',
                    },
                },
                axisLabel: {
                    color: '#444',
                },
                axisTick: {
                    alignWithLabel: true,
                },
                axisPointer: {
                    show: true,
                },
            },
            defaultSlider: {
                type: 'slider',
                borderColor: 'transparent',
                backgroundColor: '#f5f5f5',
                handleIcon: 'm 136.21318,23.179873 h 1.75995 c 0.2552,0 0.46066,0.205453 0.46066,0.460658 v 1.759951 c 0,0.255205 -0.20546,0.460659 -0.46066,0.460659 h -1.75995 c -0.25521,0 -0.46066,-0.205454 -0.46066,-0.460659 v -1.759951 c 0,-0.255205 0.20545,-0.460658 0.46066,-0.460658 z',
                handleSize: 16,
                showDataShadow: false,
                handleStyle: {
                    color: '#fff',
                    borderColor: '#ccc',
                    borderWidth: 1,
                    borderType: 'solid',
                },
                fillerColor: '#6e1e5533',
                throttle: 10,
            },
            defaultSummarySlider: {
                dataBackground: {
                    lineStyle: {
                        color: 'rgba(0, 0, 0, 0.25)',
                        opacity: 1,
                        width: 1,
                    },
                    areaStyle: {
                        opacity: 0,
                    },
                },
                fillerColor: 'rgba(0, 0, 0, 0.05)',
                handleStyle: {
                    color: 'rgba(0, 0, 0, 0.25)',
                },
                showDetail: false,
                handleSize: 30,
            },
        };
    },
    computed: {
        // Chart positional helpers
        chartPadding() {
            // Padding is in pixel values to ensure consistent space around charts despite resizing
            return {
                top: 20,
                right: 60,
                bottom: 70,
                left: 100,
                ...this.chart.padding,
            };
        },
        chartPaddingPct() {
            return {
                top: (this.chartPadding.top / this.chartHeight) * 100,
                right: (this.chartPadding.right / this.chartWidth) * 100,
                bottom: (this.chartPadding.bottom / this.chartHeight) * 100,
                left: (this.chartPadding.left / this.chartWidth) * 100,
            };
        },
        trackSpacingPct() {
            const trackSpacing = this.chart.trackSpacing !== undefined ? this.chart.trackSpacing : this.defaultTrackSpacing;
            return (trackSpacing / this.chartHeight) * 100;
        },
        // Echarts Options configuration
        chartOptions() {
            const chartOptions = {
                animation: true,
                axisPointer: this.axisPointerComponent,
                dataset: this.datasetComponent,
                dataZoom: this.dataZoomComponent,
                grid: this.gridComponent,
                series: this.seriesComponent,
                textStyle: {
                    fontFamily: 'Proxima Nova Regular,Roboto,-apple-system,Helvetica Neue,Helvetica,Arial,sans-serif',
                },
                // TODO: turn the toolbox on later for marking and selection
                toolbox: null,
                tooltip: this.tooltipComponent,
                xAxis: this.xAxisComponent,
                yAxis: this.yAxisComponent,
            };

            return chartOptions;
        },
        // TODO: This should be happening in the forge
        dimensionTransforms() {
            const dimensionTransforms = {};
            if (this.chart.transform) {
                Object.entries(this.chart.transform)
                .forEach(([dimension, transform]) => {
                    if (typeof transform === 'string') {
                        if (transform === 'non-zero') {
                            dimensionTransforms[dimension] = function nonZero(dimensionValue) {
                                return (dimensionValue > 0) ? dimensionValue : null;
                            };
                        }
                    }
                    else {
                        dimensionTransforms[dimension] = transform;
                    }
                });
            }
            return dimensionTransforms;
        },
        // Computed props ending with Component are their Echarts equivalent options component
        axisPointerComponent() {
            if (this.tracks && this.tracks.length) {
                return {
                    show: false,
                    label: {
                        show: false,
                    },
                    link: {
                        xAxisIndex: 'all',
                        yAxisIndex: 'all',
                    },
                };
            }
            return null;
        },
        gridComponent() {
            if (this.tracks && this.tracks.length && this.chartHeight) {
                // Ensure that each track gets a default scale of 1 if not provided
                const tracks = this.tracks.map(track => {
                    if (!track.scale) {
                        track.scale = 1;
                    }
                    return track;
                });

                // Get the sum of user provided scales to determine the total scale
                const totalScale = tracks.reduce((total, track) => total + track.scale, 0);

                // Determines the gap between tracks
                const trackSpacingPct = this.trackSpacingPct;
                // Track space is the number of gaps between tracks or total tracks - 1
                const totalTrackSpacePct = (this.tracks.length - 1) * trackSpacingPct;

                // TODO: DRY this chart orientation logic
                if (this.chart.orientation && this.chart.orientation === 'vertical') {
                    // Determine the padding offset percent based on a pixel value and the overall chart width
                    const leftPaddingPct = this.chartPaddingPct.left;
                    const rightPaddingPct = this.chartPaddingPct.right;

                    // Total padding accounts for left and right padding
                    const totalPaddingPct = leftPaddingPct + rightPaddingPct;

                    // Determine the base section percent width from the total scale, padding, and track spacing
                    const baseSectionPctWidth = (100 - totalPaddingPct - totalTrackSpacePct) / totalScale;

                    // Initialize the left offset which will be adjusted up for each track
                    let offsetPct = leftPaddingPct;

                    // Loop over each track and create a grid
                    const grid = tracks.map((track, index) => {
                        // Scale the grid section based on the provided scale
                        const sectionPctWidth = baseSectionPctWidth * track.scale;
                        const gridDefinition = {
                            id: track.id,
                            left: `${offsetPct}%`,
                            width: `${sectionPctWidth}%`,
                            top: `${this.chartPaddingPct.top}%`,
                            bottom: `${this.chartPaddingPct.bottom}%`,
                        };

                        // Increment the offset for the next track to use
                        offsetPct += sectionPctWidth + trackSpacingPct;
                        return gridDefinition;
                    });
                    return grid;
                }

                // TODO: DRY this
                // Determine the padding offset percent based on a pixel value and the overall chart height
                const bottomPaddingPct = this.chartPaddingPct.bottom;
                const topPaddingPct = this.chartPaddingPct.top;

                // Total padding accounts for top and bottom padding
                const totalPaddingPct = bottomPaddingPct + topPaddingPct;

                // Determine the base section percent height from the total scale, padding, and track spacing
                const baseSectionPctHeight = (100 - totalPaddingPct - totalTrackSpacePct) / totalScale;

                // Initialize the bottom offset which will be adjusted up for each track
                let offsetPct = bottomPaddingPct;

                // Loop over each track and create a grid
                const grid = tracks.map((track, index) => {
                    // Scale the grid section based on the provided scale
                    const sectionPctHeight = baseSectionPctHeight * track.scale;
                    const gridDefinition = {
                        id: track.id,
                        bottom: `${offsetPct}%`,
                        height: `${sectionPctHeight}%`,
                        left: `${this.chartPaddingPct.left}%`,
                        right: `${this.chartPaddingPct.right}%`,
                    };

                    // Increment the offset for the next track to use
                    offsetPct += sectionPctHeight + trackSpacingPct;
                    return gridDefinition;
                });
                return grid;
            }
            // Single chart gets the default grid padding
            const gridDefinition = {
                id: 'default',
                bottom: `${this.chartPaddingPct.bottom}%`,
                top: `${this.chartPaddingPct.top}%`,
                left: `${this.chartPaddingPct.left}%`,
                right: `${this.chartPaddingPct.right}%`,
            };
            return gridDefinition;
        },
        datasetComponent() {
            // Build up the Echarts datasets from the data sources provided
            // Echarts datasets should be in the form of array of objects, for now
            const dataset = [];
            Object.entries(this.dataSources).forEach(([datasetName, datasetSource]) => {
                if (datasetSource) {
                    let sourceDimensions = null;
                    // Forge Source
                    if (datasetSource.format === 'index' && Object.keys(datasetSource.data).length > 0) {
                        sourceDimensions = {};
                        Object.entries(datasetSource.data).forEach(([dimensionName, dimensionValues]) => {
                            let transformedDimensionValues = dimensionValues;
                            if (this.dimensionTransforms[dimensionName]) {
                                transformedDimensionValues = dimensionValues.map(dimensionValue => this.dimensionTransforms[dimensionName](dimensionValue));
                            }
                            sourceDimensions[dimensionName] = transformedDimensionValues;
                        });
                    }
                    // Default Source
                    else if (datasetSource.length) {
                        sourceDimensions = datasetSource;
                    }

                    if (sourceDimensions !== null) {
                        dataset.push({
                            id: datasetName,
                            source: Object.freeze(sourceDimensions),
                        });
                    }
                }
            });
            return dataset;
        },
        xAxisComponent() {
            if (this.axes.xAxis) {
                return this.axes.xAxis.map(axisOptions => {
                    const axis = { ...this.xAxisDefaults };
                    // TODO: Validate axisOptions
                    // TODO: DRY this code with YAxis
                    // If there are tracks and axes are assigned to tracks set the gridId
                    if (this.gridComponent && this.gridComponent.length && axisOptions.track) {
                        const gridIds = this.gridComponent.map(grid => grid.id);
                        if (gridIds.includes(axisOptions.track)) {
                            axis.gridId = axisOptions.track;
                        }
                        else {
                            this.$logging.loggers.PluginFramework.error(`Axis passed unknown track: ${axisOptions.track}`);
                        }
                    }

                    // TODO: Need a better way to assign defaults than getDefault or ternary something that does some error checking/provides some rails?
                    axis.position = axisOptions.position;
                    axis.inverse = axisOptions.inverse;
                    axis.type = axisOptions.type;
                    axis.name = axisOptions.label;
                    axis.id = axisOptions.id;
                    axis.show = !axisOptions.hide;
                    axis.min = this.getDefault(axisOptions.min, 'axis.min');
                    axis.max = this.getDefault(axisOptions.max, 'axis.max');
                    axis.offset = axisOptions.offset ? axisOptions.offset : 0;
                    axis.axisLabel = axisOptions.axisLabel ? { ...axis.axisLabel, ...axisOptions.axisLabel } : axis.axisLabel;
                    axis.axisPointer = axisOptions.axisPointer ? { ...axis.axisPointer, ...axisOptions.axisPointer } : axis.axisPointer;
                    axis.axisTick = axisOptions.hideTicks ? { ...axis.axisTick, show: !axisOptions.hideTicks } : axis.axisTick;
                    axis.axisLabel = axisOptions.hideLabels ? { ...axis.axisLabel, show: !axisOptions.hideLabels } : axis.axisLabel;
                    axis.axisLabel.formatter = axisOptions.formatter !== undefined ? axisOptions.formatter : (value) => this.formatAxisLabel(value, axis.type, axisOptions.timeZone);
                    axis.axisLine = axisOptions.hideLine ? { ...axis.axisLine, show: !axisOptions.hideLine } : axis.axisLine;

                    return axis;
                });
            }
            return null;
        },
        yAxisComponent() {
            if (this.axes.yAxis) {
                return this.axes.yAxis.map(axisOptions => {
                    const axis = { ...this.yAxisDefaults };
                    // TODO: Validate axisOptions
                    // TODO: DRY this code with XAxis
                    // If there are tracks and axes are assigned to tracks set the gridId
                    if (this.gridComponent && this.gridComponent.length && axisOptions.track) {
                        const gridIds = this.gridComponent.map(grid => grid.id);
                        if (gridIds.includes(axisOptions.track)) {
                            axis.gridId = axisOptions.track;
                        }
                        else {
                            this.$logging.loggers.PluginFramework.error(`Axis passed unknown track: ${axisOptions.track}`);
                        }
                    }

                    // TODO: Need a better way to assign defaults than getDefault or ternary something that does some error checking/provides some rails?
                    axis.position = axisOptions.position;
                    axis.inverse = axisOptions.inverse;
                    axis.type = axisOptions.type;
                    axis.name = axisOptions.label;
                    axis.id = axisOptions.id;
                    axis.show = axisOptions.show !== undefined ? axisOptions.show : true;
                    axis.min = this.getDefault(axisOptions.min, 'axis.min');
                    axis.max = this.getDefault(axisOptions.max, 'axis.max');
                    axis.offset = axisOptions.offset ? axisOptions.offset : 0;
                    axis.axisLabel = axisOptions.axisLabel ? { ...axis.axisLabel, ...axisOptions.axisLabel } : axis.axisLabel;
                    axis.axisPointer = axisOptions.axisPointer ? { ...axis.axisPointer, ...axisOptions.axisPointer } : axis.axisPointer;
                    axis.axisTick = axisOptions.hideTicks ? { ...axis.axisTick, show: !axisOptions.hideTicks } : axis.axisTick;
                    axis.axisLabel = axisOptions.hideLabels ? { ...axis.axisLabel, show: !axisOptions.hideLabels } : axis.axisLabel;
                    axis.axisLabel.formatter = axisOptions.formatter !== undefined ? axisOptions.formatter : (value) => this.formatAxisLabel(value, axis.type);
                    axis.axisLine = axisOptions.hideLine ? { ...axis.axisLine, show: !axisOptions.hideLine } : axis.axisLine;

                    return axis;
                });
            }
            return null;
        },
        tooltipComponent() {
            return {
                show: true,
                trigger: 'axis', // item will cause formatter to not work as it returns the individual point
                extraCssText: 'border-radius: 0',
                formatter: seriesData => {
                    // Sort the series on index so the tooltip shows them in correct order
                    // Vertical needs to list series from left to right
                    if (this.chart.orientation === 'vertical') {
                        seriesData.sort((a, b) => a.seriesIndex - b.seriesIndex);
                    }
                    // Horizontal needs to list series from top to bottom
                    else {
                        seriesData.sort((a, b) => b.seriesIndex - a.seriesIndex);
                    }

                    // Assuming that the series are using a common axis use the first value
                    const axis = this[`${seriesData[0].axisDim}AxisComponent`][seriesData[0].axisIndex];
                    const axisRawValue = seriesData[0].axisValue;

                    // Format the axis value using the axis component formatter
                    const axisFormattedValue = axis.axisLabel.formatter(axisRawValue);
                    let htmlString = `${axisFormattedValue}<br>`;

                    // Build up the tooltip html for each series
                    seriesData.forEach(seriesDataPoint => {
                        // Value axis will be opposite of the axis pointer (axisDim)
                        const valueAxisDim = seriesDataPoint.axisDim === 'y' ? 'x' : 'y';

                        // Use the layer to look up the needed axis options which may contain formatter
                        const layer = this.layers[seriesDataPoint.seriesIndex];
                        const valueAxisId = layer[`${valueAxisDim}Axis`];
                        const valueAxis = this[`${valueAxisDim}AxisComponent`].find(axisComponent => axisComponent.id === valueAxisId);

                        // Format the axis value using the axis component formatter
                        const rawValue = seriesDataPoint.value[seriesDataPoint.encode[valueAxisDim][0]];
                        const formattedValue = valueAxis.axisLabel.formatter(rawValue);

                        htmlString += `${seriesDataPoint.marker} ${seriesDataPoint.seriesName}: ${formattedValue}<br>`;
                    });

                    return htmlString;
                },
            };
        },
        dataZoomComponent() {
            if (this.dataZoom && this.dataZoom.length) {
                const dataZoomComponent = this.dataZoom.map(dataZoomElement => {
                    // Map the Axis names to indices
                    const xAxisIndices = dataZoomElement.xAxes ? dataZoomElement.xAxes.map(axisName => this.axes.xAxis.findIndex(axis => axis.id === axisName)) : null;
                    const yAxisIndices = dataZoomElement.yAxes ? dataZoomElement.yAxes.map(axisName => this.axes.yAxis.findIndex(axis => axis.id === axisName)) : null;

                    // If the position is left/right orient vertical, otherwise horizontal
                    const orient = dataZoomElement.position === 'left' || dataZoomElement.position === 'right' ? 'vertical' : 'horizontal';

                    // Place the data zoom based on position (top, bottom, left, right)
                    // TODO: Handle case where multiple data zooms need o exist on same side
                    const position = {};
                    switch (dataZoomElement.position) {
                        case 'bottom':
                        default:
                            position.left = `${this.chartPaddingPct.left}%`;
                            position.right = `${this.chartPaddingPct.right}%`;
                            position.bottom = this.defaultDataZoomPosition;
                            break;
                        case 'top':
                            position.left = `${this.chartPaddingPct.left}%`;
                            position.right = `${this.chartPaddingPct.right}%`;
                            position.top = this.defaultDataZoomPosition;
                            break;
                        case 'right':
                            position.bottom = `${this.chartPaddingPct.bottom}%`;
                            position.top = `${this.chartPaddingPct.top}%`;
                            position.right = this.defaultDataZoomPosition;
                            break;
                        case 'left':
                            position.bottom = `${this.chartPaddingPct.bottom}%`;
                            position.top = `${this.chartPaddingPct.top}%`;
                            position.left = this.defaultDataZoomPosition;
                            break;
                    }

                    const slider = {
                        xAxisIndex: xAxisIndices,
                        yAxisIndex: yAxisIndices,
                        orient,
                        ...position,
                        ...this.defaultSlider,
                        ...dataZoomElement,
                        type: 'slider',
                    };
                    const summarySlider = {
                        xAxisIndex: xAxisIndices,
                        yAxisIndex: yAxisIndices,
                        orient,
                        ...position,
                        ...this.defaultSummarySlider,
                        ...dataZoomElement,
                        type: 'slider',
                    };

                    // Default slider is the basic slider
                    if (dataZoomElement.type === 'slider' || dataZoomElement.type === undefined) {
                        // Modify the height/width based on orientation
                        if (orient === 'horizontal') {
                            slider.height = 10;
                        }
                        else {
                            slider.width = 10;
                        }
                        return slider;
                    }
                    // Summary slider if explicitly defined
                    if (dataZoomElement.type === 'summary-slider') {
                        return summarySlider;
                    }
                    // Straight pass through of element if another type
                    return dataZoomElement;
                });

                return dataZoomComponent;
            }

            // No data zoom
            return null;
        },
        seriesComponent() {
            const series = this.layers.map(layer => this.generateSeriesFromLayer(layer));
            return series;
        },
    },
    mounted() {
        // Get the chart dimenions when loaded to calculate padding values and create grid later
        this.chartWidth = this.$refs.theChart.width;
        this.chartHeight = this.$refs.theChart.height;
    },
    methods: {
        // TODO: Reevaluate/Remove?
        createBrush(brushInfo, chartContext) {
            return {
                xAxisIndex: 'all',
                brushLink: 'all',
                throttleType: 'debounce',
                throttleDelay: 250,
                outOfBrush: {
                    colorAlpha: 0.3,
                },
                brushStyle: {
                    borderWidth: 1,
                    color: 'rgba(152,74,180,0.3)',
                    borderColor: 'rgba(88,0,180,0.8)',
                },
            };
        },
        generateSeriesFromLayer(layer) {
            const series = [];
            // TODO: Change to hide
            if (layer.visible !== false) {
                switch (layer.type) {
                    case 'line': {
                        const lineSeries = {
                            type: 'line',
                            id: layer.id,
                            name: layer.name,
                            xAxisId: layer.xAxis,
                            yAxisId: layer.yAxis,
                            datasetId: layer.data,
                            encode: {
                                x: layer.x,
                                y: layer.y,
                            },
                            color: this.getDefault(layer.color, 'line.color'),
                            showSymbol: this.getDefault(layer.showSymbol, 'line.showSymbol'),
                            lineStyle: {
                                width: this.getDefault(layer.width, 'line.width'),
                                type: this.getDefault(layer.style, 'line.style'),
                            },
                        };
                        if (layer.area) {
                            lineSeries.areaStyle = {};
                        }
                        if (layer.stack) {
                            lineSeries.stack = layer.stack;
                        }
                        return lineSeries;
                    }
                    case 'scatter': {
                        const scatterSeries = {
                            type: 'scatter',
                            id: layer.id,
                            name: layer.name,
                            xAxisId: layer.xAxis,
                            yAxisId: layer.yAxis,
                            datasetId: layer.data,
                            encode: {
                                x: layer.x,
                                y: layer.y,
                            },
                            symbolSize: this.getDefault(layer.size, 'marker.size'),
                            itemStyle: {
                                color: this.getDefault(layer.color, 'marker.color'),
                                opacity: this.getDefault(layer.opacity, 'marker.opacity'),
                            },
                        };
                        return scatterSeries;
                    }
                    case 'pie': {
                        const pieSeries = {
                            type: 'pie',
                            id: layer.id,
                            name: layer.name,
                            datasetId: layer.data,
                            radius: this.getDefault(layer.radius, 'pie.radius'),
                            center: ['50%', '50%'],
                            encode: {
                                itemName: layer.itemName,
                                value: layer.value,
                                tooltip: layer.tooltip,
                            },
                        };
                        return pieSeries;
                    }
                    case 'comments': {
                        const commentSeries = {
                            type: 'scatter',
                            id: layer.id,
                            name: layer.name,
                            xAxisId: layer.xAxis,
                            yAxisId: layer.yAxis,
                            datasetId: layer.data,
                            encode: {
                                x: layer.x,
                                y: layer.y,
                            },
                            color: this.getDefault(layer.color, 'line.color'),
                            showSymbol: this.getDefault(layer.showSymbol, 'line.showSymbol'),
                            symbol: 'rect',
                            lineStyle: {
                                width: this.getDefault(layer.width, 'line.width'),
                                type: this.getDefault(layer.style, 'line.style'),
                            },
                        };
                        return commentSeries;
                    }
                    default: {
                        this.$logging.loggers.PluginFramework.error(`Chart rendering halted due to unknown layer type: ${layer.type}`);
                        return null;
                    }
                }
            }
        },
        getDefault(value, name) {
            if (value === undefined || value === null) {
                if (this.defaultOptions[name] !== undefined) {
                    return this.defaultOptions[name];
                }
                return null;
            }
            return value;
        },
        getDataURL(options) {
            if (this.$refs.theChart.chart) {
                return this.$refs.theChart.chart.getDataURL({
                    pixelRatio: 2,
                    backgroundColor: '#fff',
                });
            }
            // TODO: throw error
            return null;
        },
        // General axis formatter for echart axis types
        formatAxisLabel(value, type, timeZoneId = 'UTC') {
            switch (type) {
                case 'value':
                case 'log':
                default:
                    return this.$utils.formatting.formatNumber(value);
                case 'time':
                    return this.$utils.formatting.formatDate(value, timeZoneId, 'M/D/YY');
                case 'category':
                    // Handle echarts bug where dataZoom doesn't work with time axes
                    // TODO: This is brittle against different non-time values, is there another way?
                    if (moment(value).isValid()) {
                        return this.$utils.formatting.formatDate(value, timeZoneId, 'M/D/YY');
                    }
                    return value;
            }
        },
        onChartClick(params) {
            if (this.chartClick) {
                this.chartClick(this.$refs.theChart, params);
            }
        },
        onChartContextMenu(params) {
            if (this.canComment && this.chartContextMenu && this.$refs.theChart && this.$refs.theChart.chart) {
                const evt = params.event.event;
                evt.preventDefault();
                const chart = this.$refs.theChart.chart;
                const spot = chart.convertFromPixel({ seriesId: params.seriesId }, [evt.layerX, evt.layerY]);
                if (spot) {
                    const chartName = this.chartCommentPartName();
                    this.chartContextMenu({
                        partName: chartName,
                        context: {
                            seriesId: params.seriesId,
                            x1: spot[0],
                            y1: spot[1],
                            commentGroup: `${chartName}, ${params.seriesName}`,
                        },
                    });
                }
            }
        },
        onBrushChanged(params) {

            /*
            // get the selected points
            if (params.type === 'brushselected') {
                const selectedItems = params.batch[0].selected.map(x => {
                    return {
                        layerId: x.seriesId,
                        dataIndex: x.dataIndex,
                    };
                });
                this.$emit('dataselected', selectedItems);
            }
                         */
        },
    },
};
</script>

<style scoped>

</style>
