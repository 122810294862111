<template>
    <div>{{ label }}</div>
</template>

<script>
export default {
    name: 'HorizontalRuleLabel',
    props: {
        label: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped lang="stylus">
@import '../../../css/quasar.variables.styl';

div {
    display: flex;
    align-items: center;
    text-align: center;
    color: $faded;
}

div::before, div::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid $separator-color;
}

div::before {
    margin-right: 1rem;
}

div::after {
    margin-left: 1rem;
}
</style>
