import Vue from 'vue';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { init as elasticAPMInitialize } from '@elastic/apm-rum';
import { afterFrame } from '@elastic/apm-rum-core';
import { Logger } from '../logging/logger.js';

export class TelemetryService {
    constructor({ config, router }) {
        this.log = new Logger({ name: 'Telemetry', level: Logger.levels.warn });
        Vue.$logging.registerLogger(this.log);

        if (!Vue.$auth) {
            this.log.error('Telemetry plugin requires the Auth plugin.');
            return;
        }

        if (!Vue.$api) {
            this.log.error('Telemetry plugin requires the API plugin.');
            return;
        }

        if (!router) {
            this.log.error('Telemetry plugin requires a router.');
            return;
        }

        this.appInsights = null;
        this.elasticAPM = null;

        this.router = router;

        // Constant event names
        this.eventNames = {
            BUTTON_CLICK: 'Button Click',
        };

        // Constant event types
        this.eventTypes = {
            USER: 'user',
            SYSTEM: 'system',
        };

        this.__addRouteHooks();

        // Must be called after user has logged in so that API call can be made to get instrumentationKey
        Vue.$auth.afterSignIn('initializeTelemetryService', user => {
            this.__initialize({ config, user });
        });
    }

    // Track a custom system event
    trackSystemEvent({ name, properties = {} } = {}) {
        this.__trackEvent({
            name,
            properties: {
                ...properties,
                eventType: this.eventTypes.SYSTEM,
            },
        });
    }

    // Track a custom user event
    trackUserEvent({ name, properties = {} } = {}) {
        this.__trackEvent({
            name,
            properties: {
                ...properties,
                eventType: this.eventTypes.USER,
            },
        });
    }

    // Send a named event with optional metadata to app insights
    __trackEvent({ name, properties = null } = {}) {
        if (!name || typeof name !== 'string') {
            this.log.error('trackEvent requires an event name of type string to be passed.');
            return;
        }

        if (typeof properties !== 'object' || properties === null) {
            this.log.error('trackEvent event properties must be of type object.');
            return;
        }

        if (!properties?.eventType) {
            this.log.error('trackEvent properties must include an event type.');
            return;
        }

        if (this.appInsights) {
            const event = {
                name,
                properties,
            };

            this.appInsights.trackEvent(event);
        }
    }

    // Initializes the application insights service
    __initialize({ config, user }) {
        // Configure vue to use error handler that reports Vue errors
        Vue.config.errorHandler = this.__createErrorHandlerInterceptor();

        this.__getTelemetryInfo()
        .then(telemetryInfo => {
            this.__initializeAppInsights({ config, user, telemetryInfo });
            this.__initializeElasticAPM({ config, user, telemetryInfo });
        })
        .catch(error => {
            this.log.error('Unable to initialize telemetry plugin:', error);
        });
    }

    __initializeAppInsights({ config, user, telemetryInfo }) {
        try {
            const defaultConfig = {
                instrumentationKey: telemetryInfo.instrumentationKey,
                accountId: user?.orgId,
            };
            const appInsightsConfig = { ...defaultConfig, ...config.appInsights };

            if (!appInsightsConfig.instrumentationKey) {
                this.log.error('Unable to initialize app insights: missing instrumentation key.');
                return;
            }


            this.appInsights = new ApplicationInsights({ config: appInsightsConfig });
            this.appInsights.loadAppInsights();

            const validatedId = user.userId.replace(/[,;=| ]+/g, '_');
            this.appInsights.setAuthenticatedUserContext(validatedId);

            // Add telemetry initializer which adds common data to telemetry
            this.appInsights.addTelemetryInitializer((telemetryItem) => {
                // Ensure that telemetryItem has baseData.properties
                if (telemetryItem && !telemetryItem.baseData) {
                    telemetryItem.baseData = {};
                }
                if (telemetryItem && !telemetryItem.baseData.properties) {
                    telemetryItem.baseData.properties = {};
                }

                const pluginId = this.router.currentRoute?.matched?.find(route => route.meta && route.meta.pluginId)?.meta.pluginId;

                // Add common properties to all telemetry
                telemetryItem.baseData.properties = {
                    ...telemetryItem.baseData.properties,
                    organizationId: user.orgId,
                    environmentId: telemetryInfo.environmentId,
                    roles: user.roles,
                    version: telemetryInfo.version,
                    pageName: this.router?.currentRoute?.name,
                    // If a pluginId is not present then we are in a legacy platform route
                    pluginId: pluginId || 'Platform',
                };
            });

            // Manually call trackPageView to establish the current user/session/pageview
            const currentRouteName = this.router?.currentRoute?.name ? this.router.currentRoute.name : 'unnamed-route';
            const toUri = `${window.location.protocol}//${window.location.host}${this.router.currentRoute.fullPath}`;
            this.appInsights.trackPageView({
                name: currentRouteName,
                uri: toUri,
            });
        }
        catch (error) {
            this.log.error('Unable to initialize app insights:', error);
        }
    }

    __initializeElasticAPM({ config, user, telemetryInfo }) {
        try {
            if (!config.elasticAPM || !config.elasticAPM.serviceName) {
                this.log.error('Unable to initialize Elastic APM: missing service name.');
                return;
            }

            this.elasticAPM = elasticAPMInitialize(config.elasticAPM);

            this.elasticAPM.setUserContext({
                id: user.userId,
            });

            this.elasticAPM.addLabels({
                organizationId: user.orgId,
                environmentId: telemetryInfo.environmentId,
                version: telemetryInfo.version,
            });
        }
        catch (error) {
            this.log.error('Unable to initialize Elastic APM:', error);
        }
    }

    __addRouteHooks() {
        this.__addAppInsightsRouteHooks();
        this.__addElasticAPMRouteHooks();
    }

    __addAppInsightsRouteHooks() {
        // track a page view before going to the route
        this.router.beforeEach((to, from, next) => {
            if (this.appInsights) {
                const name = to.name ? to.name : 'unnamed-route';
                const toUri = `${window.location.protocol}//${window.location.host}${to.fullPath}`;
                const fromUri = `${window.location.protocol}//${window.location.host}${from.fullPath}`;
                this.appInsights.trackPageView({
                    name,
                    uri: toUri,
                    refUri: fromUri,
                });
                this.appInsights.flush();
            }
            next();
        });
    }

    __addElasticAPMRouteHooks() {
        // Taken from https://github.com/elastic/apm-agent-rum-js/blob/master/packages/rum-vue/src/route-hooks.js
        let transaction;

        this.router.beforeEach((to, from, next) => {
            if (this.elasticAPM) {
                const matched = to.matched || [];
                let path = to.path;
                // If possible use the URL with slugs (provided by matched stack) for the path name
                if (matched.length) {
                    path = matched[matched.length - 1].path || path;
                }
                // Send the route change transaction
                transaction = this.elasticAPM.startTransaction(path, 'route-change', { managed: true, canReuse: true });
            }
            next();
        });

        this.router.afterEach(() => {
            afterFrame(() => {
                if (transaction) {
                    transaction.detectFinish();
                }
            });
        });

        // End transaction when there is an error
        this.router.onError(() => {
            if (transaction) {
                transaction.end();
            }
        });
    }

    __createErrorHandlerInterceptor() {
        const defaultHandler = Vue.config.errorHandler;

        return (error, vm, info) => {
            if (this.elasticAPM) {
                // Enhance the error with vue info
                if (vm && vm.options) {
                    const options = vm.$options;
                    let component;
                    if (vm.$root === vm) {
                        component = 'Root';
                    }
                    else {
                        component = options.name || options._componentTag || 'Anonymous';
                    }

                    error.component = component;
                    error.file = options.__file || '';
                }
                if (info) {
                    error.lifecycleHook = info;
                }

                // Send to telemetry service
                this.elasticAPM.captureError(error);
            }

            // Pass the error through
            defaultHandler.call(this, error, vm, info);
        };
    }

    // Get the instrumentation key from the backend using user credentials
    __getTelemetryInfo() {
        return Vue.$api.axiosInstance.get('/api/telemetry')
        .then(response => {
            if (response.data && response.data.length && response.data[0]) {
                const telemetryInfo = response.data[0];
                return telemetryInfo;
            }
            throw new Error('response contained no data');
        })
        .catch(error => {
            this.log.error('Unable to retrieve telemetry info:', error);
            return null;
        });
    }
}
