export function NotificationsModel({ app, plugin }) {
    return {
        fields: {
            allHashtags: {
                source: 'forge',
                options: {
                    workspace: 'hashtagsWorkspace',
                    resource: 'HashtagInfo',
                },
            },
            followedHashtags: {
                source: 'forge',
                options: {
                    workspace: 'hashtagsFollowedWorkspace',
                    resource: 'FollowedTags',
                },
            },
            currentUserObject: {
                source: 'forge',
                options: {
                    workspace: 'userWorkspace',
                    resource: 'SessionUser',
                    single: true,
                },
            },
            notificationUpdateInProgress: {
                source: 'self',
                options: {
                    default: false,
                },
            },
        },
        getters: {
            hashtagsWorkspace: () => '$root/Hashtags',
            hashtagsFollowedWorkspace: (state, getters) => `${getters.hashtagsWorkspace}/Followed/${app.$auth.user.userId}`,
            userWorkspace: (state, getters) => `$root/Users/${app.$auth.user.userId}`,
            forgeResources: (state, getters) => [
                getters.allHashtagsResource,
                getters.currentUserObjectResource,
                getters.followedHashtagsResource,
            ],
            userNotificationPreferences: (state, getters) => {
                let preferences = [];
                const userDefinition = getters.currentUserObject;
                if (userDefinition) {
                    preferences = userDefinition.notificationPreferences || [];
                }
                return preferences;
            },
        },
        actions: {
            updateNotificationPreferences({ state, getters }, update) {
                state.notificationUpdateInProgress = true;
                const userObject = getters.currentUserObject;
                const notificationPreferences = [...getters.userNotificationPreferences];
                let consumerPreferences = notificationPreferences.find(consumer => consumer.consumerName === update.consumerName);
                if (consumerPreferences) {
                    consumerPreferences.userOptions = update.userOptions;
                }
                else {
                    consumerPreferences = {
                        consumerName: update.consumerName,
                        userOptions: update.userOptions,
                        userId: app.$auth.user.userId,
                        subscribed: true,
                    };
                    notificationPreferences.push(consumerPreferences);
                }
                const data = {
                    notificationPreferences,
                };
                app.$api.data.update({
                    type: 'User',
                    query: {
                        id: userObject.id,
                    },
                    body: data,
                }).then(() => {
                    app.$notify.success('Preferences updated.');
                    state.notificationUpdateInProgress = false;
                }).catch(issue => {
                    app.$notify.error('Could not update preferences:', issue);
                    state.notificationUpdateInProgress = false;
                });
            },
        },
    };
}
