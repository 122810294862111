<template>
    <q-scroll-area
        class="fit"
    >
        <div
            class="markdown-content"
            v-html="htmlContent"
        >
            Loading...
        </div>
    </q-scroll-area>
</template>

<script>
import marked from 'marked';
import DOMPurify from 'dompurify';

marked.setOptions({
    // Don't ad Id hashes to header elements
    headerIds: false,
    // Clean malicious HTML
    sanitizer: DOMPurify.sanitize,
});

export default {
    name: 'Markdown',
    props: {
        markdown: {
            type: String,
            required: false,
            default: '',
        },
    },
    data() {
        return {
            htmlContent: null,
        };
    },
    watch: {
        markdown: {
            immediate: true,
            handler(newMarkdown) {
                this.htmlContent = marked(newMarkdown);
            },
        },
    },
};
</script>

<style>
.markdown-content h1 {
    font-size: 2rem;
    line-height: 2rem;
}
.markdown-content h2 {
    font-size: 1.5rem;
    line-height: 1.5rem;
}
.markdown-content h3 {
    font-size: 1.17rem;
    line-height: 1.17rem;
}
.markdown-content h4 {
    font-size: 1rem;
    line-height: 1rem;
}
.markdown-content h5 {
    font-size: .83rem;
    line-height: .83rem;
}
.markdown-content h6 {
    font-size: .67rem;
    line-height: .67rem;
}
</style>
