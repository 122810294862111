import Vue from 'vue';
import { debounce } from 'quasar';
import InteractiveAnalysisTemplate from './templates/InteractiveAnalysisTemplate.vue';
import OverviewTableTemplate from './templates/OverviewTableTemplate.vue';
import FullPageTemplate from './templates/FullPageTemplate.vue';
import ReportTemplate from './templates/ReportTemplate.vue';
import FAppCard from './fragments/FAppCard.vue';
import DataTable from './shared-components/tables/data-table/DataTable.vue';
import { Logger } from './services/logging/logger.js';
import ForgeTableTemplate from './templates/ForgeTableTemplate.vue';
import ProfilePageTemplate from './templates/ProfilePageTemplate';

function _iterObj(obj, callback) {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            callback(key, obj[key], obj);
        }
    }
}

export class PluginFramework {
    constructor({ routes, store, appContext }) {
        // Service Name
        this.name = this.constructor.name;
        // Logging
        this.log = new Logger({ name: 'PluginFramework', level: Logger.levels.warn });
        Vue.$logging.registerLogger(this.log);

        // Ensure that Framework remains a singleton
        if (PluginFramework.instance) {
            return PluginFramework.instance;
        }

        if (!store) {
            throw new Error('Framework must be passed a vuex store.');
        }

        if (!routes) {
            throw new Error('Framework must be passed an array of vue router routes.');
        }

        // Map of plugin templates to associated framework vue components
        this.templateMappings = {
            'forge-table': ForgeTableTemplate,
            'full-page': FullPageTemplate,
            report: ReportTemplate,
            'interactive-analysis': InteractiveAnalysisTemplate,
            'overview-table': OverviewTableTemplate,
            'profile-page': ProfilePageTemplate,
        };

        this.validModuleTypes = [
            'fragment',
            'template',
            'globals',
        ];

        this.validModelFieldSources = [
            'route',
            'forge',
            'forge-depends',
            'self',
        ];

        // Store all registered plugin routes
        this.routes = routes;

        // Store the application store ;)
        this.store = store;

        // Application context exposed to the plugin
        this.appContext = appContext;
        this.appContext.$plugins = this;
        Vue.$plugins = this;

        // Store all the registered plugin instances
        this.plugins = [];

        // Store all registered fragments
        this.registeredFragments = {};

        PluginFramework.instance = this;
    }

    // Make Framework available within Vue
    install(vueInstance) {
        vueInstance.$pai = this;
        vueInstance.prototype.$pai = this;

        this.__registerGlobalPlugins();

        // Register fragments
        // TODO: Make this registration dynamic
        vueInstance.component('FAppCard', FAppCard);
        vueInstance.component('DataTable', DataTable);
    }

    // Get fragments with provided target and initialize their options
    getFragments(target) {
        const fragments = this.registeredFragments[target];
        if (fragments) {
            // invoke the fragment options
            return fragments.map(frag => {
                return { priority: frag.priority, ...frag.options() };
            });
        }
        return [];
    }

    commentExists(notificationSource) {
        return new Promise((resolve, reject) => {
            Vue.$api.data.query({
                type: 'Comment',
                query: {
                    id: notificationSource.id,
                },
            }).then(response => {
                if (response && response.data && response.data.length) {
                    resolve(true);
                }
                resolve(false);
            }).catch(issue => {
                resolve(false);
            });
        });
    }

    /**
     * Invokes a specific action on one or more plugins.
     * @param actionName - The action name, which must be defined on the target plugins.
     * @param pluginIds - The IDs of all the plugins on which to call the action. null indicates a global action for all registered plugins.
     * @param parameters - The parameters object that will be sent to each action call.
     */
    async triggerPluginAction(actionName, pluginIds, parameters) {
        if (!actionName) {
            this.log.error('triggerPluginAction actionName required.');
        }
        if (pluginIds && !pluginIds.length) {
            this.log.error('triggerPluginAction pluginIds must be non-empty, if defined. Use null for a global action.');
            return;
        }
        if (actionName === 'resolveComment') {
            // TODO: Remove this later, when the onus is moved to the plugins.
            const commentExists = await this.commentExists(parameters);
            if (!commentExists) {
                Vue.$notify.error('The content no longer exists.');
                return;
            }
        }
        const actionPlugins = pluginIds ? this.plugins.filter(plugin => pluginIds.includes(plugin.name)) : this.plugins;
        if (pluginIds) {
            // Particular plugins were targeted. Verify that they are all registered.
            if (actionPlugins.length !== pluginIds.length) {
                const foundIds = actionPlugins.map(plugin => plugin.name);
                const difference = pluginIds.filter(plugin => !foundIds.includes(plugin));
                this.log.error(`triggerPluginAction found no plugins matching ID(s): ${difference.join(', ')}`);
                if (!actionPlugins.length) {
                    // List is empty, so there's nothing to do.
                    return;
                }
            }
        }
        else {
            this.log.info(`triggerPluginAction attempting action '${actionName}' on all registered plugins.`);
        }
        // We had at least one match, so continue with the ones that were found.
        actionPlugins.forEach(plugin => {
            const pluginName = plugin.name;
            if (!plugin.actions) {
                this.log.info(`triggerPluginAction no actions defined for plugin '${pluginName}'.`);
                return;
            }
            if (!plugin.actions[actionName]) {
                this.log.info(`triggerPluginAction action '${actionName}' not defined for plugin '${pluginName}'.`);
                return;
            }
            this.log.info(`triggerPluginAction calling action '${actionName}' on plugin '${pluginName}'.`);
            plugin.actions[actionName].call(plugin.$context, parameters);
        });
    }

    /**
     * Builds a shortcut to a plugin page, invoking default behavior if the plugin has not overridden it.
     * @param pluginId - The ID of all the plugin generating the shortcut.
     * @returns An object with route or other information for later resolution of the shortcut.
     */
    buildPluginShortcut(pluginId) {
        if (!pluginId) {
            this.log.error('buildPluginShortcut pluginId must be defined.');
            return null;
        }
        const pluginMatch = this.plugins.find(plugin => pluginId === plugin.name);
        // Verify that the plugin is registered.
        if (!pluginMatch) {
            this.log.error(`buildPluginShortcut found no plugin matching ID: ${pluginId}`);
            return null;
        }
        if (!pluginMatch.actions || !pluginMatch.actions.buildShortcut) {
            this.log.info(`buildPluginShortcut no 'buildShortcut' action defined for plugin '${pluginId}'. Using default behavior.`);
            const currentRoute = this.appContext.$router.app.$route;
            return {
                toRoute: {
                    name: currentRoute.name,
                    query: currentRoute.query,
                    params: currentRoute.params,
                },
            };
        }
        return pluginMatch.actions.buildShortcut();
    }

    // Look for plugins that are installed in the window.petroPluginNamespaces namespace
    // These plugins will have been imported via script tags in the index.html
    __registerGlobalPlugins() {
        const petroPluginNamespaces = window.petroPluginNamespaces;
        if (petroPluginNamespaces && petroPluginNamespaces.length) {
            // Loop through all namespaces and all plugins in each namespace registering them
            this.log.info(`Registering plugins for the following namespaces ${petroPluginNamespaces}`);
            petroPluginNamespaces.forEach(namespace => {
                if (window[namespace]) {
                    // Get all plugin keys under the namespace
                    const pluginKeys = Object.keys(window[namespace]);
                    // Register each plugin
                    pluginKeys.forEach(pluginKey => {
                        const plugin = window[namespace][pluginKey];
                        this.__registerPlugin(plugin);
                    });
                }
            });
            this.log.info('Plugins registered');
        }
        else {
            this.log.warn('No petroPluginNamespaces found on window object. No plugins registered.');
        }
    }

    // Main method called to register plugin with the app
    __registerPlugin(plugin) {
        this.log.info(`Registering ${plugin.name} plugin`);

        // Make sure the plugin has name and definition function
        if (this.__pluginObjectIsValid(plugin)) {
            // create the definition by passing the appContext to the definition
            const initializedDefinition = plugin.definition({
                app: this.appContext,
                plugin: this.__createPluginContext(plugin),
            });
            // merge in the fields
            const initializedPlugin = { ...plugin, ...initializedDefinition };

            // Ensure that the initialized plugin is valid
            if (this.__initializedPluginIsValid(initializedPlugin)) {
                this.plugins.push(initializedPlugin);
                try {
                    this.__buildStore(initializedPlugin);
                    this.__registerStore(initializedPlugin);
                    this.__buildContext(initializedPlugin);
                    this.__bindContext(initializedPlugin);
                    this.__registerFragments(initializedPlugin);
                    this.__registerTemplates(initializedPlugin);
                    this.__registerViews(initializedPlugin);
                    this.__registerComponents(initializedPlugin);
                    this.__registerGlobals(initializedPlugin);
                    PluginFramework.__registerAfterSignInHook(initializedPlugin);
                }
                catch (error) {
                    this.log.error(error);
                }
            }
        }
    }

    // Creates a context for plugin related services that are scoped to the plugin
    // Used for plugin specific services
    __createPluginContext(plugin) {
        // If in development start by logging all, prod log errors only
        const level = process.env.NODE_ENV === 'development' ? Logger.levels.all : Logger.levels.warn;
        // Remove all whitespace from the logger name
        const logger = new Logger({ name: plugin.name.replace(/\s+/g, ''), level });
        Vue.$logging.registerLogger(logger);

        const context = {
            // Inject the original plugin definition
            $definition: plugin,
            $log: logger,
        };
        this.log.info(`Created plugin context for ${plugin.name}:`, context);
        return context;
    }

    // Validates that a plugin has the required name field, it is unique and has definition function
    __pluginObjectIsValid(plugin) {
        this.log.info('Validating plugin name:', plugin.name);
        let isValid = true;

        // plugin has name
        if (!plugin.name || plugin.name === '' || typeof plugin.name !== 'string') {
            this.log.error('Plugin definition is missing required field "name" of type string');
            isValid = false;
        }
        // plugin has unique name
        if (plugin.name) {
            const nameUnique = !this.plugins.some(registeredPlugin => registeredPlugin.name === plugin.name);
            if (!nameUnique) {
                this.log.error(`${plugin.name} definition name is not unique`);
                isValid = false;
            }
        }
        // plugin has definition function
        if (!plugin.definition || typeof plugin.definition !== 'function') {
            this.log.error(`${plugin.name} definition is missing required field "definition" of type function`);
            isValid = false;
        }

        return isValid;
    }

    // TODO: move to a class based validation not this function based validation mess
    __initializedPluginIsValid(plugin) {
        this.log.info(`Validating initialized ${plugin.name}:`, { ...plugin });

        const modulesAreValid = this.__pluginModulesAreValid(plugin);
        const modelIsValid = this.__pluginModelIsValid(plugin);

        return modulesAreValid && modelIsValid;
    }

    // Validates modules of an initialized plugin
    __pluginModulesAreValid(plugin) {
        this.log.info(`Validating modules for ${plugin.name}:`, plugin.modules);
        let isValid = true;

        // validate plugin modules type
        if (plugin.modules && !Array.isArray(plugin.modules)) {
            this.log.error(`${plugin.name} definition "modules" field is not of type array`);
            isValid = false;
        }
        // validate each module

        if (plugin.modules && Array.isArray(plugin.modules)) {
            const moduleValidFlags = [];
            plugin.modules.forEach((module, index) => {
                // Collect array of flags
                moduleValidFlags.push(this.__moduleIsValid(module, index + 1, plugin.name));
            });
            // Reduce to a single flag
            const everyModuleValid = moduleValidFlags.every(flag => flag === true);
            if (!everyModuleValid) {
                isValid = false;
            }
        }

        return isValid;
    }

    // Validate module
    __moduleIsValid(module, moduleNumber, pluginName) {
        this.log.info(`Validating ${pluginName} module ${moduleNumber}:`, module);
        let isValid = true;

        // validate module has type field
        if (!module.type || typeof module.type !== 'string') {
            this.log.error(`${pluginName} module ${moduleNumber} is missing required field "type" of type string`);
            isValid = false;
        }

        // validate module has target field
        if (!module.target || typeof module.target !== 'string') {
            this.log.error(`${pluginName} module ${moduleNumber} is missing required field "target" of type string`);
            isValid = false;
        }

        // validate module has options field
        if (!module.options || typeof module.options !== 'function') {
            this.log.error(`${pluginName} module ${moduleNumber} is missing required field "options" of type function`);
            isValid = false;
        }

        // validate type values
        if (module.type && !this.validModuleTypes.includes(module.type)) {
            this.log.error(`${pluginName} module ${moduleNumber} "type" field contains invalid module type:`, module.type);
            isValid = false;
        }

        return isValid;
    }

    // Validates the model of an initialized plugin
    __pluginModelIsValid(plugin) {
        this.log.info(`Validating ${plugin.name} modules:`, plugin.model);
        let isValid = true;

        // validate plugin model type
        if (plugin.model && typeof plugin.model !== 'object') {
            this.log.error(`${plugin.name} definition "model" field is not of type object`);
            isValid = false;
        }

        // validate model fields
        if (plugin.model && plugin.model.fields) {
            const fieldValidFlags = [];
            const fieldNames = Object.keys(plugin.model.fields);
            fieldNames.forEach(fieldName => {
                const field = plugin.model.fields[fieldName];
                fieldValidFlags.push(this.__modelFieldIsValid(field, fieldName, plugin.name));
            });
            // Reduce to a single flag
            const everyFieldValid = fieldValidFlags.every(flag => flag === true);
            if (!everyFieldValid) {
                isValid = false;
            }
        }

        return isValid;
    }

    // validates a model field
    __modelFieldIsValid(field, fieldName, pluginName) {
        this.log.info(`Validating ${pluginName} ${fieldName} model field:`, field);
        let isValid = true;

        // field has source
        if (!field.source || typeof field.source !== 'string') {
            this.log.error(`${pluginName} ${fieldName} model field is missing required field "source" of type string`);
            isValid = false;
        }

        // validate source values
        if (field.source && !this.validModelFieldSources.includes(field.source)) {
            this.log.error(`${pluginName} ${fieldName} model field "source" field contains invalid source type:`, field.source);
            isValid = false;
        }

        // validate that forge sources have options
        if (field.source === 'forge' && (!field.options || typeof field.options !== 'object')) {
            this.log.error(`${pluginName} ${fieldName} model field with source type of forge is missing required field "options" of type object`);
            isValid = false;
        }

        return isValid;
    }

    // Programmatically generate a Vuex store from the plugin model field
    __buildStore(plugin) {
        if (plugin.model) {
            plugin.info = {
                // TODO: Should this be explicitly provided by plugin not the name?
                namespace: plugin.name,
            };

            // Define the default plugin store
            plugin.store = {
                namespaced: true,
                state: {},
                mutations: {},
                getters: {},
                actions: {},
            };

            const fields = plugin.model.fields;
            const modelGetters = plugin.model.getters;
            const modelMutations = plugin.model.mutations;
            const modelActions = plugin.model.actions;

            // Add plugin defined fields to the store as state and mutations
            if (fields) {
                const fieldNames = Object.keys(fields);
                fieldNames.forEach(fieldName => {
                    const field = fields[fieldName];
                    // If the field is a forge type register it
                    if (field.source === 'forge') {
                        // Get the resource name
                        const forgeOptions = field.options;

                        // TODO: Fix these magic names
                        // Create a getter for the forge resource name
                        const fieldNameResource = `${fieldName}Resource`;
                        plugin.store.getters[fieldNameResource] = (state, getters) => `${getters[forgeOptions.workspace]}#${forgeOptions.resource}`;

                        // Create a getter for the forge resource loading flag
                        const fieldNameLoading = `${fieldName}Loading`;
                        plugin.store.getters[fieldNameLoading] = (state, getters, rootState, rootGetters) => {
                            // Get the forge resource
                            const resourceName = `${getters[forgeOptions.workspace]}#${forgeOptions.resource}`;
                            const el = rootGetters['compute/getElement'](resourceName);
                            return el ? el.state === 'initial' : false;
                        };

                        // Create a getter for the forge resource
                        plugin.store.getters[fieldName] = (state, getters, rootState, rootGetters) => {
                            // Get the forge resource
                            const resourceName = `${getters[forgeOptions.workspace]}#${forgeOptions.resource}`;
                            const el = rootGetters['compute/getElement'](resourceName);

                            if (forgeOptions.single === true) {
                                return el ? el.data[0] : null;
                            }
                            return el ? el.data : [];
                        };

                        // Create an action for setting the forge resource
                        plugin.store.actions[fieldName] = ({ getters }, value) => {
                            const resourceName = `${getters[forgeOptions.workspace]}#${forgeOptions.resource}`;
                            return this.appContext.$compute.forgeSetVariable(resourceName, value);
                        };
                    }
                    // Field is a forge depends list
                    else if (field.source === 'forge-depends') {
                        const forgeDepends = field.options.depends;
                        const _overlap = (a, b) => a.filter(x => b.includes(x)).length > 0;
                        const dependsList = fieldNames.filter(f => fields[f].source === 'forge' && fields[f].options.depends !== undefined && _overlap(fields[f].options.depends, forgeDepends));
                        // Collect forge resources that match
                        plugin.store.getters[fieldName] = (state, getters) => {
                            const arr = dependsList.map(x => getters[`${x}Resource`]);
                            return arr;
                        };
                    }
                    // Field is a normal self type
                    else {
                        // Populate the plugin store with the read/write functions and setting initial default state
                        plugin.store.state[fieldName] = (field.options && field.options.default !== undefined) ? field.options.default : null;
                        plugin.store.mutations[fieldName] = (state, value) => {
                            state[fieldName] = value;
                        };
                    }

                    // Fields of route-info type can retrieve data from Vue route meta, params, or query information
                    if (field.source === 'route') {
                        this.store.watch(
                            // State.route is populated on each route change with the Vue route object
                            state => state.route,
                            // On each change of the route state run this function
                            route => {
                                // Pull in data from meta, params, and query
                                const routeInfo = {
                                    ...route.meta,
                                    ...route.params,
                                    ...route.query,
                                };
                                const allRouteParameters = route.params;
                                const fieldValue = routeInfo[fieldName];
                                // If there is a value from the route data then save it to the plugin store
                                if (fieldValue) {
                                    this.store.commit(`${plugin.info.namespace}/${fieldName}`, fieldValue);
                                }
                                // If not then save null to the plugin store
                                else {
                                    this.store.commit(`${plugin.info.namespace}/${fieldName}`, null);
                                }
                            },
                            // Options for the watcher
                            { sync: true },
                        );
                    }
                });
            }

            // Add plugin defined getters to store
            if (modelGetters) {
                _iterObj(modelGetters, (key, g) => {
                    plugin.store.getters[key] = g;
                });
            }

            // Add plugin defined mutations to store
            if (modelMutations) {
                _iterObj(modelMutations, (key, mutator) => {
                    plugin.store.mutations[key] = mutator;
                });
            }

            // Add plugin defined actions to store
            if (modelActions) {
                _iterObj(modelActions, (key, action) => {
                    plugin.store.actions[key] = action;
                });
            }
        }
    }

    // Initialize a plugin field binding and calling functions if needed
    __getPluginField(field, name, defaultValue) {
        if (typeof field[name] === 'function') {
            field[name].bind({ info: field, store: this.store });
            return field[name]();
        }
        if (field[name] !== undefined && field[name] !== null) {
            return field[name];
        }
        return defaultValue;
    }

    // Registers a plugin's store with the application store
    __registerStore(plugin) {
        if (plugin.store) {
            this.store.registerModule(plugin.name, plugin.store);
        }
    }

    // Takes the model and generates a new Vue reactive context for the plugin
    __buildContext(plugin) {
        this.log.info(`Building reactive context for ${plugin.name}`);
        if (plugin.store && plugin.model) {
            // Create a default Vue options which will become reactive
            const reactiveOptions = {
                computed: {},
                methods: {},
            };
            const fields = plugin.model.fields;
            const getters = plugin.model.getters;
            const mutations = plugin.model.mutations;
            const actions = plugin.model.actions;

            // Create reactive computed properties for fields defined in plugin model
            if (fields) {
                const fieldNames = Object.keys(fields);
                fieldNames.forEach(fieldName => {
                    const field = fields[fieldName];
                    if (field.source === 'forge') {
                        let timeMs = 500;
                        if (field.options.debounce > 0) {
                            timeMs = field.options.debounce;
                        }
                        const debouncedDispatch = debounce((value) => {
                            this.store.dispatch(`${plugin.info.namespace}/${fieldName}`, value);
                        }, timeMs);

                        reactiveOptions.computed[fieldName] = {
                            get: () => this.store.getters[`${plugin.info.namespace}/${fieldName}`],
                            set: value => {
                                if (field.options.debounce > 0) {
                                    debouncedDispatch(value);
                                }
                                else {
                                    this.store.dispatch(`${plugin.info.namespace}/${fieldName}`, value);
                                }
                            },
                        };
                        // add resource
                        reactiveOptions.computed[`${fieldName}Loading`] = {
                            get: () => this.store.getters[`${plugin.info.namespace}/${fieldName}Loading`],
                            set: value => {
                                throw new Error(`Cannot set ${fieldName}Loading to ${value} because it is read-only.`);
                            },
                        };
                    }
                    else if (field.source === 'forge-depends') {
                        reactiveOptions.computed[fieldName] = {
                            get: () => this.store.getters[`${plugin.info.namespace}/${fieldName}`],
                        };
                    }
                    else {
                        reactiveOptions.computed[fieldName] = {
                            get: () => this.store.state[plugin.info.namespace][fieldName],
                            set: value => this.store.commit(`${plugin.info.namespace}/${fieldName}`, value),
                        };
                    }
                });
            }

            // Create reactive computed properties for getters defined in plugin model
            if (getters) {
                const getterNames = Object.keys(getters);
                getterNames.forEach(getterName => {
                    reactiveOptions.computed[getterName] = {
                        get: () => this.store.getters[`${plugin.info.namespace}/${getterName}`],
                    };
                });
            }

            // Create methods for mutations defined in plugin model
            if (mutations) {
                const mutationNames = Object.keys(mutations);
                mutationNames.forEach(mutationName => {
                    reactiveOptions.methods[mutationName] = (args) => this.store.commit(`${plugin.info.namespace}/${mutationName}`, args);
                });
            }

            // Create methods for actions defined in the plugin model
            if (actions) {
                const actionNames = Object.keys(actions);
                actionNames.forEach(actionName => {
                    reactiveOptions.methods[actionName] = (args) => this.store.dispatch(`${plugin.info.namespace}/${actionName}`, args);
                });
            }

            // Save this reactive context to the plugin
            plugin.$context = new Vue(reactiveOptions);
            plugin.$context.$store = this.store;
            this.log.info(`Reactive context built for ${plugin.name}:`, reactiveOptions);
        }
        else {
            this.log.info(`No reactive context to build for ${plugin.name}:`);
        }
    }

    // Function which binds a context to all functions of the plugin
    __bindContext(plugin) {
        plugin.modules = this.__bindContextObj(plugin, plugin.modules);
    }

    // Binds the context to all functions recursively through the provided object
    __bindContextObj(plugin, obj) {
        if (typeof obj === 'function') {
            // Add context here!
            const boundFunction = obj.bind(plugin.$context);
            return boundFunction;
        }

        if (Array.isArray(obj)) {
            obj.forEach(el => this.__bindContextObj(plugin, el));
        }
        else if (typeof obj === 'object') {
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    // Replace with bound object
                    obj[key] = this.__bindContextObj(plugin, obj[key]);
                }
            }
        }
        return obj;
    }

    // Registers fragment modules by saving their options to the framework
    __registerFragments(plugin) {
        if (plugin.modules === undefined) return;
        const fragmentModules = plugin.modules.filter(module => module.type === 'fragment');
        fragmentModules.forEach(module => {
            const targetFragments = this.registeredFragments[module.target];
            // Initialize the module by calling the options function
            const initializedFragment = module; // { priority: module.priority, ...module.options() };
            if (targetFragments) {
                targetFragments.push(initializedFragment);
                // Sort the modules based on priority so when rendered they maintain order
                this.registeredFragments[module.target].sort((a, b) => {
                    if (typeof a.priority === 'number' && typeof b.priority === 'number') {
                        return a.priority - b.priority;
                    }
                    // Both priorities not set leave unchanged
                    if (typeof a.priority !== 'number' && typeof b.priority !== 'number') {
                        return 0;
                    }
                    // a is set, b not move a higher
                    if (typeof a.priority === 'number' && typeof b.priority !== 'number') {
                        return -1;
                    }
                    // b is set, a not move b higher
                    return 1;
                });
            }
            else {
                this.registeredFragments[module.target] = [initializedFragment];
            }
        });
    }

    // Registers template modules by building their routes and linking their options as router props
    __registerTemplates(plugin) {
        this.log.info(`Registering routes for ${plugin.name}`);
        if (plugin.modules) {
            const templateModules = plugin.modules.filter(module => module.type === 'template');
            templateModules.forEach(module => {
                if (module.route) {
                    this.__buildTemplateModuleRoute(module, plugin);

                    switch (module.route.type) {
                        case 'petron':
                            this.__registerPetronRoute(module.route);
                            break;
                        case 'root-app':
                            this.__registerRootAppRoute(module.route);
                            break;
                        // if route.type is not defined it is a root route
                        default:
                            this.__registerRootRoute(module.route);
                            break;
                    }
                }
            });
        }
    }

    // Registers globals that can be accessed from other plugins
    __registerGlobals(plugin) {
        this.log.info(`Registering globals for ${plugin.name}`);

        if (plugin.modules) {
            const globalModules = plugin.modules.filter(module => module.type === 'globals');
            globalModules.forEach(module => {
                if (module.target) {
                    const obj = module.options();
                    this.appContext.$globals[module.target] = obj;
                }
            });
        }
    }

    // Registers root level routes from the plugin in the main router
    // TODO: Confirm route has required properties
    __registerRootRoute(rootRoute) {
        this.routes = [rootRoute, ...this.routes];
    }

    // Modifies the plugin route with components and props to be passed to vue router
    __buildTemplateModuleRoute(module, plugin) {
        const component = this.templateMappings[module.target];

        if (!component) {
            throw new Error(`Unknown module template target '${module.target}' passed in plugin definition.`);
        }

        if (!module.route.meta) {
            module.route.meta = {};
        }
        module.route.meta.pluginId = plugin.name;

        // Attach the component to the layout router view
        module.route.components = {
            pageContent: component,
        };

        // Pass props from the plugin to the router component
        module.route.props = {
            pageContent(route) {
                return module.options();
            },
        };
    }

    // Registers petron routes from the plugin by nesting them under /petron/:petronId in the main router
    // TODO: Confirm route has required properties
    __registerPetronRoute(petronRoute) {
        const petronBaseRoute = this.routes.find(route => route.path === '/petron/:petronId');
        // Append petronRoutes to the PetronLayout children array
        // TODO: Protect against overriding previously defined routes
        petronBaseRoute.children[0].children.push(petronRoute);
    }

    // Registers root app routes from the plugin by nesting them under /apps in the main router
    __registerRootAppRoute(rootAppRoute) {
        const rootAppBaseRoute = this.routes.find(route => route.path === '/apps');
        // Append rootAppRoutes to the PageLayout children array
        // TODO: Protect against overriding previously defined routes
        rootAppBaseRoute.children[0].children.push(rootAppRoute);
    }

    // Registers plugin defined vue components
    __registerViews(plugin) {
        this.log.info('Registering views', plugin.views);
        // Ensure that views are passed as an object
        if (plugin.views && (Array.isArray(plugin.views) || typeof plugin.views !== 'object')) {
            this.log.error(`Plugin definition for ${plugin.name} must register views as an object:`, plugin.views);
            return;
        }
        // Register all custom views from the plugin
        for (const key in plugin.views) {
            if (plugin.views.hasOwnProperty(key)) {
                // register the component
                const x = this;
                Vue.component(key, plugin.views[key]);
            }
        }
    }

    // Register all dynamic components from the plugin
    __registerComponents(plugin) {
        if (plugin.components) {
            if (plugin.components) {
                const componentNames = Object.keys(plugin.components);
                componentNames.forEach(componentName => {
                    const component = plugin.components[componentName];
                    if (this.components[componentName]) {
                        this.components[componentName].push(component);
                    }
                    else {
                        this.components[componentName] = [component];
                    }
                });
            }
        }
    }

    // Pass the afterSignIn hook to the auth plugin
    static __registerAfterSignInHook(plugin) {
        if (plugin.afterSignIn) {
            Vue.$auth.afterSignIn('initializeDrillingPlugin', plugin.afterSignIn);
        }
    }
}
