import Vue from 'vue';
import { Logger } from '../logging/logger.js';
import EnvironmentModule from './environment-module.js';
import { ROUTES } from '../../../plugins/data-storage/constants.js';

class EnvironmentService {
    constructor({ store, router }) {
        this.log = new Logger({ name: 'EnvironmentService', level: Logger.levels.warn });
        Vue.$logging.registerLogger(this.log);

        if (!store) {
            this.log.error('Environment plugin must be passed a vuex store.');
            return;
        }

        if (!router) {
            this.log.error('Environment plugin must be passed a vue router.');
            return;
        }

        if (!Vue.$api) {
            this.log.error('Environment plugin requires Auth plugin.');
            return;
        }

        if (!Vue.$api) {
            this.log.error('Environment plugin requires API plugin.');
            return;
        }

        if (!Vue.$notify) {
            this.log.error('Environment plugin requires Notifications plugin.');
            return;
        }

        this.store = store;
        this.router = router;
        this.moduleName = 'EnvironmentService';

        store.registerModule(this.moduleName, EnvironmentModule);

        // User starts already logged in
        if (Vue.$auth.signedIn) {
            this.__initialize();
        }

        Vue.$auth.afterSignIn('initializeEnvironmentService', user => {
            this.__initialize();
        });
    }

    get info() {
        return this.store.state[this.moduleName].info;
    }

    get maxStorage() {
        return this.store.getters[`${this.moduleName}/maxStorage`];
    }

    get usedStorage() {
        return this.store.getters[`${this.moduleName}/usedStorage`];
    }

    get unlimitedStorage() {
        return this.store.getters[`${this.moduleName}/unlimitedStorage`];
    }

    get availableStorage() {
        return this.store.getters[`${this.moduleName}/availableStorage`];
    }

    get fractionUsed() {
        return this.store.getters[`${this.moduleName}/fractionUsed`];
    }

    get overLimit() {
        return this.store.getters[`${this.moduleName}/overLimit`];
    }

    get approachingLimit() {
        return this.store.getters[`${this.moduleName}/approachingLimit`];
    }

    __initialize() {
        this.__updateEnvironmentInfo()
        .then(this.__notifyIfNearingStorageLimit.bind(this));
    }

    __notifyIfNearingStorageLimit() {
        if (this.approachingLimit && !this.overLimit) {
            const formattedAvailableStorage = Vue.$utils.formatting.formatBytes(this.availableStorage);

            Vue.$notify.warning(`Approaching environment data storage limit. ${formattedAvailableStorage} remaining.`, {
                timeout: 0,
                actions: [{
                    label: 'View & Upgrade',
                    color: 'white',
                    handler: () => {
                        this.router.push({ name: ROUTES.DataStorage });
                    },
                }],
            });
        }
    }

    __notifyIfOverStorageLimit() {
        if (this.overLimit) {
            Vue.$notify.error('Exceeded environment storage limit.', {
                actions: [{
                    label: 'View & Upgrade',
                    color: 'white',
                    handler: () => {
                        this.router.push({ name: ROUTES.DataStorage });
                    },
                }],
            });
        }
    }

    __updateEnvironmentInfo() {
        return this.store.dispatch(`${this.moduleName}/getEnvironmentInfo`);
    }
}

export default EnvironmentService;
