import Vue from 'vue';

export class TagsService {
    constructor({ store }) {
        this.store = store;
        this.tagInfoForgeWorkspace = '$root/Hashtags';
        this.tagInfoForgeResourceName = 'HashtagInfo';
        this.tagInfoForgeResource = `${this.tagInfoForgeWorkspace}#${this.tagInfoForgeResourceName}`;

        Vue.$auth.afterSignIn('initializeTagsService', user => {
            this.subscribeToTags();
        });

        Vue.$auth.beforeSignOut('disposeTagsService', () => {
            this.dispose();
        });
    }

    subscribeToTags() {
        this.forgeDisposableReference = Vue.$compute.forgeSubscribeElement('TagsService', this.tagInfoForgeResource);
    }

    dispose() {
        if (this.forgeDisposableReference) {
            this.forgeDisposableReference.dispose();
        }
    }
}
