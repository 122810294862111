import JobResultLogDialog from '../components/JobResultLogsDisplay.vue';

export function JobResultLogDialogFragment({ app, plugin }) {
    return {
        type: 'fragment',
        target: 'dialog',
        priority: 1,
        options() {
            return {
                model: this.showLogDialog,
                props: {
                    title: 'View Log',
                    sections: [
                        {
                            type: 'custom',
                            componentType: JobResultLogDialog,
                            props: {
                                jobResultsId: this.jobResultId,
                            },
                        },
                    ],
                    actionButtons: [
                        {
                            props: {
                                label: 'Close',
                                tooltip: 'Close the log',
                                flat: true,
                                outline: false,
                                color: 'primary',
                                testId: 'JobResultLogDialog_Close_Button',
                            },
                            events: {
                                click: () => {
                                    this.showLogDialog = false;
                                },
                            },
                        },
                    ],
                },
                events: {
                    input: (value) => {
                        this.showLogDialog = value;
                    },
                },
            };
        },
    };
}
