<template>
    <PDialog
        v-model="display"
        :title="title"
        :ok-btn-text="okBtnText"
        :ok-btn-tooltip="okBtnTooltip"
        @ok="applySetting"
    >
        <q-select
            v-model="selectedSettingsDoc"
            :options="settingsDocsOptions"
            :label="`Search for ${displayName.toLowerCase()}`"
            class="q-pb-sm"
            :option-label="opt => opt === null ? 'null' : `${opt.name} (${opt.scope})`"
            use-input
            filled
            dense
            options-dense
            clearable
            @filter="settingsDocsOptionsFilter"
        >
            <template v-slot:no-option>
                <q-item>
                    <q-item-section class="text-grey">
                        No results
                    </q-item-section>
                </q-item>
            </template>
        </q-select>
    </PDialog>
</template>

<script>
export default {
    name: 'LoadSettingsDialog',
    props: {
        // v-model prop to show or hide the modal
        value: {
            type: Boolean,
            required: true,
        },
        // Type of setting used in labels and tooltips
        displayName: {
            type: String,
            required: true,
        },
        // List of all settings docs that can be applied
        settingsDocs: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            selectedSettingsDoc: null,
            settingsDocsOptions: [],
        };
    },
    computed: {
        display: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            },
        },
        title() {
            return `Load Saved ${this.displayName}`;
        },
        okBtnText() {
            return `Apply ${this.displayName}`;
        },
        okBtnTooltip() {
            return `Apply ${this.displayName}`;
        },
    },
    created() {
        this.settingsDocsOptions = this.settingsDocs;
    },
    methods: {
        applySetting() {
            // Only emit apply when a setting is selected
            if (this.selectedSettingsDoc) {
                this.$emit('apply', this.selectedSettingsDoc);
                this.$notify.info(`${this.selectedSettingsDoc.name} applied.`);
                this.selectedSettingsDoc = null;
            }
            else {
                this.$notify.error('No setting selected.');
                this.selectedSettingsDoc = null;
            }
        },
        settingsDocsOptionsFilter(value, update) {
            if (value === '') {
                update(() => {
                    this.settingsDocsOptions = this.settingsDocs;
                });
                return;
            }
            update(() => {
                this.settingsDocsOptions = this.settingsDocs.filter(option => option.name.toLowerCase().includes(value.toLowerCase()) || option.scope.toLowerCase().includes(value.toLowerCase()));
            });
        },
    },
};
</script>

<style scoped>

</style>
