<template>
    <q-input
        v-model="searchText"
        v-cypress="'AppHeader_Search_Input'"
        placeholder="Search"
        color="white"
        filled
        dark
        dense
        @keypress.enter="performSearch"
    >
        <template v-slot:prepend>
            <q-icon
                v-if="!searchText"
                name="search"
            />
            <q-icon
                v-if="searchText"
                name="clear"
                class="cursor-pointer"
                @click="searchText = ''"
            />
        </template>
    </q-input>
</template>

<script>
export default {
    name: 'AppHeaderSearchInput',
    data() {
        return {
            searchText: '',
        };
    },
    watch: {
        $route: {
            immediate: true,
            handler(newRoute) {
                // Update the search box based on route changes, i.e. someone links to search page
                if (newRoute && newRoute.name === 'search') {
                    // Only update if query exists
                    if (newRoute.query && newRoute.query.text) {
                        this.searchText = newRoute.query.text;
                    }
                    else {
                        this.searchText = '';
                    }
                }
            },
        },
    },
    methods: {
        performSearch() {
            this.$router.push({ name: 'search', query: { text: this.searchText.trim() } });
        },
    },
};
</script>

<style scoped>

</style>
