<template>
    <span v-if="dateTime">{{ $utils.formatting.timeAgo(dateTime) }}</span>
</template>

<script>
export default {
    name: 'PTimeAgo',
    props: {
        dateTime: {
            type: String,
            required: false,
            default: null,
        },
    },
};
</script>

<style scoped>

</style>
