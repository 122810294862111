<template>
    <ItemActionsBar
        :action-buttons="displayButtons"
    />
</template>

<script>
import ItemActionsBar from '../../components/ItemActionsBar';

export default {
    name: 'WellActionsBar',
    components: {
        ItemActionsBar,
    },
    props: {
        profileUrl: {
            type: String,
            required: false,
            default: null,
        },
        viewingSelf: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            userButtons: [
                {
                    id: 0,
                    tooltip: 'Edit your profile on the portal',
                    click: this.navigateToPortal,
                    text: 'Edit on Portal',
                    icon: 'open_in_new',
                },
            ],
        };
    },
    computed: {
        displayButtons() {
            return this.viewingSelf ? this.userButtons : [];
        },
    },
    methods: {
        navigateToPortal() {
            window.open(this.profileUrl);
        },
    },
};
</script>

<style scoped>
</style>
