export class ApiControllerForge {
    constructor({ axiosInstance, route } = {}) {
        // Configured base axios instance
        this.axiosInstance = axiosInstance;

        // Route appended to the axios instance baseURL
        this.route = route;
    }

    // Run a forge action
    runAction({ action, options } = {}) {
        // Ensure that action is provided
        if (!action) {
            return Promise.reject(new Error('runAction must be provided a action'));
        }

        return this.axiosInstance.post(`${this.route}/action`, {
            action,
            options,
        });
    }
}
