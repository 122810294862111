import Vue from 'vue';

const FilterModule = {
    namespaced: true,
    state: {
        filterPanelState: null,
    },
    mutations: {},
    getters: {
        pageFilterFragments: (state, getters, router) => {
            const pageFragments = Vue.$pai.getFragments('page-filter');
            if (!pageFragments || !router || !router.route || !router.route.name) {
                return [];
            }
            const currentRouteName = router.route.name;
            return pageFragments.filter(fragment => {
                if (!fragment.props || !fragment.props.routes) {
                    return false;
                }
                return !fragment.props.hide && fragment.props.routes.includes(currentRouteName);
            });
        },
        routeIndexDefinitions: (state, getters) => getters.pageFilterFragments.map(pageFragment => pageFragment.props.indexData),
    },
    actions: {
        resetAllFilters({ getters, dispatch }) {
            getters.pageFilterFragments.forEach((fragment) => {
                fragment.props.indexData.forEach((indexDef) => {
                    const workspace = indexDef.workspace;
                    const collection = indexDef.collection;
                    const indexName = indexDef.name;
                    const filters = indexDef.filters.filter(filter => filter.filterSet);
                    if (filters.length) {
                        dispatch('resetCollectionFilters', {
                            workspace, collection, indexName, filters,
                        });
                    }
                });
            });
        },
        resetCollectionFilters({ getters }, {
            workspace, collection, indexName, filters, events,
        }) {
            const filterQuery = filters.map(filter => {
                return {
                    schema: filter.name,
                };
            });
            const options = {
                workspace,
                collection,
                name: indexName,
                filterQuery: JSON.stringify(filterQuery),
            };
            const beforeEvent = events ? events.beforeResetAllFilters : null;
            if (beforeEvent) {
                if (beforeEvent(options) === false) {
                    return;
                }
            }
            Vue.$api.forge.runAction({
                action: 'ClearFilter',
                options,
            })
            .then(() => {
                const afterEvent = events ? events.afterResetAllFilters : null;
                if (afterEvent) {
                    afterEvent(options);
                }
            })
            .catch(error => {
                if (events) {
                    Vue.$notify.error(`There was an error clearing the filter: ${error.message}`);
                }
            });
        },
        setCollectionFilters({ getters }, {
            workspace, collection, indexName, filters,
        }) {
            // TODO: Figure out a better way to do this to avoid string manipulation
            const schemata = filters.map(filter => {
                const valuesQuery = filter.filter.values ? `, values: ['${filter.filter.values.join('\',\'')}']`
                    : `, range: ['${filter.filter.low}', '${filter.filter.high}']`;
                return `{ schema: '${filter.name}'${valuesQuery} }`;
            });
            const filterQuery = `[${schemata.join(', ')}]`;
            const options = {
                workspace,
                collection,
                name: indexName,
                filterQuery,
            };
            Vue.$api.forge.runAction({
                action: 'SetFilter',
                options,
            }).catch(error => {
                Vue.$notify.error(`There was an error setting the filter: ${error.message}`);
            });
        },
        updateIndexDefinitions({ getters, dispatch }, { indexDefinitions }) {
            indexDefinitions.forEach(fragment => {
                fragment.forEach(indexDef => {
                    // If the new values are coming from saved settings, the workspaces might not match.
                    // Find matching local definition, to use that workspace.
                    const match = getters.routeIndexDefinitions.flat().find(definedIndex => definedIndex.name === indexDef.name && definedIndex.collection === indexDef.collection);
                    if (!match) {
                        return;
                    }
                    const workspace = match.workspace;
                    const collection = indexDef.collection;
                    const indexName = indexDef.name;
                    const unsetFilters = indexDef.filters.filter(filter => !filter.filterSet);
                    if (unsetFilters.length) {
                        dispatch('resetCollectionFilters', {
                            workspace, collection, indexName, filters: unsetFilters,
                        });
                    }
                    const setFilters = indexDef.filters.filter(filter => filter.filterSet);
                    if (setFilters.length) {
                        dispatch('setCollectionFilters', {
                            workspace, collection, indexName, filters: setFilters,
                        });
                    }
                });
            });
        },
    },
};

// Dynamically add all the mutation setters for state.
Object.keys(FilterModule.state).forEach(stateKey => {
    FilterModule.mutations[stateKey] = (state, value) => {
        state[stateKey] = value;
    };
});

export default FilterModule;
