<template>
    <div>
        <q-card class="q-mt-lg">
            <q-card-section class="q-py-sm q-px-md">
                <div class="row items-center">
                    <span>
                        {{ $users.getUserFullName(searchResult.userId) }} {{ searchResult.verb }} in the {{ searchResult.pluginId | spaces | capitalize }} of <router-link :to="{ name: 'petron-overview', params: { petronId: searchResult.pid, } }">{{ searchResult.petronName }}</router-link> petron.
                    </span>
                    <q-space/>
                    <GeneralButton
                        v-if="canResolveActivity"
                        tooltip="Go to activity"
                        icon="fa fa-chevron-right"
                        @click="resolveActivity"
                    />
                </div>
            </q-card-section>
        </q-card>
        <q-card>
            <Comment
                :comment="searchResult.document"
                hide-comment-actions
                hide-comment-menu
                hide-comment-info
            />
        </q-card>
    </div>
</template>

<script>
import Comment from '../comments/Comment.vue';
import GeneralButton from '../inline-elements/GeneralButton.vue';

export default {
    name: 'CommentSearchResult',
    components: {
        Comment,
        GeneralButton,
    },
    props: {
        searchResult: {
            type: Object,
            required: true,
        },
    },
    computed: {
        canResolveActivity() {
            return this.searchResult && this.searchResult.document && this.searchResult.document.pluginId;
        },
    },
    methods: {
        resolveActivity() {
            this.$pai.triggerPluginAction('resolveComment', [this.searchResult.document.pluginId], this.searchResult.document);
        },
    },
};
</script>

<style scoped>

</style>
