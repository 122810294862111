<template>
    <q-input
        v-model="displayDateTime"
        v-cypress="testId"
        filled
        dense
        :label="`${label} (${displayTimeZone})`"
        :hint="hint"
        :disable="disable"
        readonly
        :dark="dark"
    >
        <template
            v-if="!readonly"
            v-slot:prepend
        >
            <q-icon
                name="event"
                class="cursor-pointer"
            >
                <q-popup-proxy
                    transition-show="scale"
                    transition-hide="scale"
                >
                    <q-date
                        v-model="displayDateTime"
                        :mask="displayFormat"
                        :options="dateOptions"
                        today-btn
                    />
                </q-popup-proxy>
            </q-icon>
        </template>
        <template
            v-if="!readonly"
            v-slot:append
        >
            <q-icon
                name="access_time"
                class="cursor-pointer"
            >
                <q-popup-proxy
                    transition-show="scale"
                    transition-hide="scale"
                >
                    <q-time
                        v-model="displayDateTime"
                        :mask="displayFormat"
                    />
                </q-popup-proxy>
            </q-icon>
            <q-icon
                v-if="clearable"
                name="cancel"
                @click="displayDateTime = null"
            />
        </template>
    </q-input>
</template>

<script>
import moment from 'moment-timezone';

export default {
    name: 'AutoFormDateTimeInput',
    props: {
        // UTC date time
        value: {
            required: true,
            validator(value) {
                return typeof value === 'string' || value === null;
            },
        },
        testId: {
            type: String,
            required: false,
            default: null,
        },
        // IANA Time zone string to display time in
        displayTimeZone: {
            required: true,
            validator(value) {
                return typeof value === 'string' || value === null;
            },
        },
        label: {
            type: String,
            required: true,
        },
        hint: {
            type: String,
            required: false,
            default: null,
        },
        debounce: {
            type: Number,
            required: true,
        },
        clearable: {
            type: Boolean,
            required: true,
        },
        disable: {
            type: Boolean,
            required: true,
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false,
        },
        dateOptions: {
            type: Function,
            required: false,
            default: () => true,
        },
        dark: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            displayFormat: 'h:mm A, MMMM D, YYYY',
        };
    },
    computed: {
        // Transform display time to/from display time to UTC
        displayDateTime: {
            get() {
                if (this.value && this.displayTimeZone) {
                    const dateTimeInTimeZone = moment(this.value).tz(this.displayTimeZone).format(this.displayFormat);
                    return dateTimeInTimeZone;
                }
                return null;
            },
            set(value) {
                if (value !== null) {
                    const utcStringDateTime = moment.tz(value, this.displayFormat, this.displayTimeZone).toISOString();
                    this.$emit('input', utcStringDateTime);
                }
                else {
                    this.$emit('input', null);
                }
            },
        },
    },
};
</script>

<style scoped>

</style>
