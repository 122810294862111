<template>
    <q-page
        v-if="petron && petron.id === petronId && forgeResourcesLoaded"
        class="bg-grey-3"
    >
        <div class="row q-px-md q-col-gutter-x-md">
            <div class="col-12 col-md-3 q-pt-md">
                <PCard>
                    <div class="q-px-lg q-pt-lg">
                        <div class="items-center row no-wrap">
                            <div class="text-primary text-bold text-h6 ellipsis">
                                {{ petron.name }}
                                <q-tooltip :delay="750">
                                    {{ petron.name }}
                                </q-tooltip>
                            </div>
                            <q-space/>
                            <GeneralButton
                                v-cypress="'ThePOverviewPage_TogglePublic_Button'"
                                :icon="`fa ${petron.isPublic ? 'fa-lock-open' : 'fa-lock'}`"
                                :tooltip="`Make this Petron ${petron.isPublic ? 'private' : 'public'}`"
                                flat
                                dense
                                :color="petron.isPublic ? 'primary' : 'grey-8'"
                                :disabled="!canEdit"
                                @click.stop="togglePublic"
                            />
                            <GeneralButton
                                v-if="isFavorite"
                                v-cypress="'ThePOverviewPage_Unfavorite_Button'"
                                icon="star"
                                tooltip="Remove from favorites"
                                flat
                                dense
                                color="primary"
                                @click.stop="removeFromFavoritesFunc(petron.id)"
                            />
                            <GeneralButton
                                v-else
                                v-cypress="'ThePOverviewPage_Favorite_Button'"
                                icon="star_border"
                                tooltip="Add to favorites"
                                flat
                                dense
                                color="grey-8"
                                @click.stop="addToFavoritesFunc(petron.id)"
                            />
                            <GeneralButton
                                v-cypress="'ThePOverviewPage_Settings_Button'"
                                icon="settings"
                                :tooltip="`${canEdit ? 'Open Petron settings' : 'Only the owner or an admin can edit this Petron'}`"
                                flat
                                dense
                                color="grey-8"
                                @click.stop="showEditDialog = true"
                            />
                        </div>
                        <div class="text-grey-8 petron-description ellipsis-2-lines">
                            {{ petron.description }}
                            <q-tooltip :delay="750">
                                {{ petron.description }}
                            </q-tooltip>
                        </div>
                    </div>
                    <q-scroll-area
                        ref="petronFeedScrollArea"
                        class="collaborator-scroll"
                        :style="collaboratorListStyle"
                    >
                        <CollaboratorList
                            :petron="petron"
                            :collaborators="petronCollaborators"
                            :can-edit="canEdit"
                            class="q-px-lg"
                            @remove="confirmRemoveCollaborator"
                        />
                    </q-scroll-area>
                    <div class="q-pa-md text-center">
                        <GeneralButton
                            v-if="canEdit"
                            v-cypress="'ThePOverviewPage_AddCollaborators_Button'"
                            label="Add Collaborators"
                            tooltip="Add collaborators to this Petron"
                            icon="add_circle"
                            :flat="false"
                            color="grey-8"
                            @click="showAddCollaboratorsDialog = true"
                        />
                    </div>
                    <GeneralButton
                        v-if="$refs.petronFeedScrollArea && $refs.petronFeedScrollArea.getScrollPosition()"
                        fab
                        :flat="false"
                        :outline="false"
                        icon="fa fa-chevron-up"
                        color="primary"
                        tooltip="Go to top"
                        class="scroll-button-sticky"
                        @click="$refs.petronFeedScrollArea.setScrollPosition(0)"
                    />
                </PCard>
            </div>
            <div
                class="col-12 col-md-3 q-pt-md"
            >
                <PCard>
                    <div class="col q-py-lg">
                        <div class="text-primary text-bold text-h6">
                            Recent Work
                            <GeneralButton
                                icon="refresh"
                                tooltip="Get latest work"
                                @click="loadWorkItems"
                            />
                        </div>
                        <div class="work-scroll">
                            <q-scroll-area
                                v-if="workItems && workItems.length"
                                ref="workFeedScrollArea"
                                class="fit"
                            >
                                <WorkItem
                                    v-for="(item, index) in workItems"
                                    :key="`work-${index}`"
                                    :item="item"
                                    class="q-ma-sm"
                                />
                            </q-scroll-area>
                            <div
                                v-else-if="workItems && workItems.length === 0"
                                class="text-grey-9 q-py-sm"
                            >
                                <p>Choose an app from the dropdown menu in the top bar to start working inside this Petron.</p>
                            </div>
                            <div
                                v-else
                                class="text-center"
                            >
                                <q-spinner
                                    size="30px"
                                    color="primary"
                                />
                                <p>Loading work...</p>
                            </div>
                        </div>
                    </div>
                    <GeneralButton
                        v-if="$refs.workFeedScrollArea && $refs.workFeedScrollArea.getScrollPosition()"
                        fab
                        :flat="false"
                        :outline="false"
                        icon="fa fa-chevron-up"
                        color="primary"
                        tooltip="Go to top"
                        class="scroll-button-sticky"
                        @click="$refs.workFeedScrollArea.setScrollPosition(0)"
                    />
                </PCard>
            </div>
            <div class="col-12 col-md-6 q-pt-md">
                <PCard>
                    <div class="q-py-lg text-primary text-bold text-h6 text-center">
                        Recent Activity
                        <GeneralButton
                            icon="refresh"
                            tooltip="Get latest activity"
                            @click="loadFeedItems"
                        />
                    </div>
                    <div class="activity-scroll">
                        <q-scroll-area
                            v-if="feedItems"
                            ref="activityFeedScrollArea"
                            class="fit"
                        >
                            <ActivityFeed
                                :items="feedItems"
                                :total-items="totalFeedItems"
                                :comment-petron-id="petronId"
                                :comment-petron-name="petron.name"
                                no-activity-message="This Petron has no recent activity."
                                @reload="loadFeedItems"
                                @load-more="loadMoreItems"
                                @item-added="addFeedItem"
                                @item-updated="updateFeedItem"
                                @item-deleted="removeFeedItem"
                                @add-reaction="addReaction"
                                @remove-reaction="removeReaction"
                            />
                        </q-scroll-area>
                        <div
                            v-else
                            class="text-center"
                        >
                            <q-spinner
                                size="30px"
                                color="primary"
                            />
                            <p>Loading activity...</p>
                        </div>
                    </div>
                    <GeneralButton
                        v-if="$refs.activityFeedScrollArea && $refs.activityFeedScrollArea.getScrollPosition()"
                        fab
                        :flat="false"
                        :outline="false"
                        icon="fa fa-chevron-up"
                        color="primary"
                        tooltip="Go to top"
                        class="scroll-button-sticky"
                        @click="$refs.activityFeedScrollArea.setScrollPosition(0)"
                    />
                </PCard>
            </div>
        </div>
        <PetronEditDialog
            v-model="showEditDialog"
            :petron="petron"
            @delete="deletePetron"
        />
        <AddCollaboratorsDialog
            v-model="showAddCollaboratorsDialog"
            :petron="petron"
            :collaborators="petronCollaborators"
        />
    </q-page>
    <q-page v-else>
        <ForgeLoadState
            :resources="forgeResourceElements"
        />
    </q-page>
</template>

<script>
import {
    mapActions,
    mapGetters, mapState,
} from 'vuex';
import mixins from '../../../mixins';
import ForgeLoadState from '../../../plugin-framework/shared-components/ForgeLoadState.vue';
import ActivityFeed
    from '../../../plugin-framework/shared-components/activity-feed/ActivityFeed.vue';
import PetronEditDialog from '../components/PetronEditDialog.vue';
import CollaboratorList from '../components/CollaboratorList.vue';
import AddCollaboratorsDialog from '../components/AddCollaboratorsDialog.vue';
import WorkItem from '../../../plugin-framework/shared-components/work-feed/WorkItem.vue';
import GeneralButton from '../../../plugin-framework/shared-components/inline-elements/GeneralButton.vue';

export default {
    name: 'ThePOverviewPage',
    components: {
        AddCollaboratorsDialog,
        CollaboratorList,
        ActivityFeed,
        ForgeLoadState,
        PetronEditDialog,
        WorkItem,
        GeneralButton,
    },
    mixins: [mixins.compute],
    data() {
        return {
            feedItems: null,
            totalFeedItems: 0,
            feedFromDate: new Date(),
            feedItemsSkip: 0,
            feedItemsLimit: 5,
            workItems: null,
            showEditDialog: false,
            showAddCollaboratorsDialog: false,
        };
    },
    computed: {
        ...mapState('Petron', {
            petronId: 'petronId',
        }),
        ...mapGetters('Petron', {
            petron: 'petron',
            petronUnits: 'petronUnits',
            petronCollaborators: 'petronCollaborators',
            petronForgeResources: 'forgeResources',
        }),
        ...mapGetters('Library', {
            favoritePetrons: 'favoritePetrons',
            favoritePetronsResource: 'favoritePetronsResource',
        }),
        collaboratorListStyle() {
            const calculatedHeight = (this.petronCollaborators.length + 1) * 65;
            return {
                height: `${calculatedHeight}px`,
            };
        },
        forgeResources() {
            return [
                ...this.petronForgeResources,
                this.favoritePetronsResource,
            ];
        },
        userIsPetronOwner() {
            return this.petron.ownerId === this.$auth.user.userId;
        },
        canEdit() {
            return this.userIsPetronOwner || this.$auth.userIsAdmin;
        },
        isFavorite() {
            return this.favoritePetrons.find(favorite => favorite.id === this.petron.id);
        },
    },
    watch: {
        // Must watch petron to ensure that is present before making API call to get activity feed
        petron: {
            immediate: true,
            handler(newValue) {
                if (newValue) {
                    this.loadFeedItems();
                    this.loadWorkItems();
                }
            },
        },
    },
    methods: {
        ...mapActions('Library', {
            addToFavoritesFunc: 'addToFavorites',
            removeFromFavoritesFunc: 'removeFromFavorites',
            removePetronFunc: 'removePetron',
        }),
        // TODO: Generalize this duplicated code into a common Vue 3 paradigm
        getFeedItems({ fromDate = this.feedFromDate, skip = this.feedItemsSkip, limit = this.feedItemsLimit } = {}) {
            return this.$api.tasks.runTask({
                runInBackground: false,
                taskType: 'RenderActivityFeed',
                options: {
                    fromDate,
                    userId: null,
                    pluginId: null,
                    pid: this.petron.id,
                    skip,
                    limit,
                },
            })
            .then(response => {
                if (response.data) {
                    return response;
                }

                throw new Error('response contained no data');
            })
            .catch(error => {
                this.$notify.error(`Unable to load activity feed items: ${error.message}`);
            });
        },
        loadFeedItems() {
            // Reset to initial state
            this.feedFromDate = new Date();
            this.feedItemsSkip = 0;

            this.getFeedItems()
            .then(response => {
                this.feedItems = response.data;
                this.totalFeedItems = response.total;
            });
        },
        loadMoreItems(index, done) {
            if (this.feedItems.length < this.totalFeedItems) {
                this.feedItemsSkip += this.feedItemsLimit;
                this.getFeedItems()
                .then(response => {
                    this.feedItems.push(...response.data);
                    this.totalFeedItems = response.total;
                    done();
                });
            }
            else {
                done();
            }
        },
        addFeedItem({ item, parentItem }) {
            if (parentItem) {
                if (!parentItem.thread.replies) {
                    parentItem.thread.replies = [];
                }
                parentItem.thread.replies.unshift(item);
                return;
            }
            this.feedItems.unshift(item);
            this.totalFeedItems += 1;
        },
        removeFeedItem({ id, parentId }) {
            if (parentId) {
                const parent = this.feedItems.find(item => item.thread.comment.id === parentId);
                if (parent) {
                    const replyIdx = parent.thread.replies.findIndex(reply => reply.comment.id === id);
                    if (replyIdx > -1) {
                        parent.thread.replies.splice(replyIdx, 1);
                    }
                }
                return;
            }
            const commentIdx = this.feedItems.findIndex(item => item.thread.comment.id === id);
            if (commentIdx > -1) {
                this.feedItems.splice(commentIdx, 1);
                this.totalFeedItems -= 1;
            }
        },
        updateFeedItem({ comment, parentId }) {
            let originalComment;
            if (parentId) {
                const parent = this.feedItems.find(item => item.thread.comment.id === parentId);
                if (parent) {
                    originalComment = parent.thread.replies.find(item => item.comment.id === comment.id);
                }
            }
            else {
                const originalItem = this.feedItems.find(item => item.thread.comment.id === comment.id);
                if (originalItem) {
                    originalComment = originalItem.thread.comment;
                }
            }
            if (originalComment) {
                originalComment.richContent = comment.richContent;
                originalComment.mentionIds = comment.mentionIds;
                originalComment.updatedAt = new Date().toISOString();
            }
        },
        addReaction({ reaction, parentId }) {
            let matchingItem;
            if (parentId) {
                const parent = this.feedItems.find(item => item.thread.comment.id === parentId);
                if (parent) {
                    matchingItem = parent.thread.replies.find(item => item.comment.id === reaction.parentCommentId);
                }
            }
            else {
                const originalItem = this.feedItems.find(item => item.thread.comment.id === reaction.parentCommentId);
                if (originalItem) {
                    matchingItem = originalItem.thread;
                }
            }
            if (matchingItem) {
                if (!matchingItem.reactions) {
                    matchingItem.reactions = [];
                }
                matchingItem.reactions.push(reaction);
            }
        },
        removeReaction({ reaction, parentId }) {
            let matchingItem;
            if (parentId) {
                const parent = this.feedItems.find(item => item.thread.comment.id === parentId);
                if (parent) {
                    matchingItem = parent.thread.replies.find(item => item.comment.id === reaction.parentCommentId);
                }
            }
            else {
                const originalItem = this.feedItems.find(item => item.thread.comment.id === reaction.parentCommentId);
                if (originalItem) {
                    matchingItem = originalItem.thread;
                }
            }
            if (matchingItem) {
                const matchingIndex = matchingItem.reactions.findIndex(reactionItem => reactionItem.id === reaction.id);
                if (matchingIndex > -1) {
                    matchingItem.reactions.splice(matchingIndex, 1);
                }
            }
        },
        loadWorkItems() {
            return this.$api.tasks.runTask({
                runInBackground: false,
                taskType: 'GetRecentProjectActivity',
                options: {
                    petronId: this.petron.id,
                },
            })
            .then(response => {
                this.workItems = response.data;
            })
            .catch(error => {
                this.$notify.error(`Unable to load work items: ${error.message}`);
            });
        },
        confirmRemoveCollaborator(user) {
            const name = user.fullName || user.email || user.userId;
            this.$notify.confirm(`Do you want to remove collaborator "${name}" from the Petron?`, {
                title: 'Remove Collaborator',
                okButtonText: 'Remove Collaborator',
                okButtonTooltip: 'Remove the collaborator from the Petron',
                cancelButtonTooltip: 'Do not remove the collaborator',
                onOk: (closeCallback) => {
                    closeCallback(true);
                    this.removeCollaborator(user.userId);
                },
            });
        },
        removeCollaborator(userId) {
            this.$api.tasks.runTask({
                runInBackground: false,
                taskType: 'RemoveCollaborator',
                options: {
                    petronId: this.petron.id,
                    collaborators: [userId],
                },
            })
            .then(() => {
                this.$notify.success('Collaborator removed from Petron.');
            })
            .catch((issue) => {
                this.$logging.loggers.PluginFramework.error(`Could not remove collaborator from Petron ${this.petron.id}: ${issue}`);
                this.$notify.error('Unable to remove collaborator.');
            });
        },
        deletePetron() {
            this.showEditDialog = false;
            this.removePetronFunc({
                petronToRemove: this.petron,
                onSuccess: () => {
                    this.$router.push({ name: 'home' });
                },
            });
        },
        togglePublic() {
            const wasPublic = this.petron.isPublic;
            this.$api.data.update({
                type: 'Petron',
                query: { id: this.petron.id },
                body: {
                    isPublic: !wasPublic,
                },
            }).then(() => {
                this.$notify.success(`This Petron is now ${wasPublic ? 'private' : 'public'}`);
            }).catch((issue) => {
                this.$logging.loggers.PluginFramework.error(`Could not toggle isPublic for Petron '${this.petron.id}': ${issue}`);
            });
        },
    },
};
</script>

<style scoped lang="stylus">
@import '../../../css/petro.styl';
.overview-page__row {
    /* App header + petron header */
    height: "calc(100vh - 65px - %s)" % $app-header-height;
}
.petron-description {
    height: 40px;
}
.activity-scroll {
    height: "calc(100vh - %s - 120px)" % $app-header-height;
}
.collaborator-scroll {
    max-height: "calc(100vh - %s - 195px)" % $app-header-height;
}
.work-scroll {
    height: "calc(100vh - %s - 120px)" % $app-header-height;
}
.scroll-button-sticky {
    position: absolute;
    bottom: 40px;
    right: 18px;
    opacity: 30%;
    &:hover {
        opacity: inherit;
    }
}
</style>
