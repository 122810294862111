<template>
    <DropdownButton
        v-if="button.type === 'drop-down'"
        v-bind="button.props"
        v-on="button.events"
    />
    <MenuButton
        v-else-if="button.type === 'menu'"
        v-bind="button.props"
        v-on="button.events"
    />
    <GeneralButton
        v-else
        v-cypress="button.props.testId"
        v-bind="button.props"
        v-on="button.events"
    />
</template>

<script>
import DropdownButton from './DropdownButton.vue';
import GeneralButton from './GeneralButton.vue';
import MenuButton from './MenuButton.vue';

export default {
    name: 'OmniButton',
    components: {
        DropdownButton,
        GeneralButton,
        MenuButton,
    },
    props: {
        button: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style scoped>

</style>
