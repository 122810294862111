import Vue from 'vue';
import { Logger } from '../logging/logger.js';

export class UnitsService {
    constructor({ store }) {
        this.store = store;
        this.log = new Logger({ name: 'UnitsService', level: Logger.levels.warn });
        Vue.$logging.registerLogger(this.log);
        Vue.$auth.afterSignIn('initializeUnitsService', user => {
            this.__loadUnits();
            this.__loadReportingUnitsDefinition();
        });
    }

    get unitOptions() {
        return this.store.state.unitsService.unitOptions;
    }

    set unitOptions(options) {
        this.store.commit('unitsService/setUnitOptions', options);
    }

    get reportingUnitsDefinition() {
        return this.store.state.unitsService.reportingUnitsDefinition;
    }

    set reportingUnitsDefinition(unitsDefinition) {
        this.store.commit('unitsService/setReportingUnitsDefinition', unitsDefinition);
    }

    __loadUnits() {
        // Get all possible units information
        Vue.$api.tasks.runTask({
            runInBackground: false,
            taskType: 'UnitsInformation',
            options: {},
        })
        .then((response) => {
            const unitOptions = {};
            Object.values(response.data[0]).forEach(unitInformation => {
                const key = unitInformation.name;
                const units = unitInformation.units;
                unitOptions[key] = units.map((unitOption) => {
                    return {
                        value: unitOption.abbreviation,
                        label: `${unitOption.abbreviation} (${unitOption.name})`,
                    };
                });
            });
            this.unitOptions = unitOptions;
        })
        .catch((error) => {
            this.log.error('Could not load units information:', error);
        });
    }

    __loadReportingUnitsDefinition() {
        // Get the reporting units definition
        Vue.$api.data.query({
            type: 'AppSetting',
            query: {},
        })
        .then(appSettingResponse => {
            if (appSettingResponse && appSettingResponse.data && appSettingResponse.data[0]) {
                const reportingUnitsDefinitionId = appSettingResponse.data[0].reportingUnitsDefinitionId;
                return Vue.$api.data.query({
                    type: 'UnitsDefinition',
                    query: { id: reportingUnitsDefinitionId },
                });
            }

            throw new Error('AppSetting response contained no data');
        })
        .then(unitsDefinitionResponse => {
            if (unitsDefinitionResponse && unitsDefinitionResponse.data && unitsDefinitionResponse.data[0]) {
                const reportingUnitsDefinition = unitsDefinitionResponse.data[0];
                this.reportingUnitsDefinition = reportingUnitsDefinition;
            }
            else {
                throw new Error('UnitsDefinitions response contained no data');
            }
        })
        .catch(error => {
            this.log.error('Could not load reporting units definition:', error);
        });
    }
}
