<template>
    <q-td class="text-left">
        <UserChip
            v-if="context.value"
            :user-id="context.value"
        />
    </q-td>
</template>

<script>
export default {
    name: 'UserColumn',
    props: {
        context: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style scoped>

</style>
