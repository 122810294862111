export function UnitDefinitionEditDialog({ app, plugin }) {
    return {
        type: 'fragment',
        target: 'dialog',
        priority: 1,
        options() {
            return {
                model: this.showEditDialog,
                props: {
                    title: `Edit: ${this.editDefinitionPage.name}`,
                    sections: [
                        {
                            type: 'auto-form',
                            props: {
                                value: this.editDefinitionPage,
                                initialValue: {},
                                testId: 'EditUnitDefinitionDialog_Form',
                                schema: {
                                    dense: true,
                                    inputs: this.unitInputSchema,
                                },
                            },
                            events: {
                                input: (value) => {
                                    this.editDefinitionPage = value;
                                },
                                validate: (value) => {
                                    this.editDefinitionPageIsValid = value;
                                },
                            },
                        },
                    ],
                    actionButtons: [
                        {
                            props: {
                                label: 'Cancel',
                                tooltip: 'Undo any changes',
                                flat: true,
                                outline: false,
                                color: 'primary',
                            },
                            events: {
                                click: () => {
                                    this.showEditDialog = false;
                                },
                            },
                        },
                        {
                            props: {
                                label: 'Save',
                                tooltip: 'Save units definition',
                                flat: false,
                                outline: false,
                                color: 'primary',
                                testId: 'EditUnitDefinitionDialog_Save_Button',
                            },
                            events: {
                                click: () => {
                                    this.saveUnitDefinition();
                                    this.showEditDialog = false;
                                },
                            },
                        },
                    ],
                },
                events: {
                    input: (value) => {
                        this.showEditDialog = value;
                    },
                },
            };
        },
    };
}
