<template>
    <GeneralButton
        v-cypress="'AppJobsButton_RunningJobs_Button'"
        dense
        :tooltip="backgroundJobPanelOpen ? 'Hide jobs' : 'Jobs'"
        :text-color="backgroundJobPanelOpen ? 'accent' : ''"
        :disabled="!forgeResourcesLoaded"
        size="lg"
        @click="toggleBackgroundJobPanel"
    >
        <q-icon
            name="mdi-pinwheel-outline"
            :class="{'fa-spin': userHasRunningJobs}"
        />
        <q-badge
            v-if="userHasRunningJobs"
            v-cypress="'AppJobsButton_RunningJobs_Badge'"
            color="accent"
            text-color="secondary"
            class="badge-notification-rounded"
            floating
        >
            {{ userRunningJobs.length }}
        </q-badge>
    </GeneralButton>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import mixins from '../mixins';

export default {
    name: 'AppJobsButton',
    mixins: [mixins.compute],
    computed: {
        ...mapState('app', {
            backgroundJobPanelOpen: 'backgroundJobPanelOpen',
        }),
        ...mapGetters('Jobs', {
            forgeResources: 'runningJobsForgeResources',
            userRunningJobs: 'userRunningJobs',
        }),
        userHasRunningJobs() {
            return this.userRunningJobs && this.userRunningJobs.length > 0;
        },
    },
    methods: {
        ...mapMutations('app', [
            'toggleBackgroundJobPanel',
        ]),
    },
};
</script>

<style scoped>
.badge-notification-rounded {
    border-radius: 20%;
    height: 0.9rem;
    margin-top: 1rem;
}
</style>
