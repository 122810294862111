<template>
    <div class="column">
        <div class="col-auto q-pa-lg text-h6 text-bold text-secondary">
            My Shortcuts
        </div>
        <div class="col-auto q-px-md">
            Access insights faster. Make shortcuts across Petro.ai.
        </div>
        <q-scroll-area
            class="col"
        >
            <div
                v-if="forgeResourcesLoaded"
            >
                <ExpandableActionList
                    v-for="(shortcutGroup, groupIndex) in mappedShortcuts"
                    :key="`PetronShortcuts-${groupIndex}`"
                    :title="shortcutGroup.name"
                    :items="shortcutGroup.shortcuts"
                    class="q-py-md text-secondary"
                    @item-clicked="followShortcut"
                >
                    <template v-slot:actionControls="shortcutItem">
                        <GeneralButton
                            v-cypress="'AppMainShortcuts_RemoveShortcut_Button'"
                            icon="link_off"
                            tooltip="Remove shortcut"
                            flat
                            dense
                            @click.stop="removeShortcut(shortcutItem.shortcut)"
                        />
                    </template>
                </ExpandableActionList>
            </div>
            <q-spinner
                v-else
                size="30px"
                color="secondary"
                class="full-width"
            />
        </q-scroll-area>
        <div class="col-auto q-pa-lg">
            <GeneralButton
                v-if="currentPlugin"
                v-cypress="'AppMainShortcuts_MakeShortcut_Button'"
                :flat="false"
                :outline="false"
                class="full-width text-bold purple-box"
                label="Make Shortcut"
                color="accent"
                text-color="secondary"
                tooltip="Add a shortcut to here"
                @click="launchShortcutCreateDialog"
            />
        </div>
        <NewShortcutDialog
            v-model="showCreateShortcutDialog"
            :default-name="defaultShorcutName"
            @createShortcut="createShortcut"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import mixins from '../mixins';
import { formatting } from '../plugin-framework/services/utils/formatting-utils.js';
import NewShortcutDialog from './NewShortcutDialog';
import ExpandableActionList from '../plugin-framework/services/pai-components/ExpandableActionList';
import GeneralButton from '../plugin-framework/shared-components/inline-elements/GeneralButton.vue';

export default {
    name: 'AppMenuShortcuts',
    components: {
        ExpandableActionList,
        NewShortcutDialog,
        GeneralButton,
    },
    mixins: [mixins.compute],
    data() {
        return {
            showCreateShortcutDialog: false,
        };
    },
    computed: {
        ...mapGetters('Library', {
            userShortcuts: 'shortcuts',
            libraryForgeResources: 'forgeResources',
        }),
        ...mapGetters('Petron', {
            petron: 'petron',
            petronForgeResources: 'forgeResources',
        }),
        forgeResources() {
            return [...this.libraryForgeResources, ...this.petronForgeResources];
        },
        currentPlugin() {
            // See if the route has a name defined.
            const meta = this.$route.meta;
            if (meta && meta.pluginId) {
                return meta.pluginId;
            }
            return null;
        },
        defaultShorcutName() {
            return this.currentPlugin ? formatting.startCase(this.currentPlugin) : 'New Shortcut';
        },
        organizedShortcuts() {
            const unclassified = {
                shortcuts: [],
            };
            const petronMap = {};
            this.userShortcuts.forEach(shortcut => {
                const meta = shortcut.meta;
                if (!meta) {
                    // Log something?
                    return;
                }
                let shortcutGroup = unclassified;
                const petronId = shortcut.petronId;
                if (petronId) {
                    const petronName = shortcut.petronName;
                    if (petronName) {
                        shortcutGroup = petronMap[petronId];
                        if (!shortcutGroup) {
                            shortcutGroup = {
                                petronName,
                                shortcuts: [],
                            };
                            petronMap[petronId] = shortcutGroup;
                        }
                    }
                }
                shortcutGroup.shortcuts.push(shortcut);
            });
            const organizedShortcuts = [...Object.values(petronMap)];
            organizedShortcuts.sort((a, b) => a.petronName.localeCompare(b.petronName));
            return organizedShortcuts;
        },
        mappedShortcuts() {
            return this.organizedShortcuts.map(shortcutObject => {
                const petron = shortcutObject.petronName;
                const shortcuts = shortcutObject.shortcuts.map(shortcut => {
                    const shortcutItem = {
                        title: shortcut.name,
                        tooltip: `Visit '${shortcut.name}'`,
                        shortcut,
                    };
                    if (shortcut.meta && shortcut.meta.toRoute) {
                        shortcutItem.to = shortcut.meta.toRoute;
                    }
                    return shortcutItem;
                });
                shortcuts.sort((a, b) => a.title.localeCompare(b.title));
                return {
                    name: petron,
                    shortcuts,
                };
            });
        },
    },
    methods: {
        launchShortcutCreateDialog() {
            this.showCreateShortcutDialog = true;
        },
        createShortcut({ name }) {
            if (!this.currentPlugin) {
                return;
            }
            const pluginId = this.currentPlugin;
            const meta = this.$pai.buildPluginShortcut(pluginId);
            if (!meta) {
                this.$notify.error('Failed to create shortcut.');
                return;
            }
            if (this.petron) {
                meta.petronId = this.petron.id;
            }
            this.$api.data.insert({
                type: 'Shortcut',
                data: [
                    {
                        name,
                        pluginId,
                        meta,
                    },
                ],
            }).then(() => {
                this.$notify.success('Shortcut created.');
            }).catch(issue => {
                this.$notify.error('Could not create shortcut:', issue);
            });
        },
        removeShortcut(shortcut) {
            this.$notify.confirm('Do you want to remove this shortcut?', {
                title: 'Remove Shortcut',
                okButtonText: 'Remove Shortcut',
                okButtonTooltip: 'Remove the shortcut',
                cancelButtonTooltip: 'Do not remove the shortcut',
                onOk: (closeCallback) => {
                    closeCallback(true);
                    this.$api.data.delete({
                        type: 'Shortcut',
                        query: {
                            id: shortcut.id,
                        },
                        options: {},
                    }).then(response => {
                        if (response && response.success) {
                            this.$notify.success('Shortcut removed.');
                        }
                        else {
                            this.$notify.error('Shortcut could not be removed.');
                        }
                    }).catch(error => {
                        this.$notify.error('Error removing shortcut.', error);
                    });
                },
            });
        },
        followShortcut(shortcutItem) {
            if (!shortcutItem) {
                return;
            }
            this.$emit('collapse');
            if (shortcutItem.to) {
                // The shortcut is a simple navigation. No other action is required.
                return;
            }
            const shortcut = shortcutItem.shortcut;
            this.$pai.triggerPluginAction('resolveShortcut', [shortcut.pluginId], shortcut);
        },
    },
};
</script>

<style scoped lang="stylus">
@import '../css/quasar.variables.styl';
.purple-box {
    border: solid 2px $secondary;
    border-radius: 2px;
}
</style>
