import { render, staticRenderFns } from "./CommentView.vue?vue&type=template&id=aeab5d16&scoped=true&"
import script from "./CommentView.vue?vue&type=script&lang=js&"
export * from "./CommentView.vue?vue&type=script&lang=js&"
import style0 from "./CommentView.vue?vue&type=style&index=0&id=aeab5d16&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aeab5d16",
  null
  
)

export default component.exports