import { NotificationsModel } from './model/notifications-model.js';
import { NotificationsRoute } from './fragments/notifications-route';

export const NotificationsPlugin = {
    name: 'Notifications',
    definition({ app, plugin }) {
        return {
            modules: [
                NotificationsRoute({ app, plugin }),
            ],
            model: NotificationsModel({ app, plugin }),
        };
    },
};
