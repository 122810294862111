import { PetronOverviewModel } from './model/model.js';

export const PetronOverview = {
    name: 'PetronOverview',
    views: {
    },
    definition({ app, plugin }) {
        return {
            modules: [],
            model: PetronOverviewModel({ app, plugin }),
            actions: {
                resolveComment: (comment) => {
                    app.$router.push({
                        name: 'petron-overview',
                        params: {
                            petronId: comment.petronId,
                        },
                    });
                },
            },
        };
    },
};
