import ApplicationLayout from '../../layouts/ApplicationLayout.vue';
import DefaultPageLayout from '../../layouts/DefaultPageLayout.vue';
import TheLibraryPage from './components/TheLibraryPage.vue';
import { LibraryRoute } from './fragments/library-route.js';
import { LibraryModel } from './model/library-model.js';

export const Library = {
    name: 'Library',
    views: {
        ApplicationLayout,
        DefaultPageLayout,
        TheLibraryPage,
    },
    definition({ app, plugin }) {
        return {
            modules: [
                LibraryRoute({ app, plugin }),
            ],
            model: LibraryModel({ app, plugin }),
        };
    },
};
