<template>
    <div>
        <slot v-if="authorized"/>
        <slot
            v-else
            name="notAuthorized"
        />
    </div>
</template>

<script>
export default {
    name: 'AuthProtectedContent',
    props: {
        roles: {
            type: Array,
            required: true,
        },
    },
    computed: {
        authorized() {
            const userRoles = this.$auth.user.roles;
            const roleUnion = userRoles.filter(userRole => this.roles.includes(userRole));
            return roleUnion.length > 0;
        },
    },
};
</script>

<style scoped>

</style>
