import Vue from 'vue';

const PlatformActionsModule = {
    namespaced: true,
    state: {
        // Actions Dialog
        showActionDialog: false,
        actionItems: [],
        onActionSuccess: null,
        onActionError: null,
        // Form Dialog
        showFormDialog: false,
        formOptions: {
            title: 'Options',
            schema: {},
            value: {},
            size: 'sm',
            message: 'Please provide your input:',
            referenceId: 'none',
        },
        formValue: {},
        formValid: true,
        // Info Dialog
        showInfoDialog: false,
        infoOptions: {
            title: 'Petro.ai Information',
            sections: [],
            size: 'md',
        },
        // Shared action callback
        onConfirm: null,
    },
    mutations: {},
    getters: {
        actionFragments: (state, getters) => {
            // Filter the possible actions down to those that are applicable by type
            const actionFragments = Vue.$plugins.getFragments('action');
            const actions = actionFragments.filter(action => {
                // If action is for all types and doesn't explicitly exclude, then return it
                const actionExcludesType = action.excludeTypes && action.excludeTypes.length && action.excludeTypes.some(type => getters.actionTypes.includes(type));
                if (action.types.length === 0 && !actionExcludesType) {
                    return true;
                }
                // If action for one of the types then return it and not in the explicit exclude
                const actionIncludesType = action.types.some(type => getters.actionTypes.includes(type));
                return actionIncludesType && !actionExcludesType;
            });
            return actions;
        },
        actionTypes: (state, getters) => {
            // Create a deduped list of action types from the items
            const actionTypes = [...new Set(state.actionItems.map(actionItem => actionItem.type))];
            return actionTypes;
        },
        defaultActionsMap: (state, getters) => {
            const allActionFragments = Vue.$plugins.getFragments('action');
            const allActionsMap = {};
            allActionFragments.forEach(fragment => {
                if (fragment.types) {
                    fragment.types.forEach(type => {
                        if (!allActionsMap[type]) {
                            allActionsMap[type] = [];
                        }
                        allActionsMap[type].push(fragment);
                    });
                }
            });
            const defaultActionsMap = {};
            Object.keys(allActionsMap).forEach(actionType => {
                const actionsWithPriority = allActionsMap[actionType].filter(action => action.priority);
                if (!actionsWithPriority.length) {
                    // If there are no priorities, we can't determine a default action.
                    Vue.$logging.loggers.PluginFramework.warn(`Could not find prioritized actions for type '${actionType}'.`);
                    return;
                }
                if (actionsWithPriority.length === 1) {
                    // There is only one prioritized action, so that will be the default one.
                    defaultActionsMap[actionType] = actionsWithPriority[0];
                    return;
                }
                // Find the action with the highest priority, to be the default.
                actionsWithPriority.sort((a, b) => a.priority - b.priority);
                const defaultAction = actionsWithPriority[0];
                if (actionsWithPriority[0].priority === actionsWithPriority[1].priority) {
                    Vue.$logging.loggers.PluginFramework.warn(`There is more than one action with the highest priority for type '${actionType}'. The default action might not be what's expected.`);
                }
                defaultActionsMap[actionType] = defaultAction;
            });
            return defaultActionsMap;
        },
    },
};

// Dynamically add all the mutation setters for state
Object.keys(PlatformActionsModule.state).forEach(stateKey => {
    PlatformActionsModule.mutations[stateKey] = (state, value) => {
        state[stateKey] = value;
    };
});

export default PlatformActionsModule;
