import _ from 'lodash';
import { mapState } from 'vuex';

export default {
    watch: {
        forgeResources(newVal, oldVal) {
            this.$logging.loggers.ComputeService.debug('Component forge resources changed (from/to):', newVal, oldVal);
            // If the resources change dynamically on a component, we need to update the connection.
            if (!_.isEqual(newVal, oldVal)) {
                // Verify the value has changed, which is necessary for an array. See also https://vuejs.org/v2/api/#vm-watch.
                this.connect();
            }
        },
    },
    async beforeMount() {
        this.connect();
    },
    beforeDestroy() {
        if (this.forgeDisposableReference) {
            this.forgeDisposableReference.dispose();
        }
    },
    computed: {
        ...mapState({
            forgeResourceElements(state) {
                if (this.forgeResources) {
                    const forgeResourceElements = Object.keys(state.compute.elements)
                    .filter(uri => this.forgeResources.includes(uri))
                    .map(uri => state.compute.elements[uri]);

                    return forgeResourceElements;
                }
                return [];
            },
        }),
        forgeResourcesLoaded() {
            if (this.forgeResources) {
                const forgeResourceStates = this.forgeResourceElements
                .map(element => element.state);

                return forgeResourceStates.every(resourceState => resourceState === 'ready');
            }
            return true;
        },
    },
    methods: {
        forgeElement(name) {
            return this.$store.getters['compute/getElement'](name);
        },
        connect() {
            if (this.forgeDisposableReference) {
                this.forgeDisposableReference.dispose();
            }
            // Subscribe to the forge resources using name of component with its uid as subscriber
            this.forgeDisposableReference = this.$compute.forgeSubscribeElements(`${this.$options.name}-${this._uid}`, this.forgeResources);
        },
    },
};
