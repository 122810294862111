<template>
    <div>
        <div
            v-if="forgeResourcesLoaded"
            class="col"
        >
            <div
                v-if="filteredFavoritePetrons.length || filteredOtherPetrons.length"
            >
                <div
                    v-for="(petron, idx) in filteredFavoritePetrons"
                    :key="`favorite-${idx}`"
                    class="q-pa-sm"
                >
                    <PetronCard
                        :petron="petron"
                        is-favorite
                        :editable="canEditPetron(petron)"
                        dense
                        class="bg-grey-2"
                        @remove="displayDeletePetronDialog(petron)"
                        @pin-to-favorites="addToFavoritesFunc(petron.id)"
                        @unpin="removeFromFavoritesFunc(petron.id)"
                        @copy="displayCopyPetronDialog(petron)"
                    />
                </div>
                <div
                    v-for="(petron, idx) in filteredOtherPetrons"
                    :key="`owned-${idx}`"
                    class="q-pa-sm"
                >
                    <PetronCard
                        :petron="petron"
                        :is-favorite="false"
                        :editable="canEditPetron(petron)"
                        dense
                        class="bg-grey-2"
                        @remove="displayDeletePetronDialog(petron)"
                        @pin-to-favorites="addToFavoritesFunc(petron.id)"
                        @unpin="removeFromFavoritesFunc(petron.id)"
                        @copy="displayCopyPetronDialog(petron)"
                    />
                </div>
            </div>
            <div
                v-else
                class="q-py-md text-grey-9"
            >
                <p v-if="searchText && searchText.length">
                    Clear the search to see your petrons.
                </p>
                <p v-else>
                    Click the add icon above, to create your first Petron.
                </p>
            </div>
        </div>
        <ForgeLoadState
            v-else
            class="col"
            :resources="forgeResourceElements"
        />
        <NewPetronDialog
            v-model="showCreateDialog"
            @createPetron="createPetronFunc"
        />
        <CopyPetronDialog
            v-model="showCopyDialog"
            :petron="petronToCopy"
            @copy="copyPetronFunc"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CopyPetronDialog from './CopyPetronDialog.vue';
import NewPetronDialog from './NewPetronDialog.vue';
import mixins from '../../../mixins';
import ForgeLoadState from '../../../plugin-framework/shared-components/ForgeLoadState';
import PetronCard from './PetronCard.vue';

export default {
    name: 'PetronFeed',
    components: {
        PetronCard,
        ForgeLoadState,
        NewPetronDialog,
        CopyPetronDialog,
    },
    mixins: [mixins.compute],
    props: {
        searchText: {
            type: String,
            required: false,
            default: '',
        },
    },
    data() {
        return {
            showCreateDialog: false,
            petronToCopy: null,
            showCopyDialog: false,
        };
    },
    computed: {
        ...mapGetters('Library', {
            favoritePetrons: 'favoritePetrons',
            ownerCollaboratorPetrons: 'ownerCollaboratorPetrons',
            forgeResources: 'forgeResources',
        }),
        filteredFavoritePetrons() {
            const favorites = [...this.favoritePetrons];
            favorites.sort((a, b) => a.name.localeCompare(b.name));
            if (this.searchText) {
                return favorites.filter(petron => petron.name.toLowerCase().includes(this.searchText.toLowerCase()));
            }
            return favorites;
        },
        userId() {
            return this.$auth.user.userId;
        },
        filteredOtherPetrons() {
            const favoritePetronIds = this.favoritePetrons.map(x => x.id);
            const ownerCollaboratorPetrons = this.ownerCollaboratorPetrons.filter(petron => !favoritePetronIds.includes(petron.id));
            ownerCollaboratorPetrons.sort((a, b) => a.name.localeCompare(b.name));
            if (this.searchText) {
                return ownerCollaboratorPetrons.filter(petron => petron.name.toLowerCase().includes(this.searchText.toLowerCase()));
            }
            return ownerCollaboratorPetrons;
        },
    },
    methods: {
        ...mapActions('Library', {
            addToFavoritesFunc: 'addToFavorites',
            copyPetronFunc: 'copyPetron',
            createPetronFunc: 'createPetron',
            removeFromFavoritesFunc: 'removeFromFavorites',
            removePetronFunc: 'removePetron',
        }),
        displayCopyPetronDialog(petronToCopy) {
            this.petronToCopy = petronToCopy;
            this.showCopyDialog = true;
        },
        displayDeletePetronDialog(petronToRemove) {
            this.removePetronFunc({ petronToRemove });
        },
        canEditPetron(petron) {
            return this.$auth.userIsAdmin || (this.userId === petron.ownerId);
        },
    },
};
</script>

<style scoped>
</style>
