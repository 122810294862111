<template>
    <q-page class="bg-grey">
        <div class="row justify-center items-center window-height">
            <div class="col-md-3">
                <q-card>
                    <q-card-section class="text-center">
                        <div class="text-h4">
                            Authorization failed
                        </div>
                        <div class="text-subtitle2 highlight-text">
                            You are not authorized to access this page.
                        </div>
                        <GeneralButton
                            label="Logout"
                            icon="lock"
                            tooltip="Logout"
                            @click="logout"
                        />
                    </q-card-section>
                </q-card>
            </div>
        </div>
    </q-page>
</template>

<script>
import GeneralButton from '../../plugin-framework/shared-components/inline-elements/GeneralButton.vue';

export default {
    name: 'Forbidden',
    components: {
        GeneralButton,
    },
    methods: {
        logout() {
            this.$auth.signOut();
            this.$router.push({ name: 'sign-in' });
        },
    },
};
</script>

<style scoped>
    .highlight-text {
        color: darkred;
    }
</style>
