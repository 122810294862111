<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-6">
                <ForgeLineChart
                    :data="chartData"
                    chart-height="250px"
                    x-axis="start"
                    y-axis="totalResources"
                    y-label="Total Resources"
                    :y-min="0"
                />
            </div>
            <div class="col-6">
                <ForgeLineChart
                    :data="chartData"
                    chart-height="250px"
                    x-axis="start"
                    y-axis="utilization"
                    y-label="Utilization %"
                    :y-min="0"
                    :y-max="100"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <ForgeLineChart
                    :data="chartData"
                    chart-height="250px"
                    x-axis="start"
                    y-axis="totalRows"
                    y-label="Total Rows"
                    :y-min="0"
                />
            </div>
            <div class="col-6">
                <ForgeLineChart
                    :data="chartData"
                    chart-height="250px"
                    x-axis="start"
                    y-axis="clientWorkspaces"
                    y-label="Client Workspaces"
                    :y-min="0"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import mixins from '../../../../mixins';

export default {
    name: 'ForgePerformanceMonitor',
    mixins: [mixins.compute],
    data() {
        return {
            name: 'ForgeMonitorPerformance',
            workspaceNetwork: {},
            count: 0,
        };
    },
    computed: {
        chartData() {
            const el = this.forgeElement(this.forgeResources[0]);
            // const el = this.forgeGetElement(this.forgeResources[0]);
            if (el !== undefined) {
                const theData = el.data.map(x => {
                    x.start = new Date(x.start);
                    return x;
                });
                return theData;
            }
            return [];
        },
        aggData() {
            const el = this.forgeElement(this.forgeResources[1]);
            return el;
        },
        workspaceName() {
            // return `${this.name}-${this.count}`;
            return `${this.name}`;
        },
        fullName() {
            return `$root/${this.workspaceName}`;
        },
        forgeResources() {
            return [`$root/${this.workspaceName}#PerformanceTable`, `$root/${this.workspaceName}#AggStats`, `$root/${this.workspaceName}#DurationSeconds`];
        },
    },
    methods: {
        load() {
            this.$compute.forgeGetResource(this.fullName, 'PerformanceTable').then((response) => {
            // this.$compute.forgeGetResource('$root', 'sys.events').then((response) => {
                // Clean dates
                const theData = response.data.map(x => {
                    const d = { ...x };
                    x.start = new Date(x.start);
                    return x;
                });
                // const data = response.data.data;
                // const sortedData = theData.sort((a, b) => a.start - b.start);
                this.chartData = Object.freeze(theData);
            }).catch((e) => {
                console.error('Error getting resource: ', e);
            });
        },
        loadGraph() {
            this.$compute.forgeGetWorkspace('$root').then(response => {
                console.debug('Got workspace graph ', response);
                this.workspaceNetwork = response.data[0];
            }).catch((e) => {
                console.error('Error getting network: ', e);
            });
        },
    },
};
</script>

<style scoped>

</style>
