<template>
    <q-layout
        view="hHh lpR fFf"
        container
        class="default-layout-height"
    >
        <q-page-container>
            <router-view
                :key="$route.path"
                name="pageContent"
            />
        </q-page-container>

        <q-drawer
            v-if="filtersAvailable"
            v-model="filterPanelOpen"
            :width="325"
            side="right"
            behavior="desktop"
            bordered
        >
            <FilterMenu/>
        </q-drawer>

        <q-drawer
            v-if="commentPanelOpen && commentsAvailable"
            v-model="commentPanelOpen"
            :width="325"
            side="right"
            behavior="desktop"
            bordered
        >
            <CommentsMenu/>
        </q-drawer>
    </q-layout>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import FilterMenu from '../navigation/FilterMenu.vue';
import CommentsMenu from '../navigation/CommentsMenu.vue';

export default {
    name: 'DefaultPageLayout',
    components: {
        FilterMenu,
        CommentsMenu,
    },
    data() {
        return {
            showFilterMenu: false,
        };
    },
    computed: {
        ...mapState('app', {
            filterPanelOpen: state => state.filterPanelOpen,
            commentPanelOpen: state => state.commentPanelOpen,
        }),
        filtersAvailable() {
            const pageFragments = this.$pai.getFragments('page-filter');
            if (!pageFragments) {
                return false;
            }
            return pageFragments.filter(fragment => {
                if (!fragment.props || !fragment.props.routes) {
                    return false;
                }
                return !fragment.props.hide && fragment.props.routes.includes(this.$route.name);
            }).length > 0;
        },
        commentsAvailable() {
            const commentViewFragments = this.$pai.getFragments('comment-view');
            const pageCommentViewFragments = commentViewFragments.filter(pageCommentView => pageCommentView.props.routes.includes(this.$route.name));
            return pageCommentViewFragments.length > 0;
        },
    },
    watch: {
        '$route.query.showComments': {
            immediate: true,
            handler(newVal) {
                if (newVal && !this.commentPanelOpen) {
                    this.toggleCommentPanel();
                }
            },
        },
        filtersAvailable(newVal) {
            if (!newVal) {
                this.showFilterMenu = false;
            }
        },
    },
    methods: {
        ...mapMutations('app', [
            'toggleFilterPanel',
            'toggleCommentPanel',
        ]),
    },
};
</script>

<style scoped lang="stylus">
@import '../css/petro.styl';
.default-layout-height {
    /* full height minus the application bar */
    height: "calc(100vh - %s)" % $app-header-height;
}
</style>
