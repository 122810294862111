import CMSService from './cms-service.js';

const CMS = {
    install(Vue, {
        settings,
    }) {
        const service = new CMSService({ settings });

        // Register the CMS service globally.
        Vue.$cms = service;

        // Register the cms service on the vue instance.
        Vue.prototype.$cms = service;
    },
};

export default CMS;
