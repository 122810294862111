export const tableColumnsJobResults = function tableColumnsJobResults(app) {
    return {
        source: 'self',
        options: {
            default: [
                {
                    name: 'id',
                    align: 'center',
                },
                {
                    name: 'jobStatus',
                    align: 'center',
                    label: 'Status',
                    type: 'badge',
                    typeOptions: {
                        format: (value) => {
                            return {
                                color: value === 'completed' ? 'green' : 'black',
                                label: value,
                                textColor: 'white',
                            };
                        },
                    },
                    required: true,
                },
                {
                    name: 'progress',
                    align: 'right',
                    type: 'progress',
                    typeOptions: {
                        color: 'green',
                        label: 'percent',
                        labelBackgroundColor: 'green',
                        min: 0,
                        max: 100,
                        textColor: 'white',
                    },
                },
                {
                    name: 'createdBy',
                    type: 'user',
                },
                {
                    name: 'scheduledAt',
                    required: true,
                },
            ],
        },
    };
};
