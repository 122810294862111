<template>
    <PDialog
        v-model="display"
        :title="`Select ${name}`"
        hide-cancel-btn
        body-height="600px"
        ok-btn-text="Close"
        @ok="$emit('ok')"
    >
        <div
            style="height: 100%; overflow: hidden;"
        >
            <div class="row q-px-sm q-pb-md">
                <q-select
                    v-model="currentScope"
                    :options="scopeOptions"
                    :disable="disableScope"
                    dense
                    options-dense
                    @input="refreshObjects"
                />
            </div>
            <div class="q-pb-sm">
                <q-input
                    v-model="searchText"
                    :placeholder="`Search for ${name}`"
                    :hint="target.searchHint"
                    filled
                    dense
                    clearable
                    debounce="200"
                    @input="refreshObjects"
                >
                    <template v-slot:append>
                        <q-icon name="search"/>
                    </template>
                </q-input>
            </div>
            <div class="q-pa-md">
                <div
                    v-for="object in selectedObjects"
                    :key="object.id"
                    class="row items-center"
                >
                    <GeneralButton
                        tooltip="Remove from selection"
                        icon="clear"
                        dense
                        style="width: 20px;"
                        @click="removeSelection(object)"
                    />
                    <slot
                        name="selectedObject"
                        :object="object"
                    >
                        <!-- default display -->
                        <div class="col">
                            {{ object.displayName }}
                        </div>
                    </slot>
                </div>
            </div>
            <q-separator/>
            <q-spinner
                v-if="findingObjects"
                size="30px"
                class="full-width"
            />
            <div
                v-else
                class="qa-pa-md"
            >
                <div
                    v-for="object in filteredResults"
                    :key="object.id"
                    class="row q-px-md q-py-xs items-center search-result"
                    @click="addSelection(object)"
                >
                    <div style="width: 20px;"/>
                    <slot
                        name="matchingObject"
                        :object="object"
                    >
                        <!-- default display -->
                        <div class="col">
                            {{ object.displayName }}
                        </div>
                    </slot>
                    <q-tooltip>Click to add</q-tooltip>
                </div>
            </div>
        </div>
    </PDialog>
</template>

<script>
import GeneralButton from '../inline-elements/GeneralButton.vue';

export default {
    name: 'AutoFormObjectPickerDialog',
    components: {
        GeneralButton,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        target: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            allObjects: [],
            selectedObjects: [],
            searchText: '',
            defaultInitialScope: 'Current Petron',
            defaultScopeOptions: [
                'Current Petron',
                'All Petrons',
            ],
            currentScope: null,
            findingObjects: false,
        };
    },
    computed: {
        display: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        name() {
            return this.target.displayName || '';
        },
        filteredResults() {
            let searchResults = this.allObjects.filter(obj => !this.selectedObjects.includes(obj));
            if (this.searchText && this.searchText.length) {
                searchResults = searchResults.filter(ob => ob.id.toLowerCase().includes(this.searchText.toLowerCase())
                    || ob.displayName.toLowerCase().includes(this.searchText.toLowerCase()));
            }
            searchResults.length = Math.min(searchResults.length, 10);
            return searchResults;
        },
        findFunction() {
            return this.target ? this.target.findFunction : null;
        },
        singleSelectionOnly() {
            return this.target ? this.target.singleSelectionOnly : false;
        },
        scopeOptions() {
            if (this.target && this.target.scope) {
                return this.target.scope.options;
            }
            return this.defaultScopeOptions;
        },
        disableScope() {
            return this.target && this.target.disableScope;
        },
    },
    watch: {
        selectedObjects(newVal) {
            this.$emit('selectionChanged', newVal);
        },
        display(newVal) {
            if (newVal) {
                this.refreshObjects();
            }
        },
    },
    created() {
        let scope = this.defaultInitialScope;
        if (this.target && this.target.scope) {
            scope = this.target.scope.initialValue;
        }
        this.currentScope = scope;
        this.refreshObjects();
    },
    methods: {
        refreshObjects() {
            if (this.findFunction) {
                this.findingObjects = true;
                this.findFunction({
                    callback: this.updateObjects,
                    routeParams: this.$route.params,
                    scope: this.currentScope,
                    filter: this.searchText,
                });
            }
        },
        updateObjects(results) {
            this.findingObjects = false;
            this.allObjects = results;
        },
        addSelection(object) {
            if (this.singleSelectionOnly) {
                this.selectedObjects = [object];
            }
            else {
                this.selectedObjects.push(object);
            }
        },
        removeSelection(object) {
            const idx = this.selectedObjects.findIndex(obj => obj.id === object.id);
            if (idx !== -1) {
                this.selectedObjects.splice(idx, 1);
            }
        },
    },
};
</script>

<style scoped>
.search-result {
    cursor: pointer;
}
.search-result:hover {
    background-color: #dddddd;
}
</style>
