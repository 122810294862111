<template>
    <q-dialog
        v-if="display"
        v-model="display"
        :maximized="maximized"
        persistent
        @show="show"
        @before-show="beforeShow"
        @hide="hide"
        @before-hide="beforeHide"
        @shake="$emit('shake')"
    >
        <q-card
            :class="cardClasses"
        >
            <q-card-section class="row items-center">
                <slot name="header">
                    <q-icon
                        v-if="headerIcon"
                        :name="headerIcon"
                        class="text-h6"
                    />
                    <div class="text-h5 petro-font-bold text-primary">
                        {{ title }}
                    </div>
                </slot>
                <q-space/>

                <slot name="header-buttons"/>
                <GeneralButton
                    v-if="showMinMax && maximized"
                    color="grey-8"
                    flat
                    icon="far fa-window-restore"
                    :disabled="!maximized"
                    tooltip="Minimize dialog"
                    @click="minimize"
                />
                <GeneralButton
                    v-if="showMinMax && !maximized"
                    color="grey-8"
                    flat
                    icon="far fa-window-maximize"
                    :disabled="maximized"
                    tooltip="Maximize dialog"
                    @click="maximize"
                />
                <GeneralButton
                    v-close-popup="closeOnCancel"
                    v-cypress="'PDialog_TitleClose_Button'"
                    color="grey-8"
                    flat
                    icon="clear"
                    tooltip="Close dialog"
                    @click="$emit('cancel')"
                />
            </q-card-section>

            <q-card-section
                :style="bodyStyle"
                class="max-height-body scroll"
            >
                <slot/>
            </q-card-section>

            <q-card-actions
                v-if="showFooter"
                align="right"
            >
                <slot name="footer">
                    <slot name="footer-note"/>
                    <GeneralButton
                        v-if="!hideCancelBtn"
                        v-cypress="cancelBtnTestId"
                        v-close-popup="closeOnCancel"
                        color="primary"
                        flat
                        :disabled="cancelBtnDisabled"
                        :label="cancelBtnText"
                        :tooltip="cancelBtnTooltip"
                        @click="$emit('cancel')"
                    />
                    <GeneralButton
                        v-if="!hideOkBtn"
                        v-cypress="okBtnTestId"
                        v-close-popup="closeOnOk"
                        color="accent"
                        text-color="primary"
                        :flat="false"
                        :outline="false"
                        :disabled="okBtnDisabled"
                        :label="okBtnText"
                        :tooltip="okBtnTooltip"
                        @click="$emit('ok')"
                    />
                </slot>
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import GeneralButton from '../../shared-components/inline-elements/GeneralButton.vue';

export default {
    name: 'PDialog',
    components: {
        GeneralButton,
    },
    props: {
        // v-model prop to show or hide the modal
        value: {
            type: Boolean,
            required: true,
        },
        // Horizontal width sizing for the modal
        // sm: 300px wide, md: 700px wide, lg: 1100px wide
        size: {
            type: String,
            required: false,
            default: 'md',
            validator: (value) => ['sm', 'md', 'lg', 'auto'].includes(value),
        },
        // Shown in the header of the dialog
        title: {
            type: String,
            required: false,
            default: '',
        },
        // Icon to show to the left of the title
        headerIcon: {
            type: String,
            required: false,
            default: '',
        },
        bodyHeight: {
            type: String,
            required: false,
            default: '',
        },
        // Show or hide the minimize and maximize buttons in header
        showMinMax: {
            type: Boolean,
            required: false,
            default: false,
        },
        // Start with the modal maximized
        startMaximized: {
            type: Boolean,
            required: false,
            default: false,
        },
        // Hide the ok button
        hideOkBtn: {
            type: Boolean,
            required: false,
            default: false,
        },
        // Button text to show in the ok button
        okBtnText: {
            type: String,
            required: false,
            default: 'OK',
        },
        // Tooltip for ok button
        okBtnTooltip: {
            type: String,
            required: false,
            default: 'OK',
        },
        // Hide the cancel button
        hideCancelBtn: {
            type: Boolean,
            required: false,
            default: false,
        },
        // Button text to show in the cancel button
        cancelBtnText: {
            type: String,
            required: false,
            default: 'Cancel',
        },
        // Tooltip for the cancel button
        cancelBtnTooltip: {
            type: String,
            required: false,
            default: 'Cancel',
        },
        // Close the modal when cancel is clicked. Set to false if you want to run logic before closing modal.
        closeOnCancel: {
            type: Boolean,
            required: false,
            default: true,
        },
        // Close the modal when ok is clicked. Set to false if you want to run logic before closing modal.
        closeOnOk: {
            type: Boolean,
            required: false,
            default: true,
        },
        okBtnDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        cancelBtnDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        okBtnTestId: {
            type: String,
            required: false,
            default: null,
        },
        cancelBtnTestId: {
            type: String,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            maximized: false,
            widths: {
                sm: '300px',
                md: '700px',
                lg: '1100px',
            },
        };
    },
    computed: {
        display: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            },
        },
        cardClasses() {
            return {
                'sm-size': this.size === 'sm' && !this.maximized,
                'md-size': this.size === 'md' && !this.maximized,
                'lg-size': this.size === 'lg' && !this.maximized,
            };
        },
        bodyStyle() {
            // Conditionally apply body height if provided
            if (this.bodyHeight) {
                return {
                    height: this.bodyHeight,
                };
            }
            return null;
        },
        hasFooterSlot() {
            return (this.$slots && !!this.$slots.footer) || (this.$slots && this.$slots.$scopedSlots && !!this.$slots.$scopedSlots.footer);
        },
        showFooter() {
            return this.hasFooterSlot || !this.hideOkBtn || !this.hideCancelBtn;
        },
    },
    created() {
        if (this.startMaximized) {
            this.maximized = true;
        }
    },
    methods: {
        minimize() {
            this.maximized = false;
            this.$emit('minimize');
        },
        maximize() {
            this.maximized = true;
            this.$emit('maximize');
        },
        show(event) {
            this.$emit('show', event);
        },
        beforeShow(event) {
            this.$emit('before-show', event);
        },
        hide(event) {
            this.$emit('hide', event);
        },
        beforeHide(event) {
            this.$emit('before-hide', event);
        },
    },
};
</script>

<style scoped>
.max-height-body {
    max-height: 70vh;
}
.sm-size {
    width: 300px;
    max-width: 80vw;
}
.md-size {
    width: 700px;
    max-width: 80vw;
}
.lg-size {
    width: 11000px;
    max-width: 80vw;
}
</style>
