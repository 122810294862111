<template>
    <editor-menu-bubble
        v-slot="{ commands, isActive, menu, getMarkAttrs }"
        :editor="editor"
        keep-in-bounds
        @hide="hideLinkMenu"
    >
        <div
            class="editor-menu"
            :class="{ 'is-active': menu.isActive }"
            :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
        >
            <form
                v-if="linkMenuIsActive"
                class="editor-menu__form"
                @submit.prevent="setLinkUrl(commands.link, linkUrl)"
            >
                <input
                    ref="linkInput"
                    v-model="linkUrl"
                    class="editor-menu__input"
                    type="text"
                    placeholder="https://"
                    @keydown.esc="hideLinkMenu"
                >
                <EditorMenuBtn
                    icon="clear"
                    :active="false"
                    @click="setLinkUrl(commands.link, null)"
                />
            </form>

            <template v-else>
                <EditorMenuBtn
                    :active="isActive.bold()"
                    icon="format_bold"
                    @click="commands.bold"
                />
                <EditorMenuBtn
                    :active="isActive.italic()"
                    icon="format_italic"
                    @click="commands.italic"
                />
                <EditorMenuBtn
                    :active="isActive.underline()"
                    icon="format_underline"
                    @click="commands.underline"
                />
                <EditorMenuBtn
                    :active="isActive.strike()"
                    icon="format_strikethrough"
                    @click="commands.strike"
                />
                <EditorMenuBtn
                    :active="isActive.code()"
                    icon="code"
                    @click="commands.code"
                />
                <EditorMenuBtn
                    :active="isActive.bullet_list()"
                    icon="format_list_bulleted"
                    @click="commands.bullet_list"
                />
                <EditorMenuBtn
                    :active="isActive.ordered_list()"
                    icon="format_list_numbered"
                    @click="commands.ordered_list"
                />
                <EditorMenuBtn
                    :active="isActive.link()"
                    icon="link"
                    @click="showLinkMenu(getMarkAttrs('link'))"
                />
            </template>
        </div>
    </editor-menu-bubble>
</template>

<script>
import { Editor, EditorMenuBubble } from 'tiptap';
import EditorMenuBtn from './EditorMenuBtn.vue';

export default {
    name: 'EditorMenu',
    components: {
        EditorMenuBtn,
        EditorMenuBubble,
    },
    props: {
        editor: {
            type: Editor,
            required: true,
        },
    },
    data() {
        return {
            linkUrl: null,
            linkMenuIsActive: false,
        };
    },
    methods: {
        showLinkMenu(attrs) {
            this.linkUrl = attrs.href;
            this.linkMenuIsActive = true;
            this.$nextTick(() => {
                this.$refs.linkInput.focus();
            });
        },
        hideLinkMenu() {
            this.linkUrl = null;
            this.linkMenuIsActive = false;
        },
        setLinkUrl(command, url) {
            command({ href: url });
            this.hideLinkMenu();
        },
    },
};
</script>

<style scoped lang="stylus">
@import '../../../../css/quasar.variables.styl';

.editor-menu {
    position: absolute;
    display: flex;
    z-index: 20;
    background: $grey-10;
    border-radius: 0px;
    padding: 0.3rem;
    margin-bottom: 0.5rem;
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;
}

.editor-menu.is-active {
    opacity: 1;
    visibility: visible;
}

.editor-menu__form {
    display: flex;
    align-items: center;
}

.editor-menu__input {
    font: inherit;
    border: none;
    background: transparent;
    color: $light;
}
</style>
