<template>
    <q-item>
        <q-item-section>{{ title }}</q-item-section>
        <q-item-section
            side
            class="no-wrap"
        >
            <div>
                <InlineElement
                    v-for="(inlineElement, index) in inlineElements"
                    :key="index"
                    :element="inlineElement"
                />
            </div>
        </q-item-section>
    </q-item>
</template>

<script>
import InlineElement from '../../../plugin-framework/shared-components/inline-elements/InlineElement.vue';

export default {
    name: 'AppSettingsItem',
    components: {
        InlineElement,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        inlineElements: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
};
</script>

<style scoped>

</style>
