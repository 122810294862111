<template>
    <q-page class="q-px-md q-pt-md column">
        <div
            v-if="forgeResourcesLoaded"
            class="row items-center no-wrap q-pb-md"
        >
            <InlineElement
                v-for="(element, index) in headerLeftInlineElements"
                :key="`left-${index}`"
                :element="element"
                class="q-ml-sm"
            />

            <q-space/>

            <InlineElement
                v-for="(element, index) in headerRightInlineElements"
                :key="`right-${index}`"
                :element="element"
                class="q-ml-sm"
            />
        </div>

        <q-scroll-area
            v-if="forgeResourcesLoaded"
            id="report-section-scroll-area"
            style="height:0"
            class="col-grow"
        >
            <div
                v-for="(reportSection, index) in props.reportSections"
                :key="`section-${index}`"
                class="q-mb-lg"
            >
                <div
                    :id="reportSection.routeHashId"
                    class="text-h6 text-bold text-primary"
                >
                    <q-separator
                        color="primary"
                        class="q-mb-md"
                    />
                    {{ reportSection.title }}
                </div>
                <div class="report-section-grid">
                    <VisualizationContainer
                        v-for="(visualization, visualizationIndex) in reportSection.visualizations"
                        :key="`visualization-${visualizationIndex}`"
                        :style="`grid-area: ${visualization.gridArea};`"
                        :visualization="visualization"
                        :title="visualization.title"
                    />
                </div>
            </div>
        </q-scroll-area>

        <ForgeLoadState
            v-if="!forgeResourcesLoaded"
            :resources="forgeResourceElements"
        />
    </q-page>
</template>

<script>
import { scroll } from 'quasar';
import mixins from '../../mixins/index.js';
import InlineElement from '../shared-components/inline-elements/InlineElement.vue';
import VisualizationContainer from '../shared-components/visualizations/VisualizationContainer.vue';
import ForgeLoadState from '../shared-components/ForgeLoadState.vue';

export default {
    name: 'ReportTemplate',
    components: {
        InlineElement,
        ForgeLoadState,
        VisualizationContainer,
    },
    mixins: [mixins.compute, mixins.lifecycleHooks({ addProps: false })],
    props: {
        props: {
            type: Object,
            required: true,
        },
        events: {
            type: Object,
            required: true,
        },
    },
    computed: {
        forgeResources() {
            return this.props.forgeResources;
        },
        headerLeftInlineElements() {
            return this.props.headerLeftInlineElements ? this.props.headerLeftInlineElements : [];
        },
        headerRightInlineElements() {
            return this.props.headerRightInlineElements ? this.props.headerRightInlineElements : [];
        },
    },
    watch: {
        '$route.hash': {
            immediate: true,
            handler(hash) {
                this.scrollToHash(hash);
            },
        },
    },
    mounted() {
        this.scrollToHash(this.$route.hash);
    },
    methods: {
        scrollToHash(hash) {
            if (hash) {
                // remove the # from the hash to lookup the element in DOM
                const element = document.getElementById(hash.substring(1));
                if (element) {
                    const target = scroll.getScrollTarget(element);
                    const offset = element.offsetTop;
                    scroll.setScrollPosition(target, offset);
                }
            }
        },
    },
};
</script>

<style>
.report-section-grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    /* Create rows that are as close as possible to squares */
    grid-auto-rows: calc(100vw / 12);
    grid-gap: 10px;
}
</style>
