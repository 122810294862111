<template>
    <q-infinite-scroll @load="load">
        <div
            v-for="(result, index) in searchResultsWithDateSeparators"
            :key="index"
        >
            <CommentSearchResult
                v-if="result.documentType === 'Comment'"
                :search-result="result"
            />
            <HorizontalRuleLabel
                v-if="result.documentType === 'horizontal-rule'"
                :label="result.document.label"
            />
        </div>
        <template v-slot:loading>
            <div class="text-center">
                <q-spinner
                    size="30px"
                    color="primary"
                />
                <p>Loading results...</p>
            </div>
        </template>
    </q-infinite-scroll>
</template>

<script>
import moment from 'moment-timezone';
import CommentSearchResult from './CommentSearchResult.vue';
import HorizontalRuleLabel from '../activity-feed/HorizontalRuleLabel.vue';

export default {
    name: 'SearchResults',
    components: {
        HorizontalRuleLabel,
        CommentSearchResult,
    },
    props: {
        searchResults: {
            type: Array,
            required: true,
        },
        totalSearchResults: {
            type: Number,
            required: true,
        },
    },
    computed: {
        displayTimeZoneId() {
            return this.$globalSettings.displayTimeZoneId;
        },
        // TODO: Move this to vue 3 paradigm to share duplicated code
        searchResultsWithDateSeparators() {
            let lastDate = moment.utc();
            let todayInserted = false;
            const searchResultsWithDateSeparators = [];
            this.searchResults.forEach(item => {
                // Insert a 'Today' marker if not inserted already and day is today
                if (!todayInserted && moment(item.lastUpdatedAt).isSame(lastDate, 'day')) {
                    searchResultsWithDateSeparators.push({
                        documentType: 'horizontal-rule',
                        document: {
                            label: 'Today',
                        },
                    });
                    todayInserted = true;
                }
                // Insert a date marker when day changes
                if (moment(item.lastUpdatedAt).isBefore(lastDate, 'day')) {
                    searchResultsWithDateSeparators.push({
                        documentType: 'horizontal-rule',
                        document: {
                            label: this.$utils.formatting.formatDate(item.lastUpdatedAt, this.displayTimeZoneId, 'MMM Do'),
                        },
                    });
                }
                lastDate = item.lastUpdatedAt;
                searchResultsWithDateSeparators.push(item);
            });
            return searchResultsWithDateSeparators;
        },
    },
    methods: {
        load(index, done) {
            if (this.searchResults.length < this.totalSearchResults) {
                // Pass the infinite scroll event up to the parent to get more results
                this.$emit('load-more', index, done);
            }
            else {
                done();
            }
        },
    },
};
</script>

<style scoped>

</style>
