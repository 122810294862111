<template>
    <div class="inline-block">
        <span
            v-if="!hideReactions"
            v-cypress="'CommentInfo_Reactions_Span'"
            :class="{'cursor-pointer': canReact, 'text-bold': hasLiked, 'text-primary': hasLiked, 'text-grey-6': !hasLiked}"
            @click="react"
        >
            <q-icon
                v-cypress="'CommentInfo_Like_Icon'"
                name="thumb_up"
                :color="`${hasLiked ? 'primary' : 'grey-6'}`"
            />
            {{ reactions.length }}
            <q-tooltip
                v-if="canReact"
            >
                {{ `${hasLiked ? 'Unlike' : 'Like'} this comment` }}
            </q-tooltip>
        </span>
        <span v-if="!hideReactions && !hideReplies">
            &middot;
        </span>
        <span
            v-if="!hideReplies"
            class="text-grey-6"
        >
            <q-icon
                name="mode_comment"
            />
            {{ replies.length }}
        </span>
    </div>
</template>

<script>
export default {
    name: 'CommentInfo',
    props: {
        reactions: {
            type: Array,
            required: false,
            default: () => [],
        },
        replies: {
            type: Array,
            required: false,
            default: () => [],
        },
        canReact: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideReactions: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideReplies: {
            type: Boolean,
            required: false,
            default: false,
        },
        hasLiked: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    methods: {
        react() {
            if (this.canReact) {
                this.$emit('react', 'like');
            }
        },
    },
};
</script>

<style scoped>

</style>
