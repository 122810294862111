<template>
    <div v-if="forgeResourcesLoaded">
        <PDataTable
            :columns="columns"
            :table-data="data"
            row-key="name"
            :selected.sync="selectedRows"
            :pagination-options.sync="paginationOptions"
            :column-select-settings-parent-id="parentId"
            :selection="selection"
            :table-id="`pluginOverviewTable-${tableType}`"
            :table-type="tableType"
            show-column-select
            :top-left-buttons="topLeftButtons"
            :top-right-buttons="topRightButtons"
            :top-right-buttons-drop-down="topRightButtonsDropDownMenu"
            :selection-key="selectionKey"
            flat
        >
            <div slot="dt-top-left">
                <OmniButton
                    v-for="(button, index) in topLeftDataFilterButtons"
                    :key="`button-${index}`"
                    :button="button"
                />
            </div>
            <!-- Shared Item Name and Actions Slot -->
            <template
                v-for="primaryNameColumn in primaryNameColumns"
                v-slot:[`body-cell-${primaryNameColumn}`]="tableData"
            >
                <q-td
                    :key="primaryNameColumn"
                    class="text-left"
                >
                    <div
                        class="row items-center no-wrap"
                        style="min-width: 400px"
                    >
                        <span
                            v-if="tableData.row[primaryNameColumn]"
                        >
                            {{ tableData.row[primaryNameColumn] }}
                        </span>
                    </div>
                </q-td>
            </template>

            <!--  Shared Created Date Slot -->
            <template
                v-for="dateColumnName in dateColumnNames"
                v-slot:[`body-cell-${dateColumnName}`]="tableData"
            >
                <q-td
                    :key="dateColumnName"
                    class="text-left"
                >
                    <span
                        v-if="tableData.row[dateColumnName]"
                    >
                        {{ dateTimeValue(tableData.row[dateColumnName]) }}
                    </span>
                </q-td>
            </template>

            <!-- Schedule Start Date Slot -->
            <template
                v-slot:[`body-cell-startDate`]="tableData"
            >
                <q-td
                    class="text-left"
                >
                    <span
                        v-if="tableData.row.autoConfig"
                    >
                        {{ tableData.row.autoConfig.generalOptions ? dateTimeValue(tableData.row.autoConfig.generalOptions.scheduleStartDate) : null }}
                    </span>
                </q-td>
            </template>

            <!-- Schedule End Date Slot -->
            <template
                v-slot:[`body-cell-endDate`]="tableData"
            >
                <q-td
                    class="text-left"
                >
                    <span
                        v-if="tableData.row.wells && tableData.row.wells[tableData.row.wells.length - 1]"
                    >
                        {{ dateTimeValue(tableData.row.wells[tableData.row.wells.length - 1].date.productionStartDate) }}
                    </span>
                </q-td>
            </template>

            <!-- Scheduler Total Wells Slot -->
            <template
                v-slot:[`body-cell-totalWells`]="tableData"
            >
                <q-td
                    class="text-left"
                >
                    <span
                        v-if="tableData.row.wells"
                    >
                        {{ tableData.row.wells.length }}
                    </span>
                </q-td>
            </template>

            <!-- Economics Payout Slot -->
            <template
                v-slot:[`body-cell-payOut`]="tableData"
            >
                <q-td
                    class="text-left"
                >
                    <span
                        v-if="tableData.row.evaluationSummary"
                    >
                        {{ numberValue(tableData.row.evaluationSummary.payOut[0].afterTaxValue) }}
                    </span>
                </q-td>
            </template>

            <!-- Economics Payout Slot -->
            <template
                v-slot:[`body-cell-schedules`]="tableData"
            >
                <q-td
                    class="text-left"
                >
                    <span
                        v-if="tableData.row.scheduleIds"
                    >
                        {{ tableData.row.scheduleIds.length }}
                    </span>
                </q-td>
            </template>

            <!-- Economics IRR Slot -->
            <template
                v-slot:[`body-cell-irr`]="tableData"
            >
                <q-td
                    class="text-left"
                >
                    <span
                        v-if="tableData.row.evaluationSummary"
                    >
                        {{ percentValue(tableData.row.evaluationSummary.irr[0].afterTaxValue) }}
                    </span>
                </q-td>
            </template>

            <!-- Shared User Chip Slot -->
            <template
                v-for="userChipColumn in userChipColumns"
                v-slot:[`body-cell-${userChipColumn}`]="tableData"
            >
                <q-td
                    :key="userChipColumn"
                    class="text-left"
                >
                    <UserChip
                        v-if="tableData.row[userChipColumn]"
                        :user-id="tableData.row[userChipColumn]"
                    />
                </q-td>
            </template>
        </PDataTable>
    </div>
    <ForgeLoadState
        v-else
        :resources="forgeResourceElements"
    />
</template>

<script>
import PDataTable from './PDataTable.vue';
import OmniButton from '../../inline-elements/OmniButton.vue';
import mixins from '../../../../mixins';
import ForgeLoadState from '../../ForgeLoadState.vue';

export default {
    name: 'DataTable',
    components: {
        PDataTable,
        OmniButton,
        ForgeLoadState,
    },
    mixins: [mixins.compute],
    props: {
        // TODO: Define a condition for 'disabled' prop in any button in the plugin model
        forgeResources: {
            type: Array,
            required: true,
        },
        // NOTE: If you want to add any buttons to the top left and top right slots in your table,
        //  you MUST pass them as props to the PDataTable component in order for them to appear.
        topLeftButtons: {
            type: Array,
            required: false,
            default: () => [],
        },
        topRightButtons: {
            type: Array,
            required: false,
            default: () => [],
        },
        // You must define these in the model if you want a data filter button in the top left slot
        topLeftDataFilterButtons: {
            type: Array,
            required: false,
            default: () => [],
        },
        // This populates the filter dropdown if you want that option
        topLeftFilterButtonDropdown: {
            type: Array,
            required: false,
            default: () => [],
        },
        // Parent ID required for saving column settings
        parentId: {
            type: String,
            required: true,
        },
        data: {
            type: Array,
            required: true,
        },
        columns: {
            type: Array,
            required: true,
        },
        tableType: {
            type: String,
            required: false,
            default: '',
        },
        topRightButtonsDropDownMenu: {
            type: Array,
            required: false,
            default: () => [],
        },
        // We need a way for the PDataTable to know that its selected row is deleted when we hit the delete button
        selectedItemsDeleted: {
            type: Boolean,
            required: false,
            default: false,
        },
        // Flag for signaling that the table can be selected from
        selection: {
            type: String,
            required: false,
            default: () => 'multiple',
            validator: (v) => ['none', 'single', 'multiple'].includes(v),
        },
        selectedRowKeys: {
            type: Array,
            required: false,
            default: () => [],
        },
        // Field name handle selection by, TODO: Allow plugins define a key that isn't visible on the table
        selectionKey: {
            type: String,
            required: false,
            default: 'id',
        },
        timeZoneId: {
            type: String,
            required: false,
            default: null,
        },
    },
    data() {
        // TODO: Eventually we would like to refactor our tables so we can avoid manually customizing different slots(when we have time to refactor...)
        return {
            itemContextOptions: false,
            itemMenuSelections: null,
            selectedRows: [],
            paginationOptions: {
                sortBy: null,
                descending: null,
                page: 1,
                rowsPerPage: 10,
            },
            // TODO: Let plugin define these
            primaryNameColumns: [
                'name',
                'wellName',
            ],
            userChipColumns: [
                'createdBy',
                'updatedBy',
            ],
            dateColumnNames: [
                'createdAt',
                'createdDate',
                'updatedAt',
            ],
            percentageColumnNames: [
                'irr',
            ],
        };
    },
    computed: {
        // If timezoneId is not passed then use the global timezoneId.
        displayTimeZoneId() {
            return this.timeZoneId || this.$globalSettings.displayTimeZoneId;
        },
    },
    watch: {
        selectedRowKeys(newVal) {
            if (!newVal) return;
            this.updateSelectionWithKeys(newVal);
        },
        selectedRows(newVal) {
            this.$emit('selected', newVal);
        },
        itemMenuSelections(item) {
            this.$emit('itemMenuOpened', item);
        },
        selectedItemsDeleted() {
            this.emptySelectedRows();
        },
        data() {
            if (!this.data.length) {
                this.emptySelectedRows();
            }
        },
    },
    created() {
        this.updateSelectionWithKeys(this.selectedRowKeys);
    },
    methods: {
        openItemActionMenu(item) {
            this.selectedRows = [];
            this.itemMenuSelections = item;
            this.itemContextOptions = !this.itemContextOptions;
        },
        clearSelectedItemContext() {
            this.selectedRows = [];
            this.itemMenuSelections = null;
            this.itemContextOptions = false;
        },
        emptySelectedRows() {
            this.selectedRows = [];
        },
        updateSelectionWithKeys(keys) {
            if (this.selectionKey != null && keys != null) {
                this.selectedRows = [
                    ...this.selectedRows.filter(d => d[this.selectionKey] != null && keys.find(x => x === d[this.selectionKey]) == null),
                    ...this.data.filter(d => d[this.selectionKey] != null && keys.find(x => x === d[this.selectionKey]) != null),
                ];
            }
        },
        dateTimeValue(value) {
            return this.$utils.formatting.formatLongDateTime(value, this.displayTimeZoneId);
        },
        numberValue(value) {
            return this.$utils.formatting.formatNumber(value, { decimalPlaces: 0 });
        },
        percentValue(value) {
            return this.$utils.formatting.formatPercent(value, { decimalPlaces: 0 });
        },
    },
};
</script>

<style scoped>

</style>
