<template>
    <q-page
        padding
    >
        <div
            v-if="forgeResourcesLoaded"
            class="row justify-around"
        >
            <div class="col-md-6">
                <div class="text-h5 q-py-lg">
                    System Information
                </div>
                <div class="full-width q-col-gutter-y-lg">
                    <div
                        v-if="activeApps.length"
                    >
                        <PCard
                            v-cypress="'TheInfoPage_Applications_PCard'"
                            title="Applications"
                        >
                            <q-markup-table
                                dense
                                flat
                                :bordered="false"
                                separator="none"
                                class="q-py-md text-grey-8"
                            >
                                <tbody>
                                    <tr
                                        v-for="app in activeApps"
                                        :key="app.id"
                                    >
                                        <td>
                                            {{ app.name }}
                                            <q-tooltip>
                                                {{ app.description }}
                                            </q-tooltip>
                                        </td>
                                        <td>
                                            <q-icon
                                                :color="applicationStatuses[app.status].color"
                                                :name="applicationStatuses[app.status].icon"
                                            >
                                                <q-tooltip>
                                                    {{ `Status: ${applicationStatuses[app.status].name}` }}
                                                </q-tooltip>
                                            </q-icon>
                                        </td>
                                        <td>{{ `v${app.version}` }}</td>
                                    </tr>
                                </tbody>
                            </q-markup-table>
                        </PCard>
                    </div>
                    <div
                        v-if="activePlugins.length"
                    >
                        <PluginInfoCard
                            :plugins="activePlugins"
                        />
                    </div>
                    <div
                        v-if="applianceEntries.length"
                    >
                        <PCard
                            v-cypress="'TheInfoPage_Appliances_PCard'"
                            title="Appliances"
                        >
                            <q-markup-table
                                dense
                                flat
                                :bordered="false"
                                separator="none"
                                class="q-py-md text-grey-8"
                            >
                                <tbody>
                                    <tr
                                        v-for="(appliance, index) in applianceEntries"
                                        :key="`${appliance.name}-${index}`"
                                    >
                                        <td
                                            :class="appliance.displayClass"
                                        >
                                            {{ appliance.name }}
                                        </td>
                                        <td>
                                            <q-icon
                                                v-if="applianceStatuses[appliance.status]"
                                                :color="applianceStatuses[appliance.status].color"
                                                :name="applianceStatuses[appliance.status].icon"
                                            />
                                        </td>
                                        <td>
                                            <span
                                                v-if="applianceStatuses[appliance.status]"
                                                class="text-grey-6"
                                            >
                                                {{ applianceStatuses[appliance.status].name }}
                                            </span>
                                        </td>
                                        <td
                                            class="ellipsis text-error"
                                        >
                                            <small>
                                                {{ appliance.reason }}
                                            </small>
                                            <q-tooltip
                                                v-if="appliance.reason"
                                            >
                                                {{ appliance.reason }}
                                            </q-tooltip>
                                        </td>
                                    </tr>
                                </tbody>
                            </q-markup-table>
                        </PCard>
                    </div>
                    <div>
                        <PCard
                            v-cypress="'TheInfoPage_Database_PCard'"
                            title="Petro Database"
                        >
                            <q-markup-table
                                dense
                                flat
                                :bordered="false"
                                separator="none"
                                class="q-py-md text-grey-8"
                            >
                                <tbody v-if="serverInfo">
                                    <tr>
                                        <td>Database Name</td>
                                        <td/>
                                        <td>{{ serverInfo.databaseName }}</td>
                                    </tr>
                                    <tr>
                                        <td>Latest DB Version</td>
                                        <td/>
                                        <td>{{ latestDBVersion }}</td>
                                    </tr>
                                    <tr>
                                        <td>Database Size</td>
                                        <td/>
                                        <td>{{ $utils.formatting.formatBytes(serverInfo.databaseInfo.storageSize) }}</td>
                                    </tr>
                                </tbody>
                            </q-markup-table>
                        </PCard>
                    </div>
                    <div
                        v-if="serverInfo"
                    >
                        <PCard
                            v-cypress="'TheInfoPage_Platform_PCard'"
                            title="Platform"
                        >
                            <q-markup-table
                                dense
                                flat
                                :bordered="false"
                                separator="none"
                                class="q-py-md text-grey-8"
                            >
                                <tbody>
                                    <tr>
                                        <td>Environment Name</td>
                                        <td/>
                                        <td>{{ serverInfo.environmentName }}</td>
                                    </tr>
                                    <tr>
                                        <td>Version</td>
                                        <td/>
                                        <td>{{ serverInfo.petroCoreVersion }}</td>
                                    </tr>
                                    <tr>
                                        <td>Environment ID</td>
                                        <td/>
                                        <td>{{ serverInfo.environmentId }}</td>
                                    </tr>
                                    <tr>
                                        <td>Manager Local Worker Enabled</td>
                                        <td/>
                                        <td>{{ serverInfo.instanceConfig.enableLocalWorker }}</td>
                                    </tr>
                                    <tr>
                                        <td>Manager Forwarded Headers Enabled</td>
                                        <td/>
                                        <td>{{ serverInfo.instanceConfig.enableForwardedHeadersMiddleware }}</td>
                                    </tr>
                                    <tr>
                                        <td>Manager HTTPS Redirection Enabled</td>
                                        <td/>
                                        <td>{{ serverInfo.instanceConfig.enableHttpsRedirection }}</td>
                                    </tr>
                                </tbody>
                            </q-markup-table>
                        </PCard>
                    </div>
                    <div
                        v-if="serverInfo"
                    >
                        <PCard
                            v-cypress="'TheInfoPage_Email_PCard'"
                            title="SMTP Email Server Connection"
                        >
                            <q-markup-table
                                dense
                                flat
                                :bordered="false"
                                separator="none"
                                class="q-py-md text-grey-8"
                            >
                                <tbody>
                                    <tr>
                                        <td>Connected</td>
                                        <td/>
                                        <td>{{ serverInfo.smtpClientConnected }}</td>
                                    </tr>
                                    <tr>
                                        <td>Authenticated</td>
                                        <td/>
                                        <td>{{ serverInfo.smtpClientAuthenticated }}</td>
                                    </tr>
                                    <tr>
                                        <td>Encrypted</td>
                                        <td/>
                                        <td>{{ serverInfo.smtpClientSecured }}</td>
                                    </tr>
                                </tbody>
                            </q-markup-table>
                        </PCard>
                    </div>
                </div>
            </div>
        </div>
        <ForgeLoadState
            v-else
            :resources="forgeResourceElements"
            class="fit"
        />
    </q-page>
</template>

<script>
import Vue from 'vue';
import mixins from '../../../mixins';
import ForgeLoadState from '../../../plugin-framework/shared-components/ForgeLoadState';
import petroJSSettings from '../../../../petrojs-settings.json';
import PluginInfoCard from './PluginInfoCard';

export default {
    name: 'TheInfoPage',
    components: {
        ForgeLoadState,
        PluginInfoCard,
    },
    mixins: [mixins.compute],
    data() {
        return {
            portalUrl: petroJSSettings.portalUrl,
            applicationStatuses: {
                loaded: {
                    name: 'Loaded',
                    color: 'positive',
                    icon: 'done',
                },
                error: {
                    name: 'Error',
                    color: 'negative',
                    icon: 'error_outline',
                },
            },
            applianceInfo: null,
            applianceStatuses: {
                good: {
                    name: 'Online',
                    color: 'positive',
                    icon: 'done',
                },
                unresponsive: {
                    name: 'Unresponsive',
                    color: 'warning',
                    icon: 'mdi-alert-circle-outline',
                },
                error: {
                    name: 'Error',
                    color: 'negative',
                    icon: 'report_problem',
                },
            },
        };
    },
    computed: {
        serverInfo() {
            return this.$environment.info;
        },
        bundlesResource() {
            return '$root/SystemInfo#AllPlugins';
        },
        forgeResources() {
            return [this.bundlesResource];
        },
        activeApps() {
            const appCards = this.$pai.getFragments('app-card');
            const activeApps = appCards.map((ac) => {
                return {
                    name: ac.props.title,
                    id: ac.props.title,
                    version: '0.1',
                    status: 'loaded',
                    description: ac.props.description,
                };
            });
            activeApps.sort((a, b) => a.name.localeCompare(b.name));
            return activeApps;
        },
        activePlugins() {
            const bundles = this.forgeElement(this.bundlesResource);
            if (bundles && bundles.data && bundles.data.length) {
                const activePlugins = [...bundles.data];
                activePlugins.sort((a, b) => a.name.localeCompare(b.name));
                return activePlugins;
            }
            return [];
        },
        latestDBVersion() {
            if (this.serverInfo.databaseInfo && this.serverInfo.databaseInfo.latestDBVersion) {
                return `${this.serverInfo.databaseInfo.latestDBVersion}`;
            }
            return '';
        },
        applianceEntries() {
            const applianceEntries = [];
            if (this.applianceInfo) {
                this.applianceInfo.forEach(appliance => {
                    const name = appliance.name;
                    const reason = appliance.reason;
                    const status = appliance.status ? appliance.status.toLowerCase() : '';
                    applianceEntries.push({
                        applianceName: name,
                        name,
                        reason,
                        status,
                    });
                    if (appliance.serverStatuses) {
                        const serverStatuses = [];
                        appliance.serverStatuses.forEach(serverStatus => {
                            const serverName = `\t${serverStatus.serverId}`;
                            const entry = {
                                applianceName: name,
                                name: serverName,
                                status: serverStatus.status ? serverStatus.status.toLowerCase() : '',
                                displayClass: 'server-indent',
                            };
                            if (serverStatus.reason) {
                                entry.reason = serverStatus.reason;
                            }
                            serverStatuses.push(entry);
                        });
                        serverStatuses.sort((a, b) => a.name.localeCompare(b.name));
                        applianceEntries.push(...serverStatuses);
                    }
                });
            }
            applianceEntries.sort((a, b) => a.applianceName.localeCompare(b.applianceName));
            return applianceEntries;
        },
    },
    created() {
        this.getInfo();
    },
    methods: {
        getInfo() {
            this.$api.appliances.getStatuses()
            .then(response => {
                let applianceInfo = null;
                if (response && response.data && response.data.length) {
                    applianceInfo = response.data;
                }
                this.applianceInfo = applianceInfo;
            })
            .catch(error => {
                Vue.$notify.error(`Unable to get appliance info: ${error.message}`);
            });
        },
    },
};
</script>

<style scoped>
td:first-of-type {
    width: 50%;
}
td:nth-child(2) {
    width: 15%;
}
td:nth-child(4) {
    max-width: 10rem;
}
.server-indent {
    text-indent: 1rem;
}
</style>
